import React, { useState, useEffect, useRef } from 'react';
import Icon from './Icon';
import { createPortal } from 'react-dom';

type DropdownMenuProps = {
  className?: string;
  label?: string;
  icon?: string;
  iconSize?: string;
  flat?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'negative' | 'success' | 'info';
  options: { label: string; onClick: () => void }[];
  onClick?: (event: React.MouseEvent) => void;
  refs?: any;
};

export default function DropdownMenu({
  label,
  className,
  disabled = false,
  flat = false,
  color = 'primary',
  size = 'medium',
  icon,
  iconSize,
  options,
  onClick,
  refs,
}: DropdownMenuProps) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const dropdownRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleMenuToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    setMenuOpen((prev) => !prev);
  };

  const updateDropdownPosition = () => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const screenWidth = window.innerWidth;

      let left = rect.left + window.scrollX;

      if (dropdownRef.current) {
        const dropdownWidth = dropdownRef.current.offsetWidth;
        const maxLeft = screenWidth - dropdownWidth;
        left = Math.min(left, maxLeft);
      }

      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left,
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    const handleResize = () => {
      if (menuOpen) {
        updateDropdownPosition();
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleResize);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleResize);
    };
  }, [menuOpen]);

  useEffect(() => {
    if (menuOpen) {
      updateDropdownPosition();
    }
  }, [menuOpen]);

  function getButtonBackgroundColor() {
    switch (color) {
      case 'primary':
        return flat ? 'bg-transparent active:bg-cyan-100 shadow-none' : 'bg-cyan-600';
      case 'negative':
        return flat ? 'bg-transparent active:bg-red-200 shadow-none' : 'bg-red-600 active:bg-red-700';
      case 'info':
        return 'hover:underline';
      default:
        return '';
    }
  }

  function getButtonTextColor() {
    if (flat) {
      switch (color) {
        case 'primary':
          return 'text-cyan-600';
        case 'negative':
          return 'text-red-600';
      }
    } else {
      switch (color) {
        case 'primary':
          return 'text-white';
        case 'negative':
          return 'text-white';
      }
    }
  }

  function getLabelSize() {
    switch (size) {
      case 'small':
        return 'text-sm';
      case 'medium':
        return 'text-md';
      default:
        return 'text-md';
    }
  }

  return (
    <div className={`relative inline-block ${className}`} ref={containerRef}>
      {/* Dropdown toggle button */}
      <button
        ref={refs}
        onClick={(e) => {
          e.stopPropagation();
          if (!disabled && onClick) onClick(e);
          handleMenuToggle(e);
        }}
        className={`hover:opacity-90 relative font-medium active:opacity-100 active:brightness-50 px-4 py-2 rounded-2xl ${getButtonBackgroundColor()} ${getButtonTextColor()} ${className}`}
      >
        <div className={'flex items-center'}>
          {icon && (
            <Icon
              className={`${label ? 'mr-2' : ''}`}
              name={icon}
              size={iconSize || '20px'}
            />
          )}
          {label && <span className={`${getLabelSize()}`}>{label}</span>}
        </div>
      </button>

      {/* Dropdown options using React Portal */}
      {menuOpen && createPortal(
        <div
          ref={dropdownRef}
          className="absolute bg-white shadow-lg rounded-lg py-2 z-50 transition duration-300 ease-out"
          style={{
            top: dropdownPosition.top,
            left: dropdownPosition.left,
          }}
        >
          <ul>
            {options.map((option, index) => (
              <li
                key={index}
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  option.onClick();
                  setMenuOpen(false);
                }}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>,
        document.body 
      )}
    </div>
  );
}
