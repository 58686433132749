import { useEffect, useState } from "react";
import { DeviceEntityCommand, DomoticEntity } from "../../types";
import SelectInput from "../SelectInput";
import TextInput from "../TextInput";
import { useTranslation } from "react-i18next"


export default function RuleManagerCommand ({
  entity,
  value,
  initialCommandValue,
  onChange
} : {
  value?: string,
  initialCommandValue?: string,
  entity?: DomoticEntity,
  onChange: (command: DeviceEntityCommand, val?:any) => void
}) {

  const [commandOptions, setCommandOptions] = useState<any>([])
  const [command, setCommand] = useState<{ label: string, value: string, valueType: string | undefined }>()
  const [commandValue, setCommandValue] = useState('')
  const { t } = useTranslation()


  useEffect(() => {
    if (entity && entity.commands) {
      setCommandOptions(entity.commands
        .map(command => {
          return {
            label: command.command_type,
            value: command.command_type,
            valueType: command.value_specifications.type
          }
        }))
    handleChange()

    } else {
      setCommandOptions([])
    }
  }, [ entity ])

  useEffect(() => {
    if (entity && value) {
      const selectedCommand = entity.commands.find(c => c.command_type === value)
      if (selectedCommand) {
        setCommand({
          label: selectedCommand.command_type,
          value: selectedCommand.command_type,
          valueType: selectedCommand.value_specifications.type
        })
        if (initialCommandValue) setCommandValue(initialCommandValue)
        onChange(selectedCommand, commandValue)
      }
    }
  }, [ entity ])

  useEffect(() => {
      handleChange()
  }, [ commandValue, command ])


  function handleChange () {
    if (entity && command) {
      const c = entity.commands.find(c => c.command_type === command.value)
      if (c) {
        onChange(c, commandValue)
      }
    }
  }

  return (
    <div className="">
      <div className="bg-cyan-50 border-l-4 border-cyan-600 py-2 px-4 cursor-pointer">
        <div className="flex items-center space-x-4">
          <div className={'w-36 font-medium text-sm'}> {t('rules.command')}</div>
          <div className={'flex-1'}>
            <SelectInput<{ label: string, value: string, valueType: string | undefined }, false>
              isDisabled={commandOptions.length === 0}
              value={command}
              onChange={item => {
                if (item) setCommand(item)
              }}
              options={commandOptions} />
          </div>
        </div>
      </div>
      {
        (command && command.valueType && command.valueType !== 'None') &&
        <div className="bg-cyan-50 border-l-4 border-cyan-600 py-2 px-4 cursor-pointer">
          <div className="flex items-center space-x-4">
            <div className={'w-36 font-medium text-sm'}> {t('rules.value_to_send')}</div>
            <div className={'flex-1'}>
              <TextInput
                value={commandValue}
                onChange={val => 
                  setCommandValue(val)} />
            </div>
          </div>
        </div>
      }
    </div>
  )

}