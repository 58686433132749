/**
 * This modules stores and retrieve the auth token when needed
 * TODO: discuss the token location, storing the token in local storage is quite
 * dangerous and expose the app to attacks
 */

export const TOKEN_IDENTIFIER = "viktoken";
export const REFRESH_TOKEN = "refreshtoken";
export const OLD_TOKEN_IDENTIFIER = "old_viktoken";
export const ACCOUNT_LIST = "account_list";

// Those methods does not relly need comments, they describe themselves!
export const getAuthToken = () => localStorage.getItem(TOKEN_IDENTIFIER);
export const getOldToken = () => localStorage.getItem(OLD_TOKEN_IDENTIFIER);
export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN);
export const getAccountList = () => localStorage.getItem(ACCOUNT_LIST);
export const saveAuthToken = (token: string) =>
  localStorage.setItem(TOKEN_IDENTIFIER, token);
export const saveRefreshToken = (token: string) =>
  localStorage.setItem(REFRESH_TOKEN, token);
export const saveOldToken = (token: string) =>
  localStorage.setItem(OLD_TOKEN_IDENTIFIER, token);
export const saveAccountList = (accountList: any) => {
  localStorage.setItem(ACCOUNT_LIST, JSON.stringify(accountList));
};
export const removeToken = () => localStorage.removeItem(TOKEN_IDENTIFIER);
export const clearAllTokens = () => {
  localStorage.removeItem(TOKEN_IDENTIFIER);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(OLD_TOKEN_IDENTIFIER);
  localStorage.removeItem('vikey_res_sorting');
  localStorage.removeItem('vikey_res_sorting_API');
  localStorage.removeItem('apt_filters');
  localStorage.removeItem('vikey_res_status');
  localStorage.removeItem('vikey_res_page');
};
