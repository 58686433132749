import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { fetchDirectLocals, fetchOrdersExport } from '../../shared/queries';
import { useTranslation } from 'react-i18next';
import FormField from '../FormField';
import SelectInput from '../SelectInput';
import { BaseSelectItem } from '../../types';
import DateRangePicker from '../DateRangePicker';
import dayjs from 'dayjs';
import Icon from '../Icon';
import qs from 'qs';

export default function OrderExport() {
  const { t } = useTranslation();

  //   const [currentData, setCurrentData] = useState<any>();
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [localKey, setLocalKey] = useState<string | undefined>('');

  const downloadMutation = useMutation((currentData: any) =>
    fetchOrdersExport(currentData)
  );
  const { data: locals, isLoading: isLoadingLocals } = useQuery(
    'localsList',
    fetchDirectLocals
  );

  const download = () => {
    const currentData = localKey
      ? {
          date_from_gt: dateFrom,
          date_from_lt: dateTo,
          local_key: localKey,
        }
      : {
        date_from_gt: dateFrom,
        date_from_lt: dateTo,
        };
    downloadMutation.mutate(currentData);
    // window.open('https://api.vikey.it/api/v3/pay/services/export?' + qs.stringify(currentData))
  };

  return (
    <div className={'p-4'}>
      <div className="text-lg font-semibold">
        {t('orders.exportOrdersTitle')}
      </div>
      <div className="mt-4 text-gray-600 text-sm">
        {t('orders.exportOrdersBody')}
      </div>
      <div className="mt-6 flex flex-col space-y-4">
        <DateRangePicker
          inputFormat={'YYYY-MM-DD'}
          displayFormat={'DD-MM-YYYY'}
          outputFormat={'DD-MM-YYYY'}
          size={'sm'}
          value={dateFrom + ' -> ' + dateTo}
          onChange={(value) => {
            const { from, to } = value as any;
            setDateFrom(dayjs(from).format('YYYY-MM-DD'));
            setDateTo(dayjs(to).format('YYYY-MM-DD'));
          }}
        />

        <FormField label={t('navigation.apartments')}>
          <SelectInput<BaseSelectItem, false>
            menuPortalTarget={document.body}
            styles={{
              container: (base) => ({ ...base, zIndex: 999 }),
              menuPortal: (base) => ({ ...base, zIndex: 999 }),
            }}
            isLoading={isLoadingLocals}
            menuShouldScrollIntoView={false}
            onChange={(item) => {
              if (item) setLocalKey(item.value);
            }}
            options={
              locals && locals.results
                ? locals.results.map((l) => {
                    return {
                      label: l.name,
                      value: l.local_key,
                    };
                  })
                : []
            }
          />
        </FormField>

        <button
          //   disabled={!localKey}
          onClick={() => {
            download();
          }}
          className="flex justift-between items-center p-4 bg-cyan-50 hover:bg-cyan-100 transition-all cursor-pointer font-medium text-cyan-800 rounded-2xl"
        >
          <div className={'text-left flex-1'}>
            {t('orders.exportSelection')}
          </div>
          <div>
            <Icon name={'document-download'} size={'24px'} className={'mr-2'} />
          </div>
        </button>
      </div>
    </div>
  );
}
