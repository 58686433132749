import Skeleton from "react-loading-skeleton";

export default function ReservationServiceSkeleton () {

  return (
    <div className={'bg-white border rounded-2xl px-4 py-2'}>
      <Skeleton></Skeleton>
      <div className="flex justify-between items-center mt-2 border-t pt-2">
        <div className={'w-auto'}>
          <Skeleton width={'4rem'} height={'0.9rem'}></Skeleton>
        </div>
        <div className={'w-auto'}>
          <Skeleton width={'6rem'} height={'0.9rem'}></Skeleton>
        </div>
      </div>
    </div>
  )

}