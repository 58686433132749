import TextInput from '../TextInput'
import FormField from '../FormField'
import Button from '../Button'
import { useMutation } from 'react-query'
import { addNotificationEmail } from '../../shared/queries'
import { toast } from 'react-toastify'
import Modal from '../Modal'
import ModalCard from '../ModalCard'
import ModalSection from '../ModalSection'
import ModalAction from '../ModalAction'
import { useState } from 'react'
import addEmailSchema from '../../shared/schema/addEmailSchema'
import { useTranslation } from "react-i18next"


export default function AddNotificationEmail ({
  visible,
  onClose
} : {
  visible: boolean,
  onClose: () => void
}) {

  const [validationError, setValidationError] = useState<any>(null)
  const [email, setEmail] = useState<string>()
  const { t } = useTranslation()


  const addEmailMutation = useMutation(() => addNotificationEmail({
    email: email,
    preferred: 0
  }), {
    onSuccess: () => {
      toast.success(t('general.requestCompleted'))
      if (onClose) onClose()
    },
    onError: (error: Error) => {
      toast.error(error.message || t('general.requestError'))
    }
  })

  function handleSubmit () {
    const { error, value } = addEmailSchema.validate({
      email
    })
    if (error) {
      setValidationError(error)
      toast.error(t('general.invalidFields'))
    } else {
      setValidationError(null)
      addEmailMutation.mutate()
    }
  }

  return (
    <Modal
      visible={visible}>
      <ModalCard
        className={'w-full max-w-lg'}
        title={t('profile.addEmail')}>
        <ModalSection>
          <div className={'p-4'}>
            <FormField
              errors={validationError}
              errorKey={'email'}
              label={t('profile.email')}>
              <TextInput
                autofocus={true}
                value={email}
                onChange={val => setEmail(val)} />
            </FormField>
          </div>
        </ModalSection>
        <ModalAction
          saveLabel={t('general.add')}
          isLoading={addEmailMutation.isLoading}
          onClose={onClose}
          onSave={handleSubmit} />
      </ModalCard>
    </Modal>
  )

}