import { io, Socket } from 'socket.io-client'
import { addEvent, addSubscription, clearSubscriptions, setStatus } from '../../features/socket/socketSlice'
import store from '../../store'
import { GenericSocketManager } from '../../types'
import { REACT_APP_VIKEY_API_URL } from './config'
import { getAuthToken } from './tokenStore'


const socketManagerV2 = {
  socket: null as Socket | null,


  init(name_space:string) {
    this.socket = io(REACT_APP_VIKEY_API_URL + '/' + name_space, {
      path: '/api/v3/socket',
      extraHeaders: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });

    this.socket.on('connect', () => {
      console.debug('[SOCKET] - Connected');
      store.dispatch(setStatus(true));
    });

    this.socket.on('connect_error', () => {
      console.debug('[SOCKET] - Connection error');
    });

    this.socket.on('disconnect', () => {
      store.dispatch(setStatus(false));
      console.debug('[SOCKET] - Disconnected');
    });
  },

  subscribe({ topic }: { topic: string }) {
    console.debug('[SOCKET] - Activating subscription to:', topic);
    store.dispatch(addSubscription(topic));
    this.socket?.emit('subscriptions', {
      topics: [topic] 
    });
  },

  unsubscribe() {
    console.debug('[SOCKET] - Unsubscribing from all topics');
    store.dispatch(clearSubscriptions());
    this.socket?.emit('subscriptions', {
      topics: [ ] 
    });
  },

  closeConnection() {
    console.debug('[SOCKET] - Closing connection');
    this.socket?.close();
    this.socket = null;
  },

  listen({ event, callback }: { event: string; callback: (data: any) => void }) {
    console.debug('[SOCKET] - Listening to event:', event);
    this.socket?.on(event, (data) => {
      store.dispatch(
        addEvent(JSON.parse(JSON.stringify(data)))
      );
      callback(data); 
    });
  },

  unlisten({ event, callback }: { event: string; callback: (data: any) => void }) {
    console.debug('[SOCKET] - Unlistening from event:', event);
    this.socket?.off(event, callback);
  },
};

export default socketManagerV2;
