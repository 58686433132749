import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { fetchInvoicesById } from "../../shared/queries";
import Modal from "../Modal";
import ModalActions from "../ModalAction";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import ProgressBar from "../ProgressBar";

const BillAttemptDetail = ({ visible, id, onClose }: any) => {
  const { t } = useTranslation();
  const [arrayAttempt, setArrayAttempt] = useState<any>();
  const [loading, setLoading] = useState(true);
  const { data: dataAttemptById } = useQuery(
    "attempts",
    () => fetchInvoicesById(id),
    {
      keepPreviousData: false,
      onSuccess: (data) => {
        if (data && data.results) {
          setLoading(false);
        }
      },
    }
  );

  useEffect(() => {
    if (visible) {
      setArrayAttempt(dataAttemptById);
    } else {
      setArrayAttempt(undefined);
    }
    return () => {
      setArrayAttempt(undefined);
    };
  }, [dataAttemptById, visible]);

  return (
    <Modal visible={visible}>
      <ModalCard
        className={"w-full"}
        title={`${t("billing.transactions")} ( ${
          !loading ? arrayAttempt?.results?.id ?? "ND" : "ND"
        } )`}
      >
        <ModalSection>
          {!loading ? (
            arrayAttempt?.results?.attempts?.map((attempt: any) => (
              <div className={"px-4 mt-5"}>
                {/*  */}
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-3 py-3">
                          ID
                        </th>
                        <th scope="col" className="px-6 py-3">
                          {t("invoices.status")}
                        </th>
                        <th scope="col" className="px-6 py-3">
                          {t("reservations.date")}
                        </th>
                        <th scope="col" className="px-6 py-3">
                          {t("stripe.paymentIntent")}
                        </th>
                        <th scope="col" className="px-6 py-3">
                          {t("invoices.competenzeFattura")}
                        </th>
                        <th scope="col" className="px-6 py-3">
                          {t("invoices.totaleTransazione")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-3 py-4">{attempt.id ?? "ND"}</td>
                        <td className="px-3 py-4">{attempt.status ?? "ND"}</td>
                        <td className="px-6 py-4">
                          {attempt.date_last_try
                            ? dayjs(attempt.date_last_try).format("YYYY-MM-DD")
                            : "ND"}
                        </td>
                        <td className="px-6 py-4">
                          {attempt.stripe_pi_id ?? "ND"}
                        </td>
                        <td className="px-6 py-4">
                          {attempt.currency}{" "}
                          {(
                            attempt.competenza.amount / 100 +
                            attempt.competenza.amount_iva / 100
                          ).toFixed(2)}
                        </td>
                        <td className="px-6 py-4">
                          {attempt.currency}{" "}
                          {(
                            attempt.amount / 100 +
                            attempt.amount_iva / 100
                          ).toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/*  */}
              </div>
            )) ?? (
              <div className={"w-full text-center"}>
                <div className="text-center p-4">
                  <img src={"/search.svg"} className={"inline-block h-32"} />
                  <div className="mt-6 font-bold text-lg">
                    {t("table.noRes")}
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className={"w-full text-center"}>
              <div className="text-center p-4">
                <img src={"/search.svg"} className={"inline-block h-32"} />
                <div className="mt-6 font-bold text-lg">{t("table.noRes")}</div>
                <ProgressBar />
              </div>
            </div>
          )}
        </ModalSection>
        <ModalActions
          onClose={() => {
            if (onClose) onClose();
          }}
        />
      </ModalCard>
    </Modal>
  );
};

export default BillAttemptDetail;
