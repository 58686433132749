import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { fetchLocalAccess, addUserAccess } from "../../shared/queries"
import { ListApiResponse, ListApiResponseData, Local, LocalAccess } from "../../types"
import ApartmentUserPermission from "./ApartmentUserPermission"
import FormField from "../FormField"
import FormSectionTitle from "../FormSectionTitle"
import TextInput from "../TextInput"
import Button from "../Button";
import { useMutation } from "react-query"
import { toast } from "react-toastify"


type ApartmentAccessProps = {
  data: Local
}

export default function ApartmentAccess ({
  data
} : ApartmentAccessProps) {

  const { t } = useTranslation()

  const [access, setCurrentAccess] = useState<LocalAccess[]>()
  const [mail, setCurrentMail] = useState<string>("")


  const {
    isLoading,
    refetch
  } = useQuery<ListApiResponse<LocalAccess>, Error>(['subscription', data.local_key], () => fetchLocalAccess(data.local_key),{
    onSuccess: data => {
      if (data && data.results ) {
        setCurrentAccess(data.results)
      }
    }
  })

  const mutation = useMutation(() => addUserAccess({local_key: data.local_key, email: mail, access_tools: 1, access_reservations: 1, access_billing: 1}), {
    onSuccess: (data) => {
      setCurrentMail("")
      refetch()
    },
    onError: (data: any) => {
      toast.error(data)
    }
  })


  return (
    <div>
      {
        access && access.map((a: LocalAccess, index: number) => (
          <ApartmentUserPermission
            key={index}
            access={a}
            refetch={()=>refetch()} />
        ))
      }

      <FormField
        tooltip={t('connectionPermission.addUser')}
        label={t('connectionPermission.addUserMail')}>
        <TextInput
          value={mail}
          onChange={ val =>
            setCurrentMail(val)
          }
          />
          <div className="mt-6 mb-6">
          <Button
          onClick={() => {
            mutation.mutate()
          }}
            >{t("general.add")}</Button>
        </div>
        </FormField>
      </div>
  )

}
