import { io } from 'socket.io-client'
import { addEvent, addSubscription, clearSubscriptions, setStatus } from '../../features/socket/socketSlice'
import store from '../../store'
import { SocketManager } from '../../types'
import { REACT_APP_VIKEY_API_URL } from './config'
import { getAuthToken } from './tokenStore'

const socketManger : SocketManager = {

  socket: null,

  devices: [],

  init () {
    //this.socket = io(REACT_APP_VIKEY_API_URL + '/domotics', {
    this.socket = io(REACT_APP_VIKEY_API_URL + '/domotics', {
      path: '/api/v3/socket',
      extraHeaders: {
        Authorization: 'Bearer ' + getAuthToken() 
      }
    })
    this.socket.on('connect', () => {
      console.debug('[SOCKET] - Connected')
      store.dispatch(setStatus(true))
    })
    this.socket.on('connect_error', () => {
      console.debug('[SOCKET] - Connection error')
    })
    this.socket.on('disconnect', () => {
      store.dispatch(setStatus(false))
      console.debug('[SOCKET] - Disconnected')
    })
  },

  /**
   * Subscribe socket to a specific topic 
   */
  subscribe ({ topic }) {
    console.debug('[SOCKET] - activating subscription to ' + topic)
    store.dispatch(addSubscription(topic))
    this.socket?.emit('subscriptions', {
      topics: [
        topic
      ]
    })
  },

  /**
   * Unsubscribe the socket from a topic
   */
  unsubscribe () {
    console.debug('[SOCKET] - Unsubscribed from all topics')
    store.dispatch(clearSubscriptions())
    this.socket?.emit('subscriptions', {
      topics: []
    })
  },

  closeConnection () : void {
    console.debug('[SOCKET] - closing connection')
    this.socket?.close()
    this.socket = null
  },

  listen ({ event, callback }) {
    this.socket?.on('message', (data) => {
      store.dispatch(addEvent(
        JSON.parse(JSON.stringify(data))
      ))
      if (data && data.data && data.data.vikey_mac) {
        const currentDevice = this.devices.find(d => d.vikey_mac === data.data.vikey_mac)
        if (!currentDevice) {
          // device does not exists
          this.devices.push({
            ...data.data,
            entities: [data.data.entity]
          })
        } else {
          // device exists
          // let's update the entity
          if (data.data.entity) {
            const currentEntity = currentDevice.entities.find(e => e.entity_id === data.data.entity.entity_id)
            if (currentEntity) {
              // update the entity
              currentEntity.value = data.data.entity.value
            } else {
              // push the entity
              currentDevice.entities.push(data.data.entity)
            }
          }
        }
      }
      callback(data)
    })
  },

  unlisten ({ event, callback }) {
    console.debug('[SOCKET] - Unlistening message')
    this.socket?.off('message', callback)
  }

}

export default socketManger


