import { useRef } from 'react';

interface ImageUploaderProps {
  upload: (data: any) => any;
  children: any;
}

const ImageUploader = (props: ImageUploaderProps) => {
  const { upload, children } = props;
  const uploadInput = useRef<any>();

  const handleFile = (event: any) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const reader = new FileReader();

      reader.addEventListener('loadend', () => {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const maxSize = 640;

          let width = image.width;
          let height = image.height;

          if (width > image.width) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }
          }

          canvas.height = height;
          canvas.width = width;

          const context = canvas.getContext('2d');

          if (context) {
            context.drawImage(image, 0, 0, width, height);
            const data = canvas.toDataURL('image/jpeg', 0.9);
            upload(data);
          }
        };
        if (reader.result) {
          image.src = reader.result.toString();
        }
      });

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <div
      onClick={() => {
        if (uploadInput && uploadInput.current) uploadInput.current.click();
        // upload(undefined);
      }}
    >
      <input type="file" hidden onChange={handleFile} ref={uploadInput} />
      {children}
    </div>
  );
};

export default ImageUploader;
