import { ReactNode } from "react";
import PaginationItem, { PaginationItemArrow } from "./PaginationItem";

const Pagination: React.FC<{
  page: number;
  pageSize: number;
  totalItems: number;
  disableBackPage?: boolean;
  onPageUpdate: (page: number | string) => void;
}> = (props) => {
  const totalPage = Math.ceil(props.totalItems / props.pageSize);

  let pagesToView = ["<", 1, "..", props.page, "..", totalPage, ">"];
  let showPageInfo = true;
  let backArrowDisabled = false;
  let forwardArrowDisabled = false;
  if (props.totalItems < 0) {
    pagesToView = ["<", ">"];
    showPageInfo = false;
  } else if (props.page === totalPage) {
    pagesToView = ["<", 1, "..", totalPage, ">"];
    forwardArrowDisabled = true;
  } else if (props.page === 1) {
    pagesToView = ["<", 1, "..", totalPage, ">"];
    backArrowDisabled = true;
  }

  if (props.disableBackPage) {
    pagesToView = pagesToView.filter((v) => v != "<");
  }

  const showFrom = (props.page - 1) * props.pageSize + 1;
  const showTo =
    props.page * props.pageSize > props.totalItems ? props.totalItems : props.page * props.pageSize;

  return (
    <nav className="md:flex justify-between items-center px-4 py-2" aria-label="Table navigation">
      {showPageInfo ? (
        <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
          Showing
          <span className="font-semibold text-gray-900 dark:text-white">
            {` ${showFrom}-${showTo} `}
          </span>
          of
          <span className="font-semibold text-gray-900 dark:text-white">
            {" " + props.totalItems}
          </span>
        </span>
      ) : (
        <div></div>
      )}

      <ul className="inline-flex items-center -space-x-px">
        {pagesToView.map((p, idx) => {
          let pItemContent: ReactNode;
          if (p === "<") {
            pItemContent = (
              <PaginationItemArrow
                key={`${idx}-${p}`}
                back={true}
                onClick={() => {
                  props.onPageUpdate("-");
                }}
                disabled={backArrowDisabled}
              />
            );
          } else if (p === ">") {
            pItemContent = (
              <PaginationItemArrow
                key={`${idx}-${p}`}
                back={false}
                onClick={() => {
                  props.onPageUpdate("+");
                }}
                disabled={forwardArrowDisabled}
              />
            );
          } else {
            const disabled = typeof p != "number";
            pItemContent = (
              <PaginationItem
                key={`${idx}-${p}`}
                page={p}
                highlight={props.page === p}
                disabled={disabled}
                onClick={props.onPageUpdate}
              >
                {p}
              </PaginationItem>
            );
          }

          return pItemContent;
        })}
      </ul>
    </nav>
  );
};

export default Pagination;
