import { combineReducers } from 'redux'

import loginSlice from '../features/login/loginSlice'
import navigationSlice from '../features/login/navigationSlice'
import socketSlice from '../features/socket/socketSlice'
import ruleSlice from '../features/rule/ruleSlice'
import channelManagerSlice from '../features/channelManager/channelManagerSlice'
import messagingSlice from '../features/messaging/messagingSlice'


const rootReducer = combineReducers({
  login: loginSlice,
  navigation: navigationSlice,
  socket: socketSlice,
  rule: ruleSlice,
  channelManager: channelManagerSlice,
  messageStore: messagingSlice
})

export default rootReducer

export type RootState = ReturnType<typeof rootReducer>