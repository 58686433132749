import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { SortingRule } from "react-table";
import BaseTable from "../components/BaseTable";
import Icon from "../components/Icon";
import Modal from "../components/Modal";
import ModalActions from "../components/ModalAction";
import ModalCard from "../components/ModalCard";
import ModalSection from "../components/ModalSection";
import OrderResults from "../components/orders/OrderResults";
import PageWrapper from "../components/PageWrapper";
// import useBreadcrumbs from "../hooks/useBreadcrumbs";
import { fetchOrders } from "../shared/queries";
import { getHumanDateFromISO } from "../shared/utils/formatting";
import { OrderTable, TableFilterColumn, TableResponse, TableSort } from "../types";
import {isMobile} from "react-device-detect";
import ModalAction from "../components/ModalAction";
import OrderExport from "../components/orders/OrderExport";
import TabSelector from "../components/TabSelector";

export default function Orders () {

  const { t } = useTranslation()

  const history = useHistory()

  const [sorting, setSorting] = useState<TableSort[]>([])
  const [filters, setFilters] = useState<TableFilterColumn[] | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [viewResults, setViewResults] = useState(false)
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState('orders');

  function getColumns () { 
    if (isMobile) {
      return [
        {
          id: '4',
          Header: t('orders.code') || '',
          accessor: 'resv_key'
        },
        {
          id: '10',
          Header: t('orders.guest') || '',
          accessor: 'guest'
        }
      ]
    } else {
      return [
        {
          id: '3',
          Header: t('orders.apartment') || '',
          accessor: 'apartment'
        },
        {
          id: '4',
          Header: t('orders.code') || '',
          accessor: 'resv_key'
        },
        {
          id: '10',
          Header: t('orders.guest') || '',
          accessor: 'guest'
        },
        {
          id: '11',
          Header: t('addService.categories') || '',
          accessor: (row: OrderTable) => {
            return <>{row?.service_type?.length > 0 ? row?.service_type?.map((service: any, i: number) => {
              if(i === 0){
                return <div>{t(`cats.${service}`)}</div>
              } else {
                return <div>{t(`cats.${service}`)}</div>
              }
            }) : '-'}</>
          }
        },
        {
          id: '9',
          Header:  t('orders.amount') || 'Importo',
          accessor: 'amount'
        },
        {
          id: '5',
          Header: 'Checkin',
          accessor: (row: OrderTable) => {
            return getHumanDateFromISO(row.check_in, false)
          }
        },
        {
          id: '6',
          Header: t('general.create') || 'Creazione',
          accessor: (row: OrderTable) => {
            return getHumanDateFromISO(row.creation_date, false)
          }
        },
        {
          id: '7',
          Header: t('orders.paymentDate') || 'Pagamento',
          accessor: (row: OrderTable) => {
            return getHumanDateFromISO(row.payment_date, false)
          }
        },
        {
          id: '8',
          Header: t('orders.status') || 'Stato',
          accessor: (row: OrderTable) => {
            if (row.status === 'SUBMITTED') return (<div className={'text-xs rounded-2xl px-2 py-1 bg-green-600 text-white'}>{t('apartmentServices.paymentVerified')}</div>)
            if (row.status === 'ERROR') return (<div className={'text-xs rounded-2xl px-2 py-1 bg-red-600 text-white'}>{t('general.error')}</div>)
            if (row.status === 'DONE') return (<div className={'text-xs rounded-2xl px-2 py-1 bg-green-600 text-white'}>{t('apartmentServices.paymentMade')}</div>)
            if (row.status === 'COMPLETED') return (<div className={'text-xs rounded-2xl px-2 py-1 bg-green-600 text-white'}>{t('apartmentServices.paymentCompleted')}</div>)
            if (row.status === 'CONFIRMED') return (<div className={'text-xs rounded-2xl px-2 py-1 bg-green-600 text-white'}>{t('apartmentServices.paymentMade')}</div>)       
            if (row.status === 'CEASED') return (<div className={'text-xs rounded-2xl px-2 py-1 bg-red-600 text-white'}>{t('apartmentServices.paymentCeased')}</div>)
            if (row.status === 'REFUND') return (<div className={'text-xs rounded-2xl px-2 py-1 bg-gray-100'}>{t('apartmentServices.paymentRefund')}</div>)
          },
        },
      ]
    }
  }

  const {
    data,
    isFetching
  } = useQuery<TableResponse<OrderTable>, Error>(['orders', currentPage, filters, sorting], () => fetchOrders(filters, ((currentPage - 1) * 15), sorting), {
    keepPreviousData: true
  })

  function handleSorting (data: SortingRule<any>[]) {
    const cols = [] as TableSort[]
    data.forEach(f => {
      const columnKey = getColumns().find(df => df.id === f.id)
      if (columnKey) {
        cols.push({
          columnIndex: columnKey.id,
          desc: f.desc || false
        })
      }
    })
    setSorting(cols)
  }

  /* useBreadcrumbs([
    { label: t('navigation.services'), route: '/orders' }
  ]) */

  useEffect(() => {
    history.push(`/${currentTab}`)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab])

  return (
    <PageWrapper>

      <Modal
        visible={viewResults}>
          <ModalCard
            className={'w-full max-w-4xl'}
            title={t('orders.results')}>
            <ModalSection className={'p-4'}>
              <OrderResults />
            </ModalSection>
            <ModalActions
              onSave={() => {
                setViewResults(false)
              }}
              saveLabel={t('general.done')} />
          </ModalCard>
      </Modal>

      <TabSelector tabs={[{
          key: 'orders',
          label: t('navigation.orders'),
          active: (currentTab === 'orders')
        },{
          key: 'services',
          label: t('navigation.servicesDirect'),
          active: (currentTab === 'services')
        }]}
        className={'mt-4 mb-8'}
        onChange={tab => setCurrentTab(tab.key)} />

      <BaseTable
        currentPage={currentPage}
        pageCount={Math.ceil(parseInt(data?.recordsTotal || '0') / 15)}
        onSorting={handleSorting}
        identifierKey={'id'}
        //title={t('orders.orders')}
        data={data ? data.data : []}
        loading={isFetching}
        totalRows={parseInt(data?.recordsTotal || '0')}
        displayRows={15}
        onFilterChange={(filters, page) => {
          setCurrentPage(page || 1)
          setFilters(filters)
          // localStorage.setItem('vikey_order_filters', JSON.stringify(filters))
        }}
        renderButtons={() => (
          <div className="inline-flex space-x-2">
            <button        
              onClick={() => setViewResults(true)}
              style={{
                boxShadow: 'rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px'
              }}
              className={'flex items-center bg-white focus:outline-none bg-white-600 px-3 text-sm font-medium border-gray-300 rounded-2xl'}>
              <div className={'flex py-1'}>
                <div className={'flex items-center'}>
                  <Icon name={'cash'} size={'16px'} className={'mr-2'}></Icon> {t('orders.results')}
                </div>
              </div>
            </button>
            <button        
              onClick={() => setShowExportModal(true)}
              style={{
                boxShadow: 'rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px'
              }}
              className={'flex items-center bg-white focus:outline-none bg-white-600 px-3 text-sm font-medium border-gray-300 rounded-2xl'}>
              <div className={'flex py-1'}>
                <div className={'flex items-center'}>
                  <Icon name={'document-download'} size={'16px'} className={'mr-2'}></Icon> {t('general.export')}
                </div>
              </div>
            </button>
          </div>
        )}
        filters={[{
          key: '9',
          label: t('orders.amount'),
          active: false,
          value: null,
          type: 'text'
        }, {
          key: '3',
          label: t('orders.apartment'),
          active: false,
          value: null,
          type: 'text'
        }, {
          key: '4',
          label: t('orders.code'),
          active: false,
          value: null,
          type: 'text'
        }, {
          key: '5',
          label: t('orders.arrival'),
          active: false,
          type: 'date',
          value: null
        }, {
          key: '6',
          label: t('general.create'),
          active: false,
          type: 'date',
          value: null
        }, {
          key: '7',
          label: t('orders.paymentDate'),
          active: false,
          type: 'date',
          value: null
        }, {
          key: '8',
          label: t('orders.status'),
          active: false,
          value: null,
          type: 'text'
        }, {
          key: '10',
          label: t('orders.guest'),
          active: false,
          value: null,
          type: 'text'
        }]}
        columns={getColumns()}
        onEdit={id => {
          history.push('/orders/' + id)
        }} />
         <Modal visible={showExportModal}>
        <ModalCard className={'w-full max-w-xl'}>
          <ModalSection className={'w-full max-w-xl'}>
            <OrderExport />
          </ModalSection>
          <ModalAction
            closeLabel={t('general.exit')}
            saveLabel={t('general.confirm')}
            onClose={() => {
              setShowExportModal(false);
            }}
          />
        </ModalCard>
      </Modal>

    </PageWrapper>
  )

}