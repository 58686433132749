import Skeleton from "react-loading-skeleton"

type DetailRowProps = {
  label: string,
  children: any,
  loading?: boolean
}

export default function DetailRow ({
  label,
  children,
  loading
} : DetailRowProps) {

  return (
    <div className="flex flex-wrap mb-4 flex-wrap">
      <div className={'w-full mb-1 md:mb-0 md:w-44 xs:w-full text-gray-500'}>{label}</div>
      <div className={'flex-1'}>
        {!loading && children}
        {loading && <Skeleton width={'10rem'}></Skeleton>}
      </div>
    </div>
  )
  
}