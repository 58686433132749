export function getReservationColor (mark: any) {
  switch (mark) {
    case 1:
      return 'bg-gray-200'
    case 2:
      return 'bg-green-600'
    case 3:
      return 'bg-blue-600'
    case 4:
      return 'bg-yellow-300'
    case 5:
      return 'bg-yellow-600'
    case 6:
      return 'bg-red-600'
    default:
      return 'bg-gray-200'
  }
}