import Skeleton from "react-loading-skeleton";

export default function LoadingEmailSkeleton () {

  return (
    <div>
      <div className="mb-8">
        <Skeleton width={'12rem'} height={'2rem'}></Skeleton>
      </div>
      
      <div>
        <Skeleton height={'1.5rem'} width={'10rem'}></Skeleton>
      </div>

      <div style={{
        marginTop: '2rem'
      }}>
        <Skeleton height={'1.5rem'} width={'15rem'}></Skeleton>
      </div>

      <div style={{
        marginTop: '2rem'
      }}>
        <Skeleton height={'1.5rem'} width={'15rem'}></Skeleton>
      </div>

      <div style={{
        marginTop: '2rem'
      }}>
        <Skeleton height={'1.5rem'} width={'4rem'}></Skeleton>
      </div>

      <div style={{
        marginTop: '2rem'
      }}>
        <Skeleton height={'18rem'} width={'100%'}></Skeleton>
      </div>
    </div>

  )

}