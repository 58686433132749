// FaqList.tsx
import React from 'react';
import Button from '../Button';
import { FaqEntry } from '../../types';

interface FaqListProps {
    faqs: Array<FaqEntry & { index: number }>;
    emptyMessage: string,
    questionLabel: string,
    answerLabel:string,
    onQuestionChange: (index: number, value: string) => void;
    onAnswerChange: (index: number, value: string) => void;
    onDeleteFaq: (index: number) => void;
}

const FaqList: React.FC<FaqListProps> = ({ faqs, onQuestionChange, onAnswerChange, onDeleteFaq, emptyMessage,questionLabel,answerLabel}) => {

    if (faqs.length === 0) {
        return (
            <div className="flex justify-center items-center h-32">
                <p className="text-gray-500">{ emptyMessage }</p>
            </div>
        );
    }

    return (
            console.log(faqs),

        <div className="overflow-y-auto h-[70vh]">
            {faqs.map((faq) => (
                <div key={faq.index} className="border-b border-gray-300 py-4 relative">
                    <div className="mb-4">
                        <label className="block font-semibold">{questionLabel}</label>
                        <textarea
                            className="w-full rounded-lg p-2 mt-2 focus:border-cyan-600"
                            value={faq.question}
                            onChange={(e) => onQuestionChange(faq.index, e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block font-semibold">{answerLabel}</label>
                        <textarea
                            className="w-full rounded-lg p-2 mt-2 focus:border-cyan-600"
                            value={faq.proposed_answer}
                            onChange={(e) => onAnswerChange(faq.index, e.target.value)}
                        />
                    </div>
                    <Button
                        icon="trash"
                        onClick={() => onDeleteFaq(faq.index)}
                        className="mt-2 px-4 py-2 bg-cyan-500 text-white rounded-lg hover:bg-red-600"
                    />
                </div>
            ))}
        </div>
    );
};

export default FaqList;
