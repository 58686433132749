import dayjs from "dayjs"
import { useEffect, useRef } from "react"
import { Timeline } from 'vis-timeline/peer'
import 'vis-timeline/styles/vis-timeline-graph2d.min.css'

let timeline = null as any

export default function TimelineChart ({
    className,
    data = []
} : {
    className?: string,
    data: {
        id: number,
        start: string,
        end: string,
        title: string,
        content: any,
        type: string
    }[]
}) {

    const containerRef = useRef<any>()

    useEffect(() => {
        timeline = new Timeline(
            containerRef.current,
            data,
            {
                maxHeight: 300,
                minHeight: 300,
                stack: true,
                zoomable: true,
                start: dayjs().startOf('year').format('YYYY-MM-DD'),
                end: dayjs().endOf('year').format('YYYY-MM-DD'),
                format: {
                    minorLabels: {
                        millisecond: '',
                        second: 's',
                        minute: 'HH:mm',
                        hour: 'HH:mm',
                        weekday: 'D/M',
                        day: 'D/M',
                        month: 'M',
                        year: 'YYYY'
                    },
                    majorLabels: {
                        millisecond: 'HH:mm:ss',
                        second: 'D/M HH:mm',
                        minute: 'D/M',
                        hour: 'D/M',
                        weekday: 'M/YYYY',
                        day: 'M/YYYY',
                        month: 'YYYY',
                        year: ''
                    }
                }
            }
        )
    }, [])

    useEffect(() => {
        if (timeline) {
            timeline.setItems(data)
        }
    }, [ data ])

    return (
        <div className={className}>
            <div ref={containerRef}></div>
        </div>
    )

}