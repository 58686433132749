import Skeleton from "react-loading-skeleton";

export default function BaseLoadingPage () {

    return (
        <div className={'flex flex-col space-y-4'}>
            <Skeleton width={140} height={30} />

            <Skeleton width={140} height={20} />

            <div className="flex flex-col space-y-1">
                <Skeleton width={400} height={25} />
                <Skeleton width={250} height={15} />
            </div>

            <div className="flex space-x-3">
                <div className="flex flex-col">
                    <Skeleton width={100} height={25} />
                    <Skeleton width={100} height={15} />
                </div>

                <div className="flex flex-col">
                    <Skeleton width={100} height={25} />
                    <Skeleton width={100} height={15} />
                </div>

                <div className="flex flex-col">
                    <Skeleton width={100} height={25} />
                    <Skeleton width={100} height={15} />
                </div>

                <div className="flex flex-col">
                    <Skeleton width={100} height={25} />
                    <Skeleton width={100} height={15} />
                </div>
            </div>

            <div className="flex flex-col space-y-1">
                <Skeleton width={400} height={20} />
                <div className="flex mt-4 space-x-2">
                    <Skeleton width={150} height={15} />
                    <Skeleton width={90} height={15} />
                </div>

                <div className="flex space-x-2">
                    <Skeleton width={150} height={15} />
                    <Skeleton width={90} height={15} />
                </div>

                <div className="flex space-x-2">
                    <Skeleton width={150} height={15} />
                    <Skeleton width={90} height={15} />
                </div>
            </div>
        </div>
    )

}