import TextInput from '../TextInput'
import FormField from '../FormField'
import { useMutation } from 'react-query'
import { updatePassword } from '../../shared/queries'
import { toast } from 'react-toastify'
import Modal from '../Modal'
import ModalCard from '../ModalCard'
import ModalSection from '../ModalSection'
import ModalAction from '../ModalAction'
import { useState } from 'react'
import changePasswordSchema from '../../shared/schema/changePasswordSchema'
import { useTranslation } from 'react-i18next'

export default function UpdatePassword ({
  visible,
  onClose
} : {
  visible: boolean,
  onClose: () => void
}) {

  const { t } = useTranslation()

  const [validationError, setValidationError] = useState<any>(null)
  const [password, setPassword] = useState<string>()
  const [confirmPassword, setConfirmPassword] = useState<string>()

  const updatePasswordMutation = useMutation(() => updatePassword({
    password: password,
  }), {
    onSuccess: () => {
      toast.success(t('general.updated'))
      if (onClose) onClose()
    },
    onError: () => {
      toast.error(t('general.requestError'))
    }
  })

  function handleSubmit () {
    const { error, value } = changePasswordSchema.validate({
      password,
      password2: confirmPassword
    })
    if(password!==confirmPassword){
      setValidationError(error)
      toast.error(t('general.invalidFields'))
    }
    else if (error) {
      setValidationError(error)
      toast.error(t('general.invalidFields'))
    } else {
      setValidationError(null)
      updatePasswordMutation.mutate()
    }
  }

  return (
    <Modal
      visible={visible}>
      <ModalCard
        className={'w-full max-w-lg'}
        title={t('updatePassword.changePassword')}>
        <ModalSection>
          <div className={'p-4 flex flex-col space-y-4'}>
            <FormField
              errors={validationError}
              errorKey={'password'}
              label={t('updatePassword.newPassword')}>
              <TextInput
                autofocus={true}
                value={password}
                onChange={val => setPassword(val)} />
            </FormField>

            <FormField
              errors={validationError}
              errorKey={'password2'}
              label={t('updatePassword.confirmPassword')}>
              <TextInput
                value={confirmPassword}
                onChange={val => setConfirmPassword(val)} />
            </FormField>
          </div>
        </ModalSection>
        <ModalAction
          saveLabel={t('general.save')}
          isLoading={updatePasswordMutation.isLoading}
          onClose={onClose}
          onSave={handleSubmit} />
      </ModalCard>
    </Modal>
  )

}