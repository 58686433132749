import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { fetchCountries } from '../shared/queries';
import {
  CountryContainer,
  SingleApiResponseV3,
} from '../types';
import SelectInput from './SelectInput';

type CountrySelectorProps = {
  initial: string | number;
  onChange?: (country: CountryOption) => void;
};

type CountryOption = {
  label: string;
  value: string;
};

export default function CountrySelector({
  initial,
  onChange,
}: CountrySelectorProps) {
  const [current, setCurrent] = useState<CountryOption | null>(null);
 

  const { data: countries, isLoading } = useQuery<
    SingleApiResponseV3<CountryContainer>,
    Error
  >(['countries'], () => fetchCountries());

  

   useEffect(() => {
    if (countries && countries.results) {
      const country = countries.results.en[initial];
      if (country){ //&& isDefined(country.id)) {
        setCurrent({
          label: country.name,
          value: country.code,
        });
      }
    }
  }, [initial,countries]);
 
  return (
    <SelectInput
      // menuShouldScrollIntoView={false}
      menuPortalTarget={document.body}
      onChange={(item) => {
        setCurrent(item as CountryOption);
        if (onChange && item) onChange(item as CountryOption);
      }}
      value={current}
      
      options={
          countries? Object.values(countries.results?.en).map((e:any) => {
            return {
              label: e.name,
              value: e.cod,
            }}) 
        :[]
      }
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
    />
  );
}