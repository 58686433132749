export default [
  /* {
    key: "BOOKING",
    cat: ["OTA"],
    name: "Booking.com",
    href: "https://www.booking.com?utm_source=vikey",
    logo: "images/logos/bookinglogo.png",
    token: "HotelID",
    secret: "ConnectionID",
    oAuth: true,
    desc: null,
    ready: true,
    new: false,
    modes: [
      { k: "ROOMS", c: "blue", i: "bed" },
      { k: "HOTEL", c: "blue", i: "home" },
    ],
  }, */
  {
    key: "AIRBNB",
    cat: ["OTA"],
    name: "Airbnb",
    href: "https://www.airbnb.com?utm_source=vikey",
    logo: "images/logos/airbnb.png",
    token: false,
    secret: false,
    oAuth: true,
    desc: "integrationWizard.AirBnbDesc",
    ready: true,
    new: false,
    modes: [{ k: "ROOMS", c: "blue", i: "bed" }],
  },

  {
    key: "WUBOOK",
    cat: ["CM", "PMS"],
    name: "WuBook",
    href: "https://wubook.net?utm_source=vikey",
    logo: "images/logos/wubooklogo.png",
    token: "PropKey",
    secret: "UsernameSpacePassword",
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [{ k: "ROOMS", c: "blue", i: "bed" }],
  },
  {
    key: "ZAK",
    cat: ["CM", "PMS"],
    name: "WuBook Zak",
    href: "https://wubook.net?utm_source=vikey",
    logo: "images/logos/zak-logo.png",
    token: "ApiKey",
    secret: false,
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [{ k: "ROOMS", c: "blue", i: "bed" }],
  },
  // {
  //   key: "OCTORATE",
  //   cat: ["CM", "PMS"],
  //   name: "Octorate",
  //   href: "https://www.octorate.com?utm_source=vikey",
  //   logo: "images/logos/octologo.png",
  //   token: "PropertyId",
  //   secret: false,
  //   oAuth: false,
  //   desc: null,
  //   ready: true,
  //   new: false,
  //   modes: [
  //     { k: "ROOMS", c: "blue", i: "bed" },
  //     { k: "CHANNEL", c: "blue", i: "home" },
  //   ],
  // },
  {
    key: "OCTORATE2",
    cat: ["CM", "PMS"],
    name: "Octorate",
    href: "https://www.octorate.com?utm_source=vikey",
    logo: "images/logos/octologo.png",
    token: "PropertyId",
    secret: false,
    oAuth: true,
    desc: null,
    ready: true,
    new: false,
    modes: [
      { k: "ROOMS", c: "blue", i: "bed" },
      { k: "CHANNEL", c: "blue", i: "home" },
      { k: "PROPS", c: "blue", i: "home" },
    ],
  },
  {
    key: "KROSS",
    cat: ["CM", "PMS"],
    name: "KrossBooking",
    href: "https://www.krossbooking.com?utm_source=vikey",
    logo: "images/logos/krosslogo.png",
    token: "HotelId",
    secret: "UsernameSpacePassword",
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [{ k: "ROOMS", c: "blue", i: "bed" }],
  },
  {
    key: "BEDS24",
    cat: ["CM", "PMS"],
    name: "Beds24",
    href: "https://www.beds24.com?utm_source=vikey",
    logo: "images/logos/beds24logo.png",
    token: "ApiKey",
    secret: "PropKey",
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [
      { k: "ROOMS", c: "blue", i: "home" },
      { k: "ROOMTYPE", c: "blue", i: "bath" },
      { k: "UNITS", c: "blue", i: "bed" },
    ],
  },
  {
    key: "SMOOBU",
    cat: ["CM", "PMS"],
    name: "Smoobu",
    href: "https://www.smoobu.com?utm_source=vikey",
    logo: "images/logos/smoobu-logo.png",
    token: "ApiKey",
    secret: false,
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [{ k: "ROOMS", c: "blue", i: "bed" }],
  },
  {
    key: "GUESTY",
    cat: ["CM", "PMS"],
    name: "Guesty",
    href: "https://www.guesty.com?utm_source=vikey",
    logo: "images/logos/guestylogo.png",
    token: "Token",
    secret: "Secret",
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [
      { k: "ROOMS", c: "blue", i: "bed" },
      { k: "HOTEL", c: "blue", i: "home" },
    ],
  },
  {
    key: "YOURPORTER",
    cat: ["CM", "PMS"],
    name: "Your porter App",
    href: "https://yourporter.com/?utm_source=vikey",
    logo: "images/logos/yourporter.png",
    token: "ClientToken",
    secret: false,
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [{ k: "ROOMS", c: "blue", i: "bath" }],
  },
  {
    key: "AVAIBOOK",
    cat: ["CM", "PMS"],
    name: "AvaiBook",
    href: "https://www.avaibook.com?utm_source=vikey",
    logo: "images/logos/avaibook-logo.png",
    token: "Owner ID",
    secret: "Token",
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [ { k: "ROOMS", c: "blue", i: "bath" },
             { k: "CHANNEL", c: "blue", i: "home" }],
  },
  {
    key: "RENTIPRO",
    cat: ["CM", "PMS"],
    name: "Rentipro",
    href: "https://www.rentipro.com?utm_source=vikey",
    logo: "images/logos/rentiproLogo.png",
    token: "PropertyManagerId",
    secret: false,
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [
      { k: "PROPS", c: "blue", i: "home" },
      { k: "ROOMS", c: "blue", i: "bath" },
    ],
  },
  {
    key: "RENTIPRO",
    cat: ["CM", "PMS"],
    name: "EstarGroup",
    href: "https://www.estargroup.it/?utm_source=vikey",
    logo: "images/logos/estargroupLogo.png",
    token: "PropertyManagerId",
    secret: false,
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [
      { k: "PROPS", c: "blue", i: "home" },
      { k: "ROOMS", c: "blue", i: "bath" },
    ],
  },
  {
    key: "CINQUESTELLE",
    cat: ["CM", "PMS"],
    name: "5stelle*",
    href: "https://www.hotelcinquestelle.cloud/?utm_source=vikey",
    logo: "images/logos/5stelleLogo.png",
    token: "HotelCode",
    secret: false,
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    push_back_guests: true,
    modes: [
      { k: "PROPS", c: "blue", i: "home" },
      { k: "ROOMS", c: "blue", i: "bath" },
    ],
  },
  {
    key: "ROOMCLOUD",
    cat: ["CM", "PMS"],
    name: "RoomCloud",
    href: "http://www.roomcloud.net?utm_source=vikey",
    logo: "images/logos/roomcloud_logo.jpg",
    token: "Username",
    secret: "Password",
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [{ k: "ROOMS", c: "blue", i: "bath" }],
  },
  {
    key: "BNBSUITE",
    cat: ["CM", "PMS"],
    name: "BNBsuite",
    href: "http://www.bnbsuite.com?utm_source=vikey",
    logo: "images/logos/bnbsuiteLogo.png",
    token: "ApiKey",
    secret: "PropKey",
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [
      { k: "ROOMS", c: "blue", i: "home" },
      { k: "ROOMTYPE", c: "blue", i: "bath" },
      { k: "UNITS", c: "blue", i: "bed" },
    ],
  },
  {
    key: "PASSEPARTOUT2",
    cat: ["CM", "PMS"],
    name: "Passepartout",
    href: "https://www.passepartout.net/?utm_source=vikey",
    logo: "images/logos/passepartout_logo.png",
    token: "Url",
    secret: "UsernameSpacePassword",
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    push_back_guests: true,
    modes: [
      { k: "PROPS", c: "blue", i: "home" },
      { k: "ROOMS", c: "blue", i: "bath" },
    ],
  },
  {
    key: "BEDZZLE",
    cat: ["CM", "PMS"],
    name: "Bedzzle",
    href: "https://www.bedzzle.com/?utm_source=vikey",
    logo: "images/logos/bedzzle_logo.png",
    token: "ApiToken",
    secret: false,
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [
      //{k: 'PROPS', c: 'blue',    t: $translate.instant('COM.ModeIntegrBProp'), i: 'home'},
      { k: "ROOMS", c: "blue", i: "bath" },
    ],
  },
  {
    key: "IRESERVATION",
    cat: ["CM", "PMS"],
    name: "Bed and Breakfast.it",
    href: "https://www.bed-and-breakfast.it/?utm_source=vikey",
    logo: "images/logos/bedandbreakfast_logo.png",
    token: "PropKey",
    secret: "UsernameSpacePassword",
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [
      //{k: 'PROPS', c: 'blue',    t: $translate.instant('COM.ModeIntegrBProp'), i: 'home'},
      { k: "ROOMS", c: "blue", i: "bath" },
    ],
  },
  {
    key: "BEDDY",
    cat: ["CM", "PMS"],
    name: "Beddy",
    href: "https://www.beddy.io/?utm_source=vikey",
    logo: "images/logos/beddy_logo.png",
    token: "Hotel code",
    secret: false,
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [
      //{k: 'PROPS', c: 'blue',    t: $translate.instant('COM.ModeIntegrBProp'), i: 'home'},
      { k: "ROOMS", c: "blue", i: "bath" },
      { k: "CHANNEL", c: "blue", i: "home" },
    ],
  },
  {
    key: "MAGELLANO",
    cat: ["CM", "PMS"],
    name: "Magellano - Software per Affitti Turistici",
    href: "https://magellano.mainapps.com/?utm_source=vikey",
    logo: "images/logos/magellano_logo.png",
    token: "ApiToken",
    secret: false,
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [
      //{k: 'PROPS', c: 'blue',    t: $translate.instant('COM.ModeIntegrBProp'), i: 'home'},
      { k: "ROOMS", c: "blue", i: "bath" },
    ],
  },
  {
    key: "SIMPLE",
    cat: ["CM", "PMS"],
    name: "simpleBooking",
    href: "https://www.simplebooking.travel/?utm_source=vikey",
    logo: "images/logos/simplebooking_logo.png",
    token: "HotelId",
    secret: "UsernameSpacePassword",
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [{ k: "ROOMS", c: "blue", i: "bath" }],
  },
  {
    key: "MEWS",
    cat: ["CM", "PMS"],
    name: "Mews",
    href: "https://www.mews.com/?utm_source=vikey",
    logo: "images/logos/mews_logo.png",
    token: "ClientToken",
    secret: false,
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    push_back_guests: true,
    modes: [{ k: "ROOMS", c: "blue", i: "bath" }],
  },
  {
    key: "XENION",
    cat: ["CM", "PMS"],
    name: "Xenion",
    href: "https://www.xenion.it/?utm_source=vikey",
    logo: "images/logos/xenion_logo.png",
    token: "PropertyId",
    secret: "ApiKey",
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [{ k: "ROOMS", c: "blue", i: "bath" }],
  },
  {
    key: "RENTLIO",
    cat: ["CM", "PMS"],
    name: "Rentlio",
    href: "https://rentl.io/en?utm_source=vikey",
    logo: "images/logos/rentlio.png",
    token: false,
    secret: "ApiKey",
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [
      { k: "PROPS", c: "blue", i: "home" },
      { k: "ROOMS", c: "blue", i: "bath" },
    ],
  },
  {
    key: "SLOPE",
    cat: ["CM", "PMS"],
    name: "Slope",
    href: "https://www.slope.it/?utm_source=vikey",
    logo: "images/logos/slope_logo.png",
    token: "ApiKey",
    secret: false,
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    push_back_guests: true,
    modes: [
      { k: "CHANNEL", c: "blue", i: "home" },
      { k: "ROOMS", c: "blue", i: "bath" },
    ],
  },
  {
    key: "IPERBOOKING",
    cat: ["CM"],
    name: "iperbooking",
    href: "https://www.iperbooking.com/?utm_source=vikey",
    logo: "images/logos/iperbooking.png",
    token: "HotelId",
    secret: "UsernameSpacePassword",
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [{ k: "ROOMS", c: "blue", i: "bath" }],
  },
  {
    key: "BOOKNOWHOTEL",
    cat: ["CM"],
    name: "BookNowHotel",
    href: "https://www.booknowhotel.com/?utm_source=vikey",
    logo: "images/logos/booknowhotel.png",
    token: "HotelId",
    secret: "UsernameSpacePassword",
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [{ k: "ROOMS", c: "blue", i: "bath" }],
  },
  {
    key: "CIAOBOOKING",
    cat: ["CM"],
    name: "CiaoBooking",
    href: "https://ciaobooking.com/?utm_source=vikey",
    logo: "images/logos/ciaobooking.png",
    token: "Username",
    secret: "Password",
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [
      { k: "PROPS", c: "blue", i: "home" },
      { k: "ROOMS", c: "blue", i: "bath" },
    ],
  },
  {
    key: "E4JCONNECT",
    cat: ["CM", "PMS"],
    name: "e4jconnect",
    href: "https://e4jconnect.com/?utm_source=vikey",
    logo: "images/logos/e4jconnect_logo.png",
    token: "WebSiteUrl",
    secret: "UsernameSpacePassword",
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [{ k: "ROOMS", c: "blue", i: "bath" }],
  },
  {
    key: "ICNEA",
    cat: ["CM"],
    name: "icnea",
    href: "https://icnea.it/?utm_source=vikey",
    logo: "images/logos/icnea_logo.png",
    token: false,
    secret: false,
    oAuth: false,
    desc: null,
    ready: false,
    new: false,
    modes: [{ k: "ROOMS", c: "blue", i: "bath" }],
  },
  {
    key: "INRECEPTION",
    cat: ["CM"],
    name: "inReception",
    href: "https://www.inreception.it/?utm_source=vikey",
    logo: "images/logos/logo_inreception.png",
    token: "HotelId",
    secret: "UsernameSpacePassword",
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    push_back_guests: true,
    modes: [{ k: "CHANNEL", c: "blue", i: "home" }],
  },
  {
    key: "CLOUDBEDS",
    cat: ["CM"],
    name: "Cloudbeds",
    href: "https://www.cloudbeds.com/?utm_source=vikey",
    logo: "images/logos/cloudbeds_logo.jpg",
    token: "Code",
    secret: "ConnectionID",
    oAuth: true,
    desc: null,
    ready: true,
    new: false,
    push_back_guests: true,
    push_back_guest_open: true,
    modes: [{ k: "CHANNEL", c: "blue", i: "home" }],
  },
  {
    key: "EPRENOTAZIONI",
    cat: ["CM", "PMS"],
    name: "ePrenotazioni",
    href: "https://www.eprenotazioni.it/?utm_source=vikey",
    logo: "images/logos/eprenotazioni_logo.png",
    token: false,
    secret: false,
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [{ k: "ROOMS", c: "blue", i: "bath" }],
  },
  {
    key: "LODGIFY",
    cat: ["CM", "PMS"],
    name: "Lodgify",
    href: "https://www.lodgify.com/?utm_source=vikey",
    logo: "images/logos/lodgify_logo.png",
    token: "ApiToken",
    secret: false,
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [
      { k: "PROPS", c: "blue", i: "home" },
      { k: "ROOMS", c: "blue", i: "bath" },
    ],
  },
  {
    key: "HOTELBB",
    cat: ["CM", "PMS"],
    name: "Hotel BB",
    href: "https://www.hotel.bb/?utm_source=vikey",
    logo: "images/logos/hotelbb_logo.png",
    token: "HotelId",
    secret: "UsernameSpacePassword",
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [{ k: "ROOMS", c: "blue", i: "bath" }],
  },
  {
    key: "MAGARENTAL",
    cat: ["CM", "PMS"],
    name: "Magarental",
    href: "https://www.magarental.ch/?utm_source=vikey",
    logo: "images/logos/magarental_logo.png",
    token: "UrlProperties",
    secret: "UrlReservations",
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [{ k: "PROPS", c: "blue", i: "home" }],
  },
  {
    key: "AVANTIO",
    cat: ["CM", "PMS"],
    name: "Avantio",
    href: "https://www.avantio.it/?utm_source=vikey",
    logo: "images/logos/avantio_logo.png",
    token: "Username",
    secret: "Password",
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    push_back_guests: true,
    modes: [{ k: "CHANNEL", c: "blue", i: "home" }],
  },
  {
    key: "BOOKINGEXPERT",
    cat: ["CM", "PMS"],
    name: "Booking Expert",
    href: "https://www.bookingexpert.com/?utm_source=vikey",
    logo: "images/logos/bookingexpert_logo.png",
    token: "HotelID",
    secret: false,
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [{ k: "CHANNEL", c: "blue", i: "home" }],
  },
  {
    key: "EVIIVO",
    cat: ["CM", "PMS"],
    name: "Eviivo",
    href: "https://eviivo.com/?utm_source=vikey",
    logo: "images/logos/eviivo_logo.jpg",
    token: "Shortname",
    secret: "ClientidSpaceClientsecret",
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [{ k: "ROOMS", c: "blue", i: "bath" }],
  },
  {
    key: "NEWBOOK",
    cat: ["CM", "PMS"],
    name: "newbook",
    href: "https://www.newbook.cloud/?utm_source=vikey",
    logo: "images/logos/newbook_logo.png",
    token: "ApiKey",
    secret: false,
    oAuth: false,
    desc: null,
    ready: true,
    push_back_guests: true,
    push_back_guest_open: true,
    new: false,
    modes: [
      { k: "ROOMS", c: "blue", i: "bath" },
      { k: "PROPS", c: "blue", i: "home" },
    ],
  },
  {
    key: "HOTELINCLOUD",
    cat: ["CM", "PMS"],
    name: "Hotel in Cloud",
    href: "https://www.hotelincloud.com/?utm_source=vikey",
    logo: "images/logos/hotelincloud_logo.jpg",
    token: "PropKey",
    secret: false,
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [
      { k: "CHANNEL", c: "blue", i: "home" },
      { k: "ROOMS", c: "blue", i: "bath" },
    ],
  },
  {
    key: "AMENITIZ",
    cat: ["CM", "PMS"],
    name: "Amenitiz",
    href: "https://www.amenitiz.com/?utm_source=vikey",
    logo: "images/logos/amenitiz_logo.jpeg",
    token: "PropKey",
    secret: false,
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [
      { k: "CHANNEL", c: "blue", i: "home" },
      { k: "ROOMS", c: "blue", i: "bath" }],
  },
  {
    key: "DIRECTHOLIDAY",
    cat: ["CM", "PMS"],
    name: "Direct holiday",
    href: "https://directholiday.it/?utm_source=vikey",
    logo: "images/logos/directholiday.png",
    token: false,
    secret: false,
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [{ k: "ROOMS", c: "blue", i: "bath" }],
  },
  {
    key: "BBPLANNER",
    cat: ["CM", "PMS"],
    name: "BBPLANNER",
    href: "https://bbplanner.com/?utm_source=vikey",
    logo: "images/logos/bbplanner.png",
    token: "token",
    secret: false,
    oAuth: true,
    desc: null,
    ready: true,
    new: false,
    modes: [{ k: "ROOMS", c: "blue", i: "bath" }],
  },
  {
    key: "VRBOOKINGS",
    cat: ["CM", "PMS"],
    name: "VRBookings",
    href: "https://www.vrbookings.com/?utm_source=vikey",
    logo: "images/logos/vrbookings.jpeg",
    token: "WebSiteUrl",
    secret: "UsernameSpacePassword",
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    push_back_invdata: true,
    modes: [{ k: "CHANNEL", c: "blue", i: "home" }],
  },
  {
    key: "RMS",
    cat: ["CM", "PMS"],
    name: "RMS",
    href: "https://www.rmscloud.com/?utm_source=vikey",
    logo: "images/logos/rmslogo.png",
    token: "clientId",
    secret: "clientPassword",
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    push_back_invdata: false,
    push_back_guest_open: true,
    modes: [{ k: "CHANNEL", c: "blue", i: "home" },
            { k: "ROOMS", c: "blue", i: "home" },
            { k: "PROPS", c: "blue", i: "home" }],
  },
  {
    key: "QUOVAI",
    cat: ["CM", "PMS"],
    name: "Quovai",
    href: "https://www.quovai.com/?utm_source=vikey",
    logo: "images/logos/quovai.png",
    token: "Username",
    secret: 'Password',
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [
      { k: "CHANNEL", c: "blue", i: "home" },
      { k: "ROOMS", c: "blue", i: "bath" },
    ],
  },
  {
    key: "SITEMINDER",
    cat: ["CM", "PMS"],
    name: "siteminder",
    href: "https://www.siteminder.com/?utm_source=vikey",
    logo: "images/logos/siteminder.png",
    token: false,
    secret: false,
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [{ k: "ROOMS", c: "blue", i: "bath" },
            { k: "CHANNEL", c: "blue", i: "home" }],
  },
  {
    key: "ROOMRACOON",
    cat: ["CM", "PMS"],
    name: "roomracoon",
    href: "https://roomraccoon.it/?utm_source=vikey",
    logo: "images/logos/roomracoon.png",
    token: false,
    secret: false,
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [{ k: "ROOMS", c: "blue", i: "bath" },
            { k: "CHANNEL", c: "blue", i: "home" }],
  },
  {
    key: "BOOKINGSYNC",
    cat: ["CM", "PMS"],
    name: "bookingsync",
    href: "https://www.bookingsync.com/?utm_source=vikey",
    logo: "images/logos/bookingsync.png",
    token: true,
    secret: true,
    oAuth: true,
    desc: null,
    ready: true,
    new: false,
    modes: [{ k: "ROOMS", c: "blue", i: "home" }],
  },
  {
    key: "SUITE5",
    cat: ["CM", "PMS"],
    name: "suite5",
    href: "https://www.ericsoft.com/it/?utm_source=vikey",
    logo: "images/logos/suite5-1.png",
    token: true,
    secret: false,
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    modes: [{ k: "ROOMS", c: "blue", i: "bath" },
            { k: "CHANNEL", c: "blue", i: "home" }],
  },
  {
    key: "HOTELCUBE",
    cat: ["CM", "PMS"],
    name: "hotelcube",
    href: "https://www.hotelcube.eu/?utm_source=vikey",
    logo: "images/logos/hotelcube.png",
    token: 'Token',
    secret: 'Url',
    oAuth: false,
    desc: null,
    ready: true,
    new: false,
    push_back_guests: true,
    modes: [{ k: "ROOMS", c: "blue", i: "bath" }],
  },
  {
    key: "HOSPITABLE",
    cat: ["CM", "PMS"],
    name: "hospitable",
    href: "https://www.hospitable.com/?utm_source=vikey",
    logo: "images/logos/hospitable.png",
    token: "token",
    secret: false,
    oAuth: true,
    desc: null,
    ready: true,
    new: false,
    modes: [{ k: "PROPS", c: "blue", i: "bath" }],
  },
];
