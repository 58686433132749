import { motion } from 'framer-motion'

type CircleSpinnerProps = {
  width?: number,
  height?: number,
  color?: 'white' | 'primary' | 'negative' | 'success' | 'info',
  inverted?: boolean
}

export default function CircleSpinner ({
  width = 20,
  height = 20,
  color = 'white',
  inverted = false
} : CircleSpinnerProps) {

  function getStyle ():any {

    const colors = {
      bg: 'rgba(255,255,255,0.4)',
      border: 'rgb(255,255,255)'
    }

    switch (color) {
      case 'white':
        colors.bg = 'rgba(255,255,255,0.4)'
        colors.border = 'rgb(255,255,255)'
        break
      case 'primary':
        colors.bg = '#00BDE140'
        colors.border = '#00BDE1'
        break
      case 'negative':
        colors.bg = 'rgba(220, 38, 38, 0.4)'
        colors.border = '#dc2626'
        break
    }

    return {
      display: "block",
      width: width + 'px',
      height: height + 'px',
      border: (width / 5) + "px solid " + colors.bg,
      borderTop: (width / 5) +"px solid " + colors.border,
      borderRadius: "50%",
      position: "absolute",
      boxSizing: "border-box",
      top: 0,
      left: 0
    }
  }
 
  return (
    <div style={{
      position: "relative",
      width: width + 'px',
      height: height + 'px',
      boxSizing: "border-box"
    }}>
      <motion.span
        style={getStyle()}
        animate={{ rotate: 360 }}
        transition={{
          loop: Infinity,
          ease: 'linear',
          duration: 1,
        }}>
      </motion.span>
    </div>
  )

}