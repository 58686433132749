import { motion } from "framer-motion";
import React, { useState } from "react";
import { DayPicker } from "react-day-picker";
import { useTranslation } from "react-i18next";
import FormField from "../FormField";
import TextInput from "../TextInput";
import dayjs from "dayjs";
import Popover from "../Popover";
import Checkbox from "../Checkbox";
import { useMutation, useQuery } from "react-query";
import { changeRestrictions, fetchRestrictions } from "../../shared/queries";
import CircleSpinner from "../CircleSpinner";
import Button from "../Button";
import ReactSwitch from "react-switch";
import { toast } from "react-toastify";

type Props = {
  openTab: boolean;
  closeTab: (r: any) => void;
  startDate: string;
};

const SideBarChannel = (props: Props) => {
  const { t } = useTranslation();
  const { openTab, closeTab, startDate } = props;
  const [isLoadingRestriction, setIsLoadingRestriction] = useState(true);
  const [rateToEdit, setRateToEdit] = useState<
    { propertyId: string; rateId: string }[]
  >([]);
  const [days, setDays] = useState<any>([
    { day: "mo", value: true },
    { day: "tu", value: true },
    { day: "we", value: true },
    { day: "th", value: true },
    { day: "fr", value: true },
    { day: "sa", value: true },
    { day: "su", value: true },
  ]);
  const [rules, setRules] = useState<any>([
    {
      key: "rate",
      rule: t("channelManager.rate_plan.rate"),
      checked: false,
      value: "",
    },
    {
      key: "stop_sell",
      rule: t("channelManager.rate_plan.stopSell"),
      checked: false,
      value: false,
    },
    {
      key: "closed_to_arrival",
      rule: t("channelManager.rate_plan.closedToArrival"),
      checked: false,
      value: false,
    },
    {
      key: "closed_to_departure",
      rule: t("channelManager.rate_plan.closedToDeparture"),
      checked: false,
      value: false,
    },
    {
      key: "min_stay_arrival",
      keyExtra: "min_stay_through",
      rule: t("channelManager.rate_plan.minStayArrival"),
      checked: false,
      value: "",
    },
    {
      key: "max_stay",
      rule: t("channelManager.rate_plan.maxStay"),
      checked: false,
      value: "",
    },
  ]);
  const [selectedDays, setSelectedDays] = useState<any>();
  const [ratePlanList, setRatePlanList] = useState<any>([]);

  const closeBarActions = () => {
    setSelectedDays(null);
    setRatePlanList([]);
    setRateToEdit([]);
    setDays([
      { day: "mo", value: true },
      { day: "tu", value: true },
      { day: "we", value: true },
      { day: "th", value: true },
      { day: "fr", value: true },
      { day: "sa", value: true },
      { day: "su", value: true },
    ]);
    setRules([
      {
        key: "rate",
        rule: t("channelManager.rate_plan.rate"),
        checked: false,
        value: "",
      },
      {
        key: "stop_sell",
        rule: t("channelManager.rate_plan.stopSell"),
        checked: false,
        value: false,
      },
      {
        key: "closed_to_arrival",
        rule: t("channelManager.rate_plan.closedToArrival"),
        checked: false,
        value: false,
      },
      {
        key: "closed_to_departure",
        rule: t("channelManager.rate_plan.closedToDeparture"),
        checked: false,
        value: false,
      },
      {
        key: "min_stay_arrival",
        keyExtra: "min_stay_through",
        rule: t("channelManager.rate_plan.minStayArrival"),
        checked: false,
        value: "",
      },
      {
        key: "max_stay",
        rule: t("channelManager.rate_plan.maxStay"),
        checked: false,
        value: "",
      },
    ]);
  };

  useQuery(
    ["getRatePlanRestrictions", startDate],
    () => fetchRestrictions(14, startDate, null),
    {
      onSuccess: (data) => {
        const ratePlanList: any = [];
        Object.keys(data.results).map((local) => {
          if (local !== "tot") {
            const localTemp = data.results[local];
            const localNameTemp = localTemp.name;
            const property_id = localTemp.property_id;
            const rateListName: any[] = [];
            Object.keys(localTemp).map((desc) => {
              if (desc === "per_person" || desc === "per_room") {
                const descTemp = localTemp[desc as keyof typeof localTemp];
                descTemp.map((ratePlan: any) => {
                  Object.keys(ratePlan).map((rateId) => {
                    if (ratePlan[rateId].is_primary) {
                      rateListName.push({
                        rateId: rateId,
                        title: ratePlan[rateId].title,
                        type: desc,
                        apply: false,
                      });
                    }
                    return false;
                  });
                  return false;
                });
              }
              return false;
            });
            ratePlanList.push({
              aptName: localNameTemp,
              rateList: rateListName,
              propertyId: property_id,
            });
          }
          return false;
        });
        setRatePlanList(ratePlanList);
        setIsLoadingRestriction(false);
      },
    }
  );
  const bulkUpdateMutation = useMutation(
    (data: any) => changeRestrictions(data),
    {
      onSuccess: () => {
        toast.success(t("general.requestCompleted"));
        closeBarActions();
        closeTab(!openTab);
      },
      onError: () => {
        toast.error(t("general.requestError"));
      },
    }
  );
  const changeRateToEdit = (
    type: "ADD" | "REMOVE",
    data: { rateId: string; propertyId: string }
  ) => {
    if (type === "ADD") {
      setRateToEdit((prev) => {
        if (prev.find((d) => d.rateId === data.rateId)) {
          return [...prev];
        }
        return [...prev, data];
      });
    }
    if (type === "REMOVE") {
      setRateToEdit((prev) => {
        const newData = prev.filter((d) => d.rateId !== data.rateId);
        return newData;
      });
    }
  };
  const getDay = (daySelected: any) => {
    switch (daySelected) {
      case "mo":
        return t("channelManager.rate_plan.monday");
      case "tu":
        return t("channelManager.rate_plan.tuesday");
      case "we":
        return t("channelManager.rate_plan.wednesday");
      case "th":
        return t("channelManager.rate_plan.thursday");
      case "fr":
        return t("channelManager.rate_plan.friday");
      case "sa":
        return t("channelManager.rate_plan.saturday");
      case "su":
        return t("channelManager.rate_plan.sunday");
      default:
        return t("channelManager.rate_plan.monday");
    }
  };
  const saveDataSideBar = () => {
    if (
      !selectedDays ||
      !selectedDays.from ||
      selectedDays.from === "undefined"
    ) {
      toast.error(t("general.error"));
      return;
    }
    const startDate = dayjs(selectedDays.from).format("YYYY-MM-DD");
    const endDate = dayjs(selectedDays.to).format("YYYY-MM-DD");
    const starterData: any = { values: [] };
    const dates: any = [];
    days
      .filter((d: any) => d.value)
      .forEach((d: any) => {
        dates.push(d.day);
      });

    const newRules = rules.filter((rule: any) => rule.checked);
    const rulesObj: any = {};
    newRules.forEach((newRule: any) => {
      if (newRule.key === "rate") {
        rulesObj[newRule.key] = parseFloat(newRule.value) * 100;
        if (newRule.keyExtra) {
          rulesObj[newRule.keyExtra] = parseFloat(newRule.value) * 100;
        }
      } else {
        rulesObj[newRule.key] = newRule.value;
        if (newRule.keyExtra) {
          rulesObj[newRule.keyExtra] = newRule.value;
        }
      }
    });
    if (startDate === endDate || dayjs(endDate).isBefore(dayjs(startDate))) {
      rateToEdit.forEach((rate) => {
        starterData.values.push({
          ...rulesObj,
          property_id: rate.propertyId,
          rate_plan_id: rate.rateId,
          days: dates,
          date: startDate ?? "",
        });
      });
    } else {
      rateToEdit.forEach((rate) => {
        starterData.values.push({
          ...rulesObj,
          property_id: rate.propertyId,
          rate_plan_id: rate.rateId,
          days: dates,
          date_to: endDate ?? "",
          date_from: startDate ?? "",
        });
      });
    }
    bulkUpdateMutation.mutate(starterData);
  };

  return (
    <motion.div
      initial={{ width: "100vw", x: "100vw" }}
      animate={{ width: "100vw", x: "0vw" }}
      transition={{ duration: 0.5, origin: 1 }}
      className="fixed inset-0 overflow-hidden"
      style={{zIndex: 1000}}
    >
      <div className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      {/* <!-- Sidebar Content --> */}
      <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex">
        <div className="w-screen max-w-xl">
          <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-auto">
            {isLoadingRestriction ? (
              <div className={"flex items-center w-full justify-center"}>
                <CircleSpinner color={"primary"}></CircleSpinner>

                <div className={"ml-2 text-gray-600"}>
                  {t("apartmentContent.loadingContent")}
                </div>
              </div>
            ) : (
              <>
                {/* <!-- Sidebar Header --> */}
                <div className="flex items-center justify-between px-4">
                  <h2 className="text-xl font-semibold text-black">
                    {t("channelManager.bulkUpdate")}
                  </h2>
                  <button
                    className="text-gray-500 hover:text-gray-700"
                    onClick={() => closeTab(!openTab)}
                  >
                    <span className="sr-only">Close</span>
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                  </button>
                </div>
                {/* <!-- Search Input --> */}
                <Popover
                  className={"p-4 flex flex-col space-y-4"}
                  // placement={'bottom-start'}
                  renderContent={() => (
                    <div className="mt-4 px-4 mx-auto">
                      <DayPicker
                        mode="range"
                        onSelect={setSelectedDays}
                        selected={selectedDays}
                        className={"w-full rounded-2xl"}
                      />
                    </div>
                  )}
                >
                  <FormField label={`${t("channelManager.affectedDates")}:`}>
                    <TextInput
                      value={
                        selectedDays
                          ? `${dayjs(selectedDays.from).format(
                              "DD-MM-YYYY"
                            )} --> ${dayjs(selectedDays.to).format(
                              "DD-MM-YYYY"
                            )}`
                          : t("channelManager.selectDateRange")
                      }
                    />
                  </FormField>
                </Popover>
                <div className="mt-4 px-4 flex flex-wrap h-auto text-xs gap-1">
                  {days.map((d: any, idx: number) => {
                    return (
                      <Checkbox
                        key={idx}
                        
                        className="rounded-xl p-0.5 md:text-sm text-xs border max-w-min"
                        label={getDay(d.day)}
                        size="xs"
                        checked={d.value}
                        onChange={() => {
                          const newValueTemp = [...days];
                          newValueTemp[idx].value = !newValueTemp[idx].value;
                          setDays(newValueTemp);
                        }}
                      />
                    );
                  })}
                </div>
                {/* <!-- Sidebar Content --> */}
                <div className="mt-4 px-4 h-full">
                  <FormField
                    label={`${t("channelManager.affectedRestrictions")}:`}
                  >
                    <div className="grid grid-cols-2 space-y-2 text-xs">
                      {rules.map((ru: any, idx: number) => (
                        <>
                          <Checkbox
                            
                            className="rounded-xl p-0.5 md:text-sm text-xs w-full"
                            label={t(ru.rule)}
                            size="sm"
                            checked={ru.checked}
                            onChange={() => {
                              const prevTemp = [...rules];
                              prevTemp[idx].checked = !prevTemp[idx].checked;
                              setRules(prevTemp);
                            }}
                          />
                          {typeof ru.value === "boolean" ? (
                            <ReactSwitch
                              disabled={!ru.checked}
                              onChange={() => {
                                const prevTemp = [...rules];
                                prevTemp[idx].value = !prevTemp[idx].value;
                                setRules(prevTemp);
                              }}
                              checked={ru.value}
                            ></ReactSwitch>
                          ) : (
                            <TextInput
                              type="number"
                              value={ru.value}
                              disabled={!ru.checked}
                              onChange={(val: string) => {
                                const prevTemp = [...rules];
                                prevTemp[idx].value = val;
                                setRules(prevTemp);
                              }}
                            />
                          )}
                        </>
                      ))}
                    </div>
                  </FormField>
                </div>
                <div className="mt-4 px-4 h-full">
                  <FormField label={`${t("channelManager.affectedRooms")}:`}>
                    {ratePlanList.map((rate: any, idx: number) => {
                      return (
                        <FormField
                          key={idx}
                          label={`${rate.aptName}:`}
                          className="text-xs ml-10"
                        >
                          {rate.rateList.map((list: any, iDx: number) => {
                            return (
                              <Checkbox
                                key={iDx}
                                className="rounded-xl p-0.5 md:text-sm text-xs w-full ml-10"
                                
                                label={list.title}
                                size="xs"
                                checked={list.apply}
                                onChange={() => {
                                  const prevTemp = [...ratePlanList];
                                  if (prevTemp[idx].rateList[iDx].apply) {
                                    changeRateToEdit("REMOVE", {
                                      rateId:
                                        prevTemp[idx].rateList[iDx].rateId,
                                      propertyId: prevTemp[idx].propertyId,
                                    });
                                  } else {
                                    changeRateToEdit("ADD", {
                                      rateId:
                                        prevTemp[idx].rateList[iDx].rateId,
                                      propertyId: prevTemp[idx].propertyId,
                                    });
                                  }
                                  prevTemp[idx].rateList[iDx].apply =
                                    !prevTemp[idx].rateList[iDx].apply;
                                  setRatePlanList(prevTemp);
                                }}
                              />
                            );
                          })}
                        </FormField>
                      );
                    })}
                  </FormField>
                </div>

                {/* <!-- Sidebar Footer --> */}
                <div className="mt-6 px-4">
                  <Button
                    className="flex justify-center items-center bg-cyan-600 text-white rounded-md text-md p-2 gap-1 w-full"
                    onClick={() => {
                      saveDataSideBar();
                    }}
                  >
                    {t("general.save")}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </motion.div>
  );
};

export default SideBarChannel;

/* SINGLE UPDATE
                 {
                    "values": [
                        {
                            "property_id": "8e97ba32-1efd-4b0a-abdc-9d9a16dfecc7",
                            "rate_plan_id": "24099b89-9c57-4891-9cd2-96a0dfcada39",
                            "date": "2024-09-14",
                            "rate": "80"
                        }
                      ]
                    }
                 */
