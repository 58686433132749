import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Select from "react-select";
import DateRangePicker from '../DateRangePicker'
import { fetchAllLocals } from "../../shared/queries";
import dayjs from "dayjs";
import TextInput from "../TextInput";
import Checkbox from "../Checkbox";
import { getOldToken } from "../../shared/utils/tokenStore";
import SelectInput from "../SelectInput";
import { useTranslation } from "react-i18next";
import { BaseSelectItem } from "../../types";


const statuses = [
  { value: '-', label: 'Tutti' },
  { value: 'NONEED', label: 'Non richiesto' },
  { value: 'WAIT', label: 'Non ancora eseguito' },
  { value: 'WAIT_DEPOSIT', label: 'Attesa della caparra' },
  { value: 'PEND', label: 'Da confermare' },
  { value: 'OK', label: 'Confermato' }
]

export default function ExportReservations ({
  onChange
} : {
  onChange: (data: any) => void
}) {

  const {
    data: locals,
    isFetching: isFetchingLocals
  } = useQuery('apartments', fetchAllLocals)

  const { t } = useTranslation()


  const [fromDate, setFromDate] = useState<string>(dayjs().format('YYYY-MM-DD'))
  const [toDate, setToDate] = useState<string>(dayjs().add(1, 'day').format('YYYY-MM-DD'))
  const [city, setCity] = useState<string>()
  const [checkinStatus, setCheckinStatus] = useState<string>()
  const [localKey, setLocalKey] = useState<string>()
  const [includeBilling, setIncludeBilling] = useState<boolean>(false)
  const [includeDocuments, setIncludeDocuments] = useState<boolean>(false)
  const [includeTax, setIncludeTax] = useState<boolean>(false)
  const [includeExtra, setIncludeExtra] = useState<boolean>(false)

  useEffect(() => {
    onChange({
      action: 'RESERVATIONSCSV',
      date_from: fromDate,
      date_to: toDate,
      do_invoice: includeBilling ? 1 : 0,
      do_docs: includeDocuments ? 1 : 0,
      do_extra: includeExtra ? 1 : 0,
      do_citytax: includeTax ? 1 : 0,
      checkin_status: checkinStatus,
      local_key: localKey,
      city: city,
      token: getOldToken()
    })
  }, [
    fromDate,
    toDate,
    city,
    includeBilling,
    includeDocuments,
    includeExtra,
    includeTax,
    checkinStatus,
    localKey,
    // city
  ])

  return (
    <div className={'p-4'}>
      <div className="text-lg font-semibold">
        {t('export.reservations')}
      </div>

      <div className="mt-4 text-gray-600 text-sm">
        {t('export.reservationsDesc')}
        
      </div>

      <div className="mt-6 flex flex-col space-y-4">
        
        <div className="flex items-center font-medium space-x-8">
          <div className="w-44">
           {t('export.dateRange')}
          </div>
          <div className="flex-1">
            <DateRangePicker
              inputFormat={'YYYY-MM-DD'}
              displayFormat={'DD-MM-YYYY'}
              outputFormat={'DD-MM-YYYY'}
              size={'sm'}
              value={fromDate + ' -> ' + toDate}
              onChange={value => {
                const { from, to } = value as any
                setFromDate(dayjs(from).format('YYYY-MM-DD'))
                setToDate(dayjs(to).format('YYYY-MM-DD'))
              }} />
          </div>
        </div>

        <div className="flex items-center font-medium space-x-8">
          <div className="w-44">
            {t('reservations.checkinStatus')}
          </div>
          <div className="flex-1">
            <SelectInput<BaseSelectItem, false>
              menuPortalTarget={document.body}
              styles={{
                container: base => ({ ...base, zIndex: 999 }),
                menuPortal: base => ({ ...base, zIndex: 999 })
              }}
              menuShouldScrollIntoView={false}
              onChange={item => {
                if (item) setCheckinStatus(item.value)
              }}
              options={statuses} />
          </div>
        </div>

        <div className="flex items-center font-medium space-x-8">
          <div className="w-44">
            Città
          </div>
          <div className="flex-1">
            <TextInput value={city} onChange={val => setCity(val)} className='rounded-2xl'/>
          </div>
        </div>

        <div className="flex items-center font-medium space-x-8">
          <div className="w-44">
            Appartamento
          </div>
          <div className="flex-1">
            <SelectInput<BaseSelectItem, false>
              menuPortalTarget={document.body}
              styles={{
                container: base => ({ ...base, zIndex: 999 }),
                menuPortal: base => ({ ...base, zIndex: 999 })
              }}
              menuShouldScrollIntoView={false}
              onChange={item => {
                if (item) setLocalKey(item.value)
              }}
              options={(locals && locals.data) ? locals.data.map(local => {
                return {
                  label: local.name,
                  value: local.local_key
                }
              }) : [] } />
          </div>
        </div>

        <div className="font-bold mt-6">
          {t('export.moreElement')}
        </div>

        <Checkbox
          onChange={() => setIncludeBilling(!includeBilling)}
          checked={includeBilling}
          label={t('export.includeBillingData')} />
        
        <Checkbox
          onChange={() => setIncludeDocuments(!includeDocuments)}
          checked={includeDocuments}
          label={t('export.includeDocs')} />

        <Checkbox
          onChange={() => setIncludeTax(!includeTax)}
          checked={includeTax}
          label={t('export.includeTax')} />

        <Checkbox
          onChange={() => setIncludeExtra(!includeExtra)}
          checked={includeExtra}
          label={t('export.includeExtra')} />
      </div>
    </div>
  )

}
