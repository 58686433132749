import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SelectInput from "../SelectInput";
import { BaseSelectItem } from "../../types";
import Button from "../Button";
import { timesChannex } from "../../shared/utils/formatting";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { fetchLastMinute, saveLastMinute } from "../../shared/queries";
import TextInput from "../TextInput";

type Props = {
  user_key: any
};

const EditProperty = (props: Props) => {
  const { user_key } = props;
  const { t } = useTranslation();
  const daysChannex = [
    {
      label: t("general.sameDay"),
      value: 0,
    },
    {
      label: `2 ${t("general.days")}`,
      value: 1,
    },
    {
      label: `3 ${t("general.days")}`,
      value: 2,
    },
    {
      label: `4 ${t("general.days")}`,
      value: 3,
    },
    {
      label: `5 ${t("general.days")}`,
      value: 4,
    },
    {
      label: `6 ${t("general.days")}`,
      value: 5,
    },
    {
      label: `7 ${t("general.days")}`,
      value: 6,
    },
    {
      label: `8 ${t("general.days")}`,
      value: 7,
    },
    {
      label: `9 ${t("general.days")}`,
      value: 8,
    },
    {
      label: `10 ${t("general.days")}`,
      value: 9,
    },
    {
      label: `11 ${t("general.days")}`,
      value: 10,
    },
    {
      label: `12 ${t("general.days")}`,
      value: 11,
    },
    {
      label: `13 ${t("general.days")}`,
      value: 12,
    },
    {
      label: `14 ${t("general.days")}`,
      value: 13,
    },
    {
      label: `15 ${t("general.days")}`,
      value: 14,
    },
    {
      label: `16 ${t("general.days")}`,
      value: 15,
    },
    {
      label: `17 ${t("general.days")}`,
      value: 16,
    },
    {
      label: `18 ${t("general.days")}`,
      value: 17,
    },
    {
      label: `19 ${t("general.days")}`,
      value: 18,
    },
    {
      label: `20 ${t("general.days")}`,
      value: 19,
    },
    {
      label: `21 ${t("general.days")}`,
      value: 20,
    },
    {
      label: `22 ${t("general.days")}`,
      value: 21,
    },
    {
      label: `23 ${t("general.days")}`,
      value: 22,
    },
    {
      label: `24 ${t("general.days")}`,
      value: 23,
    },
    {
      label: `25 ${t("general.days")}`,
      value: 24,
    },
    {
      label: `26 ${t("general.days")}`,
      value: 25,
    },
    {
      label: `27 ${t("general.days")}`,
      value: 26,
    },
    {
      label: `28 ${t("general.days")}`,
      value: 27,
    },
    {
      label: `29 ${t("general.days")}`,
      value: 28,
    },
    {
      label: `30 ${t("general.days")}`,
      value: 29,
    },
  ];
  const [data, setData] = useState({
    cut_off_time: null,
    cut_off_days: 0,
    state_length: 500,
  });

  const { isLoading: isLoadingLastMinuteSettings } = useQuery<any>(
    ["lastMinuteSettings", user_key],
    () => fetchLastMinute(user_key),
    {
      onSuccess: (data) => {
        setData({
          cut_off_days: data.results.settings.cut_off_days,
          cut_off_time: data.results.settings.cut_off_time,
          state_length: data.results.settings.state_length,
        });
      },
      onError: () => {
        toast.error(t("general.requestError"));
      },
    }
  );

  const saveEditLastMinute = useMutation(
    (body: { cut_off_time: any; cut_off_days: number, state_length: any }) => saveLastMinute({...body, user_key: user_key}),
    {
      onSuccess: () => {
        toast.success(t("general.completed"));
      },
      onError: (error: Error) => {
        toast.error(t(error?.message ? error.message : "general.requestError"));
      },
    }
  );
  return (
    <>
      <div className="space-y-3 text-sm">
        <p className="font-bold italic">
          {t("channelManager.editproperty.cutOffTime")}
        </p>
        <p>{t("channelManager.editproperty.cutOffTimeDescription")}</p>
      </div>
      <div className="border-b border-gray-300 h-full my-3"></div>
      <div className="space-y-3">
        <div className="md:flex items-center gap-3">
          <p className="text-sm">
            {t("channelManager.editproperty.cutOffTime")}:
          </p>
          <div className="lg:w-1/3 w-full">
            <SelectInput<BaseSelectItem, false>
              isLoading={
                saveEditLastMinute.isLoading || isLoadingLastMinuteSettings
              }
              isDisabled={
                saveEditLastMinute.isLoading || isLoadingLastMinuteSettings
              }
              value={{
                label: timesChannex.find(
                  (time) => time.value === data.cut_off_time
                )?.label,
                value: timesChannex.find(
                  (time) => time.value === data.cut_off_time
                )?.value,
              }}
              onChange={(item) => {
                if (item) {
                  setData((prev) => {
                    return {
                      ...prev,
                      cut_off_time: item.value,
                      cut_off_days: 0,
                    };
                  });
                }
              }}
              options={timesChannex}
            />
          </div>
        </div>
        {data.cut_off_time && (
          <div className="md:flex items-center gap-3">
            <p className="text-sm">
              {t("channelManager.editproperty.cutOffDays")}:
            </p>
            <div className="lg:w-1/3 w-full">
              <SelectInput<BaseSelectItem, false>
                isLoading={
                  saveEditLastMinute.isLoading || isLoadingLastMinuteSettings
                }
                isDisabled={
                  saveEditLastMinute.isLoading || isLoadingLastMinuteSettings
                }
                value={{
                  label: daysChannex.find(
                    (day) => day.value === data.cut_off_days
                  )?.label,
                  value: daysChannex.find(
                    (day) => day.value === data.cut_off_days
                  )?.value,
                }}
                onChange={(item) => {
                  if (item) {
                    setData((prev) => {
                      return {
                        ...prev,
                        cut_off_days: item.value,
                      };
                    });
                  }
                }}
                options={daysChannex}
              />
            </div>
          </div>
        )}
      </div>
      <div className="space-y-3 text-sm mt-8">
        <p className="font-bold italic">
          {t("channelManager.editproperty.inventoryDays")}
        </p>
        <p className="">{t("channelManager.editproperty.inventoryDaysInfo")}</p>
      </div>
      <div className="border-b border-gray-300 h-full my-3"></div>
      <div className="space-y-3">
        <div className="md:flex items-center gap-3">
          <p className="text-sm">
            {t("channelManager.editproperty.inventoryDays")}:
          </p>
          <div className="lg:w-1/3 w-full">
            <TextInput
              disabled={
                saveEditLastMinute.isLoading || isLoadingLastMinuteSettings
              }
              type="number"
              min={100}
              max={500}
              numbered={true}
              value={data.state_length}
              onChange={(val) => {
                setData((prev) => {
                  return {
                    ...prev,
                    state_length: +val,
                  };
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="text-center mt-40">
        <Button
          color={"primary"}
          size="small"
          label={t("general.save")}
          loading={saveEditLastMinute.isLoading || isLoadingLastMinuteSettings}
          disabled={saveEditLastMinute.isLoading || isLoadingLastMinuteSettings}
          className="mt-5"
          onClick={() => {
            if (data.state_length < 100 || data.state_length > 500) {
              toast.error(t("channelManager.editproperty.inventoryDaysErrors"));
              return;
            }
            saveEditLastMinute.mutate(data);
          }}
        ></Button>
      </div>
    </>
  );
};

export default EditProperty;
