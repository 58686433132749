import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../components/Button";
import Icon from "../components/Icon";
import Modal from "../components/Modal";
import ModalActions from "../components/ModalAction";
import ModalCard from "../components/ModalCard";
import ModalSection from "../components/ModalSection";
import ModuleCard from "../components/module/ModuleCard";
import PageTitle from "../components/PageTitle";
import PageWrapper from "../components/PageWrapper";
import Pagination from "../components/Pagination";
import PreviousPage from "../components/PreviousPage";
import ProgressBar from "../components/ProgressBar";
import TextInput from "../components/TextInput";
// import useBreadcrumbs from "../hooks/useBreadcrumbs";
import {
  activateModule,
  deactivateModule,
  fetchModuleDetail,
  fetchReasonDeactivation,
} from "../shared/queries";
import FormField from "../components/FormField";
import Checkbox from "../components/Checkbox";

const Module = () => {
  const { t } = useTranslation();
  const confirmationKey = t("general.iConfirm");
  const filtering = [
    { filter: "", name: t("module.all") },
    { filter: "statusActive", name: t("module.statusActive") },
    { filter: "statusInactive", name: t("module.statusInactive") },
    { filter: "renewEnable", name: t("module.renewEnable") },
    { filter: "renewDisable", name: t("module.renewDisable") },
  ];

  const { data: reasonList } = useQuery<any>(["reason"], () =>
    fetchReasonDeactivation()
  );

  const location = useLocation();
  const idModule = location.hash.substring(1);
  const topRef: any = useRef();

  const [modalVisible, setModalVisible] = useState<{
    display: boolean;
    id: string[];
    name: string | null;
    active: boolean;
    date_closed: any;
    type: boolean;
    price?: number;
  }>({
    display: false,
    id: [],
    name: null,
    active: false,
    date_closed: null,
    type: false,
    price: 0,
  });
  const [confirmValue, setConfirmValue] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [filterPage, setFilterPage] = useState({ initial: 0, final: 15 });
  const [searchValue, setSearchValue] = useState<string>("");
  const [mutationLoading, setMutationLoading] = useState<boolean>(false);
  const [activeFilter, setactiveFilter] = useState<string>("");
  const [reasonDeactivaton, setReasonDeactivaton] = useState<any>([]);
  const [textDisable, setTextDisable] = useState<boolean>(true);
  const [other, setOther] = useState<{
    isActive: boolean;
    text: string;
  }>({
    isActive: false,
    text: "",
  });

  const {
    data: module,
    isLoading,
    refetch,
  } = useQuery("moduleDetail", () => fetchModuleDetail(idModule));
  /* useBreadcrumbs([
    { label: t("navigation.modules"), route: "/modules" },
    {
      label: t(`module.id${idModule}`).toUpperCase(),
      route: "/modules/module#" + idModule,
    },
  ]); */

  const getModulesStatus = () => {
    const activeModulesLength = module?.locals?.filter(
      (local: any) => local.active && !local.date_closed
    ).length;
    switch (activeModulesLength) {
      case 0:
        return false;
      case module?.locals_count:
        return true;
      case activeModulesLength > 0 &&
        activeModulesLength < module?.locals_count:
        return false;
      default:
        return false;
    }
  };

  const getAllLocalKeys = () => {
    const localKeys: string[] = [];
    module?.locals?.map((local: any) => localKeys.push(local.local_key));
    return localKeys;
  };

  const activateMutation = useMutation(
    (data: { local_keys: string[]; module_id: number }) =>
      activateModule(data.local_keys, data.module_id),
    {
      onSuccess: () => {
        window.dataLayer.push({
          event: "modulo_attivato_moduli",
          gtm: {
            id_modulo: idModule,
            value: modalVisible.price
              ? modalVisible.price > 0
                ? (modalVisible.price / 100).toFixed(2)
                : 0
              : 0,
          },
        });
        toast.success(t("general.requestCompleted"));
        refetch();
        setModalVisible({
          display: false,
          id: [],
          name: null,
          active: false,
          date_closed: null,
          type: false,
          price: 0,
        });
        setMutationLoading(false);
      },
      onError: (error: Error) => {
        toast.error(t("general.requestError"));
        setMutationLoading(false);
      },
    }
  );

  const deactivateMutation = useMutation(
    (data: {
      local_keys: string[];
      module_id: number;
      reason: any;
      other: string | null;
    }) =>
      deactivateModule(
        data.local_keys,
        data.module_id,
        data.reason,
        data.other
      ),
    {
      onSuccess: () => {
        toast.success(t("general.requestCompleted"));
        refetch();
        setModalVisible({
          display: false,
          id: [],
          name: null,
          active: false,
          date_closed: null,
          type: false,
          price: 0,
        });
        setReasonDeactivaton([]);
        setOther({
          isActive: false,
          text: "",
        });
        setMutationLoading(false);
      },
      onError: (error: Error) => {
        toast.error(t("general.requestError"));
        setMutationLoading(false);
      },
    }
  );

  const getBundleSpecsArray = (bundles: { id: any; date_closed: string }[]) => {
    const bundleSpecs: { id: any; date_closed: string }[] = [];
    bundles?.map((bundle) =>
      bundleSpecs.push({ id: bundle.id, date_closed: bundle.date_closed })
    );
    return bundleSpecs;
  };

  useEffect(() => {
    topRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }, [page]);

  useEffect(() => {
    setPage(1);
    setFilterPage({
      initial: 0,
      final: 15,
    });
  }, [activeFilter]);

  return (
    <PageWrapper>
      <PreviousPage
        className={"mb-4"}
        label={t("general.goBack")}
      ></PreviousPage>
      <div className="md:flex justify-between" ref={topRef}>
        <PageTitle title={t(`module.id${idModule}`).toUpperCase()}></PageTitle>
        <div className={"border-gray-200 text-gray-600 text-sm"}>
          {isLoading ? (
            <ProgressBar className={"mt-4 w-full"} />
          ) : (
            <Button
              disabled={
                getModulesStatus() ||
                module?.module?.blocked_activation ||
                module.module.blocked_deactivation
              }
              onClick={() => {
                setModalVisible({
                  display: true,
                  id: getAllLocalKeys(),
                  name: "ALL",
                  active: getModulesStatus(),
                  date_closed: null,
                  type: true,
                });
              }}
              icon="home"
              color="primary"
              size="small"
              label={t("module.enableServicesApartments")}
            />
          )}
        </div>
      </div>
      <div className="lg:flex align-middle my-3 gap-5">
        <div className="relative bg-white rounded-xl border-gray-300 border h-8 md:w-64">
          <input
            style={{
              height: "100%",
              width: "100%",
            }}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              setPage(1);
              setFilterPage({
                initial: 0,
                final: 15,
              });
            }}
            placeholder={t("general.searchForApartment")}
            className={
              "relative flex items-center align-middle bg-white focus:outline-none bg-white-600 px-3 text-sm font-medium border-gray-300 rounded-2xl truncate"
            }
          />
          <Icon
            name={"home"}
            size={"18px"}
            className={"absolute top-1 right-1"}
          />
        </div>
        <div className="lg:my-0 my-3">
          {filtering.map((filt) => (
            <button
              key={filt.name}
              onClick={() => setactiveFilter(filt.filter)}
              className={`${
                activeFilter === filt.filter && "bg-cyan-100 text-cyan-800"
              } focus:outline-none hover:bg-cyan-100 cursor-pointer px-3 py-1 rounded-2xl text-sm font-semibold truncate`}
            >
              {filt.name}
            </button>
          ))}
        </div>
      </div>
      {isLoading ? <ProgressBar className={"mt-4 w-full"} /> : null}
      {module &&
        module?.locals
          ?.filter((local: any) => {
            switch (activeFilter) {
              case "statusActive":
                return local.active;
              case "statusInactive":
                return !local.active;
              case "renewEnable":
                return local.active;
              case "renewDisable":
                return local.active;
              default:
                return local;
            }
          })
          .filter((local: any) => {
            switch (activeFilter) {
              case "renewEnable":
                return !local.date_closed;
              case "renewDisable":
                return local.date_closed;
              default:
                return local;
            }
          })
          .filter((local: any) =>
            local.local_name?.toLowerCase().includes(searchValue)
          )
          .filter(
            (_local: any, idx: number) =>
              idx >= filterPage.initial && idx < filterPage.final
          )
          .map((local: any) => (
            <ModuleCard
              key={local.local_key}
              moduleName={t(`module.id${idModule}`).toUpperCase()}
              apartmentName={local.local_name}
              active={local.active}
              dateClosed={local.date_closed}
              price={local.self_price}
              defaultPrice={module.module.default_price}
              onChange={() =>
                setModalVisible({
                  display: true,
                  id: [local.local_key],
                  name: local.local_name,
                  active: local.active,
                  date_closed: local.date_closed,
                  type: false,
                  price:
                    local.self_price !== null
                      ? local.self_price >= 0
                        ? local.self_price
                        : module.module.default_price
                      : module.module.default_price,
                })
              }
              bundled={local.activated_by_modules?.length > 0 ?? false}
              bundlesSpecs={getBundleSpecsArray(local.activated_by_modules)}
              freeMonthRemain={local.self_free_months_remains}
              blockedActivation={local.self_blocked_activation}
              blockedDeactivation={local.self_blocked_deactivation}
            />
          ))}
      {/* PAGINAZIONE */}
      <div className="mt-5">
        <Pagination
          page={page}
          pageSize={15}
          totalItems={
            module?.locals
              ?.filter((local: any) => {
                switch (activeFilter) {
                  case "statusActive":
                    return local.active;
                  case "statusInactive":
                    return !local.active;
                  case "renewEnable":
                    return local.active;
                  case "renewDisable":
                    return local.active;
                  default:
                    return local;
                }
              })
              ?.filter((local: any) => {
                switch (activeFilter) {
                  case "renewEnable":
                    return !local.date_closed;
                  case "renewDisable":
                    return local.date_closed;
                  default:
                    return local;
                }
              })
              ?.filter((local: any) =>
                local.local_name?.toLowerCase().includes(searchValue)
              ).length ?? 0
          }
          onPageUpdate={(value) => {
            if (!isLoading) {
              if (value === "+") {
                setPage((prev) => prev + 1);
                setFilterPage((prev) => ({
                  initial: prev.initial + 15,
                  final: prev.final + 15,
                }));
                return;
              }
              if (value === "-") {
                setPage((prev) => (prev > 1 ? prev - 1 : 1));
                setFilterPage((prev) =>
                  prev.initial === 0
                    ? prev
                    : {
                        initial: prev.initial - 15,
                        final: prev.final - 15,
                      }
                );
                return;
              }
              setPage(+value);
              setFilterPage({
                initial: 15 * (value as number) - 15,
                final: 15 * (value as number),
              });
            }
          }}
        />
      </div>
      {/* MODALE */}
      <Modal visible={modalVisible.display}>
        <ModalCard
          title={`${t("general.module")} ${t(
            `module.id${idModule}`
          ).toUpperCase()} - ${modalVisible.name}`}
        >
          <ModalSection>
            {modalVisible.active && !modalVisible.date_closed && (
              <div className={`p-4 mb-5`}>
                <FormField label={t("module.selectMotifs")}>
                  {reasonList.results?.map((option: any) => (
                    <Checkbox
                      key={option.id}
                      checked={reasonDeactivaton.find(
                        (first: any) => first.id === option.id
                      )}
                      label={t(`module.motif${option.id}`)}
                      onChange={() =>
                        setReasonDeactivaton((prev: any) => {
                          const previousArray = [...prev];
                          const findedPrev = previousArray.find(
                            (prev) => prev.id === option.id
                          );
                          if (findedPrev) {
                            const dataNew = previousArray?.filter(
                              (prev) => prev.id !== option.id
                            );
                            return dataNew;
                          }
                          previousArray.push({
                            id: option.id,
                            text: `module.motif${option.id}`,
                          });
                          return previousArray;
                        })
                      }
                    />
                  ))}
                  <div className="md:flex gap-2">
                    <Checkbox
                      checked={other.isActive}
                      label={t("cats.altro")}
                      onChange={() => {
                        setOther(
                          (prev: { isActive: boolean; text: string }) => {
                            return {
                              ...prev,
                              isActive: !prev.isActive,
                            };
                          }
                        );
                      }}
                    />
                    {other.isActive && (
                      <TextInput
                        placeholder={t("serviceQuestionItem.freeText")}
                        value={other.text}
                        onChange={(val) => {
                          if (!val) {
                            setTextDisable(true);
                          } else {
                            setTextDisable(false);
                          }
                          setOther(
                            (prev: { isActive: boolean; text: string }) => {
                              return {
                                ...prev,
                                text: val,
                              };
                            }
                          );
                        }}
                      />
                    )}
                  </div>
                </FormField>
              </div>
            )}

            <div className="text-gray-700 p-4 pt-0 border-gray-300">
              {t("general.write1")}
              <span className={"font-bold"}>{confirmationKey}</span>{" "}
              {t("general.write2")}
              <div className="mt-2">
                <TextInput
                  value={confirmValue}
                  onChange={(val) => setConfirmValue(val)}
                />
              </div>
            </div>
          </ModalSection>
          <ModalActions
            isLoading={mutationLoading}
            onSave={() => {
              if (confirmValue === confirmationKey) {
                setMutationLoading(true);
                setConfirmValue("");
                if (modalVisible.type) {
                  if (modalVisible.active) {
                    const reasonsValue: any = [];
                    reasonDeactivaton?.forEach(
                      (element: { id: any; text: any }) => {
                        reasonsValue.push(element.id);
                      }
                    );
                    deactivateMutation.mutate({
                      local_keys: modalVisible.id,
                      module_id: Number.parseFloat(idModule),
                      reason: reasonsValue,
                      other:
                        other.isActive && other.text === "" ? null : other.text,
                    });
                  } else {
                    activateMutation.mutate({
                      local_keys: modalVisible.id,
                      module_id: Number.parseFloat(idModule),
                    });
                  }
                } else {
                  if (modalVisible.active && !modalVisible.date_closed) {
                    const reasonsValue: any = [];
                    reasonDeactivaton?.forEach(
                      (element: { id: any; text: any }) => {
                        reasonsValue.push(element.id);
                      }
                    );
                    deactivateMutation.mutate({
                      local_keys: modalVisible.id,
                      module_id: Number.parseFloat(idModule),
                      reason: reasonsValue,
                      other: other.isActive
                        ? other.text === ""
                          ? null
                          : other.text
                        : null,
                    });
                  } else {
                    activateMutation.mutate({
                      local_keys: modalVisible.id,
                      module_id: Number.parseFloat(idModule),
                    });
                  }
                }
                setReasonDeactivaton([]);
              }
            }}
            saveLabel={
              modalVisible.active && !modalVisible.date_closed
                ? t("general.disable")
                : t("general.enable")
            }
            saveDisabled={
              (modalVisible.active &&
                !modalVisible.date_closed &&
                reasonDeactivaton.length === 0 &&
                ((other.isActive && !other.text) || !other.isActive)) ||
              (modalVisible.active &&
                !modalVisible.date_closed &&
                reasonDeactivaton.length > 0 &&
                other.isActive &&
                textDisable)
            }
            onClose={() => {
              setModalVisible({
                display: false,
                id: [],
                name: null,
                active: false,
                date_closed: null,
                type: false,
                price: 0,
              });
              setReasonDeactivaton([]);
              setOther({
                isActive: false,
                text: "",
              });
            }}
          />
        </ModalCard>
      </Modal>
    </PageWrapper>
  );
};

export default Module;
