import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  fetchAptNotifications,
  updateNotifications,
} from "../../shared/queries";
import {
  ApartmentNotification,
  Local,
} from "../../types";
import Button from "../Button";
import FormSectionTitle from "../FormSectionTitle";
import ReactSwitch from "react-switch";
import Icon from "../Icon";
import { useState } from "react";
import Checkbox from "../Checkbox";
import BaseTable from "../BaseTable";

type ApartmentNotifsProps = {
  data: Local;
  refetch: () => void;
};
export default function ApartmentNotifs({
  data,
  refetch,
}: ApartmentNotifsProps) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const {
    data: aptNotifications,
    isLoading: loadingAptNotifications,
    refetch: refetchAptNotifications,
  } = useQuery(
    ["aptNotifications", data.local_key],
    () => fetchAptNotifications(data.local_key ?? ""),
    { onSuccess: (data) => console.log("success", data) }
  );

  const preferredMutation = useMutation(
    (data: any) => updateNotifications(data),
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
        setLoading(false);
        refetchAptNotifications();
      },
      onError: (error: Error) => {
        toast.error(error.message || t("general.requestError"));
        setLoading(false);
      },
    }
  );

  return (
    <div>
      <FormSectionTitle
        borderless
        title={t("profile.notifications")}
      ></FormSectionTitle>
      {loadingAptNotifications ? (
        <div className={"mt-2 flex border-b pb-2 border-gray-200"}>
          <div className={"px-1 flex-1"}>
            <Skeleton />
          </div>

          <div className={"px-1 flex-1"}>
            <Skeleton />
          </div>

          <div className={"px-1 flex-1"}>
            <Skeleton />
          </div>

          <div className={"px-1 flex-1"}>
            <Skeleton />
          </div>

          <div className={"px-1 flex-1"}>
            <Skeleton />
          </div>
        </div>
      ) : (
      <BaseTable
        currentPage={1}
        identifierKey={"id"}
        data={aptNotifications?.results}
        loading={loadingAptNotifications}
        displayRows={aptNotifications?.results?.length}
        filters={[]}
        onEdit={(id) => {}}
        columns={[
          {
            Header: t<string>("Email"),
            accessor: (row: any) => {
              return row.email;
            },
          },
          {
            Header: t<string>("profile.acceptSummary"),
            accessor: (row: any) => {
              return <Checkbox
              disabled={loading}
              checked={row.summary_1d}
              onChange={() => {
                setLoading(true);
                preferredMutation.mutate({
                  users_notifications_id: row.users_notifications_id,
                  local_key: data.local_key ?? "",
                  summary_1d: !row.summary_1d,
                  action: "SUMMARY_1D",
                });
              } } label={""}                  />;
            },
          },
          {
            Header: t<string>("resv.notifications"),
            accessor: (row: any) => {
              return row.preferred ? (
                <Button
                  flat
                  disabled={loading}
                  onClick={() => {
                    setLoading(true);
                    preferredMutation.mutate({
                      users_notifications_id:
                        row.users_notifications_id,
                      local_key: data.local_key ?? "",
                      preferred: false,
                      action: "PREFERRED",
                    });
                  }}
                  icon={"starfilled"}
                  iconSize={"20px"}
                />
              ) : (
                <Button
                  flat
                  disabled={loading}
                  onClick={() => {
                    setLoading(true);
                    preferredMutation.mutate({
                      users_notifications_id:
                        row.users_notifications_id,
                      local_key: data.local_key ?? "",
                      preferred: true,
                      action: "PREFERRED",
                    });
                  }}
                  icon={"star"}
                  iconSize={"20px"}
                />
              );
            },
          },
        ]}
      />
      )}
    </div>
  );
}
