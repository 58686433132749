import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { updateService } from "../../shared/queries"
import { Buyable } from "../../types"
import FormField from "../FormField"
import Modal from "../Modal"
import ModalActions from "../ModalAction"
import ModalCard from "../ModalCard"
import ModalSection from "../ModalSection"
import TextInput from "../TextInput"

type UpdateServiceGeneralProps = {
  data: Buyable,
  visible: boolean,
  onClose?: () => void,
  onSave?: () => void,
  onGet?: any
}

export default function UpdateServiceGeneral ({
  data,
  visible,
  onClose,
  onSave,
  onGet
} : UpdateServiceGeneralProps) {

  const { t } = useTranslation()

  const [title, setTitle] = useState<string>(data.title || '')
  const [amount, setAmount] = useState<string>(data.amount_tot || '')

  /**
   * Mutate the selected stripe account
   */
  const mutation = useMutation(() => updateService(data.id || 0, {
    ...data,
    title: title,
    amount_tot: amount,
    //langs: null
  }), {
    onSuccess: (data) => {
      toast.success(t('general.requestCompleted'))
      if (onSave) onSave()
      onGet(data)
    },
    onError: () => {
      toast.error(t('general.requestError'))
      onGet('isError')
    }
  })

  return (
    <Modal
      visible={visible}>
      <ModalCard
        title={t('general.generalInformation')}>
        <ModalSection>
          <div className={'p-4 flex-wrap flex -mx-2'}>
          <FormField
            className={'px-2 mb-4 w-full'}
            label={t('services.name')}>
            <TextInput
              value={title}
              onChange={value => setTitle(value)}></TextInput>
          </FormField>
          <FormField
            className={'px-2 mb-4 w-full'}
            label={t('services.amount')}>
            <TextInput
              value={amount}
              numbered={true}
              onChange={value => setAmount(value)}></TextInput>
          </FormField>
        </div>
        </ModalSection>
        <ModalActions
          isLoading={mutation.isLoading}
          saveLabel={t('general.save')}
          onClose={() => {
            if (onClose) onClose()
          }}
          onSave={async() => {
            mutation.mutate()
            if (onSave) onSave()
          }}></ModalActions>
      </ModalCard>
    </Modal>
  )

}