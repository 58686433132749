import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import EditRoomForm from "./EditRoomForm";

type Props = {
  singleData: any;
  onSaveEditRoom: (form: any) => void;
};

const EditRoomComponent = (props: Props) => {
  const { singleData, onSaveEditRoom } = props;
  const dataForm = singleData?.results?.attributes;
  const { t } = useTranslation();
  const [data, setData] = useState<any>();

  const changeData = (prop: any, value: any) => {
    setData((prev: any) => {
      return {
        ...prev,
        [prop]: value,
      };
    });
  };

  useEffect(() => {
    setData(dataForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleData]);

  return (
    <div className="p-4 space-y-4 text-sm">
        <EditRoomForm data={data} changeData={changeData} />
        <div className="text-center">
          <Button
            padding={"md"}
            size={"small"}
            label={t("general.save")}
            onClick={() => {
              if (onSaveEditRoom) onSaveEditRoom(data);
            }}
          />
        </div>
    </div>
  );
};

export default EditRoomComponent;
