export const { NODE_ENV, REACT_APP_VIKEY_API_URL } = process.env;

export const moduleId = {
  base: 1, //
  sms: 2, //module.smsInfo => module.id2Info
  deposit: 3,//
  auto_istat: 4,//module.autoIstatInfo => module.id4Info
  sms_whatsapp: 5,//module.smsWhatsappInfo => module.id5Info
  whatsapp: 6,//module.whatsappInfo => module.id6Info
  sms_done: 7,//module.smsDoneInfo => module.id7Info
  whatsapp_done: 8,//module.whatsappDoneInfo => module.id8Info
  sms_whatssapp_done: 9,//module.smsWhatsappDoneInfo => module.id9Info
  contract: 10,//module.contractInfo => module.id10Info
  integration: 11,//module.integrationInfo => module.id11Info
  pa: 12,//module.alloggiatiWebInfo => module.id12Info
  city_tax: 13,//module.touristicTaxInfo => module.id13Info
  starter_pack: 14,//module.starterPackInfo => module.id14Info
  opening: 15,//
  bridge: 16,//
  domotics: 17,//
  docs_auto_confirmation: 19,//module.recognitionInfo => module.id19Info
  paytourist: 20,//module.paytouristInfo => module.id20Info
  calendar: 21,//module.calendarInfo => module.id21Info
  logo: 22,//module.logoInfo => module.id22Info
  channel_manager: 23,//module.channel_managerInfo => module.id23Info
  master_pack: 24,//module.master_packInfo => module.id24Info
};

export const currencies = [
  {
    label: "Euro (EUR)",
    value: "EUR",
  },
  {
    label: "British Pound (GBP)",
    value: "GBP",
  },
];

declare global {
  interface Window {
    dataLayer: any;
  }
}
