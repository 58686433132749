import { useQuery } from "react-query"
import { fetchOrderStats } from "../../shared/queries"
import { ListApiResponseData, OrderStats } from "../../types"
import { useTranslation } from "react-i18next"

export default function OrderResults () {

  const {
    data,
    isFetching,
    refetch
  } = useQuery<ListApiResponseData<OrderStats>, Error>(
    ['orderResults'], () => fetchOrderStats(), {
    keepPreviousData: true
  })
  const { t } = useTranslation()

  return (
    <div className={'grid md:grid-cols-3 grid-cols-2 gap-4'}>
      {
        (data && data.data) && data.data.map(item => (
          <div className={'border rounded-2xl p-4'}>
            <div className="font-bold mb-2">
              {item.date}
            </div>
            <div className={'text-sm md:flex justify-between border-b mb-2 pb-2'}>
              <div className={'flex-1'}>
                {t('order.total') + ':'}
              </div>
              <div>
                {(item.tot).toFixed(2)} EUR
              </div>
            </div>
            <div className={'text-sm md:flex justify-between'}>
              <div className={'flex-1'}>
              {t('order.fee') + ':'}
              </div>
              <div>
                {((item.tot * item.vikeypay_fee)/100).toFixed(2)} EUR
              </div>
            </div>
          </div>
        ))
      }
    </div>
  )

}