import React, { useEffect, useState } from 'react';
import PageWrapper from '../components/PageWrapper';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CircleSpinner from '../components/CircleSpinner';
import { DomainContext } from '../DomainContext';
import { useMutation, useQuery } from 'react-query';
import { notificationConfirmation } from '../shared/queries';
import { toast } from 'react-toastify';

export default function ConfirmNotification() {
  const history = useHistory();
  const { t } = useTranslation();
  const { token } = useParams<any>();
  const { appLogo, appName } = React.useContext(DomainContext);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState('');

  useEffect(() => {
    setLoading(true);
    confirmToken.mutate(token);
  }, [token]);

  const confirmToken = useMutation(() => notificationConfirmation(token), {
    onSuccess: () => {
      setResult(t('general.operationCompleted'));
    },
    onError: () => {
      setResult(t('general.requestError'));
    },
    onMutate: (res) => {
      setLoading(false);
    },
  });

  return (
    <PageWrapper>
      <div className="max-w-sm py-8 px-8 bg-white shadow-lg rounded-lg my-20 m-auto">
        <div className="flex justify-center">
          <img src={appLogo} alt={appName + ' logo'} className={'h-12'} />
        </div>
        {loading ? (
          <div className="flex justify-center mt-4">
            <CircleSpinner color={'primary'} />
          </div>
        ) : (
          <div className="flex justify-center">
            <p className="mt-2 text-gray-600 block">{result}</p>
          </div>
        )}
      </div>
    </PageWrapper>
  );
}
