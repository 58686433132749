import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FormSectionTitle from "../FormSectionTitle";
import { useMutation, useQuery } from "react-query";
import {
  createNewApiToken,
  disableApiToken,
  fetchApiToken,
} from "../../shared/queries";
import BaseTable from "../BaseTable";
import Checkbox from "../Checkbox";
import { toast } from "react-toastify";
import Modal from "../Modal";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import ModalActions from "../ModalAction";
import Button from "../Button";
import DeleteDialog from "../DeleteDialog";

type Props = {};

const ProfileToken = (props: Props) => {
  const { t } = useTranslation();
  const [token, setToken] = useState();
  const [showDelete, setShowDelete] = useState<any>({
    display: false,
    module_id: null,
  });

  const copyLink = () => {
    const tokenClipboard = token!;
    navigator.clipboard.writeText(tokenClipboard);
  };

  const {
    data: dataToken,
    isLoading: isLoadingToken,
    refetch: refetchToken,
  } = useQuery("api_token", fetchApiToken);

  const createNewApiTokenMutation = useMutation(() => createNewApiToken(), {
    onSuccess: (data) => {
      toast.success(t("general.operationCompleted"));
      setToken(data.results);
      refetchToken();
    },
    onError: (error: Error) => {
      toast.error(t(error.message) || t("general.requestError"));
      refetchToken();
    },
  });
  const disableApiTokenMutation = useMutation(
    (data: any) => disableApiToken(data),
    {
      onSuccess: () => {
        toast.success(t("general.operationCompleted"));
        refetchToken();
        setShowDelete({
          display: false,
          module_id: null,
        })
      },
      onError: (error: Error) => {
        toast.error(t(error.message) || t("general.requestError"));
        refetchToken();
        setShowDelete({
          display: false,
          module_id: null,
        })
      },
    }
  );
  return (
    <>
      <FormSectionTitle
        dense
        title={t("profile.token")}
        add={true}
        addLabel={t("profile.createToken")}
        onAdd={() => {
          createNewApiTokenMutation.mutate();
        }}
      ></FormSectionTitle>

      <BaseTable
        currentPage={1}
        identifierKey={"id"}
        data={dataToken?.results ?? []}
        loading={isLoadingToken}
        displayRows={dataToken?.results?.length}
        filters={[]}
        onEdit={(id) => {}}
        columns={[
          {
            Header: t<string>("profile.token"),
            accessor: "token",
          },
          {
            Header: t<string>("channelManager.status"),
            accessor: (row: any) => {
              if (row.status.toLowerCase() === "active") {
                return (
                  <span
                    className={
                      "bg-green-100 font-medium text-green-800 px-2 py-1 rounded-2xl text-xs"
                    }
                  >
                    {row.status}
                  </span>
                );
              }
              return (
                <span
                  className={
                    "bg-red-200 text-red-800 font-medium px-2 py-1 rounded-2xl text-xs"
                  }
                >
                  {row.status}
                </span>
              );
            },
          },
          {
            Header: t<string>("general.disable"),
            accessor: (row: any) => {
              if (row.status.toLowerCase() === "active") {
                return (
                  <Checkbox
                    checked={row.status.toLowerCase() === "active"}
                    onChange={() => {
                      setShowDelete({ display: true, module_id: [row.id] });
                      //disableApiTokenMutation.mutate([row.id]);
                    }}
                    label={""}
                  />
                );
              }
              return <Checkbox disabled checked={false} label={""} />;
            },
          },
        ]}
      />
      <Modal visible={!!token}>
        <ModalCard
          className={"w-full max-w-lg"}
          title={t("profile.tokenCreated")}
        >
          <ModalSection>
            <div className={"p-4 flex flex-col space-y-4"}>
              <p>{t("profile.tokenCreatedMsg")}</p>
              <p className="font-bold">{t("profile.token")}:</p>
              <p className="text-sm">{token}</p>
              <div className="flex justify-end">
                <Button onClick={copyLink}>{t("profile.copyToken")}</Button>
              </div>
            </div>
          </ModalSection>
          <ModalActions
            onClose={() => {
              setToken(undefined);
            }}
            closeLabel={t("general.close")}
          />
        </ModalCard>
      </Modal>
      <DeleteDialog
        askConfirmation={true}
        confirmationKey={t("general.iConfirm")}
        isLoading={disableApiTokenMutation.isLoading}
        visible={showDelete.display}
        message={""}
        onCancel={() => setShowDelete({
          display: false,
          module_id: null,
        })}
        onConfirm={() => {
          disableApiTokenMutation.mutate(showDelete.module_id);
        }}
      />
    </>
  );
};

export default ProfileToken;
