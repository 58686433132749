import ReactTooltip from 'react-tooltip'
import Icon from './Icon'

type FormFieldProps = {
  label: string,
  className?: string,
  tooltip?: string,
  children: any,
  errors?: any,
  errorKey?: string
}

export default function FormField ({
  label,
  children,
  className,
  tooltip,
  errors,
  errorKey
} : FormFieldProps) {

  return (
    <div className={className}>
      <div className={'mb-2 text-gray-500 font-medium'}>
        <div className={'flex items-center'}>
          {label}
          {
            tooltip &&
            <div data-tip={tooltip}>
              <Icon
                name={'information-circle'}
                className={'ml-2 cursor-pointer'}
                size={'15px'}></Icon>
            </div>
          }
        </div>
      </div>
      <div>
        {children}
      </div>
      {
        tooltip &&
        <ReactTooltip></ReactTooltip>
      }
      {
        (errors && errors.details && errorKey) && <div className="mt-2 text-sm font-medium text-red-600">
          { errors.details.filter((d: any) => d.context.key == errorKey).map((e: any) => (
            <div>
              {e.message}
            </div>
          ))}
        </div>
      }
    </div>
  )

}
