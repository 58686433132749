import React, { useState } from "react";
import { useQuery } from "react-query";
import Icon from "../components/Icon";
import {
  fetchAlertsClient,
  fetchDashboardStats,
  fetchLocals,
} from "../shared/queries";
import { DashboardStats, LocalTable, TableResponse } from "../types";
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines";
import PageWrapper from "../components/PageWrapper";
import DashboardApartments from "../components/dashboard/DashboardApartments";
import DashboradArrivals from "../components/dashboard/DashboradArrivals";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import useBreadcrumbs from "../hooks/useBreadcrumbs";
import i18n from "../langs";
import ReactHtmlParser from "react-html-parser";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";

export default function Dashboard() {
  const history = useHistory();

  const { t } = useTranslation("translation");
  const { profile } = useSelector((state: RootState) => state.login);

  useQuery<TableResponse<LocalTable>, Error>("apartments", () =>
    fetchLocals(null, undefined)
  );
  const [dataAlerts, setDataAlerts] = useState<any[]>([]);
  const viewDataAlert = (alert: any) => {
    const alertTemp = [...dataAlerts];
    const findIndexAlert = alertTemp.findIndex((al) => al.id === alert.id);
    alertTemp[findIndexAlert] = {
      ...alertTemp[findIndexAlert],
      visible: !alertTemp[findIndexAlert].visible,
    };
    setDataAlerts(alertTemp);
  };

  // useBreadcrumbs([{ label: "Dashboard", route: "/dashboard" }]);

  const { data: stats } = useQuery<DashboardStats, Error>(
    "stats",
    fetchDashboardStats
  );

  const { isLoading: isLoadingAlerts } = useQuery<any, Error>(
    "alertsClient",
    () =>
      fetchAlertsClient().then((data) => {
        const dataTemp: any[] = [...data.results];
        const newData = dataTemp?.map((d) => {
          return {
            ...d,
            visible: false,
          };
        });
        setDataAlerts(newData);
        return data;
      })
  );

  const getAlertTypeColor = (alertTypeColor: string) => {
    switch (alertTypeColor) {
      case "DANGER":
        return "text-red-600";
      case "WARNING":
        return "text-yellow-500";
      case "SUCCESS":
        return "text-green-500";
      case "INFO":
        return "text-blue-500";
      case "SECONDARY":
        return "text-gray-500";
      case "PURPLE":
        return "text-purple-500";
      case "VIKEY":
        return "text-cyan-600";
      case "YELLOW":
        return "text-yellow-400";
      case "FUCSIA":
        return "text-indigo-600";
      case "LIME":
        return "text-green-300";
      case "SALMON":
        return "text-pink-300";
      default:
        return "text-gray-500";
    }
  };

  const DashboardCard = ({
    title,
    value,
    sparkLineData,
    dataChange,
    positiveChange = false,
  }: {
    title: string;
    value: number | string | undefined;
    dataChange?: string;
    sparkLineData?: number[];
    positiveChange?: boolean;
  }) => (
    <div className={"mb-2 md:mb-0 xs:w-full"}>
      <div
        style={{
          boxShadow:
            "0px 2px 4px rgba(0,0,0,0.06), 0px 0px 0px 1px rgba(0,0,0,0.07)",
        }}
        className={"bg-white rounded-2xl p-4"}
      >
        <div className={"text-gray-700 font-medium mb-3"}>{title}</div>
        <div className={"flex items-center -mx-4"}>
          <div className={"px-4 text-3xl font-bold text-gray-700"}>{value}</div>
          {sparkLineData && sparkLineData.length > 0 && (
            <div className={"px-4 h-12 max-w-sm flex-1"}>
              <Sparklines height={40} data={sparkLineData}>
                <SparklinesLine style={{ fill: "none" }} color="#00BDE1" />
                <SparklinesSpots />
              </Sparklines>
            </div>
          )}
        </div>
        {dataChange && (
          <div
            className={`${
              positiveChange ? "text-green-600" : "text-red-600"
            } text-sm mt-1 font-medium`}
          >
            {dataChange}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <PageWrapper>
      {isLoadingAlerts && (
        <div className="mb-8">
          <Skeleton width={"12rem"} height={"2rem"}></Skeleton>
        </div>
      )}

      {dataAlerts
        ?.filter((alert: any) => {
          if (profile?.has_done_dash_setup) {
            return alert.alert_key !== "ASSISTANCE";
          }
          return alert;
        })
        ?.filter((alert: any) => {
          if (i18n.language !== 'it') {
            return alert.alert_key !== "PARTHERSHIP_VIKEY_SWITCHO";
          }
          return alert;
        })
        ?.map((alert: any) => (
          <div
            style={{
              boxShadow:
                "0px 2px 8px rgba(0,0,0,0.03), 0px 0px 0px 1px rgba(0,0,0,0.05)",
            }}
            className={"bg-gray-50 rounded-2xl overflow-hidden mt-1"}
          >
            <div
              className={
                "bg-white items-center w-full p-4 flex flex-wrap justify-between rounded-2xl rounded-tr-none rounded-tl-none"
              }
            >
              {/* TITOLO E SOTTOTITOLO */}
              <div className="md:w-3/4">
                <strong className={getAlertTypeColor(alert.type)}>
                  {alert.lang[i18n.language].title}
                </strong>
                {alert.lang[i18n.language].sub_title === "" ? (
                  <p className="text-white">.</p>
                ) : (
                  <p className={`text-cyan-700 text-sm mb-2`}>
                    {ReactHtmlParser(alert.lang[i18n.language].sub_title)}
                  </p>
                )}
              </div>
              {/* ACTION BOTTONE */}
              <div>
                {alert.lang[i18n.language].action === "LINK" &&
                  !alert.lang[i18n.language].action_newtab && (
                    <button
                      style={{
                        boxShadow:
                          "0px 2px 4px rgba(0,0,0,0.06), 0px 0px 0px 1px rgba(0,0,0,0.07)",
                      }}
                      className={`bg-white flex items-center px-3 py-2 rounded-2xl ${getAlertTypeColor(
                        alert.type
                      )}`}
                      onClick={() =>
                        history.push(alert.lang[i18n.language].action_link)
                      }
                    >
                      {alert.lang[i18n.language].action_text}
                      <Icon
                        name={"support"}
                        size={"20px"}
                        className={"ml-2"}
                      ></Icon>
                    </button>
                  )}
                {alert.lang[i18n.language].action === "LINK" &&
                  alert.lang[i18n.language].action_newtab && (
                    <div>
                      <a
                        href={alert.lang[i18n.language].action_link}
                        target={"_blank"}
                        style={{
                          boxShadow:
                            "0px 2px 4px rgba(0,0,0,0.06), 0px 0px 0px 1px rgba(0,0,0,0.07)",
                        }}
                        className={`bg-white flex items-center px-3 py-2 rounded-2xl ${getAlertTypeColor(
                          alert.type
                        )} ${alert.lang[i18n.language].tracking ?? ''}`}
                        rel="noreferrer"
                      >
                        {alert.lang[i18n.language].action_text}
                        <Icon
                          name={"support"}
                          size={"20px"}
                          className={"ml-2"}
                        ></Icon>
                      </a>
                    </div>
                  )}
                {alert.lang[i18n.language].action === "TEXT" && (
                  <button
                    style={{
                      boxShadow:
                        "0px 2px 4px rgba(0,0,0,0.06), 0px 0px 0px 1px rgba(0,0,0,0.07)",
                    }}
                    className={`bg-white flex items-center px-3 py-2 rounded-2xl ${getAlertTypeColor(
                      alert.type
                    )}`}
                    onClick={() => viewDataAlert(alert)}
                  >
                    {alert.lang[i18n.language].action_text}
                    <Icon
                      name={"support"}
                      size={"20px"}
                      className={"ml-2"}
                    ></Icon>
                  </button>
                )}
              </div>
              {/* DESCRIPTION */}
              {alert.visible && (
                <>
                  <div
                    className={
                      "bg-white items-center w-full p-4 flex flex-wrap justify-between rounded-2xl rounded-tr-none rounded-tl-none"
                    }
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: alert?.lang[i18n.language]?.text,
                      }}
                      className="w-full text-cyan-700 text-sm"
                    ></div>
                  </div>
                </>
              )}
            </div>
          </div>
        ))}

      <div className={"mt-8 font-bold text-gray-700 mb-4"}>
        {t("dashboard.checkInToComplete")}
      </div>
      <DashboradArrivals className={"mb-6"} />

      <div className="flex space-x-4">
        <div className="w-full lg:w-1/2">
          <div className={"mt-8 font-bold text-gray-700 mb-4"}>
            {t("dashboard.yourApartments")}
          </div>
          <DashboardApartments />
        </div>
        <div className={"space-y-2 w-full lg:w-1/2"}>
          <div className={"mt-8 font-bold text-gray-700 mb-4"}>
            {t("dashboard.yourStatistics")}
          </div>
          <DashboardCard
            title={t("dashboard.checkInToday")}
            value={stats?.results?.resv_today}
          />
          <DashboardCard
            title={t("dashboard.checkOutToday")}
            value={stats?.results?.checkout_today}
          />
          <DashboardCard
            title={t("dashboard.checkInMonth")}
            value={stats?.results?.resv_month}
          />
          <DashboardCard
            title={t("dashboard.openingMonth")}
            value={stats?.results?.cmds_thismonth}
          />
        </div>
      </div>
    </PageWrapper>
  );
}
