import {
  clearAllTokens,
  getAuthToken,
  getOldToken,
  saveAuthToken,
  saveOldToken,
  saveRefreshToken,
} from "./tokenStore";
import { ApiRequest } from "../../types";
import {REACT_APP_VIKEY_API_URL as API_URL} from "../utils/config"

// let API_URL = "https://api.vikey.it"

/** 
 * Set the default headers for every request sent to the server
 */
function buildDefaultHeaders(
  headerList: any,
  form: FormData | undefined
): Headers {
  const headers = new Headers();
  if (!form) {
    headers.append("Content-Type", "application/json");
  }
  if (getAuthToken()) {
    if (
      !headerList ||
      !headerList.find((h: any) => h.key === "Authorization")
    ) {
      headers.append("Authorization", "Bearer " + getAuthToken());
    }
  }
  if (headerList) {
    headerList.forEach((h: any) => {
      headers.append(h.key, h.content);
    });
  }
  return headers;
}

export const request = async ({
  method,
  path,
  body,
  form,
  useOldToken = false,
  headers,
  useCors = true,
  renewToken = true,
}: ApiRequest) => {
  if (useOldToken) {
    if (path.indexOf("?") > -1) {
      path += "&token=" + getOldToken();
    } else {
      path += "?token=" + getOldToken();
    }
  }

  const httpResult = await fetch(API_URL + path, {
    headers: buildDefaultHeaders(headers, form),
    mode: useCors ? "cors" : "no-cors",
    method,
    body: body ? JSON.stringify(body) : form ? form : null,
  });

  if (!httpResult.ok) {
    const authResponse = httpResult.clone();
    const data = await authResponse.json();
    if (data && data.msg) {
      switch (data.msg) {
        case "Token has expired":
          refreshToken();
          break;
        case "Signature verification failed":
          redirectToLogin();
          break;
        case "Invalid header padding":
          redirectToLogin();
          break;
        case "Not enough segments":
          redirectToLogin();
          break;
      }
    }
  }

  if (renewToken && httpResult.status === 401) {
    // token expired, try to renew it
    refreshToken();
  }

  return {
    status: httpResult.status,
    headers: httpResult.headers,
    text: async () => httpResult.text(),
    blob: async () => httpResult.blob(),
    json: async () => httpResult.json(),
    ok: httpResult.ok,
  };
};

function redirectToLogin() {
  clearAllTokens();
  document.location.href = "/";
}

async function refreshToken() {
  try {
    console.debug("Trying to renew token");
    const response = await request({
      method: "POST",
      path: "/api/v3/auth/refresh",
      renewToken: false,
    });

    const token = await response.json();

    if (token) {
      saveAuthToken(token.access_token);
      saveOldToken(token.old_token);
      saveRefreshToken(token.refresh_token);
    }
  } catch (e) {
    console.error("Error renewing token");
    // can not exchange a new token, we need to redirect user to login
    redirectToLogin();
  }
}
