import React from "react";
import { useQuery } from "react-query";
import { fetchTokenChannel } from "../../shared/queries";
import Skeleton from "react-loading-skeleton";
import i18n from "../../langs";

type Props = {
  isLoading: boolean;
  user_key: any;
};

const ImportCM = (props: Props) => {
  const { isLoading, user_key } = props;
  const { data } = useQuery<any>(["channelimport", i18n.language, user_key], () => fetchTokenChannel(user_key));
  if (isLoading) {
    return (
      <div className={"mt-2 flex border-b pb-2 border-gray-200"}>
        <div className={"px-1 flex-1"}>
          <Skeleton />
        </div>

        <div className={"px-1 flex-1"}>
          <Skeleton />
        </div>

        <div className={"px-1 flex-1"}>
          <Skeleton />
        </div>

        <div className={"px-1 flex-1"}>
          <Skeleton />
        </div>

        <div className={"px-1 flex-1"}>
          <Skeleton />
        </div>
      </div>
    );
  }
  return (
    <div
      className="relative overflow-hidden"
      style={{ paddingBottom: "46.25%" }}
    >
      <iframe
        className="overflow-hidden border-0 align-middle self-center absolute top-0 left-0 w-full h-full"
        title="import-channel-manager"
        allow="clipboard-read; clipboard-write"
        src={`https://app.channex.io/auth/exchange?oauth_session_key=${data?.results?.token}&app_mode=headless&redirect_to=/channels&property_id=${data?.results?.property_id}&lng=${i18n.language}`}
        // src={`https://staging.channex.io/auth/exchange?oauth_session_key=${data?.results?.token}&app_mode=headless&redirect_to=/channels&property_id=${data?.results?.property_id}`}
      ></iframe>
    </div>
  );
};

export default ImportCM;
