import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Select from "react-select";
import DatePicker from '../DatePicker'
import { fetchV3AllLocals } from "../../shared/queries";
import dayjs from "dayjs";
import DateRangePicker from "../DateRangePicker";
import SelectInput from "../SelectInput";
import { useTranslation } from "react-i18next";
import FormField from "../FormField";
import { BaseSelectItem } from "../../types";


export default function ExportRegistry ({
  onChange
} : {
  onChange: (data: any) => void
}) {

  const {
    data: locals,
    isFetching: isFetchingLocals
  } = useQuery('localsv3', fetchV3AllLocals)

  const { t } = useTranslation()


  const [fromDate, setFromDate] = useState<string>(dayjs().format('YYYY-MM-DD'))
  const [toDate, setToDate] = useState<string>(dayjs().format('YYYY-MM-DD'))
  const [localKey, setLocalKey] = useState<string|undefined>('')

  useEffect(() => {
    onChange({
      action: 'REGISTRYBOOK',
      day_from: fromDate,
      day_to: toDate,
      local_key: localKey
    })
  }, [
    localKey,
    fromDate,
    toDate
  ])

  return (
    <div className={'p-4'}>
      <div className="text-lg font-semibold">
        {t('export.exportTravelerRecords')}
      </div>

      <div className="mt-4 text-gray-600 text-sm">
        {t('export.exportTravelerRecordsDesc')}
      </div>

      <div className="mt-6 flex flex-col space-y-4">
        <DateRangePicker
          inputFormat={'YYYY-MM-DD'}
          displayFormat={'DD-MM-YYYY'}
          outputFormat={'DD-MM-YYYY'}
          size={'sm'}
          value={fromDate + ' -> ' + toDate}
          onChange={value => {
            const { from, to } = value as any
            setFromDate(dayjs(from).format('YYYY-MM-DD'))
            setToDate(dayjs(to).format('YYYY-MM-DD'))
          }} />
        
        <FormField
          label={t('navigation.apartments')}>
          <SelectInput<BaseSelectItem, false>
            menuPortalTarget={document.body}
            styles={{
              container: base => ({ ...base, zIndex: 999 }),
              menuPortal: base => ({ ...base, zIndex: 999 })
            }}
            isLoading={isFetchingLocals}
            menuShouldScrollIntoView={false}
            onChange={item => {
              if (item) setLocalKey(item.value)
            }}
            options={(locals && locals.results) ? locals.results.map(l => {
              return {
                label: l.name,
                value: l.local_key
              }
            }) : [] } />
        </FormField>
      </div>
    </div>
  )

}
