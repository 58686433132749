import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DetailRow from "../components/DetailRow";
import FormSectionTitle from "../components/FormSectionTitle";
import PageWrapper from "../components/PageWrapper";
import AddNotificationEmail from "../components/profile/AddNotificationEmail";
import UpdateProfile from "../components/profile/UpdateProfile";
import UpdatePassword from "../components/profile/UpdatePassword";
import { RootState } from "../reducers";
import {
  deleteNotificationEmail,
  fetchNotifications,
  updateNotifications,
} from "../shared/queries";
import { useTranslation } from "react-i18next";
import Icon from "../components/Icon";
import Checkbox from "../components/Checkbox";
import BaseTable from "../components/BaseTable";
import { useHistory } from "react-router-dom";
import ProfileToken from "../components/profile/ProfileToken";

export default function Profile() {
  const { t } = useTranslation();
  const history = useHistory()

  const [addEmail, setAddEmail] = useState<boolean>(false);
  const [showUpdatePassword, setShowUpdatePassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [updateProfile, setUpdateProfile] = useState(false);
  const { profile } = useSelector((state: RootState) => state.login);

  const {
    data: notifications,
    isLoading: loadingNotifications,
    refetch: refetchNotifications,
  } = useQuery("notifications", fetchNotifications);

  const preferredMutation = useMutation(
    (data: any) => updateNotifications(data),
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
        setLoading(false);
        refetchNotifications();
      },
      onError: (error: Error) => {
        toast.error(t(error.message) || t("general.requestError"));
        setLoading(false);
      },
    }
  );

  const deleteEmailMutation = useMutation(
    (id: string | number) => deleteNotificationEmail(id),
    {
      onSuccess: () => {
        toast.success(t("general.operationCompleted"));
        refetchNotifications();
      },
      onError: (error: Error) => {
        toast.error(t(error.message) || t("general.requestError"));
      },
    }
  );

  const resendCodeMutation = useMutation(
    (id: string | number) =>
      updateNotifications({
        id,
        action: "RESENDCODE",
      }),
    {
      onSuccess: () => {
        toast.success(t("general.operationCompleted"));
      },
      onError: () => {
        toast.error(t("general.requestError"));
      },
    }
  );

  return (
    <PageWrapper>
      <FormSectionTitle
        title={t("profile.yourProfile")}
        editLabel={t("general.edit")}
        edit={true}
        onEdit={() => {
          setUpdateProfile(!updateProfile);
        }}
      ></FormSectionTitle>

      <DetailRow loading={!profile} label={"Email"}>
        {profile?.mail || "ND"}
      </DetailRow>

      <DetailRow loading={!profile} label={t("profile.profileName")}>
        {profile?.name || "ND"}
      </DetailRow>

      <DetailRow loading={!profile} label={t("profile.contactName")}>
        {profile?.contact_fullname || "ND"}
      </DetailRow>

      <DetailRow loading={!profile} label={t("general.address")}>
        {profile?.address}, {profile?.city} ({profile?.province}) {profile?.zip}
      </DetailRow>

      <DetailRow loading={!profile} label={t("profile.password")}>
        ••••••••{" "}
        <button
          onClick={() => setShowUpdatePassword(true)}
          className={
            "text-cyan-600 font-medium focus:outline-none focus:ring-3 focus:ring-cyan-100 px-2 rounded-lg"
          }
        >
          {t("general.edit")}
        </button>
      </DetailRow>

      <FormSectionTitle
        dense
        title={t("profile.notifications")}
        editLabel={t("profile.addEmail")}
        edit={true}
        onEdit={() => setAddEmail(true)}
      ></FormSectionTitle>

      <BaseTable
        currentPage={1}
        identifierKey={"id"}
        data={notifications?.results}
        loading={loadingNotifications}
        displayRows={notifications?.results?.length}
        filters={[]}
        onEdit={(id) => {}}
        columns={[
          {
            Header: t<string>("Email"),
            accessor: (row: any) => {
              return row.email;
            },
          },
          {
            Header: t<string>("Owner"),
            accessor: (row: any) => {
              return (
                <div
                  className={
                    "bg-cyan-100 text-cyan-800 text-white inline-block text-xs font-medium px-2 rounded-2xl py-1"
                  }
                >
                  {row.name}
                </div>
              );
            },
          },
          {
            Header: t<string>("profile.verified"),
            accessor: (row: any) => {
              return (
                <div className={"text-gray-500"}>
                  {row.verified_mail && (
                    <span className={"text-green-600 font-medium"}>
                      {t("general.yes")}
                    </span>
                  )}
                  {!row.verified_mail && (
                    <span className={"text-red-600 font-medium"}>
                      {t("general.no")}
                    </span>
                  )}
                </div>
              );
            },
          },
          {
            Header: t<string>("profile.favorite"),
            accessor: (row: any) => {
              return (
                <div className={"text-gray-500"}>
                  {row.preferred && (
                    <span className={"text-green-600 font-medium"}>
                      {t("general.yes")}
                    </span>
                  )}
                  {!row.preferred && (
                    <span className={"text-red-600 font-medium"}>
                      {t("general.no")}
                    </span>
                  )}
                </div>
              );
            },
          },
          {
            Header: t<string>("profile.acceptSummary"),
            accessor: (row: any) => {
              return (
                <Checkbox
                  disabled={loading}
                  checked={row.summary_1d}
                  onChange={() => {
                    setLoading(true);
                    preferredMutation.mutate({
                      users_notifications_id: row.id,
                      summary_1d: !row.summary_1d,
                      action: "SUMMARY_1D",
                    });
                  }}
                  label={""}
                />
              );
            },
          },
          {
            Header: t<string>("resv.notifications"),
            accessor: (row: any) => {
              return (
                <div>
                  {row.verified_mail && (
                    <>
                      {!row.preferred ? (
                        <button
                          disabled={loading}
                          onClick={() => {
                            setLoading(true);
                            preferredMutation.mutate({
                              users_notifications_id: row.id,
                              preferred: true,
                              action: "PREFERRED",
                            });
                          }}
                          className={"font-medium text-cyan-600"}
                        >
                          <Icon name={"star"} size={"20px"} />
                        </button>
                      ) : (
                        <button
                          disabled={loading}
                          onClick={() => {
                            setLoading(true);
                            preferredMutation.mutate({
                              users_notifications_id: row.id,
                              preferred: false,
                              action: "PREFERRED",
                            });
                          }}
                          className={"font-medium text-cyan-600"}
                        >
                          <Icon name={"starfilled"} size={"20px"} />
                        </button>
                      )}
                    </>
                  )}
                  {!row.verified_mail && (
                    <button
                      onClick={() => resendCodeMutation.mutate(row.id)}
                      className={"font-medium text-cyan-600"}
                    >
                      {t("profile.resendCode")}
                    </button>
                  )}
                </div>
              );
            },
          },
          {
            Header: t<string>("profile.delete"),
            accessor: (row: any) => {
              return (
                <div>
                  <button
                    className={"text-red-600"}
                    onClick={() => {
                      deleteEmailMutation.mutate(row.id);
                    }}
                  >
                    <Icon
                      name={"trash"}
                      className="text-center"
                      size={"20px"}
                    />
                  </button>
                </div>
              );
            },
          },
        ]}
      />
      <ProfileToken />
      <UpdateProfile
        visible={updateProfile}
        onSave={() => {
          history.go(0);
          setUpdateProfile(false);
        }}
        onClose={() => setUpdateProfile(false)}
      />

      <AddNotificationEmail
        onClose={() => {
          setAddEmail(false);
          refetchNotifications();
        }}
        visible={addEmail}
      />

      <UpdatePassword
        visible={showUpdatePassword}
        onClose={() => {
          setShowUpdatePassword(false);
        }}
      />
    </PageWrapper>
  );
}
