import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { updateApartment, updateApartmentV3 } from "../../shared/queries"
import { Local } from "../../types"
import FormField from "../FormField"
import Modal from "../Modal"
import ModalActions from "../ModalAction"
import ModalCard from "../ModalCard"
import ModalSection from "../ModalSection"
import TextInput from "../TextInput"

type ApartmentUpdateWifiProps = {
  data: Local,
  visible: boolean,
  onClose?: () => void,
  onSave?: () => void
}

export default function ApartmentUpdateWifi ({
  data,
  visible,
  onClose,
  onSave
} : ApartmentUpdateWifiProps) {

  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const [wifiName, setWifiName] = useState<string>(data.wifi_name || '')
  const [wifiPassword, setWifiPassword] = useState<string>(data.wifi_password || '')

  /**
   * Mutate the selected stripe account
   */
  const mutation = useMutation(() => updateApartmentV3(data.local_key || '', {
    wifi_name: wifiName,
    wifi_password: wifiPassword
  }), {
    onSuccess: () => {
      toast.success(t('general.updated'))
      queryClient.invalidateQueries(['apartment', data.local_key])
      if (onClose) onClose()
    }
  })

  return (
    <Modal
      visible={visible}>
      <ModalCard
        title={t('updateConnection.updateConnection')}>
        <ModalSection>
          <div className={'p-4 flex-wrap flex -mx-2'}>
          <FormField
            className={'px-2 mb-4 w-full'}
            label={t('updateConnection.name')}>
            <TextInput
              value={wifiName}
              onChange={value => setWifiName(value)}></TextInput>
          </FormField>
          <FormField
            className={'px-2 mb-4 w-full'}
            label={t('updateConnection.password')}>
            <TextInput
              value={wifiPassword}
              onChange={value => setWifiPassword(value)}></TextInput>
          </FormField>
        </div>
        </ModalSection>
        <ModalActions
          isLoading={mutation.isLoading}
          saveLabel={t('general.update')}
          onClose={() => {
            if (onClose) onClose()
          }}
          onSave={() => {
            mutation.mutate()
            if (onSave) onSave()
          }}></ModalActions>
      </ModalCard>
    </Modal>
  )

}