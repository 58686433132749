import { ComponentType, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { fetchAllLocals, fetchV3AllLocals, linkLocalToService } from "../../shared/queries";
import { Buyable, LocalService, LocalTable } from "../../types";
import BaseTable from "../BaseTable";
import Checkbox from "../Checkbox";
import Modal from "../Modal";
import ModalActions from "../ModalAction";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";

type ApartmentService = LocalService & {
    mandatory: boolean,
    enabled: boolean
}

type TableData = LocalTable & {
    mandatory: boolean,
    enabled: boolean
}

export default function ManageApartments ({
    visible,
    connectedApartments = [],
    service,
    onClose
} : {
    connectedApartments: ApartmentService[],
    service: Buyable,
    visible: boolean,
    onClose: () => void
}) {

    const { t } = useTranslation()

    const {
        data,
        refetch
    } = useQuery('allApartments', fetchV3AllLocals, {
        onSuccess: (data: any) => setTableData(data.results.map((l:any) => {
            /* TODO FETCH APARTMENTS */
            return {
                ...l,
                enabled: false,
                mandatory: false
            }
        }))
    })

    const [tableData, setTableData] = useState<TableData[]>([])
    const linkMutation = useMutation(() => linkLocalToService(
            service.id,
            tableData.filter(l => l.mandatory).map(l => l.local_key),
            tableData.filter(l => l.enabled).map(l => l.local_key)
        ), {
            onSuccess: () => {
                toast.success(t('general.updated'))
                refetch()
                onClose()
            }
        })
    useEffect(() => {
        if (data && data.results && connectedApartments) {
            setTableData(data.results.map((local:any) => {
                const associated = connectedApartments.find(ca => ca.prop.local_key === local.local_key)
                if (associated) {
                    return {
                        ...local,
                        mandatory: associated.mandatory,
                        enabled: true
                    }
                } else {
                    return {
                        ...local,
                        mandatory: false,
                        enabled: false
                    }
                }
            }))
        }
    }, [ data, connectedApartments ])

    return (
        <Modal visible={visible}>
            <ModalCard
                className={'w-full'}
                title={t('paymentMethods.associateApartments')}>
                <ModalSection>
                    {
                        tableData.map((local: any) => (
                            <div className={'flex items-center space-x-4 justify-between border-b p-4'} key={local.local_key}>
                                <div>
                                    {local.name}
                                </div>
                                <div className={'flex space-x-4'}>
                                    <Checkbox
                                        label={'Abilitato'}
                                        checked={local.enabled}
                                        onChange={() => {
                                            setTableData(
                                                tableData.map(row => {
                                                    if (row.local_key === local.local_key) {
                                                        return {
                                                            ...row,
                                                            enabled: !row.enabled
                                                        }
                                                    }
                                                    return row
                                                })
                                            )
                                        }} />

                                    <Checkbox
                                        label={'Obbligatorio'}
                                        checked={local.mandatory}
                                        onChange={() => {
                                            setTableData(
                                                tableData.map(row => {
                                                    if (row.local_key === local.local_key) {
                                                        return {
                                                            ...row,
                                                            mandatory: !row.mandatory
                                                        }
                                                    }
                                                    return row
                                                })
                                            )
                                        }} />
                                </div>
                            </div>
                        ))
                    }
                </ModalSection>
                <ModalActions
                    onSave={() => {
                        linkMutation.mutate()
                    }}
                    onClose={() => {
                        onClose()
                    }} />
            </ModalCard>
        </Modal>
    )

}