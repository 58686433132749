import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import Button from "../components/Button";
import DeleteDialog from "../components/DeleteDialog";
import CreateContract from "../components/contract/CreateContract";
import PageTitle from "../components/PageTitle";
import {
  fetchContracts,
  DeleteCustPtr,
  fetchAllModules,
} from "../shared/queries";
import { toast } from "react-toastify";
import { moduleId } from "../shared/utils/config";
import Modal from "../components/Modal";
import ModalCard from "../components/ModalCard";
import ModalSection from "../components/ModalSection";
import PageWrapper from "../components/PageWrapper";
import ModalActions from "../components/ModalAction";
import { error } from "console";

export default function ContractsPage() {
  const { data, refetch: refetchContracts } = useQuery("contracts", () =>
    fetchContracts(undefined, true)
  );

  const history = useHistory();
  const { t } = useTranslation();

  const [addContract, setAddContract] = useState(false);

  const [showDelete, setShowDelete] = useState(false);

  const [checkin_id, setCheckin_id] = useState("");
  const [activeContract, setActiveContract] = useState(false);
  const [moduleName, setModuleName] = useState("");
  const [visible, setVisible] = useState(false);

  console.log("tipo data: ", typeof data);

  const deleteMutation = useMutation(() => DeleteCustPtr(checkin_id), {
    onSuccess: () => {
      refetchContracts();
      toast.success(t("toast.successTitle"));
    },
    onError: () => {
      toast.error(t("general.requestError"));
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: dataModules } = useQuery<any>([], () => {
    return fetchAllModules().then((data: any) => {
      const moduleContractFinded = data?.modules?.find(
        (module: any) => module?.id === moduleId.contract
      );
      if (moduleContractFinded?.active_locals_count === 0) {
        setVisible(true);
        setModuleName(t(`module.id${moduleContractFinded.id}`).toUpperCase());
        setActiveContract(false);
      } else {
        setVisible(false);
        setModuleName("");
        setActiveContract(true);
      }
    })
  });
  
  return (
    <div>
      <div className="inline-flex w-full justify-between">
        <PageTitle
          title={t("contracts.contracts")}
          subtitle={t("contracts.description")}
        ></PageTitle>
        <div>
          <Button
            disabled={!activeContract}
            onClick={() => setAddContract(true)}
            label={t("contracts.newContract")}
          />
        </div>
      </div>

      <div className={"space-y-4"}>
        {data &&
          data.results &&
          data.results.map((contract, index) => (
            <div
              key={index}
              className={
                "justify-between cursor-pointer p-4 cst-shadow border rounded-2xl hover:bg-cyan-50 flex"
              }
            >
              <div className="font-medium">{contract.objkey}</div>
              <div>
                <Button
                  disabled={!activeContract}
                  icon={"pencil"}
                  padding={"xs"}
                  flat
                  onClick={() => {
                    history.push("/contracts/" + contract.id);
                  }}
                ></Button>
                <Button
                  disabled={!activeContract}
                  icon={"trash"}
                  padding={"xs"}
                  flat
                  onClick={() => {
                    setShowDelete(true);
                    if (contract && contract.id)
                      setCheckin_id("" + contract.id);
                  }}
                ></Button>
              </div>
            </div>
          ))}
      </div>

      <CreateContract
        visible={addContract}
        onClose={() => setAddContract(false)}
      />

      <DeleteDialog
        isLoading={deleteMutation.isLoading}
        visible={showDelete}
        message={t("services.removeService")}
        confirmationKey={t("general.iConfirm")}
        onCancel={() => setShowDelete(false)}
        onConfirm={() => {
          deleteMutation.mutate();
          setShowDelete(false);
        }}
      />

      <Modal visible={visible} className={"w-full"}>
        <ModalCard
          title={`${moduleName.toUpperCase()} (${t("module.statusInactive")})`}
          className={"w-auto"}
        >
          <ModalSection>
            <PageWrapper>
              <span className="text-gray-600 font-medium">
                {t("module.buyModuleFunctionality", {
                  name: moduleName.toUpperCase(),
                })}
              </span>
            </PageWrapper>
            <ModalActions
              onClose={() => setVisible(false)}
              onSave={() =>
                history.push(`/modules/module#${moduleId.contract}`)
              }
              saveLabel={t('module.purchase')}
            />
          </ModalSection>
        </ModalCard>
      </Modal>
    </div>
  );
}
