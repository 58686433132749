import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Checkbox from '../components/Checkbox';
import ExtraFieldManager from '../components/customcheckin/ExtraFieldManager';
import FormField from '../components/FormField';
import FormSectionTitle from '../components/FormSectionTitle';
import LanguageSelector from '../components/LanguageSelector';
import PageTitle from '../components/PageTitle';
import { Editor } from '@tinymce/tinymce-react';
import PageWrapper from '../components/PageWrapper';
import TabSelector from '../components/TabSelector';
import TextInput from '../components/TextInput';
import {
  fetchCheckin,
  updateCheckin,
  deleteCheckInsLanguage,
  updateCheckInLanguage,
  fetchDefaultCheckin,
  fetchPerms,
} from '../shared/queries';
import { BaseSelectItem, CheckInContent, CheckinLang, CustomCheckin } from '../types';
import Button from '../components/Button';
import PreviousPage from '../components/PreviousPage';
// import useBreadcrumbs from '../hooks/useBreadcrumbs';
import SpecialTags from '../components/common/SpecialTags';
import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';
import SelectInput from '../components/SelectInput';

type CustomCheckinParams = {
  id: string;
};

export default function CustomCheckinPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<CustomCheckinParams>();

  const [currentLang, setCurrentLang] = useState<
    'it' | 'fr' | 'de' | 'es' | 'ru' | 'pt' | 'zh' | 'en' | 'zh'
  >('it');
  const [checkIn, setCheckIn] = useState<CustomCheckin | undefined>(undefined);
  const [currentTab, setCurrentTab] = useState<string>('initial');

  const { data, isLoading } = useQuery('checkins', () => fetchCheckin(id));
  const { data: defaultData } = useQuery('defaultcheckins', () =>
    fetchDefaultCheckin()
  );

  const { data: dataUsers } = useQuery("accounts", fetchPerms);


  const emptyObject: CheckinLang = {
    DEL: true,
    ask_docs_all_guests: false,
    ask_docs_expiration_date: false,
    ask_docs_issue_date: false,
    ask_docs_signature: false,
    ask_docs_signature_all_guests: false,
    ask_docs_address: false,
    ask_docs_address_all_guests: false,
    cust_ptr: data?.results?.id ?? -1,
    date: '',
    extra: '[]',
    keycustc: '',
    lang: currentLang,
    objkey: '',
    objtype: '',
    p_DEL: false,
    p_date: '',
    p_objkey: '',
    p_objtype: '',
    p_user_key: '',
    step_0: '',
    step_1: '',
    step_2: '',
    step_3: '',
    step_4: '',
    user_key: '',
  };

  const mutation = useMutation(
    () =>
      updateCheckin({
        id: data?.results?.id,
        user_key: data?.results && data?.results.user_key,
        objkey: checkIn?.objkey,
      }),
    {
      onSuccess: () => {
        toast.success(t('general.created'));
        history.goBack();
      },
      onError: (error: Error) => {
        toast.error(t(error.message) || t("general.requestError"));
      },
    }
  );

  const deleteLangMutation = useMutation(
    (data: any) => deleteCheckInsLanguage(data.cust_ptr, data.lang),
    {
      onSuccess: () => {},
    }
  );

  const updateLangMutation = useMutation(
    (data: any) => updateCheckInLanguage(data),
    {
      onSuccess: () => {},
    }
  );

  function handleSubmit() {
    mutation.mutate();
    if (checkIn) {
      Object.keys(checkIn.langs).forEach((key) => {
        if (
          (key === 'it' ||
            key === 'en' ||
            key === 'fr' ||
            key === 'de' ||
            key === 'es' ||
            key === 'zh' ||
            key === 'ru' ||
            key === 'pt' || 
            key === 'zh') &&
          checkIn.langs[key]
        ) {
          if (checkIn.langs[key]?.DEL && checkIn.langs[key]?.id) {
            deleteLangMutation.mutate({
              cust_ptr: checkIn.langs[key].cust_ptr,
              lang: checkIn.langs[key].lang,
            });
          } else {
            if (!checkIn.langs[key]?.DEL){
              updateLangMutation.mutate(checkIn.langs[key]);
            }
          }
        }
      });
    }
  }

  const defaultCheckin = {
    DEL: true,
    ask_docs_all_guests: false,
    ask_docs_expiration_date: false,
    ask_docs_issue_date: false,
    ask_docs_signature: false,
    ask_docs_signature_all_guests: false,
    ask_docs_address: false,
    ask_docs_address_all_guests: false,
    cust_ptr: data?.results?.id ?? -1,
    date: '',
    extra: '[]',
    keycustc: '',
    lang: 'it',
    objkey: '',
    objtype: '',
    p_DEL: true,
    p_date: '',
    p_objkey: '',
    p_objtype: '',
    p_user_key: '',
    step_0: '',
    step_1: '',
    step_2: '',
    step_3: '',
    step_4: '',
    user_key: '',
  };

  useEffect(() => {
    if (data && data.results) {
      if (data && data.results && data.results.langs) {
        if (defaultData) {
          for (const lang in data.results.langs) {
            if (!data.results.langs[lang as keyof CheckInContent]) {
              data.results.langs[lang as keyof CheckInContent] = {
                ...defaultCheckin,
                step_0: defaultData.data[lang as keyof CheckInContent]?.step_0,
                step_1: defaultData.data[lang as keyof CheckInContent]?.step_1,
                step_2: defaultData.data[lang as keyof CheckInContent]?.step_2,
                step_3: defaultData.data[lang as keyof CheckInContent]?.step_3,
                step_4: defaultData.data[lang as keyof CheckInContent]?.step_4,
                lang: lang
              };
            }
          }
        }
      }
      setCheckIn(data.results);
    }
  }, [data]);

  /* useBreadcrumbs([
    { label: t('navigation.administration'), route: '/configuration/bureaucracy' },
    {
      label: t("                In.customCheckin"),
      route: '/configuration/custom-checkins',
    },
    { label: checkIn ? checkIn.objkey : '..' },
  ]); */

  return (
    <PageWrapper>
      {checkIn && checkIn.langs /* && checkIn.langs[currentLang] */ && (
        <>
          <PreviousPage
            className={"mb-4"}
            label={"torna indietro"}
          ></PreviousPage>

          <PageTitle title={checkIn.objkey}></PageTitle>

          <div className={"space-y-4"}>
            <FormField label={"Account"} errorKey={"user_key"}>
              <SelectInput<BaseSelectItem, false>
                /* onChange={(item) => {
                if (item) setAccount(item);
              }}
              options={
                dataUsers && dataUsers.results
                  ? dataUsers.results.map((account: any) => {
                      return {
                        label: account.o_name,
                        value: account.o_user_key,
                      };
                    })
                  : []
              } */
                value={{
                  label: dataUsers?.results?.find(
                    (account: any) => {
                      if(data && data.results){
                        return data?.results.user_key === account.o_user_key
                      }
                      return account
                      }
                      
                  )?.o_name,
                  value: dataUsers?.results?.find(
                    (account: any) => {
                      if(data && data.results){
                        return data?.results.user_key === account.o_user_key
                      }
                      return account
                      }
                      
                  )?.o_user_key,
                }}
              />
            </FormField>
            <FormField label={t("customCheckIn.key")}>
              <TextInput
                onChange={(val) => {
                  setCheckIn({
                    ...checkIn,
                    objkey: val,
                  });
                }}
                value={checkIn.objkey}
              ></TextInput>
            </FormField>

            <FormField label={t("reservations.languageTemplate")}>
              <LanguageSelector
                onChange={(val) => {
                  if (!checkIn.langs[val]) {
                    setCheckIn({
                      ...checkIn,
                      langs: {
                        ...checkIn.langs,
                        [val]: { ...emptyObject, lang: val },
                      },
                    });
                  }
                  setCurrentLang(val);
                }}
              ></LanguageSelector>
            </FormField>
          </div>

          <div className="mt-8 flex space-x-2">
            <div className="w-auto">
              <Switch
                onChange={() => {
                  setCheckIn({
                    ...checkIn,
                    langs: {
                      ...checkIn.langs,
                      [currentLang]: {
                        ...checkIn.langs[currentLang],
                        DEL: checkIn.langs[currentLang]?.DEL ? false : true,
                      },
                    },
                  });
                }}
                checked={checkIn.langs[currentLang]?.DEL === false}
              ></Switch>
            </div>
            <div className={"mt-1 font-medium"}>
              {t("createCustomEmail.languageActivated")}
            </div>
          </div>

          {!checkIn.langs[currentLang]?.DEL && (
            <>
              <FormSectionTitle
                title={t("customCheckIn.requests")}
              ></FormSectionTitle>

              <div className={"space-y-2 mb-8"}>
                <Checkbox
                  checked={checkIn.langs[currentLang]?.ask_docs_all_guests}
                  onChange={() => {
                    if (currentLang) {
                      setCheckIn({
                        ...checkIn,
                        langs: {
                          ...checkIn.langs,
                          [currentLang]: {
                            ...checkIn.langs[currentLang],
                            ask_docs_all_guests:
                              !checkIn.langs[currentLang]?.ask_docs_all_guests,
                          },
                        },
                      });
                    }
                  }}
                  label={t("customCheckIn.allDocsReq")}
                ></Checkbox>

                <Checkbox
                  checked={
                    checkIn.langs[currentLang]?.ask_docs_expiration_date
                  }
                  onChange={() => {
                    if (currentLang) {
                      setCheckIn({
                        ...checkIn,
                        langs: {
                          ...checkIn.langs,
                          [currentLang]: {
                            ...checkIn.langs[currentLang],
                            ask_docs_expiration_date:
                              !checkIn.langs[currentLang]
                                ?.ask_docs_expiration_date,
                          },
                        },
                      });
                    }
                  }}
                  label={t("customCheckIn.expirationReq")}
                ></Checkbox>

                <Checkbox
                  checked={checkIn.langs[currentLang]?.ask_docs_issue_date}
                  onChange={() => {
                    if (currentLang) {
                      setCheckIn({
                        ...checkIn,
                        langs: {
                          ...checkIn.langs,
                          [currentLang]: {
                            ...checkIn.langs[currentLang],
                            ask_docs_issue_date:
                              !checkIn.langs[currentLang]?.ask_docs_issue_date,
                          },
                        },
                      });
                    }
                  }}
                  label={t("customCheckIn.issueDateReq")}
                ></Checkbox>

                <Checkbox
                  checked={checkIn.langs[currentLang]?.ask_docs_signature}
                  onChange={() => {
                    if (currentLang) {
                      setCheckIn({
                        ...checkIn,
                        langs: {
                          ...checkIn.langs,
                          [currentLang]: {
                            ...checkIn.langs[currentLang],
                            ask_docs_signature:
                              !checkIn.langs[currentLang]?.ask_docs_signature,
                          },
                        },
                      });
                    }
                  }}
                  label={t("customCheckIn.hostSignReq")}
                ></Checkbox>

                <Checkbox
                  checked={
                    checkIn.langs[currentLang]?.ask_docs_signature_all_guests
                  }
                  onChange={() => {
                    if (currentLang) {
                      setCheckIn({
                        ...checkIn,
                        langs: {
                          ...checkIn.langs,
                          [currentLang]: {
                            ...checkIn.langs[currentLang],
                            ask_docs_signature_all_guests:
                              !checkIn.langs[currentLang]
                                ?.ask_docs_signature_all_guests,
                          },
                        },
                      });
                    }
                  }}
                  label={t("customCheckIn.allSignReq")}
                ></Checkbox>

                <Checkbox
                  checked={
                    checkIn.langs[currentLang]?.ask_docs_address
                  }
                  onChange={() => {
                    if (currentLang) {
                      setCheckIn({
                        ...checkIn,
                        langs: {
                          ...checkIn.langs,
                          [currentLang]: {
                            ...checkIn.langs[currentLang],
                            ask_docs_address:
                              !checkIn.langs[currentLang]
                                ?.ask_docs_address,
                          },
                        },
                      });
                    }
                  }}
                  label={t("customCheckIn.AddrReq")}
                ></Checkbox>

              <Checkbox
                  checked={
                    checkIn.langs[currentLang]?.ask_docs_address_all_guests
                  }
                  onChange={() => {
                    if (currentLang) {
                      setCheckIn({
                        ...checkIn,
                        langs: {
                          ...checkIn.langs,
                          [currentLang]: {
                            ...checkIn.langs[currentLang],
                            ask_docs_address_all_guests:
                              !checkIn.langs[currentLang]
                                ?.ask_docs_address_all_guests,
                          },
                        },
                      });
                    }
                  }}
                  label={t("customCheckIn.allAddrReq")}
                ></Checkbox>
              </div>

              <TabSelector
                tabs={[
                  {
                    key: "initial",
                    label: t("customCheckIn.initialPage"),
                    active: currentTab === "initial",
                  },
                  {
                    key: "generic",
                    label: t("customCheckIn.infoPage"),
                    active: currentTab === "generic",
                  },
                  {
                    key: "documents",
                    label: t("customCheckIn.docsPage"),
                    active: currentTab === "documents",
                  },
                  {
                    key: "payment",
                    label: t("customCheckIn.paymentPage"),
                    active: currentTab === "payment",
                  },
                  {
                    key: "contract",
                    label: t("customCheckIn.contractPage"),
                    active: currentTab === "contract",
                  },
                ]}
                className={"mt-4 mb-8"}
                onChange={(tab) => setCurrentTab(tab.key)}
              ></TabSelector>

              <SpecialTags target={"CC"} />

              {currentTab === "initial" && (
                <div className="flex space-x-6">
                  <div className="w-full">
                    <Editor
                      apiKey={
                        "af1s9ngxeroefg0odkelxcvqpjzqffflygu2cw2tqk9jexpo"
                      }
                      value={checkIn.langs[currentLang]?.step_0}
                      init={{
                        promotion:false,
                        height: 350,
                        plugins: [
                          'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                          'searchreplace', 'wordcount', 'visualblocks', 'visualchars', 'code', 'fullscreen', 'insertdatetime',
                          'media', 'table', 'emoticons', 'help'
                        ],
                        toolbar: 'undo redo | styles | bold italic backcolor | alignleft aligncenter alignright alignjustify | ' +
                          'bullist numlist outdent indent | link | ' +
                          'removeformat | help',
                        menubar: 'file edit view insert format tools table help',
                      }}
                      onEditorChange={(value) => {
                        if (currentLang) {
                          setCheckIn({
                            ...checkIn,
                            langs: {
                              ...checkIn.langs,
                              [currentLang]: {
                                ...checkIn.langs[currentLang],
                                step_0: value,
                              },
                            },
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              )}

              {currentTab === "generic" && (
                <div className="flex space-x-6">
                  <div className="w-1/2">
                    <Editor
                      apiKey={
                        "af1s9ngxeroefg0odkelxcvqpjzqffflygu2cw2tqk9jexpo"
                      }
                      value={checkIn.langs[currentLang]?.step_1}
                      init={{
                        promotion:false,
                        height: 350,
                        plugins: [
                          'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                          'searchreplace', 'wordcount', 'visualblocks', 'visualchars', 'code', 'fullscreen', 'insertdatetime',
                          'media', 'table', 'emoticons', 'help'
                        ],
                        toolbar: 'undo redo | styles | bold italic backcolor | alignleft aligncenter alignright alignjustify | ' +
                          'bullist numlist outdent indent | link | ' +
                          'removeformat | help',
                        menubar: 'file edit view insert format tools table help',
                      }}
                      onEditorChange={(value) => {
                        if (currentLang) {
                          setCheckIn({
                            ...checkIn,
                            langs: {
                              ...checkIn.langs,
                              [currentLang]: {
                                ...checkIn.langs[currentLang],
                                step_1: value,
                              },
                            },
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="w-1/2">
                    <ExtraFieldManager
                      section={"generic"}
                      lang={checkIn.langs[currentLang]}
                      onChange={(extras) => {
                        if (currentLang) {
                          setCheckIn({
                            ...checkIn,
                            langs: {
                              ...checkIn.langs,
                              [currentLang]: {
                                ...checkIn.langs[currentLang],
                                extra: JSON.stringify(extras),
                              },
                            },
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              )}

              {currentTab === "documents" && (
                <div className="flex space-x-6">
                  <div className="w-1/2">
                    <Editor
                      apiKey={
                        "af1s9ngxeroefg0odkelxcvqpjzqffflygu2cw2tqk9jexpo"
                      }
                      value={checkIn.langs[currentLang]?.step_2}
                      init={{
                        promotion:false,
                        height: 350,
                        plugins: [
                          'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                          'searchreplace', 'wordcount', 'visualblocks', 'visualchars', 'code', 'fullscreen', 'insertdatetime',
                          'media', 'table', 'emoticons', 'help'
                        ],
                        toolbar: 'undo redo | styles | bold italic backcolor | alignleft aligncenter alignright alignjustify | ' +
                          'bullist numlist outdent indent | link | ' +
                          'removeformat | help',
                        menubar: 'file edit view insert format tools table help',
                      }}
                      onEditorChange={(value) => {
                        if (currentLang) {
                          setCheckIn({
                            ...checkIn,
                            langs: {
                              ...checkIn.langs,
                              [currentLang]: {
                                ...checkIn.langs[currentLang],
                                step_2: value,
                              },
                            },
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="w-1/2">
                    <ExtraFieldManager
                      section={"documents"}
                      lang={checkIn.langs[currentLang]}
                      onChange={(extras) => {
                        console.log("received", extras);
                        if (currentLang) {
                          setCheckIn({
                            ...checkIn,
                            langs: {
                              ...checkIn.langs,
                              [currentLang]: {
                                ...checkIn.langs[currentLang],
                                extra: JSON.stringify(extras),
                              },
                            },
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              )}

              {currentTab === "payment" && (
                <div className="flex space-x-6">
                  <div className="w-full">
                    <Editor
                      apiKey={
                        "af1s9ngxeroefg0odkelxcvqpjzqffflygu2cw2tqk9jexpo"
                      }
                      value={checkIn.langs[currentLang]?.step_3}
                      init={{
                        promotion:false,
                        height: 350,
                        plugins: [
                          'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                          'searchreplace', 'wordcount', 'visualblocks', 'visualchars', 'code', 'fullscreen', 'insertdatetime',
                          'media', 'table', 'emoticons', 'help'
                        ],
                        toolbar: 'undo redo | styles | bold italic backcolor | alignleft aligncenter alignright alignjustify | ' +
                          'bullist numlist outdent indent | link | ' +
                          'removeformat | help',
                        menubar: 'file edit view insert format tools table help',
                      }}
                      onEditorChange={(value) => {
                        if (currentLang) {
                          setCheckIn({
                            ...checkIn,
                            langs: {
                              ...checkIn.langs,
                              [currentLang]: {
                                ...checkIn.langs[currentLang],
                                step_3: value,
                              },
                            },
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              )}

              {currentTab === "contract" && (
                <div className="flex space-x-6">
                  <div className="w-full">
                    <Editor
                      apiKey={
                        "af1s9ngxeroefg0odkelxcvqpjzqffflygu2cw2tqk9jexpo"
                      }
                      value={checkIn.langs[currentLang]?.step_4}
                      init={{
                        promotion:false,
                        height: 350,
                        plugins: [
                          'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                          'searchreplace', 'wordcount', 'visualblocks', 'visualchars', 'code', 'fullscreen', 'insertdatetime',
                          'media', 'table', 'emoticons', 'help'
                        ],
                        toolbar: 'undo redo | styles | bold italic backcolor | alignleft aligncenter alignright alignjustify | ' +
                          'bullist numlist outdent indent | link | ' +
                          'removeformat | help',
                        menubar: 'file edit view insert format tools table help',
                      }}
                      onEditorChange={(value) => {
                        if (currentLang) {
                          setCheckIn({
                            ...checkIn,
                            langs: {
                              ...checkIn.langs,
                              [currentLang]: {
                                ...checkIn.langs[currentLang],
                                step_4: value,
                              },
                            },
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          )}

          <div className="mt-6 mb-6">
            <Button onClick={handleSubmit}>{t("general.save")}</Button>
          </div>
        </>
      )}
    </PageWrapper>
  );
}
