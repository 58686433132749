import React from "react";
import Icon from "./Icon";

const PaginationItemArrow: React.FC<{
  back: boolean;
  onClick: () => void;
  disabled: boolean;
}> = (props) => {
  const classRound = props.back ? "rounded-l-lg" : "rounded-r-lg";
  const classColor = props.disabled
    ? "text-gray-300 bg-white border border-gray-200"
    : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700";

  const classDisabled = props.disabled
    ? "cursor-not-allowed"
    : "hover:bg-gray-100 hover:text-gray-700";

  return (
    <li>
      <button
        className={`block py-1.5 px-2.5 ml-0 leading-tight ${classColor} ${classDisabled}  ${classRound}`}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        <span className="sr-only">Previous</span>
        {props.back ? (
          <Icon name="arrow-narrow-left" size="16" className="w-5 h-5" />
        ) : (
          <Icon name="arrow-narrow-right" size="16" className="w-5 h-5" />
        )}
      </button>
    </li>
  );
};

const PaginationItem: React.FC<{
  page: number | string;
  highlight: boolean;
  disabled: boolean;
  children: React.ReactNode;
  onClick: (page: number | string) => void;
}> = (props) => {
  const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!props.disabled) {
      props.onClick(props.page);
    }
  };

  let className = "block py-1.5 px-2.5 leading-tight border";
  if (props.highlight) {
    className += " text-blue-600 bg-blue-50 border-blue-300";
    if (!props.disabled) {
      className += " hover:bg-blue-100 hover:text-blue-700";
    }
  } else {
    className += " text-gray-500 bg-white border-gray-300";
    if (!props.disabled) {
      className += " hover:bg-gray-100 hover:text-gray-700";
    }
  }

  return (
    <li>
      <button className={className} onClick={clickHandler}>
        {props.children}
      </button>
    </li>
  );
};

export default PaginationItem;
export { PaginationItemArrow };
