import Joi from 'joi'

export default Joi.object({
  name: Joi
    .string()
    .required()
    .messages({
      'string.base': 'Nome obbligatorio',
      'string.required': 'Nome obbligatorio',
      'string.empty': 'Nome obbligatorio'
    }),
  email: Joi
    .string()
    .required()
    .email({ tlds: { allow: false } })
    .messages({
      'string.base': 'Email obbligatoria',
      'string.required': 'Email obbligatoria',
      'string.empty': 'Email obbligatoria'
    }),
  password: Joi
    .string()
    .required()
    .messages({
      'string.base': 'Password obbligatoria',
      'string.required': 'Password obbligatoria',
      'string.empty': 'Password obbligatoria'
    }),
  passwordConfirm: Joi
    .string()
    .required()
    .equal(Joi.ref('password'))
    .messages({
      'string.base': 'Conferma password obbligatoria',
      'string.equal': 'Le password non corrispondono',
      'string.required': 'Conferma password obbligatoria',
      'string.empty': 'Conferma password obbligatoria'
    }),
})
