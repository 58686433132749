import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalTable } from '../../../types';
import Checkbox from '../../Checkbox';
import FormField from '../../FormField';
import TextInput from '../../TextInput';
import { useQuery } from 'react-query';
import { fetchAllModules } from '../../../shared/queries';
import { moduleId } from '../../../shared/utils/config';

type DepositSelectorProps = {
  local: LocalTable | undefined;
  integration: any;
  setIntegration: any;
};
export default function DepositSelector({
  local,
  integration,
  setIntegration,
}: DepositSelectorProps) {
  const { t } = useTranslation();
  const [activeDeposit, setActiveDeposit] = useState<any>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: dataModules } = useQuery<any>(['localDepositSelector', local?.local_key, activeDeposit], () => {
    if (local?.local_key) {
      return fetchAllModules(local?.local_key).then((data: any) => {
        const moduleDepositFinded = data?.modules?.find(
          (module: any) => module?.id === moduleId.deposit
        );
        if (moduleDepositFinded && moduleDepositFinded.active) {
          setActiveDeposit(true);
        } else {
          setActiveDeposit(false);
        }
        return data;
      });
    }
  });

  return (
    <>
      <Checkbox
        label={t('resv.requestDepositPayment')}
        disabled={
          (!local ||
            local == null ||
            local.stripe_account == null) || 
            !activeDeposit
        }
        onChange={() => {
          setIntegration({
            ...integration,
            integr_chk_deposit: !integration.integr_chk_deposit,
          });
        }}
        checked={integration?.integr_chk_deposit === 0 ? false : integration?.integr_chk_deposit}
      />
      {integration?.integr_chk_deposit && (
        <div className="bg-gray-100 border rounded-2xl p-4 pb-2 mb-2">
          <FormField label={t("resv.depositTotal")}>
            <TextInput
              type="number"
              value={integration.integr_deposit_amount}
              onChange={(value) => setIntegration((prev:any)=>{
                return {
                  ...prev,
                  integr_deposit_amount: value,
                }
              })}
              numbered={true}
            ></TextInput>
          </FormField>
          <Checkbox
            label={t('resv.chargeGuestCommission')}
            onChange={() => {
              setIntegration({
                ...integration,
                integr_chk_deposit_chargeguest:
                  !integration.integr_chk_deposit_chargeguest,
              });
            }}
            checked={integration.integr_chk_deposit_chargeguest === 0 ? false : integration.integr_chk_deposit_chargeguest}
          />
          <span
            className={`flex-1 cursor-pointer py-1 px-1 text-gray-400 font-medium }`}
          >
            {t('integration.channel')}
          </span>
          <Checkbox
            label={t('integration.enableFor') + ' Airbnb'}
            onChange={() => {
              // console.log(integration.integr_chk_deposit_filterchannel?.AIRBNB, integration.integr_deposit_amount)
              setIntegration({
                ...integration,
                integr_chk_deposit_filterchannel: {
                  EXPEDIA:
                    integration.integr_chk_deposit_filterchannel?.EXPEDIA ?? false,
                  BOOKING:
                    integration.integr_chk_deposit_filterchannel?.BOOKING ?? false,
                  OTHER:
                    integration.integr_chk_deposit_filterchannel?.OTHER ?? false,
                  AIRBNB:
                    integration.integr_chk_deposit_filterchannel?.AIRBNB ? false : integration.integr_deposit_amount>0 ? integration.integr_deposit_amount: true,
                },
              });
            }}
            checked={integration.integr_chk_deposit_filterchannel?.AIRBNB}
          />
          {integration?.integr_chk_deposit_filterchannel?.AIRBNB && (
              <div className="bg-gray-100 border rounded-2xl p-4 pb-2 mb-2">
                <FormField label={t("resv.depositTotal") + ' Airbnb'}>
                  <TextInput
                    type="number"
                    value={integration.integr_chk_deposit_filterchannel.AIRBNB === true ? 0: integration.integr_chk_deposit_filterchannel.AIRBNB}
                    onChange={(value) => {
                      // console.log(integration.integr_chk_deposit_filterchannel.AIRBNB)
                      setIntegration({
                      ...integration,
                      integr_chk_deposit_filterchannel: {
                        EXPEDIA:
                          integration.integr_chk_deposit_filterchannel?.EXPEDIA ?? false,
                        BOOKING:
                          integration.integr_chk_deposit_filterchannel?.BOOKING ?? false,
                        OTHER:
                          integration.integr_chk_deposit_filterchannel?.OTHER ?? false,
                        AIRBNB:
                          value,
                      },
                    })}}
                    numbered={true}
                  ></TextInput>
                </FormField>
              </div>
            )}
          <Checkbox
            label={t('integration.enableFor') + ' Booking'}
            onChange={() => {
              setIntegration({
                ...integration,
                integr_chk_deposit_filterchannel: {
                  EXPEDIA:
                    integration.integr_chk_deposit_filterchannel?.EXPEDIA ?? false,
                  OTHER:
                    integration.integr_chk_deposit_filterchannel?.OTHER ?? false,
                  AIRBNB:
                    integration.integr_chk_deposit_filterchannel?.AIRBNB ?? false,
                  BOOKING:
                    integration.integr_chk_deposit_filterchannel?.BOOKING ? false : integration.integr_deposit_amount>0 ? integration.integr_deposit_amount: true,
                },
              });
            }}
            checked={integration.integr_chk_deposit_filterchannel?.BOOKING}
          />
          {integration?.integr_chk_deposit_filterchannel?.BOOKING && (
            <div className="bg-gray-100 border rounded-2xl p-4 pb-2 mb-2">
              <FormField label={t("resv.depositTotal") + ' Booking'}>
                <TextInput
                  type="number"
                  value={integration.integr_chk_deposit_filterchannel.BOOKING === true ? 0: integration.integr_chk_deposit_filterchannel.BOOKING}
                  onChange={(value) => setIntegration({
                    ...integration,
                    integr_chk_deposit_filterchannel: {
                      EXPEDIA:
                        integration.integr_chk_deposit_filterchannel?.EXPEDIA ?? false,
                      OTHER:
                        integration.integr_chk_deposit_filterchannel?.OTHER ?? false,
                      AIRBNB:
                        integration.integr_chk_deposit_filterchannel?.AIRBNB ?? false,
                      BOOKING:
                        value,
                    },
                  })}
                  numbered={true}
                ></TextInput>
              </FormField>
            </div>
            )}
          <Checkbox
            label={t('integration.enableFor') + ' Expedia'}
            onChange={() => {
              setIntegration({
                ...integration,
                integr_chk_deposit_filterchannel: {
                  BOOKING:
                    integration.integr_chk_deposit_filterchannel?.BOOKING ?? false,
                  OTHER:
                    integration.integr_chk_deposit_filterchannel?.OTHER ?? false,
                  AIRBNB:
                    integration.integr_chk_deposit_filterchannel?.AIRBNB ?? false,
                  EXPEDIA:
                    integration.integr_chk_deposit_filterchannel?.EXPEDIA ? false : integration.integr_deposit_amount>0 ? integration.integr_deposit_amount: true,
                },
              });
            }}
            checked={integration.integr_chk_deposit_filterchannel?.EXPEDIA}
          />
          {integration?.integr_chk_deposit_filterchannel?.EXPEDIA && (
            <div className="bg-gray-100 border rounded-2xl p-4 pb-2 mb-2">
              <FormField label={t("resv.depositTotal") + ' Expedia'}>
                <TextInput
                  type="number"
                  value={integration.integr_chk_deposit_filterchannel.EXPEDIA === true ? 0: integration.integr_chk_deposit_filterchannel.EXPEDIA}
                  onChange={(value) => setIntegration({
                    ...integration,
                    integr_chk_deposit_filterchannel: {
                      OTHER:
                        integration.integr_chk_deposit_filterchannel?.OTHER ?? false,
                      AIRBNB:
                        integration.integr_chk_deposit_filterchannel?.AIRBNB ?? false,
                      BOOKING:
                        integration.integr_chk_deposit_filterchannel?.BOOKING ?? false,
                      EXPEDIA:
                        value,
                    },
                  })}
                  numbered={true}
                ></TextInput>
              </FormField>
            </div>
          )}
          <Checkbox
            label={t('integration.enableForOther')}
            onChange={() => {
              setIntegration({
                ...integration,
                integr_chk_deposit_filterchannel: {
                  EXPEDIA:
                    integration.integr_chk_deposit_filterchannel?.EXPEDIA ?? false,
                  BOOKING:
                    integration.integr_chk_deposit_filterchannel?.BOOKING ?? false,
                  AIRBNB:
                    integration.integr_chk_deposit_filterchannel?.AIRBNB ?? false,
                  OTHER:
                    integration.integr_chk_deposit_filterchannel?.OTHER ? false : integration.integr_deposit_amount>0 ? integration.integr_deposit_amount: true,
                },
              });
            }}
            checked={integration.integr_chk_deposit_filterchannel?.OTHER}
          />
          {integration?.integr_chk_deposit_filterchannel?.OTHER && (
            <div className="bg-gray-100 border rounded-2xl p-4 pb-2 mb-2">
              <FormField label={t("resv.depositTotal_other")}>
                <TextInput
                  type="number"
                  value={integration.integr_chk_deposit_filterchannel.OTHER === true ? 0: integration.integr_chk_deposit_filterchannel.OTHER}
                  onChange={(value) => setIntegration({
                    ...integration,
                    integr_chk_deposit_filterchannel: {
                      EXPEDIA:
                        integration.integr_chk_deposit_filterchannel?.EXPEDIA ?? false,
                      AIRBNB:
                        integration.integr_chk_deposit_filterchannel?.AIRBNB ?? false,
                      BOOKING:
                        integration.integr_chk_deposit_filterchannel?.BOOKING ?? false,
                      OTHER:
                        value,
                    },
                  })}
                  numbered={true}
                ></TextInput>
              </FormField>
            </div>
          )}
        </div>
      )}
    </>
  );
}
