import { motion } from "framer-motion";
import React from "react";
import { useEffect, useRef, useState } from "react";
import { Tab } from "../types";
import Icon from "./Icon";
import useResizeAware from "react-resize-aware";
import Popover from "./Popover";
import { isMobile } from "react-device-detect";

type TabSelectorProps = {
  tabs: Tab[];
  className?: string;
  onChange?: (tabs: Tab) => void;
  dontTab?: boolean
};
export default function TabSelector({
  tabs,
  className,
  onChange,
  dontTab,
}: TabSelectorProps) {
  const refs = useRef<React.RefObject<HTMLDivElement>[]>(
    tabs.map(() => React.createRef())
  );
  const [innerTabs, setInnerTabs] = useState<Tab[]>(tabs);

  useEffect(() => {
    setInnerTabs(tabs);
  }, [tabs]);

  useEffect(() => {
    if (onChange) {
      const tab = innerTabs.find((t) => t.active);
      if (tab) onChange(tab);
    }
  }, [innerTabs]);

  const [resizeListener, sizes] = useResizeAware();
  const [popupTabs, setPopupTabs] = useState<Tab[]>([]);

  useEffect(() => {
    if (!isMobile && !dontTab) {
      if (sizes.width) {
        let progressive = 0;
        let hideFrom = refs.current
          .map((el) => {
            return (progressive += el.current
              ? el.current?.clientWidth + 20
              : 0);
          })
          .findIndex((el) => el > (sizes.width || 0));
        if (hideFrom <= 0) {
          for (let i = 0; i < refs.current.length; i++) {
            const el = refs.current[i];
            if (!el || !el.current) continue;
            el.current.style.display = "block";
          }
          setPopupTabs([]);
          return;
        }

        const popTbas = [];

        for (let i = 0; i < refs.current.length; i++) {
          const el = refs.current[i];
          if (!el || !el.current) continue;
          if (tabs[i].active && tabs[i].label === el.current?.innerText) {
            el.current.style.display = "block";
          } else {
            el.current.style.display = "none";
            popTbas.push(tabs[i]);
          }
        }

        setPopupTabs(popTbas);
      }
    }
  }, [sizes.width, innerTabs]);

  function changeTab(key: string) {
    setInnerTabs((prevTab) => {
      const tabs = [...prevTab];
      return tabs.map((t) => {
        if (t.key === key) {
          t.active = true;
        } else {
          t.active = false;
        }
        return t;
      });
    });
  }

  return (
    <div
      className={
        "border-b border-gray-300 relative flex flex-col space-y-2 lg:space-y-0 lg:flex-row w-full overflow-auto" +
        className
      }
    >
      {resizeListener}
      {innerTabs.map((tab: Tab, index: number) => {
        if (tab.hidden) return null;
        return (
          <div
            key={index}
            ref={refs.current[index]}
            className={`mr-6 cursor-pointer relative pb-2 flex-1 ${
              tab.hidden && "hidden"
            }`}
            onClick={() => changeTab(tab.key)}
          >
            <span
              className={`text-lg font-medium truncate ${
                tab.active
                  ? "text-cyan-600"
                  : "text-gray-900 hover:text-gray-900"
              }`}
            >
              {tab.label}
            </span>

            {tab.pulse && (
              <div
                style={{
                  animation: "pulse 2s infinite",
                }}
                className="border-2 border-white absolute top-0 -left-1 w-3 h-3 bg-red-600 rounded-full"
              />
            )}

            {tab.active && (
              <motion.div
                style={{
                  bottom: "-2px",
                }}
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                className={"absolute left-0 w-full border-cyan-600 border-b-4"}
              />
            )}
          </div>
        );
      })}

      <Popover
        className={
          "cursor-pointer absolute right-0 top-0 h-full flex items-center justify-center"
        }
        renderContent={() =>
          popupTabs.map((tab: Tab, index: number) => {
            return (
              <div
                key={index}
                onClick={() => changeTab(tab.key)}
                className={`mt-1 mb-1 hover:bg-cyan-100 pt-1 pb-1 px-3 cursor-pointer ${
                  tab.hidden && "hidden"
                }`}
              >
                {tab.label}
              </div>
            );
          })
        }
      >
        {popupTabs.length > 0 && (
          <Icon name={"dots-horizontal"} size={"20px"} />
        )}
      </Popover>
    </div>
  );
}
