export default function EmptyState ({
  title,
  subtitle,
  image,
  actionLabel,
  className,
  action
} : {
  className?: string,
  title: string,
  subtitle: string,
  image: string,
  actionLabel?: string,
  action?: () => void
}) {

  return (
    <div className={"text-center p-4 " + className}>
      <img alt={'Image'} src={image} className={'inline-block h-32'} />
      <div className="mt-6 font-bold text-lg">
        {title}
      </div>
      <div className="text-gray-600 mt-1">
        {subtitle}
      </div>

      {
        action &&
        <button
          onClick={action}
          className={'mt-2 bg-cyan-50 text-cyan-600 px-4 py-2 rounded-2xl text-sm'}>
          {actionLabel} {'->'}
        </button>
      }
    </div>
  )
  
}
