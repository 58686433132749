import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import AssignApartments from "../components/bureaucracy/AssignApartments";
import PageTitle from "../components/PageTitle";
import { BureaucracyList } from "../shared/bureaucracy";
import { fetchAllModules } from "../shared/queries";
import { moduleId } from "../shared/utils/config";

export default function Bureaucracy() {
  const { t } = useTranslation();
  const [currentAssignChannel, setCurrentAssignChannel] = useState<string>();
  const [istatVisible, setIstatVisible] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data } = useQuery([], () => {
    fetchAllModules().then((data: any) => {
      const moduleIstatFinded = data?.modules?.find(
        (module: any) => module?.id === moduleId.paytourist
      );
      if (moduleIstatFinded) {
        setIstatVisible(true);
      } else {
        setIstatVisible(false);
      }
      return data; 
    });
  });

  return (
    <div>
      <PageTitle
        title={t("configuration.buro")}
        subtitle={t("buro.connectVikey")}
      />

      {currentAssignChannel && (
        <AssignApartments
          channel={currentAssignChannel}
          visible={currentAssignChannel !== undefined}
          onCancel={() => setCurrentAssignChannel(undefined)}
        />
      )}

      <div className={"grid md:grid-cols-2 gap-5"}>
        {BureaucracyList.map((bureau) => (
          <div
            className={"transition cst-shadow border flex-1 rounded-2xl"}
            key={bureau.name}
          >
            <div className={"p-4 items-center flex space-x-4"}>
              <img
                style={{ height: "30px" }}
                src={bureau.image}
                alt={t(bureau.name)}
              />
              <div className={"text-lg font-medium"}>{t(bureau.name)}</div>
            </div>
            {bureau.bureaucracy
              /* .filter((bureau) => {
                if (istatVisible) {
                  return bureau;
                }
                return bureau.name !== "Paytourist";
              }) */
              .map((country) => (
                <Link
                  key={country.link}
                  to={country.link}
                  className={
                    "block hover:bg-cyan-100 gover:text-cyan-600 cursor-pointer p-4 border-t"
                  }
                >
                  <div className="flex items-center justify-between space-x-2">
                    <div>{country.name}</div>
                  </div>
                </Link>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
}
