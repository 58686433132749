import { Placement } from '@popperjs/core';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';

type PopoverProps = {
  children: any;
  renderContent: Function;
  className?: string;
  placement?: Placement;
};

export default function Popover({
  children,
  className,
  renderContent,
  placement = 'bottom-start',
}: PopoverProps) {
  const [visible, setVisible] = useState<boolean>(false);
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const [arrowElement, setArrowElement] = useState<any>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
    // strategy: 'fixed',
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
  });
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setVisible(false);
      /* } else {
        setVisible(true); */
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div ref={ref}>
      {/* <div className={className} ref={setReferenceElement}> */}
      <div className={className} ref={setReferenceElement} onClick={() => setVisible((prev) => !prev)}>
        {children}
      </div>

      {visible && (
        <div
          ref={setPopperElement}
          style={{
            ...styles.popper,
            zIndex: 99999,
          }}
          {...attributes.popper}
        >
          <div
            className={
              'm-1 pt-1 pb-1 border bg-white shadow-md overflow-hidden rounded-2xl'
            }
          >
            {renderContent()}
          </div>
          <div ref={setArrowElement} style={styles.arrow}></div>
        </div>
      )}
    </div>
  );
}
