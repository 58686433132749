import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import {
  fetchBillingAddress,
  fetchLocals,
  updateApartment,
} from '../../shared/queries';
import { isDefined } from '../../shared/utils/guards';
import {
  BaseSelectItem,
  Local,
  LocalTable,
  TableFilterColumn,
  TableResponse,
  TableSort,
} from '../../types';
import BaseTable from '../BaseTable';
import Modal from '../Modal';
import ModalActions from '../ModalAction';
import ModalCard from '../ModalCard';
import ModalSection from '../ModalSection';
import SelectInput from '../SelectInput';

type AssociateAddress = {
  edit?: number | string | undefined | null;
  visible: boolean;
  onClose: () => void;
  onSave: () => void;
  addresses: any[];
};

export default function AssociateAddress({
  edit,
  visible,
  onClose,
  onSave,
  addresses,
}: AssociateAddress) {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery(
    ['address', edit],
    () => fetchBillingAddress(edit),
    {
      enabled: isDefined(edit) && edit !== null,
    }
  );

  const [sorting, setSorting] = useState<TableSort[]>([]);
  const [filters, setFilters] = useState<TableFilterColumn[] | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: apartments,
    isLoading: loadingApartments,
    refetch: refetchAddresses,
  } = useQuery<TableResponse<LocalTable>, Error>(
    ['apartmentServices', currentPage, filters, sorting],
    () => fetchLocals(filters, (currentPage - 1) * 15, sorting)
  );

  const getInvoiceAddress = (id: number) => {
    const index = addresses.findIndex((e) => e.id === id);
    console.log(index)
    if(index===-1){
      return {
        label: '',
        value: null,
      }

    }
    return {
      label: `${addresses[index].invoice_vatnum} - ${addresses[index].invoice_company}`,
      value: addresses[index].id,
    };
  };

  const mutateInvoiceAddress = useMutation(
    ({ local, method }: { local: LocalTable; method: BaseSelectItem }) =>
      updateApartment(local.local_key || '', {
        usersinvoices_id: method.value,
      }),
    {
      onSuccess: () => {
        refetchAddresses();
        toast.success(t('general.operationCompleted'));
      },
    }
  );

  return (
    <>
      <Modal visible={visible}>
        <ModalCard title={t('addAddress.addAddress')} className="w-full">
          <ModalSection>
            <BaseTable
              identifierKey={'local_key'}
              data={!loadingApartments && apartments ? apartments.data : []}
              className={'mb-4 mx-10'}
              loading={loadingApartments}
              // onSorting={handleSorting}
              currentPage={currentPage}
              pageCount={Math.ceil(
                parseInt(apartments?.recordsTotal || '0') / 15
              )}
              totalRows={parseInt(apartments?.recordsTotal || '0')}
              displayRows={15}
              paginated
              onFilterChange={(filters, page) => {
                setCurrentPage(page || 1);
                setFilters(filters);
                refetchAddresses();
              }}
              columns={[
                {
                  id: '1',
                  Header: t('apartments.general') || '',
                  accessor: (row: LocalTable) => (
                    <SelectInput
                      styles={{
                        container: (base) => ({ ...base, zIndex: 999 }),
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuShouldScrollIntoView={false}
                      menuPortalTarget={document.body}
                      value={getInvoiceAddress(row.usersinvoices_id)}
                      options={addresses.map((c: any) => {
                        return {
                          label: `${c.invoice_vatnum} - ${c.invoice_company}`,
                          value: c.id,
                        };
                      })}
                      onChange={(item: any) => {
                        if (item) {
                          mutateInvoiceAddress.mutate({
                            local: row,
                            method: item,
                          });
                        }
                      }}
                    />
                  ),
                  width: '20%',
                },
                {
                  Header: t('apartments.publicName') || '',
                  width: '20%',
                  accessor: 'public_name',
                  id: '4',
                },
                {
                  Header: t('service.internalName') || '',
                  width: '20%',
                  accessor: 'name',
                  id: '5',
                },
                {
                  Header: t('general.address') || '',
                  width: '20%',
                  accessor: 'address',
                  id: '6',
                },
                {
                  id: '7',
                  width: '20%',
                  Header: t('general.city') || '',
                  accessor: 'city',
                },
              ]}
            ></BaseTable>
          </ModalSection>
          <ModalActions
            saveLabel={t('general.exit')}
            onSave={() => {
              onClose();
            }}
          ></ModalActions>
        </ModalCard>
      </Modal>
    </>
  );
}
