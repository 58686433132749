import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageWrapper from "../PageWrapper";
import PreviousPage from "../PreviousPage";
import PageTitle from "../PageTitle";
import ApartmentFaqs from "../apartment/ApartmentFaqs";
import FileUploadComponent from "./FileUploadComponent";
import { MessageContextData } from "../../types";
import { fetchSingleMessageContext } from "../../shared/queries";
import CircleSpinner from "../CircleSpinner";

interface MessageContextDetailParams {
  context_id: string;
}

export default function MessageContextDetail() {
  const { t } = useTranslation();
  const { context_id } = useParams<MessageContextDetailParams>();

  const [messageContextData, setMessageContextData] = useState<MessageContextData | null>(null);
  const [isFetchingMessageContext, setIsFetchingMessageContext] = useState(true);
  const [fetchError, setFetchError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchMessageContextData() {
      setIsFetchingMessageContext(true);
      setFetchError(null);

      try {
        const messageContextData = await fetchSingleMessageContext(context_id);
        setMessageContextData(messageContextData);
      } catch (error) {
        console.error("Failed to fetch message context data", error);
        setFetchError(t("messageContextDetail.fetchError"));
      } finally {
        setIsFetchingMessageContext(false);
      }
    }

    fetchMessageContextData();
  }, [context_id, t]);
  return (
    <PageWrapper>
      <PreviousPage className="mb-4" label={t("general.goBack")} />

      <PageTitle title={messageContextData ? messageContextData.name :  ""} />

      {isFetchingMessageContext ? (
        
        <div className="flex justify-center items-center h-full">
                <CircleSpinner color={"primary"} />
              </div>
      ) : fetchError ? (
        <div className="text-red-600 text-center">{fetchError}</div>
      ) : (
        <div className="flex h-screen" style={{ height: "75vh" }}>
          <div className="w-2/3 bg-gray-100 border-r">
            <ApartmentFaqs context_id={context_id} faqs={messageContextData?.faqs}/>
          </div>
          <div className="w-1/3">
            <FileUploadComponent
              context_id={context_id}
              initialFiles={messageContextData?.files}
              file_element="ASSISTANT-CONTEXT"
              className="border-r flex flex-col h-full border-2 border-gray-300 rounded-lg bg-white"
            />
          </div>
        </div>
      )}
    </PageWrapper>
  );
}
