import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../components/Button";
import FormField from "../components/FormField";
import FormSectionTitle from "../components/FormSectionTitle";
import LanguageSelector from "../components/LanguageSelector";
import PageTitle from "../components/PageTitle";
import PageWrapper from "../components/PageWrapper";
import PreviousPage from "../components/PreviousPage";
import TextInput from "../components/TextInput";
import {
  deleteContractLanguage,
  fetchContract,
  fetchPerms,
  updateContract,
  updateContractLanguage,
} from "../shared/queries";
import { BaseSelectItem, ContractDescription, ContractLang } from "../types";
import Switch from "react-switch";
// import useBreadcrumbs from "../hooks/useBreadcrumbs";
import { useTranslation } from "react-i18next";
import SpecialTags from "../components/common/SpecialTags";
import SelectInput from "../components/SelectInput";

type ContractPageParams = {
  id: string;
};

export default function ContractPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<ContractPageParams>();

  const [currentLanguage, setCurrentLanguage] = useState<
    "it" | "en" | "de" | "es" | "fr" | "pt" | "ru" | "zh"
  >("it");
  const [translationChange, setTranslationChange] =
    useState<ContractDescription | null>(null);
  const [currentTranslation, setCurrentTranslation] =
    useState<ContractLang | null>(null);
  const [contractKey, setContractKey] = useState<string>("");

  const { data, isLoading } = useQuery(["contract", id], () =>
    fetchContract(id)
  );

  const { data: dataUsers } = useQuery("accounts", fetchPerms);

  useEffect(() => {
    if (data && data.results) {
      setContractKey(data.results.objkey);
    }
    if (data && data.results) {
      setTranslationChange(data.results.langs);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.results) {
      if (data.results.langs[currentLanguage]) {
        setCurrentTranslation(data.results.langs[currentLanguage]);
      } else {
        setCurrentTranslation({
          lang: currentLanguage,
          keycontr: data.results.objkey,
          objkey: data.results.objkey,
          p_id: parseInt(id),
          title: data.results.objkey,
          user_key: data.results.user_key,
          body: "",
          clausolevess: "",
          p_user_key: data.results.user_key,
          cust_ptr: parseInt(id),
          p_DEL: false,
          DEL: true,
          objtype: "CONTRACT",
          p_objkey: data.results.objkey,
          bodyintegral: "",
          p_objtype: "CONTRACT",
        });
      }
    }
  }, [data]);

  /**
   * When the current language changes  we set the current translation
   * that will be rendered in the rich editor
   */
  useEffect(() => {
    if (data && data.results && currentLanguage && translationChange) {
      if (translationChange[currentLanguage]) {
        setCurrentTranslation(translationChange[currentLanguage]);
      } else {
        const trans = {
          lang: currentLanguage,
          keycontr: data.results.objkey,
          objkey: data.results.objkey,
          p_id: parseInt(id),
          title: data.results.objkey,
          user_key: data.results.user_key,
          body: "",
          clausolevess: "",
          p_user_key: data.results.user_key,
          cust_ptr: parseInt(id),
          p_DEL: false,
          DEL: true,
          objtype: "CONTRACT",
          p_objkey: data.results.objkey,
          bodyintegral: "",
          p_objtype: "CONTRACT",
        } as ContractLang;
        setTranslationChange({
          ...translationChange,
          [currentLanguage]: trans,
        });
        setCurrentTranslation(trans);
      }
    }
  }, [currentLanguage]);
 

  const mutation = useMutation(
    () =>
      updateContract({
        id: data?.results?.id,
        user_key: data?.results?.user_key,
        objkey: contractKey,
      }),
    {
      onSuccess: () => {
        toast.success(t("general.operationCompleted"));
        history.goBack();
      },
    }
  );

  const deleteLangMutation = useMutation(
    (data: any) => deleteContractLanguage(data.cust_ptr, data.lang),
    {
      onSuccess: () => {},
    }
  );

  const updateLangMutation = useMutation(
    (data: any) => updateContractLanguage(data),
    {
      onSuccess: () => {},
    }
  );

  /* useBreadcrumbs([
    {
      label: t("navigation.administration"),
      route: "/configuration/bureaucracy",
    },
    { label: t("configuration.contracts"), route: "/configuration/contracts" },
    { label: data && data.results && data.results.objkey ? data.results.objkey : ".." },
  ]); */

  return (
    <PageWrapper>
      <PreviousPage className={"mb-4"} label={t("general.goBack")} />
      {currentTranslation && data && data.results && (
        <>
          <PageTitle title={data.results?.objkey}></PageTitle>
          <FormField label={"Account"} errorKey={"user_key"}>
            <SelectInput<BaseSelectItem, false>
              /* onChange={(item) => {
                if (item) setAccount(item);
              }}
              options={
                dataUsers && dataUsers.results
                  ? dataUsers.results.map((account: any) => {
                      return {
                        label: account.o_name,
                        value: account.o_user_key,
                      };
                    })
                  : []
              } */
              value={{
                label: dataUsers?.results?.find(
                  (account: any) => {
                    if(data?.results){
                      return data?.results.user_key === account.o_user_key
                    }
                    return account;
                  }
                )?.o_name,
                value: dataUsers?.results?.find(
                  (account: any) => {
                    if(data?.results){
                      return data?.results.user_key === account.o_user_key
                    }
                    return account;
                  }
                )?.o_user_key,
              }}
            />
          </FormField>
          <div className="p-4 space-y-4"></div>

          <FormField label={t("contracts.key")}>
            <TextInput
              value={contractKey}
              onChange={(val) => setContractKey(val)}
            ></TextInput>
          </FormField>

          <FormSectionTitle title={t("contracts.content")} />

          <SpecialTags target={"CO"} />

          <LanguageSelector
            className={"mb-3"}
            menuPortalTarget={document.body}
            onChange={(lang) => setCurrentLanguage(lang)}
          ></LanguageSelector>

          <div className="mt-8 flex space-x-2">
            <div className="w-auto">
              <Switch
                onChange={() => {
                  if (translationChange && currentLanguage) {
                    setTranslationChange({
                      ...translationChange,
                      [currentLanguage]: {
                        ...currentTranslation,
                        DEL: currentTranslation.DEL ? false : true,
                      },
                    });
                    setCurrentTranslation({
                      ...currentTranslation,
                      DEL: currentTranslation.DEL ? false : true,
                    });
                  }
                }}
                checked={currentTranslation.DEL === false}
              ></Switch>
            </div>
            <div className={"mt-1 font-medium"}>
              {t("createCustomEmail.languageActivated")}
            </div>
          </div>

          {!currentTranslation.DEL && (
            <>
              <FormSectionTitle
                borderless
                className={"mt-6"}
                title={t("contracts.titleContract")}
              />

              <div
                style={{
                  zIndex: 1,
                  position: "relative",
                }}
              >
                <Editor
                  apiKey={"af1s9ngxeroefg0odkelxcvqpjzqffflygu2cw2tqk9jexpo"}
                  value={currentTranslation?.body}
                  init={{
                    promotion:false,
                    height: 350,
                    plugins: [
                      'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                      'searchreplace', 'wordcount', 'visualblocks', 'visualchars', 'code', 'fullscreen', 'insertdatetime',
                      'media', 'table', 'emoticons', 'help'
                    ],
                    toolbar: 'undo redo | styles | bold italic backcolor | alignleft aligncenter alignright alignjustify | ' +
                      'bullist numlist outdent indent | link | ' +
                      'removeformat | help',
                    menubar: 'file edit view insert format tools table help',
                  }}
                  onEditorChange={(value) => {
                    if (
                      translationChange &&
                      currentLanguage &&
                      currentTranslation
                    ) {
                      setCurrentTranslation({
                        ...currentTranslation,
                        body: value,
                      });
                      setTranslationChange({
                        ...translationChange,
                        [currentLanguage]: {
                          ...currentTranslation,
                          body: value,
                        },
                      });
                    }
                  }}
                />
              </div>
              <FormSectionTitle
                borderless
                className={"mt-6"}
                title={t("contracts.extendedVersion")}
              />

              <Editor
                apiKey={"af1s9ngxeroefg0odkelxcvqpjzqffflygu2cw2tqk9jexpo"}
                value={currentTranslation?.bodyintegral}
                init={{
                  promotion:false,
                  height: 350,
                  plugins: [
                    'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                    'searchreplace', 'wordcount', 'visualblocks', 'visualchars', 'code', 'fullscreen', 'insertdatetime',
                    'media', 'table', 'emoticons', 'help'
                  ],
                  toolbar: 'undo redo | styles | bold italic backcolor | alignleft aligncenter alignright alignjustify | ' +
                    'bullist numlist outdent indent | link | ' +
                    'removeformat | help',
                  menubar: 'file edit view insert format tools table help',
                }}
                onEditorChange={(value) => {
                  if (
                    translationChange &&
                    currentLanguage &&
                    currentTranslation
                  ) {
                    setCurrentTranslation({
                      ...currentTranslation,
                      bodyintegral: value,
                    });
                    setTranslationChange({
                      ...translationChange,
                      [currentLanguage]: {
                        ...currentTranslation,
                        bodyintegral: value,
                      },
                    });
                  }
                }}
              />

              <FormSectionTitle title={t("contracts.clauses")} />

              <FormField label={t("contracts.clausesDesc")}>
                <TextInput
                  value={currentTranslation?.clausolevess}
                  onChange={(val) => {
                    if (
                      translationChange &&
                      currentLanguage &&
                      currentTranslation
                    ) {
                      setCurrentTranslation({
                        ...currentTranslation,
                        clausolevess: val,
                      });
                      setTranslationChange({
                        ...translationChange,
                        [currentLanguage]: {
                          ...currentTranslation,
                          clausolevess: val,
                        },
                      });
                    }
                  }}
                ></TextInput>
              </FormField>
            </>
          )}

          <div className={"mt-6"}>
            <Button
              onClick={() => {
                mutation.mutate();
                // process languages
                if (translationChange) {
                  Object.keys(translationChange).forEach((key) => {
                    if (
                      key === "it" ||
                      key === "en" ||
                      key === "fr" ||
                      key === "de" ||
                      key === "es" ||
                      key === "zh" ||
                      key === "ru" ||
                      key === "pt"
                    ) {
                      if (translationChange[key]?.DEL) {
                        deleteLangMutation.mutate({
                          cust_ptr: translationChange[key].cust_ptr,
                          lang: translationChange[key].lang,
                        });
                      } else {
                        if (translationChange[key])
                          updateLangMutation.mutate(translationChange[key]);
                      }
                    }
                  });
                }
              }}
            >
              {t("general.save")}
            </Button>
          </div>
        </>
      )}
    </PageWrapper>
  );
}
