import React, { useState, useRef,useEffect } from "react";
import PageWrapper from "../PageWrapper";
import Icon from "../Icon";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import { UploadedFile } from "../../types";
import { uploadFile,deleteSingleFile } from "../../shared/queries";

interface FileUploadComponentProps {
  context_id: string;
  local_key?: string;
  className?: string;
  file_element: string;
  initialFiles: UploadedFile[] | null | undefined;
}

const FileUploadComponent: React.FC<FileUploadComponentProps> = ({
  context_id,
  local_key,
  className,
  file_element,
  initialFiles,
}) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const [isDragging, setIsDragging] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (initialFiles){
      setFiles(initialFiles);
    }
  }, [initialFiles]);
  
  const retryUpload = async (file: UploadedFile) => {
    if (!file.originalFile) {
      console.error(`Original file not found for ${file.file_name}`);
      return;
    }

    try {
      if (file.idx && file.element) {
        const fileBase64 = await encodeFileToBase64(file.originalFile);
        const fileName = file.originalFile.name;
        const uploadedFile = await uploadFile(
          file.idx,
          context_id,
          file.element,
          fileBase64,
          fileName,
          local_key
        );

        setFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.idx === file.idx
              ? { ...f, file_id: uploadedFile.file_id, uploadFailed: false }
              : f
          )
        );
      }
    } catch (error) {
      console.error(`Error re-uploading file ${file.file_name}:`, error);
      setFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.idx === file.idx ? { ...f, uploadFailed: true } : f
        )
        
      );
    }
  };

  const encodeFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const processAndUploadFiles = async (filesToUpload: File[]) => {
    for (const file of filesToUpload) {
      console.log(file)
      const idx = files.length + 1;
      const placeholderFile: UploadedFile = {
        file_name: file.name,
        file_id: "",
        context_id,
        idx,
        element: file_element,
        uploadFailed: false,
        originalFile: file,
      };
  
      try {
        const fileBase64 = await encodeFileToBase64(file);
        const fileName = file.name;
        const uploadedFile = await uploadFile(
          idx,
          context_id,
          file_element,
          fileBase64,
          fileName,
          local_key,
        );
  
        setFiles((prevFiles) => [
          ...prevFiles,
          { ...placeholderFile, file_id: uploadedFile.file_id },
        ]);
      } catch (error) {
        console.error(`Error uploading file ${file.name}:`, error);
        setFiles((prevFiles) => [
          ...prevFiles,
          { ...placeholderFile, uploadFailed: true },
        ]);
      }
    }
  };
  
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFiles = Array.from(event.target.files || []);
    await processAndUploadFiles(selectedFiles);
  };
  
  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    console.log(event.dataTransfer.files)
    const droppedFiles = Array.from(event.dataTransfer.files).filter((file) =>
      /\.(pdf|docx|pptx|txt)$/i.test(file.name)
    );
    console.log("DROPPED FILE AAAAAAAAAA ",droppedFiles)
    await processAndUploadFiles(droppedFiles);
  };
  

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleRemoveFile = (fileToRemove: UploadedFile) => {
    if(fileToRemove.file_id){
      deleteSingleFile(fileToRemove);
    }
    setFiles((prevFiles) =>
      prevFiles.filter((file) => file.file_id !== fileToRemove.file_id)
    );
  };

  const handleUploadButtonClick = () => {
    // console.log("clicking ")
    // console.log(fileInputRef)
    fileInputRef.current?.click();
  };

  return (
    <PageWrapper className={className}>
      <div className="flex items-center border-b justify-between p-4">
        <span className="flex items-center">
          <Icon name="storage" className="bg-cyan-500 mr-2" size="20px" />
          <h2 className="text-2xl font-bold">{t("assistants.local_files")}</h2>
        </span>
        <input
              type="file"
              ref={fileInputRef}
              className="hidden"
              onChange={handleFileChange}
              accept=".pdf, .docx, .pptx, .txt"
              multiple
            />
        <Button
          icon="add-file"
          iconSize={"15px"}
          className="p-2 bg-cyan-500 text-white rounded-full hover:bg-cyan-600"
          onClick={handleUploadButtonClick}
        />
      </div>

      <div
        className={`flex-grow flex justify-center items-center rounded-lg p-4 transition-all duration-200 ${
          isDragging ? "border-cyan-500 bg-cyan-50" : "border-gray-300"
        }`}
        // onDragOver={handleDragOver}
        // onDrop={handleDrop}
        // onDragLeave={handleDragLeave}
      >
        {files.length === 0 ? (
          <label className="w-full max-w-md p-4 flex flex-col items-center cursor-pointer">
            <input
              type="file"
              ref={fileInputRef}
              className="hidden"
              onChange={handleFileChange}
              accept=".pdf, .docx, .pptx, .txt"
              multiple
            />
            <Icon name="add-file" size="40px" className="text-gray-400 mb-2" />
            <span className="text-gray-500">{t("file_upload.prompt")}</span>
            {isDragging && (
              <span className="text-cyan-600">
                {t("assistant.local_files.drag_file_prompt")}
              </span>
            )}
          </label>
        ) : (
            <div className="w-full h-full p-4">
              <>{ console.log("files in comp ",files)}</>
            {files.map((file) => (
              <div
                key={file.idx}
                className="flex justify-between items-center border-b py-2"
              >
                <span className="text-gray-500 truncate">{file.file_name}</span>

                {file.uploadFailed ? (
                  <div className="flex">
                    <Button
                      icon="refresh"
                      onClick={() => retryUpload(file)}
                      className="mr-2 px-2 py-1 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600"
                    >
                    </Button>
                    <Button
                      icon="trash"
                      onClick={() => handleRemoveFile(file)}
                      className="px-2 py-1 bg-red-500 text-white rounded-lg hover:bg-red-600"
                    >
                    </Button>
                  </div>
                ) : (
                  <Button
                    icon="trash"
                    onClick={() => handleRemoveFile(file)}
                    className="px-2 py-1 bg-cyan-500 text-white rounded-lg hover:bg-red-600"
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

export default FileUploadComponent;
