import Joi from 'joi'

export default Joi.object({
  email: Joi.string().required().email({ tlds: { allow: false } }).messages({
    'string.base': 'Enmail obbligatoria',
    'string.required': 'Enmail obbligatoria',
    'string.empty': 'Enmail obbligatoria',
    'string.email': 'Enmail non valida',
  })
})
