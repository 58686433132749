import { useTranslation } from "react-i18next";

type FormSectionTitleProps = {
  icon?: any;
  title: string;
  subtitle?: string;
  borderless?: boolean;
  className?: string;
  editLabel?: string;
  edit?: boolean;
  editFull?: boolean;
  dense?: boolean;
  onEdit?: () => void;
  add?: boolean;
  onAdd?: () => void;
  addLabel?: string;
};

export default function FormSectionTitle({
  icon,
  title,
  subtitle,
  borderless,
  editLabel,
  className,
  edit = false,
  dense = false,
  onEdit,
  add = false,
  onAdd,
  addLabel,
  editFull,
}: FormSectionTitleProps) {
  const { t } = useTranslation();

  return (
    <div
      className={`${!borderless && "pt-8"} border-gray-300 ${
        dense ? "mb-2" : "mb-6"
      } ${className}`}
    >
      <div className={`${!borderless && "border-b"} pb-2 flex justify-between ${editFull && "gap-3"}`}>
        <div className={"text-lg text-gray-800 font-semibold"}>
          {icon && icon} {title}
          {subtitle && (
            <div className={"text-sm mt-1 font-normal text-gray-600"}>
              {subtitle}
            </div>
          )}
        </div>

        {(edit || add) && (
          <div>
            {add && (
              <button
                onClick={() => {
                  if (onAdd) onAdd();
                }}
                className={
                  "focus:outline-none focus:ring-2 ring-cyan-600 font-medium hover:bg-cyan-100 px-2 py-1 rounded-2xl text-sm text-cyan-600"
                }
              >
                {addLabel || t("general.add")}
              </button>
            )}
            {edit && (
              <button
                onClick={() => {
                  if (onEdit) onEdit();
                }}
                className={`focus:outline-none focus:ring-2 ring-cyan-600 font-medium  ${
                  editFull ? "bg-cyan-600 text-white hover:bg-cyan-400 px-4 py-2" : "text-cyan-600 hover:bg-cyan-100 px-2 py-1"
                }  rounded-2xl text-sm `}
              >
                {editLabel || t("general.edit")}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
