import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LocalTable } from "../../../types";
import Checkbox from "../../Checkbox";
import { useQuery } from "react-query";
import { fetchAllModules } from "../../../shared/queries";
import { moduleId } from "../../../shared/utils/config";

type DocumentSelectorProps = {
  local: LocalTable | undefined;
  integration: any;
  setIntegration: any;
};
export default function DocumentSelector({
  local,
  integration,
  setIntegration,
}: DocumentSelectorProps) {
  const { t } = useTranslation();

  const [activeRecognition, setActiveRecognition] = useState<any>(true);
  const [recognitionFind, setRecognitionFind] = useState<any>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: dataModules } = useQuery<any>([local], () => {
    if (local?.local_key) {
      return fetchAllModules(local?.local_key).then((data: any) => {
        const moduleRecognitionFinded = data?.modules?.find(
          (module: any) => module?.id === moduleId.docs_auto_confirmation
        );
        if(moduleRecognitionFinded){
          setRecognitionFind(true);
          if(moduleRecognitionFinded.active){
            setActiveRecognition(false);
          } else {
            setActiveRecognition(true);
          }
        } else {
          setRecognitionFind(false);
          setActiveRecognition(true);
        }
        return data;
      });
    }
  });

  return (
    <>
      <Checkbox
        label={t("resv.sendDocuments")}
        onChange={() => {
          setIntegration({
            ...integration,
            integr_chk_documents:
              !integration.integr_chk_documents,
          });
        }}
        checked={integration?.integr_chk_documents === 0 ? false : integration?.integr_chk_documents}
      />
      {integration?.integr_chk_documents && (
        <div className="bg-gray-100 border rounded-2xl p-4 pb-2 mb-2">
          <Checkbox
            label={t("resv.requestDocumentMainGuest")}
            onChange={() => {
              /* setIntegration({
                ...integration,
                integr_chk_photo:!integration.integr_chk_photo,
              }); */
              setIntegration((prev: any) => {
                if(prev.integr_chk_photo) {
                  return {
                    ...prev,
                    integr_chk_photo:!prev.integr_chk_photo,
                    integr_chk_docs_auto_confirmation: false,
                    integr_chk_photo_all: false,
                  }
                }
                return {
                  ...prev,
                  integr_chk_photo:!prev.integr_chk_photo,
                }
              });
            }}
            checked={integration.integr_chk_photo === 0 ? false : integration.integr_chk_photo}
          />

          <Checkbox
            label={t("resv.requestDocumentOtherGuests")}
            onChange={() => {
              /* setIntegration({
                ...integration,
                integr_chk_photo_all:
                  !integration.integr_chk_photo_all,
              }); */
              setIntegration((prev: any) => {
                if(!prev.integr_chk_photo){
                  return {
                    ...prev,
                    integr_chk_photo_all: !prev.integr_chk_photo_all,
                    integr_chk_photo: true,
                  } 
                }
                return {
                  ...prev,
                integr_chk_photo_all:
                  !prev.integr_chk_photo_all,
                }
              });
            }}
            checked={integration.integr_chk_photo_all === 0 ? false : integration.integr_chk_photo_all}
          />

          {recognitionFind && <Checkbox
            disabled={activeRecognition || !integration.integr_chk_photo}
            label={t("resv.activeRecognition")}
            onChange={() => {
              setIntegration({
                ...integration,
                integr_chk_docs_auto_confirmation:
                  !integration.integr_chk_docs_auto_confirmation,
              });
            }}
            checked={integration.integr_chk_docs_auto_confirmation === 0 ? false : integration.integr_chk_docs_auto_confirmation}
          />}

          <Checkbox
            label={t("resv.requestNumberOfGuests")}
            onChange={() => {
              setIntegration({
                ...integration,
                integr_chk_asknumguests:
                  !integration.integr_chk_asknumguests,
              });
            }}
            checked={integration.integr_chk_asknumguests === 0 ? false : integration.integr_chk_asknumguests}
          />
          {(integration.integr_chk_asknumguests !== 0 ||  !integration.integr_chk_asknumguests) && (
            <div className="bg-gray-50 border rounded-2xl p-4 pb-2 mb-2">
              <Checkbox
                label={t("integration.enableFor") + " Airbnb"}
                onChange={() => {
                  setIntegration({
                    ...integration,
                    integr_chk_asknumguests_filterchannel: {
                      EXPEDIA:
                        integration.integr_chk_asknumguests_filterchannel
                          ?.EXPEDIA ?? false,
                      BOOKING:
                        integration.integr_chk_asknumguests_filterchannel
                          ?.BOOKING ?? false,
                      OTHER:
                        integration.integr_chk_asknumguests_filterchannel
                          ?.OTHER ?? false,
                      AIRBNB:
                        !integration.integr_chk_asknumguests_filterchannel?.AIRBNB,
                    },
                  });
                }}
                checked={
                  integration.integr_chk_asknumguests_filterchannel?.AIRBNB === 0 ? false : integration.integr_chk_asknumguests_filterchannel?.AIRBNB
                }
              />
              <Checkbox
                label={t("integration.enableFor") + " Booking"}
                onChange={() => {
                  setIntegration({
                    ...integration,
                    integr_chk_asknumguests_filterchannel: {
                      EXPEDIA:
                        integration.integr_chk_asknumguests_filterchannel
                          ?.EXPEDIA ?? false,
                      OTHER:
                        integration.integr_chk_asknumguests_filterchannel
                          ?.OTHER ?? false,
                      AIRBNB:
                        integration.integr_chk_asknumguests_filterchannel?.AIRBNB ?? false,
                      BOOKING:
                        !integration.integr_chk_asknumguests_filterchannel
                          ?.BOOKING,
                    },
                  });
                }}
                checked={
                  integration.integr_chk_asknumguests_filterchannel?.BOOKING === 0 ? false : integration.integr_chk_asknumguests_filterchannel?.BOOKING
                }
              />
              <Checkbox
                label={t("integration.enableFor") + " Expedia"}
                onChange={() => {
                  setIntegration({
                    ...integration,
                    integr_chk_asknumguests_filterchannel: {
                      BOOKING:
                        integration.integr_chk_asknumguests_filterchannel
                          ?.BOOKING ?? false,
                      OTHER:
                        integration.integr_chk_asknumguests_filterchannel
                          ?.OTHER ?? false,
                      AIRBNB:
                        integration.integr_chk_asknumguests_filterchannel?.AIRBNB ?? false,
                      EXPEDIA:
                        !integration.integr_chk_asknumguests_filterchannel
                          ?.EXPEDIA,
                    },
                  });
                }}
                checked={
                  integration.integr_chk_asknumguests_filterchannel?.EXPEDIA === 0 ? false : integration.integr_chk_asknumguests_filterchannel?.EXPEDIA
                }
              />
              <Checkbox
                label={t("integration.enableForOther")}
                onChange={() => {
                  setIntegration({
                    ...integration,
                    integr_chk_asknumguests_filterchannel: {
                      EXPEDIA:
                        integration.integr_chk_asknumguests_filterchannel
                          ?.EXPEDIA ?? false,
                      BOOKING:
                        integration.integr_chk_asknumguests_filterchannel
                          ?.BOOKING ?? false,
                      AIRBNB:
                        integration.integr_chk_asknumguests_filterchannel?.AIRBNB ?? false,
                      OTHER:
                        !integration.integr_chk_asknumguests_filterchannel
                          ?.OTHER,
                    },
                  });
                }}
                checked={
                  integration.integr_chk_asknumguests_filterchannel?.OTHER === 0 ? false : integration.integr_chk_asknumguests_filterchannel?.OTHER
                }
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}
