import { useElements, useStripe, IbanElement } from "@stripe/react-stripe-js"
import Modal from "../Modal"
import ModalActions from "../ModalAction"
import ModalCard from "../ModalCard"
import ModalSection from "../ModalSection"
import TextInput from '../TextInput'
import FormField from '../FormField'
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify";
import { RootState } from "../../reducers";
import { useDispatch, useSelector } from "react-redux";


type AddCreditCardProps = {
  visible: boolean,
  onClose: () => void,
  onSave: () => void,
  onSource?: (data: any) => void
}

export default function AddSepaIban ({
  visible,
  onClose,
  onSource,
  onSave
} : AddCreditCardProps) {

  const { t } = useTranslation()

  const [accountName, setAccountName] = useState('')
  const [email, setEmail] = useState('')

  const stripe = useStripe()
  const elements = useElements()

  const {
    profile
  } = useSelector((state:RootState) => state.login)

  async function storeIban () {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet.
      return
    }

    const ibanElement = elements.getElement(IbanElement)

    if (ibanElement) {
      const { error, source } = await stripe.createSource(ibanElement, {
        type: 'sepa_debit',
        currency: 'eur',
        owner: {
          name: accountName,
          email: email
        },
        mandate: {
          notification_method: 'email',
        }
      })
      if (error){ 
        toast.error(error.message)
        return
      }
      if (!error && onSource) onSource(source)
    }
  }

  return (
    <Modal
      visible={visible}>
      <ModalCard
        className={'w-full lg:max-w-4xl'}
        title={t('addSepa.addIban')}>
        <ModalSection>
          
          <div className={'p-4'}>

            <div className={'flex flex-col space-y-4 p-4'}>
            {  profile && profile.enable_sepa===1 ?
                <div>
                  <div className="flex space-x-4">
                    <FormField
                      label={t('addSepa.accountOwner')}>
                      <TextInput value={accountName} onChange={val => setAccountName(val)} />
                    </FormField>

                    <FormField
                      label={'Email'}>
                      <TextInput value={email} onChange={val => setEmail(val)} />
                    </FormField>
                  </div>

                  <FormField
                    label={'IBAN'}>
                    <div className={'border rounded-2xl p-2'}>
                      <IbanElement
                        options={{
                          supportedCountries: ['SEPA'],
                          style: {
                            base: {
                              fontSize: '16px',
                              color: '#424770',
                              '::placeholder': {
                                color: '#aab7c4',
                              },
                            },
                            invalid: {
                              color: '#9e2146',
                            },
                          },
                        }}
                      />
                    </div>
                  </FormField>
                </div>
                  :
                  <div className="text-sm text-red-500">
                    {t('addSepa.needToBeAble')}
                  </div>
              }
              
              <div className="text-sm text-gray-500">
                {t('addSepa.disclaimer')}
              </div>

            </div>
            
          </div>
        </ModalSection>
        <ModalActions
          isLoading={false}
          saveLabel={t('general.save')}
          onClose={() => {
            if (onClose) onClose()
          }}
          onSave={() => {
            storeIban()
          }}></ModalActions>
      </ModalCard>
    </Modal>
  )

}