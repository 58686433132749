import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  fetchBillingAddress,
  storeBillingAddress,
  fetchInvoiceCountries,
} from "../../shared/queries";
import { isDefined } from "../../shared/utils/guards";
import { BaseSelectItem } from "../../types";
import FormField from "../FormField";
import Modal from "../Modal";
import ModalActions from "../ModalAction";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import SelectInput from "../SelectInput";
import TextInput from "../TextInput";
import Checkbox from "../Checkbox";

type AddAddress = {
  edit?: number | string | undefined | null;
  visible: boolean;
  onClose: () => void;
  onSave: () => void;
};

type CountryOption = {
  label: string;
  value: string;
};

export default function AddAddress({
  edit,
  visible,
  onClose,
  onSave,
}: AddAddress) {
  const { t } = useTranslation();

  const types = [
    {
      label: t("addAddress.company"),
      value: "company",
    },
    {
      label: t("addAddress.single"),
      value: "single",
    },
  ];

  const [id, setId] = useState<string | number>("new");
  const [type, setType] = useState("company");
  const [vat, setVat] = useState<string>("");
  const [sdiCode, setSdiCode] = useState<string>("");
  const [pec, setPec] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [country, setCountry] = useState<CountryOption>({
    label: "Italia",
    value: "IT",
  });
  const [province, setProvince] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [wantBill, setWantBill] = useState<boolean>(false);

  const createMutation = useMutation(
    () =>
      storeBillingAddress({
        id: id,
        invoice_iscompany: type === "company" ? 1 : 0,
        invoice_vatnum: vat,
        invoice_codiceunivoco: sdiCode,
        invoice_pec: pec,
        invoice_company: name,
        invoice_country: country?.value,
        invoice_province: province,
        invoice_zip: zip,
        invoice_city: city,
        invoice_address: address,
        invoice_active: wantBill,
      }),
    {
      onSuccess: () => {
        toast.success(t("general.requestCompleted"));
        if (onSave) onSave();
      },
      onError: (error: Error) => {
        toast.error(error.message || t("general.requestError"));
      },
    }
  );

  const { data, isLoading } = useQuery(
    ["address", edit],
    () => fetchBillingAddress(edit),
    {
      enabled: isDefined(edit) && edit !== null,
    }
  );

  const { data: countries, isLoading: isLoadingCountry } = useQuery(
    ["address", edit, id],
    () => fetchInvoiceCountries()
  );

  const isSaveDisabled = () => {
    return (
      !(vat && name && country && province && zip && city && address) || !!edit
    );
  };

  useEffect(() => {
    if (data) {
      const companyData = data;
      setId(companyData.id);
      setType(companyData.invoice_iscompany === 1 ? "company" : "single");
      setVat(companyData.invoice_vatnum);
      setSdiCode(companyData.invoice_codiceunivoco);
      setPec(companyData.invoice_pec);
      setName(companyData.invoice_company);
      setProvince(companyData.invoice_province);
      setZip(companyData.invoice_zip);
      setCity(companyData.invoice_city);
      setAddress(companyData.invoice_address);
      setWantBill(companyData.invoice_active);
    } else {
      setId("new");
      setType("company");
      setVat("");
      setSdiCode("");
      setPec("");
      setCountry({ label: "Italia", value: "IT" } as CountryOption);
      setName("");
      setProvince("");
      setZip("");
      setCity("");
      setAddress("");
      setWantBill(false);
    }
  }, [data]);

  useEffect(() => {
    if (data && countries) {
      const companyData = data;
      const country_temp =
        countries && countries.results
          ? (Object.values(countries?.results).find(
              (e: any) => e.Cd_Nazione === companyData.invoice_country
            ) as any)
          : null;
      if (country_temp) {
        setCountry({
          label: country_temp.Descrizione,
          value: country_temp.Cd_Nazione,
        } as CountryOption);
      }
    }
  }, [data, countries]);

  return (
    <Modal visible={visible}>
      <ModalCard
        className={"w-full lg:max-w-4xl"}
        title={t("addAddress.addAddress")}
      >
        <ModalSection>
          <div
            className={
              "m-5 px-4 py-3 text-sm border-gray-300 font-medium bg-red-200 rounded-3xl"
            }
          >
            <div className="flex space-x-2 px-2 md:text-base text-xs">
              <div>{t("addAddress.alertWarning")}</div>
            </div>
          </div>
          <div className="p-4 space-y-4">
            <FormField label={t("addAddress.type")}>
              <SelectInput<BaseSelectItem, false>
                defaultValue={types.find((t) => t.value === type)}
                options={types}
                onChange={(item) => {
                  if (item) setType(item.value);
                }}
                isDisabled={!!edit}
              />
            </FormField>

            <FormField
              label={
                (type === "company"
                  ? t("addAddress.vat")
                  : t("addAddress.ssn")) + "*"
              }
            >
              <TextInput
                value={vat}
                onChange={(val) => setVat(val)}
                disabled={!!edit}
              ></TextInput>
            </FormField>

            <div className="flex space-x-4">
              <div className="w-1/2">
                <FormField label={t("addAddress.sdi")}>
                  <TextInput
                    value={sdiCode}
                    onChange={(val) => setSdiCode(val)}
                    disabled={!!edit}
                  ></TextInput>
                </FormField>
              </div>
              <div className="w-1/2">
                <FormField label={t("addAddress.pec")}>
                  <TextInput
                    value={pec}
                    onChange={(val) => setPec(val)}
                    disabled={!!edit}
                  ></TextInput>
                </FormField>
              </div>
            </div>

            <FormField
              label={
                (type === "company"
                  ? t("addAddress.companyName")
                  : t("addAddress.nameSurname")) + "*"
              }
            >
              <TextInput
                value={name}
                onChange={(val) => setName(val)}
                disabled={!!edit}
              ></TextInput>
            </FormField>

            <FormField label={t("general.address") + "*"}>
              <TextInput
                value={address}
                onChange={(val) => setAddress(val)}
                disabled={!!edit}
              ></TextInput>
            </FormField>

            <div className="flex space-x-4">
              <div className="w-1/2">
                <FormField label={t("addAddress.province") + "*"}>
                  <TextInput
                    value={province}
                    onChange={(val) => setProvince(val)}
                    maxLength={2}
                    disabled={!!edit}
                  ></TextInput>
                </FormField>
              </div>
              <div className="w-1/2">
                <FormField label={t("addAddress.zip") + "*"}>
                  <TextInput
                    value={zip}
                    onChange={(val) => setZip(val)}
                    disabled={!!edit}
                  ></TextInput>
                </FormField>
              </div>
            </div>

            <div className="flex space-x-4">
              <div className="w-1/2">
                <FormField label={t("general.city") + "*"}>
                  <TextInput
                    value={city}
                    onChange={(val) => setCity(val)}
                    disabled={!!edit}
                  ></TextInput>
                </FormField>
              </div>
              <div className="w-1/2">
                <FormField label={t("addAddress.country") + "*"}>
                  <SelectInput
                    // menuShouldScrollIntoView={false}
                    menuPortalTarget={document.body}
                    onChange={(item) => {
                      if (!edit) {
                        setCountry(item as CountryOption);
                      }
                    }}
                    isDisabled={!!edit}
                    value={country}
                    options={
                      isLoadingCountry
                        ? []
                        : countries && countries.results
                        ? Object.values(countries.results)
                            .map((e: any) => {
                              return {
                                label: e.Descrizione,
                                value: e.Cd_Nazione,
                              };
                            })
                            .sort((a, b) => (a.label > b.label ? 1 : -1))
                        : []
                    }
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </FormField>
              </div>
            </div>
            <div className="flex space-x-4">
              <Checkbox
                disabled={createMutation.isLoading || !!edit}
                checked={wantBill}
                onChange={() => {
                  setWantBill((prev) => !prev);
                }}
                label={t("addAddress.wantInvoice")}
              />
            </div>
            <label className="text-sm text-gray-500 font-medium">
              {"*" + t("general.requiredField")}
            </label>
          </div>
        </ModalSection>
        <ModalActions
          isLoading={createMutation.isLoading}
          saveLabel={t("general.save")}
          saveDisabled={isSaveDisabled()}
          onClose={() => {
            if (onClose) onClose();
          }}
          onSave={() => {
            createMutation.mutate();
          }}
        ></ModalActions>
      </ModalCard>
    </Modal>
  );
}
