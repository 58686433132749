import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import Icon from "../components/Icon";
import PageWrapper from "../components/PageWrapper";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import useBreadcrumbs from "../hooks/useBreadcrumbs";
import i18n from "../langs";

var docs = [
  {
    label: "support.subaccount",
    value: "",
    category: "dashboard",
    link: "support.subaccountLink",
  },
  {
    label: "support.istat",
    value: "",
    category: "dashboard",
    link: "support.istatLink",
  },
  {
    label: "support.customCheckin",
    value: "",
    category: "dashboard",
    link: "support.customCheckinLink",
  },
  {
    label: "support.cityTax",
    value: "",
    category: "dashboard",
    link: "support.cityTaxLink",
  },
  {
    label: "support.paymentMethod",
    value: "",
    category: "dashboard",
    link: "support.paymentMethodLink",
  },
  {
    label: "support.integrations",
    value: "",
    category: "dashboard",
    link: "support.integrationsLink",
  },
  {
    label: "support.portaleAlloggiati",
    value: "",
    category: "dashboard",
    link: "support.portaleAlloggiatiLink",
  },
  {
    label: "support.customEmail",
    value: "",
    category: "dashboard",
    link: "support.customEmailLink",
  },
  {
    label: "support.insertReservation",
    value: "",
    category: "dashboard",
    link: "support.insertReservationLink",
  },

  // { label: 'Vikey - Messa online', value: '', category: 'vikeydevice', to: 'https://drive.google.com/file/d/1V9_k0AEJ0JUUtLTRjvyDzBLljPw5FFmv/view' },

  {
    label: "support.editLockCode",
    value: "",
    category: "vikeylock",
    link: "support.editLockCodeLink",
  },
  {
    label: "support.changeLockBattery",
    value: "",
    category: "vikeylock",
    link: "support.changeLockBatteryLink",
  },
  {
    label: "support.lockInstallation",
    value: "",
    category: "vikeylock",
    link: "support.lockInstallationLink",
  },
  { label: "Ticket", value: "", category: "ticket", link: "" },
];

function SupportCard({
  category,
  title,
  onClick,
}: {
  category: string;
  title: string;
  onClick?: any;
}) {
  const [items, setItems] = useState(5);
  const { t } = useTranslation();
  const lang = localStorage.getItem("vikey_lang");

  // useBreadcrumbs([{ label: t("general.support"), route: "/support" }]);

  return (
    <div className={"bg-white shadow-xl p-6 flex-1 rounded-2xl"}>
      <div className="text-lg font-medium text-gray-600 mb-4">{title}</div>

      <div className={"flex flex-col space-y-2"}>
        {category === "dashboard" && i18n.language === "it" ? (
          <a
            target={"_blank"}
            href="https://support.vikey.it/it/"
            className={"text-cyan-600"}
            rel="noreferrer"
          >
            Clicca qui per leggere le guide
          </a>
        ) : (
          <></>
        )}

        {docs
          .filter((d) => d.category === category)
          .map((element) =>
            onClick ? (
              <h1
                className={"text-cyan-600 cursor-pointer"}
                onClick={onClick ?? ""}
              >
                {t(element.label)}
              </h1>
            ) : i18n.language !== "it" ? (
              <a
                target={"_blank"}
                href={t(element.link)}
                className={"text-cyan-600"}
                rel="noreferrer"
              >
                {t(element.label)}
              </a>
            ) : null
          )
          .slice(0, items)}
      </div>

      {i18n.language !== "it" && <div className="mt-6">
        {items === 5 &&
          docs.filter((d) => d.category === category).length > 5 && (
            <button
              onClick={() => setItems(100)}
              className={"font-medium text-cyan-800"}
            >
              {t("help.showMore")}
            </button>
          )}

        {items > 5 &&
          docs.filter((d) => d.category === category).length > 5 && (
            <button
              onClick={() => setItems(5)}
              className={"font-medium text-cyan-800"}
            >
              {t("help.showLess")}
            </button>
          )}
      </div>}
    </div>
  );
}

const SearchInput = styled(motion.div)``;

const SearchOverlay = styled(motion.div)`
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export default function Support() {
  const { t } = useTranslation();
  const history = useHistory();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<any>([]);

  useEffect(() => {
    if (!query || query === "") {
      setResults([]);
      return;
    }
    const results = docs.filter((element) => {
      return element.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
    });
    setResults(results);
  }, [query]);

  return (
    <PageWrapper>
      <div className={"text-center mb-4"}>
        <img className={"w-52 inline-block"} src="/support.svg" alt="" />
      </div>

      <div className="text-center">
        <div className="text-2xl font-bold">{t("support.howCanWeHelp")}</div>
      </div>

      {i18n.language !== "it" && (
        <div className="w-full lg:max-w-4xl relative text-center mx-auto mt-8 mb-12">
          <input
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            value={query}
            placeholder={t("support.writeToSearch")}
            className={
              "px-4 z-10 relative py-2 rounded-2xl focus:outline-none focus:ring-2 focus:ring-cyan-600"
            }
            type="text"
            style={{
              width: "100%",
              maxWidth: "500px",
              boxShadow:
                "0px 2px 12px rgba(0,0,0,0.08), 0px 0px 0px 1px rgba(0,0,0,0.08)",
            }}
          />

          {query.length > 0 && (
            <SearchOverlay
              onClick={() => {
                setResults([]);
                setQuery("");
              }}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
            />
          )}

          {query.length > 0 && (
            <SearchInput
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              style={{
                boxShadow:
                  "0px 2px 12px rgba(0,0,0,0.08), 0px 0px 0px 1px rgba(0,0,0,0.05)",
              }}
              className="overflow-hidden w-full bg-white mt-2 z-10 absolute rounded-2xl"
            >
              {results.map((r: any) => (
                <a
                  href={t(r.link)}
                  target={"_blank"}
                  className={
                    "hover:text-cyan-600 block text-left border-b px-4 py-3 hover:bg-gray-100 cursor-pointer"
                  }
                  rel="noreferrer"
                >
                  {t(r.label)}
                </a>
              ))}
              <button
                onClick={() => history.push("/support/request")}
                className={
                  "w-full px-4 py-3 text-sm font-medium hover:bg-cyan-50 hover:text-cyan-600 cursor-pointer"
                }
              >
                <div className="flex space-x-2 justify-center">
                  <div>
                    <Icon name={"chat-alt"} size={"20px"}></Icon>
                  </div>
                  <div>
                    {t("help.contactUs")} {"->"}
                  </div>
                </div>
              </button>
            </SearchInput>
          )}
        </div>
      )}

      <div className="flex flex-col md:flex-row md:space-x-12 mt-12 md:space-y-0 space-y-5">
        <SupportCard
          category={"dashboard"}
          title={t("help.dashGuide")}
        ></SupportCard>
        {i18n.language !== "it" && (
          <SupportCard
            category={"vikeydevice"}
            title={t("help.vikeyDevice")}
          ></SupportCard>
        )}
        {i18n.language !== "it" && (
          <SupportCard
            category={"vikeylock"}
            title={t("help.vikeyLock")}
          ></SupportCard>
        )}
        <SupportCard
          category={"ticket"}
          title={t("support.ticket")}
          onClick={() => history.push("/support/request")}
        ></SupportCard>
      </div>
    </PageWrapper>
  );
}
