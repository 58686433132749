import dayjs from 'dayjs';

export function getHumanDateFromISO(
  date: string,
  time: boolean,
  timePrefix?: string
) {
  let format = 'DD-MM-YYYY';
  if (time) format += ' HH:mm';
  if (timePrefix) {
    const dateFormat = dayjs(date).format('DD-MM-YYYY');
    const timeFormat = dayjs(date).format('HH:mm');

    return dateFormat + ' ' + timePrefix + ' ' + timeFormat;
  } else {
    return dayjs(date).format(format);
  }
}

export function getFormattedDate(
  date: string,
  inputFormat: string,
  outputFormat: string
) {
  const _date = dayjs(date, inputFormat).format(outputFormat);
  return _date;
}

export function cleanNumber(s:string){
  return s.replace(/[^\d]/g, '')
}

export function cleanDate(_date: string) {
  console.debug('data ricevuta', _date);
  const date = dayjs(_date).format('YYYY-MM-DD HH:mm:ss');
  console.debug('data manipolata', date);
  return date;
}

export function getTime(date: string) {
  const old_date = date.split('T');
  const new_date = (
    old_date.length > 1 ? old_date[1] : date.split(' ')[1]
  ).split(':');
  return new_date[0] + ':' + new_date[1];
}

export function formatSmsTime(_date: string) {
  const date = _date.toString();
  return date.length === 6
    ? date.substring(4) + '/' + date.substring(0, 4)
    : date;
}

export function normalizeTimestamp(timestamp: any) {
  if (timestamp.toString().length < 13) {
    return parseInt(timestamp) * 1000;
  } else {
    return timestamp;
  }
}

export const times = [
  { label: '00:00', value: '00:00' },
  { label: '00:30', value: '00:30' },
  { label: '01:00', value: '01:00' },
  { label: '01:30', value: '01:30' },
  { label: '02:00', value: '02:00' },
  { label: '02:30', value: '02:30' },
  { label: '03:00', value: '03:00' },
  { label: '03:30', value: '03:30' },
  { label: '04:00', value: '04:00' },
  { label: '04:30', value: '04:30' },
  { label: '05:00', value: '05:00' },
  { label: '05:30', value: '05:30' },
  { label: '06:00', value: '06:00' },
  { label: '06:30', value: '06:30' },
  { label: '07:00', value: '07:00' },
  { label: '07:30', value: '07:30' },
  { label: '08:00', value: '08:00' },
  { label: '08:30', value: '08:30' },
  { label: '09:00', value: '09:00' },
  { label: '09:30', value: '09:30' },
  { label: '10:00', value: '10:00' },
  { label: '10:30', value: '10:30' },
  { label: '11:00', value: '11:00' },
  { label: '11:30', value: '11:30' },
  { label: '12:00', value: '12:00' },
  { label: '12:30', value: '12:30' },
  { label: '13:00', value: '13:00' },
  { label: '13:30', value: '13:30' },
  { label: '14:00', value: '14:00' },
  { label: '14:30', value: '14:30' },
  { label: '15:00', value: '15:00' },
  { label: '15:30', value: '15:30' },
  { label: '16:00', value: '16:00' },
  { label: '16:30', value: '16:30' },
  { label: '17:00', value: '17:00' },
  { label: '17:30', value: '17:30' },
  { label: '18:00', value: '18:00' },
  { label: '18:30', value: '18:30' },
  { label: '19:00', value: '19:00' },
  { label: '19:30', value: '19:30' },
  { label: '20:00', value: '20:00' },
  { label: '20:30', value: '20:30' },
  { label: '21:00', value: '21:00' },
  { label: '21:30', value: '21:30' },
  { label: '22:00', value: '22:00' },
  { label: '22:30', value: '22:30' },
  { label: '23:00', value: '23:00' },
  { label: '23:30', value: '23:30' },
];

export const timesChannex = [
  { label: "None", value: null },
  { label: '00:00', value: '00:00:00' },
  { label: '00:30', value: '00:30:00' },
  { label: '01:00', value: '01:00:00' },
  { label: '01:30', value: '01:30:00' },
  { label: '02:00', value: '02:00:00' },
  { label: '02:30', value: '02:30:00' },
  { label: '03:00', value: '03:00:00' },
  { label: '03:30', value: '03:30:00' },
  { label: '04:00', value: '04:00:00' },
  { label: '04:30', value: '04:30:00' },
  { label: '05:00', value: '05:00:00' },
  { label: '05:30', value: '05:30:00' },
  { label: '06:00', value: '06:00:00' },
  { label: '06:30', value: '06:30:00' },
  { label: '07:00', value: '07:00:00' },
  { label: '07:30', value: '07:30:00' },
  { label: '08:00', value: '08:00:00' },
  { label: '08:30', value: '08:30:00' },
  { label: '09:00', value: '09:00:00' },
  { label: '09:30', value: '09:30:00' },
  { label: '10:00', value: '10:00:00' },
  { label: '10:30', value: '10:30:00' },
  { label: '11:00', value: '11:00:00' },
  { label: '11:30', value: '11:30:00' },
  { label: '12:00', value: '12:00:00' },
  { label: '12:30', value: '12:30:00' },
  { label: '13:00', value: '13:00:00' },
  { label: '13:30', value: '13:30:00' },
  { label: '14:00', value: '14:00:00' },
  { label: '14:30', value: '14:30:00' },
  { label: '15:00', value: '15:00:00' },
  { label: '15:30', value: '15:30:00' },
  { label: '16:00', value: '16:00:00' },
  { label: '16:30', value: '16:30:00' },
  { label: '17:00', value: '17:00:00' },
  { label: '17:30', value: '17:30:00' },
  { label: '18:00', value: '18:00:00' },
  { label: '18:30', value: '18:30:00' },
  { label: '19:00', value: '19:00:00' },
  { label: '19:30', value: '19:30:00' },
  { label: '20:00', value: '20:00:00' },
  { label: '20:30', value: '20:30:00' },
  { label: '21:00', value: '21:00:00' },
  { label: '21:30', value: '21:30:00' },
  { label: '22:00', value: '22:00:00' },
  { label: '22:30', value: '22:30:00' },
  { label: '23:00', value: '23:00:00' },
  { label: '23:30', value: '23:30:00' },
];

export function getOptionList(
  items: any,
  labelKey: string | string[],
  valueKey: string,
  add_status_to_label?: boolean | undefined,
  add_object_no_value?: {
    label: string, value: number
  }
): { label: any; value: any }[] {
  if (!items || !items.map || typeof items.map !== 'function') return [];
  const itemsNew = items.map((object: any) => {
    let labelComp = '';
    if(typeof labelKey === 'string') {
      labelComp = object[labelKey];
    } else {
      labelComp = object[labelKey[0]]
      for(let label of labelKey){
        if(label !== labelKey[0]) labelComp += ` - ${object[label]}`
      }
    }
    if (add_status_to_label !== undefined && add_status_to_label) {
      labelComp = labelComp + ' (' + object['status'] + ')';
    }
    return {
      label: labelComp,
      value: object[valueKey],
    };
  })
  if(add_object_no_value) itemsNew.push(add_object_no_value)
  return itemsNew
}
export function getOptionListFromArray(
  items: any,
  labelKey: string[],
  valueKey: string
): { label: any; value: any }[] {
  if (!items || !items.map || typeof items.map !== 'function') return [];
  return items.map((object: any) => {
    const label = labelKey.map((e) => object[e]).join(' | ');
    return {
      label: label,
      value: object[valueKey],
    };
  });
}