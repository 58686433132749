import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { DateFormatter, DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { useTranslation } from 'react-i18next';
import Popover from './Popover';
import TextInput from './TextInput';
import { enUS, es, fr, it } from 'date-fns/locale';

type DatePickerProps = {
  value: string | undefined;
  inputFormat: string;
  outputFormat: string;
  displayFormat: string;
  min?: Date;
  max?: Date;
  size?: string;
  onChange: (value: string) => void;
};

export default function DatePicker({
  value,
  inputFormat,
  outputFormat,
  displayFormat,
  size,
  min,
  max,
  onChange,
}: DatePickerProps) {
  const [date, setDate] = useState(value);
  const [selectedDay, setSelectedDay] = useState<Date>();
  const t = useTranslation();
  const locales = { enUS, es, fr, it };

  useEffect(() => {
    const parsedDate = dayjs(value, inputFormat);
    if (value) {
      setDate(parsedDate.format(displayFormat));
      setSelectedDay(parsedDate.toDate());
    }
  }, [value]);

  function getDisabledDates() {
    const result = {
      before: null,
      after: null,
    } as any;

    if (min) {
      result.before = min;
    }

    if (max) {
      result.before = max;
    }

    return result;
  }

  return (
    <div className={'w-full'}>
      <Popover
        className={
          'rounded-2xl'
        }
        // placement={'bottom-start'}
        renderContent={() => (
          <DayPicker
            disabled={getDisabledDates()}
            className={'w-full rounded-2xl'}
            onDayClick={(day) => {
              const parsedDate = dayjs(day);
              setSelectedDay(day);
              setDate(parsedDate.format(displayFormat));
              onChange(parsedDate.format(outputFormat));
            }}
            fromYear={1900}
            toYear={new Date().getFullYear() + 100}
            captionLayout="dropdown"
            selected={selectedDay}
            defaultMonth={selectedDay}
            weekStartsOn={1}
            locale={
              t.i18n.language === 'it'
                ? locales.it
                : t.i18n.language === 'es'
                ? es
                : t.i18n.language === 'fr'
                ? locales.fr
                : locales.enUS
            }
            // firstDayOfWeek={1}
          />
        )}
      >
        <TextInput
          className=" rounded-2xl"
          size={size}
          value={date}
        ></TextInput>
      </Popover>
    </div>
  );
}
