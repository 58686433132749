import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
// import PageTitle from "../components/PageTitle";
import { fetchAllModules } from "../shared/queries";
// import useBreadcrumbs from "../hooks/useBreadcrumbs";
import PageWrapper from "../components/PageWrapper";
import Button from "../components/Button";
import { useHistory } from "react-router-dom";
import { moduleId } from "../shared/utils/config";

export default function Modules() {
  const { t, i18n } = useTranslation();
  // useBreadcrumbs([{ label: t("navigation.modules"), route: "/modules" }]);
  const history = useHistory();
  const { data: dataModules } = useQuery<any>([], () => fetchAllModules());

  const renderDesc = (mod: any) => {
    if (mod.id === moduleId.paytourist) {
      return (
        <div className={"leading-relaxed font-normal text-xs text-gray-500"}>
          {t(`module.id${mod.id}Info`)}
          <div>
            Elenco città: Castello/Sicilia, Agerola/Campania, Agropoli/Campania,
            Alberobello/Puglia, Amalfi/Campania, Ascea/Campania, Bari/Puglia,
            Bari Sardo/Sardegna, Bracciano/Lazio, Budoni/Sardegna,
            Camerota/Campania, Capaccio Paestum/Campania, Casal Velino/Campania,
            Castellabate/Campania, Castellammare del Golfo/Sicilia,
            Castelmola/Sicilia, Centola/Campania, Fasano/Puglia, Formia/Lazio,
            Locorotondo/Puglia, Maiori/Campania, Minori/Campania,
            Monopoli/Puglia, Montecorice/Campania, Padula/Campania,
            Perledo/Lombardia, Peschiera del Garda/Veneto, Pimonte/Campania,
            Polignano a Mare/Puglia, Pollica/Campania, Ravello/Campania,
            Rieti/Lazio, Roseto degli Abruzzi/Abruzzo, San Giovanni a
            Piro/Campania, San Mauro Cilento/Campania, Sapri/Campania,
            Specchia/Puglia, Tertenia/Sardegna, Vibonati/Campania
          </div>
        </div>
      );
    }
    return (
      <div className={"leading-relaxed font-normal text-xs text-gray-500"}>
        {t(`module.id${mod.id}Info`)}
      </div>
    );
  };

  const renderMonthlyFee = (mod: any) => {
    if (mod.self_price === null || mod.self_price === undefined) {
      return <span>&euro;{(mod.default_price / 100).toFixed(2)}</span>;
    } else {
      if (mod.default_price <= mod.self_price) {
        return <span>&euro;{(mod.self_price / 100).toFixed(2)}</span>;
      } else {
        return (
          <>
            <span className="line-through opacity-60 mr-2">
              &euro;{(mod.default_price / 100).toFixed(2)}
            </span>
            <span>&euro;{(mod.self_price / 100).toFixed(2)}</span>
          </>
        );
      }
    }
  };

  return (
    <PageWrapper>
      {/* <PageTitle title={t("navigation.modules")}></PageTitle> */}

      <div className={"space-y-5"}>
        {dataModules &&
          dataModules?.modules
            ?.filter(
              (module: any) =>
                module?.active_locals_count !== dataModules?.user_locals_count
            )
            .map((module: any) => (
              <div
                key={module.id}
                className="bg-white shadow-xl p-6 flex-1 rounded-2xl"
              >
                <div className="flex flex-col lg:flex-row pb-4 lg:items-center justify-between">
                  <div className="flex-1 py-1 px-1 text-gray-700 font-medium">
                    <>
                      <div className="uppercase">
                        {t(`module.id${module.id}`)}
                      </div>
                      <span className="font-normal text-sm">
                        {t("general.monthlyfee")}:{renderMonthlyFee(module)}
                      </span>
                    </>
                  </div>

                  <div
                    className={
                      "font-medium py-1 px-1 md:text-right text-gray-700"
                    }
                  >
                    <>
                      <div>
                        {t("module.activeApartments", {
                          connected: module.active_locals_count,
                          total: dataModules.user_locals_count,
                        })}
                      </div>
                      {module.deactivating_locals_count > 0 ? (
                        <span className="font-normal text-sm">
                          ({" "}
                          {t("module.inactiveApartments", {
                            total: module.deactivating_locals_count,
                          })}{" "}
                          )
                        </span>
                      ) : null}
                    </>
                  </div>
                  <div className={"font-medium py-1 px-1"}></div>
                </div>
                <div className="md:flex justify-between items-center ml-2">
                  <div className="md:w-1/2">{renderDesc(module)}</div>
                  <div className="justify-end md:mt-0 mt-2">
                    <Button
                      size="small"
                      onClick={() =>
                        history.push("/modules/module#" + module.id)
                      }
                      label={t("general.viewApartments")}
                    />
                  </div>
                </div>
                {i18n.exists(`module.${module.id}moduleFor`) ? (
                  <div className="mt-3 font-normal text-xs text-gray-700 ml-2">
                    <p className="font-medium">{t("general.moduleFor")}</p> {t(`module.${module.id}moduleFor`)}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
        {dataModules &&
          dataModules?.modules
            ?.filter(
              (module: any) =>
                module?.active_locals_count === dataModules?.user_locals_count
            )
            .map((module: any) => (
              <div
                key={module.id}
                className="bg-white shadow-xl p-6 flex-1 rounded-2xl"
              >
                <div className="flex flex-col lg:flex-row pb-4 lg:items-center justify-between">
                  <div className="flex-1 py-1 px-1 text-gray-700 font-medium">
                    <>
                      <div className="uppercase">
                        {t(`module.id${module.id}`)}
                      </div>
                      <span className="font-normal text-sm">
                        {t("general.monthlyfee")}: &euro;
                        {(module.default_price / 100).toFixed(2)}
                      </span>
                    </>
                  </div>

                  <div
                    className={
                      "font-medium py-1 px-1 md:text-right text-gray-700"
                    }
                  >
                    <>
                      <div>
                        {t("module.activeApartments", {
                          connected: module.active_locals_count,
                          total: dataModules.user_locals_count,
                        })}
                      </div>
                      {module.deactivating_locals_count > 0 ? (
                        <span className="font-normal text-sm">
                          ({" "}
                          {t("module.inactiveApartments", {
                            total: module.deactivating_locals_count,
                          })}{" "}
                          )
                        </span>
                      ) : null}
                    </>
                  </div>
                  <div className={"font-medium py-1 px-1"}></div>
                </div>
                <div className="md:flex justify-between items-center ml-2">
                  <div className="md:w-1/2">{renderDesc(module)}</div>
                  <div className="justify-end md:mt-0 mt-2">
                    <Button
                      size="small"
                      onClick={() =>
                        history.push("/modules/module#" + module.id)
                      }
                      label={t("general.viewApartments")}
                    />
                  </div>
                </div>
                {i18n.exists(`module.${module.id}moduleFor`) ? (
                  <div className="mt-3 font-normal text-xs text-gray-700 ml-2">
                    <p className="font-medium">{t("general.moduleFor")}</p> {t(`module.${module.id}moduleFor`)}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
      </div>
    </PageWrapper>
  );
}
