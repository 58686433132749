import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  modalValueOverrideState,
  resetModalValueOverride,
  setModalValueOverride,
} from "../../features/channelManager/channelManagerSlice";
import { useMutation } from "react-query";
import { changeAvailability, changeRestrictions } from "../../shared/queries";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import Modal from "../Modal";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import Popover from "../Popover";
import { DayPicker } from "react-day-picker";
import FormField from "../FormField";
import TextInput from "../TextInput";
import SelectInput from "../SelectInput";
import { BaseSelectItem } from "../../types";
import Checkbox from "../Checkbox";
import ReactSwitch from "react-switch";
import ModalActions from "../ModalAction";

type Props = {
  refetchRestrictions: any;
};

const ModalOverride = (props: Props) => {
  const { refetchRestrictions } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();

  const rules = [
    { label: t("channelManager.rate_plan.rate"), value: "rate" },
    { label: t("channelManager.rate_plan.stopSell"), value: "stop_sell" },
    {
      label: t("channelManager.rate_plan.closedToArrival"),
      value: "closed_to_arrival",
    },
    {
      label: t("channelManager.rate_plan.closedToDeparture"),
      value: "closed_to_departure",
    },
    {
      label: t("channelManager.rate_plan.minStayArrival"),
      value: "min_stay_arrival",
    }, //min_stay_through min_stay_arrival
    { label: t("channelManager.rate_plan.maxStay"), value: "max_stay" },
  ];

  const modalValueOverrideStateSelector = useSelector(modalValueOverrideState);
  const modalValueOverride = modalValueOverrideStateSelector.data;

  const bulkUpdateMutation = useMutation(
    (data: any) => changeRestrictions(data),
    {
      onSuccess: () => {
        toast.success(t("general.requestCompleted"));
        refetchRestrictions();
        resetValueOverride();
      },
      onError: () => {
        toast.error(t("general.requestError"));
      },
    }
  );
  const availabilityMutation = useMutation(
    (data: any) => changeAvailability(data),
    {
      onSuccess: () => {
        toast.success(t("general.requestCompleted"));
        refetchRestrictions();
        resetValueOverride();
      },
      onError: () => {
        toast.error(t("general.requestError"));
      },
    }
  );
  const resetValueOverride = () => {
    dispatch(resetModalValueOverride());
  };

  const sendChange = () => {
    if (
      !modalValueOverride.dateRange ||
      !modalValueOverride.dateRange.from ||
      modalValueOverride.dateRange.from === "undefined"
    ) {
      toast.error(t("general.error"));
      return;
    }
    const startDate = dayjs(modalValueOverride.dateRange.from).format(
      "YYYY-MM-DD"
    );
    const endDate = dayjs(modalValueOverride.dateRange.to).format("YYYY-MM-DD");
    const starterData: any = { values: [] };
    if (startDate === endDate || dayjs(endDate).isBefore(dayjs(startDate))) {
      switch (modalValueOverride.restriction) {
        case "availability":
          starterData.values.push({
            property_id: modalValueOverride.propertyId,
            room_type_id: modalValueOverride.roomTypeId,
            date: startDate ?? "",
            availability: modalValueOverride.availabilityValue,
          });
          break;
        case "rate":
          starterData.values.push({
            property_id: modalValueOverride.propertyId,
            rate_plan_id: modalValueOverride.ratePlanId,
            date: startDate ?? "",
            rate: modalValueOverride.priceValue * 100,
          });
          break;
        case "max_stay":
          starterData.values.push({
            property_id: modalValueOverride.propertyId,
            rate_plan_id: modalValueOverride.ratePlanId,
            date: startDate ?? "",
            max_stay: modalValueOverride.maxStayValue,
          });
          break;
        case "min_stay_arrival":
          starterData.values.push({
            property_id: modalValueOverride.propertyId,
            rate_plan_id: modalValueOverride.ratePlanId,
            date: startDate ?? "",
            min_stay_arrival: modalValueOverride.minStayValue,
            min_stay_through: modalValueOverride.minStayValue,
          });
          break;
        case "stop_sell":
          starterData.values.push({
            property_id: modalValueOverride.propertyId,
            rate_plan_id: modalValueOverride.ratePlanId,
            date: startDate ?? "",
            stop_sell: modalValueOverride.stopSellValue,
          });
          break;
        case "closed_to_arrival":
          starterData.values.push({
            property_id: modalValueOverride.propertyId,
            rate_plan_id: modalValueOverride.ratePlanId,
            date: startDate ?? "",
            closed_to_arrival: modalValueOverride.closedToArrivalValue,
          });
          break;
        case "closed_to_departure":
          starterData.values.push({
            property_id: modalValueOverride.propertyId,
            rate_plan_id: modalValueOverride.ratePlanId,
            date: startDate ?? "",
            closed_to_departure: modalValueOverride.closedToDepartureValue,
          });
          break;
        default:
          break;
      }
    } else {
      switch (modalValueOverride.restriction) {
        case "availability":
          starterData.values.push({
            property_id: modalValueOverride.propertyId,
            room_type_id: modalValueOverride.roomTypeId,
            date_to: endDate ?? "",
            date_from: startDate ?? "",
            availability: modalValueOverride.availabilityValue,
          });
          break;
        case "rate":
          starterData.values.push({
            property_id: modalValueOverride.propertyId,
            rate_plan_id: modalValueOverride.ratePlanId,
            date_to: endDate ?? "",
            date_from: startDate ?? "",
            rate: modalValueOverride.priceValue * 100,
          });
          break;
        case "max_stay":
          starterData.values.push({
            property_id: modalValueOverride.propertyId,
            rate_plan_id: modalValueOverride.ratePlanId,
            date_to: endDate ?? "",
            date_from: startDate ?? "",
            max_stay: modalValueOverride.maxStayValue,
          });
          break;
        case "min_stay_arrival":
          starterData.values.push({
            property_id: modalValueOverride.propertyId,
            rate_plan_id: modalValueOverride.ratePlanId,
            date_to: endDate ?? "",
            date_from: startDate ?? "",
            min_stay_arrival: modalValueOverride.minStayValue,
            min_stay_through: modalValueOverride.minStayValue,
          });
          break;
        case "stop_sell":
          starterData.values.push({
            property_id: modalValueOverride.propertyId,
            rate_plan_id: modalValueOverride.ratePlanId,
            date_to: endDate ?? "",
            date_from: startDate ?? "",
            stop_sell: modalValueOverride.stopSellValue,
          });
          break;
        case "closed_to_arrival":
          starterData.values.push({
            property_id: modalValueOverride.propertyId,
            rate_plan_id: modalValueOverride.ratePlanId,
            date_to: endDate ?? "",
            date_from: startDate ?? "",
            closed_to_arrival: modalValueOverride.closedToArrivalValue,
          });
          break;
        case "closed_to_departure":
          starterData.values.push({
            property_id: modalValueOverride.propertyId,
            rate_plan_id: modalValueOverride.ratePlanId,
            date_to: endDate ?? "",
            date_from: startDate ?? "",
            closed_to_departure: modalValueOverride.closedToDepartureValue,
          });
          break;
        default:
          break;
      }
    }

    if (modalValueOverride.restriction === "availability") {
      availabilityMutation.mutate(starterData);
    } else {
      bulkUpdateMutation.mutate(starterData);
    }
  };
  return (
    <>
      {/* MODALE */}
      <Modal visible={modalValueOverride.display} isResvDocs>
        <ModalCard
          title={t("channelManager.valueOverride")}
          className="w-full md:h-auto h-screen"
        >
          <ModalSection>
            <div
              className={`p-4 grid md:grid-cols-2 grid-cols-1 align-middle md:h-96 h-full gap-1.5`}
            >
              <div>{t("channelManager.dateRange")}:</div>
              <Popover
                 className="md:border-b-0 border-b-4 pb-3 font-semibold border-cyan-100"
                // placement={'bottom-start'}
                renderContent={() => (
                  <div className="mt-4 px-4 mx-auto">
                    <DayPicker
                      mode="range"
                      className={"w-full rounded-2xl"}
                      selected={{from: new Date(modalValueOverride.dateRange.from), to: new Date(modalValueOverride.dateRange.to)}}
                      onSelect={(range: any) => {
                        dispatch(
                          setModalValueOverride({
                            ...modalValueOverride,
                            dateRange: range,
                          })
                        );
                      }}
                    />
                  </div>
                )}
              >
                <FormField label={""}>
                  <TextInput
                    value={
                      modalValueOverride.dateRange
                        ? `${dayjs(modalValueOverride.dateRange.from).format(
                            "DD-MM-YYYY"
                          )} --> ${dayjs(
                            modalValueOverride.dateRange.to
                          ).format("DD-MM-YYYY")}`
                        : t("channelManager.selectDateRange")
                    }
                  />
                </FormField>
              </Popover>
              <div>{t("channelManager.rate_plan.restrictions")}:</div>
              {modalValueOverride.type !== "availability" ? (
                <div className="md:border-b-0 border-b-4 pb-3 font-semibold border-cyan-100">
                  <FormField label={""} className="flex-1">
                    <SelectInput<BaseSelectItem, false>
                      isLoading={false}
                      placeholder={t("selector.selectOption")}
                      options={rules}
                      value={rules.find(
                        (val) => val.value === modalValueOverride.restriction
                      )}
                      onChange={(item) => {
                        if (item) {
                          dispatch(
                            setModalValueOverride({
                              ...modalValueOverride,
                              restriction: item.value,
                            })
                          );
                        }
                      }}
                    />
                  </FormField>
                </div>
              ) : (
                <div className=" md:border-b-0 border-b-4 pb-3 border-cyan-100 font-semibold">{t("apartment.availability")}</div>
              )}
              {modalValueOverride.type === "availability" && (
                <>
                  <div>{t("apartment.availability")}:</div>
                  <div className="grid grid-cols-2 md:border-b-0 border-b-4 pb-3 border-cyan-100">
                    <Checkbox
                      truncate
                      className="rounded-xl p-0.5 max-w-min"
                      label={t("channelManager.available")}
                      key={"True"}
                      size="xs"
                      onChange={() => {
                        dispatch(
                          setModalValueOverride({
                            ...modalValueOverride,
                            availabilityValue: 1,
                          })
                        );
                      }}
                      checked={modalValueOverride.availabilityValue === 1}
                    />
                    <Checkbox
                      truncate
                      className="rounded-xl p-0.5 max-w-min"
                      label={t("channelManager.notAvailable")}
                      key={"False"}
                      size="xs"
                      onChange={() => {
                        dispatch(
                          setModalValueOverride({
                            ...modalValueOverride,
                            availabilityValue: 0,
                          })
                        );
                      }}
                      checked={modalValueOverride.availabilityValue === 0}
                    />
                  </div>
                </>
              )}
              {modalValueOverride.type !== "availability" && (
                <>
                  <div>{t("rules.value")}:</div>
                  <div  className="md:border-b-0 border-b-4 pb-3 font-semibold border-cyan-100">
                    <FormField label={""} className="flex-1">
                      {modalValueOverride.restriction === "stop_sell" ||
                      modalValueOverride.restriction === "closed_to_arrival" ||
                      modalValueOverride.restriction ===
                        "closed_to_departure" ? (
                        <>
                          {modalValueOverride.restriction === "stop_sell" && (
                            <ReactSwitch
                              onChange={() => {
                                dispatch(
                                  setModalValueOverride({
                                    ...modalValueOverride,
                                    stopSellValue:
                                      !modalValueOverride.stopSellValue,
                                  })
                                );
                              }}
                              checked={modalValueOverride.stopSellValue}
                            ></ReactSwitch>
                          )}
                          {modalValueOverride.restriction ===
                            "closed_to_arrival" && (
                            <ReactSwitch
                              onChange={() => {
                                dispatch(
                                  setModalValueOverride({
                                    ...modalValueOverride,
                                    closedToArrivalValue:
                                      !modalValueOverride.closedToArrivalValue,
                                  })
                                );
                              }}
                              checked={modalValueOverride.closedToArrivalValue}
                            ></ReactSwitch>
                          )}
                          {modalValueOverride.restriction ===
                            "closed_to_departure" && (
                            <ReactSwitch
                              onChange={() => {
                                dispatch(
                                  setModalValueOverride({
                                    ...modalValueOverride,
                                    closedToDepartureValue:
                                      !modalValueOverride.closedToDepartureValue,
                                  })
                                );
                              }}
                              checked={
                                modalValueOverride.closedToDepartureValue
                              }
                            ></ReactSwitch>
                          )}
                        </>
                      ) : (
                        <>
                          {modalValueOverride.restriction === "rate" && (
                            <TextInput
                              value={modalValueOverride.priceValue}
                              type="number"
                              onChange={(val) => {
                                dispatch(
                                  setModalValueOverride({
                                    ...modalValueOverride,
                                    priceValue: val,
                                  })
                                );
                              }}
                            />
                          )}
                          {modalValueOverride.restriction ===
                            "min_stay_arrival" && (
                            <TextInput
                              value={modalValueOverride.minStayValue}
                              type="number"
                              onChange={(val) => {
                                dispatch(
                                  setModalValueOverride({
                                    ...modalValueOverride,
                                    minStayValue: val,
                                  })
                                );
                              }}
                            />
                          )}
                          {modalValueOverride.restriction === "max_stay" && (
                            <TextInput
                              value={modalValueOverride.maxStayValue}
                              type="number"
                              onChange={(val) => {
                                dispatch(
                                  setModalValueOverride({
                                    ...modalValueOverride,
                                    maxStayValue: val,
                                  })
                                );
                              }}
                            />
                          )}
                        </>
                      )}
                    </FormField>
                  </div>
                  <div>{t("channelManager.currentPrice")}:</div>
                  <div  className="md:border-b-0 border-b-4 pb-3 font-semibold border-cyan-100">{modalValueOverride.currentPrice} EUR</div>
                </>
              )}
              <div>{t("channelManager.roomType")}:</div>
              <div  className="md:border-b-0 border-b-4 pb-3 font-semibold border-cyan-100">{modalValueOverride.roomType}</div>
              {modalValueOverride.ratePlan && (
                <>
                  <div>{t("channelManager.rate_plan.rate")}:</div>
                  <div className="md:border-b-0 border-b-4 pb-3 font-semibold border-cyan-100">{modalValueOverride.ratePlan}</div>
                </>
              )}
            </div>
          </ModalSection>
          <ModalActions
            isLoading={false}
            onSave={() => {
              sendChange();
            }}
            saveLabel={t("general.save")}
            saveDisabled={false}
            onClose={() => {
              resetValueOverride();
            }}
          />
        </ModalCard>
      </Modal>
    </>
  );
};

export default ModalOverride;
