import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  fetchGalleryV3,
  fetchLocalLogo,
  uploadGalleryV3,
} from "../../shared/queries";
import { GalleryItem } from "../../types";
import AsyncImage from "../AsyncImage";
import Button from "../Button";

export default function ApartmentGallery({ localKey }: { localKey?: string }) {
  const { t } = useTranslation();
  const [logoDet, setLogoDet] = useState<{
    locals: any[];
    logo1: string | null;
    logo2: string | null;
    color: string | null;
  }>({
    locals: [localKey],
    logo1: null,
    logo2: null,
    color: null,
  });

  const { refetch } = useQuery(
    ["gallery", localKey],
    () => fetchGalleryV3("APT", "local_key", localKey || ""),
    {
      enabled: localKey !== undefined,
      onSuccess: (data) => {
        const gallery: GalleryItem[] = [];
        for (let i = 0; i < 10; i++) {
          gallery.push({
            name: "",
            idx: "",
            user_key: "",
            public_url: "",
            resv_key: "",
            element: "",
            local_key: "",
            id: -1,
          });
        }

        data?.results?.forEach((item, index) => {
          gallery[parseInt(item.idx) - 1] = item;
        });

        setImages([...gallery]);
      },
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: logoDetail } = useQuery(
    ["logoDetail"],
    () => fetchLocalLogo(localKey as string),
    {
      onSuccess: (data) => {
        if (data.results?.color)
          setLogoDet({
            locals: [localKey],
            logo1: data.results.logo1_url as string,
            logo2: data.results.logo2_url as string,
            color: data.results?.color as string,
          });
      },
    }
  );

  const [previewData] = useState<any>();
  const [images, setImages] = useState<GalleryItem[]>([]);
  const uploadInput = useRef<any>();
  const [currentIdx, setCurrentIdx] = useState<any>(0);

  const uploadMutation = useMutation(
    (file: any) =>
      uploadGalleryV3({
        local_key : localKey || '',
        file : file,
        element : "APT",
        idx : currentIdx
      }),
    {
      onSuccess: () => {
        toast.success(t("general.fileUploaded"));
        refetch();
      },
    }
  );

  function handleFile(event: any) {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const reader = new FileReader();

      reader.addEventListener("loadend", () => {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const maxSize = 640;

          let width = image.width;
          let height = image.height;

          if (width > image.width) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }
          }

          canvas.height = height;
          canvas.width = width;

          const context = canvas.getContext("2d");

          if (context) {
            context.drawImage(image, 0, 0, width, height);
            const data = canvas.toDataURL("image/jpeg", 0.9);
            uploadMutation.mutate(data);
          }
        };
        if (reader.result) {
          image.src = reader.result.toString();
        }
      });

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  return (
    <div className={"mb-6"}>
      <input type="file" hidden onChange={handleFile} ref={uploadInput} />

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        {images.map((image, index) => (
          <div
            key={index}
            className={"bg-white border flex flex-col rounded-2xl"}
          >
            {image.id === -1 ? (
              <img
                className={"w-full rounded-t-2xl"}
                src={"/images/emptyimage.jpg"}
                style={{ height: "150px" }}
                alt="emptyimage"
              />
            ) : (
              <AsyncImage
                isDirect={true}
                isApi={image.public_url.indexOf("http") === -1}
                className={"w-full h-full rounded-t-2xl"}
                width={"100%"}
                height={"150px"}
                url={
                  previewData && currentIdx === index
                    ? previewData
                    : image.public_url
                }
              />
            )}
            <div
              className={
                "px-4 flex items-center justify-between py-2 text-xs border-t border-gray-300"
              }
            >
              <div>##Apartment-image-{image.idx || index + 1}##</div>
              <div>
                <Button
                  flat
                  padding={"xs"}
                  size={"small"}
                  icon={"document-add"}
                  onClick={() => {
                    setCurrentIdx(index + 1);
                    if (uploadInput && uploadInput.current)
                      uploadInput.current.click();
                  }}
                />
              </div>
            </div>
          </div>
        ))}
        <div className={"bg-white border flex flex-col rounded-2xl"}>
          {!logoDet.logo1 ? (
            <img
              className={"w-full rounded-t-2xl"}
              src={"/images/emptyimage.jpg"}
              style={{ height: "150px" }}
              alt="emptyimage"
            />
          ) : (
            <AsyncImage
              isDirect={true}
              className={"w-full h-full rounded-t-2xl"}
              width={"100%"}
              height={"150px"}
              url={logoDet.logo1}
            />
          )}
          <div
            className={
              "px-4 flex items-center justify-between py-2 text-xs border-t border-gray-300"
            }
          >
            <div>##Apartment-logo-1##</div>
          </div>
        </div>
        <div className={"bg-white border flex flex-col rounded-2xl"}>
          {!logoDet.logo2 ? (
            <img
              className={"w-full rounded-t-2xl"}
              src={"/images/emptyimage.jpg"}
              style={{ height: "150px" }}
              alt="emptyimage"
            />
          ) : (
            <AsyncImage
              isDirect={true}
              className={"w-full h-full rounded-t-2xl"}
              width={"100%"}
              height={"150px"}
              url={logoDet.logo2}
            />
          )}
          <div
            className={
              "px-4 flex items-center justify-between py-2 text-xs border-t border-gray-300"
            }
          >
            <div>##Apartment-logo-2##</div>
          </div>
        </div>
      </div>
    </div>
  );
}
