import { Buyable } from '../../types';
import AsyncImage from '../AsyncImage';
import ImageUploader from '../ImageUploader';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { updateService, uploadServiceCover } from '../../shared/queries';

const XHRUpload = require('@uppy/xhr-upload');

type ServicePreviewProps = {
  data: Buyable;
};

export default function ServicePreview({ data }: ServicePreviewProps) {
  const { t } = useTranslation();

  const uploadMutation = useMutation((file: any) => uploadServiceCover(file), {
    onSuccess: (res) => {
      updateServiceMutation.mutate({ ...data, cover: res.data.public_url });
    },
  });

  const updateServiceMutation = useMutation(
    (service: Buyable) => updateService(data.id ?? '', service),
    {
      onSuccess: () => {
        toast.success(t('general.fileUploaded'));
      },
    }
  );

  return (
    <div className={''}>
      <div className="bg-white shadow-lg overflow-hidden rounded-2xl mx-auto max-w-xl border">
        <ImageUploader upload={(data) => uploadMutation.mutate(data)}>
          <img
            className={'w-full h-full rounded-t-2xl'}
            src={
              data.cover && data.cover !== ''
                ? data.cover
                : '/images/emptyimage.jpg'
            }
          />
        </ImageUploader>

        {/* <Dashboard height={250} uppy={uppy} plugins={['ImageEditor']} /> */}

        <div className={'p-4'}>
          <div className="text-lg font-semibold text-gray-700">
            {data.title}
          </div>

          {data.description && (
            <div
              className={'mt-4 text-gray-500'}
              dangerouslySetInnerHTML={{ __html: data.description }}
            ></div>
          )}
        </div>

        <div className="p-4 justify-end flex">
          <button
            className={
              'rounded-2xl text-white bg-cyan-600 font-semibold border-2 px-3 py-2 border-cyan-600 text-sm'
            }
          >
            Buy {data.amount_tot} EUR {'->'}
          </button>
        </div>
      </div>
    </div>
  );
}
