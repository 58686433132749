import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom"; // Portal support
import Button from "./Button";

type ImageViewModalProps = {
  visible: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  className?: string;
  noBackdropDismiss?: boolean;
  carousel?:boolean
};

export const ImageViewModal: React.FC<ImageViewModalProps> = ({
  visible,
  onClose,
  children,
  className = "",
  noBackdropDismiss = false,
  carousel = false,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [visible]);

  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (
      !noBackdropDismiss &&
      overlayRef.current &&
      event.target === overlayRef.current &&
      onClose
    ) {
      onClose();
    }
  };

  if (!visible) return null;

  return ReactDOM.createPortal(
    <AnimatePresence>
      {visible && (
        <motion.div
          ref={overlayRef}
          className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 ${className}`}
          onClick={handleBackdropClick}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <motion.div
            className="relative bg-white max-w-[90vw] max-h-[85vh] rounded shadow-md overflow-auto"
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Button
              onClick={onClose}
              className="absolute top-2 right-2 text-gray-600 bg-white hover:bg-gray-100 focus:outline-none shadow-md rounded-full p-2 float-right"
              icon="close"
            ></Button>

            {children}
            
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
};
