import { useEffect } from "react";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  createIntegration,
  fetchContracts,
  fetchIntegrations,
  fetchCustomEmails,
  fetchAllModules,
  fetchV3AllLocals,
  fetchCustomCheckins,
  createIntegrationLocal,
  updateIntegrationLocals,
  fetchApartmentSettings,
} from "../../shared/queries";
import {
  getOptionList,
  getOptionListFromArray,
  times,
} from "../../shared/utils/formatting";
import {
  ListApiResponseDataPaginated,
  Integration,
  LocalTable,
  BaseSelectItem,
  ListApiResponseDataPaginatedV3,
} from "../../types";
import Checkbox from "../Checkbox";
import integrations from "../../shared/integrations";
import FormField from "../FormField";
import Modal from "../Modal";
import ModalActions from "../ModalAction";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import SelectInput from "../SelectInput";
import { useTranslation } from "react-i18next";
import PaystaySelector from "./channel-selector/PaystaySelector";
import CitytaxSelector from "./channel-selector/CitytaxSelector";
import DocumentSelector from "./channel-selector/DocumentSelector";
import dayjs from "dayjs";
import { moduleId } from "../../shared/utils/config";
import DepositSelector from "./channel-selector/DepositSelector";

type AddIntegrationProps = {
  visible: boolean;
  integrationId: string;
  editId?: string | undefined;
  presetIntegration?: any;
  refId: string;
  onSave?: () => void;
  onClose: () => void;
};

export default function AddIntegration({
  visible,
  integrationId,
  editId,
  presetIntegration,
  refId,
  onSave,
  onClose,
}: AddIntegrationProps) {
  const { t } = useTranslation();

  const [local, setLocal] = useState<LocalTable>();
  const [metaIntegration, setMetaIntegration] = useState<any>();
  const [integrationType, setIntegrationType] = useState<any>();
  const [activeContract, setActiveContract] = useState<any>(false);

  const { data, isLoading } = useQuery<any>("locals", fetchV3AllLocals);

  const {
    data: activeIntegrations,
    refetch: refetchActive,
    isLoading: loadingActiveIntegrations,
  } = useQuery<ListApiResponseDataPaginatedV3<Integration>, Error>(
    ["activeIntegrations", integrationId],
    fetchIntegrations,
    {
      onSuccess: (data) => {
        if (data && data.results && data.results.data) {
          var i = data.results.data.find(
            (ai) => ai.id.toString() === integrationId.toString()
          );
          if (
            i?.integr_type &&
            i.integr_type !== undefined &&
            i.integr_type !== null
          ) {
            setIntegrationType(i.integr_type);
            setMetaIntegration(
              integrations.find((ai) => ai.key === i?.integr_type)
            );
          }
        }
      },
    }
  );

  const [integration, setIntegration] = useState<any>({
    action: "new_local",
    integr_chk_asknumguests: false,
    integr_chk_contract: false,
    integr_chk_documents: false,
    integr_chk_invoice: false,
    integr_chk_mail: false,
    integr_notify_mail_guest_active: false,
    integr_chk_sms: false,
    integr_chk_sms_done: false,
    integr_chk_whatsapp: false,
    integr_chk_whatsapp_done: false,
    integr_chk_payments: false,
    integr_chk_otp: false,
    integr_chk_photo: false,
    integr_chk_photo_all: false,
    integr_chk_cc: false,
    integr_chk_citytax: false,
    integr_chk_paystay: false,
    integr_chk_deposit: false,
    integr_chk_documents_pa_extracityforforeign: true,
    integr_time_checkin: "15:00",
    integr_time_checkout: "10:00",
    integr_cust_contract_ptr: 0,
    integr_cust_checkin_ptr: 0,
    integr_cust_email_ptr: 0,
    integr_notify_now: true,
    integr_notify_7days: true,
    integr_notify_3days: true,
    integr_notify_1days: true,
    integr_notify_done_1days: true,
    integr_mark: 2,
    integr_chk_docs_auto_confirmation: false,
    integr_chk_citytax_chargeguest: false,
    integr_chk_paystay_chargeguest: false,
    integr_chk_deposit_chargeguest: false,
    integr_deposit_amount: 0,
    integr_chk_citytax_filterchannel: {
      AIRBNB: false,
      BOOKING: false,
      EXPEDIA: false,
      OTHER: false,
    },
    integr_chk_paystay_filterchannel: {
      AIRBNB: false,
      BOOKING: false,
      EXPEDIA: false,
      OTHER: false,
    },
    integr_chk_deposit_filterchannel: {
      AIRBNB: false,
      BOOKING: false,
      EXPEDIA: false,
      OTHER: false,
    },
    integr_chk_asknumguests_filterchannel: {
      AIRBNB: false,
      BOOKING: false,
      EXPEDIA: false,
      OTHER: false,
    },
    integr_avoid_add_filterchannel: {
      AIRBNB: false,
      BOOKING: false,
      EXPEDIA: false,
      OTHER: false,
    },
  });

  const mutation = useMutation(
    () =>
      createIntegrationLocal({
        ...integration,
        integr_chk_paystay_filterchannel: JSON.stringify(
          {
            EXPEDIA:
              integration.integr_chk_paystay_filterchannel
                ?.EXPEDIA ?? false,
            BOOKING:
              integration.integr_chk_paystay_filterchannel
                ?.BOOKING ?? false,
            AIRBNB:
              integration.integr_chk_paystay_filterchannel
                ?.AIRBNB ?? false,
            OTHER:
              integration.integr_chk_paystay_filterchannel
                ?.OTHER ?? false,
          }
        ),
        integr_chk_citytax_filterchannel: JSON.stringify(
          {
            EXPEDIA:
              integration.integr_chk_citytax_filterchannel
                ?.EXPEDIA ?? false,
            BOOKING:
              integration.integr_chk_citytax_filterchannel
                ?.BOOKING ?? false,
            AIRBNB:
              integration.integr_chk_citytax_filterchannel
                ?.AIRBNB ?? false,
            OTHER:
              integration.integr_chk_citytax_filterchannel
                ?.OTHER ?? false,
          }
        ),
        integr_chk_deposit_filterchannel: JSON.stringify(
          {
            EXPEDIA:
              integration.integr_chk_deposit_filterchannel
                ?.EXPEDIA ?? false,
            BOOKING:
              integration.integr_chk_deposit_filterchannel
                ?.BOOKING ?? false,
            AIRBNB:
              integration.integr_chk_deposit_filterchannel
                ?.AIRBNB ?? false,
            OTHER:
              integration.integr_chk_deposit_filterchannel
                ?.OTHER ?? false,
          }
        ),
        integr_chk_asknumguests_filterchannel: JSON.stringify(
          {
            EXPEDIA:
              integration.integr_chk_asknumguests_filterchannel
                ?.EXPEDIA ?? false,
            BOOKING:
              integration.integr_chk_asknumguests_filterchannel
                ?.BOOKING ?? false,
            AIRBNB:
              integration.integr_chk_asknumguests_filterchannel
                ?.AIRBNB ?? false,
            OTHER:
              integration.integr_chk_asknumguests_filterchannel
                ?.OTHER ?? false,
          }
        ),
        integr_avoid_add_filterchannel: JSON.stringify(
          {
            AIRBNB: integration.integr_avoid_add_filterchannel?.AIRBNB ?? false,
            BOOKING: integration.integr_avoid_add_filterchannel?.BOOKING ?? false,
            EXPEDIA: integration.integr_avoid_add_filterchannel?.EXPEDIA ?? false,
            OTHER: integration.integr_avoid_add_filterchannel?.OTHER ?? false,
          }
        ),
        local_key: local?.local_key,
        integr_user_id: integrationId,
        ref: refId,
      }),
    {
      onSuccess: (data: any) => {
        toast.success(t("general.created"));
        if (onSave) onSave();
        if (onClose) onClose();
      },
      onError: (error: Error) => {
        toast.error(t(error.message) || t("general.requestError"));
      },
    }
  );

  const updateMutation = useMutation(
    () =>
      updateIntegrationLocals({
        ...integration,
        integr_chk_paystay_filterchannel: JSON.stringify(
          {
            EXPEDIA:
              integration.integr_chk_paystay_filterchannel
                ?.EXPEDIA ?? false,
            BOOKING:
              integration.integr_chk_paystay_filterchannel
                ?.BOOKING ?? false,
            AIRBNB:
              integration.integr_chk_paystay_filterchannel
                ?.AIRBNB ?? false,
            OTHER:
              integration.integr_chk_paystay_filterchannel
                ?.OTHER ?? false,
          }
        ),
        integr_chk_citytax_filterchannel: JSON.stringify(
          {
            EXPEDIA:
              integration.integr_chk_citytax_filterchannel
                ?.EXPEDIA ?? false,
            BOOKING:
              integration.integr_chk_citytax_filterchannel
                ?.BOOKING ?? false,
            AIRBNB:
              integration.integr_chk_citytax_filterchannel
                ?.AIRBNB ?? false,
            OTHER:
              integration.integr_chk_citytax_filterchannel
                ?.OTHER ?? false,
          }
        ),
        integr_chk_deposit_filterchannel: JSON.stringify(
          {
            EXPEDIA:
              integration.integr_chk_deposit_filterchannel
                ?.EXPEDIA ?? false,
            BOOKING:
              integration.integr_chk_deposit_filterchannel
                ?.BOOKING ?? false,
            AIRBNB:
              integration.integr_chk_deposit_filterchannel
                ?.AIRBNB ?? false,
            OTHER:
              integration.integr_chk_deposit_filterchannel
                ?.OTHER ?? false,
          }
        ),
        integr_chk_asknumguests_filterchannel: JSON.stringify(
          {
            EXPEDIA:
              integration.integr_chk_asknumguests_filterchannel
                ?.EXPEDIA ?? false,
            BOOKING:
              integration.integr_chk_asknumguests_filterchannel
                ?.BOOKING ?? false,
            AIRBNB:
              integration.integr_chk_asknumguests_filterchannel
                ?.AIRBNB ?? false,
            OTHER:
              integration.integr_chk_asknumguests_filterchannel
                ?.OTHER ?? false,
          }
        ),
        integr_avoid_add_filterchannel: JSON.stringify(
          {
            AIRBNB: integration.integr_avoid_add_filterchannel?.AIRBNB ?? false,
            BOOKING: integration.integr_avoid_add_filterchannel?.BOOKING ?? false,
            EXPEDIA: integration.integr_avoid_add_filterchannel?.EXPEDIA ?? false,
            OTHER: integration.integr_avoid_add_filterchannel?.OTHER ?? false,
          }
        ),
        action: "edit_local",
        local_key: local?.local_key,
        integr_user_id: integrationId,
        ref: refId,
      }),
    {
      onSuccess: (data: any) => {
        toast.success(t("general.created"));
        if (onSave) onSave();
        if (onClose) onClose();
      },
      onError: (error: Error) => {
        toast.error(t(error.message) || t("general.created"));
      },
    }
  );

  const { data: checkInTemplates, isFetching: fetchingCheckInTemplates } =
    useQuery(
      ["checkInTemplates", local, visible],
      () => {
        if (visible) {
          return fetchCustomCheckins();
        } else {
          return null;
        }
      },
      {
        enabled: local && local !== undefined,
      }
    );

  const { data: emailTemplates, isFetching: fetchingEmailTemplates } = useQuery(
    ["emailTemplates", local, visible],
    () => {
      if (visible) {
        return fetchCustomEmails(local ? local.local_key : undefined, true);
      } else {
        return {
          results: [],
        };
      }
    },
    {
      enabled: local && local !== undefined,
    }
  );

  const { data: contracts, isFetching: fetchingContracts } = useQuery(
    ["contracts", local, visible],
    () => {
      if (visible) {
        return fetchContracts(local ? local.local_key : undefined, true);
      } else {
        return {
          results: [],
        };
      }
    },
    {
      enabled: local && local !== undefined,
    }
  );

  const { data: dataModules } = useQuery<any>(
    [local?.local_key, visible],
    () => {
      if (local?.local_key && visible) {
        return fetchAllModules(local?.local_key).then((data: any) => {
          const moduleFinded = data?.modules?.find(
            (module: any) => module?.id === moduleId.contract
          );
          if (moduleFinded && moduleFinded.active) {
            setActiveContract(true);
          } else {
            setActiveContract(false);
          }
          return data;
        });
      }
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: settingsDef } = useQuery<any>(
    ["settingsDef", local?.local_key],
    () => fetchApartmentSettings(local?.local_key as string),
    {
      enabled: (local?.local_key !== null && local?.local_key !== undefined) && !integrationId,
      onSuccess: (data) => {
        const result = data.result;
        setIntegration((prev: any) => {
          return {
            ...prev,
            integr_chk_asknumguests: result.chk_asknumguests,
            integr_chk_contract: result.chk_contract,
            integr_chk_documents: result.chk_documents,
            integr_chk_invoice: result.require_invoice,
            integr_chk_mail: result.chk_mail,
            integr_notify_mail_guest_active: result.notify_mail_guest_active,
            integr_chk_sms: result.chk_sms,
            integr_chk_sms_done: result.chk_sms_done,
            integr_chk_whatsapp: result.chk_whatsapp,
            integr_chk_whatsapp_done: result.chk_whatsapp_done,
            integr_chk_otp: result.chk_otp,
            integr_chk_photo: result.chk_photo,
            integr_chk_photo_all: result.chk_photo_all,
            integr_chk_cc: result.chk_cc,
            integr_chk_citytax: result.chk_citytax,
            integr_chk_paystay: result.chk_paystay,
            integr_chk_deposit: result.chk_deposit,
            integr_deposit_amount: result.integr_deposit_amount ?? 0,
            integr_cust_contract_ptr: result.cust_contract_ptr ?? 0,
            integr_cust_checkin_ptr: result.cust_checkin_ptr ?? 0,
            integr_cust_email_ptr: result.cust_email_ptr ?? 0,
            integr_notify_now: result.notify_now,
            integr_notify_7days: result.notify_7days,
            integr_notify_3days: result.notify_3days,
            integr_notify_1days: result.notify_1days,
            integr_notify_done_1days: result.notify_done_1days,
            integr_chk_docs_auto_confirmation: result.docs_auto_confirmation,
            integr_chk_citytax_chargeguest: result.chk_citytax_chargeguest,
            integr_chk_paystay_chargeguest: result.chk_paystay_chargeguest,
            integr_chk_deposit_chargeguest: result.chk_deposit_chargeguest,
          };
        });
      },
    }
  );

  useEffect(() => {
    if (presetIntegration?.links?.length > 0) {
      var filterchannel_paystay = "";
      var filterchannel_citytax = "";
      var filterchannel_deposit = "";
      var filterchannel_asknumguest = "";
      var filterchannel_avoidAdd = "";
      try {
        filterchannel_paystay = JSON.parse(
          presetIntegration?.links[0]?.integr_chk_paystay_filterchannel
        );
        filterchannel_citytax = JSON.parse(
          presetIntegration?.links[0]?.integr_chk_citytax_filterchannel
        );
        filterchannel_deposit = JSON.parse(
          presetIntegration?.links[0]?.integr_chk_deposit_filterchannel
        );
        filterchannel_asknumguest = JSON.parse(
          presetIntegration?.links[0]?.integr_chk_asknumguests_filterchannel
        );
        filterchannel_avoidAdd = JSON.parse(
          presetIntegration?.links[0]?.integr_avoid_add_filterchannel
        );
      } catch (err) {
        filterchannel_paystay =
          presetIntegration?.links[0]?.integr_chk_paystay_filterchannel;
        filterchannel_citytax =
          presetIntegration?.links[0]?.integr_chk_citytax_filterchannel;
        filterchannel_deposit =
          presetIntegration?.links[0]?.integr_chk_deposit_filterchannel;
        filterchannel_asknumguest =
          presetIntegration?.links[0]?.integr_chk_asknumguests_filterchannel;
        filterchannel_avoidAdd =
          presetIntegration?.links[0]?.integr_avoid_add_filterchannel;
      }

      setIntegration({
        ...presetIntegration?.links[0],
        integr_chk_paystay_filterchannel: filterchannel_paystay,
        integr_chk_citytax_filterchannel: filterchannel_citytax,
        integr_chk_deposit_filterchannel: filterchannel_deposit,
        integr_chk_asknumguests_filterchannel: filterchannel_asknumguest,
        integr_avoid_add_filterchannel: filterchannel_avoidAdd,
      });
      if (data) {
        const l = data?.results?.find(
          (d: any) =>
            d.local_key === presetIntegration?.links[0]?.local?.local_key
        );
        if (l) setLocal(l);
      }
    }
  }, [presetIntegration, data]);

  useEffect(() => {
    checkInTemplates?.results?.push({ objkey: "Vikey Default", id: 0 });
  }, [checkInTemplates]);

  useEffect(() => {
    emailTemplates?.results?.push({ objkey: "Vikey Default", id: 0 });
  }, [emailTemplates]);

  return (
    <Modal visible={visible}>
      <ModalCard
        className={"w-full lg:max-w-4xl"}
        title={
          presetIntegration?.links?.length > 0
            ? t("general.edit")
            : t("integration.new")
        }
      >
        <ModalSection>
          <div className="p-4">
            <p className={"text-gray-600 mb-5"}>
              {t("integration.chooseApartment")}
            </p>

            <div className="flex flex-col space-y-4">
              <FormField label={t("resv.apartment")}>
                <SelectInput<BaseSelectItem, false>
                  menuShouldScrollIntoView={false}
                  menuPortalTarget={document.body}
                  styles={{
                    container: (base) => ({ ...base, zIndex: 999 }),
                    menuPortal: (base) => ({ ...base, zIndex: 999 }),
                  }}
                  isLoading={isLoading}
                  value={getOptionListFromArray(
                    data?.results,
                    ["public_name", "name"],
                    "local_key"
                  ).find((item) => item.value === local?.local_key)}
                  options={getOptionListFromArray(
                    data?.results,
                    ["public_name", "name"],
                    "local_key"
                  )}
                  onChange={(value) => {
                    if (value && data)
                      setLocal(
                        data?.results?.find(
                          (ap: any) => ap.local_key === value.value
                        )
                      );
                  }}
                />
              </FormField>

              <div className="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0">
                <div className={"flex-1"}>
                  <FormField label={t("resv.checkInTime")}>
                    <SelectInput<BaseSelectItem, false>
                      menuShouldScrollIntoView={false}
                      menuPortalTarget={document.body}
                      styles={{
                        container: (base) => ({ ...base, zIndex: 999 }),
                        menuPortal: (base) => ({ ...base, zIndex: 999 }),
                      }}
                      value={{
                        label: integration.integr_time_checkin,
                        value: integration.integr_time_checkin,
                      }}
                      options={times}
                      onChange={(item) => {
                        setIntegration({
                          ...integration,
                          integr_time_checkin: item ? item.value : "00:00",
                        });
                      }}
                    />
                  </FormField>
                </div>

                <div className={"flex-1"}>
                  <FormField label={t("resv.checkOutTime")}>
                    <SelectInput<BaseSelectItem, false>
                      menuShouldScrollIntoView={false}
                      menuPortalTarget={document.body}
                      styles={{
                        container: (base) => ({ ...base, zIndex: 999 }),
                        menuPortal: (base) => ({ ...base, zIndex: 999 }),
                      }}
                      value={{
                        label: integration.integr_time_checkout,
                        value: integration.integr_time_checkout,
                      }}
                      onChange={(item) => {
                        setIntegration({
                          ...integration,
                          integr_time_checkout: item ? item.value : "00:00",
                        });
                      }}
                      options={times}
                    />
                  </FormField>
                </div>
              </div>

              <FormField label={t("resv.onlineCheckIn")}>
                <SelectInput<BaseSelectItem, false>
                  styles={{
                    container: (base) => ({ ...base, zIndex: 999 }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuShouldScrollIntoView={false}
                  menuPortalTarget={document.body}
                  isLoading={fetchingCheckInTemplates}
                  value={getOptionList(
                    checkInTemplates?.results,
                    "objkey",
                    "id"
                  ).find(
                    (item) => item.value === integration.integr_cust_checkin_ptr
                  )}
                  onChange={(item) => {
                    if (item) {
                      setIntegration({
                        ...integration,
                        integr_cust_checkin_ptr: item.value ?? 0,
                      });
                    }
                  }}
                  options={getOptionList(
                    checkInTemplates?.results,
                    "objkey",
                    "id"
                  )}
                />
              </FormField>

              <FormField label={t("reservations.color")}>
                <div className="flex space-x-2">
                  {[
                    { label: "White", color: "bg-gray-200", value: 1 },
                    { label: "Green", color: "bg-green-600", value: 2 },
                    { label: "Blue", color: "bg-blue-600", value: 3 },
                    { label: "Yellow", color: "bg-yellow-300", value: 4 },
                    { label: "Orange", color: "bg-yellow-600", value: 5 },
                    { label: "Red", color: "bg-red-600", value: 6 },
                  ].map((color) => (
                    <button
                      key={color.value}
                      onClick={() =>
                        setIntegration({
                          ...integration,
                          integr_mark: color.value,
                        })
                      }
                      className={`transition transition-all ${
                        color.color
                      } w-5 h-5 rounded-2xl ${
                        integration.integr_mark !== color.value && `opacity-40`
                      } ${
                        integration.integr_mark === color.value &&
                        `ring-4 ring-${color.color}-200`
                      }`}
                    ></button>
                  ))}
                </div>
              </FormField>

              <div className="font-medium text-lg pt-4">Features</div>

              <div>
                {dataModules?.modules?.map((module: any) => {
                  if (module.id === moduleId.sms) {
                    return (
                      <Checkbox
                        label={`${t("resv.sendRemindSMS")} ${
                          module.date_closed
                            ? `(${t("resv.activeUntil").toLowerCase()} ${dayjs(
                                module.date_closed
                              ).format("DD-MM-YYYY")})`
                            : ""
                        }`}
                        disabled={!module.active}
                        onChange={() => {
                          setIntegration({
                            ...integration,
                            integr_chk_sms: !integration.integr_chk_sms,
                          });
                        }}
                        checked={
                          integration.integr_chk_sms === 0
                            ? false
                            : integration.integr_chk_sms
                        }
                      />
                    );
                  }
                  return null;
                })}

                {dataModules?.modules?.map((module: any) => {
                  if (module.id === moduleId.whatsapp) {
                    return (
                      <Checkbox
                        label={`${t("resv.sendRemindWHATSAPP")} ${
                          module.date_closed
                            ? `(${t("resv.activeUntil").toLowerCase()} ${dayjs(
                                module.date_closed
                              ).format("DD-MM-YYYY")})`
                            : ""
                        }`}
                        disabled={!module.active}
                        onChange={() => {
                          setIntegration({
                            ...integration,
                            integr_chk_whatsapp:
                              !integration.integr_chk_whatsapp,
                          });
                        }}
                        checked={
                          integration.integr_chk_whatsapp === 0
                            ? false
                            : integration.integr_chk_whatsapp
                        }
                      />
                    );
                  }
                  return null;
                })}
                {dataModules?.modules?.map((module: any) => {
                  if (module.id === moduleId.sms_done) {
                    return (
                      <Checkbox
                        label={`${t("resv.sendRemindSMSdone")} ${
                          module.date_closed
                            ? `(${t("resv.activeUntil").toLowerCase()} ${dayjs(
                                module.date_closed
                              ).format("DD-MM-YYYY")})`
                            : ""
                        }`}
                        disabled={!module.active}
                        onChange={() => {
                          setIntegration({
                            ...integration,
                            integr_chk_sms_done:
                              !integration.integr_chk_sms_done,
                          });
                        }}
                        checked={
                          integration.integr_chk_sms_done === 0
                            ? false
                            : integration.integr_chk_sms_done
                        }
                      />
                    );
                  }
                  return null;
                })}
                {dataModules?.modules?.map((module: any) => {
                  if (module.id === moduleId.whatsapp_done) {
                    return (
                      <Checkbox
                        label={`${t("resv.sendRemindWHATSAPPdone")} ${
                          module.date_closed
                            ? `(${t("resv.activeUntil").toLowerCase()} ${dayjs(
                                module.date_closed
                              ).format("DD-MM-YYYY")})`
                            : ""
                        }`}
                        disabled={!module.active}
                        onChange={() => {
                          setIntegration({
                            ...integration,
                            integr_chk_whatsapp_done:
                              !integration.integr_chk_whatsapp_done,
                          });
                        }}
                        checked={
                          integration.integr_chk_whatsapp_done === 0
                            ? false
                            : integration.integr_chk_whatsapp_done
                        }
                      />
                    );
                  }
                  return null;
                })}

                <Checkbox
                  label={t("resv.enableEmail")}
                  onChange={() => {
                    setIntegration({
                      ...integration,
                      integr_chk_mail: !integration.integr_chk_mail,
                    });
                  }}
                  checked={
                    integration.integr_chk_mail === 0
                      ? false
                      : integration.integr_chk_mail
                  }
                />

                {integration.integr_chk_mail && (
                  <div className="bg-gray-100 border rounded-2xl p-4 mb-2">
                    <SelectInput<BaseSelectItem, false>
                      styles={{
                        container: (base) => ({ ...base, zIndex: 999 }),
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuShouldScrollIntoView={false}
                      menuPortalTarget={document.body}
                      isLoading={fetchingEmailTemplates}
                      value={getOptionList(
                        emailTemplates?.results,
                        "objkey",
                        "id"
                      ).find(
                        (item) =>
                          item.value === integration.integr_cust_email_ptr
                      )}
                      onChange={(item) => {
                        if (item) {
                          setIntegration({
                            ...integration,
                            integr_cust_email_ptr: item.value ?? 0,
                          });
                        }
                      }}
                      options={getOptionList(
                        emailTemplates?.results,
                        "objkey",
                        "id"
                      )}
                    />

                    <div className="mt-4">
                      <Checkbox
                        label={t("resv.enableCCEmail")}
                        checked={
                          integration.integr_chk_cc === 0
                            ? false
                            : integration.integr_chk_cc
                        }
                        onChange={() => {
                          setIntegration({
                            ...integration,
                            integr_chk_cc: !integration.integr_chk_cc,
                          });
                        }}
                      />

                      <Checkbox
                        label={t("resv.createNotification")}
                        checked={
                          integration.integr_notify_now === 0
                            ? false
                            : integration.integr_notify_now
                        }
                        onChange={() => {
                          setIntegration({
                            ...integration,
                            integr_notify_now: !integration.integr_notify_now,
                          });
                        }}
                      />

                      <Checkbox
                        label={t("resv.sevenDayNotification")}
                        checked={
                          integration.integr_notify_7days === 0
                            ? false
                            : integration.integr_notify_7days
                        }
                        onChange={() => {
                          setIntegration({
                            ...integration,
                            integr_notify_7days:
                              !integration.integr_notify_7days,
                          });
                        }}
                      />

                      <Checkbox
                        label={t("resv.threeDayNotification")}
                        checked={
                          integration.integr_notify_3days === 0
                            ? false
                            : integration.integr_notify_3days
                        }
                        onChange={() => {
                          setIntegration({
                            ...integration,
                            integr_notify_3days:
                              !integration.integr_notify_3days,
                          });
                        }}
                      />

                      <Checkbox
                        label={t("resv.oneDayNotification")}
                        checked={
                          integration.integr_notify_1days === 0
                            ? false
                            : integration.integr_notify_1days
                        }
                        onChange={() => {
                          setIntegration({
                            ...integration,
                            integr_notify_1days:
                              !integration.integr_notify_1days,
                          });
                        }}
                      />

                      <Checkbox
                        label={t("resv.completeNotification")}
                        checked={
                          integration.integr_notify_done_1days === 0
                            ? false
                            : integration.integr_notify_done_1days
                        }
                        onChange={() => {
                          setIntegration({
                            ...integration,
                            integr_notify_done_1days:
                              !integration.integr_notify_done_1days,
                          });
                        }}
                      />
                    </div>
                  </div>
                )}

                <Checkbox
                  label={t("resv.enableGuestActive")}
                  onChange={() => {
                    setIntegration({
                      ...integration,
                      integr_notify_mail_guest_active:
                        !integration.integr_notify_mail_guest_active,
                    });
                  }}
                  checked={
                    integration.integr_notify_mail_guest_active === 0
                      ? false
                      : integration.integr_notify_mail_guest_active
                  }
                />

                <PaystaySelector
                  local={local}
                  integration={integration}
                  setIntegration={setIntegration}
                />

                {dataModules?.modules?.map((module: any) => {
                  if (module.id === moduleId.deposit) {
                    return (
                      <DepositSelector
                        local={local}
                        integration={integration}
                        setIntegration={setIntegration}
                      />
                    );
                  }
                  return null;
                })}

                <CitytaxSelector
                  local={local}
                  integration={integration}
                  setIntegration={setIntegration}
                />

                <DocumentSelector
                  local={local}
                  integration={integration}
                  setIntegration={setIntegration}
                />

                <Checkbox
                  label={t("resv.mandatoryContractSign")}
                  disabled={
                    !contracts ||
                    (contracts?.results &&
                      contracts.results.length <= 0 &&
                      !integration.integr_chk_contract) ||
                    (!activeContract && !integration.integr_chk_contract)
                  }
                  onChange={() => {
                    setIntegration({
                      ...integration,
                      integr_chk_contract: !integration.integr_chk_contract,
                    });
                  }}
                  checked={
                    integration.integr_chk_contract === 0
                      ? false
                      : integration.integr_chk_contract
                  }
                />

                {integration.integr_chk_contract && (
                  <div className="bg-gray-100 border rounded-2xl p-4 pb-2 mb-2">
                    <FormField label={t("resv.contract")}>
                      <SelectInput<BaseSelectItem, false>
                        menuShouldScrollIntoView={false}
                        menuPortalTarget={document.body}
                        styles={{
                          container: (base) => ({ ...base, zIndex: 999 }),
                          menuPortal: (base) => ({ ...base, zIndex: 999 }),
                        }}
                        isLoading={fetchingContracts}
                        value={getOptionList(
                          contracts?.results,
                          "objkey",
                          "id"
                        ).find(
                          (item) =>
                            item.value === integration.integr_cust_contract_ptr
                        )}
                        options={getOptionList(
                          contracts?.results,
                          "objkey",
                          "id"
                        )}
                        onChange={(item) => {
                          if (item) {
                            setIntegration({
                              ...integration,
                              integr_cust_contract_ptr: item.value ?? 0,
                            });
                          }
                        }}
                      />
                    </FormField>

                    <Checkbox
                      className={"mt-4"}
                      label={t("resv.requestOTP")}
                      onChange={() => {
                        setIntegration({
                          ...integration,
                          integr_chk_otp: !integration.integr_chk_otp,
                        });
                      }}
                      checked={
                        integration.integr_chk_otp === 0
                          ? false
                          : integration.integr_chk_otp
                      }
                    />
                  </div>
                )}

                <Checkbox
                  label={t("resv.requestInvoice")}
                  onChange={() => {
                    setIntegration({
                      ...integration,
                      integr_chk_invoice: !integration.integr_chk_invoice,
                    });
                  }}
                  checked={
                    integration.integr_chk_invoice === 0
                      ? false
                      : integration.integr_chk_invoice
                  }
                />

                {metaIntegration?.push_back_guest_open && (
                  <Checkbox
                    label={t("resv.sendPmsOpen")}
                    onChange={() => {
                      setIntegration({
                        ...integration,
                        push_back_guest_open: !integration.push_back_guest_open,
                      });
                    }}
                    checked={
                      integration.push_back_guest_open === 0
                        ? false
                        : integration.push_back_guest_open
                    }
                  />
                )}

                {(integrationType === "EMMAVILLAS" ||
                  metaIntegration?.push_back_guests) && (
                  <Checkbox
                    label={t("integration.sendPms")}
                    onChange={() => {
                      setIntegration({
                        ...integration,
                        push_back_guests: !integration.push_back_guests,
                      });
                    }}
                    checked={
                      integration.push_back_guests === 0
                        ? false
                        : integration.push_back_guests
                    }
                  />
                )}

                {(integrationType === "EMMAVILLAS" ||
                  metaIntegration?.push_back_contract) && (
                  <Checkbox
                    label={t("integration.sendPmsContract")}
                    onChange={() => {
                      setIntegration({
                        ...integration,
                        push_back_contract: !integration.push_back_contract,
                      });
                    }}
                    checked={
                      integration.push_back_contract === 0
                        ? false
                        : integration.push_back_contract
                    }
                  />
                )}
                {(integrationType === "VRBOOKINGS" ||
                  metaIntegration?.push_back_invdata) && (
                  <Checkbox
                    label={t("integration.resendInvData")}
                    onChange={() => {
                      setIntegration({
                        ...integration,
                        push_back_invdata: !integration.push_back_invdata,
                      });
                    }}
                    checked={
                      integration.push_back_invdata === 0
                        ? false
                        : integration.push_back_invdata
                    }
                  />
                )}
                <div className="bg-gray-50 border rounded-2xl p-4 pb-2 mb-2">
                  <FormField label={t("integration.avoidAdd")}>
                    <Checkbox
                      label={"Airbnb"}
                      onChange={() => {
                        setIntegration({
                          ...integration,
                          integr_avoid_add_filterchannel: {
                            ...integration.integr_avoid_add_filterchannel,
                            AIRBNB:
                              !integration.integr_avoid_add_filterchannel
                                ?.AIRBNB,
                          },
                        });
                      }}
                      checked={
                        integration.integr_avoid_add_filterchannel?.AIRBNB === 0
                          ? false
                          : integration.integr_avoid_add_filterchannel?.AIRBNB
                      }
                    />
                  </FormField>
                </div>
              </div>
            </div>
          </div>
        </ModalSection>

        <ModalActions
          isLoading={mutation.isLoading}
          saveLabel={t("general.save")}
          onClose={() => {
            if (onClose) onClose();
            if (integration.action === "new_local") {
              setLocal(undefined);
              setIntegration({
                action: "new_local",
                integr_chk_asknumguests: false,
                integr_chk_contract: false,
                integr_chk_documents: false,
                integr_chk_invoice: false,
                integr_chk_mail: false,
                integr_notify_mail_guest_active: false,
                integr_chk_sms: false,
                integr_chk_payments: false,
                integr_chk_otp: false,
                integr_chk_photo: false,
                integr_chk_photo_all: false,
                integr_chk_cc: false,
                integr_chk_citytax: false,
                integr_chk_paystay: false,
                integr_chk_deposit: false,
                integr_deposit_amount: 0,
                integr_chk_documents_pa_extracityforforeign: 1,
                integr_time_checkin: "15:00",
                integr_time_checkout: "10:00",
                integr_cust_contract_ptr: 0,
                integr_cust_checkin_ptr: 0,
                integr_cust_email_ptr: 0,
                integr_notify_now: true,
                integr_notify_7days: true,
                integr_notify_3days: true,
                integr_notify_1days: true,
                integr_notify_done_1days: true,
                integr_mark: 2,
                integr_chk_docs_auto_confirmation: false,
                integr_chk_citytax_chargeguest: false,
                integr_chk_paystay_chargeguest: false,
                integr_chk_deposit_chargeguest: false,
                integr_chk_citytax_filterchannel: {
                  AIRBNB: false,
                  BOOKING: false,
                  EXPEDIA: false,
                  OTHER: false,
                },
                integr_chk_paystay_filterchannel: {
                  AIRBNB: false,
                  BOOKING: false,
                  EXPEDIA: false,
                  OTHER: false,
                },
                integr_chk_deposit_filterchannel: {
                  AIRBNB: false,
                  BOOKING: false,
                  EXPEDIA: false,
                  OTHER: false,
                },
                integr_chk_asknumguests_filterchannel: {
                  AIRBNB: false,
                  BOOKING: false,
                  EXPEDIA: false,
                  OTHER: false,
                },
                integr_avoid_add_filterchannel: {
                  AIRBNB: false,
                  BOOKING: false,
                  EXPEDIA: false,
                  OTHER: false,
                },
              });
            }
          }}
          onSave={() => {
            if (presetIntegration?.links?.length > 0) {
              updateMutation.mutate();
            } else {
              mutation.mutate();
            }
            if (integration.action === "new_local") {
              setLocal(undefined);
              setIntegration({
                action: "new_local",
                integr_chk_asknumguests: false,
                integr_chk_contract: false,
                integr_chk_documents: false,
                integr_chk_invoice: false,
                integr_chk_mail: false,
                integr_notify_mail_guest_active: false,
                integr_chk_sms: false,
                integr_chk_payments: false,
                integr_chk_otp: false,
                integr_chk_photo: false,
                integr_chk_photo_all: false,
                integr_chk_cc: false,
                integr_chk_citytax: false,
                integr_chk_paystay: false,
                integr_chk_deposit: false,
                integr_deposit_amount: 0,
                integr_chk_documents_pa_extracityforforeign: true,
                integr_time_checkin: "15:00",
                integr_time_checkout: "10:00",
                integr_cust_contract_ptr: 0,
                integr_cust_checkin_ptr: 0,
                integr_cust_email_ptr: 0,
                integr_notify_now: true,
                integr_notify_7days: true,
                integr_notify_3days: true,
                integr_notify_1days: true,
                integr_notify_done_1days: true,
                integr_mark: 2,
                integr_chk_docs_auto_confirmation: false,
                integr_chk_citytax_chargeguest: false,
                integr_chk_paystay_chargeguest: false,
                integr_chk_deposit_chargeguest: false,
                integr_chk_citytax_filterchannel: {
                  AIRBNB: false,
                  BOOKING: false,
                  EXPEDIA: false,
                  OTHER: false,
                },
                integr_chk_paystay_filterchannel: {
                  AIRBNB: false,
                  BOOKING: false,
                  EXPEDIA: false,
                  OTHER: false,
                },
                integr_chk_deposit_filterchannel: {
                  AIRBNB: false,
                  BOOKING: false,
                  EXPEDIA: false,
                  OTHER: false,
                },
                integr_chk_asknumguests_filterchannel: {
                  AIRBNB: false,
                  BOOKING: false,
                  EXPEDIA: false,
                  OTHER: false,
                },
                integr_avoid_add_filterchannel: {
                  AIRBNB: false,
                  BOOKING: false,
                  EXPEDIA: false,
                  OTHER: false,
                },
              });
            }
          }}
        />
      </ModalCard>
    </Modal>
  );
}
