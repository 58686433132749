import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { sendCommand, updateApartment } from '../../shared/queries';
import { times } from '../../shared/utils/formatting';
import { BaseSelectItem, Local } from '../../types';
import Button from '../Button';
import FormField from '../FormField';
import Icon from '../Icon';
import Modal from '../Modal';
import ModalActions from '../ModalAction';
import ModalCard from '../ModalCard';
import ModalSection from '../ModalSection';
import SelectInput from '../SelectInput';

type ApartmentUpdateConfigurationProps = {
  local: Local;
  visible: boolean;
  onClose?: () => void;
  onSave?: () => void;
};

export default function ApartmentUpdateConfiguration({
  local,
  visible,
  onClose,
  onSave,
}: ApartmentUpdateConfigurationProps) {
  const { t } = useTranslation();
  const [conf, setConf] = useState({
    volume_rx: local.volume_rx,
    volume_tx: local.volume_tx,
    voip_buddy_number: local.voip_buddy_number,
    hour_sched_forced: local.hour_sched_forced,
  });
  const [hourSched, setHourSched] = useState(
    local.hour_sched?.split(',').map((e) => e.split(' ')) ?? []
  );
  const statuses = [
    { label: t('apartment.NORMAL'), value: 'NORMAL' },
    { label: t('apartment.FORCED_ON'), value: 'FORCED_ON' },
    { label: t('apartment.FORCED_OFF'), value: 'FORCED_OFF' },
  ];

  const buddyConfig = useMutation(
    (hour_sched: string) =>
      sendCommand({
        local_key: local.local_key,
        top: 'SCHEDULE_CONFIG',
        hour_sched: hour_sched,
        hour_sched_forced: conf.hour_sched_forced,
      }),
    {
      onSuccess: () => {
        toast.success(t('general.updated'));
        if (onSave) onSave();
      },
      onError: () => {
        toast.error(t('general.requestError'));
      },
    }
  );

  return (
    <Modal visible={visible}>
      <ModalCard
        className="w-1/2 justify-between"
        title={t('updateAddress.updateAddress')}
      >
        <ModalSection>
          <div className={'p-4 flex-wrap flex '}>
            <FormField
              className={'px-2 mb-4 w-full  items-center'}
              label={t('apartment.status')}
            >
              <SelectInput<BaseSelectItem, false>
                value={statuses.find(
                  (status) => status.value === conf.hour_sched_forced
                )}
                options={statuses}
                onChange={(item) => {
                  if (item) {
                    setConf({ ...conf, hour_sched_forced: item.value });
                  }
                }}
              />
            </FormField>
            {conf.hour_sched_forced === 'NORMAL' && (
              <>
                {hourSched.map((interval, index) => (
                  <div className="w-full m-2 space-x-4 inline-flex" key={index}>
                    {interval.map((slot: any, _index) => (
                      <div
                        className="flex inline-flex space-x-2"
                        key={slot + _index}
                      >
                        <SelectInput<BaseSelectItem, false>
                          value={times.find(
                            (hour) => hour.value === slot.substr(0, 5)
                          )}
                          options={times}
                          onChange={(item) => {
                            setHourSched(
                              hourSched.map((e, i) => {
                                if (i === index)
                                  return _index === 0
                                    ? [item?.label + ':00', e[1]]
                                    : [e[0], item?.label + ':00'];
                                return e;
                              })
                            );
                          }}
                          className={'w-48'}
                        />
                      </div>
                    ))}
                    <Icon
                      name={'trash'}
                      size={'24px'}
                      className={'self-center text-red-400 cursor-pointer'}
                      onClick={() =>
                        setHourSched(hourSched.filter((e, i) => i !== index))
                      }
                    />
                  </div>
                ))}
                <Button
                  onClick={() =>
                    setHourSched([...hourSched, ['09:00:00', '18:00:00']])
                  }
                  color={'primary'}
                  label={t('general.add')}
                  className={'m-2'}
                />
              </>
            )}
          </div>
        </ModalSection>
        <ModalActions
          isLoading={buddyConfig.isLoading}
          saveLabel={t('general.update')}
          onClose={() => {
            setHourSched(
              local.hour_sched?.split(',').map((e) => e.split(' ')) ?? []
            );
            if (onClose) onClose();
          }}
          onSave={() => {
            var _hour_sched = '';
            hourSched.forEach((element, index) => {
              _hour_sched += element[0] + ' ' + element[1] + (index<hourSched.length-1?',':'');
            });
            buddyConfig.mutate(_hour_sched);
          }}
        ></ModalActions>
      </ModalCard>
    </Modal>
  );
}
