import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { exportStatsCSV, fetchApartmentStats } from "../../shared/queries";
import { Local } from "../../types";
import TimelineChart from "../common/TimelineChart";
import DetailRow from "../DetailRow";
import FormSectionTitle from "../FormSectionTitle";
import ModalCard from "../ModalCard";
import Modal from "../Modal";
import ModalSection from "../ModalSection";
import ModalActions from "../ModalAction";
import { useState } from "react";
import FormField from "../FormField";
import DatePicker from "../DatePicker";
import { toast } from "react-toastify";

const parser = new DOMParser();

type ApartmentServicesProps = {
  data: Local;
};

export default function ApartmentStats({ data }: ApartmentServicesProps) {
  const { t } = useTranslation();

  const [modal, setModal] = useState(false);
  const [rangeDate, setRangeDate] = useState({
    local_key: data.local_key,
    from: "",
    to: "",
  });
  const [loadingRange, setLoadingRange] = useState(false);

  const { data: stats, isFetching } = useQuery(
    ["apartmentStatsDetail", data.local_key],
    () => fetchApartmentStats(data.local_key, "M", "M")
  );

  const rangeMutation = useMutation((data: any) => exportStatsCSV(data), {
    onSuccess: () => {
      setRangeDate({
        local_key: data.local_key,
        from: "",
        to: "",
      });
      toast.success(t("general.requestCompleted"));
      setLoadingRange(false);
      setModal(false);
    },
    onError: () => {
      setLoadingRange(false);
      toast.error(t("general.requestError"));
    },
  });
  return (
    <div className={"flex flex-col space-y-4"}>
      <FormSectionTitle
        borderless={true}
        title={t("apartmentStats.prevMonthData")}
      />

      <DetailRow
        loading={isFetching}
        label={t("apartmentStats.openings")}
        children={stats && stats.data ? stats.data.tot_commands : 0}
      />

      <DetailRow
        loading={isFetching}
        label={t("apartmentStats.calls")}
        children={stats && stats.data ? stats.data.tot_calls : 0}
      />

      <FormSectionTitle
        title={t("apartmentStats.availabilityAndEvents")}
        edit
        editLabel={t("apartmentStats.exportAccessTitle")}
        onEdit={() => {
          setModal(true);
        }}
      />

      <DetailRow
        loading={isFetching}
        label={t("apartmentStats.availability")}
        children={stats && stats.data ? stats.data.active_perc : 0}
      />

      <TimelineChart
        className={"pb-8"}
        data={
          stats && stats.data
            ? stats.data.actions.map((action, index) => {
                return {
                  id: index,
                  type: action.type,
                  content: action.content
                    ? parser.parseFromString(action.content, "text/html")
                        .firstChild
                    : undefined,
                  title: action.title,
                  start: action.start,
                  end: action.end,
                };
              })
            : []
        }
      />
      {/* TODO exportStatsCSV */}
      <Modal visible={modal}>
        <ModalCard
          className={"w-full max-w-xl"}
          title={t("apartmentStats.exportAccessTitle")}
        >
          <ModalSection className={"w-full max-w-xl p-5 h-96"}>
            <p className="mb-2 text-sm">{t("apartmentStats.exportAccessSubtitle")}</p>
            <div className={"md:flex md:space-x-6"}>
              <div className="md:w-1/2 w-full md:space-y-4 space-y-0 md:mb-0 mb-2">
                <FormField className="text-xs" label={`${t("reservationNotes.from")} (DD-MM-YYYY)`}>
                  <DatePicker
                    value={rangeDate.from}
                    // min={dayjs().toDate()}
                    inputFormat={"YYYY-MM-DD HH:mm"}
                    outputFormat={"YYYY-MM-DD HH:mm:ss"}
                    displayFormat={"DD-MM-YYYY"}
                    onChange={(value) =>
                      setRangeDate((prev) => {
                        return {
                          ...prev,
                          from: value,
                        };
                      })
                    }
                  ></DatePicker>
                </FormField>
              </div>
              <div className="md:w-1/2 w-full md:space-y-4 space-y-1">
                <FormField className="text-xs" label={`${t("reservationNotes.to")} (DD-MM-YYYY)`}>
                  <DatePicker
                    value={rangeDate.to}
                    // min={dayjs().toDate()}
                    inputFormat={"YYYY-MM-DD HH:mm"}
                    outputFormat={"YYYY-MM-DD HH:mm:ss"}
                    displayFormat={"DD-MM-YYYY"}
                    onChange={(value) =>
                      setRangeDate((prev) => {
                        return {
                          ...prev,
                          to: value,
                        };
                      })
                    }
                  ></DatePicker>
                </FormField>
              </div>
            </div>
          </ModalSection>
          <ModalActions
            saveLabel={t("general.export")}
            isLoading={loadingRange}
            onClose={() => {
              setModal(false);
              setRangeDate({
                local_key: data.local_key,
                from: "",
                to: "",
              });
            }}
            saveDisabled={!rangeDate.to || !rangeDate.from || loadingRange}
            onSave={() => {
              setLoadingRange(true);
              rangeMutation.mutate(rangeDate);
            }}
          />
        </ModalCard>
      </Modal>
    </div>
  );
}
