import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { components } from 'react-select';
import Button from '../components/Button';
import CircleSpinner from '../components/CircleSpinner';
import DateRangePicker from '../components/DateRangePicker';
import DeviceCharts from '../components/domotic/DeviceCharts';
import DeviceRow from '../components/domotic/DeviceRow';
import SocketDebugger from '../components/domotic/SocketDebugger';
import Icon from '../components/Icon';
import PageWrapper from '../components/PageWrapper';
import SelectInput from '../components/SelectInput';
import { clearEvents } from '../features/socket/socketSlice';
// import useBreadcrumbs from '../hooks/useBreadcrumbs';
import { fetchLocalsWithDevices, fetchDomoticDevices } from '../shared/queries';
import socketManger from '../shared/utils/socket';
import {
  BaseSelectItem,
  DomoticDevice,
  ListApiResponseV3,
  LocalTable,
} from '../types';

export default function Domotics() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showAllDevices, setShowAllDevices] = useState(false);
  const { t } = useTranslation();

  const [categories, setCategories] = useState([
    { name: 'Humidty', value: 0 },
    { name: 'Pressure', value: 0 },
    { name: 'Contact', value: 0 },
    { name: 'Temperature', value: 0 },
  ]);

  useEffect(() => {
    socketManger.init();
    return () => socketManger.closeConnection();
  }, []);

  const [isRealTime, setIsRealTime] = useState(true);

  const [from, setFrom] = useState<Date>(dayjs().subtract(3, 'hours').toDate());
  const [to, setTo] = useState<Date>(dayjs().toDate());

  const [range, setRange] = useState<{
    from: Date;
    to: Date;
  }>({
    from,
    to,
  });

  const [local, setLocal] = useState<BaseSelectItem>();
  const [socketDevices, setSocketDevices] = useState<string[]>([]);

  const { data: devices, isLoading: isLoadingDevices } = useQuery<
    ListApiResponseV3<DomoticDevice>,
    Error
  >(
    ['devices', local],
    () => fetchDomoticDevices(local ? local.value : null, null, false, true),
    {
      onSuccess: () => {
        setShowAllDevices(false);
        setSocketDevices([]);
      },
      initialData: {
        results: [],
      },
    }
  );

  useEffect(() => {
    if (devices && devices.results && Array.isArray(devices.results)) {
      const types = [] as any;
      devices.results
        .filter((d) => d.device_type_info)
        .forEach((device) => {
          const existing = types.findIndex(
            (t: any) => t.name === device.device_type_info.type
          );
          if (existing > -1) {
            types[existing].value = types[existing].value + 1;
          } else {
            types.push({
              name: device.device_type_info.type,
              value: 1,
            });
          }
        });
      setCategories(types);
    }
  }, [devices]);

  const { data: locals, isLoading: isLoadingLocals } = useQuery<
    ListApiResponseV3<LocalTable>,
    Error
  >('localDevices', fetchLocalsWithDevices);

  useEffect(() => {
    /**
     * Set the first local as default
     */
    if (locals && locals.results && locals.results.length > 0) {
      setLocal({
        label: locals.results[0].name,
        value: locals.results[0].local_key,
      });
    }
  }, [locals]);

  useEffect(() => {
    // When we switch apartments the socket log should be cleared
    dispatch(clearEvents());
  }, [local]);

  const PrefixedControl = ({
    label,
    children,
    ...props
  }: {
    label: string;
    children: any;
  }) => {
    return (
      <components.Control {...(props as any)}>
        <span className={'font-semibold pl-3 text-gray-800'}>{label}</span>
        {children}
      </components.Control>
    );
  };

  // useBreadcrumbs([{ label: t('navigation.domotics'), route: '/domotics' }]);

  return (
    <PageWrapper>
      <div className="flex-wrap lg:flex-nowrap pb-6 mb-4 lg:mb-10 flex space-x-0 lg:space-x-4 justify-between items-center">
        <div className="flex space-x-6 items-center">
          <div>
            <div className="bg-cyan-100 p-2 rounded-full text-cyan-700">
              <Icon name={'chip'} size={'25px'} />
            </div>
          </div>
          <div>
            {/* <div className="text-2xl font-medium">
              {t('navigation.domotics')}
            </div> */}
            <div className="text-gray-500 text-sm">
              {t('domotics.realtimeMonitoring')}
            </div>
          </div>
        </div>

        <div>
          <div className="mt-6 items-center lg:mt-0 flex flex-wrap">
            <div className="m-1 w-full lg:w-72">
              <SelectInput
                className={'w-full lg:w-72'}
                components={{
                  Control: ({ children, ...props }: any) => (
                    <PrefixedControl label={t('reservations.apartment')} {...props}>
                      {children}
                    </PrefixedControl>
                  ),
                }}
                isLoading={isLoadingLocals}
                value={local}
                options={
                  locals && locals.results
                    ? locals.results.map((l) => {
                        return {
                          label: l.name,
                          value: l.local_key,
                        };
                      })
                    : []
                }
                onChange={(item: any) => {
                  if (item) setLocal(item);
                }}
              />
            </div>
            <div className="m-1">
              <Button
                onClick={() => history.push('/domotics/rules')}
                label={t('rules.rules')}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={'flex flex-wrap xl:justify-end'}>
        <div className="w-full xl:w-auto inline-flex flex-col lg:flex-row space-y-2 lg:space-y-0">
          <button
            onClick={() => {
              if (!isRealTime) {
                setIsRealTime(true);
                setFrom(dayjs().subtract(3, 'hours').toDate());
                setTo(dayjs().toDate());
                setRange({
                  from: dayjs().subtract(3, 'hours').toDate(),
                  to: dayjs().toDate(),
                });
              } else {
                setIsRealTime(false);
                setFrom(dayjs().subtract(24, 'hours').toDate());
                setTo(dayjs().toDate());
                setRange({
                  from: dayjs().subtract(24, 'hours').toDate(),
                  to: dayjs().toDate(),
                });
              }
            }}
            className="justify-between font-medium text-gray-600 bg-white rounded-2xl appearance-none px-1 pl-3 items-center mx-1 py-1 focus:outline-none h-9 flex items-center cursor-pointer hover:bg-gray-50 active:bg-gray-100 space-x-2 text-sm"
            style={{
              boxShadow:
                '0px 2px 3px rgba(0,0,0,0.1), 0px 0px 0px 1px rgba(0,0,0,0.1)',
            }}
          >
            <div className="flex">
              {isRealTime
                ? t('domotics.realtimeActive')
                : t('domotics.realtimePaused')}
            </div>
            <div
              className={
                'text-gray-600 bg-cyan-100 text-cyan-800 rounded-2xl p-1'
              }
            >
              <Icon name={isRealTime ? 'pause' : 'play'} size={'20px'} />
            </div>
          </button>

          <div className={'mx-1 flex'}>
            <DateRangePicker
              onChange={(value: any) => {
                if (value.from && value.to) {
                  setFrom(dayjs(value.from).hour(0).minute(0).toDate());
                  setTo(dayjs(value.to).hour(23).minute(59).toDate());
                }
              }}
              inputFormat={'DD-MM-YYYY'}
              displayFormat={'DD-MM-YYYY'}
              outputFormat={'DD-MM-YYYY'}
            />
          </div>

          <div className={'mx-1 flex'}>
            <Button
              icon={'refresh'}
              onClick={() => {
                setIsRealTime(false);
                setRange({
                  from,
                  to,
                });
              }}
              size={'small'}
              iconSize={'20px'}
              label={t('general.update')}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 lg:space-x-2 mt-2">
        {false && <SocketDebugger />}
        <div className="grid grid-cols-1 lg:col-span-1">
          {
            devices &&
            devices.results &&
            Array.isArray(devices.results) &&
            devices.results
              .slice(0, showAllDevices ? 1000 : 8)
              .map((device, index: number) => (
                <div className="block p-6 rounded-2xl text-center bg-white dash-card mr-5 mb-5 w-full" key={device.id}>
                  <h5
                    className={
                      'overflow-ellipsis overflow-hidden mb-2 text-center'
                    }
                  >
                    {device.device_name}
                  </h5>
                  <DeviceRow
                    localKey={local ? local.value : ''}
                    device={device}
                    key={index}
                  />
                </div>
              ))
          }
          {local &&
            devices &&
            (!Array.isArray(devices.results) ||
              devices.results.length === 0) && (
              <div className={'p-4 text-gray-600'}>
                {t('domotics.noDevices')}
              </div>
            )}

          {devices && devices.results && devices.results.length > 8 && (
            <button
              className={
                'flex space-x-2 justify-center items-center text-center py-4 px-4 w-full hover:bg-cyan-200 hover:text-cyan-600'
              }
              onClick={() => {
                setShowAllDevices(!showAllDevices);
              }}
            >
              <div>
                <Icon
                  name={showAllDevices ? 'eye-off' : 'eye'}
                  size={'20px'}
                />
              </div>
              <div>{showAllDevices ? t('general.hide') : t('general.showAll')}</div>
            </button>
          )}
        </div>
        <div className="col-span-2">
          {socketDevices.length === 0 && (
            <div
              className={
                'flex flex-col items-center justify-center space-y-4 bg-white shadow-md rounded-2xl border mb-2'
              }
              style={{ padding: '2rem' }}
            >
              <img src="/search.svg" className={'h-52'} />
              <div
                className={
                  'flex flex-col space-y-2 items-center justify-center'
                }
              >
                <div className="flex items-center space-x-2 text-xl mb-2 font-semibold">
                  <CircleSpinner color={'primary'} />
                  <div>{t('domotics.dataRetrieving')}</div>
                </div>
                <p className={'text-gray-600'}>
                  {devices?.results?.length + ' ' + t('domotics.contactedDevices')}
                </p>
              </div>
            </div>
          )}
          {local &&
            devices &&
            devices.results &&
            devices.results.map((device, index) => (
              <DeviceCharts
                key={index}
                onShow={(device) => {
                  if (socketDevices.indexOf(device.vikey_mac) === -1) {
                    setSocketDevices([...socketDevices, device.vikey_mac]);
                  }
                }}
                range={range}
                isRealTime={isRealTime}
                device={device}
                socketManager={socketManger}
                localKey={local.value}
              />
            ))}
        </div>
      </div>
    </PageWrapper>
  );
}
