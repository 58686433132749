import Joi from 'joi'

export default Joi.object({
  action: Joi.string().equal('new_user'),
  integr_type: Joi.string(),
  user_key: Joi.string().required().messages({
    'string.base': 'Account obbligatorio',
    'string.empty': 'Account obbligatorio',
    'string.required': 'Account obbligatorio'
  }),
  integr_secret: Joi.any(),
  integr_token: Joi.any()
})
