import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createAction,
} from "@reduxjs/toolkit";
import {
  Message,
  MessageAttachments,
  MessageThreadState,
  MessageUploadAttachments,
  Thread,
} from "../../types";
import {
  fetchMessageThreads,
  fetchSingleThread as fetchSingleThreadAPI,
  sendMessage as sendMessageAPI,
  pinChat as pinChatAPI,
  archiveChat as archiveChatAPI,
  muteChat as muteChatAPI,
  fetchMessages as fetchMessagesAPI,
  readSingleThread,
  translateSingleMessage as translateSingleMessageAPI,
  fetchAttachments as fetchAttachmentsAPI,
} from "../../shared/queries";
import socketManagerV2 from "../../shared/utils/socketv2";
import { mapMessage } from "../../shared/utils/mappers";
import { update } from "lodash";
import store from "../../store";

const initialState: MessageThreadState = {
  threads: {},
  currentThread: null,
  messages: {},
  hasMore: true,
  isLoading: false,
  isFetching: false,
  isFetchingMessages: false,
  isFocused: false,
  error: null,
  currentPage: 1,
  limit: 10,
  currentFilter: "all",
  previousFilter: "all",
  query: "",
  socket: null,
  socketInitialized: false,
  isSendingMessage: false,
  isSearchngThread: false,
  has_unread_messages: false,
};
export const playNotificationSound = createAction(
  "messageThread/playNotificationSound"
);

const initializeSocket = (topic: string, dispatch: Function) => {
  socketManagerV2.init("messages");
  socketManagerV2.subscribe({ topic });
  socketManagerV2.listen({
    event: "message",
    callback: (data) => {
      const message = mapMessage(JSON.parse(data.data.data));
      dispatch(
        saveMessages({
          messages: [message],
          threadId: message.message_thread_id,
          overwrite: false,
          webSocket: true,
        })
      );
      dispatch(
        fetchSingleThread({
          thread_id: message.message_thread_id,
          update: true,
        })
      );

      if (message.type === "received") {
        dispatch(playNotificationSound());
      }
    },
  });

  dispatch(setSocketInitialized({ value: true }));
};

export const handleHasUnreadMessages = createAction<{
  has_unread_messages: boolean;
}>("messageThread/handleHasUnreadMessages");
export const setCurrentFilter = createAction<{
  currentFilter: "all" | "unread" | "archived";
}>("messageThread/setCurrentFilter");

export const fetchThreadData = createAsyncThunk(
  "messageThread/fetchThreadData",
  async (thread_id: string, { rejectWithValue }) => {
    try {
      const response = await readSingleThread(thread_id);
      if (response) {
        const { thread, has_unread_messages } = response;
        if (!thread) {
          throw new Error("Thread data is missing");
        }
        return { thread, has_unread_messages };
      }
      throw new Error("Failed to fetch the thread");
    } catch (error: any) {
      return rejectWithValue(error.message || "Failed to fetch thread data");
    }
  }
);
// Set the current thread action
export const setCurrentThread = createAsyncThunk(
  "messageThread/setCurrentThread",
  async (
    { thread_id }: { thread_id: string | null },
    { getState, dispatch, rejectWithValue }
  ) => {
    if (thread_id) {
      try {
        var { threads, has_unread_messages } = store.getState().messageStore;
        var thread = threads[thread_id];
        /* If there is a selected thread and it has been read just return it, just return it   */
        if (thread && !thread.has_been_read) {
          dispatch(fetchThreadData(thread_id));
        }
        return {
          current_thread: thread,
          has_unread_messages,
        };
      } catch (error: any) {
        return rejectWithValue(
          error.message || "Failed to set the current thread"
        );
      }
    } else {
      return {
        current_thread: null,
      };
    }
  }
);

// export const translateSingleMessage = createAsyncThunk(
//   "messageThread/transLateSingleMessage",
//   async (
//     { message_id, lang}: { message_id: string | null, lang:string},
//     { getState, dispatch, rejectWithValue }
//   ) => {
//     if (message_id) {
//       try {
//         const message = await translateSingleMessageAPI(message_id,lang);

//         if (message) {
//           dispatch(updateMessage({ updated_message:message, threadId:message.message_thread_id }));
//         }

//         throw new Error("Failed to fetch the thread");
//       } catch (error: any) {
//         return rejectWithValue(
//           error.message || "Failed to set the current thread"
//         );
//       }
//     }
//   }
// );

export const setIsFocused = createAction<{
  isFocused: boolean;
  thread_id: string | undefined;
}>("messageThread/setIsFocused");

// Async thunks
export const fetchSingleThread = createAsyncThunk(
  "messageThread/fetchSingleThread",
  async (
    {
      thread_id,
      update,
    }: {
      thread_id: string;
      update: boolean;
    },
    { getState, dispatch }
  ) => {
    const state = getState() as { messageStore: MessageThreadState };
    const { currentFilter, previousFilter, currentThread, isFocused } =
      state.messageStore;

    if (currentFilter !== previousFilter && !update) {
      dispatch(resetThreads());
    }
    var response = null;
    if (currentThread && currentThread.thread_id === thread_id && isFocused) {
      response = await readSingleThread(thread_id);
    } else {
      if (!state.messageStore.threads[thread_id]) {
        response = await fetchSingleThreadAPI(thread_id);
      }
    }

    if (response) {
      const thread = response.thread || [];
      const has_unread_messages = response.has_unread_messages;

      dispatch(
        handleHasUnreadMessages({ has_unread_messages: has_unread_messages })
      );

      if (thread) {
        dispatch(
          saveThreads({ threads: [thread], overwrite: true, update: update })
        );
      }
    }
  }
);

export const fetchThreads = createAsyncThunk(
  "messageThread/fetchThreads",
  async (
    {
      limit,
      query,
      filter,
      topic,
    }: {
      limit: number;
      filter: string;
      query?: string;
      topic?: string;
      user_key?: string;
    },
    { getState, dispatch }
  ) => {
    const state = getState() as { messageStore: MessageThreadState };
    const { currentPage, currentFilter, previousFilter, socketInitialized } =
      state.messageStore;
    const page = currentPage;
    if (currentFilter !== previousFilter) {
      dispatch(resetThreads());
    }

    const response = await fetchMessageThreads({ page, limit, query, filter });
    const has_unread_messages = response.has_unread_messages;

    if (response) {
      const threads = response.threads || [];
      const hasMore = threads.length > 0 && !(threads.length < limit);

      if (threads.length > 0) {
        if (page === 1) {
          dispatch(
            saveThreads({ threads, overwrite: true, currentPage: page })
          );
        } else {
          dispatch(
            saveThreads({ threads, overwrite: false, currentPage: page })
          );
        }
      }
      dispatch(
        handleHasUnreadMessages({ has_unread_messages: has_unread_messages })
      );

      if (!socketInitialized && topic) {
        initializeSocket(topic, dispatch);
      }

      return { hasMore };
    }

    if (!socketInitialized && topic) {
      initializeSocket(topic, dispatch);
    }
    dispatch(
      handleHasUnreadMessages({ has_unread_messages: has_unread_messages })
    );

    return { hasMore: false };
  }
);
export const  fetchAttachments = createAsyncThunk(
  "messageThread/fetchAttachments",
  async (
    {
      resv_key,
      attachment,
    }: {
      resv_key?: string;
      attachment: MessageAttachments;
    },
    { getState, dispatch }
  ) => {
    attachment.is_fetching = true

    console.log(attachment)
    // const response = await fetchAttachmentsAPI(resv_key, attachment.id);
    // if (Array.isArray(response)) {
    //   return { attachments: response };
    // } else {
    //   return { attachments: [response] };
    // }
  }
);
export const searchHandler = createAsyncThunk(
  "messaging/searchHandler",
  async (
    { query, filter }: { query: string; filter: string },
    { rejectWithValue }
  ) => {
    try {
      return await fetchMessageThreads({ query: query, filter: filter });
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const sendMessage = createAsyncThunk(
  "messaging/sendMessage",
  async (
    {
      thread,
      message_content,
      files,
    }: {
      thread: Thread;
      message_content: string;
      files: MessageUploadAttachments[];
    },
    { rejectWithValue }
  ) => {
    try {
      return await sendMessageAPI(
        thread.thread_id,
        message_content,
        thread.resv_key,
        files
      );
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const pinChat = createAsyncThunk(
  "messaging/pinChat",
  async ({ thread }: { thread: Thread }, { rejectWithValue }) => {
    try {
      return await pinChatAPI(thread.thread_id);
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const archiveChat = createAsyncThunk(
  "messaging/archiveChat",
  async ({ thread }: { thread: Thread }, { rejectWithValue }) => {
    try {
      return await archiveChatAPI(thread.thread_id);
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const muteChat = createAsyncThunk(
  "messaging/muteChat",
  async ({ thread }: { thread: Thread }, { rejectWithValue }) => {
    try {
      return await muteChatAPI(thread.thread_id);
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);
export const fetchMessages = createAsyncThunk(
  "messageThread/fetchMessages",
  async (
    {
      threadId,
    }: {
      threadId?: string;
    },
    { getState, dispatch }
  ) => {
    if (threadId) {
      const state = getState() as { messageStore: MessageThreadState };
      const existingMessages = state.messageStore.messages[threadId];
      
      if (existingMessages && existingMessages.length > 0) {
        return;
      }
      const messages = await fetchMessagesAPI(threadId);
      if (messages) {
        dispatch(saveMessages({ messages, threadId, overwrite: true }));
      }
    }
  }
);

const messageThreadSlice = createSlice({
  name: "messageThread",
  initialState,
  reducers: {
    resetThreads: (state) => {
      state.threads = {};
      state.currentPage = 1;
      state.query = "";
      state.isFetching = false;
      state.isFetchingMessages = false;
    },

    sortThreads: (state) => {
      const sortedThreads = Object.values(state.threads).sort((a, b) => {
        if (a.pinned !== b.pinned) {
          return a.pinned ? -1 : 1;
        }
        return (
          new Date(b.last_message.sent_on).getTime() -
          new Date(a.last_message.sent_on).getTime()
        );
      });

      state.threads = sortedThreads.reduce((acc, thread) => {
        acc[thread.thread_id] = thread;
        return acc;
      }, {} as Record<string, Thread>);
    },
    updateThread: (
      state,
      action: PayloadAction<{
        updated_thread: Thread;
      }>
    ) => {
      const { updated_thread } = action.payload;
      if (updated_thread) {
        state.threads[updated_thread.thread_id] = updated_thread;
      }
    },
    saveThreads: (
      state,
      action: PayloadAction<{
        threads: Thread[];
        overwriteSingle?: boolean;
        overwrite?: boolean;
        update?: boolean;
        currentPage?: number;
      }>
    ) => {
      const { threads, overwrite, overwriteSingle, currentPage } =
        action.payload;
      if (overwrite && !update) {
        state.threads = {};
      }
      threads.forEach((thread) => {
        state.threads[thread.thread_id] = thread;
      });
    },
    setSocketInitialized: (
      state,
      action: PayloadAction<{ value: boolean }>
    ) => {
      state.socketInitialized = action.payload.value;
    },
    updateMessage: (
      state,
      action: PayloadAction<{
        updated_message: Message;
        threadId: string;
      }>
    ) => {
      const { updated_message, threadId } = action.payload;
      if (updated_message && threadId) {
        const messageIndex = state.messages[threadId].findIndex(
          (message) => message.message_id === updated_message.message_id
        );
        if (messageIndex !== -1) {
          state.messages[threadId][messageIndex] = updated_message;
        }
      }
    },
    saveMessages: (
      state,
      action: PayloadAction<{
        messages: Message[];
        threadId: string;
        overwrite: boolean;
        webSocket?: boolean;
      }>
    ) => {
      const { messages, threadId, overwrite, webSocket } = action.payload;
      if (overwrite) {
        state.messages[threadId] = messages;
      } else {
        // console.log("socket messages ",messages)
        state.messages[threadId] = [
          ...(state.messages[threadId] || []),
          ...messages.filter(
            (newMessage) =>
              !(state.messages[threadId] || []).some(
                (existingMessage) => existingMessage.message_id === newMessage.message_id
              )
          ),
        ]
        if (webSocket) {
          if (state.threads[threadId]) {
            state.threads[threadId].last_message = messages[0];
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(playNotificationSound, (state) => {
        const audio = new Audio("/ui-bell-ding.mp3");
        audio.play();
      })
      .addCase(handleHasUnreadMessages, (state, action) => {
        const { has_unread_messages } = action.payload;
        state.has_unread_messages = has_unread_messages;
      })
      .addCase(setCurrentFilter, (state, action) => {
        const { currentFilter } = action.payload;
        state.currentFilter = currentFilter;
        state.currentPage = 1;
      })
      .addCase(setIsFocused, (state, action) => {
        const { isFocused, thread_id } = action.payload;
        state.isFocused = isFocused;
        if (thread_id) {
          setCurrentThread({ thread_id: thread_id });
        }
      })
      .addCase(fetchThreads.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchThreads.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasMore = action.payload.hasMore;
        state.previousFilter = state.currentFilter;
        if (state.hasMore) {
          state.currentPage = state.currentPage + 1;
        }
      })
      .addCase(fetchThreads.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(fetchMessages.pending, (state) => {
        state.isFetchingMessages = true;
      })
      .addCase(fetchMessages.fulfilled, (state) => {
        state.isFetchingMessages = false;
      })

      .addCase(fetchMessages.rejected, (state) => {
        state.isFetchingMessages = false;
        state.error = "Failed to fetch messages";
      })
      .addCase(setCurrentThread.pending, (state) => {
        // Reset current thread and set loading state
        state.isFetchingMessages = true;
        state.error = null;
      })
      .addCase(setCurrentThread.fulfilled, (state, action) => {
        const { current_thread, has_unread_messages } = action.payload;
        state.currentThread = current_thread;
        if (has_unread_messages !== undefined) {
          state.has_unread_messages = has_unread_messages;
        }
        state.isFetchingMessages = false;
      })
      .addCase(fetchThreadData.pending, (state) => {
        // Reset current thread and set loading state
        state.isFetchingMessages = true;
        state.error = null;
      })
      .addCase(fetchThreadData.fulfilled, (state, action) => {
        const { thread, has_unread_messages } = action.payload;
        state.threads[thread.thread_id] = thread;
        if (has_unread_messages !== undefined) {
          state.has_unread_messages = has_unread_messages;
        }
        state.isFetchingMessages = false;
      })
      .addCase(setCurrentThread.rejected, (state, action) => {
        state.currentThread = null;
        state.isFetchingMessages = false;
      })
      // Handle pinChat
      .addCase(pinChat.fulfilled, (state, action) => {
        const updatedThread = action.payload;
        console.log("pinned thread ", updatedThread);
        if (updatedThread) {
          state.threads[updatedThread.thread_id] = updatedThread;
        }
      })
      // Handle archiveChat
      .addCase(archiveChat.fulfilled, (state, action) => {
        const updatedThread = action.payload;
        if (updatedThread) {
          state.threads[updatedThread.thread_id] = updatedThread;
        }
      })
      .addCase(searchHandler.pending, (state, action) => {
        state.isSearchngThread = true;
      })
      .addCase(searchHandler.rejected, (state, action) => {
        state.isSearchngThread = false;
      })
      .addCase(searchHandler.fulfilled, (state, action) => {
        const { threads, has_unread_messages } = action.payload;
        state.isSearchngThread = false;
        if (threads.length > 0) {
          threads.forEach((newThread) => {
            if (!state.threads[newThread.thread_id]) {
              state.threads[newThread.thread_id] = newThread;
            }
          });
        }
        state.has_unread_messages = has_unread_messages;
      })
    
      .addCase(sendMessage.pending, (state, action) => {
        state.isSendingMessage = true;
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.isSendingMessage = false;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.isSendingMessage = false;
      });
  },
});

export const {
  resetThreads,
  saveThreads,
  saveMessages,
  updateThread,
  sortThreads,
  updateMessage,
  setSocketInitialized,
} = messageThreadSlice.actions;
export default messageThreadSlice.reducer;
