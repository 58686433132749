export default function ModalSection ({
  children,
  className
} : {
  children: any,
  className?: string
}) {

  return (
    <div className={className}>
      {children}
    </div>
  )

}