import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { fetchServicesCats } from '../../shared/queries';

type ServiceCatsProps = {
  data: string;
  onChange?: (categories: string[]) => void;
};

/* const cats = [
  {
    label: 'Buro',
    icon: '✒️',
    key: 'buro',
  },
  {
    label: 'Musei',
    icon: '🎨',
    key: 'musei',
  },
  {
    label: 'Tour',
    icon: '🎫',
    key: 'tour',
  },
  {
    label: 'Monumenti',
    icon: '🗽',
    key: 'monumenti',
  },
  {
    label: 'Trasporti',
    icon: '🚖',
    key: 'trasporti',
  },
  {
    label: 'Escursioni',
    icon: '🌲',
    key: 'escursioni',
  },
]; */

export default function ServiceCats({ data, onChange }: ServiceCatsProps) {
  const { t } = useTranslation();
  const [categories, setCategories] = useState<string[]>([]);
  const { data: cats } = useQuery<any>([], () => fetchServicesCats());

  useEffect(() => {
    if (data) {
      try {
        setCategories(JSON.parse(data));
      } catch (e) {}
    }
  }, [data]);

  return (
    <div className="flex flex-wrap -mx-1 -my-1">
      {cats?.results?.map((c: any, index: number) => (
        <div className="w-auto px-1 py-1" key={index}>
          <div
            onClick={() => {
              if (categories.indexOf(c.key) <= -1) {
                const cats = [...categories, c.key];
                setCategories(cats);
                if (onChange) onChange(cats);
              } else {
                const cats = categories.filter((cat) => cat !== c.key);
                setCategories(cats);
                if (onChange) onChange(cats);
              }
            }}
            className={`${
              categories.indexOf(c.key) > -1 &&
              'bg-cyan-100 border-cyan-600 text-cyan-800'
            } text-sm font-medium cursor-pointer hover:bg-cyan-100 border p-2 rounded-2xl border`}
          >
            {c.icon} {t(c.label)}
          </div>
        </div>
      ))}
    </div>
  );
}
