import React, { useEffect, useState } from "react";
import TextInput from "./TextInput";
import Icon from "./Icon";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { fetchGoogleSearch } from "../shared/queries";

type Props = {
  handleGoogleMaps: (data: any) => void;
};

const GoogleMapsSearch = (props: Props) => {
  const { t } = useTranslation();
  const { handleGoogleMaps } = props;
  const [search, setSearch] = useState("");
  const [listAutoComplete, setListAutoComplete] = useState<any[]>([]);
  const [listVisible, setListVisible] = useState<boolean>(false);

  const getAutocompleteAddress = useMutation(
    (body: { text: string }) => fetchGoogleSearch(body),
    {
      onSuccess: (data) => {
        const list = data.results;
        setListAutoComplete(list);
      },
    }
  );

  const selectAutocompleteAddress = useMutation(
    (body: { text: string; id: any }) => fetchGoogleSearch(body),
    {
      onSuccess: (data) => {
        toast.success(t("general.addressSelected"));
        handleGoogleMaps(data.results);
      },
    }
  );

  const onSearchCity = (val: any) => {
    if (val.length > 3) {
      getAutocompleteAddress.mutate({ text: val });
    }
    setSearch(val);
  };

  const onSelectCity = (searchObj: { id: any; text: string }) => {
    setSearch(searchObj.text);
    selectAutocompleteAddress.mutate(searchObj);
  };

  useEffect(() => {
    setTimeout(() => console.log("test"), 500);
  }, [search]);

  return (
    <div className="w-full flex flex-col">
      <div className="relative w-full">
        <TextInput
          onFocus={() => {
            setListVisible(true);
          }}
          onBlur={() => {
            setTimeout(() => setListVisible(false), 500);
          }}
          suffix={<Icon name="location-marker" size="18px" />}
          value={search}
          onChange={onSearchCity}
        />
        {listVisible && (
          <ul className="bg-cyan-50 border-[1px] rounded-lg p-4 absolute max-h-[200px] overflow-y-auto z-50 w-full mt-1">
            {listAutoComplete.length > 0 ? (
              listAutoComplete.map((city) => {
                return (
                  <li
                    key={city.id}
                    className="min-h-10 w-full border-b-[1px] border-solid border-l-gray-300 p-3 hover:bg-gray-50 cursor-pointer"
                    onClick={() => {
                      onSelectCity(city);
                    }}
                  >
                    {city.text}
                  </li>
                );
              })
            ) : (
              <li className="min-h-10 w-full border-b-[1px] border-solid border-l-gray-300 p-3 hover:bg-gray-50 cursor-pointer">
                {t('general.addressNotFound')}
              </li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default GoogleMapsSearch;
