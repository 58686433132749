import { useQuery } from "react-query";
import RuleList from "../components/domotic/RuleList";
import PageWrapper from "../components/PageWrapper";
import { fetchDomoticRules } from "../shared/queries";

export default function DomoticRules () {

    const {
        data,
        isLoading,
        isFetching: isLoadingRules,
        refetch: refetchRules
      } = useQuery(['localRules'], () => fetchDomoticRules())

    return (
        <PageWrapper>
            <RuleList />
        </PageWrapper>
    )

}