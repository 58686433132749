import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDomoticDevices, fetchDomoticEntities, fetchLocalsWithDevices, fetchV3AllLocals } from "../../shared/queries";
import { DomoticRuleAction, DomoticRuleCondition, RuleSlice } from "../../types";

export const fetchRuleLocals = createAsyncThunk(
    'ruleManager/fetchLocals',
    async () => {
        return await fetchV3AllLocals()
    }
)

export const fetchRuleDevices = createAsyncThunk(
    'ruleManager/fetchLocalDevices',
    async (localKey: string) => {
        return await fetchDomoticDevices(localKey, undefined, true)
    }
)

export const fetchRuleEntities = createAsyncThunk(
    'ruleManager/fetchEntities',
    async () => {
        return await fetchDomoticEntities()
    }
)

const baseCondition = {
    device: undefined,
    condition_type: undefined,
    vikey_mac: undefined,
    local_key: undefined,
    device_mac: undefined,
    timespan: 0,
    value: undefined,
    entity_id: undefined
  } as DomoticRuleCondition
  
  const baseAction = {
    device: undefined,
    device_mac: undefined,
    action: undefined,
    entity_id: undefined,
    local_key: undefined,
    top: undefined,
    value: 0,
    vikey_mac: undefined,
    timestamp: 0,
    command: {
      device_mac: undefined,
      command_type: undefined,
      parameters: {
        value: null
      },
      entity_id: undefined
    }
  } as DomoticRuleAction

const ruleSlice = createSlice({
    name: 'ruleSlice',
    initialState: {
        loading: {
            devices: false,
            locals: false,
            entities: false
        },
        devices: {},
        locals: [],
        entities: [],
        rule: {
            description: '',
            active: 'True',
            conditions: [{...baseCondition}],
            actions: [{...baseAction}]
        }
    } as RuleSlice,
    reducers: {
        setLocals: (state: RuleSlice, action) => {
            state.locals = action.payload
        },
        setDevices: (state: RuleSlice, action) => {}
    },
    extraReducers: (builder) => {
        /**
         * Fetch all locals with device associated
         */
        builder.addCase(fetchRuleLocals.pending, (state) => {
            state.loading.locals = true
        })
        builder.addCase(fetchRuleLocals.rejected, (state) => {
            state.loading.locals = false
        })
        builder.addCase(fetchRuleLocals.fulfilled, (state, action) => {
            if (action.payload.results) {
                state.locals = action.payload.results
            } else {
                state.locals = []
            }
            state.loading.locals = false
        })
        /**
         * Fetch all devices connected with the apartment
         */
         builder.addCase(fetchRuleDevices.pending, (state) => {
            state.loading.devices = true
        })
        builder.addCase(fetchRuleDevices.rejected, (state) => {
            state.loading.devices = false
        })
        builder.addCase(fetchRuleDevices.fulfilled, (state, action) => {
            if (action.payload.results) {
                state.devices[action.meta.arg] = action.payload.results
            }
            state.loading.devices = false
        })
        /**
         * Fetch all entities
         */
         builder.addCase(fetchRuleEntities.pending, (state) => {
            state.loading.entities = true
        })
        builder.addCase(fetchRuleEntities.rejected, (state) => {
            state.loading.entities = false
        })
        builder.addCase(fetchRuleEntities.fulfilled, (state, action) => {
            if (action.payload.results) {
                state.entities = action.payload.results
            }
            state.loading.entities = false
        })
    }
})

export const {
} = ruleSlice.actions

export default ruleSlice.reducer