import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { sendDomoticCommand } from '../../shared/queries';
import {
  DeviceCommandRequest,
  DeviceEntityCommand,
  DomoticDevice,
  DomoticEntity,
} from '../../types';
import Button from '../Button';
import Icon from '../Icon';
import Modal from '../Modal';
import ModalActions from '../ModalAction';
import ModalCard from '../ModalCard';
import ModalSection from '../ModalSection';
import TextInput from '../TextInput';

export default function DeviceRow({
  device,
  localKey,
}: {
  device: DomoticDevice;
  localKey: string;
}) {
  const { t } = useTranslation();

  const [currentCommand, setCurrentCommand] = useState<DeviceEntityCommand>();
  const [viewCommands, setViewCommands] = useState<boolean>(false);
  const [entitiesCommands, setEntitiesCommands] = useState<
    {
      entity: DomoticEntity;
      commands: DeviceEntityCommand[];
    }[]
  >([]);
  const [commandValue, setCommandValue] = useState<string>('');

  useEffect(() => {
    if (device && device.entities) {
      setEntitiesCommands(
        device.entities
          .filter((e) => e.commands)
          .map((e) => {
            return {
              entity: e,
              commands: e.commands.map((c) => {
                return {
                  ...c,
                  entity_id: e.entity_id,
                };
              }),
            };
          })
      );
    }
  }, [device]);

  /**
   * Check if the current command contains any kind of parameter to be sent
   */
  function commandNeedParameters(): boolean {
    if (currentCommand) {
      if (currentCommand.value_specifications.type === 'text') return true;
    }
    return false;
  }

  const commandMutation = useMutation(
    (data: DeviceCommandRequest) => sendDomoticCommand(data),
    {
      onSuccess: (data) => {
        var msg = t('domotic.commandSent');
        if(data && data.msg){
          msg+= ': ' + data.msg
        }
        toast.success(msg);
        setViewCommands(false);
      },
      onError: (data) => {
        toast.error(t('domotic.commandError'));
      },
    }
  );

  return (
    <Fragment>
      <div className="grid grid-cols-3 gap-4 justify-center mb-4">
      {entitiesCommands.map((entity, entityIndex) => (
        <div
          className={
            `p-6 rounded-2xl text-center bg-white dash-card`
          }
        >
          <h5 className={'overflow-ellipsis overflow-hidden mb-2'}>
            {entity.entity.entity_name?? entity.entity.entity_type}
          </h5>
          <div className='flex justify-center'>
            <Icon name={entity.entity.entity_type_specific?? entity.entity.entity_type} className={'text-cyan-600'} size="36px"></Icon>
          </div>
          <h1 className={'overflow-ellipsis overflow-hidden my-2'}>
            {entity.entity.value}
          </h1>
        </div>
        ))}

      </div>

      {entitiesCommands && entitiesCommands.length > 0 ? (
            <Button
              className="mt-2"
              size={'medium'}
              onClick={() => setViewCommands(true)}
              color={'info'}
              label={t('deviceCommand.commands')+' (' + entitiesCommands.length + ')'}
            />
          ) : (
            <div className={'text-gray-600 text-sm'}>
              {t('deviceCommand.noCommands')}
            </div>
          )}
      <Modal visible={viewCommands}>
        <ModalCard className={'w-full max-w-lg'} title={t('deviceCommand.availableCommands')}>
          <ModalSection className={'p-4 flex flex-col space-y-2'}>
            {entitiesCommands.map((entity, entityIndex) => (
              <div key={'ent' + entityIndex}>
                <div className="font-medium mb-4 flex items-center space-x-10">
                  <div className={'text-gray-600'}>
                    {entity.entity.entity_name &&
                      entity.entity.entity_name + ' - '}{' '}
                    {t('commandsTypes.' + entity.entity.entity_type)}
                  </div>
                  <div className="border-b flex-1"></div>
                </div>
                <div className="flex flex-col space-y-2">
                  {entity.commands.map((c, commandIndex) => (
                    <button
                      onClick={() => {
                        setCurrentCommand(c);
                        setCommandValue('');
                      }}
                      className={`${
                        currentCommand &&
                        currentCommand.command_type === c.command_type &&
                        currentCommand.entity_id === c.entity_id &&
                        'border-2 border-cyan-600 bg-cyan-100'
                      } transition-background font-medium text-cyan-800 p-4 rounded-2xl hover:bg-cyan-100`}
                      key={commandIndex}
                    >
                      <div className="flex items-center space-x-2">
                        <div>
                          <div className="w-5 h-5 border border-cyan-600 rounded-full flex items-center justify-center">
                            {currentCommand &&
                              currentCommand.command_type === c.command_type &&
                              currentCommand.entity_id === c.entity_id && (
                                <div className="bg-cyan-600 rounded-full w-3 h-3"></div>
                              )}
                          </div>
                        </div>
                        <div>{t('commandsTypes.' + c.command_type)}</div>
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </ModalSection>

          {commandNeedParameters() && (
            <div className={'p-4 px-8 border-t'}>
              <div className="font-medium text-lg mb-4">
                {t('deviceCommand.commandParameters')}
              </div>

              {currentCommand &&
                currentCommand.value_specifications.type === 'text' && (
                  <TextInput
                    placeholder={t('deviceCommand.commandParameter')}
                    value={commandValue}
                    onChange={(val) => setCommandValue(val)}
                  />
                )}
            </div>
          )}

          <ModalActions
            onSave={() => {
              if (currentCommand) {
                commandMutation.mutate({
                  top: currentCommand.top,
                  local_key: localKey,
                  vikey_mac: device.vikey_mac,
                  command: {
                    command_type: currentCommand.command_type,
                    entity_id: currentCommand.entity_id,
                    device_mac: device.device_mac,
                    parameters: {
                      ...currentCommand.parameters,
                      value: commandValue || null,
                    },
                  },
                });
              }
            }}
            onClose={() => {
              setViewCommands(false);
            }}
            closeLabel={t('general.cancel')}
            saveLabel={t('deviceCommand.sendCommand')}
          ></ModalActions>
        </ModalCard>
      </Modal>
    </Fragment>
  );
}
