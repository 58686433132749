import { fetchCounties, fetchCountries } from '../shared/queries';



export default {
    countries: undefined,
    async loadContries(){
        const response = await fetchCountries();
        this.countries = response.results
    },
    async getCountry (code) {
        if(!this.countries) await this.loadContries()
        const element = this.countries.en[code]
        if (element) return {
            province: element.prov,
            code: element.cod,
            name: element.name
        }
    },
    async getMunicipality (country, key, code) {
        const response = await fetchCounties(country, key)
        if (response.results[code]){
            return {
                province: response.results[code].prov,
                code: response.results[code].cod,
                name: response.results[code].name
            }
        }
        return undefined
    }
}
