import ProgressBar from "../ProgressBar";
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../../reducers";
import { refreshAuth } from "../../features/login/loginSlice";
import { useHistory } from "react-router-dom";
import { getAuthToken } from "../../shared/utils/tokenStore";
import React from "react";
import { DomainContext } from "../../DomainContext";

const BackgroundWrapper = styled(motion.div)`
  z-index: 99999;
  background-color: #FFF;
`

const BackgroundAnimation = styled.div`
  animation-name: color;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  z-index: 99999;
  background-color: #FFF;
  position: absolute;
  width: 350px;
  border-radius: 100%;
  height: 350px;
  z-index: 40;

  @keyframes color {
    0% {
      background-color: #FFF;
      transform: scale(1);
    }
    50% {
      background-color: #f0fafc;
      transform: scale(1.1);
    }
    100 {
      background-color: #FFF;
      transform: scale(1);
    }
  }
`

export default function SessionLoading () {

  const history = useHistory()
  const dispatch = useDispatch()

  const [visible, setVisible] = useState(true)

  const {
    profile
  } = useSelector((state: RootState) => state.login)

  async function getProfile () {
    if (!profile) {
      const result = await dispatch(refreshAuth({} as any)) as any
      if (!refreshAuth.fulfilled.match(result)) {
        history.push('/')
      } else {
        if (history.location.pathname === '/') {
          history.push('/dashboard')
        }
      }
    }
  }

  useEffect(() => {
    const token = getAuthToken()
    setTimeout(() => {
      setVisible(false)
    }, 2000)
    if (token) {
      getProfile()
    } else {
      if (history.location.pathname !== '/' && !history.location.pathname.includes('/reset/')) {
        history.push('/')
      }
    }
  } , [])
  const {appLogo, appName} = React.useContext(DomainContext);

  return (
    <AnimatePresence>
      { 
        (visible || (!profile && history.location.pathname !== '/' && !history.location.pathname.includes('/reset/')))  &&
        <BackgroundWrapper
          initial={{
            opacity: 1
          }}
          exit={{
            opacity: 0
          }}
          className={'fixed top-0 left-0 w-screen h-screen flex items-center justify-center'}>
          <BackgroundAnimation />
          <div className={'z-50 relative flex flex-col items-center'}>
            <img
              src={appLogo}
              alt={appName + " logo"}
              className={'h-16 ml-4 inline-block'} />
            
            <ProgressBar
              className={'mt-4 w-32'} />
          </div>
        </BackgroundWrapper>
      }
    </AnimatePresence>
  )

}