import { useEffect, useState } from 'react';
import { BuyableLang, ServiceQuestion } from '../../types';
import Icon from '../Icon';
import Modal from '../Modal';
import ModalActions from '../ModalAction';
import ModalCard from '../ModalCard';
import ModalSection from '../ModalSection';
import UpdateQuestion from './UpdateQuestion';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';

type ServiceQuestionProps = {
  lang: BuyableLang;
  onChange?: (data: any) => void;
};

export default function ServiceQuestionItem({
  lang,
  onChange,
}: ServiceQuestionProps) {
  const { t } = useTranslation();

  const [currentQuestion, setCurrentQuestion] =
    useState<ServiceQuestion | null>();
  const [questions, setQuestions] = useState<ServiceQuestion[]>([]);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (lang) {
      let data = JSON.parse(lang.extras);
      if (data) {
        if (!Array.isArray(data)) {
          data = [data];
        }
        setQuestions(
          data.map((q: ServiceQuestion) => {
            return {
              ...q,
              id: uuid(),
            };
          })
        );
      }
    }
  }, [lang]);

  const removeQuestion = (id?: string) => {
    const _questions = questions.filter((e) => e.id != id);
    if (onChange) onChange(_questions);
  };

  return (
    <>
      <div>
        {questions.map((question: ServiceQuestion, index: number) => (
          <div
            key={question.id}
            className={`flex mr-4 px-4 py-4 justify-between items-center ${
              index < questions.length - 1 ? 'border-b' : ''
            } ${
              questions.length === 1
                ? 'rounded-2xl'
                : index === 0
                ? 'rounded-t-2xl'
                : index === questions.length - 1
                ? 'rounded-b-2xl'
                : ''
            } border bg-white transition-all `}
          >
            <div
              className={
                'flex items-center space-x-4 cursor-pointer hover:text-cyan-600'
              }
              onClick={() => {
                setEdit(true);
                setCurrentQuestion(question);
              }}
            >
              {question.type === 'input' && (
                <Icon size={'20px'} name={'view-list'}></Icon>
              )}
              {question.type === 'select' && (
                <Icon size={'20px'} name={'clipboard-check'}></Icon>
              )}
              <div>
                <div className={'font-medium'}>{question.name}</div>
                <div className={'text-gray-500 text-sm'}>
                  {question.type === 'input' &&
                    t('serviceQuestionItem.freeText')}
                  {question.type === 'select' &&
                    t('serviceQuestionItem.select')}
                </div>
              </div>
            </div>
            <div className="flex justify-end cursor-pointer ">
              <Icon
                size={'20px'}
                name={'trash'}
                className="text-red-500 hover:text-red-600"
                onClick={()=>{removeQuestion(question.id)}}
              ></Icon>
            </div>
          </div>
        ))}
      </div>

      {questions.length === 0 && (
        <div className={'text-gray-600 font-medium'}>
          {t('serviceQuestionItem.noQuestion')}
        </div>
      )}

      <div>
        <a
          href="#"
          className={
            'text-sm px-3 py-2 rounded-2xl text-center block text-medium text-cyan-600 bg-cyan-100 w-full mt-4'
          }
          onClick={() => {
            setEdit(false);
            setCurrentQuestion({
              name: t('serviceQuestionItem.noName'),
              selvals: [],
              required: false,
              type: 'input',
            });
          }}
        >
          {t('general.add')}
        </a>
      </div>

      <Modal
        visible={currentQuestion !== undefined && currentQuestion !== null}
      >
        <ModalCard className={'w-full max-w-xl'} title={currentQuestion?.name}>
          <ModalSection className={'w-full max-w-xl'}>
            {currentQuestion && (
              <UpdateQuestion
                data={currentQuestion}
                onChange={(question) => {
                  setCurrentQuestion({
                    ...question,
                    changed: true,
                  });
                }}
              ></UpdateQuestion>
            )}
          </ModalSection>
          <ModalActions
            saveLabel={t('general.confirm')}
            onClose={() => {
              setCurrentQuestion(null);
            }}
            onSave={() => {
              if (currentQuestion) {
                const _questions = questions
                  ? edit
                    ? questions.map((q) => {
                        if (q.id === currentQuestion.id) {
                          return currentQuestion;
                        }
                        return q;
                      })
                    : [...questions, currentQuestion]
                  : [];
                if (onChange) onChange(_questions);
                setCurrentQuestion(null);
              }
            }}
          ></ModalActions>
        </ModalCard>
      </Modal>
    </>
  );
}
