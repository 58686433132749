import Joi from 'joi'

export default Joi.object({
  id: Joi.equal('new'),
  objkey: Joi.string().required().messages({
    'string.base': 'Titolo obbligatorio',
    'string.empty': 'Titolo obbligatorio',
    'string.required': 'Titolo obbligatorio'
  }),
  user_key: Joi.string().required()
})
