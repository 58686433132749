import React from "react";
import BaseTable from "../BaseTable";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import ReactTooltip from "react-tooltip";
import { useMutation, useQuery } from "react-query";
import {
  deleteRateSingleLocal,
  fetchRateSingleLocal,
} from "../../shared/queries";
import { toast } from "react-toastify";
import FormSectionTitle from "../FormSectionTitle";

type Props = {
  local_key: string;
  onEditRate: (rateId: any) => void;
  onEditSettings: (rateId: any) => void;
  onCreateRate: () => void;
};

const RateListComponent = (props: Props) => {
  const { t } = useTranslation();
  const { local_key, onEditRate, onEditSettings, onCreateRate } = props;
  const {
    data: rateList,
    isLoading,
    refetch,
  } = useQuery<any>(
    ["channelmanagerrate", local_key],
    () => fetchRateSingleLocal(local_key as string),
    { enabled: local_key !== null }
  );
  const deleteMutation = useMutation(
    (data: any) => deleteRateSingleLocal(data, local_key),
    {
      onSuccess: () => {
        toast.success(t("general.operationCompleted"));
        refetch();
      },
      onError: () => {
        toast.error(t("channelManager.rate_plan.deleteErrorMsg"))
      }
    }
  );
  const onDeleteRate = (rateId: string) => {
    deleteMutation.mutate(rateId);
  };
  const getColumnsRate = () => {
    return [
      {
        Header: t("channelManager.rate_plan.title") || "",
        accessor: (row: any) => <>{row.attributes.title}</>,
      },
      {
        Header: t("channelManager.rate_plan.actions"),
        accessor: (row: any) => (
          <>
            <div
              data-tip={t("channelManager.rate_plan.editRateRoom")}
              className="max-w-max"
            >
              <Button
                color={"info"}
                flat
                size="small"
                icon="plus"
                label={t('channelManager.rate_plan.editRate')}
                iconSize="14px"
                onClick={() => {
                  onEditRate(row.id);
                }}
              ></Button>
            </div>
            <div
              data-tip={t("channelManager.rate_plan.editCheckinRoom")}
              className="max-w-max"
            >
              <Button
                color={"info"}
                flat
                size="small"
                icon="pencil"
                label={t("channelManager.rate_plan.editCheckin")}
                iconSize="14px"
                onClick={() => {
                  onEditSettings(row.id);
                }}
              ></Button>
            </div>
            <div data-tip={t("channelManager.rate_plan.deleteRate")} className="max-w-max">
              <Button
                color={"negative"}
                loading={deleteMutation.isLoading}
                flat
                size="small"
                icon="trash"
                label={t("channelManager.rate_plan.deleteRate")}
                iconSize="14px"
                onClick={() => {
                  onDeleteRate(row.id);
                }}
              ></Button>
            </div>
            <ReactTooltip />
          </>
        ),
      },
    ];
  };
  return (
    <>
      <FormSectionTitle
        title={t("channelManager.rate_plan.rateList")}
        edit
        editLabel={t("channelManager.rate_plan.createRate")}
        editFull
        onEdit={() => onCreateRate()}
        dense
        subtitle={t("channelManager.rate_plan.rateListSubtitle")}
      />
      <BaseTable
        identifierKey={"local_key"}
        loading={isLoading}
        data={rateList?.results ?? []}
        className={"mb-4"}
        columns={getColumnsRate()}
      />
    </>
  );
};

export default RateListComponent;
