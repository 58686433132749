import React, { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Icon from '../components/Icon';
import { attemptLogin } from '../features/login/loginSlice';
import { RootState } from '../reducers';
import { useAppDispatch } from '../store';
import CircleSpinner from './CircleSpinner';

export default function LoginForm() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { loading, error } = useSelector((state: RootState) => state.login);

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  /**
   * The validation is handled by the native HTML form
   * @param event Mosue event
   */
  async function handleLogin(event: MouseEvent<HTMLButtonElement>) {
    if (!username || !password) {
      return;
    }
    event.preventDefault();
    const result = await dispatch(
      attemptLogin({
        username,
        password,
      })
    );
    if (attemptLogin.fulfilled.match(result)) {
        history.go(0);
    }
  }

  return (
    <form>
      <div className={'flex flex-col shadow-md bg-gray-100 rounded-2xl'}>
        <div>
          <input
            required
            placeholder={t('login.loginEmail')}
            className={
              'border border-gray-300 border-b-0 focus:border-b-1 placeholder-gray-500 appearance-none rounded-t-2xl relative block w-full px-3 py-2 text-gray-900 focus:outline-none focus:ring focus:ring-cyan-200 focus:border-cyan-400 focus:z-10 sm:text-sm'
            }
            type={'email'}
            onChange={(e) => setUsername(e.target.value)}
            value={username}
          ></input>
        </div>
        <div>
          <input
            required
            placeholder={t('general.password')}
            className={
              'border-gray-300 placeholder-gray-500 appearance-none relative block w-full px-3 py-2 border text-gray-900 rounded-b-2xl focus:outline-none focus:ring focus:ring-cyan-200 focus:border-cyan-400 focus:z-10 sm:text-sm'
            }
            type={'password'}
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          ></input>
        </div>
      </div>

      {error && (
        <div
          className={
            'text-center bg-red-200 rounded-2xl px-2 py-2 text-red-800 mt-4 text-sm'
          }
        >
          Credenziali non valide
        </div>
      )}

      <button
        type="submit"
        className={
          'w-full text-white py-2 px-3 font-medium rounded-2xl bg-gray-800 mt-4 hover:bg-gray-700 focus:hover:bg-gray-900'
        }
        onClick={handleLogin}
      >
        <div className={'flex items-center justify-between'}>
          <div>{t('login.loginButton')}</div>
          {loading === 'idle' ? (
            <Icon name={'lock-open'} size={'20px'}></Icon>
          ) : (
            <CircleSpinner width={15} height={15}></CircleSpinner>
          )}
        </div>
      </button>
    </form>
  );
}
