import { useQuery } from "react-query";
import Icon from "../../components/Icon";
import { fetchLocals } from "../../shared/queries";
import { LocalTable, TableResponse } from "../../types";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "../Modal";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import PageWrapper from "../PageWrapper";
import ModalActions from "../ModalAction";
import PageTitle from "../PageTitle";
import { useEffect, useState } from "react";

export default function DashboardApartments() {
  const history = useHistory();
  const { t } = useTranslation();
  const [localListNoApt, setLocalListNoApt] = useState<any[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  function getApartmentStatusColor(status: string | undefined) {
    switch (status) {
      case "ACTIVE":
        return "green-600";
      case "NOTINIT":
        return "gray-600";
      case "ERROR":
        return "red-600";
      default:
        return "gray-600";
    }
  }

  const { data, refetch, isFetching } = useQuery<
    TableResponse<LocalTable>,
    Error
  >(["apartments", 1, []], () => fetchLocals([], (1 - 1) * 15, undefined, 50), {
    onSuccess: (data) => {
      const tempList = data.data.filter(
        (d: any) => d.city === "" || d.city === null
      );
      setLocalListNoApt(tempList);
      return data;
    },
  });

  useEffect(() => {
    if (localListNoApt.length > 0) {
      setModalVisible(true);
    }
  }, [localListNoApt]);

  return (
    <div>
      {isFetching ? (
        <div className={"flex flex-col space-y-2"}>
          <Skeleton height={90}></Skeleton>
          <Skeleton height={90}></Skeleton>
          <Skeleton height={90}></Skeleton>
        </div>
      ) : (
        <>
          {data &&
            data.data &&
            [...data.data].splice(0, 5).map((local) => (
              <div
                key={local.local_key}
                onClick={() => {
                  history.push("/apartments/" + local.local_key);
                }}
                style={{
                  boxShadow:
                    "0px 2px 4px rgba(0,0,0,0.06), 0px 0px 0px 1px rgba(0,0,0,0.07)",
                }}
                className={
                  "cursor-pointer rounded-2xl mb-2 p-4 md:p-4 md:pl-0 bg-white flex justify-between items-center"
                }
              >
                <div className={"flex flex-wrap items-center"}>
                  <div className={"hidden md:block relative h-full"}>
                    <div
                      className={`-left-4 top-0 relative bg-${getApartmentStatusColor(
                        local.monitor_status
                      )} w-8 h-8 rounded-full border-4 border-gray-100`}
                    ></div>
                  </div>
                  <div>
                    <div className={"text-gray-800 font-medium"}>
                      {local.public_name}
                    </div>
                    <div className={"text-gray-700 text-sm"}>
                      {local.address}
                    </div>
                  </div>
                  <div className={"flex mt-2 md:mt-0 md:ml-8"}>
                    {local.is_bridge && (
                      <div
                        className={
                          "bg-cyan-600 text-white rounded-2xl px-2 text-xs font-medium py-1"
                        }
                      >
                        BRIDGE
                      </div>
                    )}
                  </div>
                </div>
                <Icon
                  className={"hidden md:block text-cyan-800"}
                  name={"arrow-right"}
                  size={"20px"}
                ></Icon>
              </div>
            ))}

          {data && data.data && data.data.length > 5 && (
            <button
              onClick={() => history.push("/apartments")}
              style={{
                boxShadow:
                  "0px 2px 4px rgba(0,0,0,0.06), 0px 0px 0px 1px rgba(0,0,0,0.07)",
              }}
              className={
                "bg-white hover:bg-gray-100 transition-all hover:text-gray-800 rounded-2xl w-full p-4 font-semibold"
              }
            >
              {t("dashboard.viewApartments", {
                count: data.data.length - 5,
              })}
            </button>
          )}
        </>
      )}
      <Modal visible={modalVisible}>
        <ModalCard className={"lg:w-2/3 w-full"}>
          <ModalSection>
            <PageWrapper className="mt-5">
              <PageTitle
                title={t("apartments.warning") + "!"}
                subtitle={t("dashboard.alertMsgNoCity")}
              />
              <div className="space-y-3">
                {localListNoApt.map((l) => (
                  <div
                    className="flex justify-center gap-3 border-2 rounded-full items-center cursor-pointer bg-yellow-200 md:w-1/2 w-full m-auto shadow-md text-yellow-900 px-2 py-1"
                    onClick={() => {
                      history.push("/apartments/" + l.local_key);
                    }}
                    key={l.local_key}
                  >
                    <p>{l.public_name}</p>
                    <Icon name="arrow-right" size="10px" />
                  </div>
                ))}
              </div>
            </PageWrapper>
            <ModalActions
              onClose={() => {
                setModalVisible(false);
              }}
              closeLabel={t("general.close")}
            />
          </ModalSection>
        </ModalCard>
      </Modal>
    </div>
  );
}
