import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useMutation } from "react-query";
import Button from "../Button";
import { fetchAttachments, translateSingleMessage } from "../../shared/queries";
import { Dictionary } from "@reduxjs/toolkit";
import CircleSpinner from "../CircleSpinner";
import { MessageAttachments } from "../../types";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { ImageViewModal } from "../ImageViewModal";
import Attachments from "./Attachments";
interface MessageBubbleProps {
  message: string;
  message_id: string;
  resv_key:string;
  type: string;
  sent_on: Date;
  profile_lang: string | undefined;
  translations: Dictionary<any>;
  attachments: Array<MessageAttachments>;
}

const MessageBubble: React.FC<MessageBubbleProps> = ({
  message,
  message_id,
  type,
  profile_lang,
  sent_on,
  resv_key,
  translations: initialTranslations,
  attachments,
}) => {
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [translated, setTranslated] = useState(false);
  const [localTranslations, setLocalTranslations] =
    useState(initialTranslations);
  const [displayedAttachments,setDisplayedAttachments] = useState(attachments);
  // console.log("Attachment ",attachments)
  const { mutate: translateMessage, isLoading: isTranslating } = useMutation(
    () => translateSingleMessage(message_id, profile_lang),
    {
      onSuccess: (translatedMessage) => {
        if (translatedMessage?.translations) {
          setLocalTranslations((prev) => ({
            ...prev,
            ...translatedMessage.translations,
          }));
        }
        setTranslated(true);
      },
      onError: (error: Error) => {
        toast.error(
          error.message ? t(error.message) : t("general.requestError")
        );
      },
    }
  );

  const handleMessageTranslate = () => {
    if (profile_lang && localTranslations[profile_lang]) {
      setTranslated(!translated);
    } else {
      translateMessage();
    }
  };
  const selectedImageHandle = (relative_path:string) => {
    if(relative_path){
      setSelectedImage(relative_path)
    }
  }

  const bubbleClass = classNames(
    "p-3 text-sm rounded-lg shadow-md transition-transform duration-200",
    {
      "bg-gray-300 text-black self-start hover:shadow-lg": type === "received",
      "bg-cyan-600 text-white self-end hover:shadow-lg": type === "sent-host",
      "bg-green-500 text-white self-end hover:shadow-lg": type === "sent-bot",
    }
  );

  const formatTime = (date: Date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes}`;
  };
  useEffect(() => {
    let isActive = true; 
  
    const fetchAndConvertAttachments = async () => {
      const updatedAttachments = await Promise.all(
        attachments.map(async (attachment) => {
          try {
            const response = await fetchAttachments(resv_key, attachment.id);
            if (!response.ok) throw new Error("Failed to fetch attachment");
  
            const blob = await response.blob();
            const file_data = await new Promise<ArrayBuffer>((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result as ArrayBuffer);
              reader.onerror = () => reject("Failed to convert blob to ArrayBuffer");
              reader.readAsArrayBuffer(blob);
            });
            const base64Data = Buffer.from(new Uint8Array(file_data)).toString('base64');
            const base64_string = `data:${blob.type};base64,${base64Data}`;
            return { ...attachment, relative_path: base64_string, is_fetching: false };
          } catch (error) {
            console.error(`Error fetching attachment ${attachment.id}:`, error);
            return { ...attachment, is_fetching: false };
          }
        })
      );
  
      if (isActive) {
        setDisplayedAttachments(updatedAttachments);
      }
    };
  
    fetchAndConvertAttachments();
  
    return () => {
      isActive = false; 
    };
  }, [attachments, resv_key]);

  return (
    <div
      className={`flex flex-col ${
        type === "received" ? "items-start" : "items-end"
      } mb-2`}
      style={{ maxWidth: "100%" }}
    >
      <div
        className={`flex ${
          type === "received" ? "items-center gap-2" : "justify-end"
        }`}
        style={{ width: "100%" }}
      >
        <div
          className={bubbleClass}
          style={{
            maxWidth: "75%",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            whiteSpace: "pre-wrap",
          }}
        >
          {message && (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  translated && profile_lang && localTranslations[profile_lang]
                    ? localTranslations[profile_lang].replace(/\n/g, "<br/>")
                    : message.replace(/\n/g, "<br/>"),
              }}
            />
          )}

              {displayedAttachments && displayedAttachments.length > 0 && (
                <div className="mt-2 space-y-1 max-w-[75%]">
                  <Attachments
                    attachments={displayedAttachments}
                    onClickImage={selectedImageHandle}
                    color={type !== 'received' ? "white": 'primary'}
                  />
                </div>
              )}

        </div>
        {type === "received" &&
          message &&
          (!isTranslating ? (
            <Button
              className="flex"
              color="info"
              icon={translated ? "translated" : "translate"}
              iconSize={"20px"}
              onClick={handleMessageTranslate}
            />
          ) : (
            <CircleSpinner color="primary" />
          ))}
      </div>
      <div
        className="text-xs mt-1 text-gray-500"
        style={{
          maxWidth: "75%",
          wordWrap: "break-word",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
        }}
      >
        {type === "sent-bot" ? "Bot " : ""}
        {formatTime(sent_on)}
      </div>
        {/* {attachments.length === 1 && (
          <ImageViewModal
            visible={!!selectedImage}
            onClose={() => setSelectedImage(null)} // Close modal
          >
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Preview"
                className="w-full h-full max-w-full max-h-full p-10 object-contain"
                style={{ objectFit: "contain", height: "85vh" }}
              />
            )}
          </ImageViewModal>
        )}
           */}
    </div>
  );
};

export default React.memo(MessageBubble);
