import { useState } from "react";
import PageWrapper from "../components/PageWrapper";
import FormField from "../components/FormField";
import TextInput from "../components/TextInput";
import PageTitle from "../components/PageTitle";
import { Editor } from "@tinymce/tinymce-react";
import Button from "../components/Button";
import { useMutation } from "react-query";
import { submitTicket } from "../shared/queries";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function Support () {

  const [subject, setSubject] = useState('')
  const [text, setText] = useState('')
  const {t} = useTranslation()

  const onClick=()=>{
    mutation.mutate()
  }

  const mutation = useMutation(() => submitTicket(text, subject), {
    onSuccess: () => {
      toast.success(t('general.operationCompleted'))
      setText('')
      setSubject('')
      // queryClient.invalidateQueries(['ticket'])
      // if (onClose) onClose()
    },
    onError:()=>{
      toast.error(t('general.requestError'))
    }
  })

  return (
    <PageWrapper>

      <PageTitle
        title={t('support.requestSupport')} />
    
      <div className="flex space-x-12">
        <div className="w-full w-max-xl">
          <div className="flex flex-col space-y-8">
            <FormField
              label={t('support.requestSupportSubject')}>
              <TextInput value={subject} onChange={val=>setSubject(val)}/>
            </FormField>

            <FormField
              label={t('support.requestSupportMessage')}>
                <Editor
                  apiKey={'af1s9ngxeroefg0odkelxcvqpjzqffflygu2cw2tqk9jexpo'}
                  value={text}
                  init={{
                    promotion: false,
                    height: 350,
                    plugins: [
                      'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                      'searchreplace', 'wordcount', 'visualblocks', 'visualchars', 'code', 'fullscreen', 'insertdatetime',
                      'media', 'table', 'emoticons', 'help'
                    ],
                    toolbar: 'undo redo | styles | bold italic backcolor | alignleft aligncenter alignright alignjustify | ' +
                      'bullist numlist outdent indent | link | ' +
                      'removeformat | help',
                    menubar: 'file edit view insert format tools table help',
                  }}
                  onEditorChange={value => {
                    setText(value)
                  }}
                />
            </FormField>
          </div>

          <Button
            icon={'paper-airplane'}
            label={t('support.requestSupportSend')}
            className={'mt-8'}
            onClick={()=>onClick()} />
        </div>

        <div className="w-1/2">
        </div>
      </div>

    </PageWrapper>
  )

}