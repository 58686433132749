import ReactTooltip from "react-tooltip"
import Popover from "./Popover"
import i18n from '../langs/index'
import { useState, useEffect } from "react";


export default function LanguageSwitch () {

const[flag, setFlag] = useState("/images/flags/italy.svg");
const onLanguageSelected = (_lang: string) =>{
  i18n.changeLanguage(_lang);
  localStorage.setItem('vikey_lang', _lang);
  switch(_lang){
    case 'en':
      setFlag("/images/flags/english.svg");
      break;
    case 'es':
      setFlag("/images/flags/spain.svg");
      break;
    case 'fr':
      setFlag("/images/flags/france.svg");
      break;
    default:
      setFlag("/images/flags/italy.svg");
  }

}

useEffect(() => {
    const lang = localStorage.getItem('vikey_lang')
    if (lang) {
      onLanguageSelected(lang)
    }
}, [])

useEffect(() => {
  if(i18n.language === 'it' || i18n.language === 'es'){
    window.Intercom('update', {
      "hide_default_launcher": false,
      "language_override": i18n.language
    });
  } else {
    window.Intercom('update', {
      "hide_default_launcher": true
    });
  }

}, [i18n.language])


  return (
    <Popover
      placement={'auto'}
      className={'w-full cursor-pointer h-full flex items-center justify-center'}
      renderContent={() => (
        <div className={'bg-white rounded-lg p-2 space-y-4 flex flex-col'}>
            <button
                onClick={() => {onLanguageSelected('it')}}
                className="focus:outline-none block hover:ring-2 hover:ring-blue-200 cursor-pointer">
                <img className={'w-6 h-6'} data-tip={'Italiano'} src="/images/flags/italy.svg" alt="Italian" />
            </button>

            <button
                onClick={() => {onLanguageSelected('en')}}
                className="focus:outline-none block hover:ring-2 hover:ring-blue-200 cursor-pointer">
                <img className={'w-6 h-6'} data-tip={'Inglese'} src="/images/flags/english.svg" alt="English" />
            </button>

            <button
                onClick={() => {onLanguageSelected('es')}}
                className="focus:outline-none block hover:ring-2 hover:ring-blue-200 cursor-pointer">
                <img className={'w-6 h-6'} data-tip={'Spanish'} src="/images/flags/spain.svg" alt="Spanish" />
            </button>

            <button
                onClick={() => {onLanguageSelected('fr')}}
                className="focus:outline-none block hover:ring-2 hover:ring-blue-200 cursor-pointer">
                <img className={'w-6 h-6'} data-tip={'French'} src="/images/flags/france.svg" alt="French" />
            </button>
        </div>
      )}>
        <img className={'w-6 h-6'} src={flag} alt="Italian" />
        <ReactTooltip></ReactTooltip>
    </Popover>
  )

}
