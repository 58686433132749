import { useTranslation } from "react-i18next";
import { CityTaxPersonResId } from "../../types";
import CountrySelector from "../CountrySelector";
import CountySelector from "../CountySelector";
import FormField from "../FormField";
import Icon from "../Icon";
import ProvinceSelector from "../ProvinceSelector";
import TextInput from "../TextInput";

type ApartmentTaxRowProps = {
  country: CityTaxPersonResId;
  className?: string;
  onChange?: (step: CityTaxPersonResId) => void;
  onDelete: () => void;
};

export default function ApartmentTaxCountryRow({
  className,
  country,
  onChange,
  onDelete,
}: ApartmentTaxRowProps) {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-between">
      <div className={"w-full space-y-4  " + className}>
        <div>
          <FormField label={t("addApartmentTaxCountryRow.country")}>
            <CountrySelector
              initial={country.country}
              onChange={(value) => {
                if (onChange) {
                  onChange({
                    ...country,
                    country: value.value || "",
                  });
                }
              }}
            ></CountrySelector>
          </FormField>
        </div>
        {country.country === "100000100" && (
          <div>
            <FormField label={t("addApartmentTaxCountryRow.province")}>
              <ProvinceSelector
                country={country.country}
                initial={country.prov}
                onChange={(value) => {
                  if (onChange) {
                    onChange({
                      ...country,
                      prov: value.value || "",
                    });
                  }
                }}
              ></ProvinceSelector>
            </FormField>
          </div>
        )}
        {country.country === "100000100" && country.prov && (
          <div>
            <FormField label={t("addApartmentTaxCountryRow.county")}>
              <CountySelector
                country={country.country}
                initial={country.city}
                province={country.prov}
                onChange={(value) => {
                  if (onChange) {
                    onChange({
                      ...country,
                      city: value.value || "",
                    });
                  }
                }}
              ></CountySelector>
            </FormField>
          </div>
        )}
        <div>
          <FormField
            label={t("addApartmentTaxCountryRow.reduction")}
            tooltip={t("addApartmentTaxCountryRow.reductionText")}
          >
            <TextInput
              type="number"
              min={0}
              max={100}
              numbered={true}
              suffix={"%"}
              value={country.percentage}
              onChange={(value) => {
                if (onChange) {
                  if (+value < 0) {
                    onChange({
                      ...country,
                      percentage: 0,
                    });
                    return;
                  }
                  if (+value > 100) {
                    onChange({
                      ...country,
                      percentage: 100,
                    });
                    return;
                  }
                  onChange({
                    ...country,
                    percentage: value,
                  });
                }
              }}
            ></TextInput>
          </FormField>
        </div>
      </div>
      <div onClick={onDelete} className={"pl-6"}>
        <Icon
          className={"text-red-600 cursor-pointer hover:text-red-800"}
          name={"trash"}
          size={"20px"}
        ></Icon>
      </div>
    </div>
  );
}
