export default function PageWrapper ({
  children,
  style,
  className
} : {
  children: any,
  style?: any,
  className?: string
}) {

  return (
    <div style={style} className={'px-4 xl:px-8 pb-6 mx-auto xl:mx-4 ' + className}>
      {children}
    </div>
  )

}