import { useEffect, useRef, useState } from "react";
import BaseTable from "../BaseTable";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import {
  createRateSingleLocal,
  editRateSingleLocal,
  fetchChannelLocals,
  fetchChannelSingleLocal,
  postChannelSingleLocal,
} from "../../shared/queries";
import Button from "../Button";
import ReactTooltip from "react-tooltip";
import Modal from "../Modal";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import PageWrapper from "../PageWrapper";
import ModalActions from "../ModalAction";
import EditRoomComponent from "./EditRoomComponent";
import RateListComponent from "./RateListComponent";
import CreateRateComponent from "./CreateRateComponent";
import { toast } from "react-toastify";
import EditRoomSettings from "./EditRoomSettings";
import EditProperty from "./EditProperty";
import Pagination from "../Pagination";

type Props = {
  user_key: any
};

const AparmentsCM = (props: Props) => {
  const { user_key } = props;
  const { t } = useTranslation();
  const topRef: any = useRef();
  const limitPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageData, setPageData] = useState<any[]>([]);
  const [totalData, setTotalData] = useState(0);
  const [modalData, setModalData] = useState<{
    visible: boolean;
    title: string | null;
    content: string | null;
    id: string | null;
  }>({
    visible: false,
    title: null,
    content: null,
    id: null,
  });
  const [local, setLocal] = useState<string | null>(null);
  const [rateIdTemp, setRateIdTemp] = useState<string | null>(null);

  const {
    isFetching,
    refetch: refetchLocalList,
  } = useQuery<any>(["channelmanager", limitPage, currentPage, user_key], () => fetchChannelLocals(limitPage, currentPage, user_key).then((data) => {
    setPageData(data.results.rooms)
    setTotalData(data.results.total_rooms)
    return data
  }).catch(() => {
    setPageData([])
    setTotalData(0)
  }));

  const { data: localCM, refetch } = useQuery<any>(
    ["channelmanagerlocal", local],
    () => fetchChannelSingleLocal(local as string),
    { enabled: local !== null }
  );

  const editRoomMutation = useMutation(
    (data: any) => postChannelSingleLocal(local as string, data),
    {
      onSuccess: () => {
        toast.success(t("general.requestCompleted"));
        refetch();
        refetchLocalList();
      },
    }
  );

  const createRateMutation = useMutation(
    (data: any) => createRateSingleLocal(local as string, data),
    {
      onSuccess: (data) => {
        toast.success(t("general.requestCompleted"));
        refetch();
        refetchLocalList();
        setRateIdTemp(data.results.channex_rate_plan_id);
        setModalData((prev) => {
          return {
            ...prev,
            content: "viewSettings",
            title: t("channelManager.rate_plan.saveCheckin"),
          };
        });
      },
      onError: () => {
        toast.error(t("general.requestError"));
      },
    }
  );
  const editRateMutation = useMutation(
    (data: any) => editRateSingleLocal(local as string, data),
    {
      onSuccess: () => {
        toast.success(t("general.requestCompleted"));
        refetch();
        refetchLocalList();
        setModalData((prev) => {
          return {
            ...prev,
            content: "viewrate",
            title: "",
          };
        });
      },
      onError: () => {
        toast.error(t("general.requestError"));
      },
    }
  );

  const getStatusEditRoom: any = (room: any, type: any) => {
    if (type === "disableCreateRate") {
      if (room.status_room === "pending") {
        return true;
      }
      return false;
    } else if (type === "disableCreateRateColor") {
      if (room.status_integ === "pending" || room.status_rate === "pending") {
        return "text-red-700";
      }
    } else if (type === "disableCreateRateTitle") {
      if (room.status_rate === "pending" && room.status_integ === "pending") {
        return t("channelManager.rate_plan.createRate");
      } else if (room.status_rate === "ok" && room.status_integ === "pending") {
        return t("channelManager.rate_plan.editCheckin");
      }
      return t("channelManager.rate_plan.details");
    } else if (type === "disableEditRoom") {
      if (room.status_room === "pending") {
        return "text-red-700";
      }
    } else if (type === "status_color") {
      if (room.status_rate === "pending" && room.status_integ === "pending") {
        return "bg-red-500";
      } else if (room.status_rate === "ok" && room.status_integ === "pending") {
        return "bg-yellow-500";
      }
      return "bg-green-500";
    }
  };

  const getColumns = () => {
    return [
      {
        Header: t("channelManager.name") || "",
        accessor: (row: any) => <div className="px-4 py-2">{row.name}</div>,
      },
      {
        Header: t("channelManager.status") || "",
        accessor: (row: any) => {
          if (
            row.status_room === "pending" ||
            row.status_rate === "pending" ||
            row.status_integ === "pending"
          ) {
            return (
              <div
                className={
                  "bg-yellow-100 text-yellow-800 inline-block text-xs font-medium px-2 rounded-2xl py-2"
                }
              >
                {t("channelManager.room.statusPending")}
              </div>
            );
          }
          return (
            <div
              className={
                "bg-green-100 text-green-800 inline-block text-xs font-medium px-2 rounded-2xl py-2"
              }
            >
              {t("channelManager.room.statusOk")}
            </div>
          );
        },
      },
      {
        Header: t("channelManager.editRoom"),
        accessor: (row: any) => (
          <>
            <div
              className={"flex items-center space-x-0.5"}
              data-tip={t("channelManager.editRoom")}
            >
              <div
                className={`w-3 h-3 rounded-2xl ${
                  row.status_room === "pending" ? "bg-red-500" : "bg-green-500"
                }`}
              />
              <div className="max-w-max">
                <Button
                  color={"info"}
                  className={getStatusEditRoom(row, "disableEditRoom")}
                  flat
                  size="small"
                  icon="pencil"
                  iconSize="14px"
                  onClick={() => {
                    setModalData({
                      content: "editroom",
                      title: t("channelManager.editRoom"),
                      visible: true,
                      id: row.local_key,
                    });
                  }}
                ></Button>
              </div>
            </div>
            <ReactTooltip />
          </>
        ),
      },
      {
        Header: t("channelManager.detail") || "",
        accessor: (row: any) => (
          <div
            className={"flex items-center"}
            data-tip={getStatusEditRoom(row, "disableCreateRateTitle")}
          >
            <div
              className={`w-3 h-3 rounded-2xl ${getStatusEditRoom(
                row,
                "status_color"
              )}`}
            />
            <div className="max-w-max">
              <Button
                color={"info"}
                disabled={getStatusEditRoom(row, "disableCreateRate")}
                className={getStatusEditRoom(row, "disableCreateRateColor")}
                flat
                size="small"
                icon="arrow-narrow-right"
                iconSize="14px"
                onClick={() => {
                  setModalData({
                    content: "viewrate",
                    title: "",
                    visible: true,
                    id: row.local_key,
                  });
                }}
              ></Button>
            </div>
          </div>
        ),
      },
    ];
  };

  const getContent = (content: string) => {
    switch (content) {
      case "editroom":
        return (
          <EditRoomComponent
            singleData={localCM}
            onSaveEditRoom={(form) => {
              saveEditData(form);
              setModalData({
                content: null,
                title: null,
                visible: false,
                id: null,
              });
            }}
          />
        );
      case "createrate":
        return (
          <CreateRateComponent
            rateType="createrate"
            person_count={localCM?.results?.attributes.occ_adults}
            onGoBack={() =>
              setModalData((prev) => {
                return {
                  ...prev,
                  content: "viewrate",
                  title: "",
                };
              })
            }
            onSaveLoading={createRateMutation.isLoading}
            onSave={(dataNew) => {
              topRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
              });
              createRateMutation.mutate(dataNew);
            }}
          />
        );
      case "editrate":
        return (
          <CreateRateComponent
            rateType="editrate"
            local_key={local as string}
            rateId={rateIdTemp as string}
            onGoBack={() =>
              setModalData((prev) => {
                return {
                  ...prev,
                  content: "viewrate",
                  title: "",
                };
              })
            }
            onEditLoading={editRateMutation.isLoading}
            onEdit={(dataEdit) => editRateMutation.mutate(dataEdit)}
          />
        );
      case "viewrate":
        return (
          <RateListComponent
            local_key={local as string}
            onEditRate={(rateId) => {
              setRateIdTemp(rateId);
              setModalData((prev) => {
                return {
                  ...prev,
                  content: "editrate",
                  title: t("channelManager.rate_plan.editRate"),
                };
              });
            }}
            onEditSettings={(rateId) => {
              setRateIdTemp(rateId);
              setModalData((prev) => {
                return {
                  ...prev,
                  content: "editSettings",
                  title: t("channelManager.rate_plan.editCheckin"),
                };
              });
            }}
            onCreateRate={() => {
              setModalData((prev) => {
                return {
                  ...prev,
                  content: "createrate",
                  title: t("channelManager.rate_plan.createRate"),
                };
              });
            }}
          />
        );
      case "viewSettings":
        return (
          <EditRoomSettings
            rate_plan_id={rateIdTemp}
            local_key={local as string}
            refetchLocalList={refetchLocalList}
            onSuccessMutation={() =>
              setModalData((prev) => {
                return {
                  ...prev,
                  content: "viewrate",
                  title: "",
                };
              })
            }
          />
        );
      case "editSettings":
        return (
          <EditRoomSettings
            rate_plan_id={rateIdTemp}
            refetchLocalList={refetchLocalList}
            local_key={local as string}
            onGoBack={() =>
              setModalData((prev) => {
                return {
                  ...prev,
                  content: "viewrate",
                  title: "",
                };
              })
            }
          />
        );
      case "editproperty":
        return <EditProperty user_key={user_key}/>;
      default:
        break;
    }
  };

  const saveEditData = (editFormData: any) => {
    editRoomMutation.mutate(editFormData);
  };

  useEffect(() => {
    if (modalData.id) {
      setLocal(modalData.id);
    }
  }, [modalData]);

  return (
    <>

      {/* EDIT PROPERTY */}
      <div className="max-w-max my-3">
        <Button
          color={"primary"}
          size="small"
          icon="pencil"
          label={t("channelManager.editproperty.title")}
          iconSize="14px"
          onClick={() => {
            setModalData({
              content: "editproperty",
              title: t("channelManager.editproperty.title"),
              visible: true,
              id: null,
            });
          }}
        ></Button>
      </div>
      {/* EDIT PROPERTY */}
      <BaseTable
        identifierKey={"local_key"}
        data={pageData}
        className={"mb-4"}
        loading={isFetching}
        columns={getColumns()}
      />
      {pageData && pageData.length > 1 ? (
        <div className="mt-5">
          <Pagination
            page={currentPage}
            pageSize={limitPage}
            totalItems={totalData}
            onPageUpdate={(value) => {
              if (!isFetching) {
                if (value === "+") {
                  setCurrentPage((prev) => prev + 1);
                  return;
                }
                if (value === "-") {
                  setCurrentPage((prev) => (prev > 1 ? prev - 1 : 1));
                  return;
                }
                setCurrentPage(+value);
              }
            }}
          />
        </div>
      ) : null}
      {/* MODALE VARIABILE */}
      <Modal visible={modalData.visible}>
        <ModalCard
          title={t(modalData.title as string)}
          className={
            modalData.content === "viewrate"
              ? "lg:w-2/3 w-full"
              : "lg:w-1/2 w-full"
          }
        >
          <ModalSection>
            <div ref={topRef}></div>
            <PageWrapper>{getContent(modalData.content as string)}</PageWrapper>
            <ModalActions
              onClose={() => {
                if (modalData.content === "viewSettings") {
                  toast.error(
                    t("channelManager.rate_plan.saveSettingToProceed")
                  );
                } else {
                  setModalData({
                    content: null,
                    title: null,
                    visible: false,
                    id: null,
                  });
                }
              }}
              closeLabel={t("general.close")}
            />
          </ModalSection>
        </ModalCard>
      </Modal>
      {/* MODALE VARIABILE */}
    </>
  );
};

export default AparmentsCM;
