import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BreadcrumbState, BreadItem } from "../../types";

const navigationSlice = createSlice({
    name: 'navigationSlice',
    initialState: {
        breadcrumbs: [],
        mobileMenu: false
    } as BreadcrumbState,
    reducers: {
        setBreadcrumbs: (state, action: PayloadAction<BreadItem[]>) => {
            state.breadcrumbs = action.payload
        },
        setMobileMenu: (state, action: PayloadAction<boolean>) => {
            state.mobileMenu = action.payload
        }
    }
})

export const { setBreadcrumbs, setMobileMenu } = navigationSlice.actions

export default navigationSlice.reducer