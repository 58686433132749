import { motion } from "framer-motion"
import React from "react"
import styled from "styled-components"
import { DomainContext } from "../../DomainContext"
import ProgressBar from "../ProgressBar"

const BackgroundWrapper = styled(motion.div)`
  z-index: 9999;
  background-color: #FFF;
`

const BackgroundAnimation = styled.div`
  animation-name: color;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  z-index: 9999;
  background-color: #FFF;
  position: absolute;
  width: 350px;
  border-radius: 100%;
  height: 350px;
  z-index: 40;

  @keyframes color {
    0% {
      background-color: #FFF;
      transform: scale(1);
    }
    50% {
      background-color: #f0fafc;
      transform: scale(1.1);
    }
    100 {
      background-color: #FFF;
      transform: scale(1);
    }
  }
`

export default function LoadingOverlay () {
    
  const {appLogo, appName} = React.useContext(DomainContext);
    return (
        <BackgroundWrapper
          initial={{
            opacity: 1
          }}
          exit={{
            opacity: 0
          }}
          className={'fixed top-0 left-0 w-screen h-screen flex items-center justify-center'}>
          <BackgroundAnimation />
          <div className={'z-50 relative flex flex-col items-center'}>
            <img
              src={appLogo}
              alt={appName + " logo"}
              className={'h-16 ml-4 inline-block'} />
            
            <ProgressBar className={'mt-4 w-32'} />
          </div>
        </BackgroundWrapper>
    )

}