import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import Modal from "../Modal"
import ModalActions from "../ModalAction"
import ModalCard from "../ModalCard"
import ModalSection from "../ModalSection"
import TextInput from '../TextInput'
import FormField from '../FormField'
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify";



type AddCreditCardProps = {
  visible: boolean,
  onClose: () => void,
  onSave: () => void,
  onSource?: (data: any) => void
}

export default function AddCreditCard ({
  visible,
  onClose,
  onSource,
  onSave
} : AddCreditCardProps) {

  const { t } = useTranslation()

  const [cardName, setCardName] = useState('')

  const stripe = useStripe()
  const elements = useElements()

  async function storeCard () {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet.
      return
    }

    const cardElement = elements.getElement(CardElement)

    if (cardElement) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement
      })
      if (error){ 
        toast.error(error.message)
        return
      }
      if (onSource) onSource(paymentMethod)
    }
  }

  return (
    <Modal
      visible={visible}>
      <ModalCard
        className={'w-full lg:max-w-4xl'}
        title={t('addCard.addPaymentCard')}>
        <ModalSection>
          <div className={'flex flex-col space-y-4 p-4'}>
      
            <FormField
              label={t('addCard.nameOnCard')}>
              <TextInput value={cardName} onChange={val => setCardName(val)} />
            </FormField>

            <FormField
              label={t('addCard.cardData')}>
              <div className={'border rounded-2xl p-2'}>
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#424770',
                        '::placeholder': {
                          color: '#aab7c4',
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }}
                />
              </div>
            </FormField>
            
          </div>
        </ModalSection>
        <ModalActions
          isLoading={false}
          saveLabel={t('general.save')}
          onClose={() => {
            if (onClose) onClose()
          }}
          onSave={() => {
            storeCard()
          }}></ModalActions>
      </ModalCard>
    </Modal>
  )

}