import { useHistory } from "react-router-dom"
import Icon from "./Icon"
import {useDispatch} from "react-redux";
import {setMobileMenu} from "../features/login/navigationSlice";

type MenuItemProps = {
  className?: string,
  icon: string,
  label: string,
  active?: boolean,
  mini?: boolean,
  to?: string,
  sided?: boolean,
}

export default function MenuItem ({
  className,
  icon,
  label,
  active,
  sided = false,
  mini = false,
  to,
} : MenuItemProps) {

  const dispatch = useDispatch()

  const history = useHistory()

  function getStatusClass () {
    if (active) {
      return 'bg-cyan-100 text-cyan-600'
    } else {
      return 'text-gray-500 hover:bg-gray-200'
    }
  }

  function getWidth () {
    if (mini) {
      return 'transition-all w-16'
    } else {
      return 'transition-all w-52'
    }
  }

  return (
    <div
      onClick={() => {
        dispatch(setMobileMenu(false))
        if (to) history.push(to)
      }}
      className={getWidth()}>
      <div
        className={`h-10 cursor-pointer px-4 py-2 rounded-br-full rounded-tr-full flex items-center pl-7 ${getStatusClass()} ${className}`}>
        <div className={'mr-4'}>
          <Icon name={icon} size={'20px'} />
        </div>
        {
          !mini &&
          <div className={'text-sm font-normal flex justify-between gap-5'}>
            <div>
              {label} 
            </div>{sided && <div className="text-red-600 font-bold text-xs">NEW</div>}
          </div>
        }
      </div>
    </div>
  )

}