import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRuleDevices } from "../../features/rule/ruleSlice";
import { RootState } from "../../reducers";
import { BaseSelectItem, DomoticDevice, LocalTable } from "../../types";
import SelectInput from "../SelectInput";
import { useTranslation } from "react-i18next";

export default function RuleManagerDevice ({
    value,
    onlyCommandDevices = false,
    onChange
} : {
    onlyCommandDevices: boolean,
    value: {
        localKey: string | undefined,
        deviceMac: string | undefined
    },
    onChange: (local: LocalTable, device?: DomoticDevice) => void
}) {

    const dispatch = useDispatch()
    const { t } = useTranslation()

    const locals = useSelector((state: RootState) => state.rule.locals)
    const devices = useSelector((state: RootState) => state.rule.devices)
    const loadings = useSelector((state: RootState) => state.rule.loading)

    const [localOption, setLocalOption] = useState<BaseSelectItem>()
    const [deviceOption, setDeviceOption] = useState<BaseSelectItem>()
    const [deviceList, setDeviceList] = useState<{ label: string, value: string}[]>([])

    useEffect(() => {
        if (localOption) {
            if (!devices[localOption.value]) {
                dispatch(fetchRuleDevices(
                    localOption.value
                ))
            }
        }
    }, [ localOption ])

    useEffect(() => {
        if (locals && !localOption) {
            const selectedLocal = locals.find(local => local.local_key === value.localKey)
            if (selectedLocal) {
                setLocalOption({
                    label: selectedLocal.name,
                    value: selectedLocal.local_key
                })
            }
        }
    }, [ value, locals ])

    useEffect(() => {
        if (value && value.localKey && devices && !deviceOption && devices[value.localKey]) {
            const selectedDevice = devices[value.localKey].find(device => device.device_mac === value.deviceMac)
           if (selectedDevice) {
               setDeviceOption({
                   label: selectedDevice.device_name,
                   value: selectedDevice.device_mac
               })
           }
        }
    }, [ value, devices ])

    useEffect(() => {
        if (localOption && devices[localOption.value]) {
            let items = devices[localOption.value]
            if (items) {
                if (onlyCommandDevices) {
                    items = items.filter((i: any) => {
                        return i.entities.find((e: any) => e.commands && e.commands.length > 0)
                      })
                }
                setDeviceList(
                    items
                    .map(device => {
                        return {
                            label: (device.model_identifiers.indexOf("vikey_tool") === -1) ? device.device_name : t("deviceTypes." + device.device_name),
                            value: device.device_mac
                        }
                    })
                )
            }
        } else {
            setDeviceOption(undefined)
            setDeviceList([])
        }
    }, [ localOption, devices ])

    function handleChange () {
        let selectedLocal = undefined as LocalTable | undefined
        let selectedDevice = undefined as DomoticDevice | undefined
        if (locals && localOption && deviceOption) {
            selectedLocal = locals.find(l => l.local_key === localOption.value) as LocalTable | undefined
            selectedDevice = undefined as DomoticDevice | undefined
            if (selectedLocal && devices[selectedLocal.local_key]) {
                selectedDevice = devices[selectedLocal.local_key].find(d => {
                    return d.device_mac === deviceOption.value
                })
            }
        }

        if (selectedLocal) {
            onChange(selectedLocal, selectedDevice)
        }
    }

    useEffect(() => {
        handleChange()
    }, [ localOption, deviceOption ])

    return (
        <div className="bg-cyan-50 border-l-4 border-cyan-600 py-2 px-4 cursor-pointer">
            <div className="flex items-center space-x-4 mb-2">
                <div className={'w-36 font-medium text-sm'}>{t('reservations.apartment')}</div>
                <div className={'flex-1'}>
                    <SelectInput<BaseSelectItem, false>
                        isLoading={loadings.locals}
                        value={localOption}
                        options={locals.map(l => {
                            return {
                                label: l.name,
                                value: l.local_key
                            }
                        })}
                        onChange={(item: any) => {
                            setDeviceOption(undefined)
                            if (item) setLocalOption(item)
                        }} />
                </div>
            </div>

            <div className="flex items-center space-x-4">
                <div className={'w-36 font-medium text-sm'}>{t('rules.device')}</div>
                <div className={'flex-1'}>
                <SelectInput<BaseSelectItem, false>
                    value={deviceOption}
                    isDisabled={deviceList.length === 0}
                    isLoading={loadings.devices}
                    onChange={item => {
                        if (item) {
                            setDeviceOption(item)
                        }
                    }}
                    options={deviceList} />
                </div>
            </div>
        </div>
    )

}