import { useEffect, useState } from "react" 
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { deleteIntegrationLocals } from "../../shared/queries"
import { IntegrationApartment } from "../../types"
import Icon from "../Icon"
import AddIntegration from "./AddIntegration"

type ApartmentConnectionItemProps = {
  integration: IntegrationApartment,
  remoteIntegartions: any[],
  userKey?: string,
  onEdit?: (id: number) => void,
  onAdd: () => void,
  onSave: () => void
}
export default function ApartmentConnectionItem ({
  integration,
  remoteIntegartions,
  userKey,
  onEdit,
  onAdd,
  onSave
} : ApartmentConnectionItemProps) {

  const { t } = useTranslation()

  const [editIntegration, setEditIntegration] = useState(false)
  const [currentConnection, setCurrentConnection] = useState<any>()
  const [contract, setContract] = useState('No contract')
  const [email, setEmail] = useState('Default email')
  const [checkin, setCheckin] = useState('Default checkin')

  const mutation = useMutation(() => deleteIntegrationLocals({
    id: currentConnection?.links[0]?.id,
    integr_user_id: currentConnection?.integr_user_id,
    local_key: currentConnection?.links[0]?.local?.local_key,
    ref: currentConnection?.ref
  }), {
    onSuccess: () => {
      toast.success(t('general.operationCompleted'))
      if (onSave) onSave()
    },
    onError: (error: Error) => {
      toast.error(t(error.message) || t('general.requestError'))
    }
  })

  // const {mutate: importResMut, isLoading: isLoadingImportResMut}= useMutation(() => importAllReservations({
  //   action: 'import_all',
  //   user_key: userKey,
  //   integr_user_id: currentConnection?.integr_user_id,
  //   prop_ref: currentConnection?.ref
  // }), {
  //   onSuccess: () => {
  //     toast.success(t('general.operationCompleted'))
  //   },
  //   onError: () => {
  //     toast.error(t('general.requestError'))
  //   }
  // })

  useEffect(() => {
    if (currentConnection?.links && currentConnection?.links[0]?.e_objkey != null && currentConnection?.links[0]?.e_objkey != ''){
      setEmail(currentConnection?.links[0]?.e_objkey)
    }
    if (currentConnection?.links && currentConnection?.links[0]?.k_objkey != null && currentConnection?.links[0]?.k_objkey != ''){
      setCheckin(currentConnection?.links[0]?.k_objkey)
    }
    if (currentConnection?.links && currentConnection?.links[0]?.c_objkey != null && currentConnection?.links[0]?.c_objkey != ''){
      setContract(currentConnection?.links[0]?.c_objkey)
    }
  }, [currentConnection])

  useEffect(() => {
    setCurrentConnection(remoteIntegartions.find(r => r.ref === integration.ref))
  }, [ integration, remoteIntegartions ])

  return (
    <div>
      <div className="flex flex-col lg:flex-row mb-3 items-center -mx-2">
        <div className="w-full lg:w-1/2 px-2">
          <div className={'bg-green-100 rounded-2xl'}>
            <div className={'flex flex-wrap items-center justify-between px-4 p-2 text-green-800 font-medium'}>
              <div>
                {integration.name}
              </div>
              <div className={'mt-2 xl:mt-0 text-sm hidden md:block'}>
                EXT: {integration.ref} <span className={'opacity-50'}>|</span> INT: {integration.id}
              </div>
            </div>

            <div className="p-2 px-4">
                <div className="flex flex-wrap items-center justify-between">
                  <div className={'hidden flex-wrap md:flex'}>
                  <div className={'text-medium text-green-100'}>N</div>
              {/* <button
                disabled={isLoadingImportResMut}
                hidden={true}
                onClick={() => importResMut()}
                className={'font-medium text-green-800 bg-green-200 px-2 py-1 rounded-2xl hover:bg-green-300 focus:outline-none'}>{t('integrationPage.importReservation')}</button> */}
            </div>
          </div>
            </div>
          </div>
        </div>
        <div className={'lg:rotate-0 rotate-90 transform px-6 text-gray-400 font-medium'}>
          ↔
        </div>
        <div className="w-full lg:w-1/2 px-2">
          {
            currentConnection?.links?.length > 0 ?
            <div className={'bg-cyan-100 rounded-2xl'}>
              <div className="flex flex-wrap p-2 px-4 items-center justify-between">
                <div className="font-medium text-cyan-800">
                  {currentConnection?.links[0]?.local?.name}
                </div>
                <div className={'hidden md:block'}>
                  <div className="flex flex-wrap mt-2 xl:mt-0">
                    <div data-tip={'Email'} className={'px-1 text-cyan-700'}>
                      <Icon name={'mail'} size={'15px'}></Icon>
                    </div>
                    <div data-tip={'SMS remind'} className={'px-1 text-cyan-700'}>
                      <Icon name={'chat-alt'} size={'15px'}></Icon>
                    </div>
                    <div data-tip={'IDs'} className={'px-1 text-cyan-700'}>
                      <Icon name={'identification'} size={'15px'}></Icon>
                    </div>
                    <div data-tip={'Booker photo'} className={'px-1 text-cyan-700'}>
                      <Icon name={'camera'} size={'15px'}></Icon>
                    </div>
                    <div data-tip={'Contract'} className={'px-1 text-cyan-700'}>
                      <Icon name={'document-text'} size={'15px'}></Icon>
                    </div>
                    <div data-tip={'OTP'} className={'px-1 text-cyan-700'}>
                      <Icon name={'finger-print'} size={'15px'}></Icon>
                    </div>
                    <div data-tip={'Invoice'} className={'px-1 text-cyan-700'}>
                      <Icon name={'receipt-tax'} size={'15px'}></Icon>
                    </div>
                    <div data-tip={'City tax'} className={'px-1 text-cyan-700'}>
                      <Icon name={'scale'} size={'15px'}></Icon>
                    </div>
                    <div data-tip={'Stay payment'} className={'px-1 text-cyan-700'}>
                      <Icon name={'credit-card'} size={'15px'}></Icon>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-2 px-4">
                <div className="flex flex-wrap items-center justify-between">
                  <div className={'hidden flex-wrap md:flex'}>
                    <div className={'text-xs bg-cyan-200 mr-2 rounded-2xl p-1 font-medium px-2 truncate flex-1'}>{contract}</div>
                    <div className={'text-xs bg-cyan-200 mr-2 rounded-2xl p-1 font-medium px-2 truncate flex-1'}>{checkin}</div>
                    <div className={'text-xs bg-cyan-200 mr-2 rounded-2xl p-1 font-medium px-2 truncate flex-1'}>{email}</div>
                  </div>
                  <div className={'mt-2 xl:mt-0'}>
                    <button
                      onClick={() => {
                        setEditIntegration(true)
                      }}
                      className={'font-medium text-cyan-800 mr-2'}>{t('general.edit')}</button>
                    <button
                      onClick={() => mutation.mutate()}
                      className={'font-medium text-red-600'}>{t('general.delete')}</button>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className={'text-center bg-gray-100 border rounded-2xl p-2 px-4'}>
              <div className={'text-sm'}>
                {t('integration.noConnections')}
              </div>
              <button
                onClick={() => {
                  if (onAdd) onAdd()
                }}
                className={'bg-cyan-600 text-white text-sm rounded-2xl px-3 py-1 mt-2'}>
                {t('integrationPage.addIntegration')}
              </button>
            </div>
          }
        </div>
      </div>

      <AddIntegration
        integrationId={integration.integr_user_id.toString()}
        presetIntegration={currentConnection}
        refId={(currentConnection) ? currentConnection.ref : ''}
        visible={editIntegration}
        onSave={() => {
          if (onSave) onSave()
        }}
        onClose={() => {
          setEditIntegration(false)
        }} />
    </div>

  )

}