import { request } from "../../shared/utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import {
  LoginAction,
  LoginResponse,
  ResetPasswordAction,
  ResetPasswordFromEmailAction,
  ResetResponse,
  SliceError,
  User,
} from "../../types";
import {
  getRefreshToken,
  saveRefreshToken,
  saveAuthToken,
  saveOldToken,
  saveAccountList,
  getAccountList,
} from "../../shared/utils/tokenStore";
import i18n from "../../langs";

interface LoginState {
  loading: "idle" | "pending";
  currentRequestId: string | undefined;
  profile: User | undefined;
  error: string | object | undefined;
}

export const attemptLogin = createAsyncThunk<
  User,
  LoginAction,
  {
    rejectValue: SliceError;
  }
>("login/attemptLogin", async (credentials, thunkApi) => {
  const response = await request({
    method: "POST",
    body: {
      email: credentials.username,
      password: credentials.password,
    },
    path: "/api/v3/auth/login",
  });

  if (!response.ok || response.status === 401) {
    return thunkApi.rejectWithValue((await response.json()) as SliceError);
  }
  const data = (await response.json()) as LoginResponse;
  if (getAccountList()) {
    const account: any[] = JSON.parse(getAccountList() as string);
    const found = account.find((acc: any) => acc.id === data.user.user_key);
    if (!found) {
      account.push({
        id: data.user.user_key,
        email: data.user.mail,
        authToken: data.access_token,
        oldToken: data.old_token,
        refreshToken: data.refresh_token,
        name: data.user.name,
        role: data.user.role,
      });
      saveAccountList(account);
    }
  } else {
    saveAccountList([
      {
        id: data.user.user_key,
        email: data.user.mail,
        authToken: data.access_token,
        oldToken: data.old_token,
        refreshToken: data.refresh_token,
        name: data.user.name,
        role: data.user.role,
      },
    ]);
  }

    const tokens: any[] = data.access_token.match(/.{1,200}/g) ?? [];
    window.Intercom("update", {
      hide_default_launcher: false,
      "language_override": i18n.language
    });
    window.Intercom("boot", {
      app_id: "ux6abiod",
      name: data.user.name,
      email: data.user.mail,
      user_id: data.user.user_key,
      token1: `Bearer ${tokens[0]}`,
      token2: tokens[1],
      token3: tokens[2],
      is_shop: data.user.user_key === 'VT1xfjPoskpbCWGj0000000000000000' ? true : data.user?.shop,
      admin_user_key: data.user?.admin_user_key
    });

  saveAuthToken(data.access_token);
  saveOldToken(data.old_token);
  saveRefreshToken(data.refresh_token);
  Sentry.setUser({ id: data.user.user_key, email: data.user.mail });
  return data.user as User;
});

export const attemptAdminLogin = createAsyncThunk<
  User,
  LoginAction,
  {
    rejectValue: SliceError;
  }
>("login/attemptAdminLogin", async (credentials, thunkApi) => {
  const response: any = await request({
    method: "POST",
    body: {
      user_key: credentials.username,
      apitoken: credentials.password,
      admin_user_key:credentials.admin,
    },
    path: "/api/v3/auth/loginadmin",
  });

  if (!response.ok || response.status === 401) {
    return thunkApi.rejectWithValue((await response.json()) as SliceError);
  }
  const data = (await response.json()) as LoginResponse;
  if (getAccountList()) {
    const account: any[] = JSON.parse(getAccountList() as string);
    const found = account.find((acc: any) => acc.id === data.user.user_key);
    if (!found) {
      account.push({
        id: data.user.user_key,
        email: data.user.mail,
        authToken: data.access_token,
        oldToken: data.old_token,
        refreshToken: data.refresh_token,
        name: data.user.name,
        role: data.user.role,
      });
      saveAccountList(account);
    }
  } else {
    saveAccountList([
      {
        id: data.user.user_key,
        email: data.user.mail,
        authToken: data.access_token,
        oldToken: data.old_token,
        refreshToken: data.refresh_token,
        name: data.user.name,
        role: data.user.role,
      },
    ]);
  }

    const tokens: any[] = data.access_token.match(/.{1,200}/g) ?? [];
    window.Intercom("update", {
      hide_default_launcher: false,
      "language_override": i18n.language
    });
    window.Intercom("boot", {
      app_id: "ux6abiod",
      name: data.user.name,
      email: data.user.mail,
      user_id: data.user.user_key,
      token1: `Bearer ${tokens[0]}`,
      token2: tokens[1],
      token3: tokens[2],
      is_shop: data.user.user_key === 'VT1xfjPoskpbCWGj0000000000000000' ? true : data.user?.shop,
      admin_user_key: data.user?.admin_user_key
    });
  saveAuthToken(data.access_token);
  saveOldToken(data.old_token);
  saveRefreshToken(data.refresh_token);
  return data.user as User;
});

export const refreshAuth = createAsyncThunk<
  User,
  LoginAction,
  {
    rejectValue: SliceError;
  }
>("login/refreshAuth", async ({}, thunkApi) => {
  const response = await request({
    method: "POST",
    body: {},
    headers: [
      {
        key: "Authorization",
        content: "Bearer " + getRefreshToken(),
      },
    ],
    path: "/api/v3/auth/refresh",
  });

  if (!response.ok || response.status === 401) {
    return thunkApi.rejectWithValue((await response.json()) as SliceError);
  }
  const data = await response.json();
  if (getAccountList()) {
    const account: any[] = JSON.parse(getAccountList() as string);
    const found = account.find((acc: any) => acc.id === data.user.user_key);
    if (!found) {
      account.push({
        id: data.user.user_key,
        email: data.user.mail,
        authToken: data.access_token,
        oldToken: data.old_token,
        refreshToken: data.refresh_token,
        name: data.user.name,
        role: data.user.role,
      });
      saveAccountList(account);
    }
  } else {
    saveAccountList([
      {
        id: data.user.user_key,
        email: data.user.mail,
        authToken: data.access_token,
        oldToken: data.old_token,
        refreshToken: data.refresh_token,
        name: data.user.name,
        role: data.user.role,
      },
    ]);
  }

    const tokens: any[] = data.access_token.match(/.{1,200}/g) ?? [];
    window.Intercom("update", {
      hide_default_launcher: false,
      "language_override": i18n.language
    });
    window.Intercom("boot", {
      app_id: "ux6abiod",
      name: data.user.name,
      email: data.user.mail,
      user_id: data.user.user_key,
      token1: `Bearer ${tokens[0]}`,
      token2: tokens[1],
      token3: tokens[2],
      is_shop: data.user.user_key === 'VT1xfjPoskpbCWGj0000000000000000' ? true : data.user?.shop,
      admin_user_key: data.user?.admin_user_key
    });
  saveAuthToken(data.access_token);
  saveOldToken(data.old_token);
  saveRefreshToken(data.refresh_token);
  Sentry.setUser({ id: data.user.user_key, email: data.user.mail });
  return data.user as User;
});

export const resetPassword = createAsyncThunk<
  ResetResponse,
  ResetPasswordAction,
  {
    rejectValue: SliceError;
  }
>("login/resetPassword", async (reset, thunkApi) => {
  const response = await request({
    method: "POST",
    body: {
      action: "resetpass",
      email: reset.email,
    },
    path: "/api/v3/user/token",
  });

  if (!response.ok || response.status === 401) {
    return thunkApi.rejectWithValue((await response.json()) as SliceError);
  }
  const data = (await response.json()) as ResetResponse;
  return data;
});

export const resetFromTokenPassword = createAsyncThunk<
  ResetResponse,
  ResetPasswordFromEmailAction,
  {
    rejectValue: SliceError;
  }
>("login/resetPassword", async (reset, thunkApi) => {
  const response = await request({
    method: "POST",
    body: {
      action: "confirmpass",
      token: reset.token,
      password: reset.password,
    },
    path: "/api/v3/user/token",
  });

  if (!response.ok || response.status === 401) {
    return thunkApi.rejectWithValue((await response.json()) as SliceError);
  }
  const data = (await response.json()) as ResetResponse;
  return data;
});

const loginSlice = createSlice({
  name: "login",
  initialState: {
    loading: "idle",
    profile: undefined,
    currentRequestId: undefined,
    error: undefined,
  } as LoginState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(attemptLogin.pending, (state, action) => {
      state.loading = "pending";
      state.error = undefined;
      console.debug("[SLICE-LOGIN-PENDING]", action);
    });
    builder.addCase(attemptLogin.fulfilled, (state, action) => {
      state.loading = "idle";
      state.error = undefined;
      state.profile = action.payload;
      console.debug("[SLICE-LOGIN-IDLE]", action);
    });
    builder.addCase(attemptLogin.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
      console.debug("[SLICE-LOGIN-REJECTED]", action);
    });
    builder.addCase(attemptAdminLogin.pending, (state, action) => {
      state.loading = "pending";
      state.error = undefined;
      console.debug("[SLICE-LOGIN-PENDING]", action);
    });
    builder.addCase(attemptAdminLogin.fulfilled, (state, action) => {
      state.loading = "idle";
      state.error = undefined;
      state.profile = action.payload;
      console.debug("[SLICE-LOGIN-IDLE]", action);
    });
    builder.addCase(attemptAdminLogin.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
      console.debug("[SLICE-LOGIN-REJECTED]", action);
    });
    // refresh
    builder.addCase(refreshAuth.pending, (state, action) => {
      state.loading = "pending";
      state.error = undefined;
      console.debug("[SLICE-LOGIN-PENDING]", action);
    });
    builder.addCase(refreshAuth.fulfilled, (state, action) => {
      state.loading = "idle";
      state.error = undefined;
      state.profile = action.payload;
      console.debug("[SLICE-LOGIN-IDLE]", action);
    });
    builder.addCase(refreshAuth.rejected, (state, action) => {
      state.loading = "idle";
      state.error = undefined;
      console.debug("[SLICE-LOGIN-REJECTED]", action);
    });
  },
});

export default loginSlice.reducer;
