import Joi from 'joi'

export default Joi.object({
  id: Joi.string(),
  cats: Joi.string().required().min(3).required().messages({
    'string.base': 'Devi selezionare almeno una categoria',
    'string.empty': 'Devi selezionare almeno una categoria',
    'string.min': 'Devi selezionare almeno una categoria'
  }),
  title: Joi.string().required().messages({
    'string.base': 'Titolo obbligatorio',
    'string.required': 'Titolo obbligatorio',
    'string.empty': 'Titolo obbligatorio'
  }),
  amount_tot: Joi.number().required().messages({
    'number.base': 'Totale non valido',
    'number.required': 'Totale non valido'
  }),
  user_key: Joi.string().required().messages({
    'string.base': 'Account obbligatorio',
    'string.required': 'Account obbligatorio',
    'string.empty': 'Account obbligatorio'
  }),
  type: Joi.any(),
  mandatory: Joi.any(),
  notifemails: Joi.any(),
  chargeguest: Joi.any(),
  email_to_guest: Joi.any()
})
