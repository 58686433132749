export const calendarIntegrations = [{
    key: "BOOKING",
    cat: ["OTA"],
    name: "Booking.com",
    href: "https://www.booking.com?utm_source=vikey",
    logo: "images/logos/bookinglogo.png",
    token: "HotelID",
    secret: "ConnectionID",
    oAuth: true,
    desc: null,
    ready: true,
    new: false,
    modes: [
      { k: "ROOMS", c: "blue", i: "bed" },
      { k: "HOTEL", c: "blue", i: "home" },
    ],
  },
  {
    key: "AIRBNB",
    cat: ["OTA"],
    name: "Airbnb",
    href: "https://www.airbnb.com?utm_source=vikey",
    logo: "images/logos/airbnb.png",
    token: false,
    secret: false,
    oAuth: true,
    desc: "integrationWizard.AirBnbDesc",
    ready: true,
    new: false,
    modes: [{ k: "ROOMS", c: "blue", i: "bed" }],
  },
  {
    key: "VRBO",
    cat: ["OTA"],
    name: "Airbnb",
    href: "https://www.vrbo.com?utm_source=vikey",
    logo: "images/logos/vrbo.png",
    token: false,
    secret: false,
    oAuth: true,
    desc: null,
    ready: true,
    new: false,
    modes: [{ k: "ROOMS", c: "blue", i: "bed" }],
  },
]