import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { MessageAttachments } from "../../types";
import CircleSpinner from "../CircleSpinner";
import { CarouselModal } from "../ImageViewCarousel";
import Icon from "../Icon";

interface AttachmentsProps {
  attachments: MessageAttachments[];
  color: "white" | "primary" | "negative" | "success" | "info" | undefined;
  onClickImage: (relative_path: string) => void;
}

const Attachments: React.FC<AttachmentsProps> = ({
  attachments,
  onClickImage,
  color,
}) => {
  const { t } = useTranslation();
  const remainingCount = attachments.length - 4;
  const [isCarouselVisible, setCarouselVisible] = useState<[boolean, number]>([false, 0]);

  const openCarousel = (index?: number) => {
    setCarouselVisible([true, index || 0]);
  };

  const closeCarousel = () => {
    setCarouselVisible([false, 0]);
  };

  // Function to check if the attachment is an image based on mime type
  const isImage = (mimeType: string) => {
    return mimeType.startsWith('image/');
  };

  const renderAttachments = (item: MessageAttachments, index: number) => {
    if (index === 3 && remainingCount > 0) {
      return (
        <div
          key={index}
          className="hover:shadow-lg"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #ccc",
            margin: "5px",
            padding: "10px",
            borderRadius: "8px",
          }}
        >
          <button
            style={{
              fontSize: "18px",
              background: "none",
              border: "none",
              cursor: "pointer",
            }}
            onClick={() => openCarousel()}
          >
            +{remainingCount + 1}
          </button>
        </div>
      );
    }

    // If it's an image, render the image
    if (item.relative_path && !item.is_fetching && isImage(item.file_mime_type)) {
      return (
        <div
          key={item.id}
          className="hover:shadow-lg"
          style={{
            margin: "5px",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <img
            src={item.relative_path}
            alt={item.file_name}
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={() => openCarousel(index)}
          />
        </div>
      );
    }

    // For non-image attachments, show a placeholder with a download button
    return (
      <div
        key={item.id}
        className="hover:shadow-lg"
        style={{
          margin: "5px",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <div style={{ textAlign: "center" }}>
          {item.is_fetching ? (
            <CircleSpinner color={color} />
          ) : (
            <div style={{
                display: "flex", 
                alignItems: "center", 
                gap: "8px", 
                flexWrap: "wrap",  
                justifyContent: "space-between", 
                maxWidth: "100%", 
              }}>
                
              
                {/* Download Button */}
                <a
                  href={item.relative_path}
                  download
                  style={{
                    display: "inline-block",
                    marginLeft: "auto",  
                    padding: "8px",
                    backgroundColor: "whitesmoke",
                    borderRadius: "4px",
                    textDecoration: "none",
                    fontSize: "14px",
                    alignItems: "center",
                    justifyContent: "center", 
                  }}
                >
                    {/* Clip Icon */}
                <Icon name={item.file_mime_type}  size="80px" />

                
                </a>
              </div>
              
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          display: attachments.length > 1 ? "grid" : "",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "10px",
          maxWidth: "400px",
          margin: "auto",
        }}
      >
        {attachments.slice(0, 4).map((item, index) =>
          renderAttachments(item, index)
        )}
      </div>

      <CarouselModal
        visible={isCarouselVisible[0]}
        onClose={closeCarousel}
        attachments={attachments}
        index={isCarouselVisible[1]}
      />
    </>
  );
};

export default Attachments;
