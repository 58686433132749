import { useEffect, useState } from 'react';
import { useMutation } from "react-query"
import { useQuery } from 'react-query';
import {
  fetchCredsGuardiaCivil,
  fetchCredsMossos,
  fetchCredsMoTo,
  fetchPerms,
  fetchCredsPoliciaNational,
  fetchCredsPortaleAlloggiati,
  deleteCredsPortaleAlloggiati,
  deleteCredsMossos,
  deleteCredsGuardiaCivil,
  deleteCredsPoliciaNacional,
  deleteCredsMotourist,
  fetchCredsRossMille,
  deleteCredsRossMille,
  fetchCredsSef,
  deleteCredsSef,
  fetchCredsRadar,
  deleteCredsRadar,
  fetchCredsUby,
  deleteCredsUby,
  fetchCredsPaytourist,
  deleteCredsPaytourist,
  fetchCredsIstatTrento,
  deleteCredsIstatTrento,
  fetchCredsIstatSicilia,
  deleteCredsIstatSicilia,
  fetchCredsSesHospedajes,
  deleteCredsSesHospedajes
} from '../../shared/queries';
import BaseTable from '../BaseTable';
import AddBureaucracyCreds from './AddCredentials';
import { useTranslation } from 'react-i18next';
import AssignApartments from './AssignApartments';
import Icon from '../Icon';
import DeleteDialog from "../../components/DeleteDialog";
import { toast } from "react-toastify"
import Button from '../Button';
import { BureaucracyIstatTrento, BureaucracyPaytourist } from '../../types';
import { getHumanDateFromISO } from '../../shared/utils/formatting';


type CredentialListProps = {
  type: string;
  disableButton: boolean,
};

export default function CredentialList({ type, disableButton }: CredentialListProps) {
  function getTypeTitle(type: any) {
    switch (type) {
      case 'portale-alloggiati':
        return 'Portale Alloggiati';
      case 'ross1000':
        return 'Ross 1000';
      case 'radar':
        return 'Radar';
      case 'sef':
        return 'Sef';
      case 'policia-nacional':
        return 'Policía Nacional';
      case 'mossos-esquadra':
        return "Mossos d'Esquadra";
      case 'guardia-civil':
        return 'Guardia Civil';
      case 'ses-hospedajes':
          return 'SES.Hospedajes';
      case 'motourist':
        return 'MOTouristOffice';
      case 'uby':
        return 'Uby';
      case 'paytourist':
        return 'Paytourist';
      case 'istat-trento':
        return 'Istat Trento';
      case 'istat-sicilia':
        return 'Istat Sicilia';
      default:
        break;
    }
  }


  function getIdKey(type: any) {
    switch (type) {
      case 'portale-alloggiati':
        return 'creds_portale_alloggiati_id';
      case 'ross1000':
        return 'turismo5_id';
      case 'radar':
        return 'id';
      case 'uby':
        return 'id';
      case 'sef':
        return 'id';
      case 'policia-nacional':
        return 'policia_nacional_creds_id';
      case 'mossos-esquadra':
        return "mossos_creds_id";
      case 'guardia-civil':
        return 'guardia_civil_creds_id';
      case 'ses-hospedajes':
        return 'hospedajes_creds_id';
      case 'motourist':
        return 'ricestat_creds_id';
      case 'paytourist':
        return 'id';
      case 'istat-trento':
        return 'id';
      case 'istat-sicilia':
        return 'sicilia_creds_id';
      default:
        break;
    }
  }

  function getColums(): any{ 
    var cols = [ 
      {
        Header: 'Account',
        accessor: (row: any) => (
          <>
            {
              <div
                className={
                  'bg-cyan-100 text-cyan-800 text-white inline-block text-xs font-medium px-2 rounded-2xl py-1'
                }
              >
                {(accounts as any)[row.user_key]}
              </div>
            }
          </>
        ),
      },
      {
        Header: t<string>('buro.username'),
        accessor: 'username',
      },
      {
        Header: t<string>('buro.password'),
        accessor: 'password',
      },
      {
        Header: 'Web service key',
        accessor: 'wskey',
      },
      {
        Header: t<string>('buro.status'),
        accessor: (row: any) => (
          <>
            {row.status === 'error' && (
              <span
                className={
                  'bg-red-200 text-red-800 font-medium px-2 py-1 rounded-2xl text-xs'
                }
              >
                {t('general.error')}
              </span>
            )}
            {row.status === 'pending' && (
              <span
                className={
                  'bg-gray-200 text-gray-800 font-medium px-2 py-1 rounded-2xl text-xs'
                }
              >
              {t('apartments.waitingPayment')}
              </span>
            )}
            {row.status === 'ok' && (
              <span
                className={
                  'bg-green-100 font-medium text-green-800 px-2 py-1 rounded-2xl text-xs'
                }
              >
                Ok
              </span>
            )}
          </>
        ),
      },
      {
        Header: 'Azioni',
        accessor: (originalRow: any, rowIndex: any) => (
          <div  className={'flex space-x-2'}>
          <button onClick={id=> {
                var id_key = getIdKey(type);
                if(id_key!==undefined)
                  setCredidToDelete(originalRow[id_key])}}
                className={'focus:outline-none'}>
                <div className={'text-red-600 flex space-x-2'}>
                  <div>
                    <Icon name={'trash'} size={'20px'}></Icon>
                  </div>
                  <div>
                    {t('general.delete')}
                  </div>
                </div>
              </button>
        </div>

        )
      }
    ]
    if(type !== 'portale-alloggiati'){
      cols.splice(3, 1);
    }
    if(type === 'istat-trento'){
      cols.splice(0,5)
      cols.push({
        Header: t<string>('CIPAT'),
        accessor: (row: BureaucracyIstatTrento) => (
          <>{row.accomodation_id}</>
        )
      })
      cols.push({
        Header: t<string>('status'),
        accessor: (row: BureaucracyIstatTrento) => (
          <>{row.status}</>
        )
      })
      cols.push({
        Header: t<string>('date'),
        accessor: (row: BureaucracyIstatTrento) => (
          <>{getHumanDateFromISO(row.date, true)}</>
        )
      })
      cols.push({
        Header: t<string>('date_updated'),
        accessor: (row: BureaucracyIstatTrento) => (
          <>{getHumanDateFromISO(row.date_updated, true)}</>

        )
      })
      cols.push({
        Header: 'Azioni',
        accessor: (originalRow: any, rowIndex: any) => (
          <div  className={'flex space-x-2'}>
          <button onClick={id=> {
                var id_key = getIdKey(type);
                if(id_key!==undefined)
                  setCredidToDelete(originalRow[id_key])}}
                className={'focus:outline-none'}>
                <div className={'text-red-600 flex space-x-2'}>
                  <div>
                    <Icon name={'trash'} size={'20px'}></Icon>
                  </div>
                  <div>
                    {t('general.delete')}
                  </div>
                </div>
              </button>
        </div>
        )
      })
    }
    if(type === 'istat-sicilia'){
      cols.splice(0,5)
      cols.push({
        Header: t<string>('hotel_code'),
        accessor: (row: any) => (
          <>{row.sicilia_creds_id} - {row.hotel_code}</>
        )
      })
      cols.push({
        Header: t<string>('status'),
        accessor: (row: any) => (
          <>{row.status}</>
        )
      })
      cols.push({
        Header: t<string>('date'),
        accessor: (row: any) => (
          <>{getHumanDateFromISO(row.date, true)}</>
        )
      })
      cols.push({
        Header: t<string>('date_updated'),
        accessor: (row: any) => (
          <>{getHumanDateFromISO(row.date_updated, true)}</>
        )
      })
      cols.push({
        Header: 'Azioni',
        accessor: (originalRow: any, rowIndex: any) => (
          <div  className={'flex space-x-2'}>
          <button onClick={id=> {
                var id_key = getIdKey(type);
                if(id_key!==undefined)
                  setCredidToDelete(originalRow[id_key])}}
                className={'focus:outline-none'}>
                <div className={'text-red-600 flex space-x-2'}>
                  <div>
                    <Icon name={'trash'} size={'20px'}></Icon>
                  </div>
                  <div>
                    {t('general.delete')}
                  </div>
                </div>
              </button>
        </div>
        )
      })
    }
    if(type === 'paytourist'){
      cols.splice(0,5)
      cols.push({
        Header: t<string>('ID'),
        accessor: (row: BureaucracyPaytourist) => (
          <>{row.id}</>
        )
      })
      cols.push({
        Header: t<string>('Token'),
        accessor: (row: BureaucracyPaytourist) => (
          <>{row.token}</>
        )
      })
      cols.push({
        Header: t<string>('accomodation_id'),
        accessor: (row: BureaucracyPaytourist) => (
          <>{row.accomodation_id}</>
        )
      })
      cols.push({
        Header: t<string>('Province'),
        accessor: (row: BureaucracyPaytourist) => (
          <>{row?.province?.name}</>
        )
      })
      cols.push({
        Header: 'Azioni',
        accessor: (originalRow: any, rowIndex: any) => (
          <div  className={'flex space-x-2'}>
          <button onClick={id=> {
                var id_key = getIdKey(type);
                if(id_key!==undefined)
                  setCredidToDelete(originalRow[id_key])}}
                className={'focus:outline-none'}>
                <div className={'text-red-600 flex space-x-2'}>
                  <div>
                    <Icon name={'trash'} size={'20px'}></Icon>
                  </div>
                  <div>
                    {t('general.delete')}
                  </div>
                </div>
              </button>
        </div>

        )
      })
    }
    if(type === 'ross1000'){
      cols.splice(4, 1);

      cols.push({
        Header: t<string>('accomodation_id'),
        accessor: 'accomodation_id',
      })
      cols.push({
        Header: t<string>('region_name'),
        accessor: 'region.name',
      })
      cols.splice(0, 1);
      cols.push({
        Header: "Azioni",
        accessor: (originalRow: any, rowIndex: any) => (
          <div className={"flex space-x-2"}>
            <button
              onClick={() => {
                setCredidToDelete(originalRow.id)
              }}
              className={"focus:outline-none"}
            >
              <div className={"text-red-600 flex space-x-2"}>
                <div>
                  <Icon name={"trash"} size={"20px"}></Icon>
                </div>
                <div>{t("general.delete")}</div>
              </div>
            </button>
          </div>
        ),
      });
    }
    if(type === 'radar'){
      cols.splice(4, 1);

      cols.push({
        Header: t<string>('accomodation_id'),
        accessor: 'accomodation_id',
      })
      /* cols.push({
        Header: t<string>('region_name'),
        accessor: 'region.name',
      }) */
      cols.splice(0, 1);
      cols.push({
        Header: "Azioni",
        accessor: (originalRow: any, rowIndex: any) => (
          <div className={"flex space-x-2"}>
            <button
              onClick={() => {
                setCredidToDelete(originalRow.id)
              }}
              className={"focus:outline-none"}
            >
              <div className={"text-red-600 flex space-x-2"}>
                <div>
                  <Icon name={"trash"} size={"20px"}></Icon>
                </div>
                <div>{t("general.delete")}</div>
              </div>
            </button>
          </div>
        ),
      });
    }
    if(type === 'sef'){
      cols = [{
        Header: 'Chiave Acesso',
        accessor: 'chiaveacesso',
      },
      {
        Header: 'Unidade Hoteleira',
        accessor: 'unidadehoteleira',
      },
      {
        Header: t<string>('buro.status'),
        accessor: (row: any) => (
          <>
            {row.status === 'error' && (
              <span
                className={
                  'bg-red-200 text-red-800 font-medium px-2 py-1 rounded-2xl text-xs'
                }
              >
                {t('general.error')}
              </span>
            )}
            {row.status === 'pending' && (
              <span
                className={
                  'bg-gray-200 text-gray-800 font-medium px-2 py-1 rounded-2xl text-xs'
                }
              >
              {t('apartments.waitingPayment')}
              </span>
            )}
            {row.status === 'ok' && (
              <span
                className={
                  'bg-green-100 font-medium text-green-800 px-2 py-1 rounded-2xl text-xs'
                }
              >
                Ok
              </span>
            )}
          </>
        ),
      },
      {
        Header: 'Estabelecimento',
        accessor: 'estabelecimento',
      },{
        Header: 'Azioni',
        accessor: (originalRow: any, rowIndex: any) => (
          <div  className={'flex space-x-2'}>
          <button onClick={id=> {
                var id_key = getIdKey(type);
                if(id_key!==undefined)
                  setCredidToDelete(originalRow[id_key])}}
                className={'focus:outline-none'}>
                <div className={'text-red-600 flex space-x-2'}>
                  <div>
                    <Icon name={'trash'} size={'20px'}></Icon>
                  </div>
                  <div>
                    {t('general.delete')}
                  </div>
                </div>
              </button>
        </div>

        )
      }

      ]
    }
    return cols
  }

  function getCredsQuery(): any {
    switch (type) {
      case 'portale-alloggiati':
        return fetchCredsPortaleAlloggiati;
      case 'ross1000':
        return fetchCredsRossMille;
      case 'radar':
        return fetchCredsRadar;
      case 'uby':
        return fetchCredsUby;
      case 'sef':
        return fetchCredsSef;
      case 'mossos-esquadra':
        return fetchCredsMossos;
      case 'guardia-civil':
        return fetchCredsGuardiaCivil;
      case 'ses-hospedajes':
        return fetchCredsSesHospedajes;
      case 'policia-nacional':
        return fetchCredsPoliciaNational;
      case 'motourist':
        return fetchCredsMoTo;
      case 'paytourist':
        return fetchCredsPaytourist;
      case 'istat-trento':
        return fetchCredsIstatTrento;
      case 'istat-sicilia':
        return fetchCredsIstatSicilia;
      default:
        break;
    }
  }

  function getDeleteCredsQuery(): any {
    switch (type) {
      case "portale-alloggiati":
        return deleteCredsPortaleAlloggiati;
      case "ross1000":
        return deleteCredsRossMille;
      case "radar":
        return deleteCredsRadar;
      case 'sef':
        return deleteCredsSef;
      case 'mossos-esquadra':
        return deleteCredsMossos;
      case "guardia-civil":
        return deleteCredsGuardiaCivil;
      case 'ses-hospedajes':
        return deleteCredsSesHospedajes;
      case "policia-nacional":
        return deleteCredsPoliciaNacional;
      case "motourist":
        return deleteCredsMotourist;
      case "uby":
        return deleteCredsUby;
      case "paytourist":
        return deleteCredsPaytourist;
      case "istat-trento":
        return deleteCredsIstatTrento;
      case "istat-sicilia":
        return deleteCredsIstatSicilia;
      default:
        break;
    }
  }

  const { t } = useTranslation();

  const [credidToDelete, setCredidToDelete] = useState<string | null>(null)



  const {
    data: creds,
    isLoading: isLoadingCreds,
    refetch,
  } = useQuery<any, Error>(['creds'], getCredsQuery(), {
    initialData: [],
  });

  const [addCredentials, setAddCredentials] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [pageData, setPageData] = useState([]);
  const [showAssignAppartments, setShowAssignAppartments] = useState(false);

  const [accounts, setAccounts] = useState<{}>({});

  const { data: data1, isLoading: isLoadingAccount } = useQuery(
    'accounts',
    fetchPerms,
    {
      onSuccess: (data) => {
        const obj = {} as any;
        if (data && data.results) {
          data.results.forEach((account) => {
            obj[account.o_user_key] = account.o_name;
          });
          setAccounts(obj);
        }
      },
    }
  );
  
  const deleteMutation = useMutation(() => getDeleteCredsQuery()(credidToDelete), {
    onSuccess: () => {
      toast.success(t('general.operationCompleted'))
      setCredidToDelete(null)
      if(refetch)
        refetch()
    }
  })
  useEffect(() => {
    if (creds && creds.results) {
      setPageData(
        creds.results.slice((currentPage - 1) * 15, 15 * currentPage)
      );
    }
  }, [creds, currentPage]);

  return (
    <>
    <DeleteDialog
        askConfirmation={true}
        isLoading={deleteMutation.isLoading}
        confirmationKey={t('general.iConfirm')}
        visible={credidToDelete!==null}
        message={""}
        onCancel={() => setCredidToDelete(null)}
        onConfirm={() => {
          deleteMutation.mutate()
        }} />
      <AssignApartments
        channel={type}
        visible={showAssignAppartments === true}
        onCancel={() => setShowAssignAppartments(false)}
      />
      <BaseTable
        currentPage={currentPage}
        pageCount={
          creds
            ? Math.ceil(
                parseInt(creds.results ? creds.results.length : 0 || '0') / 15
              )
            : 0
        }
        identifierKey={t('buro.username')}
        title={t('buro.credentials') + ' ' + getTypeTitle(type)}
        data={pageData}
        loading={isLoadingCreds && isLoadingAccount}
        totalRows={creds?.results ? creds.results.length : 0}
        displayRows={15}
        renderButtons={() => (
          <div className="flex space-x-2 text-sm">
            <Button
              size='small'
              disabled={disableButton}
              onClick={() => setAddCredentials(true)}
              iconSize="20px"
              icon='document-add'
            >
              <span className={'hidden lg:block'}>{t('general.add')}</span>
            </Button>
            <Button
              size='small'
              disabled={disableButton}
              onClick={() => setShowAssignAppartments(true)}
              iconSize="20px"
              icon='link'
            >
              <span className={'hidden lg:block'}>
                {t('paymentMethods.associateApartments')}
              </span>
            </Button>
          </div>
        )}
        onFilterChange={(filters, page) => {
          setCurrentPage(page || 1);
        }}
        filters={[]}
        columns={getColums()}
        onEdit={(id) => {}}
      />

      <AddBureaucracyCreds
        type={type}
        visible={addCredentials}
        onClose={() => {
          setAddCredentials(false);
        }}
        onSave={() => {
          setAddCredentials(false);
          refetch();
        }}
      />
    </>
  );
}
