import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Select from "react-select";
import DatePicker from '../DatePicker'
import { fetchAllLocals, fetchAlloggiatiUser } from "../../shared/queries";
import dayjs from "dayjs";
import SelectInput from "../SelectInput";
import { useTranslation } from "react-i18next";
import { BaseSelectItem } from "../../types";
import FormField from "../FormField";


const exportOptions = [
  { label: 'Singolo appartamento', value: 'SINGLE' },
  { label: 'Multi appartamento', value: 'MULTI' }
]

export default function ExportBuroIta ({
  onChange
} : {
  onChange: (data: any) => void
}) {

  const {
    data: users,
    isFetching: isFetchingUsers
  } = useQuery('alloggiatiUsers', fetchAlloggiatiUser)

  const {
    data: locals,
    isFetching: isFetchingLocals
  } = useQuery('apartments', fetchAllLocals)

  const { t } = useTranslation()


  const [exportDate, setExportDate] = useState<string>(dayjs().format('YYYY-MM-DD'))
  const [type, setType] = useState<string>('SINGLE')
  const [localKey, setLocalKey] = useState<string|undefined>('')
  const [accountKey, setAccountKey] = useState<string|undefined>()

  useEffect(() => {
    onChange({
      action: 'PORTALLOGTXT',
      day: exportDate,
      local_key: localKey,
      singleormulti: type
    })
  }, [
    localKey,
    type,
    exportDate
  ])

  return (
    <div className={'p-4'}>
      <div className="text-lg font-semibold">
      {t('export.pa')}
      </div>

      <div className="mt-4 text-gray-600 text-sm">
            {t('export.desc')}
      </div>

      <div className="mt-6 flex flex-col space-y-4">
        <DatePicker
          value={exportDate}
          inputFormat={'YYYY-MM-DD'}
          outputFormat={'YYYY-MM-DD'}
          displayFormat={'DD-MM-YYYY'}
          onChange={value => setExportDate(value)} />

        <FormField
          label={t('export.paMode')}>
          <SelectInput<BaseSelectItem, false>
            menuPortalTarget={document.body}
            styles={{
              container: base => ({ ...base, zIndex: 999 }),
              menuPortal: base => ({ ...base, zIndex: 999 })
            }}
            menuShouldScrollIntoView={false}
            value={exportOptions.find(o => o.value === type)}
            onChange={item => {
              if (item) setType(item.value)
              if (item && item.value === 'MULTI') setLocalKey(undefined)
            }}
            options={exportOptions} />
        </FormField>

        {
          type === 'SINGLE' &&
          <FormField
            label={t('navigation.apartments')}>
            <SelectInput<BaseSelectItem, false>
              menuPortalTarget={document.body}
              styles={{
                container: base => ({ ...base, zIndex: 999 }),
                menuPortal: base => ({ ...base, zIndex: 999 })
              }}
              menuShouldScrollIntoView={false}
              onChange={item => {
                if (item) setLocalKey(item.value)
              }}
              options={(locals && locals.data) ? locals.data.map(l => {
                return {
                  label: l.name,
                  value: l.local_key
                }
              }) : [] } />
          </FormField>
        }

        {
          type === 'MULTI' &&
          <FormField
            label={t('export.paAccount')}>
            <SelectInput<BaseSelectItem, false>
              menuPortalTarget={document.body}
              styles={{
                container: base => ({ ...base, zIndex: 999 }),
                menuPortal: base => ({ ...base, zIndex: 999 })
              }}
              menuShouldScrollIntoView={false}
              onChange={item => {
                if (item && item.value !== 'NS') setAccountKey(item.value)
                if (item && item.value === 'NS') setAccountKey(undefined)
              }}
              options={(users && users.data) ? [
                ...users.data.map(l => {
                  return {
                    label: l.alloggiati_users || 'Non specificato',
                    value: l.alloggiati_users || 'NS'
                  }
                })
              ] : [] } />
          </FormField>
        }
      </div>
    </div>
  )

}
