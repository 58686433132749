import React from "react";
import FormField from "../FormField";
import TextInput from "../TextInput";
import FormSectionTitle from "../FormSectionTitle";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

type Props = {
  data: any;
  changeData: (property: string, value: any) => void;
};

const EditRoomForm = (props: Props) => {
  const { t } = useTranslation();
  const { data, changeData } = props;
  return (
    <>
      <FormField
        label={t("channelManager.room.roomCount") + ": *"}
        tooltip={t("channelManager.room.roomCountInfo")}
      >
        <TextInput
          type="number"
          disabled
          className="bg-gray-300"
          value={data?.count_of_rooms}
          onChange={(val) => changeData("count_of_rooms", parseFloat(val))}
        ></TextInput>
      </FormField>
      <FormSectionTitle
        title={t("channelManager.room.occupancySettings")}
      ></FormSectionTitle>
      <FormField
        label={t("channelManager.room.adultSpaces") + ": *"}
        tooltip={t("channelManager.room.adultSpacesInfo")}
      >
        <TextInput
          type="number"
          value={data?.occ_adults}
          onChange={(val) => {
            if(parseFloat(val) > 10){
              toast.error(t('channelManager.room.occ_adultsError'))
            }
            changeData("occ_adults", parseFloat(val));
          }}
        ></TextInput>
      </FormField>
      <FormField
        label={t("channelManager.room.childrenSpaces") + ": *"}
        tooltip={t("channelManager.room.childrenSpacesInfo")}
      >
        <TextInput
          type="number"
          value={data?.occ_children}
          onChange={(val) => changeData("occ_children", parseFloat(val))}
        ></TextInput>
      </FormField>
      <FormField
        label={t("channelManager.room.cotSpaces") + ": *"}
        tooltip={t("channelManager.room.cotSpacesInfo")}
      >
        <TextInput
          type="number"
          value={data?.occ_infants}
          onChange={(val) => changeData("occ_infants", parseFloat(val))}
        ></TextInput>
      </FormField>
    </>
  );
};

export default EditRoomForm;
