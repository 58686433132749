import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CityTaxParams,
  CityTaxPerPersonStep,
  CityTaxPerRangePriceStep,
  CityTaxPersonResId,
  PerStayPrice,
} from "../../types";
import Checkbox from "../Checkbox";
import FormField from "../FormField";
import Icon from "../Icon";
import TextInput from "../TextInput";
import ApartmentTaxCountryRow from "./ApartmentTaxCountryRow";
import ApartmentTaxRow from "./ApartmentTaxRow";
import ApartmentTaxStayPriceRow from "./ApartmentTaxStayPriceRow";
import ApartmentTaxRangeDatePrice from "./ApartmentTaxRangeDatePrice";

type AddApartmentTaxProps = {
  taxElement?: CityTaxParams;
  args?: any;
  onChange?: (steps: CityTaxParams) => void;
  onAddAgeStep?: (step: CityTaxPerPersonStep) => void;
  onAddCountryStep?: (step: CityTaxPersonResId) => void;
};

export default function AddApartmentTax({
  taxElement,
  onChange,
  onAddAgeStep,
  onAddCountryStep,
  ...args
}: AddApartmentTaxProps) {
  const { t } = useTranslation();

  const [currentTax, setCurrentTax] = useState<CityTaxParams>();

  const [isNightMethod, setIsNightMethod] = useState(false);
  const [isPercentMethod, setIsPercentMethod] = useState(false);
  const [isPerStayPrice, setIsPerStayPrice] = useState(false);
  const [isPerRangePrice, setisPerRangePrice] = useState(false);

  /**
   set current tax
   */
  useEffect(() => {
    setCurrentTax(taxElement);
  }, [taxElement]);

  /**
   * Set default values
   */
  useEffect(() => {
    console.log(currentTax);
    if (
      currentTax &&
      currentTax.perperson_price &&
      parseFloat(currentTax.perperson_price) > 0
    ) {
      setIsNightMethod(true);
    }

    if (
      currentTax &&
      currentTax.percentage &&
      parseFloat(currentTax.percentage) > 0
    ) {
      setIsPercentMethod(true);
    }
    if (
      currentTax &&
      currentTax.perstayprice &&
      currentTax.perstayprice.length > 0
    ) {
      setIsPerStayPrice(true);
    }
    if (
      currentTax &&
      currentTax.perrangeprice &&
      currentTax.perrangeprice.length > 0
    ) {
      setisPerRangePrice(true);
    }

    if (onChange && currentTax) onChange(currentTax);
  }, [currentTax]);

  return (
    <>
      {!currentTax ? (
        <></>
      ) : (
        <div {...args} className={"flex flex-col flex-wrap mt-2 mb-2 -my-3"}>
          <div className={"p-4"}>
            <div className={"text-md mb-2 font-bold text-gray-700"}>
              {t("addAppartmentTax.validityPeriod")}
            </div>

            <div
              className={
                "flex flex-col space-y-4 lg:space-y-0 lg:flex-row -mx-3 py-3 items-center"
              }
            >
              <FormField
                className={"px-3 flex-1 w-full"}
                label={t("addAppartmentTax.fromDay")}
              >
                <TextInput
                  type="number"
                  min={1}
                  max={31}
                  numbered={true}
                  value={currentTax.date_from_day || ""}
                  onChange={(val) => {
                    if (+val < 1) {
                      setCurrentTax({
                        ...currentTax,
                        date_from_day: 1,
                      });
                      return;
                    }
                    if (+val > 31) {
                      setCurrentTax({
                        ...currentTax,
                        date_from_day: 31,
                      });
                      return;
                    }
                    setCurrentTax({
                      ...currentTax,
                      date_from_day: val,
                    });
                  }}
                />
              </FormField>

              <FormField
                className={"px-3 flex-1 w-full"}
                label={t("addAppartmentTax.ofMonth")}
              >
                <TextInput
                  type="number"
                  min={1}
                  max={12}
                  numbered={true}
                  value={currentTax.date_from_mon || ""}
                  onChange={(val) => {
                    if (+val < 1) {
                      setCurrentTax({
                        ...currentTax,
                        date_from_mon: 1,
                      });
                      return;
                    }
                    if (+val > 12) {
                      setCurrentTax({
                        ...currentTax,
                        date_from_mon: 12,
                      });
                      return;
                    }
                    setCurrentTax({
                      ...currentTax,
                      date_from_mon: val,
                    });
                  }}
                ></TextInput>
              </FormField>

              <div
                className={
                  "px-6 mt-6 flex items-center flex-col text-gray-500 font-medium"
                }
              >
                <Icon name={"calendar"} size={"20px"}></Icon>
                {t("addAppartmentTax.to")}
              </div>

              <FormField
                className={"px-3 flex-1 w-full"}
                label={t("addAppartmentTax.toDay")}
              >
                <TextInput
                  type="number"
                  min={1}
                  max={31}
                  numbered={true}
                  value={currentTax.date_to_day || ""}
                  onChange={(val) => {
                    if (+val < 1) {
                      setCurrentTax({
                        ...currentTax,
                        date_to_day: 1,
                      });
                      return;
                    }
                    if (+val > 31) {
                      setCurrentTax({
                        ...currentTax,
                        date_to_day: 31,
                      });
                      return;
                    }
                    setCurrentTax({
                      ...currentTax,
                      date_to_day: val,
                    });
                  }}
                ></TextInput>
              </FormField>

              <FormField
                className={"px-3 flex-1 w-full"}
                label={t("addAppartmentTax.ofMonth")}
              >
                <TextInput
                  type="number"
                  min={1}
                  max={12}
                  numbered={true}
                  value={currentTax.date_to_mon || ""}
                  onChange={(val) => {
                    if (+val < 1) {
                      setCurrentTax({
                        ...currentTax,
                        date_to_mon: 1,
                      });
                      return;
                    }
                    if (+val > 12) {
                      setCurrentTax({
                        ...currentTax,
                        date_to_mon: 12,
                      });
                      return;
                    }
                    setCurrentTax({
                      ...currentTax,
                      date_to_mon: val,
                    });
                  }}
                ></TextInput>
              </FormField>
            </div>

            <FormField
              tooltip={"se 0 viene ignorato"}
              className={"flex-1 mb-4"}
              label={t("addAppartmentTax.fixedCost")}
            >
              <TextInput
                type="number"
                min={0}
                suffix={"EUR"}
                numbered={true}
                value={currentTax.fixed || "0"}
                onChange={(val) => {
                  if (+val < 0) {
                    setCurrentTax({
                      ...currentTax,
                      fixed: "0",
                    });
                    return;
                  }
                  setCurrentTax({
                    ...currentTax,
                    fixed: val,
                  });
                }}
              ></TextInput>
            </FormField>
          </div>

          <div className={"p-4 w-full border-b border-t"}>
            <Checkbox
              checked={isNightMethod}
              onChange={() => {
                if (isNightMethod) {
                  setCurrentTax({
                    ...currentTax,
                    perperson_price: "0",
                  });
                }
                setIsNightMethod(!isNightMethod);
              }}
              label={t("addAppartmentTax.methodPersonNight")}
            ></Checkbox>
          </div>

          {isNightMethod && (
            <motion.div
              className={"w-full bg-gray-100"}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
            >
              <div className={"flex px-4 -mx-4 mt-4"}>
                <FormField
                  tooltip={t("addAppartmentTax.zeroIfNotProvided")}
                  className={"px-4 flex-1"}
                  label={t("addAppartmentTax.pricePerPerson")}
                >
                  <TextInput
                    type="number"
                    min={0}
                    suffix={"EUR"}
                    numbered={true}
                    value={currentTax.perperson_price || "0"}
                    onChange={(val) => {
                      if (+val < 0) {
                        setCurrentTax({
                          ...currentTax,
                          perperson_price: "0",
                        });
                        return;
                      }
                      setCurrentTax({
                        ...currentTax,
                        perperson_price: val,
                      });
                    }}
                  ></TextInput>
                </FormField>

                <FormField
                  tooltip={t("addAppartmentTax.999IfNotProvided")}
                  className={"px-4 flex-1"}
                  label={t("addAppartmentTax.maxNumberDays")}
                >
                  <TextInput
                    type="number"
                    min={0}
                    numbered={true}
                    value={currentTax.perperson_maxdays || "999"}
                    onChange={(val) => {
                      if (+val < 0) {
                        setCurrentTax({
                          ...currentTax,
                          perperson_maxdays: "999",
                        });
                        return;
                      }
                      setCurrentTax({
                        ...currentTax,
                        perperson_maxdays: val,
                      });
                    }}
                  ></TextInput>
                </FormField>
              </div>
              <div
                className={"w-full px-4 pt-4 text-md text-gray-700 font-bold"}
              >
                {t("addAppartmentTax.ageBands")}
              </div>
              <div className={"px-4 mb-4"}>
                {currentTax &&
                  currentTax.perperson_steps &&
                  currentTax.perperson_steps.map(
                    (step: CityTaxPerPersonStep, index: number) => (
                      <ApartmentTaxRow
                        className={"py-3"}
                        step={step}
                        key={"step" + index}
                        onDelete={() => {
                          if (currentTax.perperson_steps) {
                            setCurrentTax({
                              ...currentTax,
                              perperson_steps:
                                currentTax.perperson_steps.filter(
                                  (o: any, deleteIndex: number) => {
                                    if (deleteIndex !== index) return o;
                                  }
                                ),
                            });
                          }
                        }}
                        onChange={(value) => {
                          if (currentTax.perperson_steps) {
                            setCurrentTax({
                              ...currentTax,
                              perperson_steps: currentTax.perperson_steps?.map(
                                (
                                  step: CityTaxPerPersonStep,
                                  updateIndex: number
                                ) => {
                                  if (index === updateIndex) {
                                    return value;
                                  }
                                  return step;
                                }
                              ),
                            });
                          }
                        }}
                      />
                    )
                  )}

                {((currentTax &&
                  currentTax.perperson_steps &&
                  currentTax.perperson_steps.length === 0) ||
                  !currentTax.perperson_steps) && (
                  <div className={"text-sm mt-2 text-gray-600"}>
                    {t("addAppartmentTax.noAgeBands")}
                  </div>
                )}
              </div>

              <button
                onClick={() => {
                  if (!currentTax.perperson_steps) {
                    setCurrentTax({
                      ...currentTax,
                      perperson_steps: [
                        {
                          age: 0,
                          percentage: 0,
                          type: "person",
                        },
                      ],
                    });
                  } else {
                    setCurrentTax({
                      ...currentTax,
                      perperson_steps: [
                        ...currentTax.perperson_steps,
                        {
                          age: 0,
                          percentage: 0,
                          type: "person",
                        },
                      ],
                    });
                  }
                }}
                className={
                  "text-sm px-4 focus:outline-none w-full justify-center hover:bg-cyan-100 transition hover:border-cyan-300 flex items-center py-3 border-t border-b border-gray-200 text-cyan-600"
                }
              >
                <Icon name={"add"} size={"20px"}></Icon>{" "}
                {t("addAppartmentTax.addAgeBand")}
              </button>

              <div className={"bg-gray-100 border-gray-300 w-full"}>
                <div className={"pt-6 px-4 text-md text-gray-700 font-bold"}>
                  {t("addAppartmentTax.esenzioneResidenza")}
                </div>
                <div className={"p-4 mb-4 -my-3"}>
                  {currentTax &&
                    currentTax.perperson_steps_resid &&
                    currentTax.perperson_steps_resid.map(
                      (country: CityTaxPersonResId, index: number) => (
                        <ApartmentTaxCountryRow
                          className={`${index > 0 ? "border-t" : ""} py-3`}
                          key={"country" + index}
                          onDelete={() => {
                            setCurrentTax({
                              ...currentTax,
                              perperson_steps_resid:
                                currentTax.perperson_steps_resid.filter(
                                  (o: any, deleteIndex: number) => {
                                    if (deleteIndex !== index) return o;
                                  }
                                ),
                            });
                          }}
                          onChange={(value) => {
                            setCurrentTax({
                              ...currentTax,
                              perperson_steps_resid:
                                currentTax.perperson_steps_resid?.map(
                                  (
                                    step: CityTaxPersonResId,
                                    updateIndex: number
                                  ) => {
                                    if (index === updateIndex) {
                                      return value;
                                    }
                                    return step;
                                  }
                                ),
                            });
                          }}
                          country={country}
                        ></ApartmentTaxCountryRow>
                      )
                    )}

                  {((currentTax &&
                    currentTax.perperson_steps_resid &&
                    currentTax.perperson_steps_resid.length === 0) ||
                    !currentTax.perperson_steps_resid) && (
                    <div className={"text-sm mt-2 stext-gray-600"}>
                      {t("addAppartmentTax.nessunaEsenzionePaese")}
                    </div>
                  )}
                </div>

                <button
                  onClick={() => {
                    if (!currentTax.perperson_steps_resid) {
                      setCurrentTax({
                        ...currentTax,
                        perperson_steps_resid: [
                          {
                            percentage: 0,
                            city: "",
                            country: "",
                            prov: "",
                            type: "person",
                          },
                        ],
                      });
                    } else {
                      setCurrentTax({
                        ...currentTax,
                        perperson_steps_resid: [
                          ...currentTax.perperson_steps_resid,
                          {
                            percentage: 0,
                            city: "",
                            country: "",
                            prov: "",
                            type: "person",
                          },
                        ],
                      });
                    }
                  }}
                  className={
                    "text-sm px-4 focus:outline-none w-full justify-center hover:bg-cyan-100 transition hover:border-cyan-300 flex items-center py-3 border-t border-b border-gray-200 text-cyan-600"
                  }
                >
                  <Icon name={"add"} size={"20px"}></Icon>{" "}
                  {t("addAppartmentTax.addCountry")}
                </button>
              </div>
            </motion.div>
          )}

          <div className={"p-4 w-full border-b border-t"}>
            <Checkbox
              checked={isPercentMethod}
              onChange={() => {
                if (isPercentMethod) {
                  setCurrentTax({
                    ...currentTax,
                    percentage: "0",
                  });
                }
                setIsPercentMethod(!isPercentMethod);
              }}
              label={t("addAppartmentTax.methodPercentPrice")}
            ></Checkbox>
          </div>

          {isPercentMethod && (
            <motion.div
              className={"w-full bg-gray-100 border-t"}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
            >
              <div className={"flex px-4 -mx-4 mt-4"}>
                <FormField
                  tooltip={t("addAppartmentTax.zeroIfNotProvided")}
                  className={"px-4 flex-1"}
                  label={t("addAppartmentTax.pricePerPersonPercentage")}
                >
                  <TextInput
                    type="number"
                    min={0}
                    suffix={"%"}
                    numbered={true}
                    value={currentTax.percentage || "0"}
                    onChange={(val) => {
                      if (+val < 0) {
                        setCurrentTax({
                          ...currentTax,
                          percentage: "0",
                        });
                        return;
                      }
                      setCurrentTax({
                        ...currentTax,
                        percentage: val,
                      });
                    }}
                  ></TextInput>
                </FormField>

                <FormField
                  tooltip={t("addAppartmentTax.zeroIfNotProvided")}
                  className={"px-4 flex-1"}
                  label={t("addAppartmentTax.maxPayblePerNigthPercentage")}
                >
                  <TextInput
                    type="number"
                    min={0}
                    suffix={"EUR"}
                    numbered={true}
                    value={currentTax.percentage_max || "0"}
                    onChange={(val) => {
                      if (+val < 0) {
                        setCurrentTax({
                          ...currentTax,
                          percentage_max: "0",
                        });
                        return;
                      }
                      setCurrentTax({
                        ...currentTax,
                        percentage_max: val,
                      });
                    }}
                  ></TextInput>
                </FormField>
              </div>
              <div className={"flex px-4 -mx-4 mt-4"}>
                <FormField
                  tooltip={t("addAppartmentTax.999IfNotProvided")}
                  className={"px-4 flex-1"}
                  label={t("addAppartmentTax.maxNumberDays")}
                >
                  <TextInput
                    type="number"
                    min={0}
                    numbered={true}
                    value={currentTax.perpercentage_maxdays || "999"}
                    onChange={(val) => {
                      if (+val < 0) {
                        setCurrentTax({
                          ...currentTax,
                          perpercentage_maxdays: "999",
                        });
                        return;
                      }
                      setCurrentTax({
                        ...currentTax,
                        perpercentage_maxdays: val,
                      });
                    }}
                  ></TextInput>
                </FormField>
              </div>
              <div
                className={"w-full px-4 pt-4 text-md text-gray-700 font-bold"}
              >
                {t("addAppartmentTax.ageBands")}
              </div>
              <div className={"px-4 mb-4"}>
                {currentTax &&
                  currentTax.perpercentage_steps &&
                  currentTax.perpercentage_steps.map(
                    (step: CityTaxPerPersonStep, index: number) => (
                      <ApartmentTaxRow
                        className={"py-3"}
                        step={step}
                        key={"step" + index}
                        onDelete={() => {
                          if (currentTax.perpercentage_steps) {
                            setCurrentTax({
                              ...currentTax,
                              perpercentage_steps:
                                currentTax.perpercentage_steps.filter(
                                  (o: any, deleteIndex: number) => {
                                    if (deleteIndex !== index) return o;
                                  }
                                ),
                            });
                          }
                        }}
                        onChange={(value) => {
                          if (currentTax.perpercentage_steps) {
                            setCurrentTax({
                              ...currentTax,
                              perpercentage_steps:
                                currentTax.perpercentage_steps?.map(
                                  (
                                    step: CityTaxPerPersonStep,
                                    updateIndex: number
                                  ) => {
                                    if (index === updateIndex) {
                                      return value;
                                    }
                                    return step;
                                  }
                                ),
                            });
                          }
                        }}
                      ></ApartmentTaxRow>
                    )
                  )}

                {((currentTax &&
                  currentTax.perpercentage_steps &&
                  currentTax.perpercentage_steps.length === 0) ||
                  !currentTax.perpercentage_steps) && (
                  <div className={"text-sm mt-2 text-gray-600"}>
                    {t("addAppartmentTax.noAgeBands")}
                  </div>
                )}
              </div>

              <button
                onClick={() => {
                  if (!currentTax.perpercentage_steps) {
                    setCurrentTax({
                      ...currentTax,
                      perpercentage_steps: [
                        {
                          age: 0,
                          percentage: 0,
                          type: "percentage",
                        },
                      ],
                    });
                  } else {
                    setCurrentTax({
                      ...currentTax,
                      perpercentage_steps: [
                        ...currentTax.perpercentage_steps,
                        {
                          age: 0,
                          percentage: 0,
                          type: "percentage",
                        },
                      ],
                    });
                  }
                }}
                className={
                  "text-sm px-4 focus:outline-none w-full justify-center hover:bg-cyan-100 transition hover:border-cyan-300 flex items-center py-3 border-t border-b border-gray-200 text-cyan-600"
                }
              >
                <Icon name={"add"} size={"20px"}></Icon>{" "}
                {t("addAppartmentTax.addAgeBand")}
              </button>

              <div className={"bg-gray-100 border-gray-300 w-full"}>
                <div className={"pt-6 px-4 text-md text-gray-700 font-bold"}>
                  {t("addAppartmentTax.esenzioneResidenza")}
                </div>
                <div className={"p-4 mb-4 -my-3"}>
                  {currentTax &&
                    currentTax.perpercentage_steps_resid &&
                    currentTax.perpercentage_steps_resid.map(
                      (country: CityTaxPersonResId, index: number) => (
                        <ApartmentTaxCountryRow
                          className={"py-3"}
                          key={"country" + index}
                          onDelete={() => {
                            if (currentTax.perpercentage_steps_resid) {
                              setCurrentTax({
                                ...currentTax,
                                perpercentage_steps_resid:
                                  currentTax.perpercentage_steps_resid.filter(
                                    (o: any, deleteIndex: number) => {
                                      if (deleteIndex !== index) return o;
                                    }
                                  ),
                              });
                            }
                          }}
                          onChange={(value) => {
                            if (currentTax.perpercentage_steps_resid) {
                              setCurrentTax({
                                ...currentTax,
                                perpercentage_steps_resid:
                                  currentTax.perpercentage_steps_resid?.map(
                                    (
                                      step: CityTaxPersonResId,
                                      updateIndex: number
                                    ) => {
                                      if (index === updateIndex) {
                                        return value;
                                      }
                                      return step;
                                    }
                                  ),
                              });
                            }
                          }}
                          country={country}
                        ></ApartmentTaxCountryRow>
                      )
                    )}

                  {((currentTax &&
                    currentTax.perpercentage_steps_resid &&
                    currentTax.perpercentage_steps_resid.length === 0) ||
                    !currentTax.perpercentage_steps_resid) && (
                    <div className={"text-sm mt-2 stext-gray-600"}>
                      {t("addAppartmentTax.nessunaEsenzionePaese")}
                    </div>
                  )}
                </div>

                <button
                  onClick={() => {
                    if (!currentTax.perpercentage_steps_resid) {
                      setCurrentTax({
                        ...currentTax,
                        perpercentage_steps_resid: [
                          {
                            percentage: 0,
                            city: "",
                            country: "",
                            prov: "",
                            type: "percentage",
                          },
                        ],
                      });
                    } else {
                      setCurrentTax({
                        ...currentTax,
                        perpercentage_steps_resid: [
                          ...currentTax.perpercentage_steps_resid,
                          {
                            percentage: 0,
                            city: "",
                            country: "",
                            prov: "",
                            type: "percentage",
                          },
                        ],
                      });
                    }
                  }}
                  className={
                    "text-sm px-4 focus:outline-none w-full justify-center hover:bg-cyan-100 transition hover:border-cyan-300 flex items-center py-3 border-t border-b border-gray-200 text-cyan-600"
                  }
                >
                  <Icon name={"add"} size={"20px"}></Icon>{" "}
                  {t("addAppartmentTax.addCountry")}
                </button>
              </div>
            </motion.div>
          )}
          <div className={"p-4 w-full border-b border-t"}>
            <Checkbox
              checked={isPerStayPrice}
              onChange={() => {
                if (isPerStayPrice) {
                  setCurrentTax({
                    ...currentTax,
                    perstayprice: [],
                    perstayprice_steps: [],
                    perstayprice_steps_resid: [],
                  });
                }
                setIsPerStayPrice((prev) => !prev);
                
              }}
              label={t("addAppartmentTax.methodPerStayPrice")}
            ></Checkbox>
          </div>

          {isPerStayPrice && (
            <motion.div
              className={"w-full bg-gray-100 border-t"}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
            >
              <div
                className={"w-full px-4 pt-4 text-md text-gray-700 font-bold"}
              >
                {t("addAppartmentTax.perStayPriceBands")}
              </div>
              <div className={"px-4 mb-4"}>
                {currentTax &&
                  currentTax.perstayprice &&
                  currentTax.perstayprice.map(
                    (step: PerStayPrice, index: number) => (
                      <ApartmentTaxStayPriceRow
                        className={"py-3"}
                        step={step}
                        key={"step" + index}
                        onDelete={() => {
                          if (currentTax.perstayprice) {
                            setCurrentTax({
                              ...currentTax,
                              perstayprice: currentTax.perstayprice.filter(
                                (o: any, deleteIndex: number) => {
                                  if (deleteIndex !== index) return o;
                                }
                              ),
                            });
                          }
                        }}
                        onChange={(value) => {
                          if (currentTax.perstayprice) {
                            setCurrentTax({
                              ...currentTax,
                              perstayprice: currentTax.perstayprice?.map(
                                (step: PerStayPrice, updateIndex: number) => {
                                  if (index === updateIndex) {
                                    return value;
                                  }
                                  return step;
                                }
                              ),
                            });
                          }
                        }}
                      ></ApartmentTaxStayPriceRow>
                    )
                  )}

                {((currentTax &&
                  currentTax.perstayprice &&
                  currentTax.perstayprice.length === 0) ||
                  !currentTax.perstayprice) && (
                  <div className={"text-sm mt-2 text-gray-600"}>
                    {t("addAppartmentTax.noPerStayPriceBands")}
                  </div>
                )}
              </div>

              <button
                onClick={() => {
                  if (!currentTax.perstayprice) {
                    setCurrentTax({
                      ...currentTax,
                      perstayprice: [
                        {
                          from_price: 0,
                          to_price: 9999,
                          maxdays: 999,
                          amount: 1,
                        },
                      ],
                    });
                  } else {
                    setCurrentTax({
                      ...currentTax,
                      perstayprice: [
                        ...currentTax.perstayprice,
                        {
                          from_price: 0,
                          to_price: 9999,
                          maxdays: 999,
                          amount: 1,
                        },
                      ],
                    });
                  }
                }}
                className={
                  "text-sm px-4 focus:outline-none w-full justify-center hover:bg-cyan-100 transition hover:border-cyan-300 flex items-center py-3 border-t border-b border-gray-200 text-cyan-600"
                }
              >
                <Icon name={"add"} size={"20px"}></Icon>{" "}
                {t("addAppartmentTax.addPerStayPriceBand")}
              </button>
              <div
                className={"w-full px-4 pt-4 text-md text-gray-700 font-bold"}
              >
                {t("addAppartmentTax.ageBands")}
              </div>
              <div className={"px-4 mb-4"}>
                {currentTax &&
                  currentTax.perstayprice_steps &&
                  currentTax.perstayprice_steps.map(
                    (step: CityTaxPerPersonStep, index: number) => (
                      <ApartmentTaxRow
                        className={"py-3"}
                        step={step}
                        key={"step" + index}
                        onDelete={() => {
                          if (currentTax.perstayprice_steps) {
                            setCurrentTax({
                              ...currentTax,
                              perstayprice_steps:
                                currentTax.perstayprice_steps.filter(
                                  (o: any, deleteIndex: number) => {
                                    if (deleteIndex !== index) return o;
                                  }
                                ),
                            });
                          }
                        }}
                        onChange={(value) => {
                          if (currentTax.perstayprice_steps) {
                            setCurrentTax({
                              ...currentTax,
                              perstayprice_steps:
                                currentTax.perstayprice_steps?.map(
                                  (
                                    step: CityTaxPerPersonStep,
                                    updateIndex: number
                                  ) => {
                                    if (index === updateIndex) {
                                      return value;
                                    }
                                    return step;
                                  }
                                ),
                            });
                          }
                        }}
                      ></ApartmentTaxRow>
                    )
                  )}

                {((currentTax &&
                  currentTax.perstayprice_steps &&
                  currentTax.perstayprice_steps.length === 0) ||
                  !currentTax.perstayprice_steps) && (
                  <div className={"text-sm mt-2 text-gray-600"}>
                    {t("addAppartmentTax.noAgeBands")}
                  </div>
                )}
              </div>

              <button
                onClick={() => {
                  if (!currentTax.perstayprice_steps) {
                    setCurrentTax({
                      ...currentTax,
                      perstayprice_steps: [
                        {
                          age: 0,
                          percentage: 0,
                          type: "percentage",
                        },
                      ],
                    });
                  } else {
                    setCurrentTax({
                      ...currentTax,
                      perstayprice_steps: [
                        ...currentTax.perstayprice_steps,
                        {
                          age: 0,
                          percentage: 0,
                          type: "percentage",
                        },
                      ],
                    });
                  }
                }}
                className={
                  "text-sm px-4 focus:outline-none w-full justify-center hover:bg-cyan-100 transition hover:border-cyan-300 flex items-center py-3 border-t border-b border-gray-200 text-cyan-600"
                }
              >
                <Icon name={"add"} size={"20px"}></Icon>{" "}
                {t("addAppartmentTax.addAgeBand")}
              </button>

              <div className={"bg-gray-100 border-gray-300 w-full"}>
                <div className={"pt-6 px-4 text-md text-gray-700 font-bold"}>
                  {t("addAppartmentTax.esenzioneResidenza")}
                </div>
                <div className={"p-4 mb-4 -my-3"}>
                  {currentTax &&
                    currentTax.perstayprice_steps_resid &&
                    currentTax.perstayprice_steps_resid.map(
                      (country: CityTaxPersonResId, index: number) => (
                        <ApartmentTaxCountryRow
                          className={"py-3"}
                          key={"country" + index}
                          onDelete={() => {
                            if (currentTax.perstayprice_steps_resid) {
                              setCurrentTax({
                                ...currentTax,
                                perstayprice_steps_resid:
                                  currentTax.perstayprice_steps_resid.filter(
                                    (o: any, deleteIndex: number) => {
                                      if (deleteIndex !== index) return o;
                                    }
                                  ),
                              });
                            }
                          }}
                          onChange={(value) => {
                            if (currentTax.perstayprice_steps_resid) {
                              setCurrentTax({
                                ...currentTax,
                                perstayprice_steps_resid:
                                  currentTax.perstayprice_steps_resid?.map(
                                    (
                                      step: CityTaxPersonResId,
                                      updateIndex: number
                                    ) => {
                                      if (index === updateIndex) {
                                        return value;
                                      }
                                      return step;
                                    }
                                  ),
                              });
                            }
                          }}
                          country={country}
                        ></ApartmentTaxCountryRow>
                      )
                    )}

                  {((currentTax &&
                    currentTax.perstayprice_steps_resid &&
                    currentTax.perstayprice_steps_resid.length === 0) ||
                    !currentTax.perstayprice_steps_resid) && (
                    <div className={"text-sm mt-2 stext-gray-600"}>
                      {t("addAppartmentTax.nessunaEsenzionePaese")}
                    </div>
                  )}
                </div>

                <button
                  onClick={() => {
                    if (!currentTax.perstayprice_steps_resid) {
                      setCurrentTax({
                        ...currentTax,
                        perstayprice_steps_resid: [
                          {
                            percentage: 0,
                            city: "",
                            country: "",
                            prov: "",
                            type: "percentage",
                          },
                        ],
                      });
                    } else {
                      setCurrentTax({
                        ...currentTax,
                        perstayprice_steps_resid: [
                          ...currentTax.perstayprice_steps_resid,
                          {
                            percentage: 0,
                            city: "",
                            country: "",
                            prov: "",
                            type: "percentage",
                          },
                        ],
                      });
                    }
                  }}
                  className={
                    "text-sm px-4 focus:outline-none w-full justify-center hover:bg-cyan-100 transition hover:border-cyan-300 flex items-center py-3 border-t border-b border-gray-200 text-cyan-600"
                  }
                >
                  <Icon name={"add"} size={"20px"}></Icon>{" "}
                  {t("addAppartmentTax.addCountry")}
                </button>
              </div>
            </motion.div>
          )}

          <div className={"p-4 w-full border-b border-t"}>
            <Checkbox
              checked={isPerRangePrice}
              onChange={() => {
                if (isPerRangePrice) {
                  setCurrentTax({
                    ...currentTax,
                    perrangeprice: [],
                  });
                }
                setisPerRangePrice((prev) => !prev);
              }}
              label={t("addAppartmentTax.methodPerRangePrice")}
            ></Checkbox>
          </div>

          {isPerRangePrice && (
            <motion.div
              className={"w-full bg-gray-100 border-t"}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
            >
              <div
                className={"w-full px-4 pt-4 text-md text-gray-700 font-bold"}
              >
                {t("addAppartmentTax.methodPerRangePriceBand")}
              </div>
              <div className={"px-4 mb-4"}>
                {currentTax &&
                  currentTax.perrangeprice &&
                  currentTax.perrangeprice.map(
                    (step: CityTaxPerRangePriceStep, index: number) => (
                      <ApartmentTaxRangeDatePrice
                        className={"py-3"}
                        step={step}
                        minValue={currentTax.perrangeprice && currentTax.perrangeprice[index-1]?.date +1}
                        maxValue={currentTax.perrangeprice && currentTax.perrangeprice[index+1]?.date -1}
                        key={"step" + index}
                        onDelete={() => {
                          if (currentTax.perrangeprice) {
                            setCurrentTax({
                              ...currentTax,
                              perrangeprice:
                                currentTax.perrangeprice.filter(
                                  (o: any, deleteIndex: number) => {
                                    if (deleteIndex !== index) return o;
                                  }
                                ),
                            });
                          }
                        }}
                        onChange={(value) => {
                          if (currentTax.perrangeprice) {
                            setCurrentTax({
                              ...currentTax,
                              perrangeprice:
                                currentTax.perrangeprice?.map(
                                  (
                                    step: CityTaxPerRangePriceStep,
                                    updateIndex: number
                                  ) => {
                                    if (index === updateIndex) {
                                      return value;
                                    }
                                    return step;
                                  }
                                ),
                            });
                          }
                        }}
                      ></ApartmentTaxRangeDatePrice>
                    )
                  )}

                {((currentTax &&
                  currentTax.perrangeprice &&
                  currentTax.perrangeprice.length === 0) ||
                  !currentTax.perrangeprice) && (
                  <div className={"text-sm mt-2 text-gray-600"}>
                    {t("addAppartmentTax.noPerRangePrice")}
                  </div>
                )}
              </div>

              <button
                onClick={() => {
                  if (!currentTax.perrangeprice) {
                    setCurrentTax({
                      ...currentTax,
                      perrangeprice: [
                          {
                            date: 0,
                            price: 0,
                          },
                      ],
                    });
                  } else {
                    setCurrentTax({
                      ...currentTax,
                      perrangeprice: [
                        ...currentTax.perrangeprice,
                        {
                          date: currentTax?.perrangeprice[currentTax?.perrangeprice?.length -1]?.date + 1,
                          price: 0,
                        },
                      ],
                    });
                  }
                }}
                className={
                  "text-sm px-4 focus:outline-none w-full justify-center hover:bg-cyan-100 transition hover:border-cyan-300 flex items-center py-3 border-t border-b border-gray-200 text-cyan-600"
                }
              >
                <Icon name={"add"} size={"20px"}></Icon>{" "}
                {t("addAppartmentTax.addPerRangePriceBand")}
              </button>
            </motion.div>
          )}

          <div className={"pt-4 px-4 text-md text-gray-700 mt-4 font-bold"}>
            {t("addAppartmentTax.calcMethod")}
          </div>
          <div className={"p-4 mb-2"}>
            <Checkbox
              checked={currentTax.combination === "SUM"}
              label={"Somma"}
              onChange={() => {
                setCurrentTax({
                  ...currentTax,
                  combination: "SUM",
                });
              }}
            ></Checkbox>

            <Checkbox
              checked={currentTax.combination === "MAX"}
              label={"Valore massimo"}
              onChange={() => {
                setCurrentTax({
                  ...currentTax,
                  combination: "MAX",
                });
              }}
            ></Checkbox>
          </div>
        </div>
      )}
    </>
  );
}
