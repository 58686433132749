import React, { Dispatch, SetStateAction } from 'react'
import { useState, MouseEvent } from 'react'
import Icon from '../components/Icon'
import { useAppDispatch } from '../store'
import { toast } from 'react-toastify'
import { resetPassword } from '../features/login/loginSlice'



export default function ResetPasswordForm (
  {setResetPassword}:
  {setResetPassword?: Dispatch<SetStateAction<boolean>>}
) {

  const [email, setEmail] = useState<string>()
  const dispatch = useAppDispatch()



  async function handleResetPassword (event:MouseEvent<HTMLButtonElement>) {
    if (!email ) {
      return
    }
    event.preventDefault()
    const result = await dispatch(resetPassword({
      email
    }))
    if (resetPassword.fulfilled.match(result)) {
      toast.success("A reset email has been sent to the account you specified");
      if(setResetPassword)
          setResetPassword(false);
    }
    
  }


  return (
    <form>
      <div>
        <p className={'text-center text-gray-600 mb-6'}>
          Inserisci il tuo indirizzo email, ti invieremo le istruzioni per reimpostare la tua password.
        </p>
      </div>
      <div>
        <input
          required
          placeholder={'Email'}
          onChange={e =>{
            setEmail(e.target.value)
          }}
          className={'border-gray-300 placeholder-gray-500 appearance-none rounded-none relative block w-full px-3 py-2 border text-gray-900 rounded-2xl focus:outline-none focus:z-10 sm:text-sm focus:ring focus:ring-cyan-200 focus:border-cyan-400'}
          type={'email'}></input>
      </div>

      <button
        type="submit"
        onClick={handleResetPassword}
        className={'w-full text-white px-3 py-2 font-medium rounded-2xl bg-gray-800 mt-6'}>
          <div className={'flex items-center justify-between'}>
            <div>Avanti</div>
            <Icon name={'arrow-narrow-right'} size={'20px'}></Icon>
          </div>
      </button>
    </form>
  )

}