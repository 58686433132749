import { Editor } from '@tinymce/tinymce-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import {
  deleteReservationService,
  updateServiceData,
} from '../../shared/queries';
import { BuyableLang, ServiceQuestion } from '../../types';
import FormField from '../FormField';
import Modal from '../Modal';
import ModalActions from '../ModalAction';
import ModalCard from '../ModalCard';
import ModalSection from '../ModalSection';
import TextInput from '../TextInput';

type ServiceContentProps = {
  lang: BuyableLang;
  visible: boolean;
  onChange: (data: any) => void;
  onClose: () => void;
};

export default function ServiceContent({
  lang,
  visible,
  onClose,
  onChange,
}: ServiceContentProps) {
  const { t } = useTranslation();
  const [currentLang, setCurrentLang] = useState<BuyableLang>(lang);

  useEffect(()=>{
    setCurrentLang(lang)
  }, [lang])

  return (
    <Modal visible={visible}>
      <ModalCard title={t('serviceContent.titleDescription')}>
        <ModalSection>
          <div className={'p-4'}>
            <FormField
              className={'mb-4 w-full'}
              label={t('serviceContent.publicName')}
            >
              <TextInput
                value={currentLang.title}
                onChange={(value) =>
                  setCurrentLang({
                    ...currentLang,
                    title: value,
                  })
                }
              ></TextInput>
            </FormField>

            <FormField
              className={'mb-4 w-full'}
              label={t('serviceContent.description')}
            >
              <div className={'bg-white shadow-md mb-8'}>
                <Editor
                  apiKey={'af1s9ngxeroefg0odkelxcvqpjzqffflygu2cw2tqk9jexpo'}
                  value={currentLang.body}
                  init={{
                    promotion:false,
                    height: 210,
                    menubar: false,
                    plugins: [
                      'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                  'searchreplace', 'wordcount', 'visualblocks', 'visualchars', 'code', 'fullscreen', 'insertdatetime',
                  'media', 'table', 'emoticons', 'help'
                    ],
                    toolbar:
                      'save | undo redo | formatselect | bold italic backcolor | ' +
                          'alignleft aligncenter alignright alignjustify | ' +
                          'bullist numlist outdent indent | removeformat | help ',
                  }}
                  onSaveContent={() => {}}
                  onEditorChange={(value) => {
                    setCurrentLang({
                      ...currentLang,
                      body: value,
                    });
                  }}
                />
              </div>
            </FormField>
          </div>
        </ModalSection>
        <ModalActions
        saveDisabled={!(currentLang.title && currentLang.body)}
          saveLabel={t('general.save')}
          onClose={() => {
            if (onClose) onClose();
          }}
          onSave={() => {
            onChange(currentLang);
            // mutation.mutate()
          }}
        ></ModalActions>
      </ModalCard>
    </Modal>
  );
}
