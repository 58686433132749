import { useCallback, useEffect, useRef, useState } from 'react';
import { fetchImage, fetchApiImage } from '../shared/queries';
import Draggable from 'react-draggable';

type ImageProps = {
  url: string;
  isApi?: boolean;
  width?: string;
  height?: string;
  label?: string;
  className?: string;
  block?: boolean;
  rounded?: boolean;
  zoomable?: boolean;
  isDirect?: boolean;
  viewBackground?: boolean;
  style?: any;
  isLogo?:boolean;
  logoBg?: any
};

export default function AsyncImage({
  url,
  className,
  width,
  height,
  label,
  block,
  rounded,
  zoomable,
  isApi = false,
  isDirect = false,
  viewBackground = false,
  style,
  isLogo,
  logoBg,
}: ImageProps) {
  // const [error, setError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<string>();
  const [isZoomed, setIsZoomed] = useState<boolean>(false);

  const ref: any = useRef()

  async function loadImage() {
    try {
      setLoading(true);
      // setError(false);
      let data = null;
      if (isApi) {
        data = await fetchApiImage(url);
      } else {
        data = await fetchImage(url);
      }
      if (data) setData(URL.createObjectURL(data));
    } catch (e) {
      //setError(true);
      console.error('Error loading image');
    } finally {
      setLoading(false);
    }
  }

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      setIsZoomed(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  useEffect(() => {
    loadImage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, isDirect]);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref?.current?.contains(event?.target)) {
        setIsZoomed(false)
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div
    style={style}
      className={`${block ? "block" : "inline-block"} ${
        rounded ? "rounded-2xl" : ""
      } ${zoomable ? "cursor-pointer" : ""}`}
      onClick={(e) => {
        if (zoomable) setIsZoomed(true);
        e.stopPropagation();
      }}
    >
      {isLoading && width && height && (
        <div
          className={"w-14 h-14 skeleton-box"}
          style={{
            width,
            height,
          }}
        ></div>
      )}
      {data && !isDirect && (
        <img
          alt={"Image"}
          className={`${block ? "w-full" : ""} ${
            rounded ? "rounded-2xl" : ""
          } ${className}`}
          style={{ width, height }}
          src={data}
        ></img>
      )}
      {isDirect && (
        <img
          alt={"Image"}
          className={`${block ? "w-full" : ""} ${
            rounded ? "rounded-2xl" : ""
          } ${className}`}
          style={{ width, height }}
          src={url}
        ></img>
      )}
      {label && (
        <div className={"text-center mt-1 text-sm text-gray-500"}>{label}</div>
      )}

      {isZoomed && (
        <div
          className={
            "top-0 flex flex-col items-center justify-center left-0 fixed"
          }
          style={{
            zIndex: 999999,
            width: "100vw",
            height: "100vh",
            background: viewBackground ? "rgba(255,255,255, 0.2)" : "rgba(0,0,0,0.6)",
          }}
        >
          <Draggable >
            <div ref={ref}
              className={`${logoBg && 'bg-cyan-400' } rounded-lg`}
              style={{
                position: "absolute",
                color: "black",
                userSelect: "none",
                cursor: 'move',
                backgroundColor: logoBg ? logoBg : 'null'
              }}
            >
              <img
                alt={"Image"}
                className={`shadow-md rounded-lg`}
                draggable="false"
                src={isLogo ? url : data}
              ></img>
            </div>
          </Draggable>
        </div>
      )}
    </div>
  );
}
