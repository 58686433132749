import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { fetchDomoticEntities } from "../../shared/queries";
import { DomoticDevice, DomoticEntity, ListApiResponseV3, SocketManager } from "../../types";
import DomoticGraph from "./DomoticGraph";

export default function DeviceCharts ({
    localKey,
    device,
    socketManager,
    range,
    isRealTime,
    onShow
} : {
    range: {
        from: Date,
        to: Date
    },
    isRealTime: boolean,
    onShow: (device: DomoticDevice) => void,
    localKey: string,
    device: DomoticDevice,
    socketManager: SocketManager
}) {

    const [visible, setVisible] = useState(false)

    const {
        data
    } = useQuery<ListApiResponseV3<DomoticEntity>, Error>(['device', device.device_mac], () => fetchDomoticEntities(device.device_mac))

    function hasChart (entityId: string, entityType: string) : string[] {
        if (data && data.results) {
            const entity = data.results.find(entity => entity.entity_type === entityType)
            if (
                entity &&
                (
                    entity.graph_types.indexOf('line_graph') > -1 ||
                    entity.graph_types.indexOf('calendar_heatmap') > -1 ||
                    entity.graph_types.indexOf('squared_chart') > -1
                )) {
                return entity.graph_types
            }
            return []
        }
        return []
    }

    useEffect(() => {
        if (visible) {
            if (onShow) onShow(device)
        }
    }, [ visible ])

    return (
        <div className={visible ? 'bg-white mb-4 shadow rounded-2xl border-1': ''}>
            {
                visible &&
                <div className={'font-medium text-lg p-4'}>
                    {device.device_name}
                </div>
            }
            {
                device.entities
                    .filter(entity => hasChart(entity.entity_id, entity.entity_type).length > 0)
                    .map((entity, index) => (
                        <>
                        <DomoticGraph
                        key={index}
                        isRealTime={isRealTime}
                        range={range}
                        vikeyMac={device.vikey_mac}
                        deviceMac={device.device_mac}
                        entityId={entity.entity_id}
                        graphType={hasChart(entity.entity_id, entity.entity_type)}
                        localKey={localKey}
                        entityType={entity.entity_type}
                        socketManager={socketManager}
                        visible={visible}
                        onData={() => setVisible(true)} />
                        </>
                    
                ))
            }
        </div>
    )
    
}