type PageTitleProps = {
  title: string,
  subtitle?: string,
  noMargin?: boolean
}

export default function PageTitle ({
  title,
  noMargin = false,
  subtitle
} : PageTitleProps) {

  return (
    <div className={noMargin ? 'mb-1' : 'mb-6'}>
      <div className={'text-2xl font-bold text-gray-700'}>
        {title}
      </div>
      {
        subtitle &&
        <div className={'mt-1 text-gray-600'}>
          {subtitle}
        </div>
      }
    </div>
  )
  
}