import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { updateApartment, updateApartmentV3 } from "../../shared/queries";
import { Local } from "../../types";
import FormField from "../FormField";
import Modal from "../Modal";
import ModalActions from "../ModalAction";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import TextInput from "../TextInput";

type ApartmentUpdateBedsProps = {
  data: Local;
  visible: boolean;
  onClose?: () => void;
  onSave?: () => void;
};

export default function ApartmentUpdateBeds({
  data,
  visible,
  onClose,
  onSave,
}: ApartmentUpdateBedsProps) {
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const [bedsCount, setBedsCount] = useState<number>(data.beds_count || 0);
  const [bathroomCount, setBathroomCount] = useState<number>(
    data.bathroom_count || 0
  );
  const [roomCount, setRoomCount] = useState<number>(data.room_count || 0);

  /**
   * Mutate the selected stripe account
   */
  const mutation = useMutation(
    () =>
      updateApartmentV3(data.local_key || "", {
        beds_count: bedsCount,
        bathroom_count: bathroomCount,
        room_count: roomCount,
      }),
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
        queryClient.invalidateQueries(["apartment", data.local_key]);
        if (onClose) onClose();
      },
    }
  );

  return (
    <Modal visible={visible}>
      <ModalCard title={t("updateBeds.updateComposition")}>
        <ModalSection>
          <div className={"p-4 flex-wrap flex -mx-2"}>
            <FormField
              className={"px-2 mb-4 w-full"}
              label={t("updateBeds.roomNumber")}
            >
              <TextInput
                type="number"
                value={roomCount}
                onChange={(value) => setRoomCount(value)}
              ></TextInput>
              {roomCount <= 0 && <p className="text-xs p-1 text-red-500">{t('updateBeds.minApartment')}</p>}
            </FormField>
            <FormField
              className={"px-2 mb-4 w-full"}
              label={t("updateBeds.bathRoomNumber")}
            >
              <TextInput
                type="number"
                value={bathroomCount}
                onChange={(value) => setBathroomCount(value)}
              ></TextInput>
            </FormField>
            <FormField
              className={"px-2 mb-4 w-full"}
              label={t("updateBeds.bedNumber")}
            >
              <TextInput
                type="number"
                value={bedsCount}
                onChange={(value) => setBedsCount(value)}
              ></TextInput>
            </FormField>
          </div>
        </ModalSection>
        <ModalActions
          isLoading={mutation.isLoading}
          saveLabel={t("general.update")}
          saveDisabled={roomCount <= 0}
          onClose={() => {
            if (onClose) onClose();
          }}
          onSave={() => {
            mutation.mutate();
            if (onSave) onSave();
          }}
        ></ModalActions>
      </ModalCard>
    </Modal>
  );
}
