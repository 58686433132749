import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  fetchIntegrationLinking,
  fetchCheckins,
  updateIntegrationLocals,
  fetchAllLocals,
  fetchLocalSmsAddon,
} from '../../shared/queries';
import {
  ApiIntegrationItem,
  BaseSelectItem,
  ListApiResponseData,
  LocalTable,
} from '../../types';
import FormField from '../FormField';
import PageTitle from '../PageTitle';
import PageWrapper from '../PageWrapper';
import SelectInput from '../SelectInput';
import Checkbox from '../Checkbox';
import FormSectionTitle from '../FormSectionTitle';
import { toast } from 'react-toastify';
import Button from '../Button';
import PreviousPage from '../PreviousPage';
import { useTranslation } from 'react-i18next';

const timeOptions = [
  { label: '00:00', value: '00:00:00' },
  { label: '01:00', value: '01:00:00' },
  { label: '02:00', value: '02:00:00' },
  { label: '03:00', value: '03:00:00' },
  { label: '04:00', value: '04:00:00' },
  { label: '05:00', value: '05:00:00' },
  { label: '06:00', value: '06:00:00' },
  { label: '07:00', value: '07:00:00' },
  { label: '08:00', value: '08:00:00' },
  { label: '09:00', value: '09:00:00' },
  { label: '10:00', value: '10:00:00' },
  { label: '11:00', value: '11:00:00' },
  { label: '12:00', value: '12:00:00' },
  { label: '13:00', value: '13:00:00' },
  { label: '14:00', value: '14:00:00' },
  { label: '15:00', value: '15:00:00' },
  { label: '16:00', value: '16:00:00' },
  { label: '17:00', value: '17:00:00' },
  { label: '18:00', value: '18:00:00' },
  { label: '19:00', value: '19:00:00' },
  { label: '20:00', value: '20:00:00' },
  { label: '21:00', value: '21:00:00' },
  { label: '22:00', value: '22:00:00' },
  { label: '23:00', value: '23:00:00' },
];

type IntegrationDetailParams = {
  id: string;
  integration: string;
};

export default function IntegrationDetail() {
  const { t } = useTranslation();

  const { id, integration } = useParams<IntegrationDetailParams>();

  const [currentIntegration, setCurrentIntegration] =
    useState<any>();
  const [local, setLocal] = useState<LocalTable>();

  const { data: apartments, isLoading: loadingApartments } = useQuery<
    ListApiResponseData<LocalTable>,
    Error
  >('locals', fetchAllLocals);

  const { data, isLoading } = useQuery<
    any,
    Error
  >(['linkings', id], () => fetchIntegrationLinking(id));

  const { data: checkInsEmail, isLoading: loadingCheckIns } = useQuery<
    ListApiResponseData<ApiIntegrationItem>,
    Error
  >(['checkinsEmail', currentIntegration], () =>
    fetchCheckins(currentIntegration?.local_key)
  );

  useEffect(() => {
    if (!data) return;
    setCurrentIntegration(
      data?.results.find((d:any) => d.id.toString() === integration.toString())
    );
  }, [data, id]);

  useEffect(() => {
    if (currentIntegration && apartments) {
      const lc = apartments.data.find(
        (ap) => ap.local_key === currentIntegration.local_key
      );
      if (lc) setLocal(lc);
    }
  }, [currentIntegration, apartments]);

  const mutation = useMutation(
    () =>
      updateIntegrationLocals({
        ...currentIntegration,
        action: 'edit_local',
      }),
    {
      onSuccess: () => {
        toast.success(t('general.requestCompleted'));
      },
    }
  );

  function getCurrentCheckin() {
    const data = checkInsEmail?.data.find(
      (ce) => ce.id === currentIntegration?.integr_cust_checkin_ptr
    );
    if (data) {
      return {
        label: data.name,
        value: data.id,
      };
    } else {
      return {
        label: 'Vikey default',
        value: 0,
      };
    }
  }

  function getCurrentApartment() {
    const data = apartments?.data.find(
      (ap) => ap.local_key === currentIntegration?.local_key
    );
    if (data) {
      return {
        label: data.public_name,
        value: data.local_key,
      };
    }
  }

  const { data: smsAddon, isFetching: fetchingSmsAddon } = useQuery(
    ['smsAddon', currentIntegration?.local_key],
    () =>
      fetchLocalSmsAddon(
        currentIntegration ? currentIntegration.local_key : ''
      ),
    {
      enabled: currentIntegration && currentIntegration.local_key !== undefined,
    }
  );

  return (
    <PageWrapper>
      {currentIntegration && (
        <div className="p-4 -my-2">
          <PreviousPage
            className={'mb-4'}
            label={t('general.goBack')}
          ></PreviousPage>

          <PageTitle title={currentIntegration.l_name}></PageTitle>

          <div className="py-2">
            <FormField label={t('integration.apartment')}>
              <SelectInput<BaseSelectItem, false>
                value={getCurrentApartment()}
                onChange={(item) => {
                  if (item) {
                    setCurrentIntegration({
                      ...currentIntegration,
                      local_key: item.value,
                    });
                  }
                }}
                options={
                  apartments
                    ? apartments.data.map((a) => {
                        return {
                          label: a.public_name,
                          value: a.local_key,
                        };
                      })
                    : []
                }
              />
            </FormField>
          </div>

          <div className="flex py-2 -mx-2">
            <div className="w-1/2 px-2">
              <FormField label={'Check-in'}>
                <SelectInput<BaseSelectItem, false>
                  onChange={(value) => {
                    if (value) {
                      setCurrentIntegration({
                        ...currentIntegration,
                        integr_time_checkin: value.value,
                      });
                    }
                  }}
                  defaultValue={timeOptions.find(
                    (t) => t.value === currentIntegration.integr_time_checkin
                  )}
                  options={timeOptions}
                />
              </FormField>
            </div>
            <div className="w-1/2 px-2">
              <FormField label={'Check-out'}>
                <SelectInput<BaseSelectItem, false>
                  onChange={(value) => {
                    if (value) {
                      setCurrentIntegration({
                        ...currentIntegration,
                        integr_time_checkout: value.value,
                      });
                    }
                  }}
                  defaultValue={timeOptions.find(
                    (t) => t.value === currentIntegration.integr_time_checkout
                  )}
                  options={timeOptions}
                />
              </FormField>
            </div>
          </div>

          <div className="py-2">
            <FormField label={t('integration.checkInProcedure')}>
              <SelectInput<BaseSelectItem, false>
                value={getCurrentCheckin()}
                onChange={(value) => {
                  if (value) {
                    setCurrentIntegration({
                      ...currentIntegration,
                      integr_cust_checkin_ptr: value.value,
                    });
                  }
                }}
                options={
                  checkInsEmail
                    ? [
                        ...checkInsEmail.data
                          .filter((a) => a.id && a.name)
                          .map((a) => {
                            return {
                              label: a.objkey,
                              value: a.id,
                            };
                          }),
                        {
                          label: 'Vikey default',
                          value: 0,
                        },
                      ]
                    : []
                }
              />
            </FormField>
          </div>

          <FormSectionTitle title={'Funzionalità'}></FormSectionTitle>

          <div className="py-2">
            <Checkbox
              disabled={
                smsAddon && smsAddon.results ? !smsAddon.results.enabled : true
              }
              checked={currentIntegration.integr_chk_sms === 1}
              onChange={() =>
                setCurrentIntegration({
                  ...currentIntegration,
                  integr_chk_sms:
                    currentIntegration.integr_chk_sms === 1 ? 0 : 1,
                })
              }
              label={t('resv.sendRemindSMS')}
            ></Checkbox>
          </div>

          <div className="py-2">
            <Checkbox
              checked={currentIntegration.integr_chk_mail === 1}
              onChange={() =>
                setCurrentIntegration({
                  ...currentIntegration,
                  integr_chk_mail:
                    currentIntegration.integr_chk_mail === 1 ? 0 : 1,
                })
              }
              label={t('resv.enableEmail')}
            ></Checkbox>
          </div>

          {currentIntegration.integr_chk_mail === 1 && (
            <div className="ml-10 mt-4 mb-4 p-4 border border-gray-300 shadow-inner rounded bg-white">
              <Checkbox
                checked={currentIntegration.integr_chk_cc === 1}
                onChange={() =>
                  setCurrentIntegration({
                    ...currentIntegration,
                    integr_chk_cc:
                      currentIntegration.integr_chk_cc === 1 ? 0 : 1,
                  })
                }
                label={t('resv.enableCCEmail')}
              ></Checkbox>

              <Checkbox
                checked={currentIntegration.integr_notify_now === 1}
                onChange={() =>
                  setCurrentIntegration({
                    ...currentIntegration,
                    integr_notify_now:
                      currentIntegration.integr_notify_now === 1 ? 0 : 1,
                  })
                }
                label={t('resv.createNotification')}
              ></Checkbox>

              <Checkbox
                checked={currentIntegration.integr_notify_7days === 1}
                onChange={() =>
                  setCurrentIntegration({
                    ...currentIntegration,
                    integr_notify_7days:
                      currentIntegration.integr_notify_7days === 1 ? 0 : 1,
                  })
                }
                label={t('resv.sevenDayNotification')}
              ></Checkbox>

              <Checkbox
                checked={currentIntegration.integr_notify_3days === 1}
                onChange={() =>
                  setCurrentIntegration({
                    ...currentIntegration,
                    integr_notify_3days:
                      currentIntegration.integr_notify_3days === 1 ? 0 : 1,
                  })
                }
                label={t('resv.threeDayNotification')}
              ></Checkbox>

              <Checkbox
                checked={currentIntegration.integr_notify_done_1days === 1}
                onChange={() =>
                  setCurrentIntegration({
                    ...currentIntegration,
                    integr_notify_done_1days:
                      currentIntegration.integr_notify_done_1days === 1 ? 0 : 1,
                  })
                }
                label={t('resv.completeNotification')}
              ></Checkbox>
            </div>
          )}

          <div className="py-2">
            <Checkbox
              checked={currentIntegration.integr_chk_citytax === 1}
              onChange={() =>
                setCurrentIntegration({
                  ...currentIntegration,
                  integr_chk_citytax:
                    currentIntegration.integr_chk_citytax === 1 ? 0 : 1,
                })
              }
              disabled={
                currentIntegration.integr_chk_citytax === 0 &&
                (!local ||
                  local == null ||
                  local.stripe_account == null ||
                  local.city_tax_params == null)
              }
              label={t('resv.requestTaxPayment')}
            ></Checkbox>
          </div>

          {currentIntegration.integr_chk_paystay === 1 && (
            <div className="ml-10 mt-4 mb-4 p-4 border border-gray-300 shadow-inner rounded bg-white">
              <Checkbox
                checked={
                  currentIntegration.integr_chk_paystay_chargeguest === 1
                }
                onChange={() =>
                  setCurrentIntegration({
                    ...currentIntegration,
                    integr_chk_paystay_chargeguest:
                      currentIntegration.integr_chk_paystay_chargeguest === 1
                        ? 0
                        : 1,
                  })
                }
                label={t('resv.chargeGuestCommission')}
              ></Checkbox>
            </div>
          )}

          <div className="py-2">
            <Checkbox
              checked={currentIntegration.integr_chk_deposit === 1}
              onChange={() =>
                setCurrentIntegration({
                  ...currentIntegration,
                  integr_chk_deposit:
                    currentIntegration.integr_chk_deposit === 1 ? 0 : 1,
                })
              }
              disabled={
                currentIntegration.integr_chk_deposit === 0 &&
                (!local ||
                  local == null ||
                  local.stripe_account == null)
                  }
              label={t('resv.requestDepositPayment')}
            ></Checkbox>
          </div>

          {currentIntegration.integr_chk_deposit === 1 && (
            <div className="ml-10 mt-4 mb-4 p-4 border border-gray-300 shadow-inner rounded bg-white">
              <Checkbox
                checked={
                  currentIntegration.integr_chk_deposit_chargeguest === 1
                }
                onChange={() =>
                  setCurrentIntegration({
                    ...currentIntegration,
                    integr_chk_deposit_chargeguest:
                      currentIntegration.integr_chk_deposit_chargeguest === 1
                        ? 0
                        : 1,
                  })
                }
                label={t('resv.chargeGuestCommission')}
              ></Checkbox>
            </div>
          )}

          <div className="py-2">
            <Checkbox
              checked={currentIntegration.integr_chk_citytax === 1}
              onChange={() =>
                setCurrentIntegration({
                  ...currentIntegration,
                  integr_chk_citytax:
                    currentIntegration.integr_chk_citytax === 1 ? 0 : 1,
                })
              }
              disabled={
                currentIntegration.integr_chk_citytax === 0 &&
                (!local ||
                  local == null ||
                  local.stripe_account == null ||
                  local.city_tax_params == null)
              }
              label={t('resv.requestTaxPayment')}
            ></Checkbox>
          </div>

          {currentIntegration.integr_chk_citytax === 1 && (
            <div className="ml-10 mt-4 mb-4 p-4 border border-gray-300 shadow-inner rounded bg-white">
              <Checkbox
                checked={
                  currentIntegration.integr_chk_citytax_chargeguest === 1
                }
                onChange={() =>
                  setCurrentIntegration({
                    ...currentIntegration,
                    integr_chk_citytax_chargeguest:
                      currentIntegration.integr_chk_citytax_chargeguest === 1
                        ? 0
                        : 1,
                  })
                }
                label={t('resv.chargeGuestCommission')}
              ></Checkbox>
            </div>
          )}

          <div className="py-2">
            <Checkbox
              checked={currentIntegration.integr_chk_documents === 1}
              onChange={() =>
                setCurrentIntegration({
                  ...currentIntegration,
                  integr_chk_documents:
                    currentIntegration.integr_chk_documents === 1 ? 0 : 1,
                })
              }
              label={t('resv.sendDocuments')}
            ></Checkbox>
          </div>

          {currentIntegration.integr_chk_documents === 1 && (
            <div className="ml-10 mt-4 mb-4 p-4 border border-gray-300 shadow-inner rounded bg-white">
              <Checkbox
                checked={currentIntegration.integr_chk_photo === 1}
                onChange={() =>
                  setCurrentIntegration({
                    ...currentIntegration,
                    integr_chk_photo:
                      currentIntegration.integr_chk_photo === 1 ? 0 : 1,
                  })
                }
                label={t('resv.requestDocumentMainGuest')}
              ></Checkbox>

              <Checkbox
                checked={currentIntegration.integr_chk_photo_all === 1}
                onChange={() =>
                  setCurrentIntegration({
                    ...currentIntegration,
                    integr_chk_photo_all:
                      currentIntegration.integr_chk_photo_all === 1 ? 0 : 1,
                  })
                }
                label={t('resv.requestDocumentOtherGuests')}
              ></Checkbox>

              <Checkbox
                checked={currentIntegration.integr_chk_asknumguests === 1}
                onChange={() =>
                  setCurrentIntegration({
                    ...currentIntegration,
                    integr_chk_asknumguests:
                      currentIntegration.integr_chk_asknumguests === 1 ? 0 : 1,
                  })
                }
                label={t('resv.requestNumberOfGuests')}
              ></Checkbox>
            </div>
          )}

          <div className="py-2">
            <Checkbox
              checked={currentIntegration.integr_chk_contract === 1}
              onChange={() =>
                setCurrentIntegration({
                  ...currentIntegration,
                  integr_chk_contract:
                    currentIntegration.integr_chk_contract === 1 ? 0 : 1,
                })
              }
              label={t('resv.signContract')}
            ></Checkbox>
          </div>

          {currentIntegration.integr_chk_contract === 1 && (
            <div className="ml-10 mt-4 mb-4 p-4 border border-gray-300 shadow-inner rounded bg-white">
              <Checkbox
                checked={currentIntegration.integr_chk_otp === 1}
                onChange={() =>
                  setCurrentIntegration({
                    ...currentIntegration,
                    integr_chk_otp:
                      currentIntegration.integr_chk_otp === 1 ? 0 : 1,
                  })
                }
                label={t('resv.requestOTP')}
              ></Checkbox>
            </div>
          )}

          <div className="py-2">
            <Checkbox
              checked={currentIntegration.integr_chk_invoice === 1}
              onChange={() =>
                setCurrentIntegration({
                  ...currentIntegration,
                  integr_chk_invoice:
                    currentIntegration.integr_chk_invoice === 1 ? 0 : 1,
                })
              }
              label={t('resv.requestInvoice')}
            ></Checkbox>
          </div>

          <div className={'py-2 mt-2'}>
            <Button
              loading={mutation.isLoading}
              onClick={() => mutation.mutate()}
            >
              {t('genera.save')}
            </Button>
          </div>
        </div>
      )}
    </PageWrapper>
  );
}
