import React, { useEffect, useState } from "react";
import Icon from "../Icon";
import FormField from "../FormField";
import TextInput from "../TextInput";
import FormSectionTitle from "../FormSectionTitle";
import Checkbox from "../Checkbox";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import { fetchRateSingleLocal } from "../../shared/queries";
import { useQuery } from "react-query";
import SelectInput from "../SelectInput";
import { currencies } from "../../shared/utils/config";
import { toast } from "react-toastify";

type Props = {
  rateType: string;
  local_key?: string;
  rateId?: string;
  person_count?: number;
  onEditLoading?: boolean;
  onSaveLoading?: boolean;
  onGoBack?: () => void;
  onEdit?: (dataEdit: any) => void;
  onSave?: (dataNew: any) => void;
};

const CreateRateComponent = (props: Props) => {
  const { t } = useTranslation();
  const {
    rateType,
    local_key,
    rateId,
    person_count,
    onEditLoading,
    onSaveLoading,
    onGoBack,
    onEdit,
    onSave,
  } = props;

  const { data } = useQuery<any>(
    ["channelmanagersinglerate", local_key, rateId],
    () => fetchRateSingleLocal(local_key as string, rateId),
    { enabled: local_key !== null && local_key !== undefined }
  );

  const dataForm = data?.results?.attributes;

  const [rateForm, setRateForm] = useState<any>();
  const [restrictionForm, setRestrictionForm] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({
    title: {
      display: false,
      message: `${t("channelManager.rate_plan.title")}: can't be blank`,
    },
    rate: {
      display: false,
      message: `${t("channelManager.rate_plan.rate")}: missing rate`,
    },
    minStayArrival: {
      display: false,
      message: `${t("channelManager.rate_plan.minStayArrival")}: can't be 0`,
    },
    minStayThrough: {
      display: false,
      message: `${t("channelManager.rate_plan.minStayThrough")}: can't be 0`,
    },
  });

  const changeData = (prop: string, value: any) => {
    setRateForm((prev: any) => {
      return {
        ...prev,
        [prop]: value,
      };
    });
  };

  useEffect(() => {
    if (dataForm) {
      setRateForm(dataForm);
    } else {
      const data = Array.from({ length: person_count as number }, (_, i) => {
        return {
          occupancy: i + 1,
          is_primary: i === 0,
          rate: "0.00",
          derived_option: {
            rate: [],
          },
        };
      });
      setRateForm({
        title: "",
        children_fee: "0.00",
        infant_fee: "0.00",
        max_stay: [0, 0, 0, 0, 0, 0, 0],
        min_stay_arrival: [1, 1, 1, 1, 1, 1, 1],
        min_stay_through: [1, 1, 1, 1, 1, 1, 1],
        closed_to_arrival: [false, false, false, false, false, false, false],
        closed_to_departure: [false, false, false, false, false, false, false],
        stop_sell: [false, false, false, false, false, false, false],
        options: data,
        currency: "EUR",
        sell_mode: "per_room",
        rate_mode: "manual",
        auto_rate_settings: {
          decrease_value: "0.00",
          increase_value: "0.00",
          decrease_mode: "$",
          increase_mode: "$",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, person_count]);
  return (
    <div className="p-4 space-y-4 text-sm">
      {onGoBack && (
        <>
          <Icon
            name="arrow-narrow-left"
            size="18px"
            onClick={() => {
              if (onGoBack) onGoBack();
            }}
            className="cursor-pointer"
          />
          {t("general.goBack")}
        </>
      )}
      <FormField
        label={t("channelManager.rate_plan.title") + ": *"}
        tooltip={t("channelManager.rate_plan.titleInfo")}
      >
        <TextInput
          value={rateForm?.title}
          onChange={(val) => {
            changeData("title", val);
            if (val && val !== "") {
              setErrors((prev: any) => {
                return {
                  ...prev,
                  title: {
                    ...prev.title,
                    display: false,
                  },
                };
              });
            }
          }}
        ></TextInput>
        {errors.title.display && (
          <p className="text-red-600 capitalize bold">{errors.title.message}</p>
        )}
      </FormField>
      <p
        onClick={() => setRestrictionForm((prev) => !prev)}
        className="text-cyan-600 cursor-pointer hover:underline"
      >
        {restrictionForm
          ? t("channelManager.rate_plan.hideAdvancedSettings")
          : t("channelManager.rate_plan.showAdvancedSettings")}
      </p>
      {restrictionForm && (
        <>
          <FormSectionTitle
            title={t("channelManager.rate_plan.restrictions")}
          ></FormSectionTitle>
          <FormField
            label={t("channelManager.rate_plan.minStayArrival") + ":"}
            tooltip={t("channelManager.rate_plan.minStayArrivalInfo")}
          >
            <TextInput
              value={
                rateForm?.min_stay_arrival && rateForm?.min_stay_arrival[0]
              }
              onChange={(val) => {
                if (!Number.isNaN(parseFloat(val))) {
                  changeData(
                    "min_stay_arrival",
                    Array(7).fill(parseFloat(val))
                  );
                } else {
                  changeData("min_stay_arrival", Array(7).fill(0));
                }
                if (parseFloat(val) > 0 && !Number.isNaN(parseFloat(val))) {
                  setErrors((prev: any) => {
                    return {
                      ...prev,
                      minStayArrival: {
                        ...prev.minStayArrival,
                        display: false,
                      },
                    };
                  });
                }
              }}
            ></TextInput>
            {errors.minStayArrival.display && (
              <p className="text-red-600 capitalize bold">
                {errors.minStayArrival.message}
              </p>
            )}
          </FormField>
          <FormField
            label={t("channelManager.rate_plan.minStayThrough") + ":"}
            tooltip={t("channelManager.rate_plan.minStayThroughInfo")}
            className="hidden"
          >
            <TextInput
              value={
                rateForm?.min_stay_through && rateForm?.min_stay_through[0]
              }
              onChange={(val) => {
                if (!Number.isNaN(parseFloat(val))) {
                  changeData(
                    "min_stay_through",
                    Array(7).fill(parseFloat(val))
                  );
                } else {
                  changeData("min_stay_through", Array(7).fill(0));
                }
                if (parseFloat(val) > 0 && !Number.isNaN(parseFloat(val))) {
                  setErrors((prev: any) => {
                    return {
                      ...prev,
                      minStayThrough: {
                        ...prev.minStayThrough,
                        display: false,
                      },
                    };
                  });
                }
              }}
            ></TextInput>
            {errors.minStayThrough.display && (
              <p className="text-red-600 capitalize bold">
                {errors.minStayThrough.message}
              </p>
            )}
          </FormField>
          <FormField
            label={t("channelManager.rate_plan.maxStay") + ":"}
            tooltip={t("channelManager.rate_plan.maxStayInfo")}
          >
            <TextInput
              value={rateForm?.max_stay && rateForm?.max_stay[0]}
              onChange={(val) =>
                changeData("max_stay", Array(7).fill(parseFloat(val)))
              }
            ></TextInput>
          </FormField>
          <FormField
            label={t("channelManager.rate_plan.closedToArrival") + ":"}
            tooltip={t("channelManager.rate_plan.closedToArrivalInfo")}
          >
            <div className="md:flex justify-around md:space-y-0 space-y-1">
              <Checkbox
                className="rounded-xl p-0.5 border max-w-min"
                label={t("channelManager.rate_plan.monday")}
                size="sm"
                checked={
                  rateForm?.closed_to_arrival &&
                  (rateForm?.closed_to_arrival[0] as boolean)
                }
                onChange={() => {
                  const testTemp = { ...rateForm };
                  testTemp.closed_to_arrival[0] =
                    !testTemp.closed_to_arrival[0];
                  setRateForm(testTemp);
                }}
              />
              <Checkbox
                className="rounded-xl p-0.5 border max-w-min"
                checked={
                  rateForm?.closed_to_arrival &&
                  (rateForm?.closed_to_arrival[1] as boolean)
                }
                onChange={() => {
                  const testTemp = { ...rateForm };
                  testTemp.closed_to_arrival[1] =
                    !testTemp.closed_to_arrival[1];
                  setRateForm(testTemp);
                }}
                label={t("channelManager.rate_plan.tuesday")}
                size="sm"
              />
              <Checkbox
                className="rounded-xl p-0.5 border max-w-min"
                checked={
                  rateForm?.closed_to_arrival &&
                  (rateForm?.closed_to_arrival[2] as boolean)
                }
                onChange={() => {
                  const testTemp = { ...rateForm };
                  testTemp.closed_to_arrival[2] =
                    !testTemp.closed_to_arrival[2];
                  setRateForm(testTemp);
                }}
                label={t("channelManager.rate_plan.wednesday")}
                size="sm"
              />
              <Checkbox
                className="rounded-xl p-0.5 border max-w-min"
                checked={
                  rateForm?.closed_to_arrival &&
                  (rateForm?.closed_to_arrival[3] as boolean)
                }
                onChange={() => {
                  const testTemp = { ...rateForm };
                  testTemp.closed_to_arrival[3] =
                    !testTemp.closed_to_arrival[3];
                  setRateForm(testTemp);
                }}
                label={t("channelManager.rate_plan.thursday")}
                size="sm"
              />
              <Checkbox
                className="rounded-xl p-0.5 border max-w-min"
                checked={
                  rateForm?.closed_to_arrival &&
                  (rateForm?.closed_to_arrival[4] as boolean)
                }
                onChange={() => {
                  const testTemp = { ...rateForm };
                  testTemp.closed_to_arrival[4] =
                    !testTemp.closed_to_arrival[4];
                  setRateForm(testTemp);
                }}
                label={t("channelManager.rate_plan.friday")}
                size="sm"
              />
              <Checkbox
                className="rounded-xl p-0.5 border max-w-min"
                checked={
                  rateForm?.closed_to_arrival &&
                  (rateForm?.closed_to_arrival[5] as boolean)
                }
                onChange={() => {
                  const testTemp = { ...rateForm };
                  testTemp.closed_to_arrival[5] =
                    !testTemp.closed_to_arrival[5];
                  setRateForm(testTemp);
                }}
                label={t("channelManager.rate_plan.saturday")}
                size="sm"
              />
              <Checkbox
                className="rounded-xl p-0.5 border max-w-min"
                checked={
                  rateForm?.closed_to_arrival &&
                  (rateForm?.closed_to_arrival[6] as boolean)
                }
                onChange={() => {
                  const testTemp = { ...rateForm };
                  testTemp.closed_to_arrival[6] =
                    !testTemp.closed_to_arrival[6];
                  setRateForm(testTemp);
                }}
                label={t("channelManager.rate_plan.sunday")}
                size="sm"
              />
            </div>
          </FormField>
          <FormField
            label={t("channelManager.rate_plan.closedToDeparture") + ":"}
            tooltip={t("channelManager.rate_plan.closedToDepartureInfo")}
          >
            <div className="md:flex justify-around md:space-y-0 space-y-1">
              <Checkbox
                className="rounded-xl p-0.5 border max-w-min"
                checked={
                  rateForm?.closed_to_departure &&
                  (rateForm?.closed_to_departure[0] as boolean)
                }
                onChange={() => {
                  const testTemp = { ...rateForm };
                  testTemp.closed_to_departure[0] =
                    !testTemp.closed_to_departure[0];
                  setRateForm(testTemp);
                }}
                label={t("channelManager.rate_plan.monday")}
                size="sm"
              />
              <Checkbox
                className="rounded-xl p-0.5 border max-w-min"
                checked={
                  rateForm?.closed_to_departure &&
                  (rateForm?.closed_to_departure[1] as boolean)
                }
                onChange={() => {
                  const testTemp = { ...rateForm };
                  testTemp.closed_to_departure[1] =
                    !testTemp.closed_to_departure[1];
                  setRateForm(testTemp);
                }}
                label={t("channelManager.rate_plan.tuesday")}
                size="sm"
              />
              <Checkbox
                className="rounded-xl p-0.5 border max-w-min"
                checked={
                  rateForm?.closed_to_departure &&
                  (rateForm?.closed_to_departure[2] as boolean)
                }
                onChange={() => {
                  const testTemp = { ...rateForm };
                  testTemp.closed_to_departure[2] =
                    !testTemp.closed_to_departure[2];
                  setRateForm(testTemp);
                }}
                label={t("channelManager.rate_plan.wednesday")}
                size="sm"
              />
              <Checkbox
                className="rounded-xl p-0.5 border max-w-min"
                checked={
                  rateForm?.closed_to_departure &&
                  (rateForm?.closed_to_departure[3] as boolean)
                }
                onChange={() => {
                  const testTemp = { ...rateForm };
                  testTemp.closed_to_departure[3] =
                    !testTemp.closed_to_departure[3];
                  setRateForm(testTemp);
                }}
                label={t("channelManager.rate_plan.thursday")}
                size="sm"
              />
              <Checkbox
                className="rounded-xl p-0.5 border max-w-min"
                checked={
                  rateForm?.closed_to_departure &&
                  (rateForm?.closed_to_departure[4] as boolean)
                }
                onChange={() => {
                  const testTemp = { ...rateForm };
                  testTemp.closed_to_departure[4] =
                    !testTemp.closed_to_departure[4];
                  setRateForm(testTemp);
                }}
                label={t("channelManager.rate_plan.friday")}
                size="sm"
              />
              <Checkbox
                className="rounded-xl p-0.5 border max-w-min"
                checked={
                  rateForm?.closed_to_departure &&
                  (rateForm?.closed_to_departure[5] as boolean)
                }
                onChange={() => {
                  const testTemp = { ...rateForm };
                  testTemp.closed_to_departure[5] =
                    !testTemp.closed_to_departure[5];
                  setRateForm(testTemp);
                }}
                label={t("channelManager.rate_plan.saturday")}
                size="sm"
              />
              <Checkbox
                className="rounded-xl p-0.5 border max-w-min"
                checked={
                  rateForm?.closed_to_departure &&
                  (rateForm?.closed_to_departure[6] as boolean)
                }
                onChange={() => {
                  const testTemp = { ...rateForm };
                  testTemp.closed_to_departure[6] =
                    !testTemp.closed_to_departure[6];
                  setRateForm(testTemp);
                }}
                label={t("channelManager.rate_plan.sunday")}
                size="sm"
              />
            </div>
          </FormField>
          <FormField
            label={t("channelManager.rate_plan.stopSell") + ":"}
            tooltip={t("channelManager.rate_plan.stopSellInfo")}
          >
            <div className="md:flex justify-around md:space-y-0 space-y-1">
              <Checkbox
                className="rounded-xl p-0.5 border max-w-min"
                checked={
                  rateForm?.stop_sell && (rateForm?.stop_sell[0] as boolean)
                }
                onChange={() => {
                  const testTemp = { ...rateForm };
                  testTemp.stop_sell[0] = !testTemp.stop_sell[0];
                  setRateForm(testTemp);
                }}
                label={t("channelManager.rate_plan.monday")}
                size="sm"
              />
              <Checkbox
                className="rounded-xl p-0.5 border max-w-min"
                checked={
                  rateForm?.stop_sell && (rateForm?.stop_sell[1] as boolean)
                }
                onChange={() => {
                  const testTemp = { ...rateForm };
                  testTemp.stop_sell[1] = !testTemp.stop_sell[1];
                  setRateForm(testTemp);
                }}
                label={t("channelManager.rate_plan.tuesday")}
                size="sm"
              />
              <Checkbox
                className="rounded-xl p-0.5 border max-w-min"
                checked={
                  rateForm?.stop_sell && (rateForm?.stop_sell[2] as boolean)
                }
                onChange={() => {
                  const testTemp = { ...rateForm };
                  testTemp.stop_sell[2] = !testTemp.stop_sell[2];
                  setRateForm(testTemp);
                }}
                label={t("channelManager.rate_plan.wednesday")}
                size="sm"
              />
              <Checkbox
                className="rounded-xl p-0.5 border max-w-min"
                checked={
                  rateForm?.stop_sell && (rateForm?.stop_sell[3] as boolean)
                }
                onChange={() => {
                  const testTemp = { ...rateForm };
                  testTemp.stop_sell[3] = !testTemp.stop_sell[3];
                  setRateForm(testTemp);
                }}
                label={t("channelManager.rate_plan.thursday")}
                size="sm"
              />
              <Checkbox
                className="rounded-xl p-0.5 border max-w-min"
                checked={
                  rateForm?.stop_sell && (rateForm?.stop_sell[4] as boolean)
                }
                onChange={() => {
                  const testTemp = { ...rateForm };
                  testTemp.stop_sell[4] = !testTemp.stop_sell[4];
                  setRateForm(testTemp);
                }}
                label={t("channelManager.rate_plan.friday")}
                size="sm"
              />
              <Checkbox
                className="rounded-xl p-0.5 border max-w-min"
                checked={
                  rateForm?.stop_sell && (rateForm?.stop_sell[5] as boolean)
                }
                onChange={() => {
                  const testTemp = { ...rateForm };
                  testTemp.stop_sell[5] = !testTemp.stop_sell[5];
                  setRateForm(testTemp);
                }}
                label={t("channelManager.rate_plan.saturday")}
                size="sm"
              />
              <Checkbox
                className="rounded-xl p-0.5 border max-w-min"
                checked={
                  rateForm?.stop_sell && (rateForm?.stop_sell[6] as boolean)
                }
                onChange={() => {
                  const testTemp = { ...rateForm };
                  testTemp.stop_sell[6] = !testTemp.stop_sell[6];
                  setRateForm(testTemp);
                }}
                label={t("channelManager.rate_plan.sunday")}
                size="sm"
              />
            </div>
          </FormField>
        </>
      )}

      <FormSectionTitle
        title={t("channelManager.rate_plan.priceSettings")}
      ></FormSectionTitle>
      <FormField
        label={t("channelManager.rate_plan.currency")}
        tooltip={t("channelManager.rate_plan.currencyInfo")}
      >
        <SelectInput
          // menuShouldScrollIntoView={false}
          isDisabled={rateType === "editrate"}
          menuPortalTarget={document.body}
          value={{
            label: currencies.find((curr) => curr.value === rateForm?.currency)
              ?.label,
            value: currencies.find((curr) => curr.value === rateForm?.currency)
              ?.value,
          }}
          onChange={(item: any) => {
            if (rateType !== "editrate") {
              changeData("currency", item.value);
            }
          }}
          options={currencies}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
      </FormField>
      <FormField
        label={t("channelManager.rate_plan.sellMode") + ":"}
        tooltip={t("channelManager.rate_plan.sellModeInfo")}
      >
        <div className="flex justify-center gap-3">
          <Checkbox
            disabled={rateType === "editrate"}
            className="rounded-xl p-0.5 border"
            checked={rateForm?.sell_mode === "per_room"}
            onChange={() => {
              setRateForm((prev: any) => {
                return {
                  ...prev,
                  sell_mode: "per_room",
                };
              });
            }}
            label={t("channelManager.rate_plan.checkPerRoom")}
            size="sm"
          />
          <Checkbox
            disabled={rateType === "editrate"}
            className="rounded-xl p-0.5 border"
            checked={rateForm?.sell_mode === "per_person"}
            onChange={() => {
              setRateForm((prev: any) => {
                return {
                  ...prev,
                  sell_mode: "per_person",
                };
              });
            }}
            label={t("channelManager.rate_plan.checkPerPerson")}
            size="sm"
          />
        </div>
      </FormField>
      {rateForm?.sell_mode === "per_room" && (
        <FormField
          label={t("channelManager.rate_plan.rate") + ":"}
          tooltip={t("channelManager.rate_plan.rateInfo")}
        >
          <div className="text-yellow-600">* {t("channelManager.rate_plan.rateInfoExtra")}</div>
          <TextInput
            disabled={rateType === "editrate"}
            suffix={rateForm.currency}
            min={0}
            type="number"
            value={rateForm.options && parseFloat(rateForm.options[0]?.rate)}
            onChange={(val) => {
              const testTemp = { ...rateForm };
              if (parseFloat(val) >= 0 && !Number.isNaN(parseFloat(val))) {
                testTemp.options[0].rate = parseFloat(val).toFixed(2);
                setRateForm(testTemp);
              }
              if (parseFloat(val) > 0) {
                setErrors((prev: any) => {
                  return {
                    ...prev,
                    rate: {
                      ...prev.rate,
                      display: false,
                    },
                  };
                });
              }
            }}
          ></TextInput>
          {errors.rate.display && (
            <p className="text-red-600 capitalize bold">
              {errors.rate.message}
            </p>
          )}
        </FormField>
      )}
      {rateForm?.sell_mode === "per_person" && (
        <>
          <FormField
            label={t("channelManager.rate_plan.rateMode") + ":"}
            tooltip={t("channelManager.rate_plan.rateModeInfo")}
          >
            <div className="flex justify-center gap-3">
              <Checkbox
                className="rounded-xl p-0.5 border"
                checked={rateForm?.rate_mode === "manual"}
                disabled={rateType === "editrate"}
                onChange={() => {
                  const data = Array.from(
                    { length: person_count as number },
                    (_, i) => {
                      return {
                        occupancy: i + 1,
                        is_primary: i === 0,
                        rate: "0.00",
                        derived_option: {
                          rate: [],
                        },
                      };
                    }
                  );
                  setRateForm((prev: any) => {
                    return {
                      ...prev,
                      rate_mode: "manual",
                      auto_rate_settings: {
                        decrease_value: "0.00",
                        increase_value: "0.00",
                        decrease_mode: "$",
                        increase_mode: "$",
                      },
                      options: data,
                    };
                  });
                }}
                label={t("channelManager.rate_plan.rateModeManual")}
                size="sm"
              />
              {/* <Checkbox
                className="rounded-xl p-0.5 border"
                checked={rateForm?.rate_mode === "derived"}
                disabled={rateType === "editrate"}
                onChange={() => {
                  setRateForm((prev: any) => {
                    return {
                      ...prev,
                      rate_mode: "derived",
                    };
                  });
                }}
                label="Derived"
                size="sm"
              /> */}
              <Checkbox
                className="rounded-xl p-0.5 border"
                checked={rateForm?.rate_mode === "auto"}
                disabled={rateType === "editrate"}
                onChange={() => {
                  const data = Array.from(
                    { length: person_count as number },
                    (_, i) => {
                      return {
                        occupancy: i + 1,
                        is_primary: i === 0,
                        rate: "0.00",
                        derived_option: {
                          rate: [],
                        },
                      };
                    }
                  );
                  setRateForm((prev: any) => {
                    return {
                      ...prev,
                      rate_mode: "auto",
                      auto_rate_settings: {
                        decrease_value: "0.00",
                        increase_value: "0.00",
                        decrease_mode: "$",
                        increase_mode: "$",
                      },
                      options: data,
                    };
                  });
                }}
                label={t("channelManager.rate_plan.rateModeAuto")}
                size="sm"
              />
            </div>
          </FormField>
          {rateForm?.rate_mode === "manual" && (
            <>
              {rateType === "createrate" &&
                Array.from({ length: person_count as number }, (_, i) => (
                  <FormField
                    key={i}
                    label={t("channelManager.rate_plan.ratePerPerson", {
                      total: i + 1,
                    })}
                    tooltip={t("channelManager.rate_plan.ratePerPersonInfo")}
                  >
                    <TextInput
                      suffix={rateForm.currency}
                      min={0}
                      type="number"
                      value={
                        rateForm.options[i]?.rate
                          ? parseFloat(rateForm.options[i]?.rate)
                          : 0
                      }
                      onChange={(val) => {
                        const testTemp = { ...rateForm };
                        if (
                          parseFloat(val) >= 0 &&
                          !Number.isNaN(parseFloat(val))
                        ) {
                          testTemp.options[i].rate = parseFloat(val).toFixed(2);
                          setRateForm(testTemp);
                        }
                        if (i === 0) {
                          if (parseFloat(val) > 0) {
                            setErrors((prev: any) => {
                              return {
                                ...prev,
                                rate: {
                                  ...prev.rate,
                                  display: false,
                                },
                              };
                            });
                          }
                        }
                      }}
                    ></TextInput>
                    {i === 0 && errors.rate.display && (
                      <p className="text-red-600 capitalize bold">
                        {errors.rate.message}
                      </p>
                    )}
                  </FormField>
                ))}
              {rateType === "editrate" &&
                rateForm.options
                  ?.sort((a: any, b: any) => a.occupancy - b.occupancy)
                  ?.map((rate: any, idx: number) => (
                    <FormField
                      label={t("channelManager.rate_plan.ratePerPerson", {
                        total: rate.occupancy,
                      })}
                      tooltip={t("channelManager.rate_plan.ratePerPersonInfo")}
                    >
                      <TextInput
                        disabled
                        suffix={rateForm.currency}
                        min={0}
                        type="number"
                        value={parseFloat(rate.rate)}
                        onChange={(val) => {
                          const testTemp = { ...rateForm };
                          if (
                            parseFloat(val) >= 0 &&
                            !Number.isNaN(parseFloat(val))
                          ) {
                            testTemp.options[idx].rate =
                              parseFloat(val).toFixed(2);
                            setRateForm(testTemp);
                          }
                          if (idx === 0) {
                            if (parseFloat(val) > 0) {
                              setErrors((prev: any) => {
                                return {
                                  ...prev,
                                  rate: {
                                    ...prev.rate,
                                    display: false,
                                  },
                                };
                              });
                            }
                          }
                        }}
                      ></TextInput>
                      {idx === 0 && errors.rate.display && (
                        <p className="text-red-600 capitalize bold">
                          {errors.rate.message}
                        </p>
                      )}
                    </FormField>
                  ))}
              <FormField
                label={t("channelManager.rate_plan.childrenFee") + ":"}
                tooltip={t("channelManager.rate_plan.childrenFeeInfo")}
              >
                <TextInput
                  suffix={rateForm.currency}
                  disabled={rateType === "editrate"}
                  min={0}
                  type="number"
                  value={parseFloat(rateForm.children_fee)}
                  onChange={(val) => {
                    if (
                      parseFloat(val) >= 0 &&
                      !Number.isNaN(parseFloat(val))
                    ) {
                      changeData("children_fee", parseFloat(val).toFixed(2));
                    }
                  }}
                ></TextInput>
              </FormField>
              <FormField
                label={t("channelManager.rate_plan.infantFee") + ":"}
                tooltip={t("channelManager.rate_plan.infantFeeInfo")}
              >
                <TextInput
                  suffix={rateForm.currency}
                  disabled={rateType === "editrate"}
                  min={0}
                  type="number"
                  value={parseFloat(rateForm.infant_fee)}
                  onChange={(val) => {
                    if (
                      parseFloat(val) >= 0 &&
                      !Number.isNaN(parseFloat(val))
                    ) {
                      changeData("infant_fee", parseFloat(val).toFixed(2));
                    }
                  }}
                ></TextInput>
              </FormField>
            </>
          )}
          {/* {rateForm?.rate_mode === "derived" && (
            <>
              <FormField
                label={"Primary occupancy:"}
                tooltip="Primary occupancy"
              >
                <TextInput
                  type="number"
                  value={
                    rateForm.options &&
                    parseFloat(rateForm.options[0]?.occupancy)
                  }
                  onChange={(val) => {
                    const testTemp = { ...rateForm };
                    testTemp.options[0].occupancy = parseFloat(val).toFixed(2);
                    setRateForm(testTemp);
                  }}
                ></TextInput>
              </FormField>
              <FormField label={"Rate:"} tooltip="Quanto di vendita: * €">
                <TextInput
                  suffix="€"
                  type="number"
                  value={
                    rateForm.options && parseFloat(rateForm.options[0]?.rate)
                  }
                  onChange={(val) => {
                    const testTemp = { ...rateForm };
                    testTemp.options[0].rate = parseFloat(val).toFixed(2);
                    setRateForm(testTemp);
                  }}
                ></TextInput>
              </FormField>
              <FormField
                label={"Children Fee:"}
                tooltip="Quanto di vendita: (Children Fee) * €"
              >
                <TextInput
                  suffix="€"
                  type="number"
                  value={parseFloat(rateForm.children_fee)}
                  onChange={(val) =>
                    changeData("children_fee", parseFloat(val).toFixed(2))
                  }
                ></TextInput>
              </FormField>
              <FormField
                label={"Infant Fee:"}
                tooltip="Quanto di vendita: (Infant Fee) * €"
              >
                <TextInput
                  suffix="€"
                  type="number"
                  value={parseFloat(rateForm.infant_fee)}
                  onChange={(val) =>
                    changeData("infant_fee", parseFloat(val).toFixed(2))
                  }
                ></TextInput>
              </FormField>
            </>
          )} */}
          {rateForm?.rate_mode === "auto" && (
            <>
              <FormField
                label={t("channelManager.rate_plan.primaryOccupancy")}
                tooltip={t("channelManager.rate_plan.primaryOccupancyInfo")}
              >
                <SelectInput
                  // menuShouldScrollIntoView={false}
                  isDisabled={rateType === "editrate"}
                  menuPortalTarget={document.body}
                  onChange={(item: any) => {
                    if (rateType !== "editrate") {
                      const testTemp = { ...rateForm };
                      testTemp.options.map((x: any) => {
                        if (x.occupancy !== item.value) {
                          x.is_primary = false;
                          x.rate = "0.00";
                          x.derived_option.rate = [];
                        } else {
                          x.is_primary = true;
                          x.rate = "0.00";
                          x.derived_option.rate = [];
                        }
                        return x;
                      });
                      testTemp.auto_rate_settings.increase_value = "0.00";
                      testTemp.auto_rate_settings.decrease_value = "0.00";
                      testTemp.auto_rate_settings.increase_mode = "$";
                      testTemp.auto_rate_settings.decrease_mode = "$";
                      setRateForm(testTemp);
                    }
                  }}
                  value={{
                    label: (rateForm.options?.find((y: any) => y.is_primary))
                      .occupancy,
                    value: (rateForm.options?.find((y: any) => y.is_primary))
                      .occupancy,
                  }}
                  options={
                    Array.from({ length: person_count as number }, (_x, i) => {
                      return {
                        label: i + 1,
                        value: i + 1,
                      };
                    }) ?? []
                  }
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
              </FormField>
              <FormField
                label={t("channelManager.rate_plan.rate") + ":"}
                tooltip={t("channelManager.rate_plan.rateInfo")}
              >
                <TextInput
                  disabled={rateType === "editrate"}
                  suffix={rateForm.currency}
                  min={0}
                  type="number"
                  value={
                    rateForm.options &&
                    parseFloat(
                      rateForm.options.find((x: any) => x.is_primary).rate
                    )
                  }
                  onChange={(val: any) => {
                    const testTemp = { ...rateForm };
                    if (
                      parseFloat(val) >= 0 &&
                      !Number.isNaN(parseFloat(val))
                    ) {
                      testTemp.options.map((x: any) => {
                        x.rate = parseFloat(val).toFixed(2);
                        return x;
                      });
                      let data: any;
                      testTemp.options.map((option: any, idx: number) => {
                        if (option.is_primary) {
                          const dataTest = testTemp.options.map(
                            (opt: any, idxII: number) => {
                              if (idxII <= idx) {
                                return {
                                  ...opt,
                                  derived_option: {
                                    rate: opt.derived_option.rate[0]
                                      ? [
                                          [
                                            opt.derived_option.rate[0][0],
                                            (
                                              parseFloat(
                                                testTemp.auto_rate_settings
                                                  .decrease_value
                                              ) *
                                              (idx - idxII)
                                            ).toFixed(2),
                                          ],
                                        ]
                                      : [],
                                  },
                                };
                              } else {
                                return {
                                  ...opt,
                                  derived_option: {
                                    rate: opt.derived_option.rate[0]
                                      ? [
                                          [
                                            opt.derived_option.rate[0][0],
                                            (
                                              parseFloat(
                                                testTemp.auto_rate_settings
                                                  .increase_value
                                              ) *
                                              (idxII - idx)
                                            ).toFixed(2),
                                          ],
                                        ]
                                      : [],
                                  },
                                };
                              }
                            }
                          );
                          data = dataTest;
                          return {
                            ...option,
                          };
                        }
                        return {
                          ...option,
                        };
                      });
                      testTemp.options = data;
                      setRateForm(testTemp);
                    }
                    if (parseFloat(val) > 0) {
                      setErrors((prev: any) => {
                        return {
                          ...prev,
                          rate: {
                            ...prev.rate,
                            display: false,
                          },
                        };
                      });
                    }
                  }}
                ></TextInput>
                {errors.rate.display && (
                  <p className="text-red-600 capitalize bold">
                    {errors.rate.message}
                  </p>
                )}
              </FormField>
              <FormField
                label={t("channelManager.rate_plan.increasedBy") + ":"}
                tooltip={t("channelManager.rate_plan.increasedByInfo")}
              >
                <div className="md:flex justify-center gap-5">
                  <div className="flex-1">
                    <TextInput
                      disabled={rateType === "editrate"}
                      min={0}
                      type="number"
                      value={parseFloat(
                        rateForm?.auto_rate_settings?.increase_value
                      )}
                      onChange={(val) => {
                        const testTemp = { ...rateForm };
                        if (
                          parseFloat(val) >= 0 &&
                          !Number.isNaN(parseFloat(val))
                        ) {
                          testTemp.auto_rate_settings.increase_value =
                            parseFloat(val).toFixed(2) ?? "0.00";
                          let data: any;
                          testTemp.options.map((option: any, idx: number) => {
                            if (option.is_primary) {
                              const dataTest = testTemp.options.map(
                                (opt: any, idxII: number) => {
                                  if (idxII <= idx) {
                                    return opt;
                                  } else {
                                    return {
                                      ...opt,
                                      derived_option: {
                                        rate: [
                                          [
                                            testTemp.auto_rate_settings
                                              .increase_mode === "$"
                                              ? "increase_by_amount"
                                              : "increase_by_percent",
                                            (
                                              parseFloat(val) *
                                              (idxII - idx)
                                            ).toFixed(2),
                                          ],
                                        ],
                                      },
                                    };
                                  }
                                }
                              );
                              data = dataTest;
                              return {
                                ...option,
                              };
                            }
                            return {
                              ...option,
                            };
                          });
                          testTemp.options = data;
                          setRateForm(testTemp);
                        }
                      }}
                    ></TextInput>
                  </div>
                  <div className="flex-2 md:mt-0 mt-3">
                    <SelectInput
                      placeholder="Increased by"
                      menuPortalTarget={document.body}
                      isDisabled={rateType === "editrate"}
                      value={{
                        label: [
                          {
                            label: rateForm.currency,
                            value: "$",
                          },
                          {
                            label: "%",
                            value: "%",
                          },
                        ].find(
                          (curr) =>
                            curr.value ===
                            rateForm?.auto_rate_settings.increase_mode
                        )?.label,
                        value: [
                          {
                            label: rateForm.currency,
                            value: "$",
                          },
                          {
                            label: "%",
                            value: "%",
                          },
                        ].find(
                          (curr) =>
                            curr.value ===
                            rateForm?.auto_rate_settings.increase_mode
                        )?.value,
                      }}
                      options={[
                        {
                          label: rateForm.currency,
                          value: "$",
                        },
                        {
                          label: "%",
                          value: "%",
                        },
                      ]}
                      onChange={(item: any) => {
                        if (rateType !== "editrate") {
                          const testTemp = { ...rateForm };
                          testTemp.auto_rate_settings.increase_mode =
                            item.value;
                          let data: any;
                          testTemp.options.map((option: any, idx: number) => {
                            if (option.is_primary) {
                              const dataTest = testTemp.options.map(
                                (opt: any, idxII: number) => {
                                  if (idxII <= idx) {
                                    return opt;
                                  } else {
                                    return {
                                      ...opt,
                                      derived_option: {
                                        rate: opt.derived_option.rate[0]
                                          ? [
                                              [
                                                item.value === "$"
                                                  ? "increase_by_amount"
                                                  : "increase_by_percent",
                                                opt.derived_option.rate[0][1],
                                              ],
                                            ]
                                          : [],
                                      },
                                    };
                                  }
                                }
                              );
                              data = dataTest;
                              return {
                                ...option,
                              };
                            }
                            return {
                              ...option,
                            };
                          });
                          testTemp.options = data;
                          setRateForm(testTemp);
                        }
                      }}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </div>
                </div>
              </FormField>
              <FormField
                label={t("channelManager.rate_plan.decreasedBy") + ":"}
                tooltip={t("channelManager.rate_plan.decreasedByInfo")}
              >
                <div className="md:flex justify-center gap-5">
                  <div className="flex-1">
                    <TextInput
                      disabled={rateType === "editrate"}
                      min={0}
                      type="number"
                      value={parseFloat(
                        rateForm?.auto_rate_settings?.decrease_value
                      )}
                      onChange={(val) => {
                        const testTemp = { ...rateForm };
                        if (
                          parseFloat(val) >= 0 &&
                          !Number.isNaN(parseFloat(val))
                        ) {
                          testTemp.auto_rate_settings.decrease_value =
                            parseFloat(val).toFixed(2) ?? "0.00";
                          let data: any;
                          testTemp.options.map((option: any, idx: number) => {
                            if (option.is_primary) {
                              const dataTest = testTemp.options.map(
                                (opt: any, idxII: number) => {
                                  if (idxII >= idx) {
                                    return opt;
                                  } else {
                                    return {
                                      ...opt,
                                      derived_option: {
                                        rate: [
                                          [
                                            testTemp.auto_rate_settings
                                              .increase_mode === "$"
                                              ? "decrease_by_amount"
                                              : "decrease_by_percent",
                                            (
                                              parseFloat(val) *
                                              (idx - idxII)
                                            ).toFixed(2),
                                          ],
                                        ],
                                      },
                                    };
                                  }
                                }
                              );
                              data = dataTest;
                              return {
                                ...option,
                              };
                            }
                            return {
                              ...option,
                            };
                          });
                          testTemp.options = data;
                          setRateForm(testTemp);
                        }
                      }}
                    ></TextInput>
                  </div>
                  <div className="flex-2 md:mt-0 mt-3">
                    <SelectInput
                      isDisabled={rateType === "editrate"}
                      placeholder="Decreased by"
                      menuPortalTarget={document.body}
                      value={{
                        label: [
                          {
                            label: rateForm.currency,
                            value: "$",
                          },
                          {
                            label: "%",
                            value: "%",
                          },
                        ].find(
                          (curr) =>
                            curr.value ===
                            rateForm?.auto_rate_settings.decrease_mode
                        )?.label,
                        value: [
                          {
                            label: rateForm.currency,
                            value: "$",
                          },
                          {
                            label: "%",
                            value: "%",
                          },
                        ].find(
                          (curr) =>
                            curr.value ===
                            rateForm?.auto_rate_settings.decrease_mode
                        )?.value,
                      }}
                      options={[
                        {
                          label: rateForm.currency,
                          value: "$",
                        },
                        {
                          label: "%",
                          value: "%",
                        },
                      ]}
                      onChange={(item: any) => {
                        const testTemp = { ...rateForm };
                        testTemp.auto_rate_settings.decrease_mode = item.value;
                        let data: any;
                        testTemp.options.map((option: any, idx: number) => {
                          if (option.is_primary) {
                            const dataTest = testTemp.options.map(
                              (opt: any, idxII: number) => {
                                if (idxII >= idx) {
                                  return opt;
                                } else {
                                  return {
                                    ...opt,
                                    derived_option: {
                                      rate: opt.derived_option.rate[0]
                                        ? [
                                            [
                                              item.value === "$"
                                                ? "decrease_by_amount"
                                                : "decrease_by_percent",
                                              opt.derived_option.rate[0][1],
                                            ],
                                          ]
                                        : [],
                                    },
                                  };
                                }
                              }
                            );
                            data = dataTest;
                            return {
                              ...option,
                            };
                          }
                          return {
                            ...option,
                          };
                        });
                        testTemp.options = data;
                        setRateForm(testTemp);
                      }}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </div>
                </div>
              </FormField>
              <FormField
                label={t("channelManager.rate_plan.childrenFee") + ":"}
                tooltip={t("channelManager.rate_plan.childrenFeeInfo")}
              >
                <TextInput
                  suffix={rateForm.currency}
                  disabled={rateType === "editrate"}
                  min={0}
                  type="number"
                  value={parseFloat(rateForm.children_fee)}
                  onChange={(val) => {
                    if (
                      parseFloat(val) >= 0 &&
                      !Number.isNaN(parseFloat(val))
                    ) {
                      changeData("children_fee", parseFloat(val).toFixed(2));
                    }
                  }}
                ></TextInput>
              </FormField>
              <FormField
                label={t("channelManager.rate_plan.infantFee") + ":"}
                tooltip={t("channelManager.rate_plan.infantFeeInfo")}
              >
                <TextInput
                  disabled={rateType === "editrate"}
                  suffix={rateForm.currency}
                  min={0}
                  type="number"
                  value={parseFloat(rateForm.infant_fee)}
                  onChange={(val) => {
                    if (
                      parseFloat(val) >= 0 &&
                      !Number.isNaN(parseFloat(val))
                    ) {
                      changeData("infant_fee", parseFloat(val).toFixed(2));
                    }
                  }}
                ></TextInput>
              </FormField>
            </>
          )}
        </>
      )}
      <div className="text-center">
        {rateType === "editrate" ? (
          <Button
            padding={"md"}
            size={"small"}
            label={t("general.save")}
            disabled={onEditLoading}
            loading={onEditLoading}
            onClick={() => {
              if (rateForm.sell_mode === "per_room") {
                const result = rateForm.options.filter(
                  (opt: any) => opt.rate !== "0.00"
                );
                const newData = [
                  {
                    occupancy: result[0].occupancy,
                    is_primary: true,
                    rate: result[0].rate,
                    derived_option: {
                      rate: [],
                    },
                  },
                ];
                const data = {
                  ...rateForm,
                  options: newData,
                };
                if (onEdit) onEdit(data);
                return;
              }
              if (onEdit) onEdit(rateForm);
            }}
          />
        ) : (
          <Button
            padding={"md"}
            size={"small"}
            label={t("channelManager.rate_plan.next")}
            disabled={onSaveLoading}
            loading={onSaveLoading}
            onClick={() => {
              if (rateForm.title === "" || !rateForm) {
                setErrors((prev: any) => {
                  return {
                    ...prev,
                    title: {
                      ...prev.title,
                      display: true,
                    },
                  };
                });
                toast.error(errors.title.message);
                return;
              }
              if (
                parseFloat(rateForm.min_stay_arrival[0]) <= 0 ||
                Number.isNaN(parseFloat(rateForm.min_stay_arrival[0]))
              ) {
                setErrors((prev: any) => {
                  return {
                    ...prev,
                    minStayArrival: {
                      ...prev.minStayArrival,
                      display: true,
                    },
                  };
                });
                toast.error(errors.minStayArrival.message);
                return;
              }
              if (
                parseFloat(rateForm.min_stay_through[0]) <= 0 ||
                Number.isNaN(parseFloat(rateForm.min_stay_arrival[0]))
              ) {
                setErrors((prev: any) => {
                  return {
                    ...prev,
                    minStayThrough: {
                      ...prev.minStayThrough,
                      display: true,
                    },
                  };
                });
                toast.error(errors.minStayThrough.message);
                return;
              }
              if (parseFloat(rateForm.options[0].rate) <= 0) {
                setErrors((prev: any) => {
                  return {
                    ...prev,
                    rate: {
                      ...prev.rate,
                      display: true,
                    },
                  };
                });
                toast.error(errors.rate.message);
                return;
              }
              if (rateForm.sell_mode === "per_room") {
                const result = rateForm.options.filter(
                  (opt: any) => opt.rate !== "0.00"
                );
                const newData = [
                  {
                    occupancy: person_count,
                    is_primary: true,
                    rate: result[0].rate,
                    derived_option: {
                      rate: [],
                    },
                  },
                ];
                const data = {
                  ...rateForm,
                  options: newData,
                };
                if (onSave) onSave(data);
                return;
              }
              if (rateForm.rate_mode === "manual") {
                const result = rateForm.options.map((opt: any, idx: number) => {
                  return {
                    ...opt,
                    derived_option: idx === 0 ? opt.derived_option : null,
                  };
                });
                const data = {
                  ...rateForm,
                  options: result,
                };
                if (onSave) onSave(data);
                return;
              }
              if (onSave) onSave(rateForm);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default CreateRateComponent;
