import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import {
  BaseSelectItem,
  ServiceQuestion,
  ServiceQuestionSelval,
} from '../../types';
import FormField from '../FormField';
import Icon from '../Icon';
import SelectInput from '../SelectInput';
import TextInput from '../TextInput';

type UpdateServiceQuestionProps = {
  data: ServiceQuestion;
  onChange?: (data: ServiceQuestion) => void;
};

export default function UpdateServiceQuestion({
  data,
  onChange,
}: UpdateServiceQuestionProps) {
  const { t } = useTranslation();

  const [question, setQuestion] = useState<ServiceQuestion>();

  useEffect(() => {
    if (data) setQuestion(data);
  }, []);

  useEffect(() => {
    if (onChange && question) {
      onChange(question);
    }
  }, [question]);

  return (
    <div>
      {question && (
        <div className={'flex border-b p-4'}>
          <div className={'flex-1'}>
            <div className="flex -mx-3 mb-6">
              <FormField
                className={'px-3 flex-1'}
                label={t('serviceUpdateQuestion.yourQuestion')}
              >
                <TextInput
                  value={question.name}
                  onChange={(val) =>
                    setQuestion({
                      ...question,
                      name: val,
                    })
                  }
                ></TextInput>
              </FormField>

              <FormField
                className={'px-3 flex-1'}
                label={t('serviceUpdateQuestion.choiceType')}
              >
                <SelectInput<BaseSelectItem, false>
                  menuShouldScrollIntoView={false}
                  value={{
                    label:
                      question.type === 'input'
                        ? t('serviceQuestionItem.freeText')
                        : t('serviceQuestionItem.select'),
                    value: question.type || '',
                  }}
                  options={[
                    {
                      label: t('serviceQuestionItem.freeText'),
                      value: 'input',
                    },
                    {
                      label: t('serviceQuestionItem.select'),
                      value: 'select',
                    },
                  ]}
                  onChange={(val) => {
                    if (val?.value === 'input' || val?.value === 'select') {
                      setQuestion({
                        ...question,
                        type: val?.value,
                      });
                    }
                  }}
                />
              </FormField>
            </div>

            {question.type === 'select' && (
              <>
                <div className="font-medium mb-3 text-gray-600">
                  {t('serviceUpdateQuestion.availableChoices')}
                </div>
                {question.selvals?.map(
                  (val: ServiceQuestionSelval, index: number) => (
                    <div key={index} className={'relative'}>
                      <input
                        value={val.name}
                        placeholder={
                          t('serviceUpdateQuestion.choice') + ' ' + (index + 1)
                        }
                        className={`${
                          index === 0 ? 'rounded-t-2xl' : ''
                        } ${
                          index + 1 === question.selvals?.length
                            ? 'rounded-b-2xl'
                            : ''
                        } w-full text-sm bg-white shadow appearance-none px-2 py-1 h-9 border border-gray-300 focus:outline-none focus:ring focus:ring-blue-200 focus:border-blue-400`}
                        onChange={(event) => {
                          setQuestion({
                            ...question,
                            selvals: question.selvals?.map(
                              (val: ServiceQuestionSelval, idx: number) => {
                                if (idx === index)
                                  return {
                                    name: event.target.value,
                                    cost: 0,
                                  };
                                return val;
                              }
                            ),
                          });
                        }}
                      ></input>
                      <Icon
                        className={
                          'cursor-pointer hover:text-red-600 absolute top-2 right-2 text-red-400'
                        }
                        name={'trash'}
                        size={'20px'}
                        onClick={() => {
                          setQuestion({
                            ...question,
                            selvals: question.selvals?.filter(
                              (val: ServiceQuestionSelval, idx: number) => {
                                if (idx !== index) return val;
                              }
                            ),
                          });
                        }}
                      ></Icon>
                    </div>
                  )
                )}

                <button
                  className={
                    'mt-3 bg-cyan-100 text-sm px-2 py-1 cursor-pointer text-cyan-600 rounded-2xl'
                  }
                  onClick={() => {
                    setQuestion({
                      ...question,
                      selvals: [
                        ...(question.selvals || []),
                        {
                          name: '',
                          cost: 0,
                        },
                      ],
                    });
                  }}
                >
                  {t('serviceUpdateQuestion.addChoice')}
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
