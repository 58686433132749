import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery, useMutation } from "react-query"
import { useHistory } from "react-router-dom"
import DeleteDialog from '../components/DeleteDialog';
import Button from "../components/Button"
import CreateEmail from "../components/emails/CreateEmail"
import PageTitle from "../components/PageTitle"
import { fetchCustomEmails,DeleteCustPtr } from "../shared/queries"
import { toast } from 'react-toastify'

export default function CustomEmails () {

  const { t } = useTranslation()

  const {
    data,
    refetch: refetchCustomEmails
  } = useQuery('customEmails', () => fetchCustomEmails(undefined, true))


  const history = useHistory()


  const [addEmail, setAddEmail] = useState(false)

  const [showDelete, setShowDelete] = useState(false)

  const [contract_id, setContract_id] = useState<any>(false)

  const deleteMutation = useMutation((id) => DeleteCustPtr(id),  {onSuccess: () => {refetchCustomEmails(); toast.success(t('toast.successTitle'))}, onError: () => {toast.error(t('general.requestError'))}} )


  return (
    <div>
      <div className="inline-flex w-full justify-between">
        <PageTitle
          title={t('customEmails.customEmails')}
          subtitle={t('customEmails.customEmailsText')}></PageTitle>
        <div>
          <Button
            onClick={() => setAddEmail(true)}
            label={t('customEmails.newEmail')} />
        </div>
      </div>      

      <div className={'space-y-4'}>
        {
          (data && data.results) && data.results.map((contract, index) => (
            <div
              className={'justify-between p-4 cst-shadow border rounded-2xl hover:bg-cyan-50 flex'} key={index}>
              <div className="font-medium">{contract.objkey}</div>
              <div >
              <Button icon={'pencil'} padding={'xs'} flat onClick={() => {history.push('/emails/' + contract.id)}}></Button>
              <Button icon={'trash'} padding={'xs'} flat onClick={() => {setShowDelete(true); setContract_id(contract?.id)}}></Button>
              </div>
            </div>
          ))
        }
      </div>

      <CreateEmail
        visible={addEmail}
        onClose={() => setAddEmail(false)} />

        <DeleteDialog
        isLoading={deleteMutation.isLoading}
        visible={showDelete}
        message={t('services.removeService')}
        confirmationKey={t('general.iConfirm')}
        onCancel={() => setShowDelete(false)}
        onConfirm={() => {
          deleteMutation.mutate(contract_id);
          setShowDelete(false)
        }}
      />

    </div>

  )

}