import { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { RootState } from "../../reducers"
import { BaseSelectItem, DomoticDevice, DomoticEntity, EntityCondition } from "../../types"
import TextInput from "../TextInput"
import SelectInput from "../SelectInput";


export default function RuleManagerCondition ({
  type,
  device,
  initialTimespan,
  initialValue,
  entity,
  onChange
}: {
  initialTimespan?: number,
  type?: string,
  initialValue?: string,
  device?: DomoticDevice,
  entity?: DomoticEntity,
  onChange: (condition: EntityCondition, value?: string | null, timespan?: number) => void
}) {

  const { t } = useTranslation()

  const [entityDefinition, setEntityDefinition] = useState<DomoticEntity | null>()

  const [condition, setCondition] = useState<EntityCondition | null>()
  const [value, setValue] = useState<string | null>()
  const [timespan, setTimespan] = useState<number>(0)

  const entities = useSelector((state: RootState) => state.rule.entities)

  useEffect(() => {
    if (entity) {
      const definition = entities.find(e => e.entity_type === entity.entity_type)
      if (definition) {
        setEntityDefinition(definition)
      } else {
        setEntityDefinition(null)
      }
    } else {
      setEntityDefinition(null)
      setCondition(null)
      setTimespan(0)
    }
  }, [ device, entity, entities ])

  useEffect(() => {
    if (type && entityDefinition) {
      const selectedCondition = entityDefinition.conditions.find(c => c.type === type)
      if (selectedCondition) {
        setCondition(selectedCondition)
        setTimespan(initialTimespan || 0)
        setValue(initialValue)
      } else {
        setCondition(null)
      }
    }
  }, [ initialValue, initialTimespan, type, entityDefinition ])

  useEffect(() => {
    if (condition) onChange(condition, value, timespan)
  }, [
    condition,
    value,
    timespan
  ])

  return (
    <Fragment>
      {
        (entityDefinition && entityDefinition.conditions.length > 0) ?
        <div className="bg-cyan-50 border-l-4 border-cyan-600 py-2 px-4 cursor-pointer">
          <div className="flex space-x-4 items-center">
            <div className={'w-36 font-medium text-sm'}>{t('rules.comparator')}</div>
            <div className="grid grid-cols-3 gap-4">
              {
                entityDefinition.conditions.map((entityCondition: EntityCondition, index: number) => (
                  <button
                    key={index}
                    onClick={() => setCondition(entityCondition)}
                    className={`${(condition && entityCondition.type === condition.type) && 'bg-cyan-600 border-cyan-700 text-white'} text-sm bg-white rounded-2xl shadow border px-4 py-1 font-medium`}>
                    {t('rules.' + entityCondition.type)}
                  </button>
                ))
              }
            </div>
          </div>
          { condition != null && condition.with_value && condition.selection_values &&
            <div className={'mt-4 flex items-center space-x-4'}>
              <div className={'w-36 font-medium text-sm'}>{t('rules.value_to_comparare')}</div>
              <div className="max-w-lg flex-1">
                  <SelectInput<BaseSelectItem, false>
                    value={value ? {
                      label: value,
                      value: value
                    } : null}
                  onChange={item => {
                    if (item) setValue(item.value)
                  }}
                  options={condition.selection_values} />
            </div>
          </div>
          }
          { condition != null && condition.with_value && condition.selection_values === null &&
            <div className={'mt-4 flex items-center space-x-4'}>
              <div className={'w-36 font-medium text-sm'}>{t('rules.value_to_comparare')}</div>
              <div className="max-w-lg">
                <TextInput
                  value={value || ''}
                  onChange={val => {
                    setValue(val)
                  }} />
              </div>
            </div>
          }
          { condition != null && condition.with_timespan &&
            <div className={'mt-4 flex items-center space-x-4'}>
              <div className={'w-36 font-medium text-sm'}>{t('rules.timespan')}</div>
              <div className="max-w-lg">
                <TextInput
                  numbered={true}
                  suffix={t('rules.seconds')}
                  value={timespan}
                  onChange={val => {
                    setTimespan(val)
                  }} />
              </div>
            </div>
          }

        </div>
        :
        <div className={'bg-cyan-50 border-l-4 border-cyan-600 py-2 px-4 cursor-pointer font-medium text-cyan-700'}>
          {t('rules.no_comparator_available')}
        </div>
      }
    </Fragment>
  )

}