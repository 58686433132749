import { ComponentType, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { components, OptionProps, SingleValueProps } from "react-select"
import { BaseSelectItem } from "../types"
import SelectInput from "./SelectInput"


type LanguageSelectorProps = {
  onChange?: (lang: 'de' | 'en' | 'es' | 'fr' | 'it' | 'pt' | 'ru' | 'zh') => void,
  className?: string,
  menuPortalTarget?: any,
  defaultValue?: 'de' | 'en' | 'es' | 'fr' | 'it' | 'pt' | 'ru' | 'zh'
}

interface LanguageOption extends BaseSelectItem {
  label: string,
  value: 'de' | 'en' | 'es' | 'fr' | 'it' | 'pt' | 'ru' | 'zh'
}

export default function LanguageSelector ({
  onChange,
  className,
  defaultValue,
  menuPortalTarget
} : LanguageSelectorProps) {

  const {t} = useTranslation()
  const [currentLanguage, setCurrentLanguage] = useState<LanguageOption>({
    label: defaultValue? t('language.'+defaultValue) : t('language.it'),
    value: defaultValue||'it'
  })

  const options: LanguageOption[] = [{
    label: t('language.it') || 'Italiano',
    value: 'it'
  }, {
    label: t('language.en') || 'Inglese',
    value: 'en'
  }, {
    label: t('language.de') || 'Tedesco',
    value: 'de'
  }, {
    label: t('language.fr') || 'Francese',
    value: 'fr'
  }, {
    label: t('language.es') || 'Spagnolo',
    value: 'es'
  }, {
    label: t('language.ru') || 'Russo',
    value: 'ru'
  }, {
    label: t('language.pt') || 'Portoghese',
    value: 'pt'
  }, {
    label: t('language.zh') || 'Cinese',
    value: 'zh'
  }]

  useEffect(() => {
    if (currentLanguage && onChange) {
      onChange(currentLanguage.value)
    }
  }, [
    currentLanguage
  ])

  const LanguageOption = ({ innerProps, data, label } : OptionProps<LanguageOption, false>) => (
    <div
      className={'hover:bg-cyan-100 cursor-pointer flex items-center p-2'}
      {...innerProps}>
      <div>
        {label}
      </div>
    </div>
  )

  const LanguageSingleValue : ComponentType<SingleValueProps<LanguageOption>> = ({ data, children, ...props }) => (
    <components.SingleValue data={data} {...props}>
      <div className={'flex items-center'}>
        <span className={'mr-2 text-gray-600 text-sm font-medium'}>Lingua</span>
        {children}
      </div>
    </components.SingleValue>
  )

  return (
    <>
      <SelectInput<LanguageOption, false>
        className={className}
        options={options}
        value={currentLanguage}
        components={{
          // SingleValue: LanguageSingleValue,
          Option: LanguageOption 
        }}
        menuPortalTarget={menuPortalTarget}
        styles={{
             menu: (base) => ({ ...base, zIndex: 999 }),
             control: (base) => ({ ...base, zIndex: 999 }),
        }}
        onChange={value => {
          if (value) {
            setCurrentLanguage(value as LanguageOption)
          }
        }} />
    </>
  )

}