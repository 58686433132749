import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { EntityUpdateData } from "../../types"
import FormField from "../FormField"
import Modal from "../Modal"
import ModalActions from "../ModalAction"
import ModalCard from "../ModalCard"
import ModalSection from "../ModalSection"
import TextInput from "../TextInput"

export default function EntityUpdateDialog ({
  title,
  data,
  fields,
  visible,
  loading = false,
  onClose,
  onSave
} : {
  title: string,
  fields: EntityUpdateData[],
  data: any,
  loading?: boolean,
  visible: boolean,
  onClose?: () => void,
  onSave?: (data: any) => void
}) {

  const { t } = useTranslation()

  const [entityData, setEntityData] = useState<any>()

  /**
   * Set the base status of the object
   */
  useEffect(() => {
    if (data) {
      setEntityData(data)
    }
  }, [ data ])

  return (
    <Modal
      visible={visible}>
      <ModalCard
        title={title}>
        <ModalSection>
          {
            entityData &&
            <div className={'p-4 flex-wrap flex -mx-2'}>
              {
                fields.map((data: EntityUpdateData, index: number) => (
                  <FormField
                    key={index}
                    className={'px-2 mb-4 w-full'}
                    label={data.label}>
                    <TextInput
                      value={entityData[data.field]}
                      onChange={value => {
                        setEntityData({
                          ...entityData,
                          [data.field]: value
                        })
                      }}></TextInput>
                  </FormField>
                ))
              }
            </div>
          }
        </ModalSection>
        <ModalActions
          isLoading={loading}
          saveLabel={t('general.update')}
          onClose={() => {
            if (onClose) onClose()
          }}
          onSave={() => {
            if (onSave) onSave(entityData)
          }}></ModalActions>
      </ModalCard>
    </Modal>
  )

}