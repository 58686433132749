import { Fragment, useState } from "react"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { fetchRuleEntities, fetchRuleLocals } from "../../features/rule/ruleSlice"
import { DomoticRule, DomoticRuleAction, DomoticRuleCondition } from "../../types"
import FormField from "../FormField"
import Icon from "../Icon"
import TextInput from "../TextInput"
import RuleManagerCommand from "./RuleManagerCommand"
import RuleManagerCondition from "./RuleManagerCondition"
import RuleManagerDevice from "./RuleManagerDevice"
import RuleManagerEntity from "./RuleManagerEntity"
import RuleManagerTimespan from "./RuleManagerTimespan"
import { useTranslation } from "react-i18next"


const RuleDivider = () => (
  <div className="h-6 pl-4">
    <div className="border-l-2 border-gray-300 h-full"></div>
  </div>
)

const RuleConnector = () => (
  <Fragment>
    <div className="h-3 pl-4">
      <div className="border-l-2 border-gray-300 h-full" />
    </div>
    <div className={'font-medium'}>
      <span className="bg-cyan-100 text-cyan-800 text-sm px-2 font-bold py-1 rounded-full">
        AND
      </span>
    </div>
    <div className="h-3 pl-4">
      <div className="border-l-2 border-gray-300 h-full" />
    </div>
  </Fragment>
)

const baseCondition = {
  device: undefined,
  condition_type: undefined,
  vikey_mac: undefined,
  local_key: undefined,
  device_mac: undefined,
  timespan: 0,
  value: undefined,
  entity_id: undefined
} as DomoticRuleCondition

const baseAction = {
  device: undefined,
  device_mac: undefined,
  action: undefined,
  entity_id: undefined,
  local_key: undefined,
  top: undefined,
  value: 0,
  vikey_mac: undefined,
  timestamp: 0,
  command: {
    device_mac: undefined,
    command_type: undefined,
    parameters: {
      value: null
    },
    entity_id: undefined
  }
} as DomoticRuleAction

export default function RuleManager ({
  onChange,
  value
} : {
  onChange: (rule: DomoticRule) => void,
  value: DomoticRule
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [rule, setRule] = useState<DomoticRule>({
    description: '',
    active: 'True',
    conditions: [{...baseCondition}],
    actions: [{...baseAction}]
  })

  useEffect(() => {
    async function initialize () {
      try {
        dispatch(fetchRuleLocals())
        dispatch(fetchRuleEntities())
      } catch (e) {
        console.error('Can not retrieve list of locals')
      }
    }
    initialize()
  }, [])

  useEffect(() => {
    onChange(rule)
  }, [ rule ])

  useEffect(() => {
    if (value) setRule(value)
  }, [])

  return (
    <div>

      <div className="text-xl text-gray-600 mb-6 font-semibold">
        {t('rules.rule')}
      </div>

      <FormField
        className={'mb-3'}
        label={t('rules.name')}>
        <TextInput
          placeholder={t('rules.describe_rule')}
          value={rule.description}
          onChange={val => {
            setRule(prevRule => {
              return {
                ...prevRule,
                description: val
              }
            })
          }} />
      </FormField>

      <div className="mt-6">
        <div className="font-bold text-gray-500">{t('rules.conditions')}</div>
        <p className={'text-gray-600 text-sm mb-3'}>
          {t('rules.conditions_info')}
        </p>
      </div>

      {
        rule.conditions.map((condition, index) => (
          <Fragment key={index}>
            {
              index > 0 &&
              <RuleConnector />
            }

            <RuleManagerDevice
              onlyCommandDevices={false}
              value={{
                localKey: condition.local_key,
                deviceMac: condition.device_mac
              }}
              onChange={(local, device) => {
                setRule(prevRule => {
                  return {
                    ...prevRule,
                    conditions: prevRule.conditions.map((c, cIndex) => {
                      if (cIndex === index) return {
                        ...c,
                        local: local,
                        local_key: local.local_key,
                        device: device ? device : undefined,
                        vikey_mac: device ? device.vikey_mac : undefined,
                        device_mac: device ? device.device_mac : undefined,
                        entity_id: (!device || device.device_mac !== c.device_mac) ? undefined : c.entity_id,
                        entity: (!device || device.device_mac !== c.device_mac) ? undefined : c.entity
                      }
                      return c
                    })
                  }
                })
              }} />
            
            <RuleDivider />
            
            <RuleManagerEntity
              device={condition.device}
              value={condition.entity_id}
              onChange={entity => {
                setRule(pevRule => {
                  return {
                    ...pevRule,
                    conditions: pevRule.conditions.map((c, cIndex) => {
                      if (cIndex === index) return {
                        ...c,
                        entity: entity ? entity : undefined,
                        entity_id: entity ? entity.entity_id : undefined
                      }
                      return c
                    })
                  }
                })
              }} />

            <RuleManagerCondition
              onChange={(condition, value, timespan) => {
                setRule(pevRule => {
                  return {
                    ...pevRule,
                    conditions: pevRule.conditions.map((c, cIndex) => {
                      if (cIndex === index) return {
                        ...c,
                        condition_type: condition.type,
                        timespan: timespan,
                        value: value ? value : undefined
                      }
                      return c
                    })
                  }
                })
              }}
              type={condition.condition_type}
              initialTimespan={condition.timespan}
              initialValue={condition.value}
              device={condition.device}
              entity={condition.entity} />

            {
              index > 0 &&
              <Fragment>
                <RuleDivider />
                <button
                  onClick={() => {
                    setRule(prevRule => {
                      return {
                        ...prevRule,
                        conditions: prevRule
                          .conditions
                          .filter((condition: any, cIndex: number) => cIndex !== index)
                      }
                    })
                }}
                  className="bg-red-100 rounded-full px-3 py-2 flex space-x-2 text-sm text-red-600 font-medium cursor-pointer mb-2 items-center mt-2 ml-2">
                  <Icon name={'trash'} size={'17px'}></Icon>
                  <div>
                  {t('rules.remove_condition')}
                  </div>
                </button>
              </Fragment>
            }
          </Fragment>
        ))
      }

      <RuleDivider />

      <button
        onClick={() => {
          setRule(prevRule => {
            return {
              ...prevRule,
              conditions: [
                ...prevRule.conditions,
                {...baseCondition}
              ]
            }
          })
        }}
        className={'flex px-2 pr-4 py-1 text-sm items-center hover:bg-cyan-100 text-cyan-600 bg-gray-50 border rounded-full'}>
        <div>
          <Icon name={'plus'} size={'20px'} />
        </div>
        <div>
          <a href="#" className={'font-medium text-sm'}>{t('rules.add_condition')}</a>
        </div>
      </button>

      <div className="mt-6">
        <div className="font-bold text-gray-500">{t('rules.actions')}</div>
        <p className={'text-gray-600 text-sm mb-3'}>
        {t('rules.actions_info')}
        </p>
      </div>

      {
        rule.actions.map((action, index) => (
          <Fragment key={index}>
            {
              index > 0 &&
              <RuleConnector />
            }
            <RuleManagerDevice
              onlyCommandDevices={false}
              value={{
                localKey: action.local_key,
                deviceMac: action.command?.device_mac
              }}
              onChange={(local, device) => {
                setRule(prevRule => {
                  return {
                    ...prevRule,
                    actions: prevRule.actions.map((a, cIndex) => {
                      if (cIndex === index) return {
                        ...a,
                        local: local,
                        vikey_mac: device ? device.vikey_mac : undefined,
                        local_key: local.local_key,
                        device: device ? device : undefined,
                        device_mac: device ? device.device_mac : undefined,
                        command: {
                          ...a.command,
                          device_mac: device ? device.device_mac : undefined
                        }
                      }
                      return a
                    })
                  }
                })
              }} />
            
            <RuleDivider />

            <RuleManagerEntity
              value={action.command?.entity_id}
              onChange={entity => {
                setRule(prevRule => {
                  return {
                    ...prevRule,
                    actions: prevRule.actions.map((a, cIndex) => {
                      if (cIndex === index) return {
                        ...a,
                        entity: entity ? entity : undefined,
                        command: {
                          ...a.command,
                          entity_id: entity ? entity.entity_id : undefined
                        }
                      }
                      return a
                    })
                  }
                })
              }}
              device={action.device} />
            <RuleManagerCommand
              value={action.command?.command_type}
              initialCommandValue={
                (action.command && action.command.parameters && action.command.parameters.value) ? action.command.parameters.value : undefined
              }
              onChange={(command, value) => {
                setRule(prevRule => {
                  return {
                    ...prevRule,
                    actions: prevRule.actions.map((a, cIndex) => {
                      if (cIndex === index) return {
                        ...a,
                        top: command.top,
                        command: {
                          ...a.command,
                          command_type: command.command_type,
                          parameters:{
                            value: value
                          }
                        }
                      }
                      return a
                    })
                  }
                })
              }}
              entity={action.entity} />

            {
              index > 0 &&
              <Fragment>
                <RuleDivider />
                <button
                  onClick={() => {
                    setRule(prevRule => {
                      return {
                        ...prevRule,
                        actions: prevRule
                          .actions
                          .filter((action: any, cIndex: number) => cIndex !== index)
                      }
                    })
                }}
                  className="bg-red-100 rounded-full px-3 py-2 flex space-x-2 text-sm text-red-600 font-medium cursor-pointer mb-2 items-center mt-2 ml-2">
                  <Icon name={'trash'} size={'17px'}></Icon>
                  <div>
                  {t('rules.remove_action')}
                  </div>
                </button>
              </Fragment>
            }
          </Fragment>
        ))
      }

      <RuleDivider />

      <button
        onClick={() => {
          setRule(prevRule => {
            return {
              ...prevRule,
              actions: [
                ...prevRule.actions,
                {...baseAction}
              ]
            }
          })
        }}
        className={'flex px-2 pr-4 py-1 text-sm items-center hover:bg-cyan-100 text-cyan-600 bg-gray-50 border rounded-full'}>
        <div>
          <Icon name={'plus'} size={'20px'} />
        </div>
        <div>
          <a href="#" className={'font-medium text-sm'}>{t('rules.add_action')}</a>
        </div>
      </button>

    </div>
  )

}