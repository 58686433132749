import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router-dom";
import { DomainContext } from "../DomainContext";
import { RootState } from "../reducers";
import Breadcrumbs from "./Breadcrumbs";
import Icon from "./Icon";
import LanguageSwitch from "./LanguageSwitch";
import NavigationMenu from "./NavigationMenu";
import UserAvatar from "./UserAvatar"
import {setMobileMenu} from "../features/login/navigationSlice";

export default function Header () {

  const { t } = useTranslation()
  
  const {
    breadcrumbs,
    mobileMenu
  } = useSelector((state: RootState) => state.navigation)

  const dispatch = useDispatch()
  const [isSupportVisible, setIsSupportVisible] = useState(false)
  const {appLogo, appName} = React.useContext(DomainContext);

  const history = useHistory()

  const MobileHeader = () => (
    <div className={'justify-between items-center py-4 px-4 border-b flex'}>
      <button
        onClick={() => history.push('/dashboard')}>
        <img
          src={appLogo}
          alt={appName + " logo"}
          className={'h-8 inline-block'} />
      </button>
      <button className={'text-gray-600'} onClick={() => dispatch(setMobileMenu(!mobileMenu))}>
        <Icon
          className={'cursor-pointer'}
          name={mobileMenu ? 'arrow-left' : 'menu'}
          size={'22px'} />
      </button>
    </div>
  )

  return (
    <div>
      {
        isMobile &&
        <div>
          <MobileHeader />
          <AnimatePresence>
            {
              mobileMenu &&
              <motion.div
                initial={{
                  opacity: 0
                }}
                animate={{
                  opacity: 1
                }}
                transition={{
                  duration: 0.3
                }}
                style={{
                  zIndex: 9999999
                }}
                className={'fixed top-0 left-0 w-screen h-screen bg-white'}>
                <MobileHeader />
                <div>
                  <NavigationMenu isMini={false} />
                </div>
              </motion.div>
            }
          </AnimatePresence>
        </div>
      }
      <div className={'flex px-4 lg:px-8 mx-auto xl:mx-4 mt-4 items-center justify-between mb-6'}>
        <div>
          <Breadcrumbs
            pages={breadcrumbs} />
        </div>
        
        <div className={'flex items-center space-x-3'}>
          {/* <div
            onClick={() => history.push('/support')}
            className={'cursor-pointer text-gray-500 hover:text-gray-800'}>
            <Icon name={'support'} size={'22px'} />
          </div> */}
          {
            false &&
            <div
              className={'cursor-pointer text-gray-500'}
              onClick={() => {}}>
              <Icon name={'bell'} size={'22px'} />
            </div>
          }
          <LanguageSwitch />
          <UserAvatar />
        </div>

        <AnimatePresence>
          {
            isSupportVisible &&
            <motion.div
              onClick={() => setIsSupportVisible(false)}
              style={{
                backgroundColor: 'rgba(0,0,0,0.4)'
              }}
              initial={{
                opacity: 0
              }}
              animate={{
                opacity: 1
              }}
              exit={{
                opacity: 0
              }}
              className={'absolute top-0 left-0 w-screen h-screen z-50'}>
              <motion.div
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
                initial={{
                  right: -1600
                }}
                animate={{
                  right: 0
                }}
                exit={{
                  right: -1600
                }}
                transition={{
                  duration: 0.2
                }}
                className={'absolute h-screen w-1/3 bg-white top-0 right-0'}>
                <div className={'p-4 mb-4'}>
                  <div className={'text-2xl mb-6 font-medium'}>
                    {t('header.needHelp')}
                  </div>

                  <div className={'bg-cyan-100 flex items-center text-cyan-800 p-4 rounded-2xl'}>
                    <div className={'flex-1 font-medium'}>
                      {t('header.video')}
                    </div>
                    <button
                      className={'bg-white flex items-center px-3 py-2 shadow-md rounded-2xl text-cyan-600'}>
                      {t('header.viewVideo')}
                      <Icon name={'play'} size={'20px'} className={'ml-2'}></Icon>
                    </button>
                  </div>

                  <div className={'flex mt-4 -mx-2'}>
                    <div
                      className={'flex-1 px-2 text-gray-700'}>
                      <div className={'bg-gray-100 cursor-pointer hover:bg-gray-200 p-4 rounded-2xl font-medium flex flex-col items-center justify-center'}>
                        <Icon className={'mb-2'} name={'document-search'} size={'25px'} />
                        <div>
                          {t('header.vikeyQuickstart')}
                        </div>
                      </div>
                    </div>
                    <div
                      className={'flex-1 px-2 text-gray-700'}>
                      <div className={'bg-gray-100 cursor-pointer hover:bg-gray-200 p-4 rounded-2xl font-medium flex flex-col items-center justify-center'}>
                        <Icon className={'mb-2'} name={'document-search'} size={'25px'} />
                        <div>
                          {t('header.vikeyLock')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          }
        </AnimatePresence>
      </div>
    </div>
  )

}