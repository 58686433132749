import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import {
  storeCredsGuardiaCivil,
  storeCredsMossos,
  storeCredsPoliciaNacional,
  storeCredsPortaleAlloggiati,
  storeCredsMotourist,
  storeCredsRossMille,
  fetchRegions,
  storeCredsSef,
  storeCredsRadar,
  storeCredsUby,
  storeCredsPaytourist,
  fetchProvincesPaytourist,
  storeCredsIstatTrento,
  storeCredsIstatSicilia,
  storeCredsSesHospedajes,
} from "../../shared/queries";
import FormField from "../FormField";
import Modal from "../Modal";
import ModalActions from "../ModalAction";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import TextInput from "../TextInput";
import { useTranslation } from "react-i18next";
import SelectInput from "../SelectInput";

type AddBureaucracyCredsProps = {
  visible: boolean;
  type: string;
  onClose: () => void;
  onSave: () => void;
};

export default function AddBureaucracyCreds({
  type,
  visible,
  onClose,
  onSave,
}: AddBureaucracyCredsProps) {
  const { profile } = useSelector((state: RootState) => state.login);

  const { t } = useTranslation();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [wskey, setWskey] = useState("");
  const [regionId, setRegionId] = useState<any>();
  const [accomodationId, setAccomodationId] = useState("");
  const [arrendador_code, setArrendadorCode] = useState("");   // SES
  const [establecimiento_code, setEstablecimientoCode] = useState(""); // SES


  const [files, setFiles] = useState(1);
  const [showInstruction, setShowInstruction] = useState(false);

  const [sefData, setSefData] = useState({
    chiaveacesso: "",
    unidadehoteleira: "",
    estabelecimento: "",
    nome: "",
    abreviatura: "",
    morada: "",
    localidade: "",
    codigo_postal: "",
    zona_postal: "",
    telefone: "",
    fax: "",
    nome_contacto: "",
    numero_ficheiro: 0,
  });

  function getMutationByType(): any {
    switch (type) {
      case "portale-alloggiati":
        return storeCredsPortaleAlloggiati({
          wskey: wskey,
          password: password,
          user_key: profile?.user_key,
          username: username,
        });
      case "paytourist":
        return storeCredsPaytourist({
          token: password,
          accomodation_id: accomodationId,
          province_id: regionId?.id,
        });
      case "istat-trento":
        return storeCredsIstatTrento({
          accomodation_id: accomodationId,
        });
      case "ross1000":
        return storeCredsRossMille({
          region_id: regionId?.id,
          accomodation_id: accomodationId,
          password: password,
          username: username,
        });
      case "radar":
        return storeCredsRadar({
          accomodation_id: accomodationId,
          password: password,
          username: username,
        });
      case "sef":
        return storeCredsSef(sefData);
      case "mossos-esquadra":
        return storeCredsMossos({
          file_number_to_send: files,
          password: password,
          user_key: profile?.user_key,
          username: username,
        });
      case "guardia-civil":
        return storeCredsGuardiaCivil({
          file_number_to_send: files,
          password: password,
          user_key: profile?.user_key,
          username: username,
        });
      case "ses-hospedajes":
        return storeCredsSesHospedajes({
          password: password,
          user_key: profile?.user_key,
          username: username,
          arrendador_code: arrendador_code,
          establecimiento_code: establecimiento_code
        });
      case "policia-nacional":
        return storeCredsPoliciaNacional({
          password: password,
          user_key: profile?.user_key,
          username: username,
        });
      case "motourist":
        return storeCredsMotourist({
          password: password,
          user_key: profile?.user_key,
          username: username,
        });
      case "uby":
        return storeCredsUby({
          password: password,
          uIdub: accomodationId,
          username: username,
        });
      case "istat-sicilia":
        return storeCredsIstatSicilia({
          username: username,
          password: password,
          hotel_code: accomodationId,
        });
      default:
        return () => {};
    }
  }

  const { data: regions } = useQuery<any>(["regions"], () => fetchRegions());
  const { data: provinces } = useQuery<any>(["provinces"], () =>
    fetchProvincesPaytourist()
  );

  const mutation = useMutation(getMutationByType, {
    onSuccess: () => {
      if (onSave) onSave();
      setUsername("");
      setPassword("");
      setWskey("");
      setRegionId(null);
      setAccomodationId("");
      setSefData({
        chiaveacesso: "",
        unidadehoteleira: "",
        estabelecimento: "",
        nome: "",
        abreviatura: "",
        morada: "",
        localidade: "",
        codigo_postal: "",
        zona_postal: "",
        telefone: "",
        fax: "",
        nome_contacto: "",
        numero_ficheiro: 0,
      });
    },
    onError: (error: Error) => {
      console.log("TODO");
    },
  });

  function renderPortaleAlloggiatiForm() {
    return (
      <div className="p-4 space-y-4">
        <FormField label={t("buro.username")}>
          <TextInput
            value={username}
            onChange={(val) => setUsername(val)}
          ></TextInput>
        </FormField>

        <FormField label={t("buro.password")}>
          <TextInput
            value={password}
            onChange={(val) => setPassword(val)}
          ></TextInput>
        </FormField>

        <FormField label={"Web service key"}>
          <TextInput
            value={wskey}
            onChange={(val) => setWskey(val)}
          ></TextInput>
        </FormField>
      </div>
    );
  }
  function renderPaytoristForm() {
    return (
      <div className="p-4 space-y-4">
        <FormField label={t("Token")}>
          <TextInput
            value={password}
            onChange={(val) => setPassword(val)}
          ></TextInput>
        </FormField>

        <FormField label={t("Accomodation_id")}>
          <TextInput
            value={accomodationId}
            onChange={(val) => setAccomodationId(val)}
          ></TextInput>
        </FormField>

        <FormField label={"Province_id"}>
          <SelectInput
            onChange={(item: any) => {
              setRegionId(
                provinces.results.find((r: any) => item.value === r.id)
              );
            }}
            options={
              provinces
                ? Object.values(provinces.results).map((e: any) => {
                    return {
                      label: `${e.id} - ${e.name}`,
                      value: e.id,
                    };
                  })
                : []
            }
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        </FormField>
      </div>
    );
  }
  function renderIstatTrentoForm() {
    return (
      <div className="p-4 space-y-4">
        <ul className="text-yellow-600 space-y-2">
          <li>
            * Al fine di rendere valido il collegamento è necessario effettuare
            una richiesta di profilazione all’indirizzo
            ispat@pec.provincia.tn.it specificando di voler collegare il vostro
            CIPAT con Vikey
          </li>
          <li>
            * L'invio dei dati parte dal giorno prima dell'attivazione del
            servizio sulla dashboard Vikey. Tutti quelli precedenti dovranno
            essere caricati manualmente sul portale regionale altrimenti non
            saranno comunicati e pertanto non presenti sul Portale.
          </li>
        </ul>
        <FormField label={t("CIPAT")}>
          <TextInput
            value={accomodationId}
            onChange={(val) => setAccomodationId(val)}
          ></TextInput>
        </FormField>
      </div>
    );
  }
  function renderIstatSiciliaForm() {
    return (
      <div className="p-4 space-y-4">
        <div className="text-yellow-600">
          <ul className="space-y-1">
            <li>
              Per creare un account PMS nel sistema informatico
              dell'Osservatorio Turistico Regionale, da associare all'account di
              una struttura ricettiva, per l'invio dei dati giornalieri del
              movimento di clienti, occorre presentare una richiesta di
              credenziali di accesso, in cui sia indicato un indirizzo email e
              il nome del gestionale.
            </li>
            <li>
              Per essere abilitato deve richiedere di farsi attivare il profilo
              per ottenere le credenziali API mandando il modulo all'indirizzo
              strmessina@regione.sicilia.it
            </li>
          </ul>

          <span
            className="text-cyan-600 cursor-pointer block"
            onClick={() =>
              window.open(
                `/Richiesta_account_PMS_da_struttura_ricettiva.docx`,
                "_blank"
              )
            }
          >
            {t("integration.readMore")}
          </span>
        </div>
        <FormField label={t("buro.username")}>
          <TextInput
            value={username}
            onChange={(val) => setUsername(val)}
          ></TextInput>
        </FormField>

        <FormField label={t("buro.password")}>
          <TextInput
            value={password}
            onChange={(val) => setPassword(val)}
          ></TextInput>
        </FormField>
        <FormField label={t("Hotel_code")}>
          <TextInput
            value={accomodationId}
            onChange={(val) => setAccomodationId(val)}
          ></TextInput>
        </FormField>
      </div>
    );
  }
  function renderSefForm() {
    return (
      <div className="p-4 space-y-4">
        <FormField label={"Chiave Acesso"}>
          <TextInput
            value={sefData.chiaveacesso}
            onChange={(val) =>
              setSefData((prev) => ({ ...prev, chiaveacesso: val }))
            }
          ></TextInput>
        </FormField>
        <FormField label={"Unidade Hoteleira"}>
          <TextInput
            value={sefData.unidadehoteleira}
            onChange={(val) =>
              setSefData((prev) => ({ ...prev, unidadehoteleira: val }))
            }
          ></TextInput>
        </FormField>
        <FormField label={"Estabelecimento"}>
          <TextInput
            value={sefData.estabelecimento}
            onChange={(val) =>
              setSefData((prev) => ({ ...prev, estabelecimento: val }))
            }
          ></TextInput>
        </FormField>
        <FormField label={"Nome"}>
          <TextInput
            value={sefData.nome}
            onChange={(val) => setSefData((prev) => ({ ...prev, nome: val }))}
          ></TextInput>
        </FormField>
        <FormField label={"Abreviatura"}>
          <TextInput
            value={sefData.abreviatura}
            onChange={(val) =>
              setSefData((prev) => ({ ...prev, abreviatura: val }))
            }
          ></TextInput>
        </FormField>
        <FormField label={"Morada"}>
          <TextInput
            value={sefData.morada}
            onChange={(val) => setSefData((prev) => ({ ...prev, morada: val }))}
          ></TextInput>
        </FormField>
        <FormField label={"Localidade"}>
          <TextInput
            value={sefData.localidade}
            onChange={(val) =>
              setSefData((prev) => ({ ...prev, localidade: val }))
            }
          ></TextInput>
        </FormField>
        <FormField label={"Codigo Postal"}>
          <TextInput
            value={sefData.codigo_postal}
            onChange={(val) =>
              setSefData((prev) => ({ ...prev, codigo_postal: val }))
            }
          ></TextInput>
        </FormField>
        <FormField label={"Zona Postal"}>
          <TextInput
            value={sefData.zona_postal}
            onChange={(val) =>
              setSefData((prev) => ({ ...prev, zona_postal: val }))
            }
          ></TextInput>
        </FormField>
        <FormField label={"Telefone"}>
          <TextInput
            value={sefData.telefone}
            onChange={(val) =>
              setSefData((prev) => ({ ...prev, telefone: val }))
            }
          ></TextInput>
        </FormField>
        <FormField label={"Fax"}>
          <TextInput
            value={sefData.fax}
            onChange={(val) => setSefData((prev) => ({ ...prev, fax: val }))}
          ></TextInput>
        </FormField>
        <FormField label={"Nome Contacto"}>
          <TextInput
            value={sefData.nome_contacto}
            onChange={(val) =>
              setSefData((prev) => ({ ...prev, nome_contacto: val }))
            }
          ></TextInput>
        </FormField>
        <FormField label={t("Numero Ficheiro")}>
          <TextInput
            type="number"
            value={sefData.numero_ficheiro}
            onChange={(val) =>
              setSefData((prev) => ({ ...prev, numero_ficheiro: +val }))
            }
          ></TextInput>
        </FormField>
      </div>
    );
  }

  function renderRossMilleForm() {
    return (
      <div className="p-4 space-y-4">
        <div>
          {t("buro.radarForm")}
          {"   "}
          <span
            className="text-cyan-600 cursor-pointer"
            onClick={() =>
              window.open(
                "https://drive.google.com/file/d/1K7OSn02gto14reXD8-1A3N1e1Ur2o4G2/view?usp=share_link"
              )
            }
          >
            {t("integration.readMore")}
          </span>
        </div>
        <div className="text-yellow-600">* {t("buro.messageAlert1")}</div>
        <FormField label={t("buro.username")}>
          <TextInput
            value={username}
            onChange={(val) => setUsername(val)}
          ></TextInput>
        </FormField>
        <FormField label={t("buro.password")}>
          <TextInput
            value={password}
            onChange={(val) => setPassword(val)}
          ></TextInput>
        </FormField>
        {/* <FormField label={"Accomodation ID"}> */}
        <FormField label={"Codice struttura"}>
          <TextInput
            value={accomodationId}
            onChange={(val) => setAccomodationId(val)}
          ></TextInput>
        </FormField>
        <FormField label={"Region ID"}>
          <SelectInput
            onChange={(item: any) => {
              setRegionId(
                regions.results.find((r: any) => item.value === r.id)
              );
            }}
            options={
              regions
                ? Object.values(regions.results).map((e: any) => {
                    return {
                      label: `${e.id} - ${e.name}`,
                      value: e.id,
                    };
                  })
                : []
            }
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        </FormField>
      </div>
    );
  }
  function renderRadarForm() {
    return (
      <div className="p-4 space-y-4">
        <div className="text-yellow-600">
          * {t("buro.messageAlert2")} {t("buro.messageAlert1")}
        </div>
        <FormField label={t("buro.username")}>
          <TextInput
            value={username}
            onChange={(val) => setUsername(val)}
          ></TextInput>
        </FormField>
        <FormField label={t("buro.password")}>
          <TextInput
            value={password}
            onChange={(val) => setPassword(val)}
          ></TextInput>
        </FormField>
        <FormField label={"Codice struttura"}>
          <TextInput
            value={accomodationId}
            onChange={(val) => setAccomodationId(val)}
          ></TextInput>
        </FormField>
      </div>
    );
  }

  function renderPoliciaNacionalForm() {
    return (
      <div className="p-4 space-y-4">
        <FormField label={t("buro.username")}>
          <TextInput
            value={username}
            onChange={(val) => setUsername(val)}
          ></TextInput>
        </FormField>

        <FormField label={t("buro.password")}>
          <TextInput
            value={password}
            onChange={(val) => setPassword(val)}
          ></TextInput>
        </FormField>
      </div>
    );
  }

  function renderMossosForm() {
    return (
      <div className="p-4 space-y-4">
        <FormField label={t("buro.username")}>
          <TextInput
            value={username}
            onChange={(val) => setUsername(val)}
          ></TextInput>
        </FormField>

        <FormField label={t("buro.password")}>
          <TextInput
            value={password}
            onChange={(val) => setPassword(val)}
          ></TextInput>
        </FormField>

        <FormField
          label={t("buro.nextFileToSend")}
          tooltip={t("buro.nextFileToSendInfo")}
        >
          <TextInput
            numbered
            value={files}
            onChange={(val) => setFiles(val)}
          ></TextInput>
        </FormField>
      </div>
    );
  }

  function renderMotouristForm() {
    return (
      <div className="p-4 space-y-4">
        <button
          onClick={() => {
            setShowInstruction(!showInstruction);
          }}
          className={"flex items-center mt-2 text-cyan-600 font-medium"}
        >
          {t("buro.mtouristHowToFindCreds")}
        </button>
        {showInstruction && (
          <div>
            {t("buro.mtouristHowToFindCredsDesc")}
            <a
              target={"_blank"}
              href="https://wci.unicom.uno/webci_005/index.php"
              className={"text-cyan-600"}
              rel="noreferrer"
            >
              {" "}
              Link al portale
            </a>
            <img src="/images/ricestat_instruction.png" />
          </div>
        )}
        <FormField label={t("buro.username")}>
          <TextInput
            value={username}
            onChange={(val) => setUsername(val)}
          ></TextInput>
        </FormField>

        <FormField label={t("buro.password")}>
          <TextInput
            value={password}
            onChange={(val) => setPassword(val)}
          ></TextInput>
        </FormField>
      </div>
    );
  }
  function renderUbyForm() {
    return (
      <div className="p-4 space-y-4">
        <div>
          {t("integration.ubyacess")}
          {"   "}
          <span
            className="text-cyan-600 cursor-pointer"
            onClick={() =>
              window.open(
                "https://www.policie.cz/clanek/lze-zasilani-dat-do-ubyportu-nejakym-zpusobem-automatizovat.aspx"
              )
            }
          >
            {t("integration.readMore")}
          </span>
        </div>
        <FormField label={t("buro.username")}>
          <TextInput
            value={username}
            onChange={(val) => setUsername(val)}
          ></TextInput>
        </FormField>

        <FormField label={t("buro.password")}>
          <TextInput
            value={password}
            onChange={(val) => setPassword(val)}
          ></TextInput>
        </FormField>
        <FormField label={"uIdub"}>
          <TextInput
            value={accomodationId}
            onChange={(val) => setAccomodationId(val)}
          ></TextInput>
        </FormField>
      </div>
    );
  }

  function renderGuardiaCivilForm() {
    return (
      <div className="p-4 space-y-4">
        <FormField label={t("buro.username")}>
          <TextInput
            value={username}
            onChange={(val) => setUsername(val)}
          ></TextInput>
        </FormField>

        <FormField label={t("buro.password")}>
          <TextInput
            value={password}
            onChange={(val) => setPassword(val)}
          ></TextInput>
        </FormField>

        <FormField
          label={t("buro.nextFileToSend")}
          tooltip={t("buro.nextFileToSendInfo")}
        >
          <TextInput
            numbered
            value={files}
            onChange={(val) => setFiles(val)}
          ></TextInput>
        </FormField>
      </div>
    );
  }

  function renderSesHospedajes() {
    return (
      <div className="p-4 space-y-4">
        <FormField label={t("buro.username")}>
          <TextInput
            value={username}
            onChange={(val) => setUsername(val)}
          ></TextInput>
        </FormField>

        <FormField label={t("buro.password")}>
          <TextInput
            value={password}
            onChange={(val) => setPassword(val)}
          ></TextInput>
        </FormField>

        <FormField
          label={t("buro.arrendador_code")}
        >
          <TextInput
            value={arrendador_code}
            onChange={(val) => setArrendadorCode(val)}
          ></TextInput>
        </FormField>

        <FormField
          label={t("buro.establecimiento_code")}
        >
          <TextInput
            value={establecimiento_code}
            onChange={(val) => setEstablecimientoCode(val)}
          ></TextInput>
        </FormField>
      </div>
    );
  }


  
  return (
    <Modal visible={visible}>
      <ModalCard className={"w-full lg:max-w-4xl"} title={t("general.add")}>
        <ModalSection>
          {type === "portale-alloggiati" && renderPortaleAlloggiatiForm()}
          {type === "paytourist" && renderPaytoristForm()}
          {type === "istat-trento" && renderIstatTrentoForm()}
          {type === "sef" && renderSefForm()}
          {type === "ross1000" && renderRossMilleForm()}
          {type === "radar" && renderRadarForm()}
          {type === "mossos-esquadra" && renderMossosForm()}
          {type === "policia-nacional" && renderPoliciaNacionalForm()}
          {type === "guardia-civil" && renderGuardiaCivilForm()}
          {type === "ses-hospedajes" && renderSesHospedajes()}
          {type === "motourist" && renderMotouristForm()}
          {type === "uby" && renderUbyForm()}
          {type === "istat-sicilia" && renderIstatSiciliaForm()}
        </ModalSection>

        <ModalActions
          isLoading={mutation.isLoading}
          saveLabel={t("general.create")}
          onClose={() => {
            if (onClose) onClose();
          }}
          onSave={() => {
            mutation.mutate();
          }}
        ></ModalActions>
      </ModalCard>
    </Modal>
  );
}
