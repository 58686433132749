import TextInput from './TextInput'
import FormField from './FormField'
import { useMutation } from 'react-query'
import { storeAccount } from '../shared/queries'
import { toast } from 'react-toastify'
import Modal from './Modal'
import ModalCard from './ModalCard'
import ModalSection from './ModalSection'
import ModalAction from './ModalAction'
import { useState } from 'react'
import addSubAccountSchema from '../shared/schema/addSubAccountSchema'
import { useTranslation } from 'react-i18next'

export default function AddSubAccount ({
  visible,
  onClose
} : {
  visible: boolean,
  onClose: () => void
}) {

  const { t } = useTranslation()

  const [validationError, setValidationError] = useState<any>(null)
  const [name, setName] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [passwordConfirm, setPasswordConfirm] = useState<string>()

  const storeSubAccountMutation = useMutation(() => storeAccount({
    email: '',
    mail: email,
    name: name,
    password: password
  }), {
    onSuccess: () => {
      toast.success(t('subAccounts.toastOk'))
      if (onClose) onClose()
    },
    onError: () => {
      toast.error(t('general.requestError'))
    }
  })

  function handleSubmit () {
    const { error } = addSubAccountSchema.validate({
      name,
      email,
      password,
      passwordConfirm
    })
    if (error) {
      setValidationError(error)
      toast.error(t('general.invalidFields'))
    } else {
      setValidationError(null)
      storeSubAccountMutation.mutate()
      if (onClose) onClose()
    }
  }

  return (
    <Modal
      visible={visible}>
      <ModalCard
        className={'w-full max-w-lg'}
        title={t('subAccounts.addNew')}>
        <ModalSection>
          <div className={'p-4 flex flex-col space-y-4'}>
            <FormField
              errors={validationError}
              errorKey={'name'}
              label={t('subAccounts.fullName')}>
              <TextInput
                autofocus={true}
                value={name}
                onChange={val => setName(val)} />
            </FormField>

            <FormField
              errors={validationError}
              errorKey={'email'}
              label={'Email'}>
              <TextInput
                value={email}
                onChange={val => setEmail(val)} />
            </FormField>

            <FormField
              errors={validationError}
              errorKey={'password'}
              label={'Password'}>
              <TextInput
                value={password}
                onChange={val => setPassword(val)} />
            </FormField>

            <FormField
              errors={validationError}
              errorKey={'passwordConfirm'}
              label={t('updatePassword.confirmPassword')}>
              <TextInput
                value={passwordConfirm}
                onChange={val => setPasswordConfirm(val)} />
            </FormField>
          </div>
        </ModalSection>
        <ModalAction
          saveLabel={t('general.add')}
          isLoading={storeSubAccountMutation.isLoading}
          onClose={onClose}
          onSave={handleSubmit} />
      </ModalCard>
    </Modal>
  )

}