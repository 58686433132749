import React, { useEffect, useState } from "react";
// import useBreadcrumbs from "../hooks/useBreadcrumbs";
import PageWrapper from "../components/PageWrapper";
import { useTranslation } from "react-i18next";
import TabSelector from "../components/TabSelector";
import AparmentsCM from "../components/channelmanager/AparmentsCM";
import ImportCM from "../components/channelmanager/ImportCM";
import CalendarCM from "../components/channelmanager/CalendarCM";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import {
  fetchAllModules,
  fetchPermissionChannel,
  fetchUserkeysChannel,
} from "../shared/queries";
import { moduleId } from "../shared/utils/config";
import Modal from "../components/Modal";
import ModalCard from "../components/ModalCard";
import ModalSection from "../components/ModalSection";
import ModalActions from "../components/ModalAction";
import { toast } from "react-toastify";
import Icon from "../components/Icon";
import ReactTooltip from "react-tooltip";
import i18n from "../langs";
import FormField from "../components/FormField";
import SelectInput from "../components/SelectInput";
import { BaseSelectItem } from "../types";
import Button from "../components/Button";
// CORSI-CHANNEL import dayjs from "dayjs";

type Props = {};

const ChannelManager = (props: Props) => {
  // useBreadcrumbs([{ label: "Channel Manager", route: "/channel-manager" }]);
  const { t } = useTranslation();
  const history = useHistory();
  const { hash } = useLocation();

  const tabs = ["#apartments", "#import", "#calendar"];

  const [currentTab, setCurrentTab] = useState<string>("apartments");
  const [displayMod, setDisplayMod] = useState({
    display: false,
    moduleName: "",
  });
  const [userKey, setUserKey] = useState({ label: null, value: null });
  
  // 0 Sunday
  // 1 Monday
  // 2 Tuesday
  // 3 Thursday
  // 4 Wednesday
  // 5 Friday
  // 6 Saturday
  // CORSI-CHANNEL const courseAtTwelveDate = [1, 3, 5]
  // CORSI-CHANNEL const courseAtSixteenDate = [2, 4]

  const { isLoading } = useQuery<any>( // user_key
    ["permissionChannel", currentTab, userKey.value],
    () => fetchPermissionChannel(userKey.value),
    {
      onError: () => {
        setCurrentTab("apartments");
      },
      enabled: currentTab !== "apartments",
    }
  );

  const { data: userKeys } = useQuery<any>(["userkeys", currentTab], () =>
    fetchUserkeysChannel().then((data) => {
      const userKeyListTemp = data.results.map((d: any) => {
        return {
          label: d.name,
          value: d.user_key,
        };
      });
      return userKeyListTemp;
    })
  );

  const { refetch: refetchDataModules } = useQuery<any>([], () => {
    fetchAllModules().then((data: any) => {
      const moduleFinded = data?.modules?.find(
        (module: any) => module?.id === moduleId.channel_manager
      );
      if (moduleFinded) {
        if (moduleFinded?.active_locals_count > 0) {
          setDisplayMod({
            display: false,
            moduleName: "",
          });
        } else {
          setDisplayMod({
            display: true,
            moduleName: t(`module.id${moduleFinded.id}`).toUpperCase(),
          });
        }
      } else {
        setDisplayMod({
          display: true,
          moduleName: "NOTAVAILABLE",
        });
      }
      return data;
    });
  });

  useEffect(() => {
    if (tabs.indexOf(hash) >= 0) {
      setCurrentTab(hash.substring(1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    history.push(window.location.pathname + `#${currentTab}`);
    refetchDataModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);
  return (
    <PageWrapper>
      <div className="lg:flex lg:space-x-2 space-x-0 space-y-2 lg:space-y-0 mb-6">
        <div>
          <a
            href="https://vikey-db38dad438b2.intercom-attachments-1.com/i/o/1031039975/e9fdab53ded50f6a2af653ee/Video+Guide+-+Channel+Manager.mp4"
            //href="https://youtu.be/7p90kiI53ds"
            target={"_blank"}
            style={{
              boxShadow:
                "0px 2px 4px rgba(0,0,0,0.06), 0px 0px 0px 1px rgba(0,0,0,0.07)",
            }}
            className={`bg-cyan-600 flex items-center px-2 py-1 rounded-2xl text-white text-sm font-bold clic_videoguida_channelmanager`}
            rel="noreferrer"
          >
            {t("channelManager.watchVideo")}
            <Icon name="play" size="16px" className="ml-1" />
          </a>
        </div>
        <div>
          <a
            href={
              i18n.language === "es"
                ? "https://support.vikey.it/it/articles/9329521-integrar-booking-com-con-el-channel-manager"
                : "https://support.vikey.it/it/articles/9173867-integrare-booking-com-con-il-channel-manager-vikey"
            }
            target={"_blank"}
            style={{
              boxShadow:
                "0px 2px 4px rgba(0,0,0,0.06), 0px 0px 0px 1px rgba(0,0,0,0.07)",
            }}
            className={`bg-cyan-600 flex items-center px-2 py-1 rounded-2xl text-white text-sm font-bold clic_leggiguida_channelmanager`}
            rel="noreferrer"
          >
            {t("channelManager.readGuide")}
            <Icon name="book-open" size="16px" className="ml-1" />
          </a>
        </div>
        {/* / CORSI-CHANNEL{i18n.language === "it" && (
          <>
            <div data-tip={t("channelManager.courseAtTwelveInfo")}>
              <a
                href="https://meet.zoho.com/pqtpxmuhM8"
                target={"_blank"}
                style={{
                  boxShadow:
                    "0px 2px 4px rgba(0,0,0,0.06), 0px 0px 0px 1px rgba(0,0,0,0.07)",
                }}
                className={`${courseAtTwelveDate.includes(dayjs().day()) ? 'bg-cyan-600' : 'disabled bg-gray-300'} flex items-center px-2 py-1 rounded-2xl text-white text-sm font-bold clic_webinar_channelmanager`}
                rel="noreferrer"
              >
                <Icon name="calendar" size="16px" className="mr-1" />
                {t("channelManager.courseAtTwelve")}
              </a>
            </div>
            <div data-tip={t("channelManager.courseAtSixteenInfo")}>
              <a
                href="https://meet.zoho.com/G04KDxH8CT"
                target={"_blank"}
                style={{
                  boxShadow:
                    "0px 2px 4px rgba(0,0,0,0.06), 0px 0px 0px 1px rgba(0,0,0,0.07)",
                }}
                className={`${courseAtSixteenDate.includes(dayjs().day()) ? 'bg-cyan-600' : 'disabled bg-gray-300'} flex items-center px-2 py-1 rounded-2xl text-white text-sm font-bold clic_webinar_channelmanager`}
                rel="noreferrer"
              >
                <Icon name="calendar" size="16px" className="mr-1" />
                {t("channelManager.courseAtSixteen")}
              </a>
            </div>
          </>
        )} */}
      </div>
      {userKeys && userKeys.length > 0 && (
        <div className={"flex-1 my-8"}>
          <FormField label={t("channelManager.selectProperty")}>
            <div className="flex gap-2">
              <div className="w-full">
                <SelectInput<BaseSelectItem, false>
                  isLoading={isLoading}
                  value={
                    userKeys?.find(
                      (user: any) => user.value === userKey.value
                    ) ?? { label: null, value: null }
                  }
                  options={userKeys ?? []}
                  onChange={(item) => {
                    if (item) {
                      setUserKey(item);
                    }
                  }}
                />
              </div>
              <Button
                icon="x"
                padding="lg"
                color="negative"
                onClick={() => setUserKey({ label: null, value: null })}
              />
            </div>
          </FormField>
        </div>
      )}

      <div className="mb-8">
        <TabSelector
          dontTab
          tabs={[
            {
              key: "apartments",
              label: t("channelManager.apartments"),
              active: currentTab === "apartments",
            },
            {
              key: "import",
              label: t("channelManager.importChannel"),
              active: currentTab === "import",
            },
            {
              key: "calendar",
              label: t("channelManager.calendar"),
              active: currentTab === "calendar",
            },
          ]}
          className={"mt-4"}
          onChange={(tab) => {
            setCurrentTab(tab.key);
          }}
        ></TabSelector>
      </div>

      {currentTab === "apartments" && <AparmentsCM user_key={userKey.value} />}
      {currentTab === "import" && (
        <ImportCM isLoading={isLoading} user_key={userKey.value} />
      )}
      {currentTab === "calendar" && (
        <CalendarCM isLoading={isLoading} user_key={userKey.value} />
      )}
      <Modal visible={displayMod.display} className={"w-full"}>
        <ModalCard
          title={`${displayMod.moduleName.toUpperCase()} (${t(
            "module.statusInactive"
          )})`}
          className={"w-auto"}
        >
          <ModalSection>
            <PageWrapper>
              <span className="text-gray-600 font-medium">
                {t("module.buyModuleFunctionality", {
                  name: displayMod.moduleName.toUpperCase(),
                })}
              </span>
            </PageWrapper>
            <ModalActions
              onClose={() =>
                setDisplayMod({
                  display: false,
                  moduleName: "",
                })
              }
              onSave={() => {
                if (displayMod.moduleName === "NOTAVAILABLE") {
                  toast.error("NOTAVAILABLE");
                } else {
                  history.push(`/modules/module#${moduleId.channel_manager}`);
                }
              }}
              saveLabel={t("module.purchase")}
            />
          </ModalSection>
        </ModalCard>
      </Modal>
      <ReactTooltip />
    </PageWrapper>
  );
};

export default ChannelManager;
