import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { sendCommand } from '../../shared/queries';
import { cleanNumber } from '../../shared/utils/formatting';
import { BaseSelectItem, Local } from '../../types';
import Button from '../Button';
import FormSectionTitle from '../FormSectionTitle';
import Modal from '../Modal';
import ModalActions from '../ModalAction';
import ModalCard from '../ModalCard';
import SelectInput from '../SelectInput';
import TextInput from '../TextInput';
import ApartmentUpdateConfiguration from './ApartmentUpdateConfiguration';

type ApartmentConfigurationProps = {
  data: Local;
  refetch: () => void;
};
export default function ApartmentConfiguration({
  data,
  refetch,
}: ApartmentConfigurationProps) {
  const { t } = useTranslation();
  const [update, setUpdate] = useState(false);
  const [local, setLocal] = useState(data);
  const [number, setNumber] = useState('');
  const [numberModal, setNumberModal] = useState(false);
  const volumes = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
  ];

  const volumeConfig = useMutation(
    (data: any) =>
      sendCommand({
        local_key: local.local_key,
        volume_rx: data.volume_rx,
        volume_tx: data.volume_tx,
        top: 'VOLUME_CONFIG',
      }),
    {
      onSuccess: () => {
        toast.success(t('general.updated'));
        refetch();
      },
      onError: () => {
        toast.error(t('general.requestError'));
      },
    }
  );

  const buddyConfig = useMutation(
    (data: any) =>
      sendCommand({
        local_key: local.local_key,
        voip_buddy_number: data.voip_buddy_number,
        top: 'BUDDY_CONFIG',
      }),
    {
      onSuccess: () => {
        toast.success(t('general.updated'));
      },
      onError: () => {
        toast.error(t('general.requestError'));
      },
    }
  );

  const makeCall = useMutation(
    () =>
      sendCommand({
        local_key: local.local_key,
        num: number,
        top: 'MAKE_CALL_TEST',
      }),
    {
      onSuccess: () => {
        toast.success(t('general.operationCompleted'));
        setNumberModal(false)
      },
      onError: () => {
        toast.error(t('general.requestError'));
      },
    }
  );

  return local.tech_call ? (
    <div>
      <div className="mb-10">
        <FormSectionTitle
          borderless
          title={t('apartment.configuration')}
          //   edit={true}
          //   onEdit={() => {
          //     setUpdate(true);
          //   }}
        ></FormSectionTitle>

        <div className="flex mb-3 flex-wrap">
          <div className={'w-40 xs:w-full text-gray-500'}>
            {t('apartment.hostVolume')}
          </div>
          <div>
            <SelectInput<BaseSelectItem, false>
              value={volumes.find((volume) => volume.value === local.volume_rx)}
              isLoading={volumeConfig.isLoading}
              options={volumes}
              onChange={(item) => {
                if (item) {
                  volumeConfig.mutate({
                    volume_rx: item.value,
                    volume_tx: local.volume_tx,
                  });
                }
              }}
            />
          </div>
        </div>
        <div className="flex mb-3 flex-wrap">
          <div className={'w-40 xs:w-full text-gray-500'}>
            {t('apartment.guestVolume')}
          </div>
          <div className="flex inline-flex items-center space-x-2">
            <SelectInput<BaseSelectItem, false>
              value={volumes.find((volume) => volume.value === local.volume_tx)}
              isLoading={volumeConfig.isLoading}
              options={volumes}
              onChange={(item) => {
                if (item) {
                  volumeConfig.mutate({
                    volume_tx: item.value,
                    volume_rx: local.volume_rx,
                  });
                }
              }}
            />
          </div>
        </div>
        <div className="flex mb-3 flex-wrap">
          <div className={'w-40 xs:w-full text-gray-500'}>
            {t('apartmentGeneral.hostNumber')}
          </div>
          <div className="flex inline-flex space-x-2 items-center">
            <TextInput
              value={local.voip_buddy_number}
              onChange={(value) => {
                setLocal({
                  ...local,
                  voip_buddy_number: cleanNumber(value),
                });
              }}
            ></TextInput>
            {local.voip_buddy_number !== data.voip_buddy_number && (
              <>
                <Button
                  icon="check"
                  loading={buddyConfig.isLoading}
                  onClick={() => {
                    buddyConfig.mutate({
                      voip_buddy_number: local.voip_buddy_number,
                    });
                  }}
                />
                {!buddyConfig.isLoading && (
                  <Button
                    icon="x"
                    color="negative"
                    onClick={() => {
                      setLocal({
                        ...local,
                        voip_buddy_number: data.voip_buddy_number,
                      });
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className="flex mb-3 flex-wrap">
          <div className={'w-40 xs:w-full text-gray-500'}>
            {t('apartment.status')}
          </div>
          <div className="flex inline-flex items-center space-x-2">
            <div>{t('apartment.' + local.hour_sched_forced)}</div>
            <Button
              icon="pencil"
              color="success"
              onClick={() => {
                setUpdate(true);
              }}
            />
          </div>
        </div>
      </div>
      <FormSectionTitle
        borderless
        title={t('apartment.testCall')}
        subtitle={t('apartment.testCallDetail')}
      ></FormSectionTitle>
      <div className="flex inline-flex items-center space-x-2">
        <TextInput
          value={number}
          onChange={(value) => {
            setNumber(cleanNumber(value));
          }}
        ></TextInput>
        <Button
          icon="phone"
          color="primary"
          onClick={() => {
            setNumberModal(true);
          }}
        />
      </div>
      <ApartmentUpdateConfiguration
        local={local}
        visible={update}
        onClose={() => {
          setUpdate(false);
        }}
        onSave={() => {
          setUpdate(false);
          refetch()
        }}
      />
      <Modal visible={numberModal}>
        <ModalCard
          className="justify-between"
          title={t('apartment.testCallConfirm')}
        >
          <ModalActions
            isLoading={buddyConfig.isLoading}
            saveLabel={t('general.yes')}
            closeLabel={t('general.no')}
            onClose={() => {
              setNumberModal(false);
            }}
            onSave={() => {
              makeCall.mutate();
            }}
          ></ModalActions>
        </ModalCard>
      </Modal>
    </div>
  ) : (
    <div
      style={{
        boxShadow:
          '0px 2px 8px rgba(0,0,0,0.03), 0px 0px 0px 1px rgba(0,0,0,0.05)',
      }}
      className={'bg-gray-50 rounded-2xl overflow-hidden mt-1'}
    >
      <div
        className={
          'bg-white items-center w-full p-4 flex flex-wrap justify-between'
        }
      >
        <div>
          <strong className={'text-gray-500'}>
            {t('apartment.confNotAvailable')}
          </strong>
        </div>
      </div>
    </div>
  );
}
