import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalTable } from '../../../types';
import Checkbox from '../../Checkbox';

type PaystaySelectorProps = {
  local: LocalTable | undefined;
  integration: any;
  setIntegration: any;
};
export default function PaystaySelector({
  local,
  integration,
  setIntegration,
}: PaystaySelectorProps) {
  const { t } = useTranslation();

  return (
    <>
      <Checkbox
        label={t('resv.requestStayPayment')}
        disabled={
          (!local && !integration?.integr_chk_paystay) ||
          (local == null && !integration?.integr_chk_paystay)||
          (local && local.stripe_account == null && !integration?.integr_chk_paystay)
        }
        onChange={() => {
          setIntegration({
            ...integration,
            integr_chk_paystay: !integration.integr_chk_paystay,
          });
        }}
        checked={integration?.integr_chk_paystay === 0 ? false : integration?.integr_chk_paystay}
      />
      {integration?.integr_chk_paystay && (
        <div className="bg-gray-100 border rounded-2xl p-4 pb-2 mb-2">
          <Checkbox
            label={t('resv.chargeGuestCommission')}
            onChange={() => {
              setIntegration({
                ...integration,
                integr_chk_paystay_chargeguest:
                  !integration.integr_chk_paystay_chargeguest,
              });
            }}
            checked={integration.integr_chk_paystay_chargeguest === 0 ? false : integration.integr_chk_paystay_chargeguest}
          />
          <span
            className={`flex-1 cursor-pointer py-1 px-1 text-gray-400 font-medium }`}
          >
            {t('integration.channel')}
          </span>
          <Checkbox
            label={t('integration.enableFor') + ' Airbnb'}
            onChange={() => {
              setIntegration({
                ...integration,
                integr_chk_paystay_filterchannel: {
                  EXPEDIA:
                    integration.integr_chk_paystay_filterchannel
                      ?.EXPEDIA ?? false,
                  BOOKING:
                    integration.integr_chk_paystay_filterchannel
                      ?.BOOKING ?? false,
                  OTHER:
                    integration.integr_chk_paystay_filterchannel?.OTHER ?? false,
                  AIRBNB:
                    !integration.integr_chk_paystay_filterchannel?.AIRBNB,
                },
              });
            }}
            checked={integration.integr_chk_paystay_filterchannel?.AIRBNB === 0 ? false : integration.integr_chk_paystay_filterchannel?.AIRBNB}
          />
          <Checkbox
            label={t('integration.enableFor') + ' Booking'}
            onChange={() => {
              setIntegration({
                ...integration,
                integr_chk_paystay_filterchannel: {
                  EXPEDIA:
                    integration.integr_chk_paystay_filterchannel
                      ?.EXPEDIA ?? false,
                  OTHER:
                    integration.integr_chk_paystay_filterchannel?.OTHER ?? false,
                  AIRBNB:
                    integration.integr_chk_paystay_filterchannel?.AIRBNB ?? false,
                  BOOKING:
                    !integration.integr_chk_paystay_filterchannel?.BOOKING,
                },
              });
            }}
            checked={
              integration.integr_chk_paystay_filterchannel?.BOOKING === 0 ? false : integration.integr_chk_paystay_filterchannel?.BOOKING
            }
          />
          <Checkbox
            label={t('integration.enableFor') + ' Expedia'}
            onChange={() => {
              setIntegration({
                ...integration,
                integr_chk_paystay_filterchannel: {
                  BOOKING:
                    integration.integr_chk_paystay_filterchannel
                      ?.BOOKING ?? false,
                  OTHER:
                    integration.integr_chk_paystay_filterchannel?.OTHER ?? false,
                  AIRBNB:
                    integration.integr_chk_paystay_filterchannel?.AIRBNB ?? false,
                  EXPEDIA:
                    !integration.integr_chk_paystay_filterchannel?.EXPEDIA,
                },
              });
            }}
            checked={
              integration.integr_chk_paystay_filterchannel?.EXPEDIA === 0 ? false : integration.integr_chk_paystay_filterchannel?.EXPEDIA
            }
          />
          <Checkbox
            label={t('integration.enableForOther')}
            onChange={() => {
              setIntegration({
                ...integration,
                integr_chk_paystay_filterchannel: {
                  EXPEDIA:
                    integration.integr_chk_paystay_filterchannel
                      ?.EXPEDIA ?? false,
                  BOOKING:
                    integration.integr_chk_paystay_filterchannel
                      ?.BOOKING ?? false,
                  AIRBNB:
                    integration.integr_chk_paystay_filterchannel?.AIRBNB ?? false,
                  OTHER:
                    !integration.integr_chk_paystay_filterchannel?.OTHER,
                },
              });
            }}
            checked={integration.integr_chk_paystay_filterchannel?.OTHER === 0 ? false : integration.integr_chk_paystay_filterchannel?.OTHER}
          />
        </div>
      )}
    </>
  );
}
