import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Select from "react-select";
import DatePicker from '../DatePicker'
import { fetchV3AllLocals } from "../../shared/queries";
import dayjs from "dayjs";
import SelectInput from "../SelectInput";
import { useTranslation } from "react-i18next";
import FormField from "../FormField";
import { BaseSelectItem } from "../../types";


export default function ExportBuroSpain ({
  onChange
} : {
  onChange: (data: any) => void
}) {

  const {
    data: locals,
    isFetching: isFetchingLocals
  } = useQuery('localsv3', fetchV3AllLocals)

  const [exportDate, setExportDate] = useState<string>(dayjs().format('YYYY-MM-DD'))
  const [localKey, setLocalKey] = useState<string|undefined>('')

  const { t } = useTranslation()


  useEffect(() => {
    onChange({
      action: 'MOSSOS',
      day: exportDate,
      spain_apartment_id: localKey
    })
  }, [
    localKey,
    exportDate
  ])

  return (
    <div className={'p-4'}>
      <div className="text-lg font-semibold">
      {t('export.mossos')}

      </div>

      <div className="mt-4 text-gray-600 text-sm">
      {t('export.mossosDesc')}

      </div>

      <div className="mt-6 flex flex-col space-y-4">
        <DatePicker
          value={exportDate}
          inputFormat={'YYYY-MM-DD'}
          outputFormat={'YYYY-MM-DD'}
          displayFormat={'DD-MM-YYYY'}
          onChange={value => setExportDate(value)} />
        
        <FormField
          label={t('navigation.apartments')}>
          <SelectInput<BaseSelectItem, false>
            menuPortalTarget={document.body}
            styles={{
              container: base => ({ ...base, zIndex: 999 }),
              menuPortal: base => ({ ...base, zIndex: 999 })
            }}
            isLoading={isFetchingLocals}
            menuShouldScrollIntoView={false}
            onChange={item => {
              if (item) setLocalKey(item.value)
            }}
            options={(locals && locals.results) ? locals.results.filter(l => l.spain_apartment_id !== null).map(l => {
              return {
                label: l.name + '(' + l.spain_apartment_id + ')',
                value: l.spain_apartment_id
              }
            }) : [] } />
        </FormField>
      </div>
    </div>
  )

}
