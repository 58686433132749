import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  fetchLocalServices,
  updateApartmentServices,
} from "../../shared/queries";
import { ListApiResponse, Local, LocalService } from "../../types";
import FormSectionTitle from "../FormSectionTitle";
import Checkbox from "../Checkbox";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

type ApartmentServicesProps = {
  data: Local;
  onEnableServices?: () => void;
};

export default function ApartmentServices({
  data,
  onEnableServices,
}: ApartmentServicesProps) {
  const { t } = useTranslation();

  const { data: services } = useQuery<
    ListApiResponse<LocalService>,
    Error
  >("apartmentServices", () => fetchLocalServices(data.local_key));

  const queryClient = useQueryClient();

  const [serviceStatus, setServiceStatus] = useState({
    enable_services: data.enable_services ?? false,
    external_services_notif: data.external_services_notif ?? false,
  });

  const mutation = useMutation(
    (service: { enable_services: boolean; external_services_notif: boolean }) =>
      updateApartmentServices(data.local_key || "", service),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["apartment", data.local_key]);
        toast.success(t("general.updated"));
      },
    }
  );

  return (
    <div>
      <FormSectionTitle
        borderless
        title={t("apartmentService.sellWithVikey")}
        /* subtitle={t('apartmentService.sellWithVikeyText')} */
      ></FormSectionTitle>

      <div>
        <Checkbox
          checked={serviceStatus.enable_services}
          onChange={() => {
            setServiceStatus((prev) => {
              return {
                ...prev,
                enable_services: !prev.enable_services
              };
            });
            mutation.mutate({
              ...serviceStatus,
              enable_services: !serviceStatus.enable_services,
            });
          }}
          label={t("apartmentService.activateServices")}
        ></Checkbox>
      </div>
      <div>
        <Checkbox
          checked={serviceStatus.external_services_notif}
          onChange={() => {
            setServiceStatus((prev) => {
              return {
                ...prev,
                external_services_notif: !prev.external_services_notif
              };
            });
            mutation.mutate({
              ...serviceStatus,
              external_services_notif: !serviceStatus.external_services_notif,
            });
          }}
          label={t("apartmentService.activateExternalServices")}
        ></Checkbox>
      </div>

      <FormSectionTitle
        title={t("apartmentService.activeServices")}
        subtitle={t("apartmentService.activeServicesText")}
      ></FormSectionTitle>

      {services && (
        <div className="mt-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {services.results.map((service: LocalService, index: number) => (
            <div
              key={index}
              className={
                "border border-gray-300 p-10 space-y-10 bg-white rounded-2xl justify-between"
              }
            >
              <div
                style={{
                  backgroundImage: "url(" + service.buyable?.cover + ")",
                }}
                className={"w-full h-36 m-auto rounded-2xl bg-cover bg-center"}
              ></div>
              <div>
                <div className={"font-medium text-gray-700"}>
                  {service.buyable?.title}
                </div>
                <div className={"text-gray-600 text-sm"}>
                  {t("apartmentService.inSellAt")} {service.buyable?.amount_tot}{" "}
                  EUR
                </div>
                <div>
                  <span className={"text-green-600 font-medium text-sm"}>
                    {service.buyable?.type === "MULTI"
                      ? t("services.multiPurchase")
                      : t("services.singlePurchase")}
                  </span>
                </div>
              </div>

              <div className={"self-end"}>
                <Link
                  to={"/services/" + service.buyable?.id}
                  className={
                    "bg-cyan-600 p-2 text-white text-center rounded-2xl text-sm"
                  }
                >
                  {t("general.edit")}
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
