import { useTranslation } from "react-i18next";
import { Route, Switch, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Icon from "../components/Icon";
import PageWrapper from "../components/PageWrapper";
// import useBreadcrumbs from "../hooks/useBreadcrumbs";
import AssociateApartmentCard from "./AssociateApartmentCard";
import Billing from "./Billing";
import Bureaucracy from "./Bureaucracy";
import ContractsPage from "./Contracts";
import CustomCheckins from "./CustomCheckins";
import CustomEmails from "./CustomEmails";
import IntegrationsPage from "./Integrations";
import PaymentMethods from "./PaymentMethods";
import StripeConnect from "./StripeConnect";
import SubAccounts from "./SubAccount";
import AssistantsPage from "./Assistans";

export default function ConfigurationPage () {

  const { t } = useTranslation()

  const history = useHistory()

  // useBreadcrumbs([])

  return (
    <PageWrapper>
      
      <div className="flex space-x-3 items-center mb-8">
        <div className="w-auto">
          <div className="bg-cyan-100 text-cyan-800 rounded-2xl p-2">
            <Icon size={'20px'} name={'adjustments'}></Icon>
          </div>
        </div>
        <div className="flex-1">
          <div className="font-bold text-xl">{t('configuration.configuration')}</div>
        </div>
      </div>

      <div className="flex flex-wrap xl:flex-nowrap xl:space-x-12 h-full">
        <div className="xl:sticky top-0 w-52">
          
          <div className={'transition-all font-medium text-gray-600 hover:text-cyan-600 mb-3'}>
            <Link
              className={ history && history.location.pathname === '/configuration/bureaucracy' ? 'border-l-2 border-cyan-600 pl-2 text-cyan-600' : ''}
              to={'/configuration/bureaucracy'}>{t('configuration.buro')}</Link>
          </div>

          <div className={'font-medium text-gray-600 hover:text-cyan-600 mb-3'}>
            <Link
              className={ history && history.location.pathname === '/configuration/contracts' ? 'border-l-2 border-cyan-600 pl-2 text-cyan-600' : ''}
              to={'/configuration/contracts'}>{t('configuration.contracts')}</Link>
          </div>

          <div className={'font-medium text-gray-600 hover:text-cyan-600 mb-3'}>
            <Link
              className={ history && history.location.pathname === '/configuration/emails' ? 'border-l-2 border-cyan-600 pl-2 text-cyan-600' : ''}
              to={'/configuration/emails'}>{t('configuration.customEmail')}</Link>
          </div>

          <div className={'font-medium text-gray-600 hover:text-cyan-600 mb-3'}>
            <Link
              className={ history && history.location.pathname === '/configuration/custom-checkins' ? 'border-l-2 border-cyan-600 pl-2 text-cyan-600' : ''}
              to={'/configuration/custom-checkins'}>{t('configuration.customCheckin')}</Link>
          </div>

          <div className={'font-medium text-gray-600 hover:text-cyan-600 mb-3'}>
            <Link
              className={ history && history.location.pathname === '/configuration/sub-accounts' ? 'border-l-2 border-cyan-600 pl-2 text-cyan-600' : ''}
              to={'/configuration/sub-accounts'}>{t('configuration.subAccounts')}</Link>
          </div>

          <div className={'font-medium text-gray-600 hover:text-cyan-600 mb-3'}>
            <Link
              className={ history && history.location.pathname === '/configuration/integrations' ? 'border-l-2 border-cyan-600 pl-2 text-cyan-600' : ''}
              to={'/configuration/integrations'}>{t('configuration.integrations')}</Link>
          </div>
           {/* <div className={'font-medium text-gray-600 hover:text-cyan-600 mb-3'}>
            <Link
              className={ history && history.location.pathname === '/configuration/assistants' ? 'border-l-2 border-cyan-600 pl-2 text-cyan-600' : ''}
              to={'/configuration/assistants'}>{t('/configuration/assistants')}</Link>
          </div> */}

          <div className={'font-medium text-gray-600 hover:text-cyan-600 mb-3'}>
            <Link
              className={ history && history.location.pathname.indexOf('payment-methods') > -1 ? 'border-l-2 border-cyan-600 pl-2 text-cyan-600' : ''}
              to={'/configuration/payment-methods'}>{t('configuration.paymentMethods')}</Link>
          </div>

          <div className={'font-medium text-gray-600 hover:text-cyan-600 mb-3'}>
            <Link
              className={ history && history.location.pathname === '/configuration/billing' ? 'border-l-2 border-cyan-600 pl-2 text-cyan-600' : ''}
              to={'/configuration/billing'}>{t('configuration.billing')}</Link>
          </div>

          <div className={'font-medium text-gray-600 hover:text-cyan-600 mb-3'}>
            <Link
              className={ history && history.location.pathname === '/configuration/stripe-connect' ? 'border-l-2 border-cyan-600 pl-2 text-cyan-600' : ''}
              to={'/configuration/stripe-connect'}>{t('configuration.stripeConnect')}</Link>
          </div>

        </div>
        <div className={'flex-1 w-full'}>
          
          <div className="cst-shadow bg-white rounded-2xl p-4 lg:p-6 mb-6 lg:px-8 border">
            <Switch>
              <Route exact path="/configuration/integrations" component={IntegrationsPage}></Route>
              <Route exact path="/configuration/contracts" component={ContractsPage}></Route>
              <Route exact path="/configuration/custom-checkins" component={CustomCheckins}></Route>
              <Route exact path="/configuration/bureaucracy" component={Bureaucracy}></Route>
              <Route exact path="/configuration/emails" component={CustomEmails}></Route>
              <Route exact path="/configuration/payment-methods" component={PaymentMethods}></Route>
              <Route exact path="/configuration/payment-methods/associate" component={AssociateApartmentCard}></Route>
              {/* <Route exact path="/configuration/assistants" component={AssistantsPage}></Route> */}
              <Route exact path="/configuration/billing" component={Billing}></Route>
              <Route exact path="/configuration/sub-accounts" component={SubAccounts}></Route>
              <Route exact path="/configuration/stripe-connect" component={StripeConnect}></Route>
            </Switch>
          </div>
          
        </div>
      </div>

    </PageWrapper>
  )

}