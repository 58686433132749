import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { SortingRule } from "react-table";
import BaseTable from "../../components/BaseTable";
import i18n from "../../langs";
import { fetchReservations } from "../../shared/queries";
import { getHumanDateFromISO } from "../../shared/utils/formatting";
import { getReservationColor } from "../../shared/utils/reservation";
import { ReservationTable, TableFilterColumn, TableResponse, TableSort } from "../../types"

const defaultFilters = [{
  key: '1',
  label: i18n.t('reservations.arrival'),
  active: false,
  value: null,
  type: 'dateRange'
}, {
  key: '2',
  label: i18n.t('reservations.departure'),
  active: false,
  value: null,
  type: 'dateRange'
}, {
  key: '3',
  label: i18n.t('reservations.code'),
  active: false,
  value: null,
  type: 'text'
}, {
  key: '4',
  label: i18n.t('reservations.channel'),
  active: false,
  value: null,
  type: 'text'
}, {
  key: '5',
  label: i18n.t('reservations.externalKey'),
  active: false,
  value: null,
  type: 'text'
}, {
  key: '12',
  label: i18n.t('reservations.apartment'),
  active: false,
  value: null,
  type: 'text'
}, {
  key: '13',
  label: i18n.t('reservations.guest'),
  active: false,
  value: null,
  type: 'text'
}, {
  key: '14',
  label: i18n.t('reservations.email'),
  active: false,
  value: null,
  type: 'text'
}, {
  key: '17',
  label: i18n.t('general.city'),
  active: false,
  value: null,
  type: 'text'
}, {
  key: '18',
  label: i18n.t('reservations.numberOfGuests'),
  active: false,
  value: null,
  type: 'text'
}, {
  key: '19',
  label: i18n.t('reservations.language'),
  active: false,
  value: null,
  type: 'text'
}, {
  key: '16',
  label: i18n.t('reservations.state'),
  active: false,
  value: null,
  type: 'text'
}, {
  key: '21',
  label: i18n.t('reservations.color'),
  active: false,
  value: null,
  type: 'select',
  options: [
    { label: i18n.t('reservations.white'), value: '1' },
    { label: i18n.t('reservations.green'), value: '2' },
    { label: i18n.t('reservations.blue'), value: '3' },
    { label: i18n.t('reservations.yellow'), value: '4' },
    { label: i18n.t('reservations.orange'), value: '5' },
    { label: i18n.t('reservations.red'), value: '6' },
  ]
}, {
  key: '22',
  label: i18n.t('reservations.canceled'),
  active: true,
  regex: true,
  value: '0',
  type: 'select',
  options: [
    { label: i18n.t('general.yes'), value: 1 },
    { label: i18n.t('general.no'), value: 0 }
  ]
}, {
  key: '50',
  label: i18n.t('reservations.checkinStatus'),
  active: true,
  value: 'PEND',
  type: 'text',
  options: [
    { label: i18n.t('resv.notExecuted'), value: 'WAIT' },
    { label: i18n.t("resv.waitingDeposit"), value: "WAIT_DEPOSIT" },
    { label: i18n.t('resv.notNecessary'), value: 'NONEED' },
    { label: i18n.t('general.confirm'), value: 'OK' },
    { label: i18n.t('resv.waitingConfirmation'), value: 'PEND' },
  ]
}]

export default function DashboardArrivals ({
  className
} :  {
  className?: string
}) {

  const { t } = useTranslation()
      
  const history = useHistory()

  const [sorting, setSorting] = useState<TableSort[]>([])
  const [filters, setFilters] = useState<TableFilterColumn[]>(defaultFilters)

  const [currentPage, setCurrentPage] = useState(1)

  const {
    data,
    isFetching
  } = useQuery<TableResponse<ReservationTable>, Error>(['reservations', currentPage, filters, sorting], () => fetchReservations(filters, ((currentPage - 1) * 15), sorting), {
    keepPreviousData: true
  })

  function handleSorting (data: SortingRule<any>[]) {
    const cols = [] as TableSort[]
    data.forEach(f => {
      const columnKey = defaultFilters.find((df: any) => df.label === f.id)
      if (columnKey) {
        cols.push({
          columnIndex: columnKey.key,
          desc: f.desc || false
        })
      }
    })
    setSorting(cols)
  }

  return (
    <BaseTable
      className={className}
      onSorting={handleSorting}
      showColumnSelector={false}
      showFilters={false}
      currentPage={currentPage}
      pageCount={Math.ceil(parseInt(data?.recordsTotal || '0') / 15)}
      identifierKey={'code'}
      data={data ? data.data : []}
      loading={isFetching}
      totalRows={parseInt(data?.recordsTotal || '0')}
      displayRows={15}
      onFilterChange={(filters, page) => {
        setCurrentPage(page || 1)
        setFilters(filters)
      }}
      // filtersValue={filters}
      filters={filters}
      columns={[
        {
          Header: t('reservations.code') as string,
          accessor: (row: ReservationTable) => (
            <div className={'flex items-center space-x-2'}>
              <div className={'w-3 h-3 rounded-2xl ' + getReservationColor(row.color)}></div>
              <div>{row.code}</div>
            </div>
          )
        },
        {
          Header: t('reservations.arrival') as string,
          accessor: (row: ReservationTable) => (
            <>
              {getHumanDateFromISO(row.check_in, false)}
            </>
          )
        },
        {
          Header: t('reservations.departure') as string,
          accessor: (row: ReservationTable) => (
            <>
              {getHumanDateFromISO(row.check_out, false)}
            </>
          )
        },
        { Header: t('reservations.guest') as string, accessor: 'guest' },
        { Header: t('reservations.apartment') as string, accessor: 'local' }
      ]}
      onEdit={id => {
        history.push('/reservations/' + id)
      }}></BaseTable>
  )

}