import React from 'react'
import ModalActions from './ModalAction'

export default function ModalCard ({
  title,
  children,
  style,
  renderActions,
  className,
  onSave,
  noscroll = false,
  onClose,
  actionsLoading = false,
} : {
  noscroll?: boolean,
  renderActions?: () => void,
  onSave?: () => void,
  onClose?: () => void,
  style?: object,
  title?: string,
  children: any,
  className?: string,
  actionsLoading?: boolean
}) {

  return (
    <div style={style} className={`flex flex-col m-2 lg:m-10 ${noscroll ? 'overflow-hidden' : 'overflow-auto overflow-y-hidden overflow-x-hidden'} bg-white shadow-lg rounded-2xl ${className}`}>
      {
        title &&
        <div className={'p-4 py-5 text-xl text-gray-700 font-bold'}>
          {title}
        </div>
      }
      <div className={`relative flex-1 ${noscroll ? 'overflow-hidden h-full flex flex-col' : 'overflow-y-auto overflow-x-hidden'}`}>
        {children}
      </div>

      {
        (onSave || onClose) &&
        <ModalActions
          isLoading={actionsLoading}
          onClose={onClose ? onClose : undefined}
          onSave={onSave ? onSave : undefined} />
      }

      {
        renderActions && renderActions()
      }
    </div>
  )

}