import dayjs from "dayjs";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { getFormattedDate } from "../../shared/utils/formatting";
import { CalendarDay, ReservationTable } from "../../types";
import { useTranslation } from "react-i18next";

type ApartmentRowProps = {
  local: {
    name: string,
    local_key: string
  };
  days: CalendarDay[];
  startDate: string;
  reservations: ReservationTable[];
};

const ApartmentCalendariCalRow = ({
  startDate,
  local,
  days,
  reservations,
}: ApartmentRowProps) => {
  const { t } = useTranslation();

  const [reservationList, setReservationList] = useState<any>(reservations);

  const verifyBg = (source: string) => {
    switch (source) {
      case "BOOKING":
        return "bg-blue-800 text-yellow-300";
      case "AIRBNB":
        return "bg-red-500 text-white ";
      default:
        return "bg-cyan-600 text-white";
    }
  };

  useEffect(() => {
    setReservationList(
      reservations.map((r) => {
        const length =
          dayjs(r.check_out, "YYYY-MM-DD").diff(
            dayjs(r.check_in, "YYYY-MM-DD"),
            "days"
          ) + 1;
        const fromToday = dayjs(r.check_in, "YYYY-MM-DD").diff(
          dayjs(startDate),
          "days"
        );
        return {
          ...r,
          length,
          fromToday,
          width: (length / 20) * 100,
          marginLeft: (fromToday / 20) * 100,
        };
      })
    );
  }, [reservations, startDate]);

  if(reservationList.length === 0){
    return <div className="text-sm text-center p-5 border-2 border-white">{t('apartmentCalendar.noResv')}</div>;
  }

  return (
    <div
      className="hover:bg-white flex"
      style={{
        height:
          reservationList.length > 0
            ? `${reservationList.length * 8 + 64}px`
            : "64px",
      }}
    >
      <div
        className={
          "bg-gray-100 z-50 text-sm space-x-2 font-medium text-gray-600 flex items-center w-52 border-b py-2 border-r truncate px-5"
        }
      >
        <div className="whitespace-pre-wrap">{local.name}</div>
      </div>

      <div className="flex-1 border-b flex relative place-content-center">
        <div className="absolute w-full h-full top-0 left-0 bg-red flex">
          {days.map((_day, index) => (
            <div
              key={index}
              className={`flex-1 h-full border-r hover:bg-gray-100 cursor-pointer`}
            ></div>
          ))}
        </div>
        <ul>
          {reservationList.map((reservation: any, index: number) => (
            <motion.li
              key={index}
              initial={{
                left: -90,
              }}
              animate={{
                left: 0,
              }}
              style={{
                width: reservation.width + "%",
                marginLeft: reservation.marginLeft + "%",
                marginTop:
                  reservationList.length > 0 ? index * 7 + 5 : index * 24,
              }}
              className={`absolute cst-shadow truncate px-4 cursor-pointer flex flex-nowrap items-center w-full h-6 p-1 rounded-2xl font-medium text-xs border-2 ${verifyBg(
                reservation.source
              )}`}
              data-tip={`${reservation.source} | ${getFormattedDate(
                reservation.check_in,
                "YYYY-MM-DD",
                "DD-MM-YYYY"
              )} -> ${getFormattedDate(
                reservation.check_out,
                "YYYY-MM-DD",
                "DD-MM-YYYY"
              )}`}
              data-for="soclose"
            >
              <div className={"w-full"}>
                {reservation.source}
                <span
                  className={"border-white opacity-30 mx-1 border-r h-full"}
                ></span>
                {getFormattedDate(
                  reservation.check_in,
                  "YYYY-MM-DD",
                  "DD-MM-YYYY"
                )}
                <span
                  className={"border-white opacity-30 mx-1 border-r h-full"}
                ></span>
                {getFormattedDate(
                  reservation.check_out,
                  "YYYY-MM-DD",
                  "DD-MM-YYYY"
                )}
              </div>
              <ReactTooltip
                id="soclose"
                getContent={(dataTip) => <span>{dataTip}</span>}
                effect="float"
              />
            </motion.li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ApartmentCalendariCalRow; 
