import React from 'react';

export const DomainContext = React.createContext<DomainContextObject>(
  window.location.host.indexOf('airekey') >= 0
    ? {
        appLogo: '/airekey_logo.png',
        appFavicon: 'airekey_favicon.png',
        appName: 'Airekey',
        lng: 'en',
        appType: 'airekey',
      }
    : {
        appLogo: '/logo.png',
        appFavicon: 'favicon.png',
        appName: 'Vikey',
        lng: 'it',
        appType: 'vikey',
      }
);

interface DomainContextObject {
  lng: string;
  appFavicon: string;
  appLogo: string;
  appName: string;
  appType: 'vikey' | 'airekey';
}
