import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
// import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import { fetchCalendariCal } from "../../shared/queries";
import { CalendarDay, ReservationTable } from "../../types";
import DatePicker from "../DatePicker";
import Icon from "../Icon";
import ApartmentCalendariCalRow from "./ApartmentCalendariCalRow";

dayjs.extend(weekday);

type Props = {
  local: {
    name: string;
    local_key: string;
  };
};

const ApartmentCalendariCalDt = (props: Props) => {
  const { name, local_key } = props.local;
  const { t } = useTranslation();

  const [days, setDays] = useState<CalendarDay[]>([]);
  const [startDate, setStartDate] = useState<string>(
    dayjs().format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(
    dayjs().add(20, "days").format("YYYY-MM-DD")
  );

  function recalcDays() {
    const ds = [];
    let tempDate = dayjs(startDate).clone();
    while (tempDate.isBefore(endDate)) {
      ds.push({
        date: tempDate.format("DD-MM-YYYY"),
        weekday: tempDate.format("dd"),
        day: tempDate.format("DD"),
        dayNumber: tempDate.weekday(),
      } as CalendarDay);
      tempDate = tempDate.add(1, "day");
    }
    setDays(ds);
  }

  // useBreadcrumbs([{ label: "Calendar", route: "/calendar" }]);


  const { data: iCalDT } = useQuery<any>(["icalDT"], () =>
    fetchCalendariCal([local_key as string])
  );

  function getLocalReservations(): ReservationTable[] {
    if (iCalDT) {
      return iCalDT.results.filter((d:any) => {
        return d.local_key === local_key;
      });
    }
    return [];
  }

  useEffect(() => {
    recalcDays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <div className={"select-none"}>
      <div className="flex p-4 pt-0 space-x-2 lg:space-x-0 items-center justify-between">
        <div className="flex items-center space-x-4">
          {/* <div className="font-bold text-gray-800 text-2xl">
            {t("navigation.calendar")}
          </div> */}
          <div className="font-bold text-gray-800 text-2xl">
            {t("reservations.date")}
          </div>

          <div>
            <DatePicker
              value={startDate}
              inputFormat={"YYYY-MM-DD"}
              outputFormat={"YYYY-MM-DD"}
              displayFormat={"DD-MM-YYYY"}
              onChange={(value) => {
                setStartDate(
                  dayjs(value).subtract(1, "days").format("YYYY-MM-DD")
                );
                setEndDate(
                  dayjs(value)
                    .subtract(1, "days")
                    .add(20, "days")
                    .format("YYYY-MM-DD")
                );
              }}
            />
          </div>
        </div>
      </div>
      {/* <div className="flex justify-center gap-5 text-xs font-bold mb-5">
        <div
          className={
            "font-medium flex items-center text-gray-800 bg-gray-100 rounded-full p-1 border-2 border-gray-800"
          }
        >
          <div className={"w-3 h-3 bg-gray-800 mr-2 rounded-full"}></div>{" "}
          {t("resv.notNecessary")}
        </div>
        <div
          className={
            "font-medium flex items-center text-red-800 bg-red-200 rounded-full p-1 border-2 border-red-800"
          }
        >
          <div className={"w-3 h-3 bg-red-800 mr-2 rounded-full"}></div>{" "}
          {t("resv.notExecuted")}
        </div>
        <div
          className={
            "font-medium flex items-center text-yellow-800 bg-yellow-300 rounded-full p-1 border-2 border-yellow-800"
          }
        >
          <div className={"w-3 h-3 bg-yellow-800 mr-2 rounded-full"}></div>{" "}
          {t("resv.waitingConfirmation")}
        </div>

        <div
          className={
            "font-medium flex items-center text-green-800 bg-green-100 rounded-full p-1 border-2 border-green-800"
          }
        >
          <div className={"w-3 h-3 bg-green-800 mr-2 rounded-full"}></div>{" "}
          {t("resv.confirmed")}
        </div>
      </div> */}

      <div className="flex border-t">
        <div className="w-52 bg-gray-100 border-r flex items-center justify-end px-2">
          <Icon
            onClick={() => {
              const newDate = dayjs(startDate).subtract(1, "days");
              setStartDate(newDate.format("YYYY-MM-DD"));
              setEndDate(newDate.add(20, "days").format("YYYY-MM-DD"));
            }}
            className={"cursor-pointer"}
            name={"chevron-left"}
            size={"20px"}
          />
          <Icon
            onClick={() => {
              const newDate = dayjs(startDate).add(1, "days");
              setStartDate(newDate.format("YYYY-MM-DD"));
              setEndDate(newDate.add(20, "days").format("YYYY-MM-DD"));
            }}
            className={"cursor-pointer"}
            name={"chevron-right"}
            size={"20px"}
          />
        </div>
        <div className="flex-1 flex">
          {days?.map((day, index) => (
            <div
              key={index}
              className={`relative flex-1 border-r text-center py-3 font-medium text-sm ${
                day.dayNumber === 0 ? "text-red-600" : "text-gray-500"
              }`}
            >
              {day.weekday} {day.day}
              {index === 0 && (
                <div
                  className={"border-r border-cyan-400"}
                  style={{
                    position: "absolute",
                    right: 0,
                    width: "1px",
                    top: 0,
                    height: "100vh",
                  }}
                />
              )}
            </div>
          ))}
        </div>
      </div>

      <ApartmentCalendariCalRow
        startDate={startDate}
        days={days}
        local={{
          name: name,
          local_key: local_key,
        }}
        reservations={getLocalReservations()}
      />
    </div>
  );
};

export default ApartmentCalendariCalDt;
