import { useEffect, useState } from "react"
import Skeleton from "react-loading-skeleton"
import { useMutation, useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import { components } from "react-select"
import { toast } from "react-toastify"
// import useBreadcrumbs from "../../hooks/useBreadcrumbs"
import { deleteDomoticRule, fetchDomoticRules, storeDomoticRule, updateDomoticRule, executeDomoticRule, sendCommand } from '../../shared/queries'
import Button from "../Button"
import DeleteDialog from "../DeleteDialog"
import Icon from "../Icon"
import Modal from "../Modal"
import ModalActions from "../ModalAction"
import ModalCard from "../ModalCard"
import ModalSection from "../ModalSection"
import RuleManager from "./RuleManager"
import { useTranslation } from "react-i18next"
export default function RuleList () {

  const [deleteRule, setDeleteRule] = useState<any>(undefined)
  const [currentRule, setCurrentRule] = useState<any>()
  const [createRule, setCreateRule] = useState<boolean>(false)
  const { t } = useTranslation()



  const {
    data,
    isFetching: isLoadingRules,
    refetch: refetchRules
  } = useQuery(['localRules'], () => fetchDomoticRules(), {
  })

  /* useBreadcrumbs([
    { label: t('navigation.domotics'), route: '/domotics' },
    { label: t('rules.rules'), route: '/rules' }
  ]) */

 

  const storeRuleMutation = useMutation(() => storeDomoticRule({
    local_key: currentRule.local_key,
    rule: currentRule
  }), {
    onSuccess: () => {
      toast.success(t('rules.saved'))
      setCurrentRule(null)
      setCreateRule(false)
      refetchRules()
    },
    onError: (data: any) => {
      toast.error(data)
    }
  })

  const updateRuleMutation = useMutation(() => updateDomoticRule({
    local_key: currentRule.local_key,
    rule: currentRule
  }), {
    onSuccess: () => {
      toast.success(t('rules.updated'))
      setCurrentRule(null)
      setCreateRule(false)
      refetchRules()
    },
    onError: (data: any) => {
      toast.error(data)
    }
  })

  const deleteRuleMutation = useMutation((rule: any) => deleteDomoticRule(rule.id, rule.local_key), {
    onSuccess: () => {
      toast.success(t('general.operationCompleted'))
      setDeleteRule(undefined)
      refetchRules()
    }
  })

  const executeRuleMutation = useMutation((rule: any) => executeDomoticRule(rule), {
    onSuccess: () => {
      toast.success(t('rules.executed'))
    }
  })

  const enableDisableRuleMutation = useMutation((rule: any) => sendCommand({
            top: 'RULE_ACTIVATION',
            local_key: rule.conditions[0].local_key,
            command: {
                rule_id: rule.id,}}), {
    onSuccess: (resp, rule) => {
      if(rule.active === 'True'){
        toast.success(t('rules.paused'))
      }else{
        toast.success(t('rules.resumed'))
      }
      refetchRules()
    }
  })
  const PrefixedControl = ({ label, children, ...props }: { label: string, children: any }) => {
    return (
      <components.Control {...props as any}>
        <span className={'font-semibold pl-3 text-gray-800'}>
          {label}
        </span>
        {children}
      </components.Control>
    );
  }

  
  return (
    <div>

      <DeleteDialog
        askConfirmation={true}
        isLoading={deleteRuleMutation.isLoading}
        visible={deleteRule !== undefined}
        confirmationKey={t('general.iConfirm')}
        message={t('rules.confirm_delete')}
        onCancel={() => setDeleteRule(undefined)}
        onConfirm={() => {
          deleteRuleMutation.mutate(deleteRule)
        }} />

      <div className="flex items-center justify-between mb-10">
        <div className="flex space-x-6 items-center">
          <div>
            <div className="bg-cyan-100 p-2 rounded-full text-cyan-700">
              <Icon name={'beaker'} size={'25px'} />
            </div>
          </div>
          <div>
            <div className="text-2xl font-medium">{t('rules.rules')}</div>
            <div className="text-gray-500 text-sm">
              {t('rules.realtime_monitoring')}
            </div>
          </div>
        </div>

        <div className="flex items-center -m-1">

            <div className="m-1">
              <Button
                label={t('rules.new_rule')}
                onClick={() => {
                  setCurrentRule(undefined)
                  setCreateRule(true)
                }} />
            </div>
        </div>
      </div>

      <div>
        {
          isLoadingRules &&
          <div className="grid grid-flow-col grids-col-2 gap-4">
            <Skeleton height={200} />
            <Skeleton height={200} />
            <Skeleton height={200} />
            <Skeleton height={200} />
          </div>
        }
        
        {
          (!isLoadingRules && data && data.results && data.results.length > 0) &&
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            {
              data.results.map((rule, index: number) => (
                <div key={index}
                  className={'bg-white rounded dash-card p-4'}>
                  <div className="text-lg font-medium">
                    {rule.description || 'Regola senza nome'}
                  </div>
                  <div className={'text-sm text-gray-600 mt-1'}>
                  {t('rules.rule_info', {
                    condition_length: (rule.conditions.length),
                    action_length: (rule.actions.length)
                  })}
                    
                  </div>
                  <div className={'flex justify-end space-x-1 mt-4'}>
                    <Button
                        icon={rule.active === 'True' ? 'pause' : 'play'}
                        onClick={() => enableDisableRuleMutation.mutate(rule)}
                        flat
                        padding={'xs'}
                        size={'small'} />
                  <Button
                      icon={'execute'}
                      onClick={() => executeRuleMutation.mutate(rule)}
                      flat
                      padding={'xs'}
                      size={'small'} />
                    <Button
                      icon={'pencil'}
                      onClick={() => {
                        setCurrentRule({
                          ...rule,
                          conditions: rule.conditions.map(c => {
                            return {
                              ...c,
                              value: (!c.value || c.value === 'None') ? null : c.value
                            }
                          })
                        })
                      }}
                      flat
                      padding={'xs'}
                      size={'small'} />
                    <Button
                      icon={'trash'}
                      color={'negative'}
                      onClick={() => setDeleteRule(rule)}
                      flat
                      padding={'xs'}
                      size={'small'} />
                  </div>
                </div>
              ))
            }
          </div>
        }

        {
          (!isLoadingRules && data && data.results && data.results.length === 0) &&
          <div className={'p-4 flex flex-col justify-center items-center'}>
            <img
              className={'w-44 rounded-full'}
              src={'/search.svg'} />
            <div className="font-medium text-lg">
              {t('rules.no_rule_available')}
            </div>
            <p className={'text-gray-600'}>
              {t('rules.add_your_first_rule')}
            </p>
            <Button
              className={'mt-4'}
              label={t('rules.new')}
              onClick={() => {
                setCurrentRule(undefined)
                setCreateRule(true)
              }} />
          </div>
        }

        <Modal
          visible={(createRule || currentRule)}>
          <ModalCard
            style={{
              width: '100%',
              maxWidth: '800px'
            }}>
            <ModalSection>
              <div className="p-4">
                <RuleManager
                  value={currentRule}
                  onChange={rule => {
                    setCurrentRule(rule)
                  }} />
              </div>
            </ModalSection>
            <ModalActions
              isLoading={false}
              saveLabel={t('rules.save')}
              onClose={() => {
                setCurrentRule(undefined)
                setCreateRule(false)
              }}
              onSave={() => {
                if (currentRule.id) {
                  updateRuleMutation.mutate()
                } else {
                  storeRuleMutation.mutate()
                }
              }}></ModalActions>
          </ModalCard>
        </Modal>

      </div>
    </div>
  )

}