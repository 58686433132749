import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { RootState } from '../../reducers'
import { fetchDomoticGraph } from '../../shared/queries'
import { normalizeTimestamp } from '../../shared/utils/formatting'
import { SocketManager } from '../../types'
import CalendarHeatmap from '../common/CalendarHeatmap'
import ChartJsWrapper from '../common/ChartJsWrapper'
import ProgressBar from '../ProgressBar'

type DomoticGraphProps = {
  entityType: string,
  localKey: string,
  entityId: string,
  graphType: string[],
  deviceMac: string,
  visible: boolean,
  onData?: () => void,
  vikeyMac: string,
  range: {
    from: Date,
    to: Date
  },
  isRealTime: boolean,
  socketManager: SocketManager
}

export default function DomoticGraph ({
  localKey,
  vikeyMac,
  visible,
  entityId,
  deviceMac,
  socketManager,
  graphType,
  entityType,
  isRealTime,
  range,
  onData
} : DomoticGraphProps) {

  const [realtimeData, setRealtimeData] = useState<any>([])
  const [historicalData, setHistoricalData] = useState<any>([])
  const [offlineData, setOfflineData] = useState<any>([])


  const {
    events
  } = useSelector((state: RootState) => state.socket)

  /**
   * Real time data watcher
   */
  useEffect(() => {
    setRealtimeData(events.filter(e => {
      if (
        e.type === 'entity_update' &&
        e.data.vikey_mac === vikeyMac &&
        e.data.entity.entity_id === entityId
      ) {
          return e
        }
    }).map(e => {
      return {
        time: parseInt(e.data.time_epoch),
        value: e.data.entity.value
      }
    }).map((d: any) => {
      let time = dayjs().unix()
      let value = 0

      if (isNaN(d.time)) {
        time = dayjs(d.time).valueOf()
      } else {
        time = dayjs(normalizeTimestamp(d.time)).valueOf()
      }

      if (isNaN(parseInt(d.value))) {
        value = d.value === 'True' ? 1 : 0
      } else {
        value = parseInt(d.value)
      }

      return {
        time,
        value
      }
    }))
  }, [ events ])

  /**
   * Historical data retreival
   */
  const {
    isFetching
  } = useQuery(['chartData', entityType, entityId, deviceMac, range], () => fetchDomoticGraph(
    localKey,
    deviceMac,
    entityId,
    dayjs(range.from).format('YYYY-MM-DD HH:mm:ss'),
    dayjs(range.to).format('YYYY-MM-DD HH:mm:ss')
  ), {
    onSuccess: data => {
      if (data && data.results &&  data.results.offline && Array.isArray(data.results.offline) && data.results.offline.length > 0) {
        setOfflineData(data.results.offline.map((d: any) => {
          var from;
          var to;
          if (isNaN(d.from)) {
            from = dayjs(d.from).valueOf()
          } 
          if (isNaN(d.to)) {
            to = dayjs(d.to).valueOf()
          } 
          return {
            from,
            to
          }
        }))
      }else{
        setOfflineData([])
      }
      if (data && data.results &&  data.results.data && Array.isArray(data.results.data) && data.results.data.length > 0) {
        setHistoricalData(data.results.data.map((d: any) => {
          let time = dayjs().unix()
          let value = 0

          if (isNaN(d.time)) {
            time = dayjs(d.time).valueOf()
          } else {
            time = dayjs(normalizeTimestamp(d.time)).valueOf()
          }

          if (isNaN(parseInt(d.value))) {
            value = d.value === 'True' || d.value === true ? 1 : 0
          } else {
            value = parseInt(d.value)
          }

          return {
            time,
            value
          }
        }))
      } else {
        setHistoricalData([])
      }
    }
  })

  useEffect(() => {
    if (historicalData.length > 0 && onData) onData()
    if (realtimeData.length > 0 && onData) onData()
  }, [ historicalData, realtimeData ])

  useEffect(() => {
    socketManager.subscribe({
      topic: `events/${vikeyMac}/entity_update/${deviceMac}/${entityType}/${entityId}`
    })

    const callback = (data: any) => {}
    
    socketManager.listen({
      event: 'message',
      callback
    })

    return () => {
      socketManager.unsubscribe()
      socketManager.unlisten({
        event: 'message',
        callback
      })
    }
  }, [ localKey ])

  return (
    <>
    {
      (visible && isFetching) && <ProgressBar />
    }
    {
      (historicalData.length > 0 || realtimeData.length > 0) &&
      <div className="w-full h-full p-4">
        {
          (graphType.indexOf('line_graph') > -1 || graphType.indexOf('squared_chart') > -1) &&
          <div style={{ height: 250 }} className="w-full h-full">
          {/* <h1 className="mb-1">{entityType}</h1> */}
            <ChartJsWrapper
              isRealTime={isRealTime}
              title={entityType}
              realtimeData={realtimeData}
              historicalData={historicalData}
              offlineData={offlineData}
              squared={graphType.indexOf('squared_chart') > -1 ? true : false}
              chartType={'line'} />
          </div>
        }
      </div>
    }
    </>
  )

}