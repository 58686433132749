import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link, useHistory, useParams } from "react-router-dom";
import DetailRow from "../components/DetailRow";
import FormSectionTitle from "../components/FormSectionTitle";
import Icon from "../components/Icon";
import PageWrapper from "../components/PageWrapper";
// import useBreadcrumbs from "../hooks/useBreadcrumbs";
import { fetchOrder } from "../shared/queries";
import { getHumanDateFromISO } from "../shared/utils/formatting";

export default function OrderPage () {

  const { t } = useTranslation()

  const { id } = useParams()
  const history = useHistory()

  const {
    data,
    isLoading
  } = useQuery(['order', id], () => fetchOrder(id))

  /* useBreadcrumbs([
    { label: t('orders.orders'), route: '/orders' },
    { label: (data && data.results && data.results.resv.resv_key) ? data.results.resv.resv_key : '...' }
  ]) */

  return (
    <PageWrapper>
      
      {
        (data && data.results && !isLoading) &&
        <>
          <div>
              <div
                onClick={() => history.goBack()}
                className={'flex items-center hover:text-gray-800 text-gray-500 cursor-pointer text-sm'}>
                <Icon name={'arrow-narrow-left'} className={'mr-2'} size={'20px'}></Icon> {t('general.backToList')}
              </div>
              <div className={'mt-8'}>
                <div className={'flex justify-between items-center'}>
                  <div>
                    <div className={'flex items-center'}>
                      <div className={'text-2xl font-bold'}>
                        <span className={'text-disambuigation'}>{data.results.id}</span> <span className={'text-gray-400 font-normal'}>–</span> {data.results.local.name}
                      </div>
                    </div>

                    <div className={'py-2 border-t mt-2 border-gray-300 mb-6'}>
                      <div className={'flex flex-wrap py-1 -mx-4'}>
                      <div className={'px-4 mb-3 w-1/2 md:mb-0 md:w-auto md:border-r border-gray-200 text-gray-600 text-sm'}>
                          <div className={'pb-3 md:pb-0 border-b md:border-b-0'}>
                            <div className={'mb-1 font-medium'}>{t('orders.code')}</div>
                            <div>
                              <Link className={'text-cyan-600'} to={'/reservations/' + data.results.resv.resv_key}>{data.results.resv.resv_key}</Link>
                            </div>
                          </div>
                        </div>

                        <div className={'px-4 mb-3 w-1/2 md:mb-0 md:w-auto md:border-r border-gray-200 text-gray-600 text-sm'}>
                          <div className={'pb-3 md:pb-0 border-b md:border-b-0'}>
                            <div className={'mb-1 font-medium'}>{t('order.total')}</div>
                            <div>
                              {data.results.amount_tot} EUR
                            </div>
                          </div>
                        </div>

                        <div className={'px-4 mb-3 w-1/2 md:mb-0 md:w-auto md:border-r border-gray-200 text-gray-600 text-sm'}>
                          <div className={'pb-3 md:pb-0 border-b md:border-b-0'}>
                            <div className={'mb-1 font-medium'}>{t('orders.apartment')}</div>
                            <div>
                              <Link className={'text-cyan-600'} to={'/apartments/' + data.results.local.local_key}>{data.results.local.public_name}</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          <FormSectionTitle
            title={t('order.guestData')}></FormSectionTitle>

          <DetailRow
            label={t('order.guestName')}>
              {data.results.resv.name}
          </DetailRow>

          <DetailRow
            label={t('order.filledName')}>
              {data.results.resv.guest_filled_fname} {data.results.resv.guest_filled_lname}
          </DetailRow>

          <DetailRow
            label={t('order.guestEmail')}>
              {data.results.resv.guest_email}
          </DetailRow>
        
          <DetailRow
            label={t('order.guestPhone')}>
              {data.results.resv.guest_phone || 'ND'}
          </DetailRow>

          <FormSectionTitle
            title={t('order.orderDetails')}></FormSectionTitle>

          <DetailRow
            label={t('orders.amount')}>
              {data.results.amount_tot || 'ND'}
          </DetailRow>

          <DetailRow
            label={t('order.creationDate')}>
              {getHumanDateFromISO(data.results.date_creation, true) || 'ND'}
          </DetailRow>

          <DetailRow
            label={t('order.paymentDate')}>
              {getHumanDateFromISO(data.results.date_payment, true) || 'ND'}
          </DetailRow>

          <DetailRow
            label={t('order.status')}>
              { (data.results.b_status === 'SUBMITTED')  &&  <span className={'bg-green-100 font-medium text-green-800 text-xs rounded-2xl px-2 py-1'}>{t('apartmentServices.paymentVerified')}</span>}
              { (data.results.b_status === 'ERROR')  &&  <span className={'bg-green-100 font-medium text-red-800 text-xs rounded-2xl px-2 py-1'}>{t('general.error')}</span>}
              { (data.results.b_status === 'DONE')  &&  <span className={'bg-green-100 font-medium text-green-800 text-xs rounded-2xl px-2 py-1'}>{t('apartmentServices.paymentMade')}</span>}
              { (data.results.b_status === 'COMPLETED')  &&  <span className={'bg-green-100 font-medium text-green-800 text-xs rounded-2xl px-2 py-1'}>{t('apartmentServices.paymentCompleted')}</span>}
              { (data.results.b_status === 'CONFIRMED')  &&  <span className={'bg-green-100 font-medium text-green-800 text-xs rounded-2xl px-2 py-1'}>{t('apartmentServices.paymentMade')}</span>}
              { (data.results.b_status === 'CEASED')  && <span className={'bg-green-100 font-medium text-red-800 text-xs rounded-2xl px-2 py-1'}>{t('apartmentServices.paymentCeased')}</span>}
              { (data.results.b_status === 'REFUND')  &&  <span className={'bg-green-100 font-medium text-gray-800 text-xs rounded-2xl px-2 py-1'}>{t('apartmentServices.paymentRefund')}</span>}
          </DetailRow>

          <FormSectionTitle
            title={t('order.serviceAssociated')}></FormSectionTitle>
            {data.results.buyables.map((value, index)  => (
              <DetailRow label={value.shown_title}>
              </DetailRow>
              
            ))
          }
          





        </>
      }

    </PageWrapper>
  )

}