import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "../../reducers";
import { updateProfile } from "../../shared/queries";
import FormField from "../FormField";
import Modal from "../Modal";
import ModalActions from "../ModalAction";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import TextInput from "../TextInput";
import addEmailSchema from "../../shared/schema/addEmailSchema";

type UpdateProfileProps = {
  visible: boolean;
  onClose?: () => void;
  onSave?: () => void;
};

export default function UpdateProfile({
  visible,
  onClose,
  onSave,
}: UpdateProfileProps) {
  const { t } = useTranslation();

  const { profile } = useSelector((state: RootState) => state.login);

  const [profileData, setProfileData] = useState<any>();
  const [validationError, setValidationError] = useState<any>(null);

  useEffect(() => {
    setProfileData(profile);
  }, [profile]);

  const mutation = useMutation(
    () =>
      updateProfile({
        name: profileData?.name,
        contact_fullname: profileData?.contact_fullname,
        mail: profileData?.mail,
        phone: profileData?.phone,
        province: profileData?.province,
        city: profileData?.city,
        zip: profileData?.zip,
        address: profileData?.address,
      }),
    {
      onSuccess: () => {
        toast.success(t("general.operationCompleted"));
        if (onSave) onSave();
      },
      onError: () => {
        toast.error(t("general.requestError"));
      },
    }
  );

  return (
    <Modal visible={visible}>
      <ModalCard
        className={"w-full lg:max-w-4xl"}
        title={t("updateProfile.profile")}
      >
        <ModalSection>
          <div className={"p-4 flex-wrap flex -mx-2"}>
            <FormField
              className={"px-2 mb-4 w-full"}
              label={"Email"}
              errors={validationError}
              errorKey={"email"}
              // </div>tooltip={t('updateProfile.contactVikey')}
            >
              <TextInput
                autofocus={true}
                value={profileData?.mail}
                onChange={(val) => {
                  const { error } = addEmailSchema.validate({
                    email: val,
                  });
                  if (error) {
                    setValidationError(error);
                  } else {
                    setValidationError(null);
                  }
                  setProfileData({
                    ...profileData,
                    mail: val,
                  });
                }}
              ></TextInput>
            </FormField>

            <FormField
              className={"px-2 mb-4 w-full"}
              label={t("profile.profileName")}
            >
              <TextInput
                value={profileData?.name}
                onChange={(val) =>
                  setProfileData({
                    ...profileData,
                    name: val,
                  })
                }
              ></TextInput>
            </FormField>

            <FormField
              className={"px-2 mb-4 w-full"}
              label={t("profile.contactName")}
            >
              <TextInput
                value={profileData?.contact_fullname}
                onChange={(val) =>
                  setProfileData({
                    ...profileData,
                    contact_fullname: val,
                  })
                }
              ></TextInput>
            </FormField>

            <FormField
              className={"px-2 mb-4 w-full"}
              label={t("updateProfile.phone")}
            >
              <TextInput
                value={profileData?.phone}
                onChange={(val) =>
                  setProfileData({
                    ...profileData,
                    phone: val,
                  })
                }
              ></TextInput>
            </FormField>
            <FormField
              className={"px-2 mb-4 w-full"}
              label={t("general.address")}
            >
              <TextInput
                value={profileData?.address}
                onChange={(val) =>
                  setProfileData({
                    ...profileData,
                    address: val,
                  })
                }
              ></TextInput>
            </FormField>

            <div className="flex">
              <div className="w-1/3">
                <FormField
                  className={"px-2 mb-4 w-full"}
                  label={t("general.city")}
                >
                  <TextInput
                    value={profileData?.city}
                    onChange={(val) =>
                      setProfileData({
                        ...profileData,
                        city: val,
                      })
                    }
                  ></TextInput>
                </FormField>
              </div>

              <div className="w-1/3">
                <FormField
                  className={"px-2 mb-4 w-full"}
                  label={t("updateProfile.province")}
                >
                  <TextInput
                    value={profileData?.province}
                    onChange={(val) =>
                      setProfileData({
                        ...profileData,
                        province: val,
                      })
                    }
                  ></TextInput>
                </FormField>
              </div>

              <div className="w-1/3">
                <FormField
                  className={"px-2 mb-4 w-full"}
                  label={t("updateProfile.zip")}
                >
                  <TextInput
                    value={profileData?.zip}
                    onChange={(val) =>
                      setProfileData({
                        ...profileData,
                        zip: val,
                      })
                    }
                  ></TextInput>
                </FormField>
              </div>
            </div>
          </div>
        </ModalSection>
        <ModalActions
          saveDisabled={validationError}
          isLoading={mutation.isLoading}
          saveLabel={t("general.save")}
          onClose={() => {
            if (onClose) onClose();
          }}
          onSave={() => {
            mutation.mutate();
          }}
        ></ModalActions>
      </ModalCard>
    </Modal>
  );
}
