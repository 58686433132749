import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { forcePaymentCheck } from '../shared/queries';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { DomainContext } from '../DomainContext';
import {RootState} from "../store";
import { useSelector } from 'react-redux';
import Header from '../components/Header';
import Icon from '../components/Icon';
import NavigationMenu from '../components/NavigationMenu';
import React from 'react';

export default function SecureLayout({ children } : { children: any }) {
  const { t } = useTranslation();
  const { appLogo, appName, /* appType */ } = React.useContext(DomainContext);

  const [isMini, setIsMini] = useState(isMobile ? true : false);
  const { profile } = useSelector((state: RootState) => state.login);

  useEffect(() => {
    if (isMobile) setIsMini(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const history = useHistory();

  useEffect(() => {
    history.listen(handleHistory);
    return history as any;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* useEffect(() => {
    // return () => window.removeEventListener('resize', resetHeight)
  }, []) */

  async function handleHistory (location: any, action: any) {
    if (
        location.pathname !== '/configuration/payment-methods' &&
        location.pathname !== '/configuration/payment-methods/associate'
    ) {
      const paymentStatus = await forcePaymentCheck() as any;
      if (paymentStatus && paymentStatus.results === true) {
        toast.error(t('general.' + paymentStatus.msg));
        history.push('/configuration/payment-methods');
      }
    }
  }

  return (
    <>
      {profile && (
        <div style={{
          'minHeight': '-webkit-fill-available'
        }} className={`h-screen w-full bg-gray-50 flex  ${isMobile ? 'pb-16' : ''}`}>
          {!isMobile && (
            <div
              className={`w-auto bg-white pr-3 border-gray-200 border-r xs:pr-0 ${
                !isMini ? 'relative pr-8' : 'relative'
              }`}
            >
              <div className={'p-4 pl-7 h-12 text-gray-500 flex items-center'}>
                <div onClick={() => setIsMini(!isMini)}>
                  <Icon
                    className={'cursor-pointer'}
                    name={'menu'}
                    size={'22px'}
                  ></Icon>
                </div>
                {!isMini && (
                  <img
                    src={appLogo}
                    alt={appName + ' logo'}
                    className={'h-8 ml-4 inline-block'}
                  ></img>
                )}
              </div>

              <NavigationMenu isMini={isMini} />
            </div>
          )}

          <div className={'flex-1 overflow-auto overflow-x-hidden'}>
            <div className={''}>
              <Header></Header>
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
