import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import LoadingOverlay from "../components/common/LoadingOverlay";
import { oauthCloudBeds, fetchCountries } from "../shared/queries"
import { getAuthToken } from "../shared/utils/tokenStore";
import { useQuery } from 'react-query';
import { toast } from 'react-toastify'





function useQueryParams() {
    return new URLSearchParams(useLocation().search);
  }

export default function IntegrationsCallbackOauthPage () {

    const { type } = useParams()
    const query = useQueryParams()
    const history = useHistory()

     useQuery(
      'oauth' + type,
      () => oauthCloudBeds("code="+query.get('code')+"&state="+getAuthToken()),
      {
        onSuccess(data) {
          if (data && data.results && data.results.integr_id ) {
            history.push('/integrations/' + type + '/' + data.results.integr_id)
          }
        },
        onError(error) {
          toast.error(error.message);
        },
      }
    );

    return (
        <LoadingOverlay />
    )

}