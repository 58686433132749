import React, { useEffect, useState } from "react";
import FormSectionTitle from "../FormSectionTitle";
import Button from "../Button";
import SelectInput from "../SelectInput";
import { useTranslation } from "react-i18next";
import FormField from "../FormField";
import { BaseSelectItem } from "../../types";
import { StylesConfig } from "react-select";

interface MultiSelectProps {
  className: string;
  label?: string;
  title: string;
  loading?: boolean;
  isCleared?: boolean;
  onButtonClick: (
    data: { label: string | undefined; value: string | undefined }[]
  ) => void;
  objStateStart?: { label: string | undefined; value: string | undefined }[];
  moduleActive?: boolean;
  objOptions?: { label: string | undefined; value: string | undefined }[];
  buttonColor?: "primary" | "negative" | "success" | "info";
  buttonLabel?: string;
  buttonClassName?: string;
  buttonDisabled?: boolean;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  className,
  label = "",
  title,
  loading,
  isCleared = false,
  onButtonClick,
  objStateStart,
  moduleActive = true,
  objOptions = [],
  buttonColor = "primary",
  buttonLabel = "general.save",
  buttonClassName = "md:mt-0 mt-5",
  buttonDisabled = false,
}) => {
  const { t } = useTranslation();

  const [selectStart, setSelectStart] = useState<any>([]);

  const onChange = (newValue: any, actionMeta: any) => {
    switch (actionMeta.action) {
      case "remove-value":
      case "pop-value":
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case "clear":
        newValue = selectStart.filter((v: any) => v.isFixed);
        break;
    }

    setSelectStart(newValue);
  };

  const styles: StylesConfig<any> = {
    multiValue: (base, state) => {
      return state.data.isFixed
        ? { ...base, backgroundColor: "lightblue" }
        : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: "regular", color: "black", paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: "none" } : base;
    },
  };

  useEffect(() => {
    const values =
      objStateStart
        ?.map((obj) => {
          return { label: obj.label, value: obj.value, isFixed: true };
        })
        ?.filter((obj) => obj.value !== undefined) || [];
    setSelectStart(values);
  }, [objStateStart]);

  return (
    <div>
      <FormSectionTitle title={t(title)} onEdit={() => {}}></FormSectionTitle>
      <FormField className={className} label={t(label)}>
        <div className="md:flex gap-5">
          <div className="w-full">
            <SelectInput<BaseSelectItem, true>
              isMulti
              isDisabled={!moduleActive}
              openMenuOnClick={!moduleActive}
              isClearable={
                isCleared && selectStart.some((v: any) => !v.isFixed)
              }
              value={selectStart}
              onChange={(item, action) => {
                if (moduleActive) {
                  onChange(item, action);
                }
              }}
              options={objOptions}
              styles={styles}
            />
          </div>
          <Button
            color={buttonColor}
            label={t(buttonLabel)}
            className={buttonClassName}
            disabled={buttonDisabled || loading}
            onClick={() => onButtonClick(selectStart)}
            loading={loading}
          ></Button>
        </div>
      </FormField>
    </div>
  );
};

export default MultiSelect;
