import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { createContractEmailCheckin, fetchAccounts, fetchPerms, updateCustomEmail } from "../../shared/queries"
import addCustomEmailSchema from "../../shared/schema/addCustomEmailSchema"
import { BaseSelectItem } from "../../types"
import FormField from "../FormField"
import Modal from "../Modal"
import ModalActions from "../ModalAction"
import ModalCard from "../ModalCard"
import ModalSection from "../ModalSection"
import SelectInput from "../SelectInput"
import TextInput from "../TextInput"

export default function CreateEmail ({
  visible,
  onClose
} : {
  visible: boolean,
  onClose: () => void
}) {

  const { t } = useTranslation()

  const history = useHistory()
  const [validationError, setValidationError] = useState<any>(null)
  const [title, setTitle] = useState<string>('')
  const [account, setAccount] = useState<string>('')

  const mutation = useMutation(() => createContractEmailCheckin({
    id: 'new',
    objkey: title,
    user_key: account,
    objtype: 'EMAIL'
  }), {
    onSuccess: data => {
      toast.success(t('general.created'))
      if (data && data.results) {
        history.push('/emails/' + data.results)
        onClose()
      }
    },
    onError: data => {
      toast.error(t('general.requestError'))
    }
  })

  const { data, isLoading } = useQuery("accounts", fetchPerms);

  function handleSubmit () {
    const { error, value } = addCustomEmailSchema.validate({
      id: 'new',
      user_key: account,
      objkey: title
    })
    if (error) {
      setValidationError(error)
      toast.error(t('general.invalidFields'))
    } else {
      setValidationError(null)
      mutation.mutate()
    }
  }

  return (
    <Modal
      visible={visible}>
      <ModalCard
          className={'w-full lg:max-w-4xl'}
        title={t('createCustomEmail.addCustomEmail')}>
        <ModalSection>

          <div className="p-4 space-y-4">
          <FormField
              label={"Account"}
              errors={validationError}
              errorKey={"user_key"}
            >
              <SelectInput<BaseSelectItem, false>
                onChange={(item) => {
                  if (item) setAccount(item.value);
                }}
                options={
                  data && data.results
                    ? data.results.map((account) => {
                        return {
                          label: account.o_name,
                          value: account.o_user_key,
                        };
                      })
                    : []
                }
              />
            </FormField>

            <FormField
              errors={validationError}
              errorKey={'objkey'}
              label={t('createCustomEmail.title')}>
              <TextInput
                value={title}
                onChange={val => setTitle(val)}></TextInput>
            </FormField>
          </div>

        </ModalSection>

        <ModalActions
          isLoading={false}
          closeLabel={t('general.cancel')}
          saveLabel={t('general.create')}
          onClose={() => {
            if (onClose) onClose()
          }}
          onSave={handleSubmit}></ModalActions>
      </ModalCard>
    </Modal>
  )

}