import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom"; // Portal support
import Button from "./Button";
import { MessageAttachments } from "../types";

type CarouselModalProps = {
  visible: boolean;
  onClose?: () => void;
  attachments: MessageAttachments[];
  className?: string;
  noBackdropDismiss?: boolean;
  index?: number;
};

export const CarouselModal: React.FC<CarouselModalProps> = ({
  visible,
  onClose,
  attachments,
  className = "",
  noBackdropDismiss = false,
  index,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(index ? index : 0);

  useEffect(() => {
    if (index) {
      setCurrentIndex(index);
    }
  }, [index]);

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
      setCurrentIndex(0);
    };
  }, [visible]);

  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (
      !noBackdropDismiss &&
      overlayRef.current &&
      event.target === overlayRef.current &&
      onClose
    ) {
      onClose();
    }
  };

  const showNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % attachments.length);
  };

  const showPreviousImage = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + attachments.length) % attachments.length
    );
  };

  if (!visible) return null;

  return ReactDOM.createPortal(
    <AnimatePresence>
      {visible && (
        <motion.div
          ref={overlayRef}
          className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 ${className}`}
          onClick={handleBackdropClick}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <motion.div
            className="relative bg-white max-w-[90vw] max-h-[85vh] rounded shadow-md overflow-hidden"
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            {/* Close Button */}
            <Button
              onClick={onClose}
              className="absolute top-2 right-2 text-gray-600 bg-white hover:bg-gray-100 focus:outline-none shadow-md rounded-full p-2 float-right"
              icon="close"
            ></Button>

            <div className="relative w-full h-full flex items-center justify-center">
              {/* Previous Button */}
              <Button
                onClick={showPreviousImage}
                className="absolute top-1/2 transform -translate-y-1/2 text-gray-600 bg-white hover:bg-gray-100 focus:outline-none shadow-md rounded-full p-2"
                icon="arrow-narrow-left"
                disabled={attachments.length === 1}
              ></Button>

              {/* Carousel Image */}
              <img
                src={attachments[currentIndex].relative_path}
                alt={`Carousel item ${currentIndex + 1}`}
                className="max-w-[70vw] max-h-[50vh] object-contain rounded-md mb-2"
                style={{ objectFit: "contain", width: "85vw", height: "75vh" }}
              />

              {/* Next Button */}
              <Button
                onClick={showNextImage}
                className="absolute top-1/2 transform -translate-y-1/2 text-gray-600 bg-white hover:bg-gray-100 focus:outline-none shadow-md rounded-full"
                icon="arrow-narrow-right"
                disabled={attachments.length === 1}
              ></Button>
            </div>

            {/* Image Counter */}
            <div className="absolute bottom-4 w-full flex justify-center">
              <span className="text-gray-600 text-sm">
                {currentIndex + 1} / {attachments.length}
              </span>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
};
