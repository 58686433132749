import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import DeleteDialog from '../components/DeleteDialog';
import Button from "../components/Button";
import CreateCustomCheckIn from "../components/checkin/CreateCustomCheckIn";
import PageTitle from "../components/PageTitle";
import { DeleteCustPtr, fetchCheckinsV3 } from "../shared/queries";
import { any } from "joi";
import { toast } from 'react-toastify'

export default function CustomCheckins () {

  const { t } = useTranslation()

  const history = useHistory()

  const [addCheckin, setAddCheckin] = useState(false)

  let {
    data,
    refetch: refetchCheckins
  } = useQuery('checkins', () => fetchCheckinsV3(undefined, true))

  const [showDelete, setShowDelete] = useState(false)

  const [checkin_id, setCheckin_id] = useState('')

  const deleteMutation = useMutation(() => DeleteCustPtr(checkin_id),  {onSuccess: () => {refetchCheckins(); toast.success(t('toast.successTitle'))}, onError: () => {toast.error(t('general.requestError'))}} )



  return (
    <div>
      <div className="inline-flex w-full justify-between">
      <PageTitle
        title={t('customCheckIn.customCheckin')}
        subtitle={t('customCheckIn.customCheckinText')}></PageTitle>
        <div >
        <Button
          onClick={() => setAddCheckin(true)}
          label={t('customCheckIn.newCheckIn')} />
      </div>
      </div>
      
      
      <div className={'space-y-4'}>
        {
          (data && data.results && Array.isArray(data.results)) && data.results.map((checkin, index) => (
            <div key={index} className={'justify-between cursor-pointer p-4 cst-shadow border rounded-2xl hover:bg-cyan-50 flex'}>
              <div className="font-medium">{checkin.objkey}</div>
              <div>
              <Button icon={'pencil'} padding={'xs'} flat onClick={() => {history.push('/custom-checkins/' + checkin.id)}}></Button>
              <Button icon={'trash'} padding={'xs'} flat onClick={() => {setShowDelete(true); if (checkin && checkin.id) setCheckin_id(''+checkin.id)}}></Button>
              </div>
            </div>
          ))
        }
      </div>

      

      <CreateCustomCheckIn
        visible={addCheckin}
        onClose={() => setAddCheckin(false)} />

        <DeleteDialog
        isLoading={deleteMutation.isLoading}
        visible={showDelete}
        message={t('services.removeService')}
        confirmationKey={t('general.iConfirm')}
        onCancel={() => setShowDelete(false)}
        onConfirm={() => {
          deleteMutation.mutate();
          setShowDelete(false)
        }}/>

    </div>
  )

}