import React, { useState } from 'react';
import { motion } from 'framer-motion';
import ResetPasswordForm from './ResetPasswordForm';
import LoginForm from './LoginForm';
import dayjs from 'dayjs';
import { DomainContext } from '../DomainContext';
import { useTranslation } from 'react-i18next';

export default function Login() {
  const [resetPassword, setResetPassword] = useState(false);
  const {appLogo, appName} = React.useContext(DomainContext);
  const {t} = useTranslation()

  return (
    <div
      className={
        'flex items-center justify-center bg-login bg-gray-100 w-screen h-screen p-4'
      }
    >
      <div className={'max-w-sm w-full'}>
        <div className={'text-center mb-6'}>
          <img
            src={appLogo}
            alt={appName + " logo"}
            className={'h-10 inline-block'}
          ></img>
        </div>

        {!resetPassword && (
          <motion.div
            initial={{
              opacity: 0.2,
            }}
            animate={{
              opacity: 1,
            }}
          >
            <LoginForm></LoginForm>
          </motion.div>
        )}

        {resetPassword && (
          <motion.div
            initial={{
              opacity: 0.2,
            }}
            animate={{
              opacity: 1,
            }}
          >
            <ResetPasswordForm setResetPassword={setResetPassword} ></ResetPasswordForm>
          </motion.div>
        )}

        <div className={'mt-6'}>
          <button
            onClick={(e) => {
              e.preventDefault();
              setResetPassword(!resetPassword);
            }}
            className={'focus:outline-none text-cyan-600 text-sm font-medium'}
          >
            {resetPassword
              ? '<- Torna al login'
              : t('login.loginForgot')}
          </button>
        </div>

        <div
          className={
            'font-medium text-sm text-center text-gray-500 mt-6 border-t pt-4'
          }
        >
          {appName} {dayjs().format('YYYY')} - All rights reserved.
        </div>
        <p className='text-xs text-center text-gray-500 hover:underline cursor-pointer' onClick={() => {
          window.location.replace('https://vikey.it/wp-content/policy-vikey/PrivacyPolicy-HOST.html')
        }}>Privacy Policy</p>
      </div>
    </div>
  );
}
