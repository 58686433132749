import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { fetchProvinces } from '../shared/queries';
import {
  SingleApiResponseV3,
} from '../types';
import SelectInput from './SelectInput';

type ProvinceSelectorProps = {
  country: string;
  initial: string;
  onChange?: (Province: ProvinceOption) => void;
};

type ProvinceOption = {
  label: string;
  value: string;
};

export default function ProvinceSelector({
  country,
  initial,
  onChange,
}: ProvinceSelectorProps) {
  const [current, setCurrent] = useState<ProvinceOption | null>(null);

  const { data: provinces, isLoading } = useQuery<
    SingleApiResponseV3<any>,
    Error
  >(['provinces'], () => fetchProvinces(country));

  useEffect(() => {
    // if (provinces && provinces.results) {
    //   const country = provinces.results.en[initial];
    //   if (country){ //&& isDefined(country.id)) {
    //     setCurrent({
    //       label: country.name,
    //       value: country.code,
    //     });
    //   }
    // }
    setCurrent({value: initial, label: initial})
  }, [initial,provinces]);

  return (
    <SelectInput
      // menuShouldScrollIntoView={false}
      menuPortalTarget={document.body}
      onChange={(item) => {
        setCurrent(item as ProvinceOption);
        if (onChange && item) onChange(item as ProvinceOption);
      }}
      value={current}
      options={isLoading?[]:
          provinces? Object.values(provinces.results).map((e:any) => {
            return {
              label: e,
              value: e,
            }}).sort((a,b)=>a.label>b.label?1:-1)
        :
        []
      }
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
    />
  );
}