import React, { useState, useRef, useEffect } from 'react';
import PageWrapper from '../PageWrapper';
import Button from '../Button';
import FaqList from './FaqList';
import { FaqEntry } from '../../types';
import { useTranslation } from "react-i18next";
import { createOrUpdateFaqs } from '../../shared/queries';

interface ApartmentFaqsProps {
    context_id?: string;
    local_key?: string;
    faqs?: Array<FaqEntry> | null; 
}

let faqIndex = 0;


const ApartmentFaqs: React.FC<ApartmentFaqsProps> = ({ context_id, local_key, faqs = [] }) => {
    const faqEnd = useRef<null | HTMLDivElement>(null);
    const { t } = useTranslation();
    const parsedFaqs = typeof faqs === 'string' ? JSON.parse(faqs) : faqs;

    const [faqData, setFaqData] = useState<Array<FaqEntry & { index: number }>>(
        Array.isArray(parsedFaqs) ? parsedFaqs.map((faq, i) => ({ ...faq, index: i })) : []
    );

    const scrollToBottom = () => {
        if (faqEnd.current) {
            setTimeout(() => {
                faqEnd.current?.scrollTo({
                    top: faqEnd.current.scrollHeight,
                    behavior: "smooth",
                });
            }, 100);
        }
    };

    const handleQuestionChange = (index: number, value: string) => {
        const updatedFaqs = [...faqData];
        updatedFaqs[index].question = value;
        setFaqData(updatedFaqs);
    };

    const handleAnswerChange = (index: number, value: string) => {
        const updatedFaqs = [...faqData];
        updatedFaqs[index].proposed_answer = value;
        setFaqData(updatedFaqs);
    };

    const addFaq = () => {
        const newFaq: FaqEntry & { index: number } = {
            entry_id: '',
            index: faqData.length,
            question: '',
            proposed_answer: '',
            created_on: new Date(),
            DEL: false,
        };
        setFaqData(prevFaqs => [...prevFaqs, newFaq]);
        scrollToBottom();
    };

    const deleteFaq = (index: number) => {
        const updatedFaqs = faqData.map((faq, idx) => 
            idx === index ? { ...faq, DEL: true } : faq
        );
        setFaqData(updatedFaqs);
    };

    const saveAllFaqs = () => {
        createOrUpdateFaqs(faqData,context_id)
        console.log('Saved FAQs:', faqData);

    };

    
     useEffect(() => {
        setFaqData(parsedFaqs.map((faq:FaqEntry, i:number) => ({ ...faq, index: i })));
    }, [faqs]);

    return (
        <PageWrapper className="h-full flex flex-col">
            <div className="p-4 flex-grow overflow-y-auto" ref={faqEnd}>
                <h2 className="text-2xl font-bold mb-4">Apartment FAQs</h2>
                <FaqList
                    faqs={faqData.filter(faq => !faq.DEL)}
                    onQuestionChange={handleQuestionChange}
                    onAnswerChange={handleAnswerChange}
                    onDeleteFaq={deleteFaq}
                    questionLabel={t("assistantPage.faqs.questionLabel")}
                    answerLabel={t("assistantPage.faqs.answerLabel")}
                    emptyMessage={t("assistantPage.faqs.emptyMessage")}
                />
            </div>
            
            {/* Buttons container */}
            <div className="p-4 border-t flex justify-center space-x-4">
                <Button
                    icon="plus-circle"
                    onClick={addFaq}
                    className="p-3 bg-cyan-500 text-white rounded-full hover:bg-cyan-600"
                />
                <Button
                    onClick={saveAllFaqs}
                    className="px-4 py-2 bg-cyan-500 text-white rounded-lg hover:bg-cyan-600"
                >
                    Save
                </Button>
            </div>
        </PageWrapper>
    );
};

export default ApartmentFaqs;
