import Skeleton from "react-loading-skeleton";

export default function LoadingIntegrationSkeleton () {

  return (
    <div>
      <div style={{
        marginTop: '2rem'
      }}>
        <Skeleton height={'18rem'} width={'100%'}></Skeleton>
      </div>
    </div>

  )

}