import React, { useState } from "react";
import { Local } from "../../types";
import { useMutation, useQuery } from "react-query";
import {
  createCalendarSync,
  deleteCalendarSync,
  fetchAllCalendars,
  fetchAllModules,
  fetchSyncJobiCal,
} from "../../shared/queries";
import { useTranslation } from "react-i18next";
import BaseTable from "../BaseTable";
import Button from "../Button";
import ReactTooltip from "react-tooltip";
import Modal from "../Modal";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import ModalActions from "../ModalAction";
import FormField from "../FormField";
import TextInput from "../TextInput";
import { toast } from "react-toastify";
import { moduleId } from "../../shared/utils/config";
import PageWrapper from "../PageWrapper";
import { useHistory } from "react-router-dom";
import Icon from "../Icon";
import FormSectionTitle from "../FormSectionTitle";
import ApartmentCalendariCalDt from "./ApartmentCalendariCalDt";
import { calendarIntegrations } from "../../shared/calendars";

type Props = {
  data: Local;
  refetch: () => void;
};

const ApartmentCalendar = (props: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { local_key, name } = props.data;
  const urlToCopy = `https://api.vikey.it/api/v3/resv/icalendar/${local_key}.ics`;
  const [copied, setCopied] = useState<boolean>(false);
  const { data, isLoading, refetch } = useQuery<any>(["calendarModules"], () =>
    fetchAllCalendars(local_key as string)
  );

  const [calendMod, setCalendMod] = useState({
    display: false,
    name: "",
    linkId: "",
    disableButton: true,
  });
  const [addiCal, setAddiCal] = useState({
    display: false,
    channel: "",
    link: "",
    local_key: local_key,
  });
  const [loadingData, setLoadingData] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: dataModules } = useQuery<any>([local_key], () => {
    if (local_key) {
      return fetchAllModules(local_key).then((data: any) => {
        const moduleCalendarFinded = data?.modules?.find(
          (module: any) => module?.id === moduleId.calendar
        );
        if (moduleCalendarFinded) {
          if (moduleCalendarFinded.active) {
            // modulo attivo
            setCalendMod({
              display: false,
              name: t(`module.id${moduleCalendarFinded.id}`).toUpperCase(),
              linkId: moduleCalendarFinded.id,
              disableButton: false,
            });
          } else {
            // modulo non attivo
            setCalendMod({
              display: true,
              name: t(`module.id${moduleCalendarFinded.id}`).toUpperCase(),
              linkId: moduleCalendarFinded.id,
              disableButton: true,
            });
          }
        } else {
          // modulo non trovato
          setCalendMod({
            display: true,
            name: t(`module.id${moduleCalendarFinded.id}`).toUpperCase(),
            linkId: moduleCalendarFinded.id,
            disableButton: true,
          });
        }
        return data;
      });
    }
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { refetch: refetchSyncJob } = useQuery<any>(
    [local_key],
    () => {
      if (local_key) {
        return fetchSyncJobiCal(local_key).then((data: any) => {
          return data;
        });
      }
    },
    {
      enabled: false,
      onSuccess: () => {
        toast.success(t("general.requestCompleted"));
      },
    }
  );
  const mutation = useMutation((data: any) => createCalendarSync(data), {
    onSuccess: () => {
      toast.success(t("general.requestCompleted"));
      setAddiCal((prev) => {
        return {
          display: !prev.display,
          channel: "",
          link: "",
          local_key: local_key,
        };
      });
      refetch();
      setLoadingData(false);
    },
    onError: () => {
      setLoadingData(false);
    },
  });
  const deleteMutation = useMutation((data: any) => deleteCalendarSync(data), {
    onSuccess: () => {
      toast.success(t("general.requestCompleted"));
      refetch();
      setLoadingData(false);
    },
    onError: () => {
      setLoadingData(false);
    },
  });
  const copyLink = () => {
    const locationClipboard = urlToCopy;
    navigator.clipboard.writeText(locationClipboard);
    setCopied(true);
  };

  return (
    <>
      <FormSectionTitle
        title={t("apartmentCalendar.exportLink")}
        onEdit={() => {}}
      ></FormSectionTitle>
      <div className="flex align-middle mb-10 flex-wrap text-sm">
        <div className={"w-40 xs:w-full text-gray-500"}>
          {t("apartmentCalendar.link")}
        </div>
        <div className="lg:flex align-middle">
          <div>{urlToCopy}</div>
          <div data-tip={"Copy"} className="lg:ml-2">
            <Icon
              className={`text-xs ${copied && "text-green-700"} cursor-pointer`}
              name="clipboard-check"
              size="20px"
              onClick={() => copyLink()}
            />
            <ReactTooltip backgroundColor="gray" />
          </div>
        </div>
      </div>
      <BaseTable
        title={t("apartmentCalendar.listTitle")}
        renderButtons={() => (
          <>
            <div data-tip={t("apartmentCalendar.updateStatus")}>
              <Button
                loading={isLoading}
                className="text-xs"
                disabled={calendMod.disableButton}
                onClick={() => {
                  refetch();
                }}
                flat
                icon="refresh"
                iconSize="15px"
              />
            </div>
            <div data-tip={"Sync"}>
              <Button
                loading={isLoading}
                className="text-xs"
                disabled={calendMod.disableButton}
                onClick={() => {
                  refetchSyncJob();
                }}
                flat
                icon="send"
                iconSize="15px"
              />
            </div>
            <Button
              label={t("apartmentCalendar.addCalendar")}
              disabled={calendMod.disableButton}
              onClick={() => {
                setAddiCal((prev) => {
                  return {
                    display: !prev.display,
                    channel: "",
                    link: "",
                    local_key: local_key,
                  };
                });
              }}
              icon="plus"
              size="small"
            />
          </>
        )}
        currentPage={1}
        identifierKey={"link"}
        onEdit={(link) => console.log("linkAdapated", link)}
        data={data?.results ?? []}
        loading={isLoading}
        displayRows={data?.results?.length}
        filters={[]}
        columns={[
          {
            Header: t<string>("buro.status"),
            accessor: (row: any) => {
              return (
                <div className={"flex space-x-2"}>
                  {row.status === "error" && (
                    <span
                      className={
                        "bg-red-200 text-red-800 font-medium px-2 py-1 rounded-2xl text-xs"
                      }
                    >
                      {t("general.error")}
                    </span>
                  )}
                  {row.status === "pending" && (
                    <span
                      className={
                        "bg-yellow-300 text-yellow-800 font-medium px-2 py-1 rounded-2xl text-xs"
                      }
                    >
                      Pending
                    </span>
                  )}
                  {row.status === "ok" && (
                    <span
                      className={
                        "bg-green-100 font-medium text-green-800 px-2 py-1 rounded-2xl text-xs"
                      }
                    >
                      Ok
                    </span>
                  )}
                </div>
              );
            },
          },
          {
            Header: t<string>("reservations.channel"),
            accessor: (row: any) => {
              return <div className={"flex space-x-2"}>{row.channel}</div>;
            },
          },

          {
            Header: t<string>("apartmentCalendar.link"),
            accessor: (row: any) => {
              return (
                <>
                  <div className={"flex space-x-2"} data-tip={row.link}>
                    <a
                      target={"_blank"}
                      href={row.link}
                      className={"text-cyan-600"}
                      rel="noreferrer"
                    >
                      {row.link}
                    </a>
                  </div>
                  <ReactTooltip backgroundColor="gray" />
                </>
              );
            },
          },
          {
            Header: t<string>("general.delete"),
            accessor: (row: any) => {
              return (
                <div className={"flex space-x-2"}>
                  <Button
                    flat
                    disabled={calendMod.disableButton || loadingData}
                    loading={loadingData}
                    onClick={() => {
                      setLoadingData(true);
                      deleteMutation.mutate(row.id);
                    }}
                    color="negative"
                    icon="trash"
                    iconSize={"15px"}
                  />
                </div>
              );
            },
          },
        ]}
      />

      {/* CALENDARIO */}
      <div className="w-full overflow-hidden mt-20 mb-96">
        <ApartmentCalendariCalDt
          local={{ name: name as string, local_key: local_key as string }}
        />
      </div>
      {/* CALENDARIO */}

      <Modal visible={addiCal.display}>
        <ModalCard
          title={t("apartmentCalendar.addCalendar")}
          className="min-w-96"
        >
          {addiCal.channel && (
            <Button
              label={t("table.prev")}
              loading={isLoading}
              disabled={calendMod.disableButton}
              onClick={() => {
                setAddiCal((prev) => {
                  return {
                    display: prev.display,
                    channel: "",
                    link: prev.link,
                    local_key: local_key,
                  };
                });
              }}
              flat
              icon="arrow-narrow-left"
              iconSize="20px"
              size="small"
            />
          )}
          <ModalSection className="p-5">
            <div className="flex flex-wrap">
              {!addiCal.channel &&
                calendarIntegrations
                  /* .filter(
                    (int) => int.key === "BOOKING" || int.key === "AIRBNB"
                  ) */
                  .map((integration: any, index: number) => (
                    <div
                      key={integration.key}
                      onClick={() => {
                        setAddiCal((prev) => {
                          return {
                            display: prev.display,
                            channel: integration.key,
                            link: prev.link,
                            local_key: local_key,
                          };
                        });
                      }}
                      className={`${
                        calendarIntegrations.length > 4 && "w-1/2 lg:w-1/4"
                      } py-1 flex-col cursor-pointer border hover:bg-gray-100 p-3 flex items-center justify-center ${
                        addiCal.channel === integration.key &&
                        "bg-green-300 hover:bg-green-300"
                      }`}
                    >
                      <img
                        className={"w-36"}
                        src={"/" + integration.logo}
                        alt={integration.key}
                      />
                    </div>
                  ))}
            </div>
            {addiCal.channel && (
              <div className="mb-10">
                <FormField label={t("apartmentCalendar.link")} className="mt-5">
                  <TextInput
                    value={addiCal.link}
                    onChange={(val) =>
                      setAddiCal((prev) => {
                        return {
                          display: prev.display,
                          channel: prev.channel,
                          link: val,
                          local_key: local_key,
                        };
                      })
                    }
                  ></TextInput>
                </FormField>
              </div>
            )}

            <ModalActions
              saveDisabled={loadingData || !addiCal.channel || !addiCal.link}
              isLoading={loadingData}
              onSave={() => {
                setLoadingData(true);
                mutation.mutate({
                  channel: addiCal.channel,
                  link: addiCal.link,
                  local_key: local_key,
                });
              }}
              saveLabel={t("general.add")}
              onClose={() => {
                setAddiCal((prev) => {
                  return {
                    display: !prev.display,
                    channel: "",
                    link: "",
                    local_key: local_key,
                  };
                });
              }}
            />
          </ModalSection>
        </ModalCard>
      </Modal>
      <Modal visible={calendMod.display} className={"w-full"}>
        <ModalCard
          title={`${calendMod.name.toUpperCase()} (${t(
            "module.statusInactive"
          )})`}
          className={"w-auto"}
        >
          <ModalSection>
            <PageWrapper>
              <span className="text-gray-600 font-medium">
                {t("module.buyModuleFunctionality", {
                  name: calendMod.name.toUpperCase(),
                })}
              </span>
            </PageWrapper>
            <ModalActions
              onClose={() =>
                setCalendMod({
                  display: false,
                  name: "",
                  linkId: "",
                  disableButton: true,
                })
              }
              onSave={() => history.push(`/modules/module#${calendMod.linkId}`)}
              saveLabel={t("module.purchase")}
            />
          </ModalSection>
        </ModalCard>
      </Modal>
    </>
  );
};

export default ApartmentCalendar;
