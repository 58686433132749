import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { BreadItem } from '../types'

export const BreadcrumbWrapper = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
`

export const BreadcrumbItem = styled.div<any>`
  border-radius: 6px;

  ${props => props.arrowLeft && `
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  `}

  ${props => props.arrowRight && `
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  `}
  position: relative;
  height: 26px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  z-index: 2;
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;
  
  &:hover{
    text-decoration: underline;
  }

  background: #cbcdd1;
  ${props => props.active ? `
    background: #00BDE1;
    color: #FFF;
  ` : `
    color: rgba(0,0,0,0.6);
  `}
  ${props => props.arrowLeft && `
    &:before{
      content: "";
      position: absolute;
      left: 0px;
      bottom: 0;
      width: 0;
      height: 0;
      z-index: 1;
      border-left: 4px solid #f9fafb;
      border-top: 13px solid transparent;
      border-bottom: 13px solid transparent;
    }
  `}
  ${props => props.arrowRight && `
    &:after{
      z-index: 1;
      content: "";
      position: absolute;
      right: -4px;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 4px solid ${props.active ? '#00BDE1' : '#cbcdd1'};
      border-top: 13px solid transparent;
      border-bottom: 13px solid transparent;
    }
  `}
`

export default function Breadcrumbs ({
  pages
} : {
  pages: BreadItem[]
}) {

  const history = useHistory()

  return (
    <BreadcrumbWrapper>

      {
        pages.map((p: any, index: number) => (
          <BreadcrumbItem
            key={index}
            active={index === 0}
            arrowLeft={index < pages.length && index !== 0}
            arrowRight={index !== (pages.length - 1)}
            onClick={() => {
              if (p.route) {
                history.push(p.route)
              }
            }}>
            {p.label}
          </BreadcrumbItem>
        ))
      }

    </BreadcrumbWrapper>
  )

}