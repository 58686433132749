import { useEffect } from "react"
import { useState } from "react"
import { BaseSelectItem, DomoticDevice, DomoticEntity } from "../../types"
import SelectInput from "../SelectInput"
import { useTranslation } from "react-i18next";


export default function RuleManagerEntity ({
    device,
    value,
    onChange
} : {
    value?: string,
    device?: DomoticDevice,
    onChange: (entity?: DomoticEntity) => void
}) {

    const [entity, setEntity] = useState<BaseSelectItem>()
    const [entityOptions, setEntityOptions] = useState<{ label: string, value: string }[]>([])
    const { t } = useTranslation()


    useEffect(() => {
        if (device && device.entities && device.entities.length > 0) {
            setEntityOptions(
                device.entities.map(entity => {
                    return {
                        label: entity.entity_name !== 'None' && entity.entity_name !== undefined? entity.entity_name +  ' - '+ entity.entity_type : entity.entity_type,
                        value: entity.entity_id
                    }
                })
            )
        } else {
            setEntity(undefined)
            setEntityOptions([])
        }
    }, [
        device
    ])

    useEffect(() => {
        if (device) {
            const selectedEntity = device.entities.find(entity => entity.entity_id === value)
            if (selectedEntity) {
                const item = {
                    label: selectedEntity.entity_name !== 'None' && selectedEntity.entity_name !== undefined ? selectedEntity.entity_name + ' - '+ selectedEntity.entity_type: selectedEntity.entity_type,
                    value: selectedEntity.entity_id
                }
                setEntity(item)
                handleChange(item)
            }
        }
    }, [ device, value ])

    function handleChange (item?: { label: string, value: string}) {
        if (item && device) {
            const entity = device.entities.find(e => e.entity_id === item.value)
            if (entity) onChange(entity)
        } else {
            onChange(undefined)
        }
    }

    return (
        <div className="bg-cyan-50 border-l-4 border-cyan-600 py-2 px-4 cursor-pointer">
            <div className="flex items-center space-x-4">
                <div className={'w-36 font-medium text-sm'}>
                    {t('rules.entity_to_monitor')}
                </div>
                <div className={'flex-1'}>
                    <SelectInput<BaseSelectItem, false>
                        isDisabled={entityOptions.length === 0}
                        value={entity}
                        options={entityOptions}
                        onChange={item => {
                            if (item) {
                                setEntity(item)
                                if (item) handleChange(item)
                            }
                        }} /> 
                </div>
            </div>
        </div>
    )

}