import { useState } from "react";
import { useTranslation } from "react-i18next";
import BillingAddress from "../components/billing/BillingAddresses";
import BillingTransactions from "../components/billing/BillingTransactions";
import PageTitle from "../components/PageTitle";
import TabSelector from "../components/TabSelector";

export default function Billing () {

  const { t } = useTranslation()

  const [currentTab, setCurrentTab] = useState<string>('addresses')

  return (
    <div>
      <PageTitle
        title={t('billing.title')} />
      <TabSelector tabs={[{
          key: 'addresses',
          label: t('billingAddresses.addresses'),
          active: (currentTab === 'addresses')
        }, {
          key: 'transactions',
          label: t('billing.transactions'),
          active: (currentTab === 'transactions')
        }]}
        className={'mt-4 mb-8'}
        onChange={tab => setCurrentTab(tab.key)} />
      
      {
        currentTab === 'addresses' &&
        <BillingAddress />
      }

      {
        currentTab === 'transactions' &&
        <BillingTransactions />
      }
    </div>
  )

}