import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  fetchAllLocals,
  fetchAllModules,
  fetchV3AllLocals,
  updateReservationV3,
} from "../../shared/queries";
import {
  getFormattedDate,
  getTime,
  times,
} from "../../shared/utils/formatting";
import {
  ListApiResponseData,
  LocalTable,
  BaseSelectItem,
  ReservationV3,
} from "../../types";
import DatePicker from "../DatePicker";
import FormField from "../FormField";
import LanguageSelector from "../LanguageSelector";
import Modal from "../Modal";
import ModalActions from "../ModalAction";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import SelectInput from "../SelectInput";
import TextInput from "../TextInput";
import Checkbox from "../Checkbox";
import { moduleId } from "../../shared/utils/config";

type UpdateReservationDetailsProps = {
  data: ReservationV3;
  visible: boolean;
  onClose?: () => void;
  onSave?: () => void;
};

export default function UpdateReservationDetails({
  data,
  visible,
  onClose,
  onSave,
}: UpdateReservationDetailsProps) {
  const { t } = useTranslation();

  const [reservation, setReservation] = useState<ReservationV3>(data);

  const [addResviCal, setAddResviCal] = useState({
    display: reservation.flag_cm_add_calendar,
    active: false,
    disableButton: true,
  });
  const [addChannel, setAddChannel] = useState({
    active: reservation.channex_set_availability,
    moduleActive: false,
    disableButton: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: dataModules } = useQuery<any>(
    [reservation.local.local_key],
    () => {
      if (reservation.local.local_key) {
        return fetchAllModules(reservation.local.local_key).then(
          (data: any) => {
            const moduleCalendar = data?.modules?.find(
              (module: any) => module?.id === moduleId.calendar
            );
            if (moduleCalendar) {
              if (moduleCalendar.active) {
                setAddResviCal({
                  display: true,
                  active: reservation.flag_cm_add_calendar ?? false,
                  disableButton: false,
                });
              } else {
                setAddResviCal(() => {
                  return {
                    display: true,
                    active: false,
                    disableButton: true,
                  };
                });
              }
            } else {
              setAddResviCal(() => {
                return {
                  display: false,
                  active: false,
                  disableButton: true,
                };
              });
            }
            const moduleChannel = data?.modules?.find(
              (module: any) => module?.id === moduleId.channel_manager
            );
            if (moduleChannel) {
              if (moduleChannel.active) {
                setAddChannel({
                  active: reservation.channex_set_availability,
                  moduleActive: true,
                  disableButton: true,
                });
              } else {
                setAddChannel(() => {
                  return {
                    active: reservation.channex_set_availability,
                    moduleActive: false,
                    disableButton: true,
                  };
                });
              }
            } else {
              setAddChannel(() => {
                return {
                  active: reservation.channex_set_availability,
                  moduleActive: false,
                  disableButton: true,
                };
              });
            }
            return data;
          }
        );
      }
    }
  );

  const setiCal = () => {
    setAddResviCal((prev) => {
      setReservation({
        ...reservation,
        flag_cm_add_calendar: !prev.active,
      });
      return {
        display: true,
        active: !prev.active,
        disableButton: false,
      };
    });
  };
  /* const setChannel = () => {
    setAddChannel((prev) => {
      setReservation({
        ...reservation,
        channex_set_availability: !prev.active,
      });
      return {
        display: true,
        active: !prev.active,
        disableButton: true,
      };
    });
  }; */

  useEffect(() => {
    if (data) {
      // console.log('data', data)
      // const _date_from = dayjs(data.date_from).add(2, 'h').toString();
      // setReservation({ ...data, date_from: dayjs(data.date_from).toISOString() });
    }
  }, [data]);

  const { data: locals, isFetching: isFetchingLocals } = useQuery(
    "localsv3",
    fetchV3AllLocals
  );

  const mutation = useMutation(
    () =>
      updateReservationV3({
        resv_key: reservation.resv_key,
        //cleanDate(reservation.date_from),
        date_from: getFormattedDate(
          reservation.date_from,
          "YYYY-MM-DDTHH:mm:ss",
          "YYYY-MM-DD HH:mm:ss"
        ),
        //cleanDate(reservation.date_to),
        date_to: getFormattedDate(
          reservation.date_to,
          "YYYY-MM-DDTHH:mm:ss",
          "YYYY-MM-DD HH:mm:ss"
        ),
        price: reservation.price,
        name: reservation.name,
        guest_phone: reservation.guest_phone,
        guest_email: reservation.guest_email,
        local_key: reservation.local.local_key,
        lang: reservation.lang,
        flag_cm_add_calendar: reservation.flag_cm_add_calendar,
        channex_set_availability: reservation.channex_set_availability,
      }),
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
        if (onSave) onSave();
      },
    }
  );

  function defaultApartment() {
    if (reservation && locals && locals.results) {
      const currentApartment = locals.results.find(
        (a) => a.local_key === reservation.local.local_key
      );
      if (currentApartment) {
        return {
          label: currentApartment.name,
          value: currentApartment.local_key,
        };
      }
    }
  }

  return (
    <Modal visible={visible} isResvDocs>
      <ModalCard
        className={"w-full lg:max-w-4xl rounded-2xl"}
        title={t("general.generalInformation")}
      >
        <ModalSection>
          <div className={"p-4 flex-wrap flex -mx-2"}>
            <FormField className={"px-2 mb-4 w-1/2"} label={t("resv.arrival")}>
              <DatePicker
                value={reservation.date_from}
                inputFormat={"YYYY-MM-DD HH:mm:ss"}
                outputFormat={"YYYY-MM-DD HH:mm:ss"}
                displayFormat={"DD-MM-YYYY"}
                onChange={(value) => {
                  var time = getTime(reservation.date_from);
                  const parts = time.split(":");
                  setReservation({
                    ...reservation,
                    date_from: dayjs(value)
                      .hour(parseInt(parts[0]))
                      .minute(parseInt(parts[1]))
                      .format("YYYY-MM-DD HH:mm:ss"),
                  });
                }}
              ></DatePicker>
            </FormField>
            <FormField
              className={"px-2 mb-4 w-1/2"}
              label={t("resv.departure")}
            >
              <DatePicker
                value={reservation.date_to}
                inputFormat={"YYYY-MM-DD HH:mm"}
                outputFormat={"YYYY-MM-DD HH:mm:ss"}
                displayFormat={"DD-MM-YYYY"}
                onChange={(value) => {
                  var time = getTime(reservation.date_to);
                  const parts = time.split(":");
                  setReservation({
                    ...reservation,
                    date_to: dayjs(value)
                      .hour(parseInt(parts[0]))
                      .minute(parseInt(parts[1]))
                      .format("YYYY-MM-DD HH:mm:ss"),
                  });
                }}
              ></DatePicker>
            </FormField>
            <FormField
              className={"px-2 mb-4 w-1/2"}
              label={t("resv.checkInTime")}
            >
              <SelectInput<BaseSelectItem, false>
                value={
                  reservation
                    ? {
                        label: getTime(reservation.date_from),
                        value: getTime(reservation.date_from),
                      }
                    : null
                }
                styles={{
                  //container: (base) => ({ ...base, zIndex: 999}),
                  //menuPortal: (base) => ({ ...base, zIndex: 999 }),
                  menu: (base) => ({ ...base, zIndex: 999 }),
                  control: (base) => ({ ...base, zIndex: 0 }),
                }}
                onChange={(item) => {
                  if (item) {
                    const parts = item.value.split(":");
                    setReservation({
                      ...reservation,
                      date_from: dayjs(reservation.date_from)
                        .hour(parseInt(parts[0]))
                        .minute(parseInt(parts[1]))
                        .format("YYYY-MM-DD HH:mm:ss"),
                    });
                  }
                }}
                options={times}
              />
            </FormField>
            <FormField
              className={"px-2 mb-4 w-1/2"}
              label={t("resv.checkOutTime")}
            >
              <SelectInput<BaseSelectItem, false>
                value={
                  reservation
                    ? {
                        label: getTime(reservation.date_to),
                        value: getTime(reservation.date_to),
                      }
                    : null
                }
                /* styles={{
                  container: (base) => ({ ...base, zIndex: 999 }),
                  menuPortal: (base) => ({ ...base, zIndex: 999 }),
                }}*/
                onChange={(item) => {
                  if (item) {
                    const parts = item.value.split(":");
                    setReservation({
                      ...reservation,
                      date_to: dayjs(reservation.date_to)
                        .hour(parseInt(parts[0]))
                        .minute(parseInt(parts[1]))
                        .format("YYYY-MM-DD HH:mm:ss"),
                    });
                  }
                }}
                options={times}
              />
            </FormField>
            {addResviCal.display && !reservation.integr_local_id && (
              <FormField className={"px-2 mb-4 w-full"} label="">
                <Checkbox
                  className=" max-w-max"
                  disabled={addResviCal.disableButton}
                  label={t("apartmentCalendar.addCalendarResv")}
                  checked={addResviCal.active ?? false}
                  onChange={() => setiCal()}
                />
              </FormField>
            )}
            {addChannel.moduleActive && (
              <FormField className={"px-2 mb-4 w-full"} label="">
                <Checkbox
                  className=" max-w-max"
                  disabled={addChannel.disableButton}
                  label={t("apartmentCalendar.addChannel")}
                  checked={addChannel.active ?? false}
                  // onChange={() => setChannel()}
                />
              </FormField>
            )}
            <FormField
              className={"px-2 mb-4 w-full"}
              label={t("resv.apartment")}
            >
              <SelectInput<BaseSelectItem, false>
                defaultValue={defaultApartment()}
                menuPortalTarget={document.body}
                menuShouldScrollIntoView={false}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 1051 }),
                  menu: (base) => ({ ...base, zIndex: 1051 }),
                  control: (base) => ({ ...base, zIndex: 0 }),
                }}
                options={
                  locals && locals.results
                    ? locals.results.map((a) => {
                        return {
                          label: a.name,
                          value: a.local_key,
                        };
                      })
                    : []
                }
                onChange={(el) => {
                  setReservation({
                    ...reservation,
                    local: { ...reservation.local, local_key: el?.value },
                  });
                }}
              />
            </FormField>
            <FormField
              className={"px-2 mb-4 w-full"}
              label={t("resv.guestName")}
            >
              <TextInput
                value={reservation.name}
                className={" rounded-2xl"}
                onChange={(val) =>
                  setReservation({
                    ...reservation,
                    name: val,
                  })
                }
              ></TextInput>
            </FormField>
            <FormField
              className={"px-2 mb-4 w-1/3"}
              label={t("resv.guestEmail")}
            >
              <TextInput
                value={reservation.guest_email}
                className={" rounded-2xl"}
                onChange={(val) =>
                  setReservation({
                    ...reservation,
                    guest_email: val,
                  })
                }
              ></TextInput>
            </FormField>
            <FormField
              className={"px-2 mb-4 w-1/3"}
              label={t("resv.guestPhone")}
            >
              <TextInput
                value={reservation.guest_phone}
                className={" rounded-2xl"}
                onChange={(val) =>
                  setReservation({
                    ...reservation,
                    guest_phone: val,
                  })
                }
              ></TextInput>
            </FormField>
            <FormField
              className={"px-2 mb-4 w-1/3 rounded-2xl"}
              label={t("resv.guestLanguage")}
            >
              <LanguageSelector
                defaultValue={
                  reservation.lang as
                    | "de"
                    | "en"
                    | "es"
                    | "fr"
                    | "it"
                    | "pt"
                    | "ru"
                    | "zh"
                }
                onChange={(item) => {
                  if (item)
                    setReservation({
                      ...reservation,
                      lang: item,
                    });
                }}
              ></LanguageSelector>
            </FormField>
            <FormField className={"px-2 mb-4 w-full"} label={t("resv.total")}>
              <TextInput
                value={reservation.price != null ? reservation.price : 0}
                className={" rounded-2xl"}
                numbered={true}
                onChange={(value) => {
                  setReservation({
                    ...reservation,
                    price: value,
                  });
                }}
              ></TextInput>
            </FormField>
          </div>
        </ModalSection>
        <ModalActions
          isLoading={mutation.isLoading}
          saveLabel={t("general.update")}
          onClose={() => {
            if (onClose) onClose();
          }}
          onSave={() => {
            mutation.mutate();
          }}
        ></ModalActions>
      </ModalCard>
    </Modal>
  );
}
