import { useEffect, useState } from "react";
import React from 'react';
import { useQuery } from "react-query";
import Select from "react-select";
import DateRangePicker from '../DateRangePicker'
import DatePicker from "../DatePicker"
import { fetchAllLocals, fetchAlloggiatiUser, fetchTurismoIds, fetchLicenseIds } from "../../shared/queries";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import SelectInput from "../SelectInput";
import { BaseSelectItem } from "../../types";
import FormField from "../FormField";
import { CONSOLE_LEVELS } from "@sentry/utils";
import { render } from "@testing-library/react";


export default function ExportBuroTicino ({
    onChange
  } : {
    onChange: (data: any) => void
  }) {

    const {
        data: ids,
        isFetching: isFetchingIds
      } = useQuery('payTouristIds', fetchLicenseIds)

      const { t } = useTranslation()

      const [date, setDate] = useState<string>(dayjs().format('YYYY-MM-DD'))
      const [id, setId] = useState<string>('')

      useEffect(() => {
        onChange({
          action: 'TICINO',
          date: date,
          licenseId: id,
        })
      }, [
            date,
            id,
         ])
    
      
  return(
          <div className={'p-4 pt-6 pb-6'}>
      <div className="text-lg font-semibold">
        {t('export.ticino')}
      </div>

      <div className="mt-4 pb-2 text-gray-600 text-sm">
        {t('export.desc')}
      </div>

      <div className="mt-6flex pt-6 pb-6 flex-col space-y-4">
      <DatePicker
          value={date}
          inputFormat={'YYYY-MM-DD'}
          outputFormat={'YYYY-MM-DD'}
          displayFormat={'DD-MM-YYYY'}
          onChange={value => setDate(value)} />
        </div>

        <div className="mt-6flex pt-3 pb-6 flex-col space-y-4">
          
        <FormField
          label={t('navigation.apartments')}>
          <SelectInput<BaseSelectItem, false>
              menuShouldScrollIntoView={false}
              onChange={item => {
                if (item) setId(item.value)
              }}
              options={(ids && ids.results) ? ids.results.map(id => {
                return {
                  label: id,
                  value: id
                }
              }) : [] } />
        </FormField>
        
        </div>
      </div>
        )

  }