import React, {useEffect, useState} from 'react'
import ReactDOM from 'react-dom'

export default function Portal ({
  children
}: {
  children: any
}) {
  
  const [element, setElement] = useState<any>(null)

  useEffect(() => {
    setElement(document.getElementsByTagName('body')[0])
  }, [])

  return (
    <>
      {element ? ReactDOM.createPortal(children, element) : null}
    </>
  )

}