import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../components/Button";
import FormField from "../components/FormField";
import FormSectionTitle from "../components/FormSectionTitle";
import LanguageSelector from "../components/LanguageSelector";
import SpecialTags from "../components/common/SpecialTags";
import PageTitle from "../components/PageTitle";
import PageWrapper from "../components/PageWrapper";
import PreviousPage from "../components/PreviousPage";
import TextInput from "../components/TextInput";
import {
  fetchCustomEmail,
  deleteEmailLanguage,
  updateCustomEmail,
  updateCustomEmailLanguage,
  fetchDefaultEmail,
  fetchPerms,
} from "../shared/queries";
import { BaseSelectItem, EmailContent, EmailLang } from "../types";
import Switch from "react-switch";
import LoadingEmailSkeleton from "../components/emails/LoadingEmailSkeleton";
// import useBreadcrumbs from "../hooks/useBreadcrumbs";
import { useTranslation } from "react-i18next";
import Icon from "../components/Icon";
import SelectInput from "../components/SelectInput";

type CustomEmailParams = {
  id: string;
};

export default function CustomEmail() {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<CustomEmailParams>();

  const [currentLanguage, setCurrentLanguage] = useState<
    "it" | "en" | "de" | "es" | "fr" | "pt" | "ru" | "zh"
  >("it");
  const [translationChange, setTranslationChange] =
    useState<EmailContent | null>(null);
  const [currentTranslation, setCurrentTranslation] =
    useState<EmailLang | null>(null);
  const [emailKey, setEmailKey] = useState<string>("");
  const { data, isLoading } = useQuery(["customEmail", id], () =>
    fetchCustomEmail(id)
  );
  const { data: defaultEmails } = useQuery(["defaultEmail"], () =>
    fetchDefaultEmail()
  );

  const populateFull = () => {
    if (defaultEmails)
      setCurrentTranslation(defaultEmails?.data.full[currentLanguage]);
  };

  const populateMini = () => {
    setCurrentTranslation((prev) => {
      if(prev){
        return {
          ...prev,
          M1_body: defaultEmails?.data.mini[currentLanguage].M1_body,
          M1_subject:defaultEmails?.data.mini[currentLanguage].M1_subject,
          M2_body:defaultEmails?.data.mini[currentLanguage].M2_body,
          M2_subject:defaultEmails?.data.mini[currentLanguage].M2_subject,
          M3_body:defaultEmails?.data.mini[currentLanguage].M3_body,
          M3_subject:defaultEmails?.data.mini[currentLanguage].M3_subject,
          bcc:defaultEmails?.data.mini[currentLanguage].bcc,
          from_host:defaultEmails?.data.mini[currentLanguage].from_host,
          keyemail:defaultEmails?.data.mini[currentLanguage].keyemail,
          lang:defaultEmails?.data.mini[currentLanguage].lang,
        };
      }
      return emptyObject;
    });
  };

  const populateDigi = () => {
    setCurrentTranslation((prev) => {
      if(prev){
        return {
          ...prev,
          M1_body: defaultEmails?.data.digi[currentLanguage].M1_body,
          M1_subject:defaultEmails?.data.digi[currentLanguage].M1_subject,
          M2_body:defaultEmails?.data.digi[currentLanguage].M2_body,
          M2_subject:defaultEmails?.data.digi[currentLanguage].M2_subject,
          M3_body:defaultEmails?.data.digi[currentLanguage].M3_body,
          M3_subject:defaultEmails?.data.digi[currentLanguage].M3_subject,
          bcc:defaultEmails?.data.digi[currentLanguage].bcc,
          from_host:defaultEmails?.data.digi[currentLanguage].from_host,
          keyemail:defaultEmails?.data.digi[currentLanguage].keyemail,
          lang:defaultEmails?.data.digi[currentLanguage].lang,
        };
      }
      return emptyObject;
    });
  };

  const { data: dataUsers } = useQuery("accounts", fetchPerms);

  const emptyObject: EmailLang = {
    DEL: true,
    M1_body: "",
    M1_subject: "",
    M2_body: "",
    M2_subject: "",
    M3_body: "",
    M3_subject: "",
    cust_ptr: parseInt(id),
    keyemail: "",
    lang: currentLanguage,
    objkey: "",
    objtype: "",
    p_DEL: true,
    p_date: "",
    p_id: parseInt(id),
    p_objkey: "",
    p_objtype: "",
    p_user_key: "",
    user_key: "",
    active: false,
  };

  useEffect(() => {
    if (data && data.results) {
      setEmailKey(data.results.objkey);

      if (data.results.langs[currentLanguage]) {
        setCurrentTranslation({ ...data.results.langs[currentLanguage] });
      } else {
        setCurrentTranslation(emptyObject);
      }
      if (data.results.langs) {
        setTranslationChange({ ...data.results.langs });
      }
      // if (data.data.langs[currentLanguage]) {
      //   setCurrentTranslation(data.data.langs[currentLanguage]);
      // }
    }
  }, [data]);

  /**
   * When the current language changes  we set the current translation
   * that will be rendered in the rich editor
   */
  useEffect(() => {
    if (data && data.results && currentLanguage && translationChange) {
      if (translationChange[currentLanguage]) {
        setCurrentTranslation(translationChange[currentLanguage]);
      } else {
        const trans = //defaultEmails?.data.full[currentLanguage] ||
          emptyObject;
        setTranslationChange({
          ...translationChange,
          [currentLanguage]: trans,
        });
        setCurrentTranslation(trans);
      }
    }
  }, [currentLanguage]);

  const mutation = useMutation(
    () =>
      updateCustomEmail({
        id: data?.results?.id,
        user_key: data?.results && data?.results.user_key,
        objkey: emailKey,
      }),
    {
      onSuccess: () => {
        toast.success(t("general.requestCompleted"));
        history.goBack();
      },
    }
  );

  const deleteLangMutation = useMutation(
    (data: any) => deleteEmailLanguage(data.cust_ptr, data.lang),
    {
      onSuccess: () => {},
    }
  );

  const updateLangMutation = useMutation(
    (data: any) => updateCustomEmailLanguage(data),
    {
      onSuccess: () => {},
    }
  );

  /* useBreadcrumbs([
    {
      label: t("navigation.administration"),
      route: "/configuration/bureaucracy",
    },
    { label: t("configuration.customEmail"), route: "/configuration/emails" },
    { label: data && data.results ? data.results.objkey : ".." },
  ]); */

  return (
    <PageWrapper>
      <PreviousPage
        className={"mb-4"}
        label={t("general.goBack")}
      ></PreviousPage>
      {isLoading && <LoadingEmailSkeleton></LoadingEmailSkeleton>}
      {currentTranslation && data?.results && (
        <>
          <PageTitle title={data.results?.objkey}></PageTitle>
          <FormField label={"Account"} errorKey={"user_key"}>
            <SelectInput<BaseSelectItem, false>
              /* onChange={(item) => {
                if (item) setAccount(item);
              }}
              options={
                dataUsers && dataUsers.results
                  ? dataUsers.results.map((account: any) => {
                      return {
                        label: account.o_name,
                        value: account.o_user_key,
                      };
                    })
                  : []
              } */
              value={{
                label: dataUsers?.results?.find((account: any) => {
                  if (data.results) {
                    return data?.results.user_key === account.o_user_key;
                  }
                  return account;
                })?.o_name,
                value: dataUsers?.results?.find((account: any) => {
                  if (data.results) {
                    return data?.results.user_key === account.o_user_key;
                  }
                  return account;
                })?.o_user_key,
              }}
            />
          </FormField>
          <div className="p-4 space-y-4"></div>
          <FormField label={t("createCustomEmail.emailKey")}>
            <TextInput
              value={emailKey}
              onChange={(val) => setEmailKey(val)}
            ></TextInput>
          </FormField>

          <FormSectionTitle title={t("createCustomEmail.emailContent")} />

          <SpecialTags target={"EM"} />

          <LanguageSelector
            className={"mb-3"}
            menuPortalTarget={document.body}
            onChange={(lang) => setCurrentLanguage(lang)}
          ></LanguageSelector>

          <div className="mt-8 flex space-x-2">
            <div className="w-auto">
              <Switch
                onChange={() => {
                  if (translationChange && currentLanguage) {
                    setTranslationChange({
                      ...translationChange,
                      [currentLanguage]: {
                        ...currentTranslation,
                        DEL: currentTranslation.DEL ? false : true,
                      },
                    });
                    setCurrentTranslation({
                      ...currentTranslation,
                      DEL: currentTranslation.DEL ? false : true,
                    });if (currentTranslation.DEL) {
                    populateMini();
                  } else {
                    setCurrentTranslation(emptyObject);
                  }}
                  
                }}
                checked={!currentTranslation.DEL}
              ></Switch>
            </div>
            <div className={"mt-1 font-medium"}>
              {t("createCustomEmail.languageActivated")}
            </div>
          </div>
          {!currentTranslation.DEL && (
            <>
              <FormSectionTitle
                borderless
                className={"mt-6"}
                title={t("createCustomEmail.newReservation")}
                subtitle={t("createCustomEmail.newReservationDesc")}
              />

              <FormField
                className={"mb-4"}
                label={t("createCustomEmail.emailSubject")}
              >
                <TextInput
                  onChange={(val) => {
                    if (
                      translationChange &&
                      currentLanguage &&
                      currentTranslation
                    ) {
                      setCurrentTranslation({
                        ...currentTranslation,
                        M1_subject: val,
                      });
                      setTranslationChange({
                        ...translationChange,
                        [currentLanguage]: {
                          ...currentTranslation,
                          M1_subject: val,
                        },
                      });
                    }
                  }}
                  value={currentTranslation?.M1_subject}
                ></TextInput>
              </FormField>

              <div
                style={{
                  zIndex: 1,
                  position: "relative",
                }}
              >
                <Editor
                  apiKey={"af1s9ngxeroefg0odkelxcvqpjzqffflygu2cw2tqk9jexpo"}
                  value={currentTranslation?.M1_body}
                  init={{
                    promotion:false,
                    height: 350,
                    plugins: [
                      'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                      'searchreplace', 'wordcount', 'visualblocks', 'visualchars', 'code', 'fullscreen', 'insertdatetime',
                      'media', 'table', 'emoticons', 'help'
                    ],
                    toolbar: 'undo redo | styles | bold italic backcolor | alignleft aligncenter alignright alignjustify | ' +
                      'bullist numlist outdent indent | link | ' +
                      'removeformat | help',
                    menubar: 'file edit view insert format tools table help',
                  }}
                  onEditorChange={(value) => {
                    if (
                      translationChange &&
                      currentLanguage &&
                      currentTranslation
                    ) {
                      setCurrentTranslation({
                        ...currentTranslation,
                        M1_body: value,
                      });
                      setTranslationChange({
                        ...translationChange,
                        [currentLanguage]: {
                          ...currentTranslation,
                          M1_body: value,
                        },
                      });
                    }
                  }}
                />
              </div>
              <FormSectionTitle
                borderless
                className={"mt-6"}
                title={t("createCustomEmail.completationReservation")}
                subtitle={t("createCustomEmail.completationReservationDesc")}
              />

              <FormField
                className={"mb-4"}
                label={t("createCustomEmail.emailSubject")}
              >
                <TextInput
                  onChange={(val) => {
                    if (
                      translationChange &&
                      currentLanguage &&
                      currentTranslation
                    ) {
                      setCurrentTranslation({
                        ...currentTranslation,
                        M2_subject: val,
                      });
                      setTranslationChange({
                        ...translationChange,
                        [currentLanguage]: {
                          ...currentTranslation,
                          M2_subject: val,
                        },
                      });
                    }
                  }}
                  value={currentTranslation?.M2_subject}
                ></TextInput>
              </FormField>

              <Editor
                apiKey={"af1s9ngxeroefg0odkelxcvqpjzqffflygu2cw2tqk9jexpo"}
                value={currentTranslation?.M2_body}
                init={{
                  promotion:false,
                  height: 350,
                  plugins: [
                    'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                    'searchreplace', 'wordcount', 'visualblocks', 'visualchars', 'code', 'fullscreen', 'insertdatetime',
                    'media', 'table', 'emoticons', 'help'
                  ],
                  toolbar: 'undo redo | styles | bold italic backcolor | alignleft aligncenter alignright alignjustify | ' +
                    'bullist numlist outdent indent | link | ' +
                    'removeformat | help',
                  menubar: 'file edit view insert format tools table help',
                }}
                onEditorChange={(value) => {
                  if (
                    translationChange &&
                    currentLanguage &&
                    currentTranslation
                  ) {
                    setCurrentTranslation({
                      ...currentTranslation,
                      M2_body: value,
                    });
                    setTranslationChange({
                      ...translationChange,
                      [currentLanguage]: {
                        ...currentTranslation,
                        M2_body: value,
                      },
                    });
                  }
                }}
              />

              <FormSectionTitle
                borderless
                className={"mt-6"}
                title={t("createCustomEmail.remindReservation")}
                subtitle={t("createCustomEmail.remindReservationDesc")}
              />

              <FormField
                className={"mb-4"}
                label={t("createCustomEmail.emailSubject")}
              >
                <TextInput
                  value={currentTranslation?.M3_subject}
                  onChange={(val) => {
                    if (
                      translationChange &&
                      currentLanguage &&
                      currentTranslation
                    ) {
                      setCurrentTranslation({
                        ...currentTranslation,
                        M3_subject: val,
                      });
                      setTranslationChange({
                        ...translationChange,
                        [currentLanguage]: {
                          ...currentTranslation,
                          M3_subject: val,
                        },
                      });
                    }
                  }}
                ></TextInput>
              </FormField>

              <Editor
                apiKey={"af1s9ngxeroefg0odkelxcvqpjzqffflygu2cw2tqk9jexpo"}
                value={currentTranslation?.M3_body}
                init={{
                  promotion:false,
                  height: 350,
                  plugins: [
                    'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                    'searchreplace', 'wordcount', 'visualblocks', 'visualchars', 'code', 'fullscreen', 'insertdatetime',
                    'media', 'table', 'emoticons', 'help'
                  ],
                  toolbar: 'undo redo | styles | bold italic backcolor | alignleft aligncenter alignright alignjustify | ' +
                    'bullist numlist outdent indent | link | ' +
                    'removeformat | help',
                  menubar: 'file edit view insert format tools table help',
                }}
                onEditorChange={(value) => {
                  if (
                    translationChange &&
                    currentLanguage &&
                    currentTranslation
                  ) {
                    setCurrentTranslation({
                      ...currentTranslation,
                      M3_body: value,
                    });
                    setTranslationChange({
                      ...translationChange,
                      [currentLanguage]: {
                        ...currentTranslation,
                        M3_body: value,
                      },
                    });
                  }
                }}
              />
              <div className="space-x-2 mt-2">
                {/* <Button
                  label={t('createCustomEmail.fillVikeyFull')}
                  onClick={() => {
                    populateFull();
                  }}
                /> */}
                <Button
                  label={t("createCustomEmail.fillVikeyMini")}
                  onClick={() => {
                    populateMini();
                  }}
                />
                <Button
                  label={t("createCustomEmail.fillVikeyDigi")}
                  onClick={() => {
                    populateDigi();
                  }}
                />
              </div>
            </>
          )}
          <div className={"mt-6 items-center space-y-2"}>
            <Button
              onClick={() => {
                mutation.mutate();
                // process languages
                if (translationChange) {
                  const keys = Object.keys(translationChange) as Array<
                    keyof EmailContent
                  >;
                  keys.forEach((key) => {
                    if (translationChange[key]) {
                      if (translationChange[key].DEL) {
                        deleteLangMutation.mutate({
                          cust_ptr: parseInt(id),
                          lang: translationChange[key].lang,
                        });
                      } else {
                        updateLangMutation.mutate({
                          ...translationChange[key],
                          cust_ptr: parseInt(id),
                        });
                      }
                    }
                  });
                }
              }}
              /* disabled={saveDisabled} */
            >
              {t("general.save")}
            </Button>
            {/* {saveDisabled && (
              <div className=" flex items-center space-x-2 rounded-lg border-1 border-gray-300 w-1/4">
                <Icon
                  name={'information-circle'}
                  size={'24px'}
                  className={'text-gray-300'}
                />
                <div className={'text-xs font-normal text-gray-400 '}>
                  {t('createCustomEmail.fillAllFields')}
                </div>
              </div>
            )} */}
          </div>
        </>
      )}
    </PageWrapper>
  );
}
