import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { REACT_APP_VIKEY_API_URL } from "../shared/utils/config";


const lng = window.location.hostname.indexOf("airekey") >= 0 ? "en" : "it";
const API_BASE_URL = REACT_APP_VIKEY_API_URL + "/api/v3/user/i18n/";
// const API_BASE_URL = "https://api.vikey.it/api/v3/user/i18n/";

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    debug: true,
    backend: {
      loadPath: `${API_BASE_URL}{{lng}}.json`,
    },
    react: {
      useSuspense: false,
    },
    partialBundledLanguages: true,
    lng: lng,
    compatibilityJSON: "v3",
    fallbackLng: "it",
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
