import { useState } from "react";
import Modal from "../Modal";
import ModalActions from "../ModalAction";
import ModalCard from "../ModalCard";
import TextInput from "../TextInput";
import { useTranslation } from "react-i18next";


export default function ConfirmDialog ({
    message = '',
    visible = false,
    askConfirmation = false,
    confirmationKey = 'confermo',
    isLoading = false,
    onConfirm,
    onCancel,
    title = 'Conferma operazione'
} : {
    title?: string,
    message: string
    visible: boolean,
    isLoading?: boolean,
    askConfirmation?: boolean,
    confirmationKey?: string,
    onConfirm: () => void,
    onCancel: () => void
}) {

    const [confirmValue, setConfirmValue] = useState<string>('')
    const { t } = useTranslation()


    function handleConfirmation () {
        if (askConfirmation) {
            if (confirmValue === confirmationKey) {
                setConfirmValue('')
                onConfirm()
            }
        } else {
            onConfirm()
        }
    }

    return (
        <Modal
            visible={visible}>
            <ModalCard
                actionsLoading={isLoading}
                renderActions={() => (
                    <ModalActions
                        saveLabel={'Conferma operazione'}
                        onClose={onCancel}
                        onSave={handleConfirmation}></ModalActions>
                )}
                title={title}>

                <div className="p-4 text-gray-700 ">
                    {message}
                </div>

                {
                    askConfirmation &&
                    <div className="text-gray-700 p-4 pt-0 border-gray-300">
                        {t('general.write1')} <span className={'font-bold'}>{confirmationKey}</span> {t('general.write2')}

                        <div className="mt-2">
                            <TextInput
                                value={confirmValue}
                                onChange={val => setConfirmValue(val)} />
                        </div>
                    </div>
                }

            </ModalCard>
        </Modal>
    )

}