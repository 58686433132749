import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { fetchReservationv3 } from "../../shared/queries"
import { ReservationV3, SingleApiResponseV3 } from "../../types"
import ReservationGeneralInfo from "../reservation/ReservationGeneralInfo"
import ReservationLoadingSkeleton from "../reservation/ReservationLoadingSkeleton"
import ReservationNotifications from "../reservation/ReservationNotifications"
import ReservationDocuments from "../reservation/ReservationDocuments"

type ReservationDetailProps = {
  reservationId: string|undefined
}

export default function ReservationDetail ({
  reservationId
} : ReservationDetailProps) {

  const {
    data,
    isLoading,
    refetch
  } = useQuery<SingleApiResponseV3<ReservationV3>, Error>(['reservation', reservationId], () => fetchReservationv3(reservationId))

  const [reservation, setReservation] = useState<ReservationV3>()

  useEffect(() => {
    if (data && data.results ) {
      setReservation(data.results)
    }
  }, [ reservationId, data ])


  return (
    <div>
      {
        !reservation ?
        <ReservationLoadingSkeleton></ReservationLoadingSkeleton>
        :
        <div>
          <ReservationGeneralInfo
            loading={isLoading}
            reservation={reservation}
            onSave={() => refetch()}></ReservationGeneralInfo>

          <ReservationNotifications
            reservation={reservation}
            onSave={() => refetch()}></ReservationNotifications>
          <div className="my-5"></div>
          <ReservationDocuments
            reservation={reservation}
            onSave={() => refetch()}></ReservationDocuments>
        </div>
      }
    </div>
  )

}