import { useTranslation } from "react-i18next";
import { CityTaxPerPersonStep } from "../../types";
import FormField from "../FormField";
import Icon from "../Icon";
import TextInput from "../TextInput";

export type ApartmentTaxRowProps = {
  className?: string;
  step: CityTaxPerPersonStep;
  onChange: (step: CityTaxPerPersonStep) => void;
  onDelete: () => void;
};

export default function ApartmentTaxRow({
  step,
  className,
  onChange,
  onDelete,
}: ApartmentTaxRowProps) {
  const { t } = useTranslation();

  return (
    <div className={"flex items-center -mx-3 " + className}>
      <FormField
        className={"px-3 flex-1"}
        label={t("addApartmentTaxRow.fromAge")}
        tooltip={t("addApartmentTaxRow.fromAgeText")}
      >
        <TextInput
          type="number"
          min={0}
          max={100}
          value={step.age}
          numbered={true}
          onChange={(value) => {
            if (onChange) {
              if (+value < 0) {
                onChange({
                  ...step,
                  age: 0,
                });
                return;
              }
              if (+value > 100) {
                onChange({
                  ...step,
                  age: 100,
                });
                return;
              }
              onChange({
                ...step,
                age: value,
              });
            }
          }}
        ></TextInput>
      </FormField>

      <FormField
        className={"px-3 flex-1"}
        label={t("addApartmentTaxRow.reduction")}
        tooltip={t("addApartmentTaxRow.reductionText")}
      >
        <TextInput
          min={0}
          max={100}
          type="number"
          suffix={"%"}
          value={step.percentage}
          numbered={true}
          onChange={(value) => {
            if (onChange) {
              if (+value < 0) {
                onChange({
                  ...step,
                  percentage: 0,
                });
                return;
              }
              if (+value > 100) {
                onChange({
                  ...step,
                  percentage: 100,
                });
                return;
              }
              onChange({
                ...step,
                percentage: value,
              });
            }
          }}
        ></TextInput>
      </FormField>

      <div onClick={onDelete} className={"mt-8 px-3"}>
        <Icon
          className={"text-red-600 cursor-pointer hover:text-red-800"}
          name={"trash"}
          size={"20px"}
        ></Icon>
      </div>
    </div>
  );
}
