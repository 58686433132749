import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import BaseTable from '../../components/BaseTable';
import { fetchBillingAddresses } from '../../shared/queries';
import AddAddress from './AddAddress';
import AssociateAddress from './AssociateAddress';

export default function BillingAddress() {
  const { t } = useTranslation();

  const {
    data: addresses,
    isLoading: isLoadingAddresses,
    refetch,
  } = useQuery<any, Error>('addresses', fetchBillingAddresses, {
    initialData: [],
  });

  const [addAddress, setAddAddress] = useState(false);
  const [associateAddress, setAssociateAddress] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [editAddressId, setEditAddressId] = useState<string | number | null>(
    null
  );

  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    if (addresses && addresses.results) {
      setPageData(
        addresses.results.slice((currentPage - 1) * 15, 15 * currentPage)
      );
    }
  }, [addresses, currentPage]);

  return (
    <>
      <BaseTable
        currentPage={currentPage}
        pageCount={
          addresses
            ? Math.ceil(
                parseInt(addresses.results ? addresses.results.length : 0 || '0') / 15
              )
            : 0
        }
        identifierKey={'id'}
        data={pageData}
        loading={isLoadingAddresses}
        totalRows={addresses.results ? addresses.results.length : 0}
        displayRows={15}
        onFilterChange={(filters, page) => {
          setCurrentPage(page || 1);
        }}
        renderButtons={() => (
          <div className='inline-flex space-x-2'>
            <div
              onClick={() => setAddAddress(true)}
              className={
                'py-1 cursor-pointer  items-center bg-white focus:outline-none bg-cyan-600 shadow border px-3 text-sm text-white font-medium border-cyan-400 rounded-2xl'
              }
            >
              {t('billingAddresses.newAddress')}
            </div>
            <div
              onClick={() => setAssociateAddress(true)}
              className={
                'py-1 cursor-pointer  items-center bg-white focus:outline-none bg-cyan-600 shadow border px-3 text-sm text-white font-medium border-cyan-400 rounded-2xl'
              }
            >
              {t('billingAddresses.associateApartments')}
            </div>
          </div>
        )}
        filters={[]}
        columns={[
          {
            Header: t("billingAddresses.vat"),
            accessor: 'invoice_vatnum',
          },
          {
            Header: t("billingAddresses.ragioneSociale"),
            accessor: 'invoice_company',
          },
        ]}
        onEdit={(id) => {
          setEditAddressId(id);
        }}
      ></BaseTable>

      <AddAddress
        visible={addAddress || editAddressId !== null}
        edit={editAddressId}
        onSave={() => {
          setAddAddress(false);
          setEditAddressId(null);
          refetch();
        }}
        onClose={() => {
          setAddAddress(false);
          setEditAddressId(null);
          console.log(editAddressId)
        }}
      ></AddAddress>

      <AssociateAddress
        visible={associateAddress}
        edit={editAddressId}
        onSave={() => {
          refetch();
          setAssociateAddress(false);
        }}
        onClose={() => {
          setAssociateAddress(false);
        }}
        addresses = {addresses.results}
      ></AssociateAddress>
    </>
  );
}
