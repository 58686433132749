import { useState } from "react"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import ReactTooltip from "react-tooltip"
import { sendReservationAction } from "../../shared/queries"
import { ReservationV3 } from "../../types"
import Popover from "../Popover"
import { getReservationColor } from '../../shared/utils/reservation'
import { useTranslation } from "react-i18next"

type ReservationColorSwitchProps = {
  reservation: ReservationV3,
  onSave: () => void
}

export default function ReservationColorSwitch ({
  reservation,
  onSave
} : ReservationColorSwitchProps) {

  const { t } = useTranslation()

  const mutation = useMutation((color: Number) => sendReservationAction(reservation.resv_key || '', color, 'MARK'), {
    onSuccess: () => {
      toast.error(t('general.operationCompleted'))
      if (onSave) onSave()
    },
    onError: () => {
      toast.error(t('general.requestError'))
    }
  })

  function getCurrentMarkClass () {
    return getReservationColor(reservation.mark)
  }

  return (
    <Popover
      className={'w-full cursor-pointer h-full flex items-center justify-center'}
      renderContent={() => (
        <div className={'bg-white rounded-2xl p-4'}>
          <button
            onClick={() => {
              if (reservation.mark !== 1) {
                mutation.mutate(1)
              }
            }}
            className="focus:outline-none block hover:ring-2 hover:ring-blue-200 mb-3 cursor-pointer w-5 h-5 rounded-2xl bg-gray-200 active:bg-gray-300"></button>
          <button
            onClick={() => {
              if (reservation.mark !== 2) {
                mutation.mutate(2)
              }
            }}
            className="focus:outline-none block hover:ring-2 hover:ring-blue-200 mb-3 cursor-pointer w-5 h-5 rounded-2xl bg-green-600 active:bg-green-700"></button>
          <button
            onClick={() => {
              if (reservation.mark !== 3) {
                mutation.mutate(3)
              }
            }}
            className="focus:outline-none block hover:ring-2 hover:ring-blue-200 mb-3 cursor-pointer w-5 h-5 rounded-2xl bg-blue-600 active:bg-blue-700"></button>
          <button
            onClick={() => {
              if (reservation.mark !== 4) {
                mutation.mutate(4)
              }
            }}
            className="focus:outline-none block hover:ring-2 hover:ring-blue-200 mb-3 cursor-pointer w-5 h-5 rounded-2xl bg-yellow-300 active:bg-yellow-700"></button>
          <button
            onClick={() => {
              if (reservation.mark !== 5) {
                mutation.mutate(5)
              }
            }}
            className="focus:outline-none block hover:ring-2 hover:ring-blue-200 mb-3 cursor-pointer w-5 h-5 rounded-2xl bg-yellow-600 active:bg-yellow-800"></button>
          <button
            onClick={() => {
              if (reservation.mark !== 6) {
                mutation.mutate(6)
              }
            }}
            className="focus:outline-none block hover:ring-2 hover:ring-blue-200 cursor-pointer w-5 h-5 rounded-2xl bg-red-600 active:bg-red-700"></button>
        </div>
      )}>
        <div data-tip={'Assegna colore'} className={`transition-all cursor-pointer w-5 h-5 rounded-2xl ${getCurrentMarkClass()}`}></div>
        <ReactTooltip></ReactTooltip>
    </Popover>
  )

}
