import { useEffect, useRef, useState } from "react"
import {
    Chart,
    ChartTypeRegistry,
    registerables
} from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-luxon'
import ZoomPlugin from 'chartjs-plugin-zoom'

Chart.register(...registerables)
Chart.register(ZoomPlugin)
Chart.register(annotationPlugin)

Chart.defaults.set('plugins.streaming', {
    duration: 100000
})

export default function ChartJsWrapper ({
    title,
    chartType,
    squared = false,
    isRealTime,
    realtimeData,
    historicalData,
    offlineData
} : {
    squared?: boolean,
    title: string,
    isRealTime: boolean,
    chartType: keyof ChartTypeRegistry,
    historicalData: any,
    realtimeData: any,
    offlineData: any
}) {

    const chartRef = useRef<any>()
    const [internalData, setInternalData] = useState<{
        lastRealtimeIndex: number,
        data: any[]
    }>({
        lastRealtimeIndex: 0,
        data: []
    })
    const [offlineInternalData, setOfflineInternalData] = useState<{}>()
    const [chart, setChart] = useState<Chart>()

    function drawChart () {
        if (chart) {
            chart.destroy()
        }
        let c : Chart | null = null
        c = new Chart(chartRef.current, {
            type: chartType,
            options: {
                scales: {
                    y: { grid: {
                        display: false}},
                    x: {
                        grid: {
                            lineWidth: 0,
                        },
                        type: 'time',
                        time: {
                            displayFormats: {
                                hour: 'd-M HH:mm',
                                minute: 'd-M HH:mm',
                                second: 'd-M HH:mm',
                                millisecond: 'd-M HH:mm',
                                day: 'd-M HH:mm',
                            }
                        },
                        ticks: {
                            source: 'auto',
                            // Disabled rotation for performance
                            maxRotation: 0,
                            autoSkip: true,
                        }
                    }
                },
                plugins: {
                    annotation: {
                        annotations: offlineInternalData
                      },
                    decimation: {
                        enabled: true,
                        algorithm: 'lttb',
                        samples: 1
                    },
                    zoom: {
                        pan: {
                            enabled: true
                        },
                        limits: {
                            x: { min: 'original', max: 'original'},
                            y: { min: 'original', max: 'original'}
                        },
                        zoom: {
                            wheel: {
                                enabled: true,
                            },
                            pinch: {
                                enabled: true
                            },
                            mode: 'x',
                        }
                    },
                    legend:{
                        // display:false
                        position:'right'

                    }
                },
                animation: false,
                maintainAspectRatio: false
            },
            data: {
                labels: internalData.data.map((d: any) => d.time),
                datasets: [{
                    pointStyle: squared ? 'rect' : 'line',
                    stepped: squared ? true : false,
                    data: internalData.data.map((d: any) => d.value),
                    label: title,
                    borderColor: '#00BDE1',
                    // pointBorderWidth: 1,
                    tension: 0.1,
                    fill: false,
                }]
            }
        })

        setChart(c)
    }

    useEffect(() => {
        setInternalData(prevData => {
            return {
                lastRealtimeIndex: prevData.lastRealtimeIndex,
                data: historicalData.map((d: any) => {
                    return {
                        time: d.time,
                        value: parseFloat(d.value)
                    }
                })
            }
        })
        setOfflineInternalData(prevData => {
            var annotations = []
            for(var o in offlineData){
                annotations.push({
                    type: 'box',
                    borderColor: 'rgba(0, 0, 0, 0)',
                    xMin: offlineData[o].from,
                    xMax: offlineData[o].to,
                    backgroundColor: 'rgba(189, 191, 190, 0.25)',
                })
            }

            return annotations
            
    })
    }, [ historicalData, offlineData ])

    useEffect(() => {
        if (isRealTime) {
            setInternalData(prevData => {
                return {
                    lastRealtimeIndex: realtimeData.length,
                    data: [
                        ...prevData.data,
                        ...realtimeData.slice(prevData.lastRealtimeIndex).map((d: any) => {
                            return {
                                time: Date.now(),
                                value: parseFloat(d.value)
                            }
                        })
                    ]
                }
            })
        }
    }, [ realtimeData ])

    useEffect(() => {
        drawChart()
    }, [internalData])

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <canvas ref={chartRef} id="chart" />
        </div>
    )

}