import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import BaseTable from "../components/BaseTable";
import Button from "../components/Button";
import {
  fetchInvoices,
  fetchPayInvoices,
  fetchBucketItem,
} from "../shared/queries";
import { Invoice } from "../types";

import customParserFormat from "dayjs/plugin/customParseFormat";
import PageWrapper from "../components/PageWrapper";
import { useTranslation } from "react-i18next";
// import useBreadcrumbs from "../hooks/useBreadcrumbs";
import { isMobile } from "react-device-detect";
import Pagination from "../components/Pagination";
import BillAttemptDetail from "../components/billing/BillAttemptDetail";
dayjs.extend(customParserFormat);

export default function Invoices() {
  const limitPage: number = 15;

  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [currentInvoices, setCurrentInvoices] = useState<"classic" | "pay">(
    "classic"
  );
  const [pageData, setPageData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [visibility, setVisibilty] = useState(false);
  const [idToView, setIdToView] = useState<any>();

  const {
    data: invoices,
    isLoading: isLoadingInvoices,
    refetch: refetchInvoices,
  } = useQuery<any, Error>(
    "invoices",
    () => fetchInvoices(limitPage, currentPage),
    {
      initialData: [],
    }
  );

  const { data: payInvoices, isLoading: isLoadingPayInvoices } = useQuery<
    any,
    Error
  >("payInvoices", fetchPayInvoices, {
    initialData: [],
  });

  function getPageCount() {
    switch (currentInvoices) {
      case "classic":
        return invoices && invoices.results && invoices.results.tot
          ? invoices.results.tot
          : 0;
      case "pay":
        return payInvoices
          ? Math.ceil(
              parseInt(
                payInvoices.results ? payInvoices.results.length : 0 || "0"
              ) / 15
            )
          : 0;
    }
  }

  // useBreadcrumbs([{ label: t("invoices.invoices"), route: "/invoices" }]);

  function getColumns() {
    if (isMobile) {
      return [
        {
          Header: t("invoices.id"),
          accessor: "id",
        },
        {
          Header: t("invoices.total"),
          accessor: (row: any) => (
            <span>
              {row.amount / 100} {row.currency}
            </span>
          ),
        },
        {
          Header: t("invoices.actions"),
          accessor: (row: Invoice) => (
            <div className="flex">
              <div>
                <Button
                  onClick={() => {
                    window.open(row.fic_inv_link);
                  }}
                  size={"small"}
                  iconSize={"15px"}
                  padding={"sm"}
                  icon={"document-download"}
                ></Button>
              </div>
            </div>
          ),
        },
      ];
    } else {
      return [
        {
          Header: t("invoices.status"),
          accessor: (row: any) =>
            currentInvoices === "classic" && (
              <span>
                {row.status === "EMITTED" && (
                  <div
                    className={
                      "bg-cyan-100 text-cyan-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                    }
                  >
                    {t("invoices.emitted")}
                  </div>
                )}
                {row.status === "ZEROED" && (
                  <div
                    className={
                      "bg-blue-100 text-blue-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                    }
                  >
                    {t("invoices.zeroed")}
                  </div>
                )}
                {row.status === "INIT" && (
                  <div
                    className={
                      "bg-gray-100 text-gray-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                    }
                  >
                    {t("invoices.init")}
                  </div>
                )}
                {row.status === "PAID" && (
                  <div
                    className={
                      "bg-green-100 text-green-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                    }
                  >
                    {t("invoices.paid")}
                  </div>
                )}
              </span>
            ),
        },
        {
          Header:
            currentInvoices === "classic"
              ? t("invoices.referenceMonth")
              : t("invoices.date"),
          accessor: (row: any) => (
            <span>
              <div>
                {currentInvoices === "classic"
                  ? dayjs(row?.month_refer?.toString(), "YYYYMM").format(
                      "YYYY-MM"
                    )
                  : dayjs(row.date_created).format("DD-MM-YYYY")}
              </div>
            </span>
          ),
        },
        {
          Header: t("invoices.id"),
          accessor: "id",
        },
        {
          Header: t("invoices.total"),
          accessor: (row: any) => (
            <span>
              {row.amount / 100} {row.currency}
            </span>
          ),
        },
        {
          Header: t("invoices.vat"),
          accessor: (row: any) => (
            <span>
              {row.amount_iva / 100} {row.currency}
            </span>
          ),
        },
        {
          Header: t("invoices.actions"),
          accessor: (row: Invoice) => (
            <div className="flex">
              {row.fic_inv_link && (
                <div>
                  <Button
                    onClick={() => {
                      window.open(row.fic_inv_link);
                    }}
                    size={"small"}
                    iconSize={"15px"}
                    padding={"sm"}
                    icon={"document-download"}
                  ></Button>
                </div>
              )}
              {row.arca_after_polling_id && (
                <div>
                  <Button
                    onClick={() =>
                      fetchBucketItem(
                        `/resv/bucket/arca-invoices/${row.arca_after_polling_id}.pdf`,
                        row.arca_after_polling_id + "",
                        "pdf"
                      )
                    }
                    size={"small"}
                    iconSize={"15px"}
                    padding={"sm"}
                    icon={"document-download"}
                  ></Button>
                </div>
              )}
            </div>
          ),
        },
      ];
    }
  }

  useEffect(() => {
    if (currentInvoices === "classic") {
      if (invoices && invoices.results && invoices.results.data) {
        setPageData(invoices.results.data);
        setTotalData(invoices.results.tot);
        return;
      } else {
        setPageData([]);
        setTotalData(0);
        return;
      }
    } else if (currentInvoices === "pay") {
      if (payInvoices && payInvoices.results) {
        setPageData(
          payInvoices.results.slice((currentPage - 1) * 15, 15 * currentPage)
        );
        setTotalData(payInvoices.results.length);
        return;
      } else {
        setPageData([]);
        setTotalData(0);
        return;
      }
    }
  }, [currentInvoices, invoices, currentPage]);

  useEffect(() => {
    refetchInvoices();
  }, [currentPage]);

  useEffect(() => {
    if (idToView) {
      setVisibilty(true);
    }
  }, [idToView]);

  return (
    <PageWrapper>
      <BaseTable
        currentPage={currentPage}
        pageCount={currentInvoices === "pay" && getPageCount()}
        identifierKey={currentInvoices === "classic" ? "id" : 'username'}
        // title={t("invoices.invoices")}
        onEdit={(id) => {
          if (currentInvoices === "classic") setIdToView(id);
        }}
        data={pageData}
        loading={isLoadingInvoices || isLoadingPayInvoices}
        displayRows={limitPage}
        renderQuickFilters={() => (
          <div className={"flex space-x-1"}>
            <button
              onClick={() => {
                setCurrentPage(1);
                setCurrentInvoices("classic");
              }}
              className={`${
                currentInvoices === "classic" && "bg-cyan-100 text-cyan-800"
              } focus:outline-none hover:bg-cyan-100 cursor-pointer px-3 py-1 rounded-2xl text-sm font-semibold`}
            >
              {t("invoices.classic")}
            </button>
            <button
              onClick={() => {
                setCurrentPage(1);
                setCurrentInvoices("pay");
              }}
              className={`${
                currentInvoices === "pay" && "bg-cyan-100 text-cyan-800"
              } focus:outline-none hover:bg-cyan-100 cursor-pointer px-3 py-1 rounded-2xl text-sm font-semibold`}
            >
              {t("invoices.pay")}
            </button>
          </div>
        )}
        onFilterChange={(filters, page) => {
          setCurrentPage(page || 1);
        }}
        filters={[]}
        columns={getColumns()}
      ></BaseTable>
      {currentInvoices === "classic" && pageData && pageData.length > 1 ? (
        <div className="mt-5">
          <Pagination
            page={currentPage}
            pageSize={limitPage}
            totalItems={totalData}
            onPageUpdate={(value) => {
              if (value === "+") {
                setCurrentPage((prev) => prev + 1);
                return;
              }
              if (value === "-") {
                setCurrentPage((prev) => (prev > 1 ? prev - 1 : 1));
                return;
              }
              setCurrentPage(+value);
            }}
          />
        </div>
      ) : null}

      {idToView && visibility ? (
        <BillAttemptDetail
          visible={visibility}
          id={idToView}
          onClose={() => {
            setIdToView(null);
            setVisibilty(false);
          }}
        />
      ) : null}
    </PageWrapper>
  );
}
