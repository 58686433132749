import { useHistory } from "react-router-dom";
import Icon from "./Icon";

type PreviousPageProps = {
  label: string,
  className?: string
}

export default function PreviousPage ({
  label,
  className
} : PreviousPageProps) {
  
  const history = useHistory()

  return (
    <div
      onClick={() => history.goBack()}
      className={'flex items-center hover:text-gray-800 text-gray-500 cursor-pointer text-sm ' + className}>
      <Icon name={'arrow-narrow-left'} className={'mr-2'} size={'20px'}></Icon> {label}
    </div>
  )
  
}