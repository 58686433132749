import ContentLoader from "react-content-loader"
import Skeleton from "react-loading-skeleton"

export default function ReservationLoadingSkeleton () {

  return (
    <div>

      <div className="mb-8">
        <Skeleton width={'12rem'} height={'2rem'}></Skeleton>
      </div>
      
      <div className={'flex -mx-2'}>
        <div className="px-2">
          <Skeleton width={'4rem'}></Skeleton>
        </div>
        <div className="px-2">
          <Skeleton width={'4rem'}></Skeleton>
        </div>
        <div className="px-2">
          <Skeleton width={'4rem'}></Skeleton>
        </div>
      </div>

      <div className={'mt-6'}>
        <Skeleton width={'15rem'} height={'1.3rem'}></Skeleton>
      </div>

      <div className={'mt-6'}>
        <div className={'flex flex-col -my-1'}>
          <div className={'flex py-1 -mx-2'}>
            <div className={'px-2'}>
              <Skeleton width={'8rem'}></Skeleton>
            </div>
            <div className={'px-2'}>
              <Skeleton width={'8rem'}></Skeleton>
            </div>
          </div>
          <div className={'flex py-1 -mx-2'}>
            <div className={'px-2'}>
              <Skeleton width={'8rem'}></Skeleton>
            </div>
            <div className={'px-2'}>
              <Skeleton width={'5rem'}></Skeleton>
            </div>
          </div>
          <div className={'flex py-1 -mx-2'}>
            <div className={'px-2'}>
              <Skeleton width={'8rem'}></Skeleton>
            </div>
            <div className={'px-2'}>
              <Skeleton width={'10rem'}></Skeleton>
            </div>
          </div>
          <div className={'flex py-1 -mx-2'}>
            <div className={'px-2'}>
              <Skeleton width={'8rem'}></Skeleton>
            </div>
            <div className={'px-2'}>
              <Skeleton width={'7rem'}></Skeleton>
            </div>
          </div>
          <div className={'flex py-1 -mx-2'}>
            <div className={'px-2'}>
              <Skeleton width={'8rem'}></Skeleton>
            </div>
            <div className={'px-2'}>
              <Skeleton width={'6rem'}></Skeleton>
            </div>
          </div>
          <div className={'flex py-1 -mx-2'}>
            <div className={'px-2'}>
              <Skeleton width={'8rem'}></Skeleton>
            </div>
            <div className={'px-2'}>
              <Skeleton width={'9rem'}></Skeleton>
            </div>
          </div>
        </div>
      </div>

      <div className={'mt-6'}>
        <Skeleton width={'15rem'} height={'1.3rem'}></Skeleton>
      </div>

      <div className={'mt-6'}>
        <div className={'flex flex-col -my-1'}>
          <div className={'flex items-center py-1 -mx-2'}>
            <div className={'px-2'}>
              <Skeleton width={'1rem'} height={'1rem'}></Skeleton>
            </div>
            <div className={'px-2'}>
              <Skeleton width={'8rem'}></Skeleton>
            </div>
          </div>
          <div className={'flex items-center py-1 -mx-2'}>
            <div className={'px-2'}>
              <Skeleton width={'1rem'} height={'1rem'}></Skeleton>
            </div>
            <div className={'px-2'}>
              <Skeleton width={'5rem'}></Skeleton>
            </div>
          </div>
          <div className={'flex items-center py-1 -mx-2'}>
            <div className={'px-2'}>
              <Skeleton width={'1rem'} height={'1rem'}></Skeleton>
            </div>
            <div className={'px-2'}>
              <Skeleton width={'10rem'}></Skeleton>
            </div>
          </div>
        </div>
      </div>

    </div>
  )

}