import { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "./Modal";
import ModalActions from "./ModalAction";
import ModalCard from "./ModalCard";
import TextInput from "./TextInput";
import { toast } from "react-toastify";
import Button from "./Button";
import { useQuery } from "react-query";
import { SingleApiResponseV3 } from "../types";
import { captureDepositInfoService } from "../shared/queries";

export default function CaptureDeposit ({
	buyId = '',
	guestLang = 'en',
	visible = false,
	isLoading = false,
	onConfirm,
	onCancel
} : {
	guestLang: string
	buyId: string,
	visible: boolean,
	isLoading?: boolean,
	maxValueDefault?: number,
	onConfirm: (amount: number, depositReason:string) => void,
	onCancel: () => void
}) {

	const { t } = useTranslation()

	const [amount, setAmount] = useState<number>(0)
	const [maxValue, setMaxValue] = useState<number>(0)
	const [depositReason, setDepositReason] = useState<string>('Motivazione del prelievo')
	const {
    data: serviceInfo,
    isLoading: loadingServiceInfo,
    refetch: refetchReservationServices
  } = useQuery<SingleApiResponseV3<any>, Error>(
    ['serviceInfo'],
    () => captureDepositInfoService(buyId),
	{onSuccess: (data) => {
		const maxValueTemp = parseFloat((data?.results.amount_capturable/100).toString())
		setMaxValue(maxValueTemp)
		setAmount(maxValueTemp)
	}}
  )	

	function handleConfirmation () {
		if (amount <= maxValue && amount > 0) {
			onConfirm(amount*100, depositReason)
		}
		else{
			toast.error(t('service.incorrectValue'))
		}
	}

	return (
		<Modal
			visible={visible}>
			<ModalCard
				className={'w-full max-w-lg'}
				actionsLoading={isLoading}
				renderActions={() => (
					<ModalActions
						isLoading={isLoading}
						closeDisabled={isLoading}
						saveDisabled={isLoading}
						saveLabel={t('service.capture')}
						onClose={onCancel}
						onSave={handleConfirmation}
					></ModalActions>
				)}
				title={t('service.capture')}>
				<div className="text-gray-700 p-4 pt-0 border-gray-300">
					{t('service.captureReason')} <span className={'font-bold uppercase'}>{guestLang} </span>
					<div className="mt-2 w-full">
						<TextInput
							value={depositReason}
							renderButton={() =>
							<div className="flex items-center">
								<Button
									onClick={() => {
										setDepositReason('')
									}}
									flat
									size={'medium'}
									color={'negative'}
									icon={'xmark'}
									iconSize={'20px'}/>
							</div>
							}
							onChange={val => setDepositReason(val)}
						/>
					</div>
				</div>

				<div className="text-gray-700 p-4 pt-0 border-gray-300">
					{t('service.maxAmount') + ': '} <span className={'font-bold uppercase'}>{maxValue} {serviceInfo?.results.currency}</span> 
					<div className="mt-2 md:w-1/2 w-full">
						<TextInput
							type="number"
							min={0}
							max={maxValue}
							// numbered={true}
							value={amount}
							renderButton={() => 
							<div className="flex items-center">
								<Button
									onClick={() => {
										setAmount(maxValue)
									}}
									flat
									size={'medium'}
									color={'primary'}
									icon={'double-arrow_top'}
									iconSize={'20px'}/>
							</div>
							}
							onChange={val => setAmount(val)}
						/>
					</div>
				</div>

			</ModalCard>
		</Modal>
	)
}
