import React, { useState, MouseEvent } from 'react';
import { useAppDispatch } from '../store'
import { useHistory, useParams } from "react-router-dom";
import dayjs from 'dayjs';
import { DomainContext } from '../DomainContext';
import Icon from '../components/Icon'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify'
import { resetFromTokenPassword } from '../features/login/loginSlice'





type ResetFromEmailPageParams = {
  token: string
}

export default function ResetFromEmail() {
  const {appLogo, appName} = React.useContext(DomainContext);
  const dispatch = useAppDispatch()
  const history = useHistory()



  const [password, setPassword] = useState<string>()
  const [password2, setPassword2] = useState<string>()





  async function handleResetPassword (event:MouseEvent<HTMLButtonElement>) {
    if (password!==password2) {
      toast.error("Password diverse");

    }
    if (password === undefined){
      toast.error("Password reimpostata");

    }else{
      event.preventDefault()
      const result = await dispatch(resetFromTokenPassword({
        token,
        password
      }))
      if (resetFromTokenPassword.fulfilled.match(result)) {
        toast.success("Password reimpostata");
        history.push('/')    
      }else{
      }
    }
    
  }


  const {t} = useTranslation()
  const { token } = useParams<ResetFromEmailPageParams>()

  return (
    <div
      className={
        'flex items-center justify-center bg-login bg-gray-100 w-screen h-screen p-4'
      }
    >
      <div className={'max-w-sm w-full'}>
        <div className={'text-center mb-6'}>
          <img
            src={appLogo}
            alt={appName + " logo"}
            className={'h-10 inline-block'}
          ></img>
        </div>

        <form>
      <div>
        <p className={'text-center text-gray-600 mb-6'}>
          Inserisci la nuova password.
        </p>
      </div>
      <div>
        <input
          required
          placeholder={'Password'}
          onChange={e =>{
            setPassword(e.target.value)
          }}
          className={'border border-gray-300 border-b-0 focus:border-b-1 placeholder-gray-500 appearance-none rounded-none relative block w-full px-3 py-2 text-gray-900 rounded-t-md focus:outline-none focus:ring focus:ring-cyan-200 focus:border-cyan-400 focus:z-10 sm:text-sm'}
          ></input>
      </div>
      <div>
        <input
          required
          placeholder={'Ripeti Password'}
          onChange={e =>{
            setPassword2(e.target.value)
          }}
          className={'border-gray-300 placeholder-gray-500 appearance-none rounded-none relative block w-full px-3 py-2 border text-gray-900 rounded-b-md focus:outline-none focus:ring focus:ring-cyan-200 focus:border-cyan-400 focus:z-10 sm:text-sm'}
          ></input>
      </div>


      <button
        type="submit"
        onClick={handleResetPassword}
        className={'w-full text-white px-3 py-2 font-medium rounded-2xl bg-gray-800 mt-6'}>
          <div className={'flex items-center justify-between'}>
            <div>Avanti</div>
            <Icon name={'arrow-narrow-right'} size={'20px'}></Icon>
          </div>
      </button>
    </form>


        <div
          className={
            'font-medium text-sm text-center text-gray-500 mt-6 border-t pt-4'
          }
        >
          {appName} {dayjs().format('YYYY')} - All rights reserved.
        </div>
      </div>
    </div>
  );
}
