import { useTranslation } from "react-i18next";
import { CityTaxPerRangePriceStep } from "../../types";
import FormField from "../FormField";
import Icon from "../Icon";
import TextInput from "../TextInput";

export type ApartmentTaxRangeDatePriceProps = {
  className?: string;
  step: CityTaxPerRangePriceStep;
  minValue?: any;
  maxValue?: any;
  onChange: (step: CityTaxPerRangePriceStep) => void;
  onDelete: () => void;
};

export default function ApartmentTaxRangeDatePrice({
  step,
  className,
  minValue,
  maxValue,
  onChange,
  onDelete,
}: ApartmentTaxRangeDatePriceProps) {
  const { t } = useTranslation();

  return (
    <>
      <div className={"flex items-center -mx-3 " + className}>
        <div className="">
          <FormField
            className={"px-3"}
            label={t("addApartmentTaxRangeDate.toDate")}
            tooltip={t("addApartmentTaxRangeDate.toDateText")}
          >
            <TextInput
              type="number"
              min={minValue ?? 0}
              max={maxValue ?? 100}
              value={step.date ?? minValue}
              numbered={true}
              onChange={(value) => {
                if (onChange) {
                  if (+value < 0) {
                    onChange({
                      ...step,
                      date: 0,
                    });
                    return;
                  }
                  if (+value < minValue) {
                    onChange({
                      ...step,
                      date: minValue,
                    });
                    return;
                  }
                  onChange({
                    ...step,
                    date: +value,
                  });
                }
              }}
            ></TextInput>
          </FormField>

          <FormField
            className={"px-3 mt-2"}
            label={t("addApartmentTaxRangeDate.priceRange")}
            tooltip={t("addApartmentTaxRangeDate.priceRangeText")}
          >
            <TextInput
              min={0}
              type="number"
              suffix={"€"}
              value={step.price}
              numbered={true}
              onChange={(value) => {
                if (onChange) {
                  if (+value < 0) {
                    onChange({
                      ...step,
                      price: 0,
                    });
                    return;
                  }
                  onChange({
                    ...step,
                    price: +value,
                  });
                }
              }}
            ></TextInput>
          </FormField>
        </div>
        <div onClick={onDelete} className={"px-3"}>
          <Icon
            className={"text-red-600 cursor-pointer hover:text-red-800"}
            name={"trash"}
            size={"20px"}
          ></Icon>
        </div>
      </div>
      <p className="bg-white p-2 shadow-md rounded-full text-center">
          {t('reservationNotes.from')} {minValue ? minValue : 1} {t('reservationNotes.to')} {step.date} {t('general.days')} {'->'} {step.price.toFixed(2)}€
        </p>
      <div className="w-full pb-4 mt-3">
        <div className="border-b-2 border-gray-300 h-full"></div>
      </div>
    </>
  );
}
