import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import BaseTable from "../../components/BaseTable";
import { fetchTransactions } from "../../shared/queries";
import { Transaction } from "../../types";
import Icon from "../Icon";
import Modal from "../Modal";
import ModalActions from "../ModalAction";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import { useStripe } from "@stripe/react-stripe-js";
import Pagination from "../Pagination";
import ProgressBar from "../ProgressBar";

export default function BillingTransactions() {
  const { t } = useTranslation();
  const limitPage = 15;

  const [currentPage, setCurrentPage] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [currentTransaction, setCurrentTransaction] = useState<
    Transaction | undefined
  >();
  const [confirmTransaction, setConfirmTransaction] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [stripeLoading, setStripeLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const stripe = useStripe();

  const { data, isLoading, refetch } = useQuery<any>(
    "transactions",
    () => fetchTransactions(limitPage, currentPage),
    {
      keepPreviousData: false,
      onSuccess: (data) => {
        if (data && data.results) {
          setLoadingData(false);
        }
      },
    }
  );

  useEffect(() => {
    if (data && data.results && data.results.data) {
      setPageData(data.results.data);
      setTotalData(data.results.tot);
    }
  }, [data, currentPage]);

  useEffect(() => {
    setLoadingData(true);
    refetch();
  }, [currentPage]);

  return (
    <>
      <BaseTable
        currentPage={currentPage}
        identifierKey={"id"}
        data={pageData}
        loading={isLoading || loadingData}
        displayRows={limitPage}
        onFilterChange={(filters, page) => {
          setCurrentPage(page || 1);
        }}
        filters={[]}
        onEdit={(id) => {
          if (!loadingData) {
            if (data && data.results && data.results.data) {
              setCurrentTransaction(
                data.results.data.find(
                  (t: Transaction) => t.id.toString() === id.toString()
                )
              );
            }
          }
        }}
        columns={[
          {
            Header: "ID",
            accessor: (row: Transaction) => {
              return row.id;
            },
          },
          {
            Header: t<string>("reservations.date"),
            accessor: (row: Transaction) => {
              return dayjs(row.date_created).format("DD-MM-YYYY HH:mm");
            },
          },
          {
            Header: t<string>("billing.status"),
            accessor: (row: Transaction) => {
              if (row.status === "ERROR") {
                return (
                  <div
                    className={
                      "px-3 py-1 bg-red-100 text-sm font-medium text-red-600 inline-block rounded-2xl"
                    }
                  >
                    {t("general.error")}
                  </div>
                );
              } else if (row.status === "CHARGING") {
                return (
                  <div
                    className={
                      "px-3 py-1 bg-cyan-100 text-sm font-medium text-cyan-800 inline-block rounded-2xl"
                    }
                  >
                    {t("billing.charging")}
                  </div>
                );
              } else if (row.status === "CONFIRMED") {
                return (
                  <div
                    className={
                      "px-3 py-1 bg-cyan-100 text-sm font-medium text-cyan-800 inline-block rounded-2xl"
                    }
                  >
                    {t("billing.confirmed")}
                  </div>
                );
              } else if (row.status === "ERRAUTH") {
                return (
                  <div
                    className={
                      "px-3 py-1 bg-red-100 text-sm font-medium text-red-600 inline-block rounded-2xl"
                    }
                  >
                    {t("billing.authReq")}
                  </div>
                );
              }
            },
          },
          {
            Header: t<string>("billing.amount"),
            accessor: (row) => {
              return (
                <div className="inline-flex items-center w-full">
                  <div>{(row.amount + row.amount_iva) / 100 + " EUR"}</div>
                  {row.status === "ERRAUTH" && (
                    <div
                      title={t("billing.authorize")}
                      className="ml-4 p-1 bg-green-100 text-sm font-medium text-green-600 inline-block rounded-2xl"
                    >
                      <Icon name={"cash"} size={"20px"}></Icon>
                    </div>
                  )}
                </div>
              );
            },
          },
        ]}
      />
      {loadingData ? <ProgressBar /> : null}
      {pageData && pageData.length > 1 ? (
        <div className="mt-5">
          <Pagination
            page={currentPage}
            pageSize={limitPage}
            totalItems={totalData}
            onPageUpdate={(value) => {
              if (!loadingData) {
                if (value === "+") {
                  setCurrentPage((prev) => prev + 1);
                  return;
                }
                if (value === "-") {
                  setCurrentPage((prev) => (prev > 1 ? prev - 1 : 1));
                  return;
                }
                setCurrentPage(+value);
              }
            }}
          />
        </div>
      ) : null}
      <Modal visible={confirmTransaction}>
        <ModalCard
          className={"w-lg max-w-4xl"}
          title={t("billing.authorize")}
          renderActions={() => (
            <ModalActions
              isLoading={stripeLoading}
              saveLabel={t("general.confirm")}
              onSave={() => {
                try {
                  setStripeLoading(true);
                  stripe
                    ?.confirmCardPayment(
                      currentTransaction?.stripe_cs_id?.toString() ?? "",
                      { payment_method: currentTransaction?.stripe_pm_id }
                    )
                    .then((res) => {
                      setStripeLoading(false);
                      if (res.error) {
                        setModalMessage(res.error.message ?? "");
                      } else {
                        setModalMessage("");
                        setConfirmTransaction(false);
                        refetch();
                      }
                    });
                } catch (err: any) {
                  setModalMessage(t("general.requestError"));
                  console.debug("err", err);
                  setStripeLoading(false);
                }
              }}
              onClose={() => {
                setConfirmTransaction(false);
                setModalMessage("");
              }}
            />
          )}
        >
          <ModalSection>
            <div className="text-justify px-4 py-2 max-w-md">
              {modalMessage}
            </div>
          </ModalSection>
        </ModalCard>
      </Modal>
      <Modal visible={currentTransaction !== undefined}>
        <ModalCard
          className={"w-full max-w-4xl"}
          title={t("billing.detail")}
          renderActions={() => (
            <ModalActions
              saveLabel={t("general.done")}
              onClose={() => setCurrentTransaction(undefined)}
              onSave={() => setCurrentTransaction(undefined)}
              extraButtonAction={() => {
                setConfirmTransaction(true);
              }}
              extraButtonColor="success"
              extraButtonLabel={
                currentTransaction?.status === "ERRAUTH"
                  ? t("billing.authorize")
                  : ""
              }
            ></ModalActions>
          )}
        >
          <ModalSection>
            {currentTransaction && (
              <div>
                {currentTransaction.items.map((item, index) => (
                  <div
                    key={index}
                    className={
                      "flex justify-between space-x-4 items-center p-4 border-b"
                    }
                  >
                    <div className={"flex space-x-4"}>
                      <div>
                        {item.status === "ERROR" && (
                          <div
                            className={
                              "px-3 py-1 bg-red-100 text-sm font-medium text-red-600 inline-block rounded-2xl"
                            }
                          >
                            {t("general.error")}
                          </div>
                        )}
                        {item.status === "CHARGING" && (
                          <div
                            className={
                              "px-3 py-1 bg-cyan-100 text-sm font-medium text-cyan-800 inline-block rounded-2xl"
                            }
                          >
                            {t("billing.charging")}
                          </div>
                        )}
                      </div>
                      {item.subscription && (
                        <div>{item.subscription.title}</div>
                      )}
                      {item.singlepay && <div>{item.singlepay.title}</div>}
                      <div>
                        {dayjs(item.month_refer.toString(), "YYYYMM").format(
                          "MM/YYYY"
                        )}
                      </div>
                    </div>

                    <div>{item.amount / 100} EUR</div>
                  </div>
                ))}
              </div>
            )}
          </ModalSection>
        </ModalCard>
      </Modal>
    </>
  );
}
