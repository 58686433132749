import { motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import { ColumnInstance } from 'react-table';
import Button from './Button';
import Checkbox from './Checkbox';

export default function TableColumnSelector({
  columns,
  onChange,
}: {
  columns: ColumnInstance[];
  onChange: (columns: string[]) => void;
}) {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const [popperElement, setPopperElement] = useState<any>(null);
  const [referenceElement, setReferenceElement] = useState<any>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
  });

  return (
    <div>
      <Button
        refs={setReferenceElement}
        icon="menu-alt-2"
        color="info"
        size="medium"
        padding="xs"
        className="flex items-center text-sm font-medium rounded-2xl"
        label={t('table.columns')}
        onClick={() => setVisible(!visible)}
      />
      {visible && (
        <div
          className={'z-50'}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <motion.div
            transition={{
              duration: 0.1,
            }}
            initial={{
              scale: 0.8,
            }}
            animate={{
              scale: 1,
            }}
          >
            <div
              className={
                'm-1 max-h-80 overflow-auto w-52 bg-white border rounded-2xl shadow-lg'
              }
            >
              <div
                className={
                  'flex p-2 bg-gray-50 border-b justify-between items-center'
                }
              >
                <div className={'font-medium text-sm'}>
                  {t('table.columns')}
                </div>
                <div>
                  <button
                    style={{
                      boxShadow:
                        'rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px',
                    }}
                    onClick={() => {
                      onChange(
                          columns
                            .filter((c) => !c.isVisible)
                            // .filter((c) => c.id !== column.id)
                            .map((c) => c.id)
                        );
                      setVisible(false);
                    }}
                    className={
                      'bg-white text-sm flex text-cyan-600 items-center focus:outline-none px-3 text-sm font-medium py-1 rounded-2xl'
                    }
                  >
                    {t('general.done')}
                  </button>
                </div>
              </div>
              {columns.map((column, index) => (
                <div key={index}>
                  <div className={'p-2 py-1 flex items-center border-b'}>
                    <Checkbox
                      size={'sm'}
                      label={
                        column && column.Header ? column.Header.toString() : ''
                      }
                      checked={column.getToggleHiddenProps().checked}
                      onChange={() => {
                        column.toggleHidden();
                        // onChange(
                        //   columns
                        //     .filter((c) => !c.isVisible)
                        //     .filter((c) => c.id !== column.id)
                        //     .map((c) => c.id)
                        // );
                      }}
                    ></Checkbox>
                  </div>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
}
